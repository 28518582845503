import React from 'react'
import styled from 'styled-components'
import {
  Modal,
  ErrorBox,
  WeakColorSeparator,
  TertiaryButton,
} from '@blue-agency/rogue'

type Props = {
  active: boolean
  onClose: () => void
}

export const EncodeErrorModal: React.FCX<Props> = ({
  className,
  onClose,
  active,
}) => {
  return (
    <Modal
      active={active}
      className={className}
      title="動画変換失敗"
      onClose={onClose}
    >
      <Upper>
        <ErrorBox size="s">
          撮影データの動画変換ができなかったため、お手数ですが
          <br />
          再撮影をお願いします。
        </ErrorBox>
      </Upper>
      <WeakColorSeparator />

      <Bottom>
        <ButtonGroup>
          <CancelButton
            onClick={onClose}
            comlinkPushParams={{
              action: 'close_encode_error_modal',
            }}
          >
            閉じる
          </CancelButton>
        </ButtonGroup>
      </Bottom>
    </Modal>
  )
}

const Upper = styled.div`
  padding: 20px;
  padding-bottom: 40px;
`
const Bottom = styled.div`
  padding: 0 20px;
`
const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;

  > *:not(:last-child) {
    margin-right: 20px;
  }
`

const CancelButton = styled(TertiaryButton).attrs({ size: 'll' })`
  width: 136px;
`
