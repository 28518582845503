import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export type Options = {
  addSuffix?: boolean
}

const defaultOptions: Options = {
  addSuffix: true,
}

type UserType = 'organizer' | 'participant' | 'undefined'

const defaultTitles: { [type in UserType]: string } = {
  organizer: 'インタビューメーカー Lite',
  participant: 'インタビューメーカー',
  undefined: 'インタビューメーカー',
}

export const useTitle = (title: string, options: Options = defaultOptions) => {
  const defaultTitle = useDefaultTitle()

  const appliedTitle = options.addSuffix ? `${title}｜${defaultTitle}` : title

  useEffect(() => {
    document.title = appliedTitle

    return () => {
      document.title = defaultTitle
    }
  }, [defaultTitle, appliedTitle])
}

const useDefaultTitle = () => {
  const location = useLocation()

  if (location.pathname.match(/^\/organizer\//)) {
    return defaultTitles['organizer']
  } else if (location.pathname.match(/^\/participant\//)) {
    return defaultTitles['participant']
  } else {
    return defaultTitles['undefined']
  }
}
