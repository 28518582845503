export const INTERNAL_PATHS = {
  organizer: {
    index: '/organizer',
    rikunabi: {
      index: '/organizer/rikunabi',
      registerEmail: '/organizer/rikunabi/register-email/:promotionGuid',
      apply: '/organizer/rikunabi/:token/apply',
      expired: '/organizer/rikunabi/expired/:promotionGuid',
      alreadyApplied: '/organizer/rikunabi/already-applied/:promotionGuid',
    },
    signup: {
      index: '/organizer/signup/:promotionGuid',
      finish: '/organizer/signup/:promotionGuid/thanks',
    },
    environment: '/organizer/check-seminar-environment',
    my: {
      index: '/organizer/:token',
      upgradePlan: '/organizer/:token/upgrade-plan',
      halls: {
        index: '/organizer/:token/halls',
        entrance: {
          index: '/organizer/:token/halls/entrances/:entranceGuid',
          room: '/organizer/:token/halls/entrances/:entranceGuid/room',
          finish: '/organizer/:token/halls/entrances/:entranceGuid/finish',
          overtime: '/organizer/:token/halls/entrances/:entranceGuid/overtime',
        },
      },
      interviews: {
        index: '/organizer/:token/interviews',
        interview: {
          index: '/organizer/:token/interviews/:interviewGuid',
          room: '/organizer/:token/interviews/:interviewGuid/room',
          finish: '/organizer/:token/interviews/:interviewGuid/finish',
          expired: '/organizer/:token/interviews/:interviewGuid/expired',
        },
        //株リク専用ページ
        interviewsCreate: '/organizer/:token/interviews-create',
      },
      presentations: {
        index: '/organizer/:token/presentations',
        presentation: {
          index: '/organizer/:token/presentations/:presentationGuid',
          recording:
            '/organizer/:token/presentations/:presentationGuid/recording',
          recorded:
            '/organizer/:token/presentations/:presentationGuid/recorded',
          overtime:
            '/organizer/:token/presentations/:presentationGuid/overtime',
        },
      },
    },
    interviewRecording: '/organizer/interview-recordings/:token',
    hallRecording: '/organizer/hall-recordings/:token',
  },
  participant: {
    index: '/participant',
    verifyEmail: '/participant/verify-email/:token',
    terms: '/participant/terms',
    halls: {
      index: '/participant/halls',
      entrance: {
        index: '/participant/halls/entrances/:entranceGuid',
        newParticipant: '/participant/halls/entrances/:entranceGuid/new',
        room: '/participant/halls/entrances/:entranceGuid/room',
        finish: '/participant/halls/entrances/:entranceGuid/finish',
        alreadyFinished:
          '/participant/halls/entrances/:entranceGuid/already-finished',
      },
    },
    interviews: {
      index: '/participant/interviews',
      interview: {
        index: '/participant/interviews/:interviewGuid',
        room: '/participant/interviews/:interviewGuid/room',
        finish: '/participant/interviews/:interviewGuid/finish',
        expired: '/participant/interviews/:interviewGuid/expired',
      },
    },
    environment: '/participant/check-interview-environment',
  },
  maintenance: '/maintenance',
}
