import React, { useCallback, useState } from 'react'
import { Layout } from '@/components/Layout'
import { EnvironmentCheck as EnvironmentCheckComponent } from '@blue-agency/react-environment-check'
import { useEnvironmentCheck } from '@/services/environmentCheckService'
import { HelpLinkIcon } from '@blue-agency/rogue'
import { EXTERNAL_URLS } from '@/services/urlService'
import { provide } from '@blue-agency/front-state-management'
import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'
import { useTitle } from '@/hooks/useTitle'
import { checkInterviewBrowser } from '@/services/browserCheckService'
import { Finish } from './Finish'

export const EnvironmentCheckPage: React.FCX = provide(
  OrganizerServiceContainer
)(({ className }) => {
  const { cameraTestConnectionProps } = useEnvironmentCheck()
  const [isFinishedEnvCheck, setIsFinishedEnvCheck] = useState(false)

  useTitle('動作環境テスト（Web面接）')

  const handleFinishEnvCheck = useCallback(() => {
    setIsFinishedEnvCheck(true)
  }, [])

  return (
    <Layout
      className={className}
      rightNode={
        <HelpLinkIcon
          href={EXTERNAL_URLS.help.participants.interview}
          action="open_participants_interview_help_page"
        />
      }
      notFixHeight
    >
      {isFinishedEnvCheck ? (
        <Finish />
      ) : (
        <EnvironmentCheckComponent
          userType="interviewee"
          steps={[
            {
              name: 'browser',
              helpUrl: EXTERNAL_URLS.help.participants.environment.os,
              showRecommendPc: true,
              checkBrowserFunction: checkInterviewBrowser,
            },
            {
              name: 'camera',
              helpUrl: EXTERNAL_URLS.help.participants.environment.camera,
              ...cameraTestConnectionProps,
            },
            {
              name: 'mic',
              helpUrl: EXTERNAL_URLS.help.participants.environment.mike,
            },
            {
              name: 'speaker',
              helpUrl: EXTERNAL_URLS.help.participants.environment.speaker,
            },
            {
              name: 'speed',
              helpUrl: EXTERNAL_URLS.help.participants.environment.speed,
            },
          ]}
          onFinish={handleFinishEnvCheck}
        />
      )}
    </Layout>
  )
})
