import React, { useMemo } from 'react'
import { getPlanTerm } from '@/utils'
import { price } from '@/constants'
import { RadioOption } from '@blue-agency/rogue'
import { Plan } from '@blue-agency/proton/web/v2/yashiori_bff/plan_data_pb'
import { ThousandSeparatedNum } from '@/components/ThousandSeparatedNum'
import { OrganizerForm } from '../types'
import { UseFormMethods } from 'react-hook-form'

type Props = {
  formContext: UseFormMethods<OrganizerForm>
  planOptions: RadioOption[]
}

export function useInputBodyPlan({ formContext, planOptions }: Props) {
  const watchedPlan = formContext.watch('plan')
  const term = useMemo(() => {
    const now = new Date()
    switch (watchedPlan) {
      case Plan.Id.BASIC.toString():
        return getPlanTerm(watchedPlan, now)
      default:
        throw new Error('Invalid plan')
    }
  }, [watchedPlan])

  const total = useMemo(() => {
    switch (watchedPlan) {
      case Plan.Id.BASIC.toString():
        return {
          sum: (
            <>
              <ThousandSeparatedNum number={price.basic} />
              円（税抜）
            </>
          ),
          notice: `※提供先は法人事業者に限られます。
※当月末締/翌々月末払となります。
    申込み翌月の20日頃請求書を発送させていただきます。
    振込手数料はお客様ご負担となります。
※本フォームでのお申込み完了後、数分でメールが送信されます。
※申込月も利用可能ですが、「申込み翌月から12ヶ月間の金額」がご利用金額の対象となります。
※記載のない条件は利用規約に定めます。ただし、利用規約と利用条件の定めとの間に齟齬があるときは、利用条件の定めを優先して適用するものとします。`,
        }
      default:
        throw new Error('Invalid plan')
    }
  }, [watchedPlan])

  return {
    term,
    total,
  }
}
