import React from 'react'
import styled from 'styled-components'
import {
  CheckBox,
  InputText as _InputText,
  RadioGroup,
  RequireTxt,
  theme,
  Txt,
  WeakColorSeparator,
  Dropdown,
  NewTabLink,
} from '@blue-agency/rogue'
import { EXTERNAL_URLS } from '@/services/urlService'
import { RecommendedEnvTable as _RecommendedEnvTable } from '@/components/RecommendEnvTable'
import {
  basicItems,
  adminItems,
  billingItems,
  billToOptions,
  newHiresNumberItems,
} from '@/components/OrganizerForm/formItems'
import { OrganizerForm } from '../../types'
import { UseFormMethods } from 'react-hook-form'

const text = {
  admin: `このメールアドレスに「ご利用アカウント情報」が送信されます。
入力内容にお間違いがないか再度確認お願いします。
また、以下のメールアドレスを受信できるように設定してください。
noreply@interview-maker.com`,
}

type Props = {
  PriceTable?: React.FCX
  plan: React.ReactNode
  formContext: UseFormMethods<OrganizerForm>
  checked: boolean
  onCheck: (e: React.ChangeEvent<HTMLInputElement>) => void
  hideBilling?: boolean
  recommendedEnvUrl?: string
  hideEmployeesNumber?: boolean
  hideNewHiresNumber?: boolean
}

export const InputBody: React.FCX<Props> = ({
  className,
  PriceTable,
  formContext,
  checked,
  onCheck,
  plan,
  hideBilling = false,
  recommendedEnvUrl = undefined,
  hideEmployeesNumber = false,
  hideNewHiresNumber = false,
}) => {
  return (
    <NewOrganizerBodyWrapper className={className}>
      {PriceTable && (
        <>
          <PriceTableWrapper>
            <PriceTable />
          </PriceTableWrapper>
          <WeakColorSeparator />
        </>
      )}
      {recommendedEnvUrl !== undefined && (
        <>
          <RecommendedEnvTable recommendedEnvUrl={recommendedEnvUrl} />
          <WeakColorSeparator />
        </>
      )}
      {plan}
      <WeakColorSeparator />
      <Block>
        <ItemGroup>
          {basicItems.map((item, index) => (
            <Item key={index}>
              <ItemTitle>{index === 0 && '契約者情報'}</ItemTitle>
              <ItemLabel>
                <ItemLabelTxt>{item.label}</ItemLabelTxt>
                <Required size="xs">必須</Required>
                {item.name === 'employeesNumber' ? (
                  !hideEmployeesNumber && (
                    <Dropdown
                      name={item.name}
                      ref={formContext.register}
                      options={item.options || []}
                      placeholder={item.placeholder}
                      onBlurError={formContext.errors[item.name]?.message}
                    />
                  )
                ) : (
                  <InputText
                    name={item.name}
                    size={item.label === '住所' ? 'll' : 'l'}
                    placeholder={item.placeholder}
                    ref={formContext.register}
                    onBlurError={formContext.errors[item.name]?.message}
                  />
                )}
              </ItemLabel>
            </Item>
          ))}
        </ItemGroup>
      </Block>
      <WeakColorSeparator />
      <Block>
        <ItemGroup>
          {adminItems.map((item, index) => (
            <Item key={index}>
              <ItemTitle>{index === 0 && '管理者情報'}</ItemTitle>
              <ItemLabel>
                <ItemLabelTxt>{item.label}</ItemLabelTxt>
                <Required size="xs">必須</Required>
                <InputText
                  name={item.name}
                  size={item.label === '住所' ? 'll' : 'l'}
                  placeholder={item.placeholder}
                  ref={formContext.register}
                  onBlurError={formContext.errors[item.name]?.message}
                />
              </ItemLabel>
            </Item>
          ))}
          <AdminTxt size="s" color={theme.color.red[2]}>
            {text.admin}
          </AdminTxt>
        </ItemGroup>
      </Block>
      <WeakColorSeparator />
      {!hideBilling && <Billing formContext={formContext} />}
      {!hideNewHiresNumber && (
        <>
          <WeakColorSeparator />
          <Block>
            <ItemGroup>
              {newHiresNumberItems.map((item, index) => (
                <Item key={index}>
                  <ItemTitle>{index === 0 && '採用状況'}</ItemTitle>
                  <ItemLabel>
                    <ItemLabelTxt>{item.label}</ItemLabelTxt>
                    <Required size="xs">必須</Required>
                    <Dropdown
                      name={item.name}
                      ref={formContext.register}
                      options={item.options || []}
                      placeholder={item.placeholder}
                      onBlurError={formContext.errors[item.name]?.message}
                    />
                  </ItemLabel>
                </Item>
              ))}
            </ItemGroup>
          </Block>
        </>
      )}
      <WeakColorSeparator />
      <Block>
        <ItemGroup>
          <Item>
            <ItemTitle>同意事項</ItemTitle>
            <TxtGroup>
              <CheckBoxWrapper>
                <ItemLabel>
                  <CheckBox checked={checked} onChange={onCheck} />
                  <CheckBoxTxt size="s">
                    <NewTabLink
                      href={EXTERNAL_URLS.terms}
                      action="open_terms_page"
                    >
                      インタビューメーカー利用規約
                    </NewTabLink>
                    、株式会社スタジアムの
                    <NewTabLink
                      href={EXTERNAL_URLS.privacy}
                      action="open_privacy_policy_page"
                    >
                      プライバシーポリシー
                    </NewTabLink>
                    に同意する
                  </CheckBoxTxt>
                </ItemLabel>
              </CheckBoxWrapper>
            </TxtGroup>
          </Item>
        </ItemGroup>
      </Block>
    </NewOrganizerBodyWrapper>
  )
}

type BillingProps = {
  formContext: UseFormMethods<OrganizerForm>
}
const Billing: React.FCX<BillingProps> = ({ formContext }) => {
  const watchedBillTo = formContext.watch('billTo')
  return (
    <Block>
      <ItemGroup>
        <Item>
          <ItemTitle>請求先情報</ItemTitle>
          <ItemLabel>
            <ItemLabelTxt>請求先</ItemLabelTxt>
            <Required size="xs" />
            <RadioGroup
              ref={formContext.register}
              name="billTo"
              direction="row"
              options={billToOptions}
            />
          </ItemLabel>
        </Item>
        {billingItems.map((item, index) => (
          <Item key={index} hide={watchedBillTo === 'same'}>
            <ItemTitle />
            <ItemLabel>
              <ItemLabelTxt>{item.label}</ItemLabelTxt>
              <Required size="xs">必須</Required>
              <InputText
                name={item.name}
                size={item.label === '住所' ? 'll' : 'l'}
                placeholder={item.placeholder}
                ref={formContext.register}
                onBlurError={formContext.errors[item.name]?.message}
              />
            </ItemLabel>
          </Item>
        ))}
      </ItemGroup>
    </Block>
  )
}

const NewOrganizerBodyWrapper = styled.div``
const PriceTableWrapper = styled.div`
  padding: 20px;
  ${NewOrganizerBodyWrapper} & {
    margin: 0 auto;
  }
`
const RecommendedEnvTable = styled(_RecommendedEnvTable)`
  padding: 20px;
  ${NewOrganizerBodyWrapper} & {
    margin: 0 auto;
  }
`
const Block = styled.div`
  ${NewOrganizerBodyWrapper} &:nth-of-type(n + 2) {
    margin-top: 0;
  }
  padding: 20px 0;
`
const ItemGroup = styled.div``
const Item = styled.div<{ hide?: boolean }>`
  ${ItemGroup} &:nth-of-type(n + 2) {
    margin-top: 20px;
  }
  padding: 0 20px;
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  align-items: center;
`
const ItemTitle = styled(Txt)`
  width: 160px;
`
const ItemLabel = styled.label`
  display: flex;
  align-items: center;
`
const ItemLabelTxt = styled(Txt).attrs({ size: 's', bold: true })`
  width: 130px;
`
const Required = styled(RequireTxt)`
  width: 30px;
`
const InputText = styled(_InputText)``
const TxtGroup = styled.div``
const AdminTxt = styled(Txt)`
  ${ItemGroup} & {
    margin-left: 330px;
    margin-top: 6px;
  }
`
const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  ${Item} & {
    margin-top: 10px;
    margin-left: 150px;
  }
`
const CheckBoxTxt = styled(Txt)`
  margin-left: 10px;
`
