import React from 'react'
import { Layout } from '@/components/Layout'
import { EnvironmentCheck as EnvironmentCheckComponent } from '@blue-agency/react-environment-check'
import { useEnvironmentCheck } from '@/services/environmentCheckService'
import { HelpLinkIcon } from '@blue-agency/rogue'
import { EXTERNAL_URLS } from '@/services/urlService'
import { checkInterviewBrowser } from '@/services/browserCheckService'

type Props = {
  onFinish: () => void
}

export const EnvironmentCheck: React.FCX<Props> = (props) => {
  const { cameraTestConnectionProps } = useEnvironmentCheck()

  return (
    <Layout
      className={props.className}
      rightNode={
        <HelpLinkIcon
          href={EXTERNAL_URLS.help.organizer.interview.top}
          action="open_organizer_interview_help_page"
        />
      }
      notFixHeight
    >
      <EnvironmentCheckComponent
        userType="interviewer"
        steps={[
          {
            name: 'recommendForAll',
          },
          {
            name: 'browser',
            helpUrl: EXTERNAL_URLS.help.organizer.environment.os,
            showRecommendPc: true,
            checkBrowserFunction: checkInterviewBrowser,
          },
          {
            name: 'camera',
            helpUrl: EXTERNAL_URLS.help.organizer.environment.camera,
            ...cameraTestConnectionProps,
          },
          {
            name: 'mic',
            helpUrl: EXTERNAL_URLS.help.organizer.environment.mike,
          },
          {
            name: 'speaker',
            helpUrl: EXTERNAL_URLS.help.organizer.environment.speaker,
          },
          {
            name: 'speed',
            helpUrl: EXTERNAL_URLS.help.organizer.environment.speed,
          },
          { name: 'completed' },
        ]}
        onFinish={props.onFinish}
      />
    </Layout>
  )
}
