import React from 'react'
import styled from 'styled-components'
import { theme } from '@blue-agency/rogue'

export const RedCircle: React.FCX = ({ className }) => {
  return <Div className={className} />
}

const Div = styled.div`
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: ${theme.color.red[2]};
`
