import { useMemo, useCallback, useState } from 'react'
import { DropdownOption } from '@blue-agency/rogue'
import { createContainer } from '@blue-agency/front-state-management'
import { HallsContainer } from './HallsContainer'
import { useModal } from '@/hooks/useModal'

const useBulkAction = () => {
  const { selectedHalls, selectAllHalls, unselectAllHalls } =
    HallsContainer.useContainer()

  const deleteHallsModal = useModal()

  const actions = useMemo(
    () => ({
      deleteHalls: {
        key: 'deleteHalls',
        func: () => deleteHallsModal.open(),
      },
    }),
    [deleteHallsModal]
  )

  const options = useMemo<DropdownOption[]>(() => {
    const options: DropdownOption[] = []

    options.push({
      label: '説明会の削除',
      value: actions.deleteHalls.key,
    })

    return options
  }, [actions])

  const [selectedActionKey, setSelectedActionKey] = useState<string>('')

  const count = useMemo(() => selectedHalls.length, [selectedHalls])

  const canExecuteAction = useMemo(() => {
    return selectedActionKey !== '' && count > 0
  }, [count, selectedActionKey])

  const hasSelectedHalls = useMemo(() => count > 0, [count])

  const execute = useCallback(() => {
    const targetAction = Object.values(actions).find(
      (action) => action.key === selectedActionKey
    )

    if (targetAction) {
      targetAction.func()
    }
  }, [actions, selectedActionKey])

  const toggleAll = useCallback(() => {
    if (hasSelectedHalls) {
      unselectAllHalls()
    } else {
      selectAllHalls()
    }
  }, [hasSelectedHalls, selectAllHalls, unselectAllHalls])

  return {
    count,
    hasSelectedHalls,
    canExecuteAction,
    options,
    execute,
    toggleAll,
    setSelectedActionKey,
    deleteHallsModal,
  }
}

export const BulkActionContainer = createContainer(useBulkAction)
