import { createContainer } from '@blue-agency/front-state-management'
import { useParams } from 'react-router-dom'
import { useRef, useEffect, useState, useCallback, useMemo } from 'react'
import { EnterContainer } from '../EnterContainer'
import {
  getCameraOffTrackForParticipants,
  getCameraOffTrackForPresenter,
} from './getCameraOffTrack'
import { useSora } from './useSora'
import { usePrevious } from '@/hooks/usePrevious'
import {
  useSpecificMediaDevice,
  buildErrorMessage,
} from '@blue-agency/react-media-devices'

const logRTCStatsEndpoint = process.env.REACT_APP_LOG_RTC_STATS_ENDPOINT
if (!logRTCStatsEndpoint) throw new Error('logRTCStatsEndpoint not found')

const getUserMedia = async (selectedCameraDeviceId: string) => {
  const constraints = {
    video: {
      facingMode: 'user',
      width: 1280,
      height: 720,
      deviceId: { exact: selectedCameraDeviceId },
    },
    audio: true,
  }
  if (
    navigator.mediaDevices === undefined ||
    navigator.mediaDevices.getUserMedia === undefined
  ) {
    alert(
      '利用できるカメラが見つかりません、本体設定や端末を変えて再度お試しください。'
    )
    return null
  }
  const userMediaStream = await navigator.mediaDevices
    .getUserMedia(constraints)
    .catch(() => {
      alert(
        '利用できるカメラが見つかりません、本体設定や端末を変えて再度お試しください。'
      )
      return null
    })

  return userMediaStream
}

const usePresenterVideo = () => {
  const { setIsPresenterPublished, presenterSora, enterHallRes } =
    EnterContainer.useContainer()

  const prevPresenterSora = usePrevious(presenterSora)
  const updatedPresenterSora = useMemo(() => {
    return presenterSora !== prevPresenterSora
  }, [presenterSora, prevPresenterSora])

  const { selectedDeviceId: selectedCameraDeviceId, error: deviceError } =
    useSpecificMediaDevice('videoinput')
  useEffect(() => {
    if (deviceError) {
      alert(buildErrorMessage(deviceError))
    }
  }, [deviceError])

  const [muted, setMuted] = useState(false)
  const [isCameraOff, setIsCameraOff] = useState(false)

  const { entranceGuid } = useParams<{ entranceGuid?: string }>()
  if (!entranceGuid) throw new Error('entranceGuid not found')

  const entranceGuidRef = useRef(entranceGuid)

  const [userMediaStream, setUserMediaStream] = useState<MediaStream>()
  const [presenterVideoStream, setPresenterVideoStream] =
    useState<MediaStream>()

  const cameraOffTrackForPresenter = useMemo<MediaStreamTrack>(
    getCameraOffTrackForPresenter,
    []
  )
  const cameraOffTrackForParticipants = useMemo<MediaStreamTrack>(
    getCameraOffTrackForParticipants,
    []
  )
  const isConnectedRef = useRef(false)

  const presenterVideoRef = useCallback(
    (element: HTMLVideoElement | null) => {
      if (!element) return
      element.srcObject = presenterVideoStream || null
    },
    [presenterVideoStream]
  )

  const {
    connectToSora,
    disconnectFromSora,
    replaceTrack,
    isConnectionFailed,
  } = useSora(enterHallRes.getHallGuid(), logRTCStatsEndpoint)

  const connect = useCallback(async () => {
    if (!presenterSora) return
    if (!selectedCameraDeviceId) return

    setIsPresenterPublished(false)
    await disconnectFromSora()
    isConnectedRef.current = false

    const myStream = await getUserMedia(selectedCameraDeviceId)
    if (!myStream) return

    await connectToSora({
      entranceGuid: entranceGuidRef.current,
      hostname: presenterSora.hostname,
      channelId: presenterSora.channelId,
      userMediaStream: myStream,
    })

    const presenterVideoStream = new MediaStream()

    if (isCameraOff) {
      presenterVideoStream.addTrack(cameraOffTrackForPresenter)
      try {
        replaceTrack(cameraOffTrackForParticipants)
      } catch {
        alert('カメラオフに失敗しました')
      }
    } else {
      const userVideoTrack = myStream.getVideoTracks()[0]
      if (!userVideoTrack) throw new Error('Not found videoTrack')
      presenterVideoStream.addTrack(userVideoTrack)
    }

    if (muted) {
      const audioTrack = myStream.getAudioTracks()[0]
      if (!audioTrack) throw new Error('Not found audioTrack')
      audioTrack.enabled = false
    }

    isConnectedRef.current = true
    setUserMediaStream(myStream)
    setPresenterVideoStream(presenterVideoStream)
    setIsPresenterPublished(true)
  }, [
    cameraOffTrackForParticipants,
    cameraOffTrackForPresenter,
    connectToSora,
    disconnectFromSora,
    isCameraOff,
    muted,
    presenterSora,
    replaceTrack,
    selectedCameraDeviceId,
    setIsPresenterPublished,
  ])

  useEffect(() => {
    // MEMO: selectedCameraDeviceId が変更しただけの場合はconnectしない
    if (isConnectedRef.current && !updatedPresenterSora) {
      return
    }

    connect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presenterSora, selectedCameraDeviceId, connect])

  useEffect(() => {
    /**
     * NOTE:
     * 配信中にWiFi切り替えなどでuserMediaStreamが取れなくなる現象が起きているので
     * connect後にuserMediaStreamが空になったら再度connectする
     */
    if (!userMediaStream && isConnectedRef.current) {
      connect()
    }
  }, [userMediaStream, connect])

  useEffect(() => {
    return () => {
      disconnectFromSora()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!userMediaStream) return
    const audioTrack = userMediaStream.getAudioTracks()[0]
    if (!audioTrack) return
    audioTrack.enabled = !muted
  }, [muted, userMediaStream])

  const toggleMuted = useCallback(() => {
    setMuted((prev) => !prev)
  }, [])

  const toggleCameraOff = useCallback(async () => {
    if (!userMediaStream) return

    const presenterVideoStream = new MediaStream()

    if (isCameraOff) {
      const userVideoTrack = userMediaStream.getVideoTracks()[0]
      try {
        presenterVideoStream.addTrack(userVideoTrack)
        replaceTrack(userVideoTrack)
      } catch {
        alert('カメラオフの解除に失敗しました')
        return
      }
    } else {
      presenterVideoStream.addTrack(cameraOffTrackForPresenter)
      try {
        replaceTrack(cameraOffTrackForParticipants)
      } catch {
        alert('カメラオフに失敗しました')
        return
      }
    }

    setPresenterVideoStream(presenterVideoStream)
    setIsCameraOff((prev) => !prev)
  }, [
    userMediaStream,
    isCameraOff,
    cameraOffTrackForParticipants,
    cameraOffTrackForPresenter,
    replaceTrack,
  ])

  return {
    presenterVideoRef,
    muted,
    toggleMuted,
    isCameraOff,
    toggleCameraOff,
    isConnectionFailed,
  }
}

export const PresenterVideoContainer = createContainer(usePresenterVideo)
