import React, { useMemo } from 'react'

import { Participant, ParticipantType } from '@/lib/react-interview-sdk'
import {
  useLocalVideoTileId,
  useMutedStreamIdMap,
  useOwnParticipant,
  usePinnedTileId,
  useUnstableLevel,
  useRemoteVideoTile,
  useIsOwnCameraMuted,
  useIsUnavailableVideoInput,
} from '@/lib/react-interview-sdk/hooks/values'
import {
  usePinTile,
  useBindVideoElement,
  useSetAudioOutputForVideoTile,
} from '@/lib/react-interview-sdk/hooks/controls'

import {
  InterviewVideos as InterviewVideosBase,
  ParticipantTile,
} from '@/components/InterviewVideos'
import { DeviceType } from '@/services/interviewService/types/DeviceType'

type InterviewVideosProps = {
  width: number
  participantType: ParticipantType
  deviceType: DeviceType
  participants: Participant[]
}

export const InterviewVideos: React.FCX<InterviewVideosProps> = ({
  className,
  width,
  participantType,
  deviceType,
  participants,
}) => {
  const ownParticipant = useOwnParticipant()
  const localTileId = useLocalVideoTileId()
  const mutedStreamIdMap = useMutedStreamIdMap()
  const pinTile = usePinTile()
  const pinnedTileId = usePinnedTileId()
  const unstableLevel = useUnstableLevel()
  const { attendeeIdToTileId } = useRemoteVideoTile()
  const isOwnCameraMuted = useIsOwnCameraMuted()
  const isUnavailableVideoInput = useIsUnavailableVideoInput()
  const bindVideoElement = useBindVideoElement()
  const setAudioOutputForVideoTile = useSetAudioOutputForVideoTile()

  const remoteParticipants = useMemo(() => {
    if (!ownParticipant) return participants
    return participants.filter(
      (i) => i.soraClientId !== ownParticipant?.soraClientId
    )
  }, [ownParticipant, participants])

  const ownParticipantVideo = useMemo((): ParticipantTile | undefined => {
    if (!ownParticipant) return undefined
    return {
      ...ownParticipant,
      muted: mutedStreamIdMap[ownParticipant.soraClientId] === true,
      tileId: localTileId,
    }
  }, [localTileId, mutedStreamIdMap, ownParticipant])

  const remoteParticipantVideos = useMemo((): ParticipantTile[] => {
    return remoteParticipants.map((participant) => {
      return {
        ...participant,
        muted: mutedStreamIdMap[participant.soraClientId] === true,
        tileId: attendeeIdToTileId[participant.soraClientId],
      }
    })
  }, [attendeeIdToTileId, mutedStreamIdMap, remoteParticipants])

  return (
    <InterviewVideosBase
      className={className}
      width={width}
      participantType={participantType}
      deviceType={deviceType}
      ownParticipant={ownParticipantVideo}
      participants={remoteParticipantVideos}
      pinTile={pinTile}
      pinnedTileId={pinnedTileId}
      unstableLevel={unstableLevel}
      isOwnCameraMuted={isOwnCameraMuted}
      isUnavailableVideoInput={isUnavailableVideoInput}
      bindVideoElement={bindVideoElement}
      setAudioOutputForVideoTile={setAudioOutputForVideoTile}
    />
  )
}
