import React from 'react'
import { Txt, theme } from '@blue-agency/rogue'
import styled, { css } from 'styled-components'

type Props = {
  arrowDirection: 'top' | 'right' | 'top-right' | 'bottom'
}

export const UnstableNavPopup: React.FCX<Props> = (props) => {
  return (
    <Wrapper arrowDirection={props.arrowDirection}>
      <Inner className={props.className}>
        <Arrow arrowDirection={props.arrowDirection} />
        <Txt color={theme.color.white[1]}>
          接続が不安定かな？
          <br />
          と思ったらこちら
        </Txt>
      </Inner>
    </Wrapper>
  )
}

const Wrapper = styled.div<Props>`
  ${({ arrowDirection }) => {
    switch (arrowDirection) {
      case 'top':
        return css`
          display: flex;
          align-items: flex-end;
          width: 150px;
          height: 78px;
        `
      case 'right':
        return css`
          width: 168px;
          height: 66px;
        `
      case 'top-right':
        return css`
          width: 168px;
          height: 66px;
        `
      case 'bottom':
        return css`
          width: 150px;
          height: 80px;
        `
    }
  }};
`

const Inner = styled.div`
  position: relative;
  display: inline-block;
  background-color: ${theme.color.navy[1]};
  border-radius: 4px;
  padding: 12px;
  z-index: 0;
`

const Arrow = styled.div<Props>`
  position: absolute;
  z-index: -1;

  ${({ arrowDirection }) => {
    switch (arrowDirection) {
      case 'top':
        return css`
          border-right: 20px solid transparent;
          border-left: 20px solid transparent;
          border-bottom: 40px solid ${theme.color.navy[1]};
          top: -12px;
          right: 12px;
        `
      case 'right':
        return css`
          border-top: 20px solid transparent;
          border-bottom: 20px solid transparent;
          border-left: 40px solid ${theme.color.navy[1]};
          right: -18px;
          bottom: 5px;
        `
      case 'top-right':
        return css`
          border-left: 40px solid ${theme.color.navy[1]};
          border-top: 4px solid transparent;
          border-bottom: 40px solid transparent;

          right: -18px;
          bottom: 15px;
        `
      case 'bottom':
        return css`
          border-top: 40px solid ${theme.color.navy[1]};
          border-right: 2px solid transparent;
          border-left: 40px solid transparent;
          right: 8px;
          bottom: -14px;
        `
    }
  }};
`
