import React, { useCallback, useRef } from 'react'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { theme, TertiaryButton, NewTabLink, CheckBox } from '@blue-agency/rogue'
import { TableBase } from '@/components/TableBase'
import { useParams } from 'react-router-dom'
import { toast } from '@blue-agency/rogue'
import {
  PresentationsContainer,
  PresentationListItem,
} from './hooks/PresentationsContainer'
import { formatDate } from '@/services/dateService'
import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'
import { StatusCode } from 'grpc-web'
import { NotPermittedModalContainer } from '@/containers/NotPermittedModalContainer'
import { UpdateNameModal } from './UpdateNameModal'
import { EncodeErrorModal } from './EncodeErrorModal'
import { useModal } from '@/hooks/useModal'
import { getRelativePath } from '@/services/urlService'
import { buttonResetStyle } from '@/styles/buttonResetStyle'
import { Menu } from './Menu'
import { MenuPlace } from '@/components/Dropdown'

type Property =
  | 'select'
  | 'name'
  | 'startedAt'
  | 'presentationUrl'
  | 'download'
  | 'menu'
  | 'padding'

type TableCellProps = {
  property: Property
  hover?: boolean
}

const width = {
  select: 50,
  name: 500,
  startedAt: 140,
  presentationUrl: 140,
  download: 140,
  menu: 50,
  delete: 50,
}

const hostname = process.env.REACT_APP_FRONT_HOST
if (!hostname) throw new Error('hostname not found')

// NOTE: 別オリジンからのダウンロードなのでdownload属性でファイル名を指定できない
const downloadVideo = (url: string) => {
  const link = document.createElement('a')
  link.href = url
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const getMenuPlace = (index: number, presentationsLength: number) => {
  // NOTE: 上4件はtopに表示するとテーブルヘッダーで見切れてしまうのでbottomに表示する
  if (index < 4) return 'bottom'
  // NOTE: 下4件はbottomに表示するとテーブルフッターで見切れてしまうのでtopに表示する
  return presentationsLength - 4 <= index ? 'top' : 'bottom'
}

export const Content: React.FC = () => {
  const { presentations } = PresentationsContainer.useContainer()
  return (
    <Table hover>
      <TableHead>
        <TableRow>
          <Th property="select" />
          <Th property="name">動画名</Th>
          <Th property="startedAt">撮影開始日時</Th>
          <Th property="presentationUrl">撮影開始画面</Th>
          <Th property="download">動画ダウンロード</Th>
          <Th property="menu" />
          <Th property="padding" />
        </TableRow>
      </TableHead>
      <TableBody>
        {presentations.map((presentation, index) => {
          const menuPlace = getMenuPlace(index, presentations.length)
          return (
            <TableBodyRow
              key={index}
              presentation={presentation}
              menuPlace={menuPlace}
            />
          )
        })}
      </TableBody>
    </Table>
  )
}

const TableBodyRow: React.FCX<{
  presentation: PresentationListItem
  menuPlace: MenuPlace
}> = ({ presentation, menuPlace }) => {
  const { token } = useParams<{ token?: string }>()
  if (!token) throw new Error('token not found')
  const tableRowRef = useRef<HTMLTableRowElement>(null)
  const { getDownloadUrlForPresentation } =
    OrganizerServiceContainer.useContainer()
  const { selectPresentation, unselectPresentation } =
    PresentationsContainer.useContainer()
  const notPermittedModal = NotPermittedModalContainer.useContainer()

  const {
    guid,
    presentationName,
    startTime,
    isRecorded,
    isEncodeSucceeded,
    isEncodeFailed,
    isSelected,
  } = presentation

  const updateNameModal = useModal()

  const encodeErrorModal = useModal()

  const toggleCheck = useCallback(() => {
    if (isSelected) {
      unselectPresentation(presentation)
    } else {
      selectPresentation(presentation)
    }
  }, [isSelected, presentation, unselectPresentation, selectPresentation])

  const handleDownload = useCallback(async () => {
    try {
      const url = await getDownloadUrlForPresentation(guid)
      downloadVideo(url)
    } catch (e) {
      if (e.code === StatusCode.PERMISSION_DENIED) {
        notPermittedModal.open('動画ダウンロード')
        return
      }
      throw e
    }
    toast('動画データをダウンロードしました')
  }, [getDownloadUrlForPresentation, guid, notPermittedModal])

  return (
    <>
      <TableRow active={!isRecorded} ref={tableRowRef}>
        <Td property="select">
          <CheckBox
            checked={presentation.isSelected}
            onChange={toggleCheck}
            size={16}
          />
        </Td>
        <Td property="name" onClick={updateNameModal.open}>
          {presentationName}
        </Td>
        <Td property="startedAt">{startTime && formatDate(startTime)}</Td>
        <Td property="presentationUrl">
          <OpenPresentationPageButton presentation={presentation} />
        </Td>
        <Td property="download">
          {isEncodeFailed ? (
            <EncodeErrorMessage onClick={encodeErrorModal.open}>
              変換エラー
            </EncodeErrorMessage>
          ) : (
            isRecorded && (
              <TertiaryButton
                text={isEncodeSucceeded ? 'ダウンロード' : '変換中...'}
                size="m1"
                onClick={handleDownload}
                comlinkPushParams={{ action: 'download_presentation' }}
                disabled={!isEncodeSucceeded}
              />
            )
          )}
        </Td>
        <Td property="menu">
          <Menu
            tableRowRef={tableRowRef}
            place={menuPlace}
            presentationGUID={presentation.guid}
          />
        </Td>
        <Td property="padding" />
      </TableRow>
      {updateNameModal.active && (
        <UpdateNameModal
          currentName={presentationName}
          presentationGuid={guid}
          onClose={updateNameModal.close}
          onOpen={updateNameModal.open}
        />
      )}
      <EncodeErrorModal
        active={encodeErrorModal.active}
        onClose={encodeErrorModal.close}
      />
    </>
  )
}

const OpenPresentationPageButton: React.FCX<{
  presentation: PresentationListItem
}> = ({ presentation }) => {
  const { isRecorded, presentationUrl } = presentation

  if (!isRecorded) {
    return (
      <NewTabLink
        href={getRelativePath(presentationUrl)}
        hideIcon
        action="open_presentation_page"
      >
        <TertiaryButton
          text="撮影開始"
          size="m2"
          comlinkPushParams={{
            action: 'click_open_presentation_page_button',
          }}
        />
      </NewTabLink>
    )
  }
  return (
    <TertiaryButton text="撮影開始" disabled size="m2" comlinkPushParams={{}} />
  )
}

const Table = styled(TableBase)`
  th:last-of-type {
    justify-content: flex-start;
  }
  width: 100%;
  min-width: ${Object.values(width).reduce((acc, val) => acc + val)}px;
`

const TableHead = styled.thead`
  position: sticky;
  top: 0px;
  z-index: 100;
`

const TableBody = styled.tbody``

type TableRowProps = {
  active?: boolean
}

const TableRow = styled.tr<TableRowProps>`
  display: flex;
  align-items: center;
  position: relative;

  ${({ active }) =>
    !active &&
    css`
      background: ${theme.color.gray[4]};
    `}
`

const Th = styled.th<TableCellProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  ::-webkit-scrollbar {
    display: none;
  }
  ${({ property }) => CSS[property]}
`
const Td = styled.td<TableCellProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ::-webkit-scrollbar {
    display: none;
  }
  height: 70px !important;
  ${({ property }) => CSS[property]}
  ${({ hover }) =>
    hover &&
    css`
      &:hover {
        background: ${theme.color.gray[4]};
      }
    `}
`

const CSS: Record<Property, FlattenSimpleInterpolation> = {
  select: css`
    display: flex;
    justify-content: center;
    flex: 0 0 ${width.select}px;
  `,
  name: css`
    display: flex;
    align-items: center;
    flex: 0 0 ${width.name}px;

    ${TableHead} && {
      > *:not(:last-child) {
        margin-right: 8px;
      }
    }

    ${TableBody} && {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  `,
  startedAt: css`
    flex: 0 0 ${width.startedAt}px;
  `,
  presentationUrl: css`
    display: flex;
    justify-content: center;
    flex: 0 0 ${width.presentationUrl}px;
  `,
  download: css`
    flex: 0 0 ${width.download}px;
  `,
  menu: css`
    flex: 0 0 ${width.menu}px;
  `,
  padding: css`
    flex: 1 0 auto;
  `,
}

const EncodeErrorMessage = styled.button`
  ${buttonResetStyle};
  color: ${theme.color.red[2]};
  text-decoration: underline;
  margin-left: 20px;
`
