import { useRef, useCallback, useState, useEffect } from 'react'
import soraSdk, { ConnectionOptions, ConnectionPublisher } from 'sora-js-sdk'
import { useOfflineComlinkQueue } from '@/hooks/useOfflineComlinkQueue'

export const useSora = (hallGuid: string) => {
  const sendonlyRef = useRef<ConnectionPublisher>()
  const [unstableLevel, setUnstableLevel] = useState<number>()
  const [isConnectionFailed, setIsConnectionFailed] = useState(false)
  const { enqueue: comlinkPush } = useOfflineComlinkQueue()

  const onIceConnectionStateChangeHandler = useCallback(() => {
    const pc = sendonlyRef?.current?.pc
    if (!pc) return
    pc.oniceconnectionstatechange = () => {
      if (
        pc.iceConnectionState === 'failed' ||
        pc.iceConnectionState === 'disconnected' ||
        pc.iceConnectionState === 'closed'
      ) {
        setIsConnectionFailed(true)
        comlinkPush({
          type: 'system_activity',
          action: 'disconnected_from_webrtc_connection_on_screen_sharing',
          targetName: 'hallGuid',
          targetIdStr: hallGuid,
          metadata: {
            iceConnectionState: pc.iceConnectionState,
          },
        })
      }
    }
  }, [hallGuid, comlinkPush])

  const connect = useCallback(
    async ({
      entranceGuid,
      hostname,
      channelId,
      screenShareStream,
    }: {
      entranceGuid: string
      hostname: string
      channelId: string
      screenShareStream: MediaStream
    }) => {
      const options: ConnectionOptions = {
        multistream: false,
        videoCodecType: 'H264',
        videoBitRate: 800,
        audio: false,
      }
      const metadata = {
        guid: entranceGuid,
      }
      const sora = soraSdk.connection(`wss://${hostname}/signaling`, false)
      const sendonly = sora.sendonly(channelId, metadata, options)
      sendonly.on('notify', (data) => {
        if (data.event_type === 'network.status') {
          setUnstableLevel(data.unstable_level)
        }
      })
      sendonlyRef.current = sendonly

      comlinkPush({
        type: 'system_activity',
        action: 'connect_screen_to_sora',
        targetName: 'channelId',
        targetIdStr: channelId,
      })
      await sendonly
        .connect(screenShareStream)
        .then(onIceConnectionStateChangeHandler)
    },
    [comlinkPush, onIceConnectionStateChangeHandler]
  )

  useEffect(() => {
    if (unstableLevel === undefined) return
    comlinkPush({
      type: 'system_activity',
      action: 'change_unstable_level',
      targetName: 'hallGuid',
      targetIdStr: hallGuid,
      metadata: {
        videoType: 'screenShare',
        unstableLevel: unstableLevel + '',
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comlinkPush, unstableLevel])

  const disconnect = useCallback(async () => {
    if (!sendonlyRef.current) return
    comlinkPush({
      type: 'system_activity',
      action: 'disconnect_screen_from_sora',
      targetName: 'channelId',
      targetIdStr: sendonlyRef.current.channelId,
    })
    await sendonlyRef.current.disconnect()
  }, [comlinkPush])

  return {
    connect,
    disconnect,
    isConnectionFailed,
  }
}
