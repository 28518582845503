import React, { useState, useMemo, useCallback } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { First } from '../First'
import { Second } from '../Second'

const useStep = () => {
  const [step, setStep] = useState(1)
  const content = useMemo(() => {
    switch (step) {
      case 1:
        return <First />
      case 2:
        return <Second />
      default:
        throw new Error('Invalid step')
    }
  }, [step])

  const toNextStep = useCallback(() => {
    setStep((prev) => ++prev)
  }, [])
  const toPreviousStep = useCallback(() => {
    setStep((prev) => --prev)
  }, [])

  return { content, toNextStep, toPreviousStep }
}

export const StepContainer = createContainer(useStep)
