import {
  createContainer,
  useCachedPromise,
} from '@blue-agency/front-state-management'
import { useState, useCallback, useMemo } from 'react'
import { FormStep } from '@/components/OrganizerForm/types'
import { useParams, useHistory } from 'react-router-dom'
import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'
import { cacheKey } from '@/services/bffService'
import { useApplyForm } from './useApplyForm'
import { ApplyFormParams } from '../types'
import {
  ApplyForRikunabiPackageRequest,
  ApplyForRikunabiPackageResponse,
} from '@blue-agency/proton/web/v2/yashiori_bff/yashiori_bff_service_pb'
import { INTERNAL_PATHS, fillParams } from '@/services/urlService'
import { PreferredStartDate } from '@blue-agency/proton/web/v2/yashiori_bff/preferred_start_date_pb'
const { Status } = ApplyForRikunabiPackageResponse

const useApplyPage = (verifyTokenRes?: {
  email: string
  promotionGuid: string
}) => {
  if (!verifyTokenRes) throw new Error('verifyTokenRes not found')
  const { token } = useParams<{ token?: string }>()
  if (!token) throw new Error('token not found')

  const orgService = OrganizerServiceContainer.useContainer()
  const promotion = useCachedPromise(
    cacheKey.getPromotionForRikunabiPackage({
      promotionGuid: verifyTokenRes.promotionGuid,
    }),
    () =>
      orgService.getPromotionForRikunabiPackage({
        promotionGuid: verifyTokenRes.promotionGuid,
      })
  )

  const [step, setStep] = useState<FormStep>('input')

  const [isAgreementChecked, setIsAgreementChecked] = useState(false)
  const handleCheck = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setIsAgreementChecked(e.target.checked)
  }, [])

  const form = useApplyForm()
  const [inputtedData, setInputtedData] = useState<ApplyFormParams>(() =>
    form.watch()
  )

  const handleSubmitInInputStep = useMemo(
    () =>
      form.handleSubmit(async (data) => {
        if (!isAgreementChecked) {
          alert('利用規約とプライバシーポリシーに同意してください')
          return
        }
        const inputtedData: ApplyFormParams =
          data.billTo === 'same'
            ? {
                ...data,
                billingOrganizerName: data.name,
                billingName: data.adminName,
                billingNameKana: data.adminNameKana,
                billingDepartment: data.adminDepartment,
                billingPostCode: data.postCode,
                billingAddress: data.address,
                billingPhoneNumber: data.adminPhoneNumber,
                billingEmail: verifyTokenRes.email,
              }
            : { ...data }
        setInputtedData(inputtedData)
        setStep('confirm')
        window.scrollTo(0, 0)
      }),
    [form, isAgreementChecked, verifyTokenRes.email]
  )
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const handleSubmitInConfirmStep = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault()
      setIsLoading(true)
      let res: { status: ApplyForRikunabiPackageResponse.Status }
      try {
        res = await orgService.applyForRikunabiPackage({
          ...inputtedData,
          billingOrganizerName: inputtedData.billingOrganizerName!,
          billingName: inputtedData.billingName!,
          billingNameKana: inputtedData.billingNameKana!,
          billingDepartment: inputtedData.billingDepartment!,
          billingPostCode: inputtedData.billingPostCode!,
          billingAddress: inputtedData.billingAddress!,
          billingPhoneNumber: inputtedData.billingPhoneNumber!,
          billingEmail: inputtedData.billingEmail!,
          token,
          preferredStartDateId: Number(
            inputtedData.preferredStartDateId
          ) as PreferredStartDate.Id,
          employeesNumber: Number(
            inputtedData.employeesNumberId
          ) as ApplyForRikunabiPackageRequest.EmployeesNumber,
          newHiresNumber: Number(
            inputtedData.newHiresNumberId
          ) as ApplyForRikunabiPackageRequest.NewHiresNumber,
        })
      } catch (e) {
        alert('申込みに失敗しました。もう一度お試しください。')
        setIsLoading(false)
        throw e
      }
      switch (res.status) {
        case Status.ALREADY_REGISTERED:
          history.push(
            fillParams({
              path: INTERNAL_PATHS.organizer.rikunabi.alreadyApplied,
              params: { promotionGuid: verifyTokenRes.promotionGuid },
            })
          )
          break
        case Status.EXPIRED:
          history.push(
            fillParams({
              path: INTERNAL_PATHS.organizer.rikunabi.expired,
              params: { promotionGuid: verifyTokenRes.promotionGuid },
            })
          )
          break
        case Status.VALID:
          setStep('completed')
          break
        default:
          throw new Error('Invalid status')
      }
    },
    [orgService, history, verifyTokenRes.promotionGuid, inputtedData, token]
  )

  const handleSubmit = useMemo(() => {
    switch (step) {
      case 'input':
        return handleSubmitInInputStep
      case 'confirm':
        return handleSubmitInConfirmStep
    }
  }, [step, handleSubmitInInputStep, handleSubmitInConfirmStep])

  const handleBackToInputStep = useCallback(() => {
    form.reset(inputtedData)
    setStep('input')
    setIsAgreementChecked(false)
    window.scrollTo(0, 0)
  }, [form, inputtedData])

  return {
    step,
    promotion,
    handleCheck,
    isAgreementChecked,
    handleSubmit,
    handleBackToInputStep,
    form,
    inputtedData,
    isLoading,
  }
}

export const ApplyPageContainer = createContainer(useApplyPage)
