import React from 'react'
import styled from 'styled-components'
import { Layout } from '@/components/Layout'
import { Txt, HelpLinkIcon } from '@blue-agency/rogue'
import { EXTERNAL_URLS } from '@/services/urlService'
import { useTitle } from '@/hooks/useTitle'

export const OvertimePage: React.FCX = ({ className }) => {
  useTitle('終了ページ')

  return (
    <Layout
      className={className}
      rightNode={
        <HelpLinkIcon
          href={EXTERNAL_URLS.help.organizer.presentation}
          action="open_organizer_presentation_help_page"
        />
      }
    >
      <TxtWrapper>
        <Txt>
          お疲れ様でした。上限時間を過ぎたため
          <br />
          撮影を終了しました。
        </Txt>
      </TxtWrapper>
    </Layout>
  )
}

const TxtWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 40px 20px 20px;
  text-align: center;
`
