import React from 'react'
import styled from 'styled-components'
import { PrimaryButton, TertiaryButton } from '@blue-agency/rogue'
import { ApplyPageContainer } from '../ApplyPageContainer'

export const Bottom: React.FCX = ({ className }) => {
  const { handleBackToInputStep, isLoading } = ApplyPageContainer.useContainer()
  return (
    <Wrapper className={className}>
      <ButtonGroup>
        <BackButton
          size="l1"
          text="入力画面に戻る"
          onClick={handleBackToInputStep}
          comlinkPushParams={{ action: 'back_to_input_step' }}
        />
        <ApplyButton
          size="l1"
          type="submit"
          loading={isLoading}
          text="この内容で申し込む"
          comlinkPushParams={{ action: 'click_apply_button' }}
        />
      </ButtonGroup>
    </Wrapper>
  )
}

const Wrapper = styled.div``
const ButtonGroup = styled.div`
  height: 76px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const BackButton = styled(TertiaryButton)``
const ApplyButton = styled(PrimaryButton)`
  margin-left: 20px;
`
