import { useState, useCallback } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'
import { useParams } from 'react-router-dom'
import { useCachedPromise } from '@blue-agency/front-state-management'
import { cacheKey } from '@/services/bffService'

export type RegisterEmailFormParams = {
  email: string
}

const validationSchema: yup.SchemaOf<RegisterEmailFormParams> = yup
  .object()
  .shape({
    email: yup
      .string()
      .required('入力してください')
      .email('メールアドレスを入力してください')
      .max(255, '255文字以内で入力してください'),
  })
  .defined()

export const useRegisterEmailPage = () => {
  const { promotionGuid } = useParams<{ promotionGuid?: string }>()
  if (!promotionGuid) throw new Error('promotionGuid not found')

  const orgService = OrganizerServiceContainer.useContainer()
  const promotion = useCachedPromise(
    cacheKey.getPromotionForRikunabiPackage({ promotionGuid }),
    () => orgService.getPromotionForRikunabiPackage({ promotionGuid })
  )

  const [isSubmitted, setIsSubmitted] = useState(false)
  const [registeredEmail, setRegisteredEmail] = useState('')

  const form = useForm<RegisterEmailFormParams>({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
  })

  const onSubmit = useCallback<SubmitHandler<RegisterEmailFormParams>>(
    async (data) => {
      await orgService.registerEmailForRikunabiPackage({
        promotionGuid,
        email: data.email,
      })
      setRegisteredEmail(data.email)
      setIsSubmitted(true)
    },
    [orgService, promotionGuid]
  )
  const handleSubmit = form.handleSubmit(onSubmit)

  const handleBack = useCallback(() => {
    setIsSubmitted(false)
  }, [])

  return {
    isSubmitted,
    handleSubmit,
    handleBack,
    form,
    registeredEmail,
    promotion,
  }
}
