import { MeetingManagerContainer } from '@/lib/react-interview-sdk/containers/MeetingManagerContainer'
import { bgEffectState } from '@/lib/react-interview-sdk/states/bg_effect'
import { useRecoilValue } from 'recoil'
import { useEffect } from 'react'
import { MeetingStatus } from '@blue-agency/interview-sdk-js'

export const useBgEffect = () => {
  const { meetingManager } = MeetingManagerContainer.useContainer()
  const bgEffect = useRecoilValue(bgEffectState)

  useEffect(() => {
    // 初期化時には別途渡す & 初期化前に呼ぶとエラーになるためガードし、変更時のためのみに使用
    if (meetingManager.meetingStatus !== MeetingStatus.Succeeded) return

    meetingManager.changeBackgroundEffect(bgEffect)
  }, [bgEffect, meetingManager])
}
