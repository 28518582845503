import React, { Suspense, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import { provide, CacheContainer } from '@blue-agency/front-state-management'
import { ToastContainer } from '@blue-agency/rogue'
import { ActivityIndicatorOverlay } from '@blue-agency/rogue'
import { INTERNAL_PATHS } from '@/services/urlService'
import { OrganizerRoutes } from '@/pages/OrganizerRoutes'
import { ParticipantRoutes } from '@/pages/ParticipantRoutes'
import * as Sentry from '@sentry/react'
import { MaintenancePage } from '@/pages/MaintenancePage'
import { BrowserContainer } from '@/containers/BrowserContainer'
import { NotFound } from '@/components/NotFound'
import { injectGoogleTagManager } from '@/googleTagManager'
import { injectZendeskChat } from '@/zendeskChat'
import { injectAlertBox } from '@/alertBox'
import { CommunicationErrorModalContainer } from '@/containers/CommunicationErrorModalContainer'
import { CommunicationErrorModal } from '@/components/CommunicationErrorModal'
import { cssBrowserSelector } from '@/cssBrowserSelector'
import { AudioContextContainer } from '@/containers/AudioContextContainer'
import { AlertboxHeightContainer } from '@/containers/AlertboxHeightContainer'
import { UnsupportedBrowserRoutes } from '@/pages/UnsupportedBrowserRoutes'
import { FallbackRender } from '@sentry/react/dist/errorboundary'
import { WindowSizeContainer } from '@/containers/WindowSizeContainer'
import { BeepContainer } from '@/containers/BeepContainer'

export const AppRoutes = provide(
  BeepContainer,
  AlertboxHeightContainer,
  AudioContextContainer,
  CommunicationErrorModalContainer,
  BrowserContainer,
  WindowSizeContainer,
  CacheContainer
)(() => {
  const windowSize = WindowSizeContainer.useContainer()
  const { isInternetExplorer } = BrowserContainer.useContainer()
  const {
    showCommunicationErrorModal,
    handleCommunicationErrorModalClose,
    seminar,
  } = CommunicationErrorModalContainer.useContainer()

  useEffect(() => {
    injectGoogleTagManager()
    injectZendeskChat()
    cssBrowserSelector(navigator.userAgent)
  }, [])

  useEffect(() => {
    if (isInternetExplorer) return
    injectAlertBox()
  }, [isInternetExplorer])

  if (isInternetExplorer) return <UnsupportedBrowserRoutes />
  return (
    <Sentry.ErrorBoundary fallback={fallback}>
      <Suspense fallback={<ActivityIndicatorOverlay />}>
        <Switch>
          <Route
            path={INTERNAL_PATHS.organizer.index}
            component={OrganizerRoutes}
          />
          <Route
            path={INTERNAL_PATHS.participant.index}
            component={ParticipantRoutes}
          />
          <Route
            exact
            path={INTERNAL_PATHS.maintenance}
            component={MaintenancePage}
          />
          <Route path="*" component={NotFound} />
        </Switch>
      </Suspense>
      <ToastContainer />

      <CommunicationErrorModal
        active={showCommunicationErrorModal}
        seminar={seminar}
        onClose={handleCommunicationErrorModalClose}
        windowSize={windowSize}
      />
    </Sentry.ErrorBoundary>
  )
})

const fallback: FallbackRender = (_fallbackProps) => {
  // NOTE: error detailsを利用する処理はここに書く
  // const err = fallbackProps.error
  // if (err instanceof CustomGrpcError) {
  //   const errorDetail = err.errorDetail
  // }

  return <NotFound />
}
