import React from 'react'
import styled from 'styled-components'
import { theme, PrimaryButton } from '@blue-agency/rogue'
import { H1 as _H1 } from '@/components/Heading'
import { sideNavWidth } from '@/components/SideNav'
import { RegisterContainer } from './hooks/RegisterContainer'

export const PageHeader: React.FCX = ({ className }) => {
  const { handleRegisterButtonClick } = RegisterContainer.useContainer()

  return (
    <Header className={className}>
      <H1>説明会動画一覧</H1>
      <HeaderRight>
        <RegisterButton
          text="説明会動画登録"
          size="l2"
          onClick={handleRegisterButtonClick}
          comlinkPushParams={{ action: 'click_register_presentation_button' }}
        />
      </HeaderRight>
    </Header>
  )
}

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
`

const headerPaddingLeftAndRight = 20
const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${headerPaddingLeftAndRight}px;
  background-color: ${theme.color.white[1]};
`

const RegisterButton = styled(PrimaryButton)`
  ${HeaderRight} & {
    margin-left: 20px;
  }
`

const H1 = styled(_H1)`
  min-width: ${sideNavWidth - headerPaddingLeftAndRight}px;
`
