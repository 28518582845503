import { atom } from 'recoil'
import { namespace } from './namespace'

export const audioOutputsState = atom<MediaDeviceInfo[]>({
  key: `${namespace}/audioOutputsState`,
  default: [],
})

export const selectedAudioOutputDeviceState = atom<string | null>({
  key: `${namespace}/selectedAudioOutputDeviceState`,
  default: null,
})
