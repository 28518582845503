import {
  CacheContainer,
  createContainer,
  useCachedPromise,
} from '@blue-agency/front-state-management'
import {
  ListHallsHall,
  OrganizerServiceContainer,
} from '@/containers/OrganizerServiceContainer'
import { cacheKey } from '@/services/bffService'
import { useState, useMemo, useCallback, useReducer, useEffect } from 'react'

const ITEMS_PER_PAGE = 20

export type HallListItem = ListHallsHall & {
  isSelected: boolean
}

const useHalls = () => {
  const { listHalls } = OrganizerServiceContainer.useContainer()
  const { deleteCache } = CacheContainer.useContainer()
  const [, forceUpdate] = useReducer((x) => x + 1, 0)

  const allHalls = useCachedPromise(cacheKey.listHalls(), listHalls)

  const [page, setPage] = useState(1)
  const [halls, setHalls] = useState<HallListItem[]>([])

  const count = useMemo(() => {
    const totalLength = allHalls.length
    return Math.ceil(totalLength / ITEMS_PER_PAGE)
  }, [allHalls])

  const selectedHalls = useMemo(() => {
    return halls.filter((hall) => hall.isSelected)
  }, [halls])

  useEffect(() => {
    const begin = (page - 1) * ITEMS_PER_PAGE
    const end = begin + ITEMS_PER_PAGE
    const paginated = allHalls.slice(begin, end)
    const halls = paginated.map<HallListItem>((hall) => mapHallListItem(hall))
    setHalls(halls)
  }, [page, allHalls])

  const reloadHalls = useCallback(() => {
    deleteCache(cacheKey.listHalls())
    forceUpdate()
  }, [deleteCache])

  const selectHall = useCallback((targetHall: HallListItem) => {
    setHalls((halls) =>
      halls.map((hall) => {
        if (hall.guid === targetHall.guid) {
          return { ...hall, isSelected: true }
        }
        return hall
      })
    )
  }, [])

  const selectAllHalls = useCallback(() => {
    setHalls((halls) =>
      halls.map((hall) => {
        return { ...hall, isSelected: true }
      })
    )
  }, [])

  const unselectHall = useCallback((targetHall: HallListItem) => {
    setHalls((halls) =>
      halls.map((hall) => {
        if (hall.guid === targetHall.guid) {
          return { ...hall, isSelected: false }
        }
        return hall
      })
    )
  }, [])

  const unselectAllHalls = useCallback(() => {
    setHalls((halls) =>
      halls.map((hall) => {
        return { ...hall, isSelected: false }
      })
    )
  }, [])

  return {
    page,
    setPage,
    count,
    halls,
    selectedHalls,
    reloadHalls,
    selectHall,
    selectAllHalls,
    unselectHall,
    unselectAllHalls,
  }
}

export const HallsContainer = createContainer(useHalls)

const mapHallListItem = (hall: ListHallsHall): HallListItem => ({
  ...hall,
  isSelected: false,
})
