import React from 'react'
import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'
import { provide } from '@blue-agency/front-state-management'
import styled from 'styled-components'
import { InterviewRecordingMeta } from './InterviewRecordingMeta'
import { InterviewRecordingPageContainer } from './hooks/useInterviewRecordingPage'
import { Layout } from '@/components/Layout'
import { useTitle } from '@/hooks/useTitle'
import { ExpiredContent } from './content/ExpiredContent'
import { EncodingContent } from './content/EncodingContent'
import { FailedContent } from './content/FailedContent'
import { useTokenExpires } from './hooks/useTokenExpires'
import { HeaderRightNode } from './HeaderRightNode'
import { useToken } from './hooks/useToken'
const wrapperWidth = 1024

export const InterviewRecordingPage = provide(OrganizerServiceContainer)(() => {
  const token = useToken()

  const { isExpired } = useTokenExpires(token)

  useTitle('Web面接 録画データ')

  if (isExpired) {
    return <ExpiredPage />
  } else {
    return <TokenAlivePage />
  }
})

const ExpiredPage: React.FCX = ({ className }) => {
  return (
    <Layout className={className}>
      <Wrapper>
        <ContentWrapper>
          <ExpiredContent />
        </ContentWrapper>
      </Wrapper>
    </Layout>
  )
}

const TokenAlivePage: React.FCX = provide(InterviewRecordingPageContainer)(
  ({ className }) => {
    const { status, videoPlayerUrl } =
      InterviewRecordingPageContainer.useContainer()

    return (
      <Layout className={className} rightNode={<HeaderRightNode />}>
        <Wrapper>
          {status === 'encoded' && (
            <PlayerWrapper>
              <iframe
                title="interview_recording"
                src={videoPlayerUrl}
                style={{ width: '100%', height: '100%' }}
                allowFullScreen
              />
            </PlayerWrapper>
          )}
          {status === 'failed' && (
            <ContentWrapper>
              <FailedContent />
            </ContentWrapper>
          )}
          {status === 'encoding' && (
            <ContentWrapper>
              <EncodingContent />
            </ContentWrapper>
          )}
          <InterviewRecordingBottom>
            <InterviewRecordingMeta />
          </InterviewRecordingBottom>
        </Wrapper>
      </Layout>
    )
  }
)

const Wrapper = styled.div`
  padding-top: 20px;
  margin: 0 auto;
  height: 100%;
  width: ${wrapperWidth}px;
`

const PlayerWrapper = styled.div`
  width: ${wrapperWidth}px;
  height: ${wrapperWidth * (9 / 16)}px;
`

const ContentWrapper = styled.div`
  ${Wrapper} & {
    margin-top: 23px;
    height: 80%;
    max-height: 512px;
  }
`

const InterviewRecordingBottom = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
`
