import { useCallback } from 'react'
import { useRecoilValue } from 'recoil'
import { MeetingManagerContainer } from '@/lib/react-interview-sdk/containers/MeetingManagerContainer'
import { audioInputsState } from '@/lib/react-interview-sdk/states'

import { storeDeviceIdInLS } from '@/lib/react-interview-sdk/utils/storeDeviceIdInLS'

export const useSelectAudioInput = () => {
  const { meetingManager } = MeetingManagerContainer.useContainer()
  const audioInputs = useRecoilValue(audioInputsState)

  const selectAudioInput = useCallback(
    async (deviceId: string) => {
      if (!meetingManager) return
      await meetingManager.selectAudioInputDevice(deviceId)
      storeDeviceIdInLS('audioinput', audioInputs, deviceId)
    },
    [meetingManager, audioInputs]
  )

  return selectAudioInput
}
