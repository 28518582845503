import { InterviewConfig } from '@/lib/react-interview-sdk/types'

import {
  useInterviewWebSocket,
  useInterviewWebSocketMute,
} from './useInterviewWebSocket'
import { useVideoInput } from './device/useVideoInput'
import { useAudioInput } from './device/useAudioInput'
import { useAudioOutput } from './device/useAudioOutput'
import { useRemoteVideoTile } from './useRemoteVideoTile'
import { useLocalVideo } from './useLocalVideo'
import { useStartMeeting } from './useStartMeeting'
import { useSoraConnectionConfig } from './useSoraConnectionConfig'
import { useInterviewStatus } from './useInterviewStatus'
import { useMainVideo } from './useMainVideo'
import { useContentShare } from './useContentShare'
import { useMicMute } from './useMicMute'
import { useBgEffect } from './useBgEffect'
import type { ComlinkPush } from '@/lib/react-interview-sdk/comlink'

export const useInitialize = (
  config: InterviewConfig,
  captureException: (e: Error) => void,
  comlinkPush: ComlinkPush
) => {
  useVideoInput()
  useAudioInput()
  useAudioOutput()

  useInterviewWebSocket(config.webSocketUrl)
  useInterviewWebSocketMute()

  useInterviewStatus()

  useMicMute()

  useMainVideo()
  useLocalVideo()
  useRemoteVideoTile()

  useBgEffect()

  const userPublisherConfig = useSoraConnectionConfig(
    config.soraConnectionConfigs.main
  )
  const screenSharingSubscriberConfig = useSoraConnectionConfig(
    config.soraConnectionConfigs.screenSharingRecv
  )
  const screenSharingPublisherConfig = useSoraConnectionConfig(
    config.soraConnectionConfigs.screenSharing
  )

  const { screenSharingPublisherRef } = useContentShare(
    screenSharingPublisherConfig
  )

  useStartMeeting({
    userPublisherConfig,
    screenSharingSubscriberConfig,
    screenSharingPublisherConfig,
    screenSharingPublisherRef,
    videoFrameRate: config.videoFrameRate,
    onConnectionCreated: config.onConnectionCreated,
    onChangedQuality: config.onChangedQuality,
    autoFocusedToMain: config.autoFocusedToMain,
    logRTCStatsEndpoint: config.logRTCStatsEndpoint,
    captureException,
    comlinkPush,
  })
}
