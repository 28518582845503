import { useCachedPromise } from '@blue-agency/front-state-management'
import { useCallback } from 'react'
import { storageKey } from '@/constants/storageKey'
import { ParticipantsServiceContainer } from '@/containers/ParticipantsServiceContainer'
import { cacheKey } from '@/services/bffService'

export const useSessionToken = () => {
  const { createInterviewSession } = ParticipantsServiceContainer.useContainer()
  const getSessionToken = useCallback(async (localStorageKey: string) => {
    const sessionTokenInLocalStorage = localStorage.getItem(localStorageKey)
    if (sessionTokenInLocalStorage) return sessionTokenInLocalStorage
    const createInterviewSessionRes = await createInterviewSession()
    const sessionToken = createInterviewSessionRes.getSessionToken()
    localStorage.setItem(localStorageKey, sessionToken)
    return sessionToken
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sessionToken = useCachedPromise(
    cacheKey.interviewSessionToken(),
    async () => {
      const [
        userSessionToken,
        screenSharingSessionToken,
        screenSharingRecvSessionToken,
      ] = await Promise.all([
        getSessionToken(storageKey.local.interviewSessionToken.user),
        getSessionToken(storageKey.local.interviewSessionToken.screenSharing),
        getSessionToken(
          storageKey.local.interviewSessionToken.screenSharingRecv
        ),
      ])
      return {
        user: userSessionToken,
        screenSharing: screenSharingSessionToken,
        screenSharingRecv: screenSharingRecvSessionToken,
      }
    }
  )

  return { sessionToken }
}
