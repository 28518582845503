import React from 'react'
import styled from 'styled-components'
import {
  Modal,
  Txt,
  TertiaryButton,
  WeakColorSeparator,
  PrimaryButton,
} from '@blue-agency/rogue'
import { EnterContainer } from './containers/EnterContainer'

const text = `配信準備を開始してよろしいですか？
「配信待機画面へ」を押すと、中断や停止はできません。`

type Props = {
  active: boolean
}

export const ConfirmModal: React.FCX<Props> = ({ className, active }) => {
  const { handleConfirmModalClose, toRoomPage } = EnterContainer.useContainer()
  return (
    <Modal
      className={className}
      active={active}
      title="配信準備の確認"
      onClose={handleConfirmModalClose}
    >
      <Wrapper>
        <Upper>
          <Txt>{text}</Txt>
        </Upper>
        <Separator />
        <Bottom>
          <ButtonGroup>
            <CancelButton
              text="キャンセル"
              onClick={handleConfirmModalClose}
              comlinkPushParams={{ action: 'close_seminar_confirm_modal' }}
            />
            <NextButton
              text="配信待機画面へ"
              onClick={toRoomPage}
              comlinkPushParams={{ action: 'to_seminar_room_page' }}
            />
          </ButtonGroup>
        </Bottom>
      </Wrapper>
    </Modal>
  )
}

const Wrapper = styled.div``
const Upper = styled.div`
  padding: 20px 16px;
`
const Separator = styled(WeakColorSeparator)`
  ${Wrapper} & {
    margin-top: 40px;
  }
`
const Bottom = styled.div`
  padding: 20px 16px;
`
const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const CancelButton = styled(TertiaryButton)`
  ${ButtonGroup} & {
    width: 48%;
  }
`
const NextButton = styled(PrimaryButton)`
  ${ButtonGroup} & {
    width: 48%;
  }
`
