import { useCallback, useEffect } from 'react'
import { AudioVideoObserver } from '@blue-agency/interview-sdk-js'
import { useSetRecoilState } from 'recoil'
import { remoteVideoTileState } from '@/lib/react-interview-sdk/states'
import { AttendeeMap, TileMap } from '@/lib/react-interview-sdk/types'
import { AudioVideoContainer } from '@/lib/react-interview-sdk/containers/AudioVideoContainer'
import { logger } from '../../logger'

function removeProperty<T extends TileMap | AttendeeMap>(
  obj: T,
  property: string
): T {
  const newState = Object.assign({}, obj)
  delete newState[property]
  return newState
}

export const useRemoteVideoTile = () => {
  const { audioVideo } = AudioVideoContainer.useContainer()
  const setRemoteVideoTile = useSetRecoilState(remoteVideoTileState)

  const update = useCallback(
    (tileId: number, attendeeId: string) => {
      logger.log(
        'useRemoteVideoTile:update. tileId=%d attendeeId=%s',
        tileId,
        attendeeId
      )
      const tileStr = tileId.toString()
      setRemoteVideoTile((prev) => {
        if (prev.tileIdToAttendeeId[tileStr]) {
          return prev
        }
        return {
          tiles: [...prev.tiles, tileId],
          tileIdToAttendeeId: {
            ...prev.tileIdToAttendeeId,
            [tileStr]: attendeeId,
          },
          attendeeIdToTileId: {
            ...prev.attendeeIdToTileId,
            [attendeeId]: tileId,
          },
        }
      })
    },
    [setRemoteVideoTile]
  )

  const remove = useCallback(
    (tileId: number) => {
      logger.log('useRemoteVideoTile:remove. tileId=%d', tileId)
      const tileStr = tileId.toString()

      setRemoteVideoTile((prev) => {
        const attendeeId = prev.tileIdToAttendeeId[tileStr]
        if (attendeeId) return prev

        return {
          tiles: prev.tiles.filter((id) => tileId !== id),
          tileIdToAttendeeId: removeProperty(prev.tileIdToAttendeeId, tileStr),
          attendeeIdToTileId: removeProperty(
            prev.attendeeIdToTileId,
            attendeeId
          ),
        }
      })
    },
    [setRemoteVideoTile]
  )

  const reset = useCallback(() => {
    setRemoteVideoTile({
      tiles: [],
      tileIdToAttendeeId: {},
      attendeeIdToTileId: {},
    })
  }, [setRemoteVideoTile])

  useEffect(() => {
    if (!audioVideo) {
      return
    }

    const observer: AudioVideoObserver = {
      videoTileDidUpdate: (tileState): void => {
        if (
          tileState.boundAttendeeId &&
          tileState.tileId &&
          !tileState.isContent &&
          !tileState.localTile
        ) {
          const { tileId, boundAttendeeId } = tileState
          update(tileId, boundAttendeeId)
          // MEMO: boundAttendeeId = soraClientId
        }
      },
      videoTileWasRemoved: (tileId): void => {
        remove(tileId)
      },
    }

    logger.log('audioVideo.addObserver remoteVideoTile')
    audioVideo.addObserver(observer)
    return () => audioVideo?.removeObserver(observer)
  }, [audioVideo, remove, update])

  useEffect(() => {
    if (!audioVideo) {
      return
    }

    return () => {
      reset()
    }
  }, [audioVideo, reset])
}

export const useResetRemoteVideoTile = () => {
  const setRemoteVideoTile = useSetRecoilState(remoteVideoTileState)
  return useCallback(() => {
    setRemoteVideoTile({
      tiles: [],
      tileIdToAttendeeId: {},
      attendeeIdToTileId: {},
    })
  }, [setRemoteVideoTile])
}
