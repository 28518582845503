import React from 'react'
import { ActivityIndicatorOverlay } from '@blue-agency/rogue'
import { provide } from '@blue-agency/front-state-management'
import { VerifyEmailContainer } from './useVerifyEmail'
import { NotFound } from '@/components/NotFound'

export const VerifyEmailPage: React.FCX = provide(VerifyEmailContainer)(() => {
  const { showNotFound } = VerifyEmailContainer.useContainer()
  return showNotFound ? <NotFound /> : <ActivityIndicatorOverlay />
})
