import styled from 'styled-components'
import { theme } from '@blue-agency/rogue'

export const H1 = styled.h1`
  margin: 0;
  font-size: ${theme.fontSize.xl};
  font-weight: 400;
`

export const H2 = styled.h2`
  margin: 0;
  font-size: ${theme.fontSize.l};
  font-weight: 400;
`

export const H3 = styled.h3`
  margin: 0;
  font-size: ${theme.fontSize.s};
`

export const H4 = styled.h4`
  margin: 0;
  font-size: ${theme.fontSize.xs};
`
