import React, { useCallback } from 'react'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import {
  theme,
  TertiaryButton,
  Icon,
  CheckBox,
  NewTabLink,
  Txt,
} from '@blue-agency/rogue'
import { TableBase } from '@/components/TableBase'
import { handleCopyUrlClick } from '@/utils'
import { formatDate } from '@/services/dateService'
import { useParams } from 'react-router-dom'
import { InterviewListItem, InterviewsContainer } from '../hooks/useInterviews'
import { toast } from '@blue-agency/rogue'
import { Tooltip } from '@/components/Tooltip'
import { useModal } from '@/hooks/useModal'
import { UpdateNameModal } from './UpdateNameModal'
import { EXTERNAL_URLS, getRelativePath } from '@/services/urlService'
import { TOOLTIP_IDS } from '@/constants/tooltip'

type Property =
  | 'select'
  | 'name'
  | 'interviewerUrl'
  | 'intervieweeUrl'
  | 'recordingUrl'
  | 'startedAt'
  | 'createdAt'
  | 'expiresAt'
  | 'padding'

type TableCellProps = {
  property: Property
  hover?: boolean
}

const width = {
  select: 50,
  name: 210,
  interviewerUrl: 220,
  intervieweeUrl: 120,
  recordingUrl: 120,
  startedAt: 120,
  createdAt: 120,
  expiresAt: 120,
  padding: 30,
}

const hostname = process.env.REACT_APP_FRONT_HOST
if (!hostname) throw new Error('hostname not found')

export const Content: React.FC = () => {
  const { interviews } = InterviewsContainer.useContainer()

  return (
    <Table hover>
      <TableHead>
        <TableRow>
          <Th property="select" />
          <Th property="createdAt">登録日時</Th>
          <Th property="expiresAt">URL有効期限</Th>
          <Th property="name">
            <span>管理用面接名</span>
            <QuestionIconWrapper
              data-tip
              data-for={TOOLTIP_IDS.interviewNameOnInterviewList}
            >
              <QuestionIcon />
            </QuestionIconWrapper>
            <Tooltip
              id={TOOLTIP_IDS.interviewNameOnInterviewList}
              arrowPosition="topRight"
            >
              <Txt size="s">
                社内管理用の面接名。応募者には表示されません。
                <FAQLinkWrapper>
                  <NewTabLink
                    href={EXTERNAL_URLS.help.organizer.interview.faq}
                    action="click_detail_link_in_interview_name_tooltip"
                  >
                    詳細はこちら
                  </NewTabLink>
                </FAQLinkWrapper>
              </Txt>
            </Tooltip>
          </Th>
          <Th property="interviewerUrl">
            <span>面接官URL</span>
            <QuestionIconWrapper
              data-tip
              data-for={TOOLTIP_IDS.interviewerUrlOnInterviewList}
            >
              <QuestionIcon />
            </QuestionIconWrapper>
            <Tooltip
              id={TOOLTIP_IDS.interviewerUrlOnInterviewList}
              arrowPosition="topRight"
            >
              <Txt size="s">
                面接官は、こちらのURLから面接を開始します。
                <br />
                複数面接など、1つの面接に複数の面接官が参加
                <br />
                する場合も、URLは共通となります。
                <FAQLinkWrapper>
                  <NewTabLink
                    href={EXTERNAL_URLS.help.organizer.interview.faq}
                    action="click_detail_link_in_interviewer_url_tooltip"
                  >
                    詳細はこちら
                  </NewTabLink>
                </FAQLinkWrapper>
              </Txt>
            </Tooltip>
          </Th>
          <Th property="intervieweeUrl">
            <span>応募者URL</span>
            <QuestionIconWrapper
              data-tip
              data-for={TOOLTIP_IDS.intervieweeUrlOnInterviewList}
            >
              <QuestionIcon />
            </QuestionIconWrapper>
            <Tooltip
              id={TOOLTIP_IDS.intervieweeUrlOnInterviewList}
              arrowPosition="topRight"
            >
              <Txt size="s">
                応募者は、こちらのURLから面接を開始します。
                <br />
                複数面接など、1つの面接に複数の応募者が参加
                <br />
                する場合も、URLは共通となります。
                <FAQLinkWrapper>
                  <NewTabLink
                    href={EXTERNAL_URLS.help.organizer.interview.faq}
                    action="click_detail_link_in_interviewee_url_tooltip"
                  >
                    詳細はこちら
                  </NewTabLink>
                </FAQLinkWrapper>
              </Txt>
            </Tooltip>
          </Th>
          <Th property="recordingUrl">録画データ</Th>
          <Th property="startedAt">開始日時</Th>
          <Th property="padding" />
        </TableRow>
      </TableHead>
      <TableBody>
        {interviews.map((interview) => (
          <TabelBody key={interview.guid} interview={interview} />
        ))}
      </TableBody>
    </Table>
  )
}

const TabelBody: React.FCX<{
  interview: InterviewListItem
}> = ({ interview }) => {
  const { token } = useParams<{ token?: string }>()
  if (!token) throw new Error('token not found')

  const { selectInterview, unselectInterview } =
    InterviewsContainer.useContainer()
  const updateNameModal = useModal()

  const {
    guid,
    name,
    interviewerUrl,
    intervieweeUrl,
    startedAt,
    createdAt,
    expiresAt,
    recordingUrl,
    active,
  } = interview

  const handleInterviewerUrlCopy = useCallback(() => {
    handleCopyUrlClick(window.location.origin + getRelativePath(interviewerUrl))
    toast('面接官URLをコピーしました。')
  }, [interviewerUrl])

  const handleIntervieweeUrlCopy = useCallback(() => {
    handleCopyUrlClick(window.location.origin + getRelativePath(intervieweeUrl))
    toast('応募者URLをコピーしました。')
  }, [intervieweeUrl])

  const toggleCheck = useCallback(() => {
    if (interview.isSelected) {
      unselectInterview(interview)
    } else {
      selectInterview(interview)
    }
  }, [interview, selectInterview, unselectInterview])

  return (
    <>
      <TableRow active={active}>
        <Td property="select">
          <CheckBox
            checked={interview.isSelected}
            onChange={toggleCheck}
            size={16}
          />
        </Td>
        <Td property="createdAt">{createdAt && formatDate(createdAt)}</Td>
        <Td property="expiresAt">{expiresAt && formatDate(expiresAt)}</Td>
        <Td property="name" onClick={updateNameModal.open}>
          {name}
        </Td>
        <Td property="interviewerUrl">
          <TertiaryButton
            text="URLコピー"
            size="m2"
            disabled={!active}
            onClick={handleInterviewerUrlCopy}
            comlinkPushParams={{ action: 'copy_interviewer_url' }}
          />
          <OpenInterviewerPageButton interview={interview} />
        </Td>
        <Td property="intervieweeUrl">
          <TertiaryButton
            text="URLコピー"
            size="m2"
            disabled={!active}
            onClick={handleIntervieweeUrlCopy}
            comlinkPushParams={{ action: 'copy_interviewee_url' }}
          />
        </Td>
        <Td property="recordingUrl">
          {!!recordingUrl && (
            <NewTabLink
              href={getRelativePath(recordingUrl)}
              hideIcon
              action="open_interview_recording_link"
            >
              <TertiaryButton
                text="再生"
                size="m2"
                comlinkPushParams={{
                  action: 'click_open_interview_recording_button',
                }}
              />
            </NewTabLink>
          )}
        </Td>
        <Td property="startedAt">{startedAt && formatDate(startedAt)}</Td>
        <Td property="padding" />
      </TableRow>
      {updateNameModal.active && (
        <UpdateNameModal
          interviewGuid={guid}
          currentName={name}
          onClose={updateNameModal.close}
          onOpen={updateNameModal.open}
        />
      )}
    </>
  )
}

const OpenInterviewerPageButton: React.FCX<{
  interview: InterviewListItem
}> = ({ interview }) => {
  const { active, interviewerUrl } = interview
  if (active) {
    return (
      <NewTabLink
        href={getRelativePath(interviewerUrl)}
        hideIcon
        action="open_interviewer_page"
      >
        <TertiaryButton
          text="開く"
          size="m2"
          comlinkPushParams={{
            action: 'click_open_interviewer_page_button',
          }}
        />
      </NewTabLink>
    )
  }
  return (
    <TertiaryButton
      text="開く"
      disabled
      size="m2"
      comlinkPushParams={{
        action: 'click_open_interviewer_page_button',
      }}
    />
  )
}

const Table = styled(TableBase)`
  th:last-of-type {
    justify-content: flex-start;
    padding-left: ${width.padding}px;
  }
  width: 100%;
  min-width: ${Object.values(width).reduce((acc, val) => acc + val)}px;
`

const TableHead = styled.thead`
  position: sticky;
  top: 0px;
  z-index: 100;
`

const TableBody = styled.tbody``

type TableRowProps = {
  active?: boolean
}

const TableRow = styled.tr<TableRowProps>`
  display: flex;
  align-items: center;

  ${({ active }) =>
    !active &&
    css`
      background: ${theme.color.gray[4]};
    `}
`

const Th = styled.th<TableCellProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  ::-webkit-scrollbar {
    display: none;
  }
  ${({ property }) => CSS[property]}
`
const Td = styled.td<TableCellProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ::-webkit-scrollbar {
    display: none;
  }
  height: 70px !important;
  ${({ property }) => CSS[property]}
  ${({ hover }) =>
    hover &&
    css`
      &:hover {
        background: ${theme.color.gray[4]};
      }
    `}
`

const CSS: Record<Property, FlattenSimpleInterpolation> = {
  select: css`
    display: flex;
    justify-content: center;
    flex: 0 0 ${width.select}px;
  `,
  name: css`
    display: flex;
    align-items: center;
    flex: 0 0 ${width.name}px;

    ${TableHead} && {
      > *:not(:last-child) {
        margin-right: 8px;
      }
    }

    ${TableBody} && {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  `,
  interviewerUrl: css`
    display: flex;
    flex: 0 0 ${width.interviewerUrl}px;
    ${TableHead} && {
      justify-content: center;

      > *:not(:last-child) {
        margin-right: 8px;
      }
    }
    ${TableBody} && {
      justify-content: space-between;
    }
  `,
  intervieweeUrl: css`
    display: flex;
    justify-content: center;
    flex: 0 0 ${width.intervieweeUrl}px;

    ${TableHead} && {
      justify-content: center;

      > *:not(:last-child) {
        margin-right: 8px;
      }
    }
  `,
  recordingUrl: css`
    display: flex;
    justify-content: center;
    flex: 0 0 ${width.recordingUrl}px;
  `,
  startedAt: css`
    flex: 0 0 ${width.startedAt}px;
  `,
  createdAt: css`
    flex: 0 0 ${width.createdAt}px;
  `,
  expiresAt: css`
    flex: 0 0 ${width.expiresAt}px;
  `,
  padding: css`
    flex: 1 0 auto;
  `,
}
const QuestionIconWrapper = styled.div`
  cursor: pointer;
  display: flex;
`
const QuestionIcon = styled(Icon).attrs({
  name: 'question-bg-white',
  size: 'l',
})`
  color: ${theme.color.navy[1]};
`
const FAQLinkWrapper = styled.span`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`
