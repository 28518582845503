import React from 'react'
import { Chat } from '@/components/Chat'
import { MessagesContainer } from './containers/MessagesContainer'
import { Icon } from '@blue-agency/rogue'
import { WindowSizeContainer } from '@/containers/WindowSizeContainer'
import { isPc } from '@/utils'

export const Messages: React.FCX = ({ className }) => {
  const { connected, messages, handleSend } = MessagesContainer.useContainer()
  const { isPortrait, width } = WindowSizeContainer.useContainer()
  const isSp = !isPc(width)

  return (
    <Chat
      className={className}
      title="質問コーナー"
      titleIcon={<Icon name="qa" />}
      placeholder={
        connected
          ? '企業への質問を入力してください'
          : '準備中です、しばらくお待ちください'
      }
      chatFontSize={isSp ? 's' : 'm'}
      messages={messages}
      connected={connected}
      onSend={handleSend}
      isPortrait={isPortrait}
      isSp={isSp}
    />
  )
}
