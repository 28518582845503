import React from 'react'
import styled from 'styled-components'
import { theme, PrimaryButton, SecondaryButton } from '@blue-agency/rogue'
import { H1 } from '@/components/Heading'
import { RegisterContainer } from '../hooks/useRegister'
import { ListDownloadModal } from './ListDownloadModal'
import { useModal } from '@/hooks/useModal'

export const PageHeader: React.FCX = ({ className }) => {
  const { handleRegisterButtonClick } = RegisterContainer.useContainer()
  const listDownloadModal = useModal()

  return (
    <Header className={className}>
      <H1>Web面接一覧</H1>
      <HeaderRight>
        <SecondaryButton
          text="リストダウンロード"
          size="l2"
          onClick={listDownloadModal.open}
          comlinkPushParams={{ action: 'download_interviews_list' }}
        />
        <RegisterButton
          text="Web面接登録"
          size="l2"
          onClick={handleRegisterButtonClick}
          comlinkPushParams={{ action: 'open_register_interview_modal' }}
        />
      </HeaderRight>
      <ListDownloadModal
        active={listDownloadModal.active}
        onClose={listDownloadModal.close}
        onOpen={listDownloadModal.open}
      />
    </Header>
  )
}

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
`

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  background-color: ${theme.color.white[1]};
`

const RegisterButton = styled(PrimaryButton)`
  ${HeaderRight} & {
    margin-left: 20px;
  }
`
