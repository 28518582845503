import React from 'react'
import styled from 'styled-components'
import { ApplyPageContainer } from '../../ApplyPageContainer'
import { Dropdown } from '@blue-agency/rogue'
import { placeholder } from '@/constants/placeholder'
import { newHiresNumberOptions } from '../../options'
import { OrganizerFormItem } from '@/components/OrganizerFormItem'

export const NewHiresSection: React.FCX = ({ className }) => {
  const { form } = ApplyPageContainer.useContainer()
  return (
    <Wrapper className={className}>
      <OrganizerFormItem
        title="採用情報"
        label="採用予定数"
        required
        inputNode={
          <Dropdown
            ref={form.register}
            name="newHiresNumberId"
            size="l"
            options={newHiresNumberOptions}
            placeholder={placeholder.select}
            allowEmpty
            onBlurError={form.errors.newHiresNumberId?.message}
          />
        }
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 20px;
`
