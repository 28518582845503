import {
  createContainer,
  useCachedPromise,
  CacheContainer,
} from '@blue-agency/front-state-management'
import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'
import { useHistory, useParams } from 'react-router-dom'
import { useCallback, useEffect, useState, useMemo } from 'react'
import { INTERNAL_PATHS, fillParams } from '@/services/urlService'
import { cacheKey } from '@/services/bffService'
import { GetSessionStatusResponse } from '@blue-agency/proton/web/v2/yashiori_bff/yashiori_bff_service_pb'
import { CommunicationErrorModalContainer } from '@/containers/CommunicationErrorModalContainer'
import { EnterHallContainer } from '../../containers/EnterHallContainer'
import { comlinkPush } from '@/comlink'
const { Status } = GetSessionStatusResponse

const POLLING_INTERVAL_SECOND = 2

const useEnter = () => {
  const {
    setupSession,
    startSession,
    finishSession,
    getSessionStatus,
    restartSession,
  } = OrganizerServiceContainer.useContainer()
  const { handleCommunicationErrorModalOpen } =
    CommunicationErrorModalContainer.useContainer()
  const { deleteCache } = CacheContainer.useContainer()
  const { entranceGuid, token } = useParams<{
    entranceGuid?: string
    token?: string
  }>()
  if (!entranceGuid) throw new Error('entranceGuid not found')
  if (!token) throw new Error('token not found')
  const history = useHistory()
  const { enterHallRes } = EnterHallContainer.useContainer()

  const { hallGuid, presenterToken } = useMemo(
    () => ({
      hallGuid: enterHallRes.getHallGuid(),
      presenterToken: enterHallRes.getPresenterToken(),
    }),
    [enterHallRes]
  )

  const getSessionStatusRes = useCachedPromise(
    cacheKey.getSessionStatus(),
    () => getSessionStatus({ hallGuid, presenterToken })
  )

  const initialStartTime = useMemo(() => {
    const startTimestamp = enterHallRes.getStartTime()
    if (!startTimestamp) return null
    return startTimestamp.toDate()
  }, [enterHallRes])

  const initialCurrentTime = useMemo(() => {
    const currentTimestamp = enterHallRes.getCurrentTime()
    if (!currentTimestamp) return null
    return currentTimestamp.toDate()
  }, [enterHallRes])

  const [status, setStatus] = useState(getSessionStatusRes.status)
  const [showFinishModal, setShowFinishModal] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [showBeforeStartModal, setShowBeforeStartModal] = useState(
    status === Status.NOT_READY || status === Status.IS_READY
  )
  const [startTime, setStartTime] = useState(initialStartTime)
  const [currentTime, setCurrentTime] = useState(initialCurrentTime)
  const [isPresenterPublished, setIsPresenterPublished] = useState(false)

  const [presenterSora, setPresenterSora] = useState(
    getSessionStatusRes.presenterSora
  )
  const [screenSora, setScreenSora] = useState(getSessionStatusRes.screenSora)

  const maxSeconds = useMemo(() => {
    const maximumDuration = enterHallRes.getMaximumDuration()
    if (!maximumDuration) throw new Error('maximumDuration not found')
    return maximumDuration.getSeconds()
  }, [enterHallRes])

  useEffect(() => {
    if (status !== Status.RUNNING || !isPresenterPublished) return
    comlinkPush({
      type: 'system_activity',
      action: 'restart_session',
      targetName: 'yashiori_hall.guid',
      targetIdStr: hallGuid,
    })
    restartSession({ hallGuid, presenterToken })
  }, [status, hallGuid, presenterToken, restartSession, isPresenterPublished])

  useEffect(() => {
    if (status === Status.NOT_READY) {
      ;(async () => {
        comlinkPush({
          type: 'system_activity',
          action: 'setup_session',
          targetName: 'yashiori_hall.guid',
          targetIdStr: enterHallRes.getHallGuid(),
        })
        try {
          await setupSession({ hallGuid, presenterToken })
        } catch (e) {
          setShowErrorModal(true)
          return
        }
      })()
    }

    const timer = setInterval(async () => {
      const res = await getSessionStatus({ hallGuid, presenterToken }).catch(
        (e: Error) => {
          clearInterval(timer)
          throw e
        }
      )

      setStatus(res.status)

      if (res.presenterSora) {
        const { hostname, channelId } = res.presenterSora
        setPresenterSora((prev) => {
          if (
            !prev ||
            hostname !== prev.hostname ||
            channelId !== prev.channelId
          ) {
            return res.presenterSora
          }
          return prev
        })
      }
      if (res.screenSora) {
        const { hostname, channelId } = res.screenSora
        setScreenSora((prev) => {
          if (
            !prev ||
            hostname !== prev.hostname ||
            channelId !== prev.channelId
          ) {
            return res.screenSora
          }
          return prev
        })
      }
    }, POLLING_INTERVAL_SECOND * 1000)
    return () => {
      clearInterval(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    return () => {
      deleteCache(cacheKey.enterHall())
      deleteCache(cacheKey.getSessionStatus())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleStart = useCallback(async () => {
    comlinkPush({
      type: 'manual_activity',
      action: 'start_session',
      targetName: 'yashiori_hall.guid',
      targetIdStr: enterHallRes.getHallGuid(),
    })

    if (!isPresenterPublished) {
      alert('カメラを許可し、映像の配信が確認できてから開始してください')
      return
    }

    const startSessionRes = await startSession({
      hallGuid,
      presenterToken,
    }).catch((err) => {
      setShowBeforeStartModal(false)
      const callback = () => setShowBeforeStartModal(true)
      handleCommunicationErrorModalOpen({ callback, seminar: true })
      throw err
    })

    const startTimestamp = startSessionRes.getStartTime()
    const currentTimestamp = startSessionRes.getCurrentTime()
    setStartTime(startTimestamp!.toDate())
    setCurrentTime(currentTimestamp!.toDate())

    setShowBeforeStartModal(false)
    setStatus(Status.RUNNING)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPresenterPublished])

  const handleFinishModalOpen = useCallback(() => {
    setShowFinishModal(true)
  }, [])

  const handleFinishModalClose = useCallback(() => {
    setShowFinishModal(false)
  }, [])

  const handleFinish = useCallback(async () => {
    comlinkPush({
      type: 'manual_activity',
      action: 'finish_session',
      targetName: 'hallGuid',
      targetIdStr: enterHallRes.getHallGuid(),
    })

    await finishSession({ hallGuid, presenterToken }).catch((err) => {
      setShowFinishModal(false)
      const callback = () => setShowFinishModal(true)
      handleCommunicationErrorModalOpen({ callback, seminar: true })
      throw err
    })

    history.push(
      fillParams({
        path: INTERNAL_PATHS.organizer.my.halls.entrance.finish,
        params: {
          token,
          entranceGuid,
        },
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOvertime = useCallback(async () => {
    comlinkPush({
      type: 'system_activity',
      action: 'over_session_time_limit',
      targetName: 'hallGuid',
      targetIdStr: enterHallRes.getHallGuid(),
    })

    await finishSession({ hallGuid, presenterToken })

    history.push(
      fillParams({
        path: INTERNAL_PATHS.organizer.my.halls.entrance.overtime,
        params: {
          token,
          entranceGuid,
        },
      })
    )
  }, [
    enterHallRes,
    hallGuid,
    presenterToken,
    entranceGuid,
    token,
    history,
    finishSession,
  ])

  return {
    enterHallRes,
    handleFinish,
    handleStart,
    status,
    presenterSora,
    screenSora,
    startTime,
    showFinishModal,
    handleFinishModalOpen,
    handleFinishModalClose,
    showErrorModal,
    showBeforeStartModal,
    setIsPresenterPublished,
    maxSeconds,
    currentTime,
    handleOvertime,
  }
}

export const EnterContainer = createContainer(useEnter)
