import React from 'react'
import styled from 'styled-components'
import { InputText as _InputText, Txt } from '@blue-agency/rogue'
import { UseFormMethods } from 'react-hook-form'
import { SignupAsOrganizerForm } from '@/components/OrganizerForm/types'
import { SignupContainer } from '../../hooks/useSignup'
import { ThousandSeparatedNum } from '@/components/ThousandSeparatedNum'
import { OrganizerFormItem } from '@/components/OrganizerFormItem'

type Props = {
  formContext: UseFormMethods<SignupAsOrganizerForm>
}

export const PcInputBodyPlan: React.FCX<Props> = ({ formContext }) => {
  const { promotion } = SignupContainer.useContainer()
  return (
    <Block>
      <ItemGroup>
        <OrganizerFormItem
          title="申込内容"
          label="利用プラン"
          inputNode={<Txt size="s">{promotion.plan.name}</Txt>}
        />
        <OrganizerFormItem
          label="ご契約期間"
          inputNode={<Txt size="s">{promotion.planTerm}</Txt>}
        />
        <OrganizerFormItem
          label="合計金額"
          inputNode={
            <TotalSumTxt size="s">
              <ThousandSeparatedNum number={promotion.billingAmount} />円
            </TotalSumTxt>
          }
        />
        <OrganizerFormItem
          label="ご紹介元"
          inputNode={
            <InputText
              name="referrer"
              size="ll"
              placeholder="他の企業様からのご紹介の場合、入力してください"
              ref={formContext.register}
              onBlurError={formContext.errors.referrer?.message}
            />
          }
        />
        <OrganizerFormItem
          label="クーポンコード"
          inputNode={
            <InputText
              name="couponCode"
              size="ll"
              placeholder="お持ちの場合は、入力してください"
              ref={formContext.register}
              onBlurError={formContext.errors.couponCode?.message}
            />
          }
        />
      </ItemGroup>
    </Block>
  )
}

const Block = styled.div`
  &:nth-of-type(n + 2) {
    margin-top: 0;
  }
  padding: 20px;
`
const ItemGroup = styled.div``
const InputText = styled(_InputText)``
const TotalSumTxt = styled(Txt)``
