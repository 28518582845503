import React, { useEffect } from 'react'
import { provide } from '@blue-agency/front-state-management'
import { EnterContainer } from './containers/EnterContainer'
import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'
import { Room } from './Room'
import { Redirect, useParams } from 'react-router-dom'
import { INTERNAL_PATHS, fillParams } from '@/services/urlService'
import { GetSessionStatusResponse } from '@blue-agency/proton/web/v2/yashiori_bff/yashiori_bff_service_pb'
import { useTitle } from '@/hooks/useTitle'
import { useOnlineStatus } from '@/hooks/useOnlineStatus'
import { useOfflineComlinkQueue } from '@/hooks/useOfflineComlinkQueue'

const { Status } = GetSessionStatusResponse

export const RoomPage = provide(
  EnterContainer,
  OrganizerServiceContainer
)(() => {
  const { status, enterHallRes } = EnterContainer.useContainer()
  const { token, entranceGuid } = useParams<{
    token?: string
    entranceGuid?: string
  }>()
  if (!entranceGuid) throw new Error('entranceGuid not found')

  const title = enterHallRes.getSessionName()
  useTitle(title)

  const { isOffline } = useOnlineStatus()
  const { enqueue: comlinkPush } = useOfflineComlinkQueue()

  useEffect(() => {
    if (isOffline) {
      comlinkPush({
        type: 'system_activity',
        action: 'disconnected_from_internet',
        targetName: 'hallGuid',
        targetIdStr: enterHallRes.getHallGuid(),
      })
    }
  }, [enterHallRes, isOffline, comlinkPush])

  if (!token) throw new Error('token not found')
  if (status === Status.FINISHED)
    return (
      <Redirect
        to={fillParams({
          path: INTERNAL_PATHS.organizer.my.halls.entrance.finish,
          params: { token, entranceGuid },
        })}
      />
    )
  return <Room />
})
