import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Layout } from '@/components/Layout'
import { EXTERNAL_URLS } from '@/services/urlService'
import { getBrowserClientUuid } from '@/services/browserClientUuidService'
import { useTitle } from '@/hooks/useTitle'
import { Txt, theme, A, HelpLinkIcon } from '@blue-agency/rogue'
import { buttonResetStyle } from '@/styles/buttonResetStyle'
import { useParams } from 'react-router-dom'

export const FinishPage: React.FCX = ({ className }) => {
  const { entranceGuid } = useParams<{ entranceGuid?: string }>()
  if (!entranceGuid) throw new Error('entranceGuid not found')

  useTitle('終了ページ')

  const openSurveyLink = useCallback(async () => {
    const browserClientUuid = await getBrowserClientUuid()
    const params = `${entranceGuid}+${browserClientUuid}+lite_seminar`
    window.location.href = EXTERNAL_URLS.survey.seminarParticipant + params
  }, [entranceGuid])

  return (
    <Layout
      className={className}
      rightNode={
        <HelpLinkIcon
          href={EXTERNAL_URLS.help.participants.seminar}
          action="open_participant_seminar_help_page"
        />
      }
    >
      <Wrapper>
        <Txt>終了しました。お疲れ様でした。</Txt>
        <Card>
          <Txt size="l" bold color={theme.color.white[1]}>
            サービス品質向上のため
            <br />
            ご意見をお聞かせください
          </Txt>
          <A
            onClick={openSurveyLink}
            comlinkPushParams={{
              action: 'open_participant_seminar_survey_page',
            }}
          >
            <AnswerButton>アンケートに回答する</AnswerButton>
          </A>
          <PresentTxt size="s" color={theme.color.white[1]}>
            回答いただいた方の中から、抽選で毎月5名様に
            <br />
            <Bold>Amazonギフト券1,000円分</Bold>をプレゼント中！
          </PresentTxt>
        </Card>
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px 20px;
`
const Card = styled.div`
  margin-top: 54px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 360px;
  padding: 24px;
  opacity: 0.85;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  color: ${theme.color.white[1]};
  background-color: ${theme.color.green[3]};
`
const AnswerButton = styled.button`
  margin-top: 20px;
  ${buttonResetStyle}
  width: 250px;
  height: 40px;
  border-radius: 24px;
  font-weight: bold;
  font-size: ${theme.fontSize.m};
  background-color: ${theme.color.white[1]};
  color: ${theme.color.green[4]};
`
const PresentTxt = styled(Txt)`
  margin-top: 10px;
  text-align: center;
`
const Bold = styled.span`
  font-weight: bold;
`
