import React, { useMemo } from 'react'
import { PresentationLayout } from '@/components/PresentationLayout'
import { useBeforeUnload } from '@blue-agency/react-utils'
import { provide } from '@blue-agency/front-state-management'
import { PresenterVideoContainer } from './hooks/PresenterVideoContainer'
import { ScreenShareVideoContainer } from './hooks/ScreenShareVideoContainer'
import { WindowSizeContainer } from '@/containers/WindowSizeContainer'
import { Layout } from '@/components/Layout'
import { headerHeight } from '@/components/Header'
import { Right } from './Right'
import { ErrorModal } from './ErrorModal'
import { BeforeStartModal } from './BeforeStartModal'
import { AlertboxHeightContainer } from '@/containers/AlertboxHeightContainer'
import { RecordingContainer } from './hooks/RecordingContainer'
import { PresenterWithOverlay } from './PresenterWithOverlay'
import { ScreenShareWithOverlay } from './ScreenShareWithOverlay'

export const Recording: React.FCX = provide(
  PresenterVideoContainer,
  ScreenShareVideoContainer
)(({ className }) => {
  const { errorModal, beforeStartModal, isShownOverlay } =
    RecordingContainer.useContainer()
  const { presenterVideoRef, isCameraOff } =
    PresenterVideoContainer.useContainer()
  const { screenShareVideoRef, screenSharing, isRecordingScreenSharing } =
    ScreenShareVideoContainer.useContainer()
  const { height, width } = WindowSizeContainer.useContainer()
  const alertboxHeight = AlertboxHeightContainer.useContainer()
  const hallHeight = useMemo(
    () => height - headerHeight - alertboxHeight,
    [height, alertboxHeight]
  )
  useBeforeUnload('ページを離れます。よろしいですか？')

  return (
    <>
      <Layout className={className} rightNode={<Right />}>
        <PresentationLayout
          className={className}
          height={hallHeight}
          width={width}
          presenterVideo={
            <PresenterWithOverlay
              showOverlay={isShownOverlay}
              ref={presenterVideoRef}
              presenterProps={{
                mirrorEffect: !isCameraOff,
              }}
            />
          }
          screenShareVideo={
            <ScreenShareWithOverlay
              showOverlay={!isRecordingScreenSharing}
              ref={screenShareVideoRef}
            />
          }
          screenSharing={screenSharing}
        />
      </Layout>
      <ErrorModal active={errorModal.active} />
      <BeforeStartModal active={beforeStartModal.active} />
    </>
  )
})
