import React from 'react'
import { Icon, theme, Txt } from '@blue-agency/rogue'
import styled from 'styled-components'
import { useParticipantCount } from '@/pages/OrganizerRoutes/MyRoutes/HallsRoutes/EntranceRoutes/RoomPage/containers/ParticipantCountContainer'

export const ParticipantsCount: React.FCX = ({ className }) => {
  const { hallJoinedCount } = useParticipantCount()

  return (
    <Title className={className}>
      <LeftArea>
        <Icon name="presenter" />
        <TitleTxt bold size="m">
          参加者数(合計)
        </TitleTxt>
      </LeftArea>
      <RightArea>
        <CountTxt size="xxl">{hallJoinedCount}</CountTxt>
        <PeopleTxt size="s">人</PeopleTxt>
      </RightArea>
    </Title>
  )
}

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  padding: 8px;
  border-bottom: 1px solid ${theme.color.navy[2]};
`

const TitleTxt = styled(Txt)`
  ${Title} & {
    margin-left: 10px;
  }
`

const CountTxt = styled(Txt)`
  margin-left: 3px;
  color: ${theme.color.green[4]};
`

const PeopleTxt = styled(Txt)`
  margin-top: 12px;
  margin-right: 15px;
`

const LeftArea = styled.div`
  align-items: center;
  display: flex;
`
const RightArea = styled.div`
  display: flex;
`
