import { css } from 'styled-components'

export const overflowYScrollStyle = css`
  overflow-y: scroll;

  -ms-overflow-style: none; // IE, Edge 用
  scrollbar-width: none; // Firefox 用

  &::-webkit-scrollbar {
    display: none; // Chrome, Safari 用
  }
`
