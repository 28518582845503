import React, { useMemo } from 'react'
import { UpgradePlanContainer } from '../hooks/useUpgradePlan'
import { Plan } from '@blue-agency/proton/web/v2/yashiori_bff/plan_data_pb'
import { getPlanTerm } from '@/utils'
import { price } from '@/constants'
import { ConfirmBody } from '@/components/OrganizerForm/Pc/ConfirmBody'

export const Confirm: React.FCX = ({ className }) => {
  const { form } = UpgradePlanContainer.useContainer()
  const planInfo = useMemo(() => {
    const now = new Date()
    switch (form.plan) {
      case Plan.Id.BASIC.toString():
        return {
          label: 'ベーシックプラン',
          term: getPlanTerm(Plan.Id.BASIC.toString(), now),
          price: price.basic,
          couponCode: form.couponCode,
        }
      default:
        throw new Error('invalid plan')
    }
  }, [form.plan, form.couponCode])

  return (
    <ConfirmBody
      className={className}
      form={form}
      planInfo={planInfo}
      hideReferrer
      hideEmpoyeesNumber
      hideNewHiresNumber
    />
  )
}
