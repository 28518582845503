import React from 'react'
import { FormStep } from '@/components/OrganizerForm/types'
import { Input } from './Input'
import { Confirm } from './Confirm'
import { Completed } from './Completed'

type Props = {
  step: FormStep
}

export const Body: React.FCX<Props> = ({ className, step }) => {
  switch (step) {
    case 'input':
      return <Input className={className} />
    case 'confirm':
      return <Confirm className={className} />
    case 'completed':
      return <Completed className={className} />
  }
}
