import { assertNever } from '@/utils'
import { theme } from '@blue-agency/rogue'
import React, { useCallback } from 'react'
import ReactTooltip from 'react-tooltip'
import styled, { css } from 'styled-components'

export { TOOLTIP_IDS } from './ids'

type ArrowPosition = 'topLeft' | 'topRight'

type Props = {
  id: string
  arrowPosition: ArrowPosition
  children: React.ReactNode
}

export const Tooltip: React.FCX<Props> = (props) => {
  const getPosition = useCallback<OverridePositionFunc>(
    (position, event, __, refNode) => {
      if (!refNode) {
        return { top: 0, left: 0 }
      }

      // NOTE: 現状、ツールチップの表示位置は下のみ
      // https://github.com/blue-agency/react-tooltip/pull/1
      // にあるように、react-tooltipが表示位置を調整してしまうケースがあるので下に固定する
      ;['top', 'left', 'right'].forEach((p) => {
        refNode.classList.remove(`place-${p}`)
      })
      refNode.classList.add('place-bottom')

      const target = event.target as HTMLElement
      const targetRect = target.getBoundingClientRect()

      if (props.arrowPosition === 'topLeft') {
        return {
          top: targetRect.bottom,
          left: targetRect.left - 46 + target.offsetWidth / 2,
        }
      }
      if (props.arrowPosition === 'topRight') {
        return {
          top: targetRect.bottom,
          left: targetRect.right - refNode.offsetWidth + 37,
        }
      }
      return assertNever(props.arrowPosition)
    },
    [props.arrowPosition]
  )

  return (
    <StyledReactTooltip
      className={props.className}
      id={props.id}
      effect="solid"
      place="bottom"
      overridePosition={getPosition}
      delayShow={80}
      // NOTE: delayUpdate, delayHide は Tooltip にマウスオーバーできるようにするための設定
      //   https://wwayne.github.io/react-tooltip/ の Demonstrate ~~ のところ
      delayUpdate={500}
      delayHide={500}
      // ReactTooltip とは関係なく、独自定義した props
      myArrowPosition={props.arrowPosition}
      arrowColor={theme.color.gray[5]}
      backgroundColor={theme.color.gray[5]}
    >
      {props.children}
    </StyledReactTooltip>
  )
}

type OverridePositionFunc = (
  position: { left: number; top: number },
  currentEvent: Event,
  currentTarget: EventTarget,
  // node is the ref argument, and the wrapper
  // is restricted to: div | span
  refNode: null | HTMLDivElement | HTMLSpanElement
) => { left: number; top: number }

const StyledReactTooltip = styled(ReactTooltip)<{
  myArrowPosition: ArrowPosition
}>`
  && {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
    color: ${theme.color.navy[1]};
    text-align: left;
    padding: 16px;
  }

  &&.show {
    opacity: 1;
  }
  &&.place-bottom::after {
    border-left-color: transparent;
    border-right-color: transparent;

    ${({ myArrowPosition }) => {
      if (myArrowPosition === 'topRight') {
        return css`
          right: 40px;
          left: auto;
        `
      }
      if (myArrowPosition === 'topLeft') {
        return css`
          right: auto;
          left: 46px;
        `
      }
      return assertNever(myArrowPosition)
    }};
  }
`
