import { createContainer } from '@blue-agency/front-state-management'
import { useCallback, useState, useRef, useEffect } from 'react'
import { CheckContainer } from './CheckContainer'
import { ParticipantsServiceContainer } from '@/containers/ParticipantsServiceContainer'
import Hls, { FragChangedData } from 'hls.js'
import { CommunicationErrorModalContainer } from '@/containers/CommunicationErrorModalContainer'
import { differenceInSeconds } from 'date-fns'
import { useHlsErrorHandler } from './useHlsErrorHandler'
import useCleanupCallback from 'use-cleanup-callback'
import { comlinkPush } from '@/comlink'

const REATTACH_SRC_INTERVAL_SECONDS = 2
const MAX_WAITING_FRAG_SECONDS = 3

const presenterHls = new Hls({
  fragLoadingMaxRetry: 200,
  manifestLoadingMaxRetry: 200,
  levelLoadingMaxRetry: 200,
  manifestLoadingMaxRetryTimeout: 1000,
  levelLoadingMaxRetryTimeout: 1000,
})

const screenShareHls = new Hls({
  fragLoadingMaxRetry: 200,
  manifestLoadingMaxRetry: 200,
  levelLoadingMaxRetry: 200,
  manifestLoadingMaxRetryTimeout: 1000,
  levelLoadingMaxRetryTimeout: 1000,
})

const usePresenterVideo = () => {
  const {
    verifyEmailRes,
    presenterDistributionUrl,
    screenDistributionUrl,
    isSessionFinished,
    isScreenShared,
    onPresenterEnded,
  } = CheckContainer.useContainer()
  const { handleCommunicationErrorModalOpen } =
    CommunicationErrorModalContainer.useContainer()
  const { joinSession } = ParticipantsServiceContainer.useContainer()

  const [enableAudio, setEnableAudio] = useState(false)
  const [showStartModal, setShowStartModal] = useState(true)
  const presenterProgramDateTimeRef = useRef<number | null>(null)
  const screenShareProgramDateTimeRef = useRef<number | null>(null)

  const prevPresenterVideoElRef = useRef<HTMLVideoElement | null>(null)
  const prevScreenVideoElRef = useRef<HTMLVideoElement | null>(null)

  const presenterHlsErrorHandler = useHlsErrorHandler(presenterHls)
  const screenHlsErrorHandler = useHlsErrorHandler(screenShareHls)

  const reattachPresenterSrc = useCallback(
    function (this: HTMLVideoElement, _ev: Event) {
      if (isSessionFinished) return
      setTimeout(() => {
        this.src = presenterDistributionUrl
      }, REATTACH_SRC_INTERVAL_SECONDS * 1000)
    },
    [isSessionFinished, presenterDistributionUrl]
  )
  const reattachScreenSrc = useCallback(
    function (this: HTMLVideoElement, _ev: Event) {
      if (isSessionFinished) return
      setTimeout(() => {
        this.src = screenDistributionUrl
      }, REATTACH_SRC_INTERVAL_SECONDS * 1000)
    },
    [isSessionFinished, screenDistributionUrl]
  )

  const comlinkPushForVideoRef = useCallback(
    (actions: string) => {
      comlinkPush({
        type: 'system_activity',
        action: actions,
        targetName: 'yashiori_hall.guid',
        targetIdStr: verifyEmailRes.getHallGuid(),
      })
    },
    [verifyEmailRes]
  )

  const presenterVideoRef = useCleanupCallback(
    (element: HTMLVideoElement | null) => {
      // NOTE: ストリームとセッション終了状態の両方を持って終了とする
      const handleVideoEnded = () => {
        if (isSessionFinished) {
          onPresenterEnded()
        }
      }

      // NOTE: iOS SafariではHlsはビルトインサポートがあるのでHls.jsは使えない
      if (Hls.isSupported()) {
        if (!element) return () => {}

        const handleMediaAttached = function () {
          comlinkPushForVideoRef('load_presenter_hls_src_when_supported')
          presenterHls.loadSource(presenterDistributionUrl)
        }

        let timer: NodeJS.Timeout
        const handleFragChanged = function (
          _e: 'hlsFragChanged',
          data: FragChangedData
        ) {
          if (timer) clearTimeout(timer)
          if (isSessionFinished) return
          timer = setTimeout(() => {
            // NOTE: 配信者側がリロードした時にtsファイルが書き換わり、参加者側が新しいtsファイルを読み込まなくなる
            // そのため、tsファイルの読み込みが止まったときに再読み込みしている
            presenterHls.detachMedia()
            presenterHls.attachMedia(element)

            // MAX_WAITING_FRAG_SECONDS秒以内に次のtsファイルが来なかった場合にtsファイルの読み込みが止まったと判断する
          }, MAX_WAITING_FRAG_SECONDS * 1000)
          // NOTE: 画面共有との同期に利用
          presenterProgramDateTimeRef.current = data.frag.programDateTime
        }

        presenterHls.attachMedia(element)
        presenterHls.on(Hls.Events.MEDIA_ATTACHED, handleMediaAttached)
        presenterHls.on(Hls.Events.ERROR, presenterHlsErrorHandler)
        presenterHls.on(Hls.Events.FRAG_CHANGED, handleFragChanged)
        element.addEventListener('ended', handleVideoEnded)

        return () => {
          if (timer) clearTimeout(timer)
          presenterHls.off(Hls.Events.MEDIA_ATTACHED, handleMediaAttached)
          presenterHls.off(Hls.Events.ERROR, presenterHlsErrorHandler)
          presenterHls.off(Hls.Events.FRAG_CHANGED, handleFragChanged)

          element.removeEventListener('ended', handleVideoEnded)
        }
      } else {
        if (!element) {
          if (prevPresenterVideoElRef.current) {
            prevPresenterVideoElRef.current.removeEventListener(
              'error',
              reattachPresenterSrc
            )
            prevPresenterVideoElRef.current.removeEventListener(
              'stalled',
              reattachPresenterSrc
            )
          }
          return () => {}
        }

        prevPresenterVideoElRef.current = element
        comlinkPushForVideoRef('load_presenter_hls_src_when_not_supported')

        element.addEventListener('error', reattachPresenterSrc)
        element.addEventListener('stalled', reattachPresenterSrc)
        element.addEventListener('ended', handleVideoEnded)
        element.src = presenterDistributionUrl

        return () => {
          element.removeEventListener('error', reattachPresenterSrc)
          element.removeEventListener('stalled', reattachPresenterSrc)
          element.removeEventListener('ended', handleVideoEnded)
        }
      }
    },
    [
      presenterDistributionUrl,
      isSessionFinished,
      reattachPresenterSrc,
      comlinkPushForVideoRef,
      presenterHlsErrorHandler,
    ]
  )

  const screenShareRef = useCleanupCallback(
    (element: HTMLVideoElement | null) => {
      // NOTE: iOS SafariではHlsはビルトインサポートがあるのでHls.jsは使えない
      if (Hls.isSupported()) {
        if (!element) return () => {}

        const handleMediaAttached = () => {
          comlinkPushForVideoRef('load_screen_hls_src_when_supported')
          screenShareHls.loadSource(screenDistributionUrl)
        }

        const handleFragChanged = (
          _e: 'hlsFragChanged',
          data: FragChangedData
        ) => {
          screenShareProgramDateTimeRef.current = data.frag.programDateTime
          if (!presenterProgramDateTimeRef.current) return
          if (!screenShareProgramDateTimeRef.current) return
          // NOTE: 配信者映像との同期
          const difference = differenceInSeconds(
            presenterProgramDateTimeRef.current,
            screenShareProgramDateTimeRef.current
          )
          if (3 <= Math.abs(difference)) {
            element.currentTime = element.currentTime + difference
          }
        }
        screenShareHls.attachMedia(element)
        screenShareHls.on(Hls.Events.MEDIA_ATTACHED, handleMediaAttached)
        screenShareHls.on(Hls.Events.ERROR, screenHlsErrorHandler)
        screenShareHls.on(Hls.Events.FRAG_CHANGED, handleFragChanged)

        return () => {
          screenShareHls.off(Hls.Events.MEDIA_ATTACHED, handleMediaAttached)
          screenShareHls.off(Hls.Events.ERROR, screenHlsErrorHandler)
          screenShareHls.off(Hls.Events.FRAG_CHANGED, handleFragChanged)
        }
      } else {
        if (!element) {
          if (prevScreenVideoElRef.current) {
            prevScreenVideoElRef.current.removeEventListener(
              'error',
              reattachScreenSrc
            )
            prevScreenVideoElRef.current.removeEventListener(
              'stalled',
              reattachScreenSrc
            )
          }
          return () => {}
        }

        prevScreenVideoElRef.current = element
        comlinkPushForVideoRef('load_screen_hls_src_when_not_supported')

        element.addEventListener('error', reattachScreenSrc)
        element.addEventListener('stalled', reattachScreenSrc)
        element.src = screenDistributionUrl

        return () => {
          element.removeEventListener('error', reattachScreenSrc)
          element.removeEventListener('stalled', reattachScreenSrc)
        }
      }
    },
    /**
     * NOTE:
     * PCでは画面共有時にレイアウトが変わりVideoElementが再レンダリングされることに対し、
     * SPでは画面共有時にレイアウト自体は変わらないのでVideoElementが再レンダリングされない
     * そのためSPでは同じscreenShareRefを参照し続け、配信者が2回目以降の画面共有を行った際に新しいstreamが読み込まれない
     * ワークアラウンドとしてisScreenSharedをdepsに入れて画面共有のたびにscreenShareRefを更新するようにする
     * (iOSでは起きていない)
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      screenDistributionUrl,
      comlinkPushForVideoRef,
      reattachScreenSrc,
      isScreenShared,
      screenHlsErrorHandler,
    ]
  )

  useEffect(() => {
    return () => {
      presenterHls.destroy()
      screenShareHls.destroy()
    }
  }, [])

  const handleStart = useCallback(async () => {
    if (isSessionFinished) {
      alert('説明会は終了しました。')
      return
    }
    comlinkPush({
      type: 'manual_activity',
      action: 'join_session',
      targetName: 'yashiori_hall.guid',
      targetIdStr: verifyEmailRes.getHallGuid(),
    })
    await joinSession({
      hallGuid: verifyEmailRes.getHallGuid(),
      participantGuid: verifyEmailRes.getParticipantGuid(),
    }).catch((err) => {
      setShowStartModal(false)
      const callback = () => setShowStartModal(true)
      handleCommunicationErrorModalOpen({ callback })
      throw err
    })
    setEnableAudio(true)
    setShowStartModal(false)
  }, [
    joinSession,
    verifyEmailRes,
    handleCommunicationErrorModalOpen,
    isSessionFinished,
  ])

  return {
    presenterVideoRef,
    screenShareRef,
    handleStart,
    showStartModal,
    enableAudio,
  }
}

export const PresenterVideoContainer = createContainer(usePresenterVideo)
