import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { WeakColorSeparator } from '@blue-agency/rogue'
import { WindowSizeContainer } from '@/containers/WindowSizeContainer'
import { overflowYScrollStyle } from '@/styles/overflowYScrollStyle'
import { isPc as isPcFn } from '@/utils'

type Props = {
  content: ReactNode
  bottom: ReactNode
}

export const ManualModalBody: React.FCX<Props> = ({
  className,
  content,
  bottom,
}) => {
  const { isPortrait, ...windowSize } = WindowSizeContainer.useContainer()
  const isPc = isPcFn(windowSize.width)

  return (
    <Wrapper className={className}>
      <Content isPc={isPc} isPortrait={isPortrait} windowSize={windowSize}>
        {content}
      </Content>
      <WeakColorSeparator />
      <Bottom>{bottom}</Bottom>
    </Wrapper>
  )
}

const Wrapper = styled.div``
const Content = styled.div<{
  isPc: boolean
  isPortrait: boolean
  windowSize: { width: number; height: number }
}>`
  ${Wrapper} & {
    height: ${({ isPc, isPortrait }) => {
      if (isPc) return '362px'
      return isPortrait ? '362px' : '282px'
    }};
    max-height: ${({ windowSize }) => windowSize.height - 128}px;
  }
  ${overflowYScrollStyle};
`
const Bottom = styled.div`
  height: 68px;
`
