import {
  createContainer,
  useCachedPromise,
} from '@blue-agency/front-state-management'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { InterviewContainer } from '../../../hooks/useInterview'
import { useSessionToken } from './useSessionToken'
import {
  InterviewChatWebSocketParams,
  parseUserGuid,
} from '@/services/interviewChatService'
import { Message } from '@/components/Chat/Messages'
import { ParticipantsServiceContainer } from '@/containers/ParticipantsServiceContainer'
import {
  InterviewQualityMode,
  GetInterviewQualityResponse,
  GetParticipantsInterviewResponse,
} from '@blue-agency/proton/web/v2/yashiori_bff/yashiori_bff_service_pb'
import { useModal } from '@/hooks/useModal'
import { BeepContainer } from '@/containers/BeepContainer'
import { enteringUserBeepSrc } from './enteringUserBeepSrc'
import { startScreenSharingBeepSrc } from './startScreenSharingBeepSrc'
import { cacheKey } from '@/services/bffService'
import { useStartInterviewBeep } from '@/hooks/useStartInterviewBeep'

import { SignalingNotifyConnectionCreated } from '@blue-agency/interview-sdk-js'
import {
  Metadata,
  SoraConnectionConfig,
  InterviewConfig,
} from '@/lib/react-interview-sdk'

import {
  useBgEffectCode,
  useInterviewStatus,
  useIsOtherScreenShared,
  useOwnParticipant,
  useUnstableLevel,
  useUserPublishserStatus,
} from '@/lib/react-interview-sdk/hooks/values'
import {
  useStartScreenSharing,
  useStopScreenSharing,
} from '@/lib/react-interview-sdk/hooks/controls'

import {
  Quality,
  QualityModeLow,
  QualityModeNormal,
} from '@/services/interviewService/types/Quality'
import { useComlinkPushSignalingStatus } from '@/services/interviewService/hooksForApp/useComlinkPushSignalingStatus'

import { toast } from '@blue-agency/rogue'
import { comlinkPush } from '@/comlink'

const { Status } = GetParticipantsInterviewResponse

const vaderWebHost = process.env.REACT_APP_VADER_WEB_HOST
if (!vaderWebHost) throw new Error('vaderWebHost not found')

const logRTCStatsEndpoint = process.env.REACT_APP_LOG_RTC_STATS_ENDPOINT
if (!logRTCStatsEndpoint) throw new Error('logRTCStatsEndpoint not found')

const SCREEN_SHARING_BITRATE = 256
const USE_DATACHANNEL_SIGNALING = true
const IGNORE_DISCONNECT_WEBSOCKET = true

const toQuality = (qualityRes: GetInterviewQualityResponse): Quality => {
  return {
    mode:
      qualityRes.getOldMode() === GetInterviewQualityResponse.Mode.LOW ||
      qualityRes.getMode() === InterviewQualityMode.LOW
        ? QualityModeLow
        : QualityModeNormal,
    videoBitRate: qualityRes.getVideoBitRate(),
    videoFrameRate: qualityRes.getVideoFrameRate(),
  }
}

const useRoom = () => {
  const participantsService = ParticipantsServiceContainer.useContainer()
  const {
    getParticipantsInterviewRes,
    initialStatus,
    interviewGuid,
    spotlight,
  } = InterviewContainer.useContainer()

  // 入室前にいるときにqualityが変更される可能性があるので再取得する
  const initialQuality = useCachedPromise(
    cacheKey.getInterviewQualityInRoom({ interviewGuid }),
    () => participantsService.getInterviewQuality({ interviewGuid })
  )

  // qualityはwsで変更イベントが飛んでくると変わる
  const [quality, setQuality] = useState<Quality>(() =>
    toQuality(initialQuality)
  )
  const handleChangedQuality = useCallback(async () => {
    const res = await participantsService.getInterviewQuality({ interviewGuid })
    const q = toQuality(res)
    setQuality(q)
    const modeTxt = q.mode === QualityModeLow ? '軽量モード' : '通常モード'
    toast(`${modeTxt}に変更されました。`)
  }, [interviewGuid, participantsService])

  const { beep } = BeepContainer.useContainer()
  const { sessionToken } = useSessionToken()

  const [status, setStatus] = useState(initialStatus)

  const signalingPoints = useCachedPromise(
    cacheKey.getSignalingPoints({ interviewGuid }),
    () => participantsService.getSignalingPoints({ interviewGuid })
  )

  const mainSignalingPoint = signalingPoints.getMain()
  if (!mainSignalingPoint) {
    throw new Error('mainSignalingPoint not found')
  }
  const mainSoraConnectionConfig: SoraConnectionConfig = useMemo(
    () => ({
      channelId: mainSignalingPoint.getChannelId(),
      webrtcHost: mainSignalingPoint.getWebrtcHost(),
      metadata: {
        session_token: sessionToken.user,
        invitation_token: getParticipantsInterviewRes.getIntervieweeToken(),
      },
      videoBitRate: quality.videoBitRate,
      spotlight: spotlight.enabled,
      spotlightNumber: spotlight.spotlightNumber,
      dataChannelSignaling: USE_DATACHANNEL_SIGNALING,
      ignoreDisconnectWebSocket: IGNORE_DISCONNECT_WEBSOCKET,
    }),
    // NOTE: https://github.com/blue-agency/yashiori-front/pull/1205#discussion_r454015996
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [quality.videoBitRate]
  )

  // 画面共有送信用
  const screenSharingSignalingPoint = signalingPoints.getScreenSharing()
  if (!screenSharingSignalingPoint) {
    throw new Error('screenSharingSignalingPoint not found')
  }
  const screenSharingSoraConnectionConfig: SoraConnectionConfig = useMemo(
    () => ({
      channelId: screenSharingSignalingPoint.getChannelId(),
      webrtcHost: screenSharingSignalingPoint.getWebrtcHost(),
      metadata: {
        session_token: sessionToken.screenSharing,
        invitation_token: getParticipantsInterviewRes.getIntervieweeToken(),
      },
      videoBitRate: SCREEN_SHARING_BITRATE,
      dataChannelSignaling: USE_DATACHANNEL_SIGNALING,
      ignoreDisconnectWebSocket: IGNORE_DISCONNECT_WEBSOCKET,
    }),
    // NOTE: https://github.com/blue-agency/yashiori-front/pull/1205#discussion_r454015996
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  // 画面共有受信用
  const screenSharingRecvSoraConnectionConfig: SoraConnectionConfig = useMemo(
    () => ({
      channelId: screenSharingSignalingPoint.getChannelId(),
      webrtcHost: screenSharingSignalingPoint.getWebrtcHost(),
      metadata: {
        session_token: sessionToken.screenSharingRecv,
        invitation_token: getParticipantsInterviewRes.getIntervieweeToken(),
      },
      videoBitRate: SCREEN_SHARING_BITRATE,
      dataChannelSignaling: USE_DATACHANNEL_SIGNALING,
      ignoreDisconnectWebSocket: IGNORE_DISCONNECT_WEBSOCKET,
    }),
    // NOTE: https://github.com/blue-agency/yashiori-front/pull/1205#discussion_r454015996
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const userPublisherStatus = useUserPublishserStatus()

  useComlinkPushSignalingStatus(interviewGuid)

  const interviewStatus = useInterviewStatus()

  const onConnectionCreated = useCallback(
    (data: SignalingNotifyConnectionCreated) => {
      if (interviewStatus !== 'started') return
      const metadata = data.metadata as Metadata
      if (
        metadata.interview_role === 'applicant' ||
        metadata.interview_role === 'recruiter'
      ) {
        beep(enteringUserBeepSrc)
      }
    },
    [beep, interviewStatus]
  )
  const interviewConfig = useMemo(
    (): InterviewConfig => ({
      webSocketUrl: `wss://${vaderWebHost}/channel_status/${getParticipantsInterviewRes.getVideoInterviewGuid()}`,
      soraConnectionConfigs: {
        main: mainSoraConnectionConfig,
        screenSharing: screenSharingSoraConnectionConfig,
        screenSharingRecv: screenSharingRecvSoraConnectionConfig,
      },
      participantType: 'interviewee',
      videoFrameRate: quality.videoFrameRate,
      logRTCStatsEndpoint,
      onConnectionCreated,
      autoFocusedToMain: spotlight.enabled,
      onChangedQuality: handleChangedQuality,
    }),
    [
      getParticipantsInterviewRes,
      handleChangedQuality,
      mainSoraConnectionConfig,
      onConnectionCreated,
      quality.videoFrameRate,
      screenSharingRecvSoraConnectionConfig,
      screenSharingSoraConnectionConfig,
      spotlight.enabled,
    ]
  )

  const unstableLevel = useUnstableLevel()

  useStartInterviewBeep(interviewStatus)

  useEffect(() => {
    if (interviewStatus === 'started') {
      setStatus(Status.STARTED)
    } else if (interviewStatus === 'finished') {
      setStatus(Status.FINISHED)
    }
  }, [interviewStatus])

  const [showUsers, setShowUsers] = useState(true)
  const [showChat, setShowChat] = useState(false)

  const toggleShowUsers = useCallback(() => setShowUsers((prev) => !prev), [])
  const toggleShowChat = useCallback(() => setShowChat((prev) => !prev), [])

  const startScreenShareModal = useModal()
  const finishScreenShareModal = useModal()
  const screenSharingLimitModal = useModal()

  const isOtherScreenShared = useIsOtherScreenShared()

  useEffect(() => {
    if (isOtherScreenShared) {
      startScreenShareModal.close()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOtherScreenShared])

  const startScreenSharing = useStartScreenSharing()
  const stopScreenSharing = useStopScreenSharing()

  const handleScreenShareStart = useCallback(async () => {
    await startScreenSharing()
    // if (!started) return
    beep(startScreenSharingBeepSrc)
    startScreenShareModal.close()
  }, [beep, startScreenShareModal, startScreenSharing])

  const handleScreenShareFinish = useCallback(() => {
    stopScreenSharing()
    finishScreenShareModal.close()
  }, [stopScreenSharing, finishScreenShareModal])

  useEffect(() => {
    comlinkPush({
      type: 'system_activity',
      action: 'change_unstable_level',
      targetName: 'interviewGuid',
      targetIdStr: interviewGuid,
      metadata: {
        userType: 'interviewee',
        unstableLevel: unstableLevel + '',
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unstableLevel])

  const bgEffectCode = useBgEffectCode()
  useEffect(() => {
    comlinkPush({
      type: 'system_activity',
      action: 'selected_background_effect',
      metadata: {
        interviewGuid,
        backgroundEffect: bgEffectCode,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bgEffectCode])

  const [chatParams, setChatParams] = useState<InterviewChatWebSocketParams>()
  const [chatMessages, setChatMessages] = useState<Message[]>([])
  const [unreadMessagesCnt, setUnreadMessagesCnt] = useState(0)

  const onAddMessage = useCallback(
    (message: Message) => {
      !showChat && setUnreadMessagesCnt((prev) => ++prev)
      setChatMessages((prev) => [...prev, message])
    },
    [showChat]
  )

  const ownParticipant = useOwnParticipant()

  useEffect(() => {
    ;(async () => {
      if (interviewStatus !== 'started' || !ownParticipant?.entryNumber) return
      const res = await participantsService.listChatMessagesForInterview(
        interviewGuid
      )
      const chatMessages: Message[] = res.getMessagesList().map((message) => {
        const { participantType, entryNumber } = parseUserGuid(
          message.getUserGuid()
        )
        return {
          type: participantType,
          name: entryNumber,
          text: message.getText(),
        }
      })
      setChatMessages(chatMessages)
      setChatParams({
        chatRoomGuid: getParticipantsInterviewRes.getChatRoomGuid(),
        participantType: 'interviewee',
        entryNumber: ownParticipant.entryNumber.toString(),
        onAddMessage,
      })
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interviewStatus, ownParticipant?.entryNumber])

  useEffect(() => {
    setChatParams((prev) => (prev ? { ...prev, onAddMessage } : prev))
    if (showChat) setUnreadMessagesCnt(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showChat])

  const isConnected = userPublisherStatus.status === 'Completed'

  return {
    interviewConfig,
    status,
    isConnected,
    showUsers,
    toggleShowUsers,
    showChat,
    toggleShowChat,
    startScreenShareModal,
    finishScreenShareModal,
    screenSharingLimitModal,
    handleScreenShareStart,
    handleScreenShareFinish,
    chatParams,
    chatMessages,
    unreadMessagesCnt,
    quality,
  }
}

export const RoomContainer = createContainer(useRoom)
