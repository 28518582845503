import React from 'react'
import styled from 'styled-components'
import { Layout } from '@/components/Layout'
import { Txt } from '@blue-agency/rogue'

export const NotFound: React.FCX = ({ className }) => {
  return (
    <Layout className={className} showFooter>
      <TxtWrapper>
        <Txt>お探しのページは見つかりません</Txt>
      </TxtWrapper>
    </Layout>
  )
}

const TxtWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 40px 20px 20px;
`
