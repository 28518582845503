import { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'
import { interviewStatusState } from '@/lib/react-interview-sdk/states'
import { useInterviewWebSocketStatus } from './useInterviewWebSocket'

export const useInterviewStatus = () => {
  const setStatus = useSetRecoilState(interviewStatusState)

  const wsStatus = useInterviewWebSocketStatus()

  useEffect(() => {
    if (wsStatus === 'started') {
      setStatus('started')
    } else if (wsStatus === 'finished') {
      setStatus('finished')
    }
  }, [setStatus, wsStatus])
}
