import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { theme, Icon } from '@blue-agency/rogue'
import { CameraOffImage as _CameraOffImage } from '@/components/CameraOffImage'
import { UnavailableVideoInputImage as _UnavailableVideoInputImage } from '@/components/UnavailableVideoInputImage'

import {
  useMutedMainVideoAudio,
  useMainVideo,
  useLocalVideoTileId,
  useIsOwnCameraMuted,
  useIsUnavailableVideoInput,
  useInterviewStatus,
} from '@/lib/react-interview-sdk/hooks/values'
import { useBindVideoElement } from '@/lib/react-interview-sdk/hooks/controls'
import { Switch, Case, Default } from 'react-if'

export const VideoArea: React.FCX = ({ className }) => {
  const mutedMainVideoAudio = useMutedMainVideoAudio()
  const bindVideoElement = useBindVideoElement()

  const localTileId = useLocalVideoTileId()
  const mainVideo = useMainVideo()
  const isOwnCameraMuted = useIsOwnCameraMuted()
  const isUnavailableVideoInput = useIsUnavailableVideoInput()
  const interviewStatus = useInterviewStatus()

  const videoRef = useRef<HTMLVideoElement>(null)
  const isMyselfPinned = localTileId === mainVideo.pinnedTileId
  const showCameraMutedImage = isOwnCameraMuted && isMyselfPinned
  const showUnavailableVideoInputImage =
    isUnavailableVideoInput && isMyselfPinned

  useEffect(() => {
    // 開始前は映像を表示しない
    if (interviewStatus === 'notStarted') return
    if (
      !mainVideo.mainVideoTileId ||
      !videoRef.current ||
      showCameraMutedImage ||
      showUnavailableVideoInputImage
    ) {
      return
    }

    const cleanup = bindVideoElement(
      mainVideo.mainVideoTileId,
      videoRef.current
    )

    return () => {
      cleanup()
    }
  }, [
    showCameraMutedImage,
    showUnavailableVideoInputImage,
    mainVideo.mainVideoTileId,
    bindVideoElement,
    interviewStatus,
  ])

  return (
    <Wrapper className={className}>
      <Switch>
        <Case condition={showCameraMutedImage}>
          <CameraOffImage />
        </Case>
        <Case condition={showUnavailableVideoInputImage}>
          <UnavailableVideoInputImage />
        </Case>
        <Default>
          <MainVideo
            className={className}
            muted
            autoPlay
            playsInline
            ref={videoRef}
            mirrorEffect={isMyselfPinned}
          />
        </Default>
      </Switch>

      {mutedMainVideoAudio && <MuteMikeIcon name="mute-mike" />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
`
const MainVideo = styled.video<{ mirrorEffect?: boolean }>`
  ${Wrapper} & {
    width: 100%;
    height: 100%;
  }
  background: ${theme.color.black[1]};
  ${({ mirrorEffect }) => mirrorEffect && 'transform: scaleX(-1);'}
`
const CameraOffImage = styled(_CameraOffImage)`
  ${Wrapper} & {
    width: 100%;
    height: 100%;
  }
  object-fit: cover;
`

const UnavailableVideoInputImage = styled(_UnavailableVideoInputImage)`
  ${Wrapper} & {
    width: 100%;
    height: 100%;
  }
  object-fit: cover;
`

const MuteMikeIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  padding: 4px;
  border-radius: 50%;
  color: ${theme.color.white[1]};
  background-color: ${theme.color.red[2]};
  opacity: 0.9;

  ${Wrapper} & {
    position: absolute;
    bottom: 12px;
    left: 12px;
  }
`
