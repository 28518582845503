import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { Txt as _Txt, theme, Icon } from '@blue-agency/rogue'
import { EnterContainer } from '../containers/EnterContainer'
import { ProgressBar as _ProgressBar } from '@/components/ProgressBar'
import { GetSessionStatusResponse } from '@blue-agency/proton/web/v2/yashiori_bff/yashiori_bff_service_pb'
import { H2 } from '@/components/Heading'
const { Status } = GetSessionStatusResponse

const TICK_INTERVAL_MILLI_SECONDS = 250
const WAITING_TIME_MILLI_SECONDS = 60000

export const Upper: React.FCX = ({ className }) => {
  const { status } = EnterContainer.useContainer()
  const [percent, setPercent] = useState(0)
  const stopTickRef = useRef(false)

  useEffect(() => {
    if (status === Status.IS_READY) {
      setPercent(100)
      return
    }

    const getNextPercent = (prev: number) => {
      if (100 <= prev) {
        stopTickRef.current = true
        return prev
      }
      return (
        prev + 100 / (WAITING_TIME_MILLI_SECONDS / TICK_INTERVAL_MILLI_SECONDS)
      )
    }
    const tick = () => {
      setPercent(getNextPercent)
      if (stopTickRef.current) return
      setTimeout(tick, TICK_INTERVAL_MILLI_SECONDS)
    }
    tick()
  }, [status])
  return (
    <Wrapper className={className}>
      <H2>{status === Status.NOT_READY ? '配信準備中…' : '配信準備完了'}</H2>
      <Txt size="s" color={theme.color.gray[1]}>
        準備完了後、下部の「配信開始」を押すと配信が開始されます。
      </Txt>
      <ProgressBarWrapper>
        <ProgressBar percent={percent} />
        {status === Status.IS_READY && <CheckIcon name="check2" />}
      </ProgressBarWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Txt = styled(_Txt)`
  ${Wrapper} & {
    margin-top: 10px;
  }
`
const ProgressBarWrapper = styled.div`
  ${Wrapper} & {
    margin-top: 15px;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const ProgressBar = styled(_ProgressBar)`
  ${ProgressBarWrapper} & {
    flex: 1 0 auto;
  }
`
const CheckIcon = styled(Icon)`
  ${ProgressBarWrapper} & {
    margin-left: 7px;
  }
  color: ${theme.color.green[4]};
`
