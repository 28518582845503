import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { RequireTxt, Txt } from '@blue-agency/rogue'

type Props = {
  title?: string
  label: string
  required?: boolean
  inputNode: ReactNode
}

export const OrganizerFormItem: React.FCX<Props> = (props) => {
  return (
    <Wrapper className={props.className}>
      {props.title ? <ItemTitle>{props.title}</ItemTitle> : <ItemTitleMargin />}
      <ItemLabel>
        <ItemLabelTxt>{props.label}</ItemLabelTxt>
        {props.required ? (
          <Required size="xs">必須</Required>
        ) : (
          <RequiredMargin />
        )}
        {props.inputNode}
      </ItemLabel>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  &:nth-of-type(n + 2) {
    margin-top: 20px;
  }
  display: flex;
  align-items: center;
`
const ItemTitle = styled(Txt)`
  width: 160px;
`
const ItemTitleMargin = styled.div`
  width: 160px;
`
const ItemLabel = styled.label`
  display: flex;
  align-items: center;
`
const ItemLabelTxt = styled(Txt).attrs({ size: 's', bold: true })`
  width: 130px;
`
const Required = styled(RequireTxt)`
  width: 30px;
`
const RequiredMargin = styled.div`
  width: 30px;
`
