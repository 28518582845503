import { useEffect, useState, useCallback, useMemo } from 'react'
import {
  ListMessagesResponse,
  CreateMessageRequest,
} from '@blue-agency/proton/web/c3po/chat_service_pb'
import { Message } from '@/components/Chat/Messages'
import { CheckContainer } from '../CheckContainer'
import { ParticipantsServiceContainer } from '@/containers/ParticipantsServiceContainer'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { buildWebSocketUrl } from './buildWebSocketUrl'
import { comlinkPush } from '@/comlink'

const maxRetryCount = 5

export const buildMessage = (params: {
  senderId: string
  myId: string
  text: string
  myName: string
}): Message => {
  const isMine = params.senderId === params.myId
  const isPresenter = params.senderId === 'presenter'
  if (isMine) {
    return {
      type: 'me',
      name: params.myName,
      text: params.text,
    }
  }
  if (isPresenter) {
    return {
      type: 'presenter',
      name: '担当者',
      text: params.text,
    }
  }
  return {
    type: 'participant',
    name: '参加者',
    text: params.text,
  }
}

export const useMessages = () => {
  const { listChatMessages } = ParticipantsServiceContainer.useContainer()
  const { verifyEmailRes } = CheckContainer.useContainer()
  const [messages, setMessages] = useState<Message[]>([])

  const handleMessage = (event: WebSocketEventMap['message']) => {
    const reader = new FileReader()
    reader.readAsArrayBuffer(event.data)
    reader.onload = () => {
      const bytes = new Uint8Array(reader.result as ArrayBuffer)
      const msg = ListMessagesResponse.ChatMessage.deserializeBinary(bytes)
      const message = buildMessage({
        senderId: msg.getUserGuid(),
        myId: verifyEmailRes.getParticipantGuid(),
        text: msg.getText(),
        myName: `${verifyEmailRes.getLastName()} ${verifyEmailRes.getFirstName()}`,
      })
      setMessages((prevMessages) => [...prevMessages, message])
    }
  }

  const { sendMessage, readyState } = useWebSocket(
    buildWebSocketUrl(verifyEmailRes.getChatRoomGuid()),
    {
      reconnectAttempts: maxRetryCount,
      onMessage: handleMessage,
      shouldReconnect,
      protocols: [verifyEmailRes.getParticipantGuid()],
    }
  )

  const connected = useMemo(() => readyState === ReadyState.OPEN, [readyState])

  useEffect(() => {
    ;(async () => {
      const chatMessagesRes = await listChatMessages({
        roomGuid: verifyEmailRes.getChatRoomGuid(),
      })
      const myName = `${verifyEmailRes.getLastName()} ${verifyEmailRes.getFirstName()}`
      const messages: Message[] = chatMessagesRes
        .getMessagesList()
        .map((message) =>
          buildMessage({
            senderId: message.getUserGuid(),
            myId: verifyEmailRes.getParticipantGuid(),
            text: message.getText(),
            myName,
          })
        )
      const noticeMessage: Message = {
        type: 'notice',
        name: 'お知らせ',
        text: '・企業への質問を受付します。\n・動作不良時はブラウザを再読込してください。',
      }
      setMessages([...messages, noticeMessage])
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSend = useCallback(
    (text: string) => {
      comlinkPush({
        type: 'manual_activity',
        action: 'send_message',
        targetName: 'yashiori_hall.guid',
        targetIdStr: verifyEmailRes.getHallGuid(),
      })
      if (!connected) return
      const message = new CreateMessageRequest()
      message.setRoomGuid(verifyEmailRes.getHallGuid())
      message.setText(text)
      message.setUserGuid(verifyEmailRes.getParticipantGuid())
      message.setUserType(ListMessagesResponse.UserType.APPLICANT)
      sendMessage(message.serializeBinary())
    },
    [connected, sendMessage, verifyEmailRes]
  )

  return { connected, messages, handleSend }
}

export const shouldReconnect = (e: WebSocketEventMap['close']) => {
  // MEMO: 正常終了の時はretryしない
  if (e.code === 1005 && e.reason === '') return false
  return true
}
