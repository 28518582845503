import React from 'react'
import styled from 'styled-components'
import { Txt, theme, NewTabLink } from '@blue-agency/rogue'
import { H2 } from '@/components/Heading'
import reactStringReplace from 'react-string-replace'

const table = {
  header: ['ご利用端末', 'OS', 'Webブラウザ'],
  body: [
    [
      'Windows',
      'Windows10以上の最新版',
      'Google Chrome, Microsoft Edgeの最新版',
    ],
    ['Mac', 'macOS Mojave(10.14.5)以上の最新版', 'Google Chromeの最新版'],
  ],
}

const redInfo = [
  '※ Windows 7は、ご利用いただけません。',
  '※ Internet Explorerは、ご利用いただけません。',
]
const info = [
  '※ 上記に記載のない環境でも問題なくご利用いただける場合がございますが動作についてはサポート対象外となります。詳細については必ず推奨環境をご確認ください。',
]

type Props = {
  recommendedEnvUrl: string
}

export const RecommendedEnvTable: React.FCX<Props> = ({
  className,
  recommendedEnvUrl,
}) => {
  return (
    <Wrapper className={className}>
      <H2>推奨環境</H2>
      <Table>
        <tbody>
          <Tr>
            {table.header.map((cell, index) => (
              <Th key={index}>
                <Txt bold color={theme.color.black[1]}>
                  {cell}
                </Txt>
              </Th>
            ))}
          </Tr>
          {table.body.map((row, rowIndex) => (
            <Tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <Td key={cellIndex}>
                  <Txt size="s" color={theme.color.black[1]}>
                    {cell}
                  </Txt>
                </Td>
              ))}
            </Tr>
          ))}
        </tbody>
      </Table>
      <InfoWrapper>
        {redInfo.map((text, i) => (
          <Txt key={i} size="s" color={theme.color.red[2]}>
            {text}
          </Txt>
        ))}
        {info.map((text, i) => (
          <Txt key={i} size="s">
            {reactStringReplace(text, '推奨環境', (match, index) => (
              <NewTabLink
                key={index}
                href={recommendedEnvUrl}
                action="open_recommended_environment_page"
              >
                {match}
              </NewTabLink>
            ))}
          </Txt>
        ))}
      </InfoWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 670px;
`
const Table = styled.table`
  ${Wrapper} & {
    margin-top: 20px;
  }
`
const Tr = styled.tr`
  border-bottom: 0.5px solid #c1c1c1;
`
const Th = styled.th`
  padding: 12px 0;
  background: #fafafa;
`
const Td = styled.td`
  padding: 12px 0;
  width: calc(670px / 3);
  text-align: center;
  vertical-align: middle;
`
const InfoWrapper = styled.div`
  ${Wrapper} & {
    margin-top: 20px;
  }
`
