import React from 'react'
import styled from 'styled-components'
import { theme, Txt, TertiaryButton } from '@blue-agency/rogue'
import { EXTERNAL_URLS } from '@/services/urlService'
import { Logo as _Logo } from '@/components/Logo'

export const Maintenance: React.FCX = () => (
  <Wrapper>
    <Logo />
    <Message>
      ご利用開始は2020年3月30日を予定しております。
      <br />
      しばらくお待ちください
    </Message>
    <ContactButton
      text="お問い合わせ"
      onClick={() => {
        window.location.href = EXTERNAL_URLS.contact
      }}
      comlinkPushParams={{ action: 'open_contact_page' }}
    />
  </Wrapper>
)

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background: ${theme.color.white[1]};
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Logo = styled(_Logo)`
  width: 200px;
  ${Wrapper} & {
    margin-top: 100px;
  }
`
const Message = styled(Txt)`
  ${Wrapper} & {
    margin-top: 34px;
  }
`
const ContactButton = styled(TertiaryButton)`
  ${Wrapper} & {
    margin-top: 34px;
  }
`
