import React from 'react'
import { Footer as _Footer } from '@/components/Footer'
import { Pagination } from '@blue-agency/rogue'
import styled from 'styled-components'
import { PresentationsContainer } from './hooks/PresentationsContainer'
import { BulkAction } from './BulkAction'

export const Footer: React.FCX = ({ className }) => {
  const { count, setPage } = PresentationsContainer.useContainer()

  return (
    <Wrapper className={className}>
      <BulkAction />
      <div />
      <Pagination count={count} onChange={setPage} />
    </Wrapper>
  )
}

const Wrapper = styled(_Footer)`
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  height: 60px;
`
