import { CacheContainer } from '@blue-agency/front-state-management'
import { useEffect, useState, useCallback, useMemo } from 'react'
import {
  ListMessagesResponse,
  CreateMessageRequest,
} from '@blue-agency/proton/web/c3po/chat_service_pb'
import { Message } from '@/components/Chat/Messages'
import { EnterContainer } from '../EnterContainer'
import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'
import { cacheKey } from '@/services/bffService'
import {
  GetParticipantNameResponse,
  ListChatMessagesForPresenterResponse,
} from '@blue-agency/proton/web/v2/yashiori_bff/yashiori_bff_service_pb'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { buildWebSocketUrl } from './buildWebSocketUrl'
import { beepSrc } from './beepSrc'
import { BeepContainer } from '@/containers/BeepContainer'
import { throttle } from 'throttle-debounce'
import { comlinkPush } from '@/comlink'

const maxRetryCount = 5

export const useMessages = () => {
  const { get, put } = CacheContainer.useContainer()
  const { enterHallRes } = EnterContainer.useContainer()
  const { listChatMessages, getParticipantName } =
    OrganizerServiceContainer.useContainer()
  const [messages, setMessages] = useState<Message[]>([])

  const { beep } = BeepContainer.useContainer()

  // NOTE: 通知音を鳴らしてから30秒は次の通知音を鳴らさない
  const throttledBeep = useMemo(
    () =>
      throttle(30000, true, () => {
        beep(beepSrc, 0.8)
      }),
    [beep]
  )

  const handleMessage = (event: WebSocketEventMap['message']) => {
    const reader = new FileReader()
    reader.readAsArrayBuffer(event.data)
    reader.onload = async () => {
      const bytes = new Uint8Array(reader.result as ArrayBuffer)
      const msg = ListMessagesResponse.ChatMessage.deserializeBinary(bytes)
      const isMine = msg.getUserGuid() === 'presenter'
      const getName = async () => {
        if (isMine) return 'あなた'
        const participantGuid = msg.getUserGuid()
        const key = cacheKey.getParticipantName({ participantGuid })
        const cache = get(key)
        if (cache) {
          // TODO: react-utilsのgetを修正してcache.valueに型が付くようにしたい
          const getParticipantNameRes =
            cache.value as GetParticipantNameResponse
          return `${getParticipantNameRes.getLastName()} ${getParticipantNameRes.getFirstName()}`
        }
        const getParticipantNameRes = await getParticipantName({
          participantGuid,
          presenterToken: enterHallRes.getPresenterToken(),
        })
        put(key, getParticipantNameRes)
        return `${getParticipantNameRes.getLastName()} ${getParticipantNameRes.getFirstName()}`
      }
      const message: Message = {
        type: isMine ? 'me' : 'participant',
        name: await getName(),
        text: msg.getText(),
      }
      setMessages((prevMessages) => [...prevMessages, message])
      if (!isMine) {
        throttledBeep()
      }
    }
  }

  const { sendMessage, readyState } = useWebSocket(
    buildWebSocketUrl(enterHallRes.getChatRoomGuid()),
    {
      reconnectAttempts: maxRetryCount,
      onMessage: handleMessage,
      shouldReconnect,
      protocols: ['presenter'],
    }
  )

  const connected = useMemo(() => readyState === ReadyState.OPEN, [readyState])

  useEffect(() => {
    ;(async () => {
      const chatMessagesRes = await listChatMessages({
        roomGuid: enterHallRes.getChatRoomGuid(),
        presenterToken: enterHallRes.getPresenterToken(),
      })
      const messages: Message[] = chatMessagesRes
        .getMessagesList()
        .map(convertChatMessageIntoMessage)
      setMessages(messages)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSend = useCallback(
    (text: string) => {
      comlinkPush({
        type: 'manual_activity',
        action: 'send_message',
        targetName: 'yashiori_hall.guid',
        targetIdStr: enterHallRes.getHallGuid(),
      })
      if (!connected) return
      const message = new CreateMessageRequest()
      message.setRoomGuid(enterHallRes.getHallGuid())
      message.setText(text)
      message.setUserGuid('presenter')
      message.setUserType(ListMessagesResponse.UserType.STAFF)
      sendMessage(message.serializeBinary())
    },
    [connected, enterHallRes, sendMessage]
  )

  return { connected, messages, handleSend }
}

export const convertChatMessageIntoMessage = (
  chatMessage: ListChatMessagesForPresenterResponse.ChatMessage
): Message => {
  const isMine = chatMessage.getUserGuid() === 'presenter'
  return {
    type: isMine ? 'me' : 'participant',
    name: isMine ? 'あなた' : chatMessage.getUserName(),
    text: chatMessage.getText(),
  }
}

export const shouldReconnect = (e: WebSocketEventMap['close']) => {
  // MEMO: 正常終了の時はretryしない
  if (e.code === 1005 && e.reason === '') return false
  return true
}
