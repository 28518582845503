import asyncRetry from 'async-retry'
import { CustomGrpcError } from './CustomGrpcError'
import * as grpcWeb from 'grpc-web'
import { GrpcError } from './types'

const retryCount = 5

export const retry = <T>(request: () => Promise<T>) => {
  const retrier = async (bail: (e: Error) => void) => {
    return await request().catch((err) => {
      const { shouldRetry } = checkRetryError(err)
      const customErr = new CustomGrpcError(err)
      if (!shouldRetry) {
        return bail(customErr) as never
      }
      throw customErr
    })
  }

  return asyncRetry(retrier, { retries: retryCount })
}

const checkRetryError: (err: GrpcError) => { shouldRetry: boolean } = (err) => {
  switch (err.code) {
    case grpcWeb.StatusCode.INVALID_ARGUMENT:
    case grpcWeb.StatusCode.NOT_FOUND:
    case grpcWeb.StatusCode.PERMISSION_DENIED:
    case grpcWeb.StatusCode.UNAUTHENTICATED:
    case grpcWeb.StatusCode.RESOURCE_EXHAUSTED:
    case grpcWeb.StatusCode.FAILED_PRECONDITION:
      return { shouldRetry: false }
    default:
      return { shouldRetry: true }
  }
}
