import { SendMessage, ReadyState } from 'react-use-websocket'
import { atom } from 'recoil'
import { namespace } from './namespace'

export type WsStatus = 'unknown' | 'started' | 'finished'

export const WebSocketReadyState = ReadyState

export type WebSocketMuteMessage = {
  status: 'mute'
  stream_id: string
  muted: boolean
}

export type WebSocketMessageMap = {
  pong: {
    status: 'pong'
  }
  start: {
    status: 'start'
    channel_id: string
    start_time: string
    current_time: string
  }
  finish: {
    status: 'finish'
    channel_id: string
  }
  'in trouble': {
    status: 'in trouble'
    action: 'change webrtc host'
    channel_id: string
    webrtc_host: string
  }
  mute: WebSocketMuteMessage
}

export type InterviewWebSocketMessageListenerFn<
  K extends keyof WebSocketMessageMap
> = (message: WebSocketMessageMap[K]) => void

export type InterviewWebSocketEventLister = <
  K extends keyof WebSocketMessageMap
>(
  status: K,
  listener: InterviewWebSocketMessageListenerFn<K>
) => void

export type InterviewWebSocket = {
  readyState: ReadyState
  sendMessage: SendMessage
  addMessageListener: InterviewWebSocketEventLister
  removeMessageListener: InterviewWebSocketEventLister
}

export const interviewWebSocketState = atom<InterviewWebSocket | null>({
  key: `${namespace}/interviewWebSocketState`,
  default: null,
})
