import React from 'react'
import styled from 'styled-components'
import { theme } from '@blue-agency/rogue'

type Props = {
  count: number
  currentPage: number
}

export const Dots: React.FCX<Props> = ({ className, count, currentPage }) => {
  return (
    <Wrapper className={className}>
      {Array(count)
        .fill(0)
        .map((_, index) => (
          <Dot key={index} active={currentPage === index + 1} />
        ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const Dot = styled.div<{ active?: boolean }>`
  ${Wrapper} & {
    &:nth-of-type(n + 2) {
      margin-left: 6px;
    }
  }
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: ${({ active }) =>
    active ? theme.color.green[5] : theme.color.gray[4]};
`
