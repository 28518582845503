import * as yup from 'yup'
import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useState } from 'react'
import { toast } from '@blue-agency/rogue'
import { StatusCode } from 'grpc-web'
import { NotPermittedModalContainer } from '@/containers/NotPermittedModalContainer'
import { UpdateNameModal } from '../UpdateNameModal'
import { PresentationsContainer } from './PresentationsContainer'

type Form = {
  name: string
}

const validationSchema: yup.SchemaOf<Form> = yup
  .object()
  .shape({
    name: yup
      .string()
      .required('入力してください')
      .max(30, '30文字以内で入力してください'),
  })
  .defined()

export const useUpdateNameModal = ({
  presentationGuid,
  currentName,
  onClose,
  onOpen,
}: React.ComponentProps<typeof UpdateNameModal>) => {
  const { updatePresentationName } = OrganizerServiceContainer.useContainer()
  const { reloadPresentations } = PresentationsContainer.useContainer()
  const notPermittedModal = NotPermittedModalContainer.useContainer()
  const { register, handleSubmit, errors, formState } = useForm<Form>({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
    defaultValues: { name: currentName },
  })
  const [isSubmitError, setIsSubmitError] = useState(false)

  const onSubmit = useCallback<SubmitHandler<Form>>(
    async (data) => {
      try {
        await updatePresentationName({
          presentationGuid,
          name: data.name,
        })
      } catch (e) {
        if (e.code === StatusCode.PERMISSION_DENIED) {
          onClose()
          notPermittedModal.open('説明会動画名編集', { callback: onOpen })
          return
        }
        setIsSubmitError(true)
        throw e
      }
      onClose()
      toast('説明会動画名を保存しました')
      reloadPresentations()
    },
    [
      presentationGuid,
      onClose,
      onOpen,
      reloadPresentations,
      updatePresentationName,
      notPermittedModal,
    ]
  )

  return {
    register,
    handleSubmit,
    errors,
    isSubmitError,
    formState,
    onSubmit,
  }
}
