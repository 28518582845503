import React, { useCallback } from 'react'
import styled from 'styled-components'
import {
  Modal,
  Txt,
  TertiaryButton,
  WeakColorSeparator,
  DangerButton,
} from '@blue-agency/rogue'

const text = `ブラウザをリロードし、再読み込みをおこないます。
読み込みが完了するまで、一時的に音声と映像が途切れますのでご注意ください。`

type Props = {
  active: boolean
  onCancel: () => void
}

export const ReloadModal: React.FCX<Props> = ({
  className,
  active,
  onCancel,
}) => {
  const handleReload = useCallback(() => {
    window.location.reload()
  }, [])
  return (
    <Modal
      className={className}
      active={active}
      title="再読込確認"
      onClose={onCancel}
      size="s"
    >
      <Wrapper>
        <Upper>
          <Txt>{text}</Txt>
        </Upper>
        <Separator />
        <Bottom>
          <ButtonGroup>
            <CancelButton
              text="キャンセル"
              onClick={onCancel}
              comlinkPushParams={{ action: 'close_reload_modal' }}
            />
            <NextButton
              text="再読込"
              onClick={handleReload}
              comlinkPushParams={{ action: 'reload_at_reload_modal' }}
            />
          </ButtonGroup>
        </Bottom>
      </Wrapper>
    </Modal>
  )
}

const Wrapper = styled.div``
const Upper = styled.div`
  padding: 20px 16px;
`
const Separator = styled(WeakColorSeparator)`
  ${Wrapper} & {
    margin-top: 40px;
  }
`
const Bottom = styled.div`
  padding: 20px 16px;
`
const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const CancelButton = styled(TertiaryButton)`
  ${ButtonGroup} & {
    width: 48%;
  }
`
const NextButton = styled(DangerButton)`
  ${ButtonGroup} & {
    width: 48%;
  }
`
