import { createContext, useContext } from 'react'
import { captureException } from '@sentry/react'

const captureExceptionContext = createContext<typeof captureException>(
  (...args) => {
    // eslint-disable-next-line no-console
    console.error('captured exception', ...args)
    return ''
  }
)

export const CaptureExceptionProvider = captureExceptionContext.Provider

export const useCaptureException = () => useContext(captureExceptionContext)
