import React, { useCallback, useState } from 'react'

import { WindowSizeContainer } from '@/containers/WindowSizeContainer'
import { getDeviceType } from '@/utils'
import {
  Modal as _Modal,
  RadioGroup as _RadioGroup,
  WeakColorSeparator,
  PrimaryButton,
  TertiaryButton,
} from '@blue-agency/rogue'
import styled from 'styled-components'
import {
  QualityMode,
  QualityModeLow,
  QualityModeNormal,
} from '@/services/interviewService/types/Quality'

export type QualityModeModalProps = {
  active: boolean
  onClose: () => void
  onOk: (val: QualityMode) => Promise<void>
  qualityMode: QualityMode
}

const RadioLabel = styled.span`
  font-size: 14px;
`

const options = [
  {
    value: QualityModeNormal,
    label: <RadioLabel>通常モード</RadioLabel>,
  },
  {
    value: QualityModeLow,
    label: <RadioLabel>軽量モード</RadioLabel>,
  },
]

export const QualityModeModal: React.FCX<QualityModeModalProps> = ({
  className,
  active,
  onClose,
  onOk,
  qualityMode,
}) => {
  const { isPortrait, width } = WindowSizeContainer.useContainer()

  const isSpLayout = getDeviceType({ windowWidth: width, isPortrait }) !== 'pc'

  const [current, setCurrent] = useState(qualityMode)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      e.target.value !== QualityModeLow &&
      e.target.value !== QualityModeNormal
    ) {
      return
    }
    setCurrent(e.target.value)
  }
  const handleOk = useCallback(() => {
    return onOk(current)
  }, [current, onOk])

  return (
    <Modal
      className={className}
      active={active}
      title="軽量モード設定"
      onClose={onClose}
      size="l"
      isSpLayout={isSpLayout}
    >
      <Wrapper>
        <Upper isSpLayout={isSpLayout}>
          <Row>
            <Description>
              モード変更は、すべての参加者に対して実行されます。
              <br />
              変更処理に数秒かかる場合がありますが、すべての参加者が自動的に新しいモードに切り替わります。
            </Description>
          </Row>
          <Row>
            <RadioGroup
              name="quality-mode"
              defaultValue={qualityMode}
              onChange={handleChange}
              direction="column"
              options={options}
            />
          </Row>
          <Row>
            <RadioDesc>
              画質を落として、通信速度が低い状態でもつながりやすくします。
            </RadioDesc>
          </Row>
        </Upper>
        <WeakColorSeparator />
        <Bottom>
          <ButtonGroup>
            <CloseButton
              comlinkPushParams={{ action: 'close_QualityModeModal' }}
              size="l2"
              onClick={onClose}
            >
              キャンセル
            </CloseButton>
            <PrimaryButton
              disabled={current === qualityMode}
              comlinkPushParams={{ action: 'ok_QualityModeModal' }}
              size="l2"
              onClick={handleOk}
            >
              保存
            </PrimaryButton>
          </ButtonGroup>
        </Bottom>
      </Wrapper>
    </Modal>
  )
}

const Modal = styled(_Modal)<{ isSpLayout: boolean }>`
  width: ${({ isSpLayout }) => (isSpLayout ? '360px' : '700px')};
  max-width: 90%;
  max-height: 90%;
`
const Row = styled.div`
  display: flex;
  align-items: center;
`

const Wrapper = styled.div``
const Upper = styled.div<{ isSpLayout: boolean }>`
  padding: ${({ isSpLayout }) => (isSpLayout ? '20px 16px' : '21px 20px 50px')};
`
const Bottom = styled.div`
  padding: 20px 16px;
`
const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const CloseButton = styled(TertiaryButton)`
  margin-right: 10px;
`

const Description = styled.span`
  font-size: 14px;
  line-height: 20px;
`

const RadioDesc = styled.span`
  margin-top: 8px;
  margin-left: 24px;
  .mobile & {
    margin-left: ${24 + 8}px;
  }
  font-size: 12px;
`

const RadioGroup = styled(_RadioGroup)`
  margin-top: 30px;
`
