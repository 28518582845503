import React, { FC } from 'react'

import { InterviewConfig } from './types'

import { useInitialize } from './hooks/initialize/useInitialize'
import type { ComlinkPush } from './comlink'

export type InterviewRootProps = {
  config: InterviewConfig
  captureException: (e: Error) => void
  comlinkPush: ComlinkPush
}

export const InterviewRoot: FC<InterviewRootProps> = ({
  config,
  captureException,
  comlinkPush,
  children,
}) => {
  useInitialize(config, captureException, comlinkPush)

  return <>{children}</>
}
