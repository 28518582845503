import React from 'react'
import styled from 'styled-components'
import { Icon, theme, Txt, PrimaryButton, NewTabLink } from '@blue-agency/rogue'
import { Tooltip } from '@/components/Tooltip'
import { TOOLTIP_IDS } from '@/constants/tooltip'
import { EXTERNAL_URLS } from '@/services/urlService'
import { useUsedInterviewOrHallCount } from './hooks/useUsedInterviewOrHallCount'
import type { Quota } from '@/services/quotaService'

export type CountTarget = 'interview' | 'hall'

export type Props = {
  type: CountTarget
}

export const UsedInterviewOrHallCount: React.FCX<Props> = ({
  className,
  type,
}) => {
  const { quota, readyCount, prevRecords } = useUsedInterviewOrHallCount(type)

  const tooltipId =
    type === 'interview'
      ? TOOLTIP_IDS.interviewCountDuringContractPeriodOnInterviewList
      : TOOLTIP_IDS.hallCountDuringContractPeriodOnHallList

  const typeJapanese = type === 'interview' ? '面接' : '説明会'

  return (
    <Wrapper className={className}>
      {quota.isAvailablePlan && (
        <>
          <Header>
            {quota.isExhausted && (
              <Row>
                <ReachQuotaLimitBox>
                  <Txt size="s" color={theme.color.red['2']}>
                    利用可能数が上限に到達しました。追加利用をご希望の場合、以下よりお問い合わせください。
                  </Txt>
                  <NewTabLink
                    href={EXTERNAL_URLS.supportDesk.general}
                    hideIcon
                    action="open_general_support_desk_from_quota_exhausted"
                  >
                    <InquiryButton
                      text="お問い合わせ"
                      size="m1"
                      comlinkPushParams={{
                        action:
                          'click_open_general_support_desk_from_quota_exhausted_button',
                      }}
                    />
                  </NewTabLink>
                </ReachQuotaLimitBox>
              </Row>
            )}
            <Row>
              <BaselineDiv>
                <BaselineTxt size="s">利用可能数</BaselineTxt>
                <IconWrapperLarge data-tip data-for={tooltipId}>
                  <QuestionIcon size="l" />
                </IconWrapperLarge>
                <Tooltip id={tooltipId} arrowPosition="topLeft">
                  <TooltipTxt>
                    こちらに表示されている件数まで、{typeJapanese}
                    を実施することができます。
                    <br />「{typeJapanese}
                    の実施」の集計ですので、新規登録だけではカウントされず、実際に接続を開始されたもののみカウントされます。
                  </TooltipTxt>
                </Tooltip>
              </BaselineDiv>
              <BaselineTxt>
                {quota.isUnlimited ? (
                  <UnlimitedQuota>無制限</UnlimitedQuota>
                ) : (
                  <RemainingCount quota={quota} />
                )}
              </BaselineTxt>
            </Row>
          </Header>
          <Middle>
            <Row>
              <BaselineTxt>
                {quota.isUnlimited ? '累計実施数' : '累計実施数 / 上限'}
              </BaselineTxt>
              <BaselineTxt>
                <span>{quota.formatFraction()}</span>
                <Unit>件</Unit>
              </BaselineTxt>
            </Row>
            {readyCount && (
              <Row>
                <BaselineDiv>
                  <BaselineTxt size="s">有効面接登録数</BaselineTxt>
                  <IconWrapperSmall
                    data-tip
                    data-for={TOOLTIP_IDS.interviewCountOnReadyOnInterviewList}
                  >
                    <QuestionIcon size="l" />
                  </IconWrapperSmall>
                  <Tooltip
                    id={TOOLTIP_IDS.interviewCountOnReadyOnInterviewList}
                    arrowPosition="topLeft"
                  >
                    <TooltipTxt>
                      実行可能な面接数です。「面接を終了」「未実施の面接を削除」「有効期限切れ」の場合にカウントから除外されます。
                    </TooltipTxt>
                  </Tooltip>
                </BaselineDiv>
                <BaselineTxt>
                  <span>{readyCount.formatFraction()}</span>
                  <Unit>件</Unit>
                </BaselineTxt>
              </Row>
            )}
          </Middle>
        </>
      )}
      <Bottom>
        <BottomTitle>直近の利用実績</BottomTitle>
        {/* prevRecords は常に3か月分の情報をもっている */}
        {prevRecords.map((record) => {
          return (
            <Row key={record.formatMonth()}>
              <BaselineTxt>{record.formatMonth()}</BaselineTxt>
              <BaselineTxt>
                <span>{record.formatCount()}</span>
                <Unit>件</Unit>
              </BaselineTxt>
            </Row>
          )
        })}
      </Bottom>
    </Wrapper>
  )
}

const RemainingCount = (props: { quota: Quota }) => {
  const { quota } = props
  const count = quota.formatRemainingCount()
  return (
    <>
      {quota.exceeded ? (
        <RemainingExceeded data-testid="remaining-count-1">
          超過
        </RemainingExceeded>
      ) : (
        <Remaining data-testid="remaining-count-1">残</Remaining>
      )}
      {quota.isRemainingCountFew ? (
        <FewRemainingCount data-testid="remaining-count-2">
          {count}
        </FewRemainingCount>
      ) : (
        <NormalRemainingCount data-testid="remaining-count-2">
          {count}
        </NormalRemainingCount>
      )}
      <UnitMaybeRed exceeded={quota.exceeded} data-testid="remaining-count-3">
        件
      </UnitMaybeRed>
    </>
  )
}

const Wrapper = styled.div`
  width: 100%;
  background-color: white;
  border: 1px solid ${theme.color.gray['4']};
`

const Header = styled.div`
  padding: 16px 14px 8px;
`

const Middle = styled.div`
  border-top: 1px solid ${theme.color.gray['4']};
  padding: 16px 14px;
`

const Bottom = styled.div`
  border-top: 1px solid ${theme.color.gray['4']};
  padding: 16px 14px;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${Header} &:not(:last-child) {
    margin-bottom: 15px;
  }

  ${Middle} &:not(:last-child) {
    margin-bottom: 9px;
  }

  ${Bottom} &:not(:last-child) {
    margin-bottom: 4px;
  }
`

const ReachQuotaLimitBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${theme.color.red['3']};
  background: ${theme.color.red['4']};
  box-sizing: border-box;
  padding: 9px 13px 12px 13px;
`

const InquiryButton = styled(PrimaryButton)`
  margin-top: 8px;
`

const TooltipTxt = styled(Txt)`
  width: 300px;
`

const BaselineDiv = styled.div`
  display: flex;
  align-items: baseline;
  position: relative;
`
const BaselineTxt = styled(Txt)`
  display: flex;
  align-items: baseline;
  position: relative;
`
BaselineTxt.defaultProps = { size: 'xs' }

const IconWrapperLarge = styled.div`
  position: absolute;
  right: -25px;
  top: -3px;
`

const IconWrapperSmall = styled.div`
  position: absolute;
  right: -25px;
  top: -6px;
`

const BottomTitle = styled(BaselineTxt)`
  ${Bottom} & {
    margin-bottom: 7px;
  }
`

const UnlimitedQuota = styled.span`
  font-size: ${theme.fontSize.l};
`

const NormalRemainingCount = styled.span`
  color: ${theme.color.green['4']};
  font-size: ${theme.fontSize.xxl};
`

const FewRemainingCount = styled.span`
  color: ${theme.color.red['2']};
  font-size: ${theme.fontSize.xxl};
`

const Remaining = styled.span`
  margin-right: 4px;
`

const RemainingExceeded = styled(Remaining)`
  color: ${theme.color.red['2']};
`

const Unit = styled.span`
  margin-left: 4px;
`

const UnitMaybeRed = styled(Unit)<{ exceeded: boolean }>`
  color: ${(props) => (props.exceeded ? theme.color.red['2'] : undefined)};
`

const QuestionIcon = styled(Icon).attrs({ name: 'question' })`
  margin-left: 4px;
`
