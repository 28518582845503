import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { INTERNAL_PATHS } from '@/services/urlService'
import { NewParticipantPage } from './NewParticipantPage'
import { RoomPage } from './RoomPage'
import { FinishPage } from './FinishPage'
import { NotFound } from '@/components/NotFound'
import { AlreadyFinishedPage } from './AlreadyFinishedPage'

export const EntranceRoutes = () => {
  return (
    <Switch>
      <Route
        exact
        path={INTERNAL_PATHS.participant.halls.entrance.newParticipant}
        component={NewParticipantPage}
      />
      <Route
        exact
        path={INTERNAL_PATHS.participant.halls.entrance.room}
        component={RoomPage}
      />
      <Route
        exact
        path={INTERNAL_PATHS.participant.halls.entrance.finish}
        component={FinishPage}
      />
      <Route
        exact
        path={INTERNAL_PATHS.participant.halls.entrance.alreadyFinished}
        component={AlreadyFinishedPage}
      />
      <Route path="*" component={NotFound} />
    </Switch>
  )
}
