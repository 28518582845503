import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { theme, Txt, NewTabLink, A } from '@blue-agency/rogue'
import { EXTERNAL_URLS } from '@/services/urlService'
import { Logo as _Logo } from '@/components/Logo'
import { Term } from '@blue-agency/proton/web/v2/yashiori_bff/plan_data_pb'

type Props = {
  onBack: () => void
  email: string
  term: Term
}

export const Submitted: React.FCX<Props> = (props) => {
  const senderEmail = 'noreply@interview-maker.com'
  const linkProps = useMemo(() => {
    switch (props.term) {
      case Term.RIKUNABI2022:
        return {
          href: EXTERNAL_URLS.rikunabi22SupportDesk,
          action: 'open_rikubabi_22_support_desk_page',
        }
      case Term.RIKUNABI2023:
        return {
          href: EXTERNAL_URLS.rikunabi23SupportDesk,
          action: 'open_rikubabi_23_support_desk_page',
        }
      case Term.RIKUNABI2023SPECIAL:
        return {
          href: EXTERNAL_URLS.rikunabi23SpecialSupportDesk,
          action: 'open_rikunabi_23_special_support_desk_page',
        }
      default:
        throw new Error('Invalid plan')
    }
  }, [props.term])
  return (
    <Wrapper className={props.className}>
      <Logo />
      <Txt size="s">
        認証メールを送信しました。
        <br />
        メール記載のURLから企業情報を登録してください。
      </Txt>
      <Box>
        <Txt size="s">登録メールアドレス</Txt>
        <Email bold>{props.email}</Email>
        <Spacer />
        <Txt size="s" color={theme.color.gray[1]}>
          送信元アドレス
        </Txt>
        <Email size="s" color={theme.color.gray[1]}>
          {senderEmail}
        </Email>
      </Box>
      <CautionWrapper>
        <Caution size="xs" color={theme.color.red[2]}>
          ※セキュリティ対策のため、24時間以内に企業情報の登録を完了させてください。
        </Caution>
        <Caution size="xs" color={theme.color.red[2]}>
          ※メールが届かない場合は、入力画面から再度登録するか、
          <NewTabLink {...linkProps}>サポートデスク</NewTabLink>
          までお問い合わせください。
        </Caution>
      </CautionWrapper>
      <BackLink
        onClick={props.onBack}
        comlinkPushParams={{ action: 'back_to_input_step' }}
      >
        入力画面に戻る
      </BackLink>
    </Wrapper>
  )
}

const flex = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const Wrapper = styled.div`
  ${flex}
  width: 326px;
  @media (min-width: 1024px) {
    width: 436px;
  }
  padding: 30px 20px 15px;
  background: ${theme.color.white[1]};
  border-radius: 4px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
`
const Logo = styled(_Logo)`
  width: 100px;
  margin-bottom: 15px;
`
const Box = styled.div`
  ${flex}
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  background: ${theme.color.green[8]};
`
const Email = styled(Txt)`
  word-break: break-all;
`
const Spacer = styled.div`
  margin-top: 10px;
`
const BackLink = styled(A)`
  margin-top: 20px;
  cursor: pointer;
  font-size: ${theme.fontSize.xs};
  text-decoration: underline;
`
const CautionWrapper = styled.div`
  margin-top: 10px;
  & > :not(:first-of-type) {
    margin-top: 6px;
  }
`
const Caution = styled(Txt)``
