import { useCallback, useEffect } from 'react'
import {
  AudioVideoObserver,
  AudioVideoFacade,
} from '@blue-agency/interview-sdk-js'
import { VideoTileState } from 'amazon-chime-sdk-js'
import { Participant } from '@/lib/react-interview-sdk/types/Participant'
import { assertIsDefined } from '@/lib/react-interview-sdk/utils/assertions'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  mainVideoState,
  otherParticipantsOfOtherRoleState,
  otherParticipantsOfSameRoleState,
  ownParticipantState,
  pinnedTileState,
  remoteVideoTileState,
} from '@/lib/react-interview-sdk/states'

import { AudioVideoContainer } from '@/lib/react-interview-sdk/containers/AudioVideoContainer'
import { logger } from '@/lib/react-interview-sdk/logger'

export const MAIN_TILE_ID = 'main'

const findOrCreateMainVideoTile = (
  audioVideo: AudioVideoFacade,
  tileId: number | null
) => {
  if (tileId) {
    const tile = audioVideo.getVideoTile(tileId)
    if (tile) return tile
  }

  const newTile = audioVideo.addVideoTile()
  newTile.stateRef().boundAttendeeId = MAIN_TILE_ID
  return newTile
}

export const useMainVideo = () => {
  const { audioVideo } = AudioVideoContainer.useContainer()

  const ownParticipant = useRecoilValue(ownParticipantState)
  const otherParticipantsOfSameRole = useRecoilValue(
    otherParticipantsOfSameRoleState
  )
  const otherParticipantsOfOtherRole = useRecoilValue(
    otherParticipantsOfOtherRoleState
  )

  const [mainVideo, setMainVideo] = useRecoilState(mainVideoState)

  const set = useCallback(
    (tileId: number) => {
      if (!audioVideo) return
      if (mainVideo.mainVideoTileId === tileId) return
      const mainTile = findOrCreateMainVideoTile(
        audioVideo,
        mainVideo.mainVideoTileId
      )

      setMainVideo((prev) => ({ ...prev, mainVideoTileId: mainTile.id() }))

      const tileState = audioVideo.getVideoTile(tileId)?.state()
      if (tileState === undefined) {
        return
      }
      if (
        tileState.boundVideoStream &&
        mainTile.state().boundVideoStream !== tileState.boundVideoStream
      ) {
        mainTile.bindVideoStream(
          MAIN_TILE_ID,
          false,
          tileState.boundVideoStream,
          tileState.videoStreamContentWidth,
          tileState.videoStreamContentHeight,
          null
        )
      }

      setMainVideo((prev) => ({ ...prev, pinnedTileId: tileId }))
    },
    [audioVideo, mainVideo.mainVideoTileId, setMainVideo]
  )
  const setDefault = useCallback(() => {
    if (!audioVideo) return
    // メインビデオの挙動: https://stadium.kibe.la/notes/10462

    const attendeeIdToTileId: Record<string, number> = Object.fromEntries(
      audioVideo
        .getAllRemoteVideoTiles()
        .filter(
          (t) =>
            t.state().boundAttendeeId &&
            t.state().boundAttendeeId !== MAIN_TILE_ID
        )
        .map((t) => [t.state().boundAttendeeId, t.id()])
    )

    const isActiveParticipant = (p: Participant) =>
      !!attendeeIdToTileId[p.soraClientId]
    const isContentShare = (p: Participant) =>
      p.role === 'applicant_screen' || p.role === 'recruiter_screen'

    const findFrom = otherParticipantsOfOtherRole.concat(
      otherParticipantsOfSameRole
    )

    const contentShareCandidate = findFrom.find(
      (p) => isContentShare(p) && isActiveParticipant(p)
    )
    if (contentShareCandidate !== undefined) {
      const tileId = attendeeIdToTileId[contentShareCandidate.soraClientId]
      assertIsDefined(tileId)
      return set(tileId)
    }

    const participantCandidate = findFrom.find(isActiveParticipant)
    if (participantCandidate !== undefined) {
      const tileId = attendeeIdToTileId[participantCandidate.soraClientId]
      assertIsDefined(tileId)
      return set(tileId)
    }

    const selfId = audioVideo.getLocalVideoTile()?.id()
    if (selfId !== undefined) {
      return set(selfId)
    }
  }, [
    audioVideo,
    otherParticipantsOfOtherRole,
    otherParticipantsOfSameRole,
    set,
  ])

  const update = useCallback(
    (tileState: VideoTileState) => {
      if (!audioVideo) return
      const mainTile = findOrCreateMainVideoTile(
        audioVideo,
        mainVideo.mainVideoTileId
      )

      if (mainTile.state().boundVideoStream !== tileState.boundVideoStream) {
        mainTile.bindVideoStream(
          MAIN_TILE_ID,
          false,
          tileState.boundVideoStream,
          tileState.videoStreamContentWidth,
          tileState.videoStreamContentHeight,
          null
        )
      }

      setMainVideo((prev) => ({ ...prev, mainVideoTileId: mainTile.id() }))
    },
    [audioVideo, mainVideo.mainVideoTileId, setMainVideo]
  )

  useEffect(() => {
    if (!audioVideo) {
      return
    }

    const observer: AudioVideoObserver = {
      videoTileDidUpdate: (tileState) => {
        if (!audioVideo) return
        if (tileState.boundAttendeeId === MAIN_TILE_ID) return

        // ピンされているタイルにstreamの変更などがあったらメインビデオにも反映させる
        if (
          mainVideo.pinnedTileId &&
          tileState.tileId === mainVideo.pinnedTileId
        ) {
          update(tileState)
          return
        }
      },
      videoTileWasRemoved(tileId: number) {
        // ピンされている人が退室したらデフォルトに設定する
        if (!audioVideo) return
        if (tileId === mainVideo.pinnedTileId) {
          setDefault()
        }
      },
    }
    audioVideo.addObserver(observer)

    return () => {
      audioVideo?.removeObserver(observer)
    }
  }, [
    audioVideo,
    otherParticipantsOfOtherRole,
    otherParticipantsOfSameRole,
    update,
    setDefault,
    mainVideo.pinnedTileId,
  ])

  useEffect(() => {
    if (!audioVideo) {
      return
    }
    setDefault()

    // TODO: ownParticipantをdepsから除く. ownParticipantがないと参加者が自分一人で最初のレンダリングをした時にメインビデオが表示されない
    // amazon-chime-sdk-jsの状態変化に応じるように変更すれば解決するはず
  }, [
    audioVideo,
    otherParticipantsOfOtherRole,
    otherParticipantsOfSameRole,
    ownParticipant,
    setDefault,
  ])

  useEffect(() => {
    if (!audioVideo) return
    if (!mainVideo.pinnedTileId) return
    set(mainVideo.pinnedTileId)
  }, [audioVideo, mainVideo.pinnedTileId, set])
}

export const useResetMainVideo = () => {
  const setMainVideo = useSetRecoilState(mainVideoState)
  return useCallback(() => {
    setMainVideo({ mainVideoTileId: null, pinnedTileId: null })
  }, [setMainVideo])
}

export const usePinByAttendeeId = () => {
  const { attendeeIdToTileId } = useRecoilValue(remoteVideoTileState)
  const setPinnedTile = useSetRecoilState(pinnedTileState)

  return useCallback(
    (attendeeId: string) => {
      logger.log('usePinByAttendeeId', attendeeId)
      const tileId = attendeeIdToTileId[attendeeId]
      if (tileId) {
        setPinnedTile(tileId)
      }
    },
    [attendeeIdToTileId, setPinnedTile]
  )
}
