import React from 'react'
import { Layout } from '@/components/Layout'
import { HelpLinkIcon, theme } from '@blue-agency/rogue'
import { EXTERNAL_URLS } from '@/services/urlService'
import { provide } from '@blue-agency/front-state-management'
import { StepContainer } from './hooks/useStep'

export const Preparing: React.FCX = provide(StepContainer)(({ className }) => {
  const { content, step } = StepContainer.useContainer()
  return (
    <Layout
      className={className}
      rightNode={
        <HelpLinkIcon
          href={EXTERNAL_URLS.help.participants.interview}
          action="open_participant_interview_help_page"
        />
      }
      notFixHeight
      backgroundColor={
        step === 'waiting' ? theme.color.white[1] : theme.color.gray[5]
      }
    >
      {content}
    </Layout>
  )
})
