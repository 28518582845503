import { useCachedPromise } from '@blue-agency/front-state-management'
import { cacheKey } from '@/services/bffService'
import {
  HallsCount,
  InterviewsCount,
  OrganizerServiceContainer,
} from '@/containers/OrganizerServiceContainer'
import type { PrevRecord as BasePrevRecord } from '@/containers/OrganizerServiceContainer'
import type { CountTarget } from '..'
import { format as dateFormat } from 'date-fns'
import { Quota } from '@/services/quotaService'

type CountResponse = {
  quota: Quota
  readyCount: ReadyCount | undefined
  prevRecords: PrevRecord[]
}

export const useUsedInterviewOrHallCount = (
  countTarget: CountTarget
): CountResponse => {
  const { getOrganizer } = OrganizerServiceContainer.useContainer()
  const count = useCount(countTarget)
  const organizer = useCachedPromise(cacheKey.getOrganizer(), () =>
    getOrganizer()
  )

  const quota = new Quota(count.contractPeriod)

  const readyCount =
    'countOnReady' in count
      ? new ReadyCount(
          organizer.interviewQuota.maximumReadyInterviews,
          count.countOnReady
        )
      : undefined

  return {
    quota,
    readyCount,
    prevRecords: PrevRecord.fromArray(count.prevRecordsList),
  }
}

const useCount = (countTarget: CountTarget) => {
  const { getInterviewsCount, getHallsCount } =
    OrganizerServiceContainer.useContainer()

  const key =
    countTarget === 'hall'
      ? cacheKey.getHallsCount()
      : cacheKey.getInterviewsCount()

  const promise = countTarget === 'hall' ? getHallsCount : getInterviewsCount

  return useCachedPromise<HallsCount | InterviewsCount>(key, promise)
}

class ReadyCount {
  constructor(private _maximum: number, private _currentCount: number) {}

  /**
   * 面接登録数の最大許容値を返す
   */
  get maximum(): number {
    return this._maximum
  }

  /**
   * 現在の面接登録数を返す
   */
  get currentCount(): number {
    return this._currentCount
  }

  /**
   * `123 / 4567` のような文字列形式で `現在の面接登録数 / 面接登録数の最大許容値` を返す
   */
  formatFraction(): string {
    return `${this._currentCount.toLocaleString()} / ${this._maximum.toLocaleString()}`
  }
}

class PrevRecord {
  constructor(private _month: Date, private _count?: number) {}

  static from(base: BasePrevRecord): PrevRecord {
    return new PrevRecord(base.targetMonth, base.count)
  }

  static fromArray(baseList: BasePrevRecord[]): PrevRecord[] {
    return baseList.map((b) => PrevRecord.from(b))
  }

  /**
   * 月を yyyy年M月 の形の文字列で返す
   */
  formatMonth(): string {
    return dateFormat(this._month, 'yyyy年M月')
  }

  /**
   * count がない場合は `-` を、ある場合は `1,234` の形式の文字列で返す
   */
  formatCount(): string {
    return this._count === undefined ? '-' : this._count.toLocaleString()
  }
}
