import React from 'react'
import styled from 'styled-components'
import {
  Txt,
  theme,
  WeakColorSeparator as _WeakColorSeparator,
} from '@blue-agency/rogue'
import { ThousandSeparatedNum } from '@/components/ThousandSeparatedNum'
import {
  basicItems,
  adminItems,
  billingItems,
  newHiresNumberItems,
  getInputtedTxt,
} from '@/components/OrganizerForm/formItems'
import { OrganizerForm, PlanInfo } from '../types'

type Props = {
  planInfo: PlanInfo
  form: OrganizerForm
  hideBillingBlock?: boolean
  hideReferrer?: boolean
}

export const ConfirmBody: React.FCX<Props> = ({
  className,
  form,
  planInfo,
  hideBillingBlock = false,
  hideReferrer = false,
}) => {
  return (
    <Wrapper className={className}>
      <Txt size="s" color={theme.color.red[2]}>
        まだ申込みは完了しておりません。
        <br />
        入力内容を確認して「この内容で申込む」ボタンを押してください。
      </Txt>
      <WeakColorSeparator />
      <Heading>■ 基本情報</Heading>
      <Inner>
        <Block>
          <ItemGroup>
            <Item>
              <Label>申込内容</Label>
              <ItemLabel>利用プラン</ItemLabel>
              <Txt>{planInfo.label}</Txt>
            </Item>
            <Item>
              <ItemLabel>ご契約期間</ItemLabel>
              <Txt>{planInfo.term}</Txt>
            </Item>
            <Item>
              <ItemLabel>合計金額</ItemLabel>
              <Txt>
                <>
                  <ThousandSeparatedNum number={planInfo.price} />
                  {planInfo.price === 0 ? '円' : '円（税抜）'}
                </>
              </Txt>
            </Item>
            {!hideReferrer && (
              <Item>
                <ItemLabel>ご紹介元</ItemLabel>
                <Txt>{form.referrer}</Txt>
              </Item>
            )}
            {planInfo.couponCode && (
              <Item>
                <ItemLabel>クーポンコード</ItemLabel>
                <Txt>{planInfo.couponCode}</Txt>
              </Item>
            )}
          </ItemGroup>
        </Block>
        <WeakColorSeparator />
        <Block>
          <ItemGroup>
            {basicItems.map((item, index) => (
              <Item key={index}>
                <Label>{index === 0 && '契約者情報'}</Label>
                <ItemLabel>{item.label}</ItemLabel>
                <Txt>{getInputtedTxt(form, item)}</Txt>
              </Item>
            ))}
          </ItemGroup>
        </Block>
        <WeakColorSeparator />
        <Block>
          <ItemGroup>
            {adminItems.map((item, index) => (
              <Item key={index}>
                <Label>{index === 0 && '管理者情報'}</Label>
                <ItemLabel>{item.label}</ItemLabel>
                <Txt>{getInputtedTxt(form, item)}</Txt>
              </Item>
            ))}
          </ItemGroup>
        </Block>
        {!hideBillingBlock && (
          <>
            <WeakColorSeparator />
            <Block>
              <ItemGroup>
                {billingItems.map((item, index) => (
                  <Item key={index}>
                    <Label>{index === 0 && '請求先情報'}</Label>
                    <ItemLabel>{item.label}</ItemLabel>
                    <Txt>{getInputtedTxt(form, item)}</Txt>
                  </Item>
                ))}
              </ItemGroup>
            </Block>
          </>
        )}
        <WeakColorSeparator />
        <Block>
          <ItemGroup>
            {newHiresNumberItems.map((item, index) => (
              <Item key={index}>
                <Label>{index === 0 && '採用状況'}</Label>
                <ItemLabel>{item.label}</ItemLabel>
                <Txt>{getInputtedTxt(form, item)}</Txt>
              </Item>
            ))}
          </ItemGroup>
        </Block>
      </Inner>
      <ConfirmTxt>
        この内容でよろしければ、「この内容で応募する」ボタンを押してください。
      </ConfirmTxt>
    </Wrapper>
  )
}
const Wrapper = styled.div``
const Inner = styled.div`
  background-color: ${theme.color.white[1]};
  border: 1px solid ${theme.color.gray[4]};
  border-radius: 4px;
  padding: 16px;
`
const Block = styled.div`
  ${Wrapper} &:nth-of-type(n + 2) {
    margin-top: 0;
  }
`
const Heading = styled(Txt)`
  ${Wrapper} & {
    margin-bottom: 14px;
  }
`
const Label = styled(Txt)`
  ${Wrapper} & {
    margin-bottom: 12px;
  }
`
Label.defaultProps = { size: 's', bold: true }
const ItemGroup = styled.div``
const Item = styled.div`
  ${ItemGroup} &:nth-of-type(n + 2) {
    margin-top: 20px;
  }
`
const WeakColorSeparator = styled(_WeakColorSeparator)`
  margin: 16px 0;
`
const ConfirmTxt = styled(Txt)`
  ${Wrapper} & {
    margin: 12px 0 28px;
  }
`
ConfirmTxt.defaultProps = { size: 's' }
const ItemLabel = styled(Txt)`
  white-space: nowrap;
`
ItemLabel.defaultProps = { size: 's', bold: true }
