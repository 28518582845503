import React from 'react'
import { useCallback } from 'react'
import styled from 'styled-components'
import { useTitle } from '@/hooks/useTitle'
import { useParams, useHistory } from 'react-router-dom'
import { Layout } from '@/components/Layout'
import { H1 } from '@/components/Heading'
import {
  EXTERNAL_URLS,
  INTERNAL_PATHS,
  fillParams,
} from '@/services/urlService'
import { theme, NewTabLink, Txt, PrimaryButton } from '@blue-agency/rogue'
import { cacheKey } from '@/services/bffService'
import { useCachedPromise } from '@blue-agency/front-state-management'
import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'

export const ExpiredPage: React.FCX = ({ className }) => {
  const orgService = OrganizerServiceContainer.useContainer()
  const history = useHistory()
  const { promotionGuid } = useParams<{ promotionGuid?: string }>()
  if (!promotionGuid) throw new Error('promotionGuid not found')
  const promotion = useCachedPromise(
    cacheKey.getPromotionForRikunabiPackage({ promotionGuid }),
    () => orgService.getPromotionForRikunabiPackage({ promotionGuid })
  )
  useTitle(`「${promotion.planName}」新規申込`)
  const handleClickReRegister = useCallback(() => {
    history.push(
      fillParams({
        path: INTERNAL_PATHS.organizer.rikunabi.registerEmail,
        params: {
          promotionGuid,
        },
      })
    )
  }, [history, promotionGuid])

  return (
    <Layout
      className={className}
      notFixHeight
      backgroundColor={theme.color.white[1]}
    >
      <HeadingWrapper>
        <H1>「{promotion.planName}」新規申込</H1>
      </HeadingWrapper>
      <Container>
        <Subject>登録有効期限が過ぎたため登録できません。</Subject>
        <Txt>
          再度、申込の手続きをお願いします。
          <br />
          ご不明点がございましたら、
          <NewTabLink href={EXTERNAL_URLS.contact} action="open_contact_page">
            こちら
          </NewTabLink>
          までお問い合わせください。
        </Txt>
        <ButtonWrappter>
          <PrimaryButton
            size="l2"
            text="再登録する"
            onClick={handleClickReRegister}
            comlinkPushParams={{ action: 'click_re_register_button' }}
          />
        </ButtonWrappter>
      </Container>
    </Layout>
  )
}

const HeadingWrapper = styled.div`
  height: 60px;
  padding: 0 100px;
  display: flex;
  align-items: center;
`

const Container = styled.div`
  max-width: 502px;
  margin: 44px auto 0;
`

const Subject = styled(Txt).attrs({ size: 'l' })`
  margin-bottom: 14px;
  text-align: center;
`
const ButtonWrappter = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`
