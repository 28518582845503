import { useEffect, useRef } from 'react'

export const useRefUpdating = <T>(v: T) => {
  const ref = useRef(v)
  useEffect(() => {
    ref.current = v
  }, [v])

  return ref
}
