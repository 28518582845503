import { useMemo, useCallback, useState } from 'react'
import { DropdownOption } from '@blue-agency/rogue'
import { createContainer } from '@blue-agency/front-state-management'
import { InterviewsContainer } from '@/pages/OrganizerRoutes/MyRoutes/InterviewsRoutes/InterviewsPage/hooks/useInterviews'
import { PermissionsContainer } from '@/containers/PermissionsContainer'

const useBulkAction = () => {
  const { hasPermission } = PermissionsContainer.useContainer()
  const { selectedInterviews, selectAllInterviews, unselectAllInterviews } =
    InterviewsContainer.useContainer()

  const [shownDeleteInterviewsModal, setShownDeleteInterviewsModal] =
    useState(false)

  const actions = useMemo(
    () => ({
      deleteInterviews: {
        key: 'deleteInterviews',
        func: () => setShownDeleteInterviewsModal(true),
      },
    }),
    []
  )

  const options = useMemo<DropdownOption[]>(() => {
    const options: DropdownOption[] = []

    if (hasPermission('deletableInterview')) {
      options.push({
        label: '面接の削除',
        value: actions.deleteInterviews.key,
      })
    }

    return options
  }, [actions, hasPermission])

  const [selectedActionKey, setSelectedActionKey] = useState<string>('')

  const count = useMemo(() => selectedInterviews.length, [selectedInterviews])

  const canExecuteAction = useMemo(() => {
    return selectedActionKey !== '' && count > 0
  }, [count, selectedActionKey])

  const hasSelectedInterviews = useMemo(() => count > 0, [count])

  const execute = useCallback(() => {
    const targetAction = Object.values(actions).find(
      (action) => action.key === selectedActionKey
    )

    if (targetAction) {
      targetAction.func()
    }
  }, [actions, selectedActionKey])

  const toggleAll = useCallback(() => {
    if (hasSelectedInterviews) {
      unselectAllInterviews()
    } else {
      selectAllInterviews()
    }
  }, [hasSelectedInterviews, selectAllInterviews, unselectAllInterviews])

  const closeDeleteInterviewsModal = useCallback(() => {
    setShownDeleteInterviewsModal(false)
  }, [])

  return {
    count,
    hasSelectedInterviews,
    canExecuteAction,
    options,
    execute,
    toggleAll,
    setSelectedActionKey,

    // 面接を削除 用
    shownDeleteInterviewsModal,
    closeDeleteInterviewsModal,
  }
}

export const BulkActionContainer = createContainer(useBulkAction)
