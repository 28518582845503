import React from 'react'
import styled from 'styled-components'
import { Txt, theme } from '@blue-agency/rogue'

type Props = {
  entryNumber: string
  backgroundColor: string
}

export const InterviewEntryNumber: React.FCX<Props> = ({
  className,
  entryNumber,
  backgroundColor,
}) => {
  return (
    <Wrapper className={className} backgroundColor={backgroundColor}>
      <Txt size="s" bold color={theme.color.white[1]}>
        {entryNumber}
      </Txt>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ backgroundColor: string }>`
  width: 16px;
  height: 16px;
  background-color: ${({ backgroundColor }) => backgroundColor}80;
  display: flex;
  justify-content: center;
  align-items: center;
`
