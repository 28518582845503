import React from 'react'
import '@/assets/reset.css'
import '@/assets/theme.css'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { useTracking } from '@/hooks/useTracking'
import { AppRoutes } from '@/pages/AppRoutes'
const history = createBrowserHistory()

const App = () => {
  useTracking(history)

  return (
    <Router history={history}>
      <AppRoutes />
    </Router>
  )
}

export default App
