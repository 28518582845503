import React from 'react'
import { ManualRowType } from '.'
import styled from 'styled-components'
import { theme, Icon as _Icon, Txt } from '@blue-agency/rogue'

type Props = {
  manualRows: ManualRowType[]
}

export const ManualForSp: React.FCX<Props> = (props) => {
  return (
    <ManualWrapper className={props.className}>
      {props.manualRows.map((manualRow) => (
        <ManualRow key={manualRow.title}>
          <IconWrapper>
            <Icon name={manualRow.iconName} size="ll" />
          </IconWrapper>
          <ManualTextWrapper>
            <ManualTitle size={theme.fontSize.l}>{manualRow.title}</ManualTitle>
            <Description size="s">{manualRow.description}</Description>
          </ManualTextWrapper>
        </ManualRow>
      ))}
    </ManualWrapper>
  )
}

const ManualWrapper = styled.div`
  width: 100%;
`
const ManualRow = styled.div`
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-top: 40px;
  }
`
const ManualTextWrapper = styled.div`
  flex: 1;
`
const IconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  background-color: ${theme.color.navy[1]};
  border-radius: 50%;

  margin-right: 16px;
`
const Icon = styled(_Icon)`
  color: ${theme.color.white[1]};
`
const ManualTitle = styled(Txt)`
  margin-bottom: 6px;
`
const Description = styled(Txt)`
  white-space: normal;
`
