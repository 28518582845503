import { Comlink } from '@blue-agency/comlink'
import { ComlinkPushParams as ComlinkPushParamsBase } from '@blue-agency/comlink/dist/src/app'

export type ComlinkPushParams = Omit<ComlinkPushParamsBase, 'group'>
export const comlinkPush = (params: ComlinkPushParams) => {
  return Comlink.push({
    group: 'yashiori',
    ...params,
  })
}
