import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { INTERNAL_PATHS } from '@/services/urlService'
import { HallsPage } from './HallsPage'
import { EntranceRoutes } from './EntranceRoutes'
import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'
import { provide } from '@blue-agency/front-state-management'
import { NotFound } from '@/components/NotFound'

export const HallsRoutes = provide(OrganizerServiceContainer)(() => {
  return (
    <Switch>
      <Route
        exact
        path={INTERNAL_PATHS.organizer.my.halls.index}
        component={HallsPage}
      />

      <Route
        path={INTERNAL_PATHS.organizer.my.halls.entrance.index}
        component={EntranceRoutes}
      />
      <Route path="*" component={NotFound} />
    </Switch>
  )
})
