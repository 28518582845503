import React from 'react'
import styled from 'styled-components'
import { ApplyPageContainer } from '../../ApplyPageContainer'
import { Dropdown, Txt, InputText } from '@blue-agency/rogue'
import { placeholder } from '@/constants/placeholder'
import { InputStepItem } from '../../types'
import { employeesNumberOptions } from '../../options'
import { OrganizerFormItem } from '@/components/OrganizerFormItem'

export const ContractSection: React.FCX = ({ className }) => {
  const { form } = ApplyPageContainer.useContainer()
  return (
    <Wrapper className={className}>
      {contractItems.map((item, index) => (
        <React.Fragment key={index}>
          <OrganizerFormItem
            title={index === 0 ? '契約者情報' : undefined}
            label={item.label}
            required
            inputNode={
              item.name === 'employeesNumberId' ? (
                <Dropdown
                  size="l"
                  name={item.name}
                  ref={form.register}
                  allowEmpty
                  options={item.options || []}
                  placeholder={item.placeholder}
                  onBlurError={form.errors[item.name]?.message}
                />
              ) : (
                <InputText
                  name={item.name}
                  size={(() => {
                    if (item.label === '住所') return 'll'
                    if (item.label === '郵便番号') return 's'
                    return 'l'
                  })()}
                  placeholder={item.placeholder}
                  ref={form.register}
                  onBlurError={form.errors[item.name]?.message}
                />
              )
            }
          />
          {item.notes && <Notes>{item.notes}</Notes>}
        </React.Fragment>
      ))}
    </Wrapper>
  )
}

const contractItems: InputStepItem[] = [
  {
    label: '会社名',
    name: 'name',
    placeholder: placeholder.orgName,
    notes:
      '※「法人格」（株式会社、合同会社など）もご入力をお願いします。㈱などの略称ではなく、正式名称でご入力ください。',
  },
  {
    label: '代表者名',
    name: 'representativeName',
    placeholder: placeholder.name,
  },
  {
    label: '電話番号',
    name: 'phoneNumber',
    placeholder: placeholder.tel,
    notes:
      '※ハイフン(-)なしで入力してください。\n※連絡がとれる窓口番号を入力してください。',
  },
  {
    label: '郵便番号',
    name: 'postCode',
    placeholder: placeholder.postCode,
    notes: '※ハイフン(-)なしで入力してください。',
  },
  {
    label: '住所',
    name: 'address',
    placeholder: placeholder.address,
  },
  {
    label: '従業員数',
    name: 'employeesNumberId',
    placeholder: placeholder.select,
    options: employeesNumberOptions,
  },
]

const Wrapper = styled.div`
  padding: 20px;
`
const Notes = styled(Txt).attrs({ size: 's' })`
  margin-top: 8px;
  margin-left: 320px;
`
