import React from 'react'
import styled, { css } from 'styled-components'
import { Modal as _Modal } from '@blue-agency/rogue'
import { ManualModalContainer } from './containers/ManualModalContainer'
import { WindowSizeContainer } from '@/containers/WindowSizeContainer'

type Props = {
  active: boolean
}
export const ManualModal: React.FCX<Props> = ({ className, active }) => {
  const { handleManualModalClose, manualModalBody } =
    ManualModalContainer.useContainer()
  const { isPortrait, ...windowSize } = WindowSizeContainer.useContainer()

  return (
    <Modal
      className={className}
      active={active}
      onClose={handleManualModalClose}
      titleSize="s"
      title="操作方法"
      windowSize={windowSize}
      isPortrait={isPortrait}
    >
      {manualModalBody}
    </Modal>
  )
}

const Modal = styled(_Modal)<{
  isPortrait: boolean
  windowSize: { width: number; height: number }
}>`
  ${({ isPortrait, windowSize }) =>
    isPortrait
      ? css`
          width: 320px;
          max-width: ${windowSize.width - 20}px;
        `
      : css`
          width: 440px;
        `}
`
