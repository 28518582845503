import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { INTERNAL_PATHS } from '@/services/urlService'
import { NotFound } from '@/components/NotFound'
import { EnvironmentCheckPage } from './EnvironmentCheckPage'
import { MyRoutes } from './MyRoutes'
import { SignupPage } from './SignupPage'
import { InterviewRecordingPage } from './InterviewRecordingPage'
import { UserTypeContainer } from '@/containers/UserTypeContainer'
import { provide } from '@blue-agency/front-state-management'
import { RikunabiRoutes } from './RikunabiRoutes'
import { HallRecordingPage } from './HallRecordingPage'

export const OrganizerRoutes = provide(UserTypeContainer)(() => {
  return (
    <Switch>
      <Route
        path={INTERNAL_PATHS.organizer.rikunabi.index}
        component={RikunabiRoutes}
      />
      <Route
        path={INTERNAL_PATHS.organizer.signup.index}
        component={SignupPage}
      />
      <Route
        exact
        path={INTERNAL_PATHS.organizer.environment}
        component={EnvironmentCheckPage}
      />
      <Route
        exact
        path={INTERNAL_PATHS.organizer.interviewRecording}
        component={InterviewRecordingPage}
      />
      <Route
        exact
        path={INTERNAL_PATHS.organizer.hallRecording}
        component={HallRecordingPage}
      />
      <Route path={INTERNAL_PATHS.organizer.my.index} component={MyRoutes} />
      <Route path="*" component={NotFound} />
    </Switch>
  )
})
