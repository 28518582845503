import React from 'react'
import styled from 'styled-components'
import { Txt as _Txt, theme } from '@blue-agency/rogue'

type Props = {
  current: number
  max: number
}

export const TutorialStep: React.FCX<Props> = ({ className, current, max }) => {
  return (
    <Wrapper className={className}>
      <Txt bold>
        <Span>{current}</Span>/{max}
      </Txt>
    </Wrapper>
  )
}

const Wrapper = styled.div``
const Txt = styled(_Txt)`
  white-space: nowrap;
`
const Span = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: ${theme.color.navy[1]};
  color: ${theme.color.white[1]};
`
