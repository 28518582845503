import React from 'react'
import styled, { css } from 'styled-components'
import { ScreenShareVideoContainer } from '../containers/ScreenShareVideoContainer'
import { EnterContainer } from '../containers/EnterContainer'
import {
  theme,
  TertiaryButton,
  Icon,
  ActiveTertiaryButton,
  HelpLinkIcon,
  Timer,
} from '@blue-agency/rogue'
import { EXTERNAL_URLS } from '@/services/urlService'
import { StartScreenShareModal } from '@/components/StartScreenShareModal'
import { FinishScreenShareModal } from '@/components/FinishScreenShareModal'

export const Right: React.FCX = ({ className }) => {
  const { startTime, currentTime, maxSeconds, handleOvertime } =
    EnterContainer.useContainer()
  const {
    startScreenShare,
    stopScreenShare,
    screenSharing,
    startScreenShareModal,
    finishScreenShareModal,
  } = ScreenShareVideoContainer.useContainer()

  const showScreenShareButton =
    typeof navigator.mediaDevices.getDisplayMedia !== 'undefined'

  return (
    <Wrapper className={className}>
      {startTime && currentTime && (
        <Timer
          startTime={startTime}
          currentTime={currentTime}
          maxSeconds={maxSeconds}
          onFinish={handleOvertime}
        />
      )}
      <IconWrapper>
        <HelpLinkIcon
          href={EXTERNAL_URLS.help.organizer.seminar}
          action="open_organizer_seminar_help_page"
        />
      </IconWrapper>
      <ButtonWrapper>
        {showScreenShareButton &&
          (screenSharing ? (
            <FinishScreenShareButton
              text="画面共有を終了"
              icon={<Icon name="screen-share" />}
              direction="column"
              onClick={finishScreenShareModal.open}
              comlinkPushParams={{ action: 'open_finish_screen_share_modal' }}
            />
          ) : (
            <StartScreenShareButton
              text="画面共有"
              icon={<Icon name="screen-share" />}
              direction="column"
              onClick={startScreenShareModal.open}
              comlinkPushParams={{ action: 'open_start_screen_share_modal' }}
            />
          ))}
        <StartScreenShareModal
          active={startScreenShareModal.active}
          onClose={startScreenShareModal.close}
          onStart={startScreenShare}
        />
        <FinishScreenShareModal
          active={finishScreenShareModal.active}
          onClose={finishScreenShareModal.close}
          onFinish={stopScreenShare}
        />
      </ButtonWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`
const ButtonWrapper = styled.div`
  ${Wrapper} & {
    margin-left: 24px;
  }
`
const IconWrapper = styled.div`
  ${Wrapper} & {
    margin-left: 16px;
  }
  cursor: pointer;
`
const buttonSize = css`
  font-size: ${theme.fontSize.xs};
  padding: 0;
  height: 44px;
  width: 90px;
`
const StartScreenShareButton = styled(TertiaryButton)`
  ${buttonSize}
`
const FinishScreenShareButton = styled(ActiveTertiaryButton)`
  ${buttonSize}
`
