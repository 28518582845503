import React from 'react'
import {
  ErrorMessage as _ErrorMessage,
  InputText,
  Modal,
  PrimaryButton,
  TertiaryButton,
  Txt,
  WeakColorSeparator,
  RequireTxt,
} from '@blue-agency/rogue'
import styled from 'styled-components'
import { useUpdateNameModal } from './hooks/useUpdateNameModal'

type Props = {
  currentName: string
  presentationGuid: string
  onClose: () => void
  onOpen: () => void
}

export const UpdateNameModal: React.FCX<Props> = (props) => {
  const { isSubmitError, onSubmit, register, errors, formState, handleSubmit } =
    useUpdateNameModal(props)

  return (
    // MEMO: 下の PR と同じ理由で常に active={true} を渡す
    //   https://github.com/blue-agency/yashiori-front/pull/1727
    <Modal active onClose={props.onClose} title="説明会動画名編集" size="l">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Upper>
          {isSubmitError && (
            <ErrorMessage text="保存できませんでした。もう一度お試しください。" />
          )}
          <Row>
            <Label>
              <Title>動画名</Title>
              <RequireTxt size="s">必須</RequireTxt>
            </Label>
            <NameInput
              name="name"
              ref={register}
              onBlurError={errors.name?.message}
            />
          </Row>
        </Upper>
        <WeakColorSeparator />
        <Bottom>
          <CancelButton
            onClick={props.onClose}
            comlinkPushParams={{ action: 'cancel_presentation_name_update' }}
          >
            キャンセル
          </CancelButton>
          <SubmitButton
            type="submit"
            loading={formState.isSubmitting}
            disabled={!formState.isValid}
            comlinkPushParams={{ action: 'update_presentation_name' }}
          >
            保存
          </SubmitButton>
        </Bottom>
      </Form>
    </Modal>
  )
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Upper = styled.div`
  ${Form} & {
    width: 660px;
  }
  padding: 40px 20px 50px;
`

const ErrorMessage = styled(_ErrorMessage).attrs({ size: 's' })`
  ${Upper} & {
    margin-top: -20px;
    margin-bottom: 20px;
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
`

const Label = styled.div`
  display: flex;
  justify-content: space-between;

  ${Row} & {
    width: 140px;
    margin-right: 8px;
  }
`

const Title = styled(Txt).attrs({ size: 's' })`
  ${Label} & {
    margin-right: 4px;
  }
`

const NameInput = styled(InputText).attrs({ size: 'max' })`
  ${Upper} & {
    flex: 1;
  }
`

const Bottom = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`

const CancelButton = styled(TertiaryButton).attrs({ size: 'l2' })`
  ${Bottom} & {
    margin-right: 20px;
  }
`

const SubmitButton = styled(PrimaryButton).attrs({ size: 'l2' })``
