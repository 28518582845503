import { createContainer } from '@blue-agency/front-state-management'
import { useState, useCallback } from 'react'
import { downloadCSV } from '@/utils'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { toast } from '@blue-agency/rogue'
import {
  OrganizerServiceContainer,
  CreateInterviewsForKaburikuInterview,
} from '@/containers/OrganizerServiceContainer'
import { StatusCode } from 'grpc-web'

const countValidationErrorMessage = '1〜1000までの半角数字で入力してください'

type Form = {
  count: number
}

const validationSchema: yup.SchemaOf<Form> = yup
  .object()
  .shape({
    count: yup
      .number()
      .required('入力してください')
      .typeError(countValidationErrorMessage)
      .min(1, countValidationErrorMessage)
      .max(1000, countValidationErrorMessage)
      .integer(countValidationErrorMessage),
  })
  .defined()

const useRegister = () => {
  const { createInterviewsForKaburiku } =
    OrganizerServiceContainer.useContainer()
  const { register, handleSubmit, errors, formState } = useForm<Form>({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
    defaultValues: { count: 100 },
  })
  const [showResult, setShowResult] = useState(false)
  const [showExhaustedError, setShowExhaustedError] = useState(false)
  const [interviews, setInterviews] = useState<
    CreateInterviewsForKaburikuInterview[]
  >([])
  const [csv, setCsv] = useState<Uint8Array>()

  const onSubmit = useCallback<SubmitHandler<Form>>(
    async (data) => {
      setShowExhaustedError(false)
      try {
        const res = await createInterviewsForKaburiku({
          args: { count: data.count },
        })
        setCsv(res.csv)
        setInterviews(res.interviews)
      } catch (e) {
        switch (e.code) {
          case StatusCode.PERMISSION_DENIED:
            return
          case StatusCode.RESOURCE_EXHAUSTED:
            setShowExhaustedError(true)
            return
          default:
            throw e
        }
      }
      setShowResult(true)
    },
    [createInterviewsForKaburiku]
  )
  const handleDownloadInterviewsList = useCallback(() => {
    if (csv === undefined) return
    const fileName = '面接リスト'
    downloadCSV({
      data: csv,
      fileName: `${fileName}.csv`,
    })
    toast('面接リストをダウンロードしました')
  }, [csv])

  return {
    showResult,
    showExhaustedError,
    register,
    handleSubmit,
    errors,
    formState,
    onSubmit,
    interviews,
    handleDownloadInterviewsList,
  }
}

export const RegisterContainer = createContainer(useRegister)
