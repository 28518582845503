import {
  createContainer,
  useCachedPromise,
} from '@blue-agency/front-state-management'
import { useParams } from 'react-router-dom'
import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'
import { cacheKey } from '@/services/bffService'

const usePresentation = () => {
  const { getPresentation } = OrganizerServiceContainer.useContainer()

  const { presentationGuid } = useParams<{ presentationGuid?: string }>()
  if (!presentationGuid) throw new Error('presentationGuid not found')

  const getPresentationRes = useCachedPromise(cacheKey.getPresentation(), () =>
    getPresentation(presentationGuid)
  )

  return { getPresentationRes }
}

export const PresentationContainer = createContainer(usePresentation)
