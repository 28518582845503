import { AssertionError } from 'assert'

export function assertIsDefined<T>(val: T): asserts val is NonNullable<T> {
  if (val === undefined || val === null) {
    throw new AssertionError({
      message: 'Expected to be defined',
      actual: val,
    })
  }
}

export function assertNever(value: never): never {
  throw new Error(`Unexpected: ${JSON.stringify(value)}`)
}
