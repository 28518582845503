import React from 'react'
import styled from 'styled-components'
import {
  Modal as _Modal,
  Txt,
  Icon as _Icon,
  TertiaryButton,
  WeakColorSeparator,
  theme,
} from '@blue-agency/rogue'
import { UserTypeContainer } from '@/containers/UserTypeContainer'
import { WindowSizeContainer } from '@/containers/WindowSizeContainer'
import { getDeviceType } from '@/utils'

type Props = {
  active: boolean
  onClose: () => void
}

export const UnstableConnectionModal: React.FCX<Props> = ({
  className,
  active,
  onClose,
}) => {
  const userType = UserTypeContainer.useContainer()
  const { isPortrait, width } = WindowSizeContainer.useContainer()

  const isSpLayout = getDeviceType({ windowWidth: width, isPortrait }) !== 'pc'

  return (
    <Modal
      className={className}
      active={active}
      title="接続が不安定なときは"
      onClose={onClose}
      size="l"
    >
      <Wrapper>
        <Upper isSpLayout={isSpLayout}>
          <Row>
            <IconWrapper isSpLayout={isSpLayout}>
              <ReloadIcon name="reload" />
            </IconWrapper>
            <DescriptionWrapper>
              <DescriptionTitle size="l">再読込 / リロード</DescriptionTitle>
              <Txt>
                音声が全く聞こえない、映像が全く見えない場合は、再読込をしてみましょう。
                <br />
                他の参加者に原因がある可能性もあるため、参加者それぞれが再読込をしてみましょう。
              </Txt>
            </DescriptionWrapper>
          </Row>
          {userType === 'organizer' && (
            <Row>
              <IconWrapper isSpLayout={isSpLayout}>
                <LowModeIcon>軽</LowModeIcon>
              </IconWrapper>
              <DescriptionWrapper>
                <DescriptionTitle size="l">軽量モード</DescriptionTitle>
                <Txt>
                  状況が改善しない場合は、「軽量モード」に変更してみましょう。
                  <br />
                  軽量モードは、画質を落として、通信速度が低い状態でもつながりやすくします。
                </Txt>
              </DescriptionWrapper>
            </Row>
          )}
        </Upper>
        <WeakColorSeparator />
        <Bottom>
          <ButtonGroup>
            <CloseButton
              comlinkPushParams={{ action: 'close_UnstableConnectionModal' }}
              size="l1"
              onClick={onClose}
            >
              閉じる
            </CloseButton>
          </ButtonGroup>
        </Bottom>
      </Wrapper>
    </Modal>
  )
}

const Modal = styled(_Modal)`
  width: 700px;
  max-width: 90%;
  overflow-y: scroll;
  max-height: 90%;
`

const Wrapper = styled.div``
const Upper = styled.div<{ isSpLayout: boolean }>`
  padding: ${({ isSpLayout }) => (isSpLayout ? '20px 16px' : '20px 20px 20px')};
`

const Row = styled.div`
  display: flex;
  align-items: flex-start;

  &:not(:first-child) {
    margin-top: 33px;
  }
`

const IconWrapper = styled.div<{ isSpLayout: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: ${theme.color.navy[1]};
  border-radius: 50%;
  margin-right: ${({ isSpLayout }) => (isSpLayout ? '12px' : '20px')};
`
const Icon = styled(_Icon)`
  color: ${theme.color.white[1]};
`
const ReloadIcon = styled(Icon)`
  width: 20px;
  height: 20px;
`

const LowModeIcon = styled.span`
  color: ${theme.color.white[1]};
  font-size: 18px;
`

const DescriptionWrapper = styled.div`
  flex: 1;
`

const DescriptionTitle = styled(Txt)`
  margin-bottom: 12px;
`
const Bottom = styled.div`
  padding: 20px 16px;
`
const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const CloseButton = styled(TertiaryButton)`
  width: 166px;
  height: 44px;
`
