export const storageKey = {
  local: {
    interviewSessionToken: {
      user: 'interviewUserSessionToken',
      screenSharing: 'interviewScreenSharingSessionToken',
      screenSharingRecv: 'interviewScreenSharingRecvSessionToken',
    },
  },
  session: {
    emailToken: 'emailToken',
    environmentCheck: (interviewGuid: string) =>
      `environmentCheck-${interviewGuid}`,
  },
  isPresentationStarted: 'isPresentationStarted',
}
