import React from 'react'
import styled from 'styled-components'
import { theme, WifiIcon } from '@blue-agency/rogue'

type Props = {
  unstableLevel: number
}
export const Wifi: React.FCX<Props> = ({ unstableLevel, className }) => {
  return (
    <Wrapper className={className}>
      <WifiIcon unstableLevel={unstableLevel} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 0 4px;
  color: ${theme.color.limeGreen[1]};
  display: flex;
  align-items: center;
  justify-content: center;
`
