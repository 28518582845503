import React from 'react'
import styled, { css } from 'styled-components'
import { theme, Txt, TertiaryButton } from '@blue-agency/rogue'
import { NewParticipantContainer } from './containers/NewParticipantContainer'

export const SendEmailContent: React.FCX = () => {
  const { handleBack, email } = NewParticipantContainer.useContainer()
  return (
    <Wrapper>
      <Txt size="s">
        登録メールアドレスにメールを送信しました。
        <br />
        送信されたメールから参加者サイトにアクセスしてください。
      </Txt>
      <Box>
        <Txt size="s">登録メールアドレス</Txt>
        <Email bold>{email}</Email>
      </Box>
      <BackButton
        size="l1"
        text="入力画面に戻る"
        onClick={handleBack}
        comlinkPushParams={{ action: 'back_to_input_step' }}
      />
    </Wrapper>
  )
}

const flex = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const Wrapper = styled.div`
  ${flex}
  width: 324px;
  padding: 30px 20px 15px;
  background: ${theme.color.white[1]};
  border-radius: 4px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  text-align: center;
`
const Box = styled.div`
  ${Wrapper} & {
    margin-top: 20px;
  }
  padding: 10px;
  background: ${theme.color.green[8]};
  ${flex}
`
const Email = styled(Txt)``
const BackButton = styled(TertiaryButton)`
  ${Wrapper} & {
    margin-top: 20px;
  }
`
