import React, { useMemo } from 'react'
import { ConfirmBody as ConfirmBodyPc } from '@/components/OrganizerForm/Pc/ConfirmBody'
import { ConfirmBody as ConfirmBodySp } from '@/components/OrganizerForm/Sp/ConfirmBody'
import { SignupContainer } from '../hooks/useSignup'

export const Confirm: React.FCX = ({ className }) => {
  const { form, promotion, isPc } = SignupContainer.useContainer()

  const planInfo = useMemo(() => {
    return {
      label: promotion.plan.name,
      term: promotion.planTerm,
      price: promotion.billingAmount,
      couponCode: form.couponCode,
    }
  }, [promotion, form.couponCode])

  const ConfirmBody = useMemo(() => {
    return isPc ? ConfirmBodyPc : ConfirmBodySp
  }, [isPc])

  return (
    <ConfirmBody
      className={className}
      form={form}
      planInfo={planInfo}
      hideReferrer={!form.referrer}
      hideBillingBlock={promotion.billingAmount === 0}
    />
  )
}
