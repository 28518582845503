import { DeviceType } from '@/services/interviewService/types/DeviceType'
import { WindowSize } from '@blue-agency/react-utils/dist/useWindowSize'
import { headerHeight } from './components/Header'
import { controlsHeight } from './components/InterviewControls/SpPortrait'
import { controlsWidth } from './components/InterviewControls/SpLandscape'
import { add, endOfMonth, format } from 'date-fns'
import { Plan } from '@blue-agency/proton/web/v2/yashiori_bff/plan_data_pb'

export function handleCopyUrlClick(url: string) {
  const ta = document.createElement('textarea')
  ta.value = url
  document.body.appendChild(ta)
  ta.select()
  document.execCommand('copy')
  ta.parentElement && ta.parentElement.removeChild(ta)
}

export const isPc = (windowWidth: number): boolean => {
  return 1024 < windowWidth
}

export const downloadCSV = ({
  data,
  fileName,
}: {
  data: Uint8Array
  fileName: string
}) => {
  const blob = new Blob([data], { type: 'text/csv' })
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.download = fileName
  link.href = url
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const isPcByUA = (ua: UAParser.IResult) =>
  // NOTE: PCはdevice情報が取れない
  ua.device.type === undefined

export const getDeviceType = ({
  windowWidth,
  isPortrait,
}: {
  windowWidth: number
  isPortrait: boolean
}): DeviceType => {
  if (isPc(windowWidth)) return 'pc'
  if (isPortrait) return 'spPortrait'
  return 'spLandscape'
}

export const getInterviewMainAreaSize = ({
  deviceType,
  width,
  height,
  alertboxHeight,
}: WindowSize & { deviceType: DeviceType; alertboxHeight: number }) => {
  switch (deviceType) {
    case 'pc':
      return {
        width,
        height: height - headerHeight - alertboxHeight,
      }
    case 'spPortrait':
      return {
        width,
        height: height - controlsHeight - alertboxHeight,
      }
    case 'spLandscape':
      return {
        width: width - controlsWidth,
        height: height - alertboxHeight,
      }
  }
}

export const getPlanTerm = (planStr: string, now: Date): string => {
  const endDateOnBasicPlan = endOfMonth(add(now, { months: 12 }))

  switch (planStr) {
    case Plan.Id.BASIC.toString():
      return `お申込日 ～ ${format(endDateOnBasicPlan, 'yyyy年M月d日')}`
    case Plan.Id.TRIAL_30DAYS.toString():
      return 'お申込日 ～ 30日間'
    case Plan.Id.RIKUNABI.toString():
      return 'お申込日 ～ 2020年9月30日'
    default:
      throw new Error('Invalid plan')
  }
}

export const assertNever = (value: never): never => {
  throw new Error(`unexpected value: ${JSON.stringify(value)}`)
}

export const truncateString = (str: string, count: number): string => {
  if (str.length <= count) {
    return str
  }
  return str.slice(0, count) + '...'
}
