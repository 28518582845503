import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { InterviewVideos } from '@/services/interviewService/components/InterviewVideos'
import { DeviceType } from '@/services/interviewService/types/DeviceType'
import { WindowSizeContainer } from '@/containers/WindowSizeContainer'
import ResizeDetector from 'react-resize-detector'
import {
  useInterviewees,
  useInterviewers,
  useInterviewStatus,
} from '@/lib/react-interview-sdk/hooks/values'

type Props = {
  deviceType: DeviceType
}

export const UsersArea: React.FCX<Props> = ({ className, deviceType }) => {
  const { height } = WindowSizeContainer.useContainer()

  const interviewers = useInterviewers()
  const _interviewees = useInterviewees()

  const interviewStatus = useInterviewStatus()

  // 開始前は応募者は表示しない
  const interviewees = useMemo(() => {
    return interviewStatus === 'notStarted' ? [] : _interviewees
  }, [_interviewees, interviewStatus])

  return (
    <ResizeDetector>
      {({
        width,
        targetRef,
      }: {
        width: number
        targetRef: React.RefObject<HTMLDivElement>
      }) => {
        const interviewVideosWidth =
          deviceType === 'spPortrait' ? width / 2 : width
        return (
          <Wrapper
            className={className}
            deviceType={deviceType}
            height={height}
            ref={targetRef}
          >
            <InterviewerVideos
              width={interviewVideosWidth}
              participantType="interviewer"
              deviceType={deviceType}
              participants={interviewers}
            />
            <IntervieweeVideos
              width={interviewVideosWidth}
              participantType="interviewee"
              deviceType={deviceType}
              participants={interviewees}
            />
          </Wrapper>
        )
      }}
    </ResizeDetector>
  )
}

const Wrapper = styled.div<{ deviceType: DeviceType; height: number }>`
  width: 100%;
  ${({ deviceType, height }) => {
    switch (deviceType) {
      case 'spPortrait':
        return css`
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
        `
      case 'spLandscape':
        return css`
          height: ${height}px;
        `
    }
  }}
`
const InterviewerVideos = styled(InterviewVideos)``
const IntervieweeVideos = styled(InterviewVideos)<{ deviceType: DeviceType }>`
  ${({ deviceType }) =>
    deviceType !== 'spPortrait' &&
    css`
      margin-top: 30px;
    `}
`
