import React from 'react'
import styled from 'styled-components'
import {
  Txt,
  theme,
  PrimaryButton,
  TertiaryButton,
  NewTabLink,
} from '@blue-agency/rogue'
import { H1 } from '@/components/Heading'
import { ENVIRONMENT_CHECK_CONTENT_MAX_WIDTH } from '@blue-agency/react-environment-check'
import { useDemoHall } from './hooks/useDemoHall'

export const Finish: React.FCX = ({ className }) => {
  const { presenterUrl, handleParticipantsUrlCopy } = useDemoHall()

  return (
    <Wrapper className={className}>
      <FinishTxt>動作環境テストが完了しました。</FinishTxt>
      <Title>Web説明会のテスト配信</Title>
      <Content>
        <Txt>
          つづけて、<Bold>Web説明会のテスト配信</Bold>を実施することができます。
          <br />
          テスト配信では、本番と同じ機能を「５分間」お試しいただけます。
          <br />
          参加者URLには、別の端末からアクセスしてください。
        </Txt>
        <Table>
          <Tr>
            <Td>発表者URL</Td>
            <Td>参加者URL</Td>
          </Tr>
          <Tr>
            <Td>
              <NewTabLink
                href={presenterUrl}
                hideIcon
                action="open_demo_presenter_page"
              >
                <TertiaryButton
                  text="開く"
                  size="m2"
                  comlinkPushParams={{
                    action: 'open_presenter_demo_seminar_url',
                  }}
                />
              </NewTabLink>
            </Td>
            <Td>
              <TertiaryButton
                text="URLコピー"
                size="m2"
                comlinkPushParams={{
                  action: 'copy_participant_demo_seminar_url',
                }}
                onClick={handleParticipantsUrlCopy}
              />
            </Td>
          </Tr>
        </Table>
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 700px;

  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const FinishTxt = styled(Txt)`
  text-align: center;
  ${Wrapper} & {
    margin-top: 4px;
  }
`

const Title = styled(H1)`
  ${Wrapper} & {
    margin-top: 20px;
  }
`

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${theme.color.white[1]};
  border: 1px solid ${theme.color.gray[4]};
  padding: 20px;
  ${Wrapper} & {
    margin: 20px 0;
    width: 100%;
  }
`

const ContentTitle = styled(Txt)`
  ${Content} & {
    margin-bottom: 16px;
  }
`
ContentTitle.defaultProps = { size: 'm' }

const CreateUrlButton = styled(PrimaryButton)`
  height: 44px;
  ${Content} & {
    margin-top: 18px;
  }
`
CreateUrlButton.defaultProps = { size: 'll' }

const Table = styled.table`
  ${Content} & {
    margin-top: 18px;
  }
`

const Tr = styled.tr`
  display: flex;
  align-items: center;
  width: ${ENVIRONMENT_CHECK_CONTENT_MAX_WIDTH}px;
  height: 44px;
  font-size: ${theme.fontSize.xs};
  ${Table} &:first-of-type {
    color: ${theme.color.white[1]};
    background: ${theme.color.navy[1]};
    font-size: ${theme.fontSize.s};
  }
`

const Td = styled.td`
  flex: 1 0 ${ENVIRONMENT_CHECK_CONTENT_MAX_WIDTH / 3}px;
  display: flex;
  justify-content: center;
`

const Bold = styled.span`
  font-weight: bold;
`
