import React, { useState, useCallback, useMemo } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { First } from '../ManualModalStep/First'
import { Second } from '../ManualModalStep/Second'
import { Third } from '../ManualModalStep/Third'

const useManualModal = () => {
  const [showManualModal, setShowManualModal] = useState(false)
  const [step, setStep] = useState(1)

  const manualModalBody = useMemo(() => {
    switch (step) {
      case 1:
        return <First />
      case 2:
        return <Second />
      case 3:
        return <Third />
      default:
        throw new Error('Invalid step')
    }
  }, [step])

  const handleManualModalOpen = useCallback(() => {
    setShowManualModal(true)
  }, [])
  const handleManualModalClose = useCallback(() => {
    setShowManualModal(false)
    setStep(1)
  }, [])

  const handleNext = useCallback(() => {
    setStep((prev) => ++prev)
  }, [])
  const handleBack = useCallback(() => {
    setStep((prev) => --prev)
  }, [])

  return {
    showManualModal,
    handleManualModalOpen,
    handleManualModalClose,
    handleNext,
    handleBack,
    manualModalBody,
  }
}

export const ManualModalContainer = createContainer(useManualModal)
