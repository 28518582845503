import React from 'react'
import styled from 'styled-components'
import {
  Modal,
  Txt,
  WeakColorSeparator,
  TertiaryButton,
  SecondaryDangerButton,
} from '@blue-agency/rogue'

type Props = {
  active: boolean
  onClose: () => void
  onFinish: () => void
}

export const FinishScreenShareModal: React.FCX<Props> = ({
  className,
  active,
  onClose,
  onFinish,
}) => {
  return (
    <Modal
      className={className}
      active={active}
      title="画面共有終了の確認"
      onClose={onClose}
    >
      <Wrapper>
        <Upper>
          <Txt>画面共有を終了してもいいですか？</Txt>
        </Upper>
        <Separator />
        <Bottom>
          <ButtonGroup>
            <CancelButton
              text="キャンセル"
              onClick={onClose}
              comlinkPushParams={{
                action: 'close_interview_screen_share_modal',
              }}
            />
            <FinishButton
              text="画面共有を終了"
              onClick={onFinish}
              comlinkPushParams={{ action: 'finish_interview_screen_share' }}
            />
          </ButtonGroup>
        </Bottom>
      </Wrapper>
    </Modal>
  )
}

const Wrapper = styled.div``
const Upper = styled.div`
  padding: 20px 16px;
`
const Separator = styled(WeakColorSeparator)`
  ${Wrapper} & {
    margin-top: 40px;
  }
`
const Bottom = styled.div`
  padding: 20px 16px;
`
const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const CancelButton = styled(TertiaryButton)`
  ${ButtonGroup} & {
    width: 48%;
  }
`
const FinishButton = styled(SecondaryDangerButton)`
  ${ButtonGroup} & {
    width: 48%;
  }
`
