import { CheckBrowserFunction } from '@blue-agency/react-environment-check'
import { CheckBrowserResult } from '@blue-agency/react-environment-check/dist/components/EnvironmentCheck/Browser'
import * as semver from 'semver'
import { UAParser } from 'ua-parser-js'
import {
  isWindows,
  isMac,
  isIOS,
  isIPadOS,
  isAndroid,
  shouldValidateIPadOSBySafariSemver,
} from './browser'

// チェックの基準はこの記事に定義されている
// https://stadium.kibe.la/notes/13366

export const checkInterviewBrowser: CheckBrowserFunction = (ua) => {
  if (ua === '') {
    return {
      type: 'notRecommendedStrongly',
      message: 'この端末は、推奨端末ではありません。別の端末をご利用ください。',
    }
  }

  // iOS15.7からUAが変更されており、UAParserを利用したチェックだと推奨環境からはじかれるので一時的に通るようにする
  // 参考 https://github.com/faisalman/ua-parser-js/pull/595/files
  if (ua.match(/version\/15\.6,[\w]+ .*mobile\/\w+ (safari)/i)) {
    return { type: 'valid', message: '' }
  }
  if (ua.match(/version\/15\.6,[\w]+ .*(mobile ?safari|safari)/i)) {
    return { type: 'valid', message: '' }
  }

  const uaParser = new UAParser(ua)
  const uaResult = uaParser.getResult()

  if (isWindows(uaResult)) {
    return checkWindows(uaResult)
  }

  if (isMac(uaResult)) {
    return checkMac(uaResult)
  }

  if (isIOS(uaResult)) {
    return checkIOS(uaResult)
  }

  if (isIPadOS(uaResult)) {
    return checkIPadOS(uaResult)
  }

  if (isAndroid(uaResult)) {
    return checkAndroid(uaResult)
  }

  return {
    type: 'notRecommendedStrongly',
    message: 'この端末は、推奨端末ではありません。別の端末をご利用ください。',
  }
}

/*
  iOS バージョンの文字列として、`12.0` といったように、セマンティックバージョニングで言うところの
  パッチバージョンがないケースがある
  complementPatchVersion では、バージョン比較しやすいようにパッチバージョンを補完して返す
 */
function complementPatchVersion(version: string): string | undefined {
  if (semver.valid(version)) {
    return version
  }

  const versionDotCount = (version.match(/\./g) || []).length

  if (versionDotCount === 1) {
    const ver = version + '.0'

    if (semver.valid(ver)) {
      return ver
    }
  }

  return undefined
}

function checkWindows(ua: UAParser.IResult): CheckBrowserResult {
  if (!ua.browser.version) {
    return {
      type: 'notRecommendedStrongly',
      message: 'この端末は、推奨端末ではありません。別の端末をご利用ください。',
    }
  }

  const majorVersion = parseInt(ua.browser.version, 10)

  if (ua.browser.name === 'Chrome') {
    if (majorVersion >= 71) {
      return { type: 'valid', message: '' }
    } else {
      return {
        type: 'unsupported',
        message: '最新のGoogle Chromeにアップデートしてください。',
      }
    }
  }

  if (ua.browser.name === 'Firefox') {
    if (majorVersion >= 78) {
      return { type: 'valid', message: '' }
    } else {
      return {
        type: 'unsupported',
        message: '最新のFirefoxにアップデートしてください。',
      }
    }
  }

  if (ua.browser.name === 'Edge') {
    if (majorVersion >= 79) {
      return { type: 'valid', message: '' }
    } else {
      return {
        type: 'unsupported',
        message: '最新のMicrosoft Edgeにアップデートしてください。',
      }
    }
  }

  if (ua.browser.name === 'IE') {
    return {
      type: 'unsupported',
      message: 'お使いのOS、ブラウザはご利用いただけません。',
    }
  }

  return {
    type: 'notRecommendedStrongly',
    message: 'この端末は、推奨端末ではありません。別の端末をご利用ください。',
  }
}

function checkMac(ua: UAParser.IResult): CheckBrowserResult {
  if (!ua.browser.version) {
    return {
      type: 'notRecommendedStrongly',
      message: 'この端末は、推奨端末ではありません。別の端末をご利用ください。',
    }
  }

  const majorVersion = parseInt(ua.browser.version, 10)

  if (ua.browser.name === 'Chrome') {
    if (majorVersion >= 71) {
      return { type: 'valid', message: '' }
    } else {
      return {
        type: 'unsupported',
        message: '最新のGoogle Chromeにアップデートしてください。',
      }
    }
  }

  if (ua.browser.name === 'Firefox') {
    if (majorVersion >= 78) {
      return { type: 'valid', message: '' }
    } else {
      return {
        type: 'unsupported',
        message: '最新のFirefoxにアップデートしてください。',
      }
    }
  }

  if (ua.browser.name === 'Edge') {
    if (majorVersion >= 79) {
      return { type: 'valid', message: '' }
    } else {
      return {
        type: 'unsupported',
        message: '最新のMicrosoft Edgeにアップデートしてください。',
      }
    }
  }

  if (ua.browser.name === 'Safari') {
    return { type: 'valid', message: '' }
  }

  return {
    type: 'notRecommendedStrongly',
    message: 'この端末は、推奨端末ではありません。別の端末をご利用ください。',
  }
}

function checkIOS(ua: UAParser.IResult): CheckBrowserResult {
  if (!ua.os.version) {
    return {
      type: 'notRecommendedStrongly',
      message: 'この端末は、推奨端末ではありません。別の端末をご利用ください。',
    }
  }

  if (ua.browser.name !== 'Mobile Safari') {
    return {
      type: 'unsupported',
      message:
        'こちらのブラウザは利用できません。Safariからページを開きなおしてください。',
    }
  }

  const iOSSemver = complementPatchVersion(ua.os.version)
  if (iOSSemver === undefined) {
    return {
      type: 'notRecommendedStrongly',
      message: 'この端末は、推奨端末ではありません。別の端末をご利用ください。',
    }
  }

  if (semver.gte(iOSSemver, '14.3.0')) {
    return {
      type: 'valid',
      message: '',
    }
  }

  if (semver.lt(iOSSemver, '14.3.0') && semver.gte(iOSSemver, '13.0.0')) {
    return {
      type: 'notRecommended',
      message:
        'OSが最新版ではありません。\n音声や映像に問題が生じることがありますので、OSをアップデートしてから開始してください。',
    }
  }

  if (semver.lt(iOSSemver, '13.0.0')) {
    return {
      type: 'unsupported',
      message:
        'OSが最新版でないためご利用できません。OSをアップデートしてください。',
    }
  }

  return {
    type: 'notRecommendedStrongly',
    message: 'この端末は、推奨端末ではありません。別の端末をご利用ください。',
  }
}

function checkIPadOS(ua: UAParser.IResult): CheckBrowserResult {
  if (!ua.os.version) {
    return {
      type: 'notRecommendedStrongly',
      message: 'この端末は、推奨端末ではありません。別の端末をご利用ください。',
    }
  }

  if (ua.browser.name !== 'Mobile Safari' && ua.browser.name !== 'Safari') {
    return {
      type: 'unsupported',
      message:
        'こちらのブラウザは利用できません。Safariからページを開きなおしてください。',
    }
  }

  // Safariのバージョンを見てバリデーションする場合、以下の対応表を参考にiPadOSのバージョンとマッピングする
  // https://qiita.com/yoshitake_1201/items/05a13fd77c18ff380eb6
  if (shouldValidateIPadOSBySafariSemver(ua)) {
    if (!ua.browser.version) {
      return {
        type: 'notRecommendedStrongly',
        message:
          'この端末は、推奨端末ではありません。別の端末をご利用ください。',
      }
    }

    const safariSemver = complementPatchVersion(ua.browser.version)
    if (safariSemver === undefined) {
      return {
        type: 'notRecommendedStrongly',
        message:
          'この端末は、推奨端末ではありません。別の端末をご利用ください。',
      }
    }

    if (semver.gte(safariSemver, '14.0.2')) {
      return {
        type: 'valid',
        message: '',
      }
    }

    if (
      semver.lt(safariSemver, '14.0.2') &&
      semver.gte(safariSemver, '13.0.3')
    ) {
      return {
        type: 'notRecommended',
        message:
          'OSが最新版ではありません。\n音声や映像に問題が生じることがありますので、OSをアップデートしてから開始してください。',
      }
    }

    return {
      type: 'unsupported',
      message:
        'OSが最新版でないためご利用できません。OSをアップデートしてください。',
    }
  }

  const iPadOSSemver = complementPatchVersion(ua.os.version)
  if (iPadOSSemver === undefined) {
    return {
      type: 'notRecommendedStrongly',
      message: 'この端末は、推奨端末ではありません。別の端末をご利用ください。',
    }
  }

  if (semver.gte(iPadOSSemver, '14.3.0')) {
    return {
      type: 'valid',
      message: '',
    }
  }

  if (semver.lt(iPadOSSemver, '14.3.0') && semver.gte(iPadOSSemver, '13.2.2')) {
    return {
      type: 'notRecommended',
      message:
        'OSが最新版ではありません。\n音声や映像に問題が生じることがありますので、OSをアップデートしてから開始してください。',
    }
  }

  return {
    type: 'unsupported',
    message:
      'OSが最新版でないためご利用できません。OSをアップデートしてください。',
  }
}

function checkAndroid(ua: UAParser.IResult): CheckBrowserResult {
  if (!ua.browser.version) {
    return {
      type: 'notRecommendedStrongly',
      message: 'この端末は、推奨端末ではありません。別の端末をご利用ください。',
    }
  }

  const majorVersion = parseInt(ua.browser.version, 10)
  const isPixel3 = ua.device.model?.includes('Pixel 3') ?? false
  if (ua.browser.name === 'Chrome' || ua.browser.name === 'Chrome WebView') {
    if (majorVersion >= 71) {
      if (isPixel3 && majorVersion < 93) {
        return {
          type: 'notRecommendedStrongly',
          message:
            'この端末は、推奨端末ではありません。別の端末をご利用ください。',
        }
      }
      return { type: 'valid', message: '' }
    } else {
      return {
        type: 'unsupported',
        message: '最新のGoogle Chromeにアップデートしてください。',
      }
    }
  }

  return {
    type: 'unsupported',
    message:
      'こちらのブラウザは利用できません。Google Chromeをご利用ください。',
  }
}
