import * as grpcWeb from 'grpc-web'
import { GrpcError } from './types'
import { Status } from '@blue-agency/proton/web/google/rpc/status_pb'

type ErrorDetail = 'NotActivatedOrganizer'

const TypeNameToErrorDetail: Record<string, ErrorDetail> = {
  'proton.v2.yashiori_bff.NotActivatedOrganizerErrorDetail':
    'NotActivatedOrganizer',
}

export class CustomGrpcError extends Error {
  code: number
  metadata: grpcWeb.Metadata
  constructor(err: GrpcError) {
    super(err.message)
    this.name = 'CustomGrpcError'
    this.code = err.code
    this.metadata = err.metadata
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor)
    }
  }

  get errorDetail(): ErrorDetail | undefined {
    const statusEncoded = this.metadata['grpc-status-details-bin']

    let statusDecoded
    try {
      // NOTE: statusEncoded が空の時は atob に失敗する
      statusDecoded = atob(statusEncoded)
    } catch {
      return undefined
    }

    const encoder = new TextEncoder()
    const status = Status.deserializeBinary(encoder.encode(statusDecoded))
    const details = status.getDetailsList()

    // NOTE: 現在は error details が1つしか返ってこないことを前提にしている
    return TypeNameToErrorDetail[details[0].getTypeName()] || undefined
  }
}
