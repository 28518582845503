import { atomFamily } from 'recoil'
import { SoraSignalingStatus } from '@/lib/react-interview-sdk/types'
import { namespace } from './namespace'

export const soraConnectionStatusState = atomFamily<
  SoraSignalingStatus,
  string
>({
  key: `${namespace}/soraConnectionStatusState`,
  default: { status: 'Pending' },
})
