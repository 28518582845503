import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { INTERNAL_PATHS } from '@/services/urlService'
import { RoomPage } from './RoomPage'
import { EntrancePage } from './EntrancePage'
import { FinishPage } from './FinishPage'
import { OvertimePage } from './OvertimePage'
import { NotFound } from '@/components/NotFound'
import { provide } from '@blue-agency/front-state-management'
import { EnterHallContainer } from './containers/EnterHallContainer'

export const EntranceRoutes = provide(EnterHallContainer)(() => {
  return (
    <Switch>
      <Route
        exact
        path={INTERNAL_PATHS.organizer.my.halls.entrance.index}
        component={EntrancePage}
      />
      <Route
        exact
        path={INTERNAL_PATHS.organizer.my.halls.entrance.room}
        component={RoomPage}
      />
      <Route
        exact
        path={INTERNAL_PATHS.organizer.my.halls.entrance.finish}
        component={FinishPage}
      />
      <Route
        exact
        path={INTERNAL_PATHS.organizer.my.halls.entrance.overtime}
        component={OvertimePage}
      />
      <Route path="*" component={NotFound} />
    </Switch>
  )
})
