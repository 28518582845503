import { useCallback } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
  interviewStatusState,
  pinnedTileState,
} from '@/lib/react-interview-sdk/states'

export const usePinTile = () => {
  const interviewStatus = useRecoilValue(interviewStatusState)
  const setPinnedTile = useSetRecoilState(pinnedTileState)
  return useCallback(
    (tileId: number) => {
      if (interviewStatus !== 'started') return
      setPinnedTile(tileId)
    },
    [interviewStatus, setPinnedTile]
  )
}
