import { atom, selector } from 'recoil'
import { MetadataMap, Participant } from '@/lib/react-interview-sdk/types'
import { namespace } from './namespace'

export const metadataMapState = atom<MetadataMap>({
  key: `${namespace}/metadataMapState`,
  default: {},
})

export const participantsState = selector({
  key: `${namespace}/participantsState`,
  get: ({ get }) => {
    const metadataMap = get(metadataMapState)
    return Object.entries(metadataMap).map(
      ([id, metadata]): Participant => ({
        soraClientId: id,
        entryNumber: metadata.entry_number,
        role: metadata.interview_role,
      })
    )
  },
})

export const ownSoraClientIdState = atom<string | undefined>({
  key: `${namespace}/ownSoraClientIdState`,
  default: undefined,
})

export const ownParticipantState = selector({
  key: `${namespace}/ownParticipantState`,
  get: ({ get }) => {
    const ownSoraClientId = get(ownSoraClientIdState)
    if (!ownSoraClientId) return undefined
    const metadataMap = get(metadataMapState)

    const metadata = metadataMap[ownSoraClientId]
    if (!metadata) {
      return undefined
    }

    return {
      soraClientId: ownSoraClientId,
      entryNumber: metadata.entry_number,
      role: metadata.interview_role,
    }
  },
})

export const otherParticipantsOfSameRoleState = selector({
  key: `${namespace}/otherParticipantsOfSameRoleState`,
  get: ({ get }) => {
    const ownParticipant = get(ownParticipantState)
    const participants = get(participantsState)

    if (!ownParticipant?.role) {
      return participants
    }
    const sameRoles =
      ownParticipant.role === 'recruiter'
        ? ['recruiter', 'recruiter_screen']
        : ['applicant', 'applicant_screen']
    return participants.filter((p) => sameRoles.includes(p.role))
  },
})

export const otherParticipantsOfOtherRoleState = selector({
  key: `${namespace}/otherParticipantsOfOtherRole`,
  get: ({ get }) => {
    const ownParticipant = get(ownParticipantState)
    const participants = get(participantsState)

    if (!ownParticipant?.role) {
      return participants
    }
    const sameRoles =
      ownParticipant.role === 'recruiter'
        ? ['recruiter', 'recruiter_screen']
        : ['applicant', 'applicant_screen']
    return participants.filter((p) => !sameRoles.includes(p.role))
  },
})

export const participantsCountState = selector({
  key: `${namespace}/participantsCountState`,
  get: ({ get }) => {
    const participants = get(participantsState)

    const targetRoles = ['recruiter', 'applicant']
    return participants.filter((p) => targetRoles.includes(p.role)).length
  },
})

export const interviewersState = selector({
  key: `${namespace}/interviewersState`,
  get: ({ get }) => {
    const participants = get(participantsState)
    const targetRoles = ['recruiter', 'recruiter_screen']
    return participants.filter((p) => targetRoles.includes(p.role))
  },
})

export const interviewersCountState = selector({
  key: `${namespace}/interviewersCountState`,
  get: ({ get }) => {
    const interviewers = get(interviewersState)
    return interviewers.length
  },
})

export const intervieweesState = selector({
  key: `${namespace}/intervieweesState`,
  get: ({ get }) => {
    const participants = get(participantsState)
    const targetRoles = ['applicant', 'applicant_screen']
    return participants.filter((p) => targetRoles.includes(p.role))
  },
})

export const intervieweesCountState = selector({
  key: `${namespace}/intervieweesCountState`,
  get: ({ get }) => {
    const interviewees = get(intervieweesState)
    return interviewees.length
  },
})
