import { useState, useCallback, useMemo, useRef } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { EXTERNAL_URLS } from '@/services/urlService'
import { UserTypeContainer } from '@/containers/UserTypeContainer'
import { useModal } from '@/hooks/useModal'
import { WindowSizeContainer } from '@/containers/WindowSizeContainer'
import { isPc } from '@/utils'
import {
  QualityMode,
  QualityModeLow,
} from '@/services/interviewService/types/Quality'
import { useIsUnavailableVideoInput } from '@/lib/react-interview-sdk/hooks/values'

type Item = {
  text: string
  secondaryText?: string
  onClick?: () => void
  href?: string
  action: string
}

type Props = {
  enableCameraDeviceChange: boolean
  qualityMode?: QualityMode
}

const useDropdown = (props: Props | undefined) => {
  const enableCameraDeviceChange = props?.enableCameraDeviceChange
  const qualityMode = props?.qualityMode
  const [showDropdown, setShowDropdown] = useState(false)
  const unstableConnectionModal = useModal()
  const reloadModal = useModal()
  const changeDeviceModal = useModal()
  const qualityModeModal = useModal()
  const dropdownRef = useRef<HTMLDivElement>(null)
  const backgroundSettingModal = useModal()

  const userType = UserTypeContainer.useContainer()
  const { width } = WindowSizeContainer.useContainer()
  const pc = isPc(width)

  const isUnavailableVideoInput = useIsUnavailableVideoInput()

  const helpHref = useMemo(() => {
    return userType === 'participant'
      ? EXTERNAL_URLS.help.participants.interview
      : EXTERNAL_URLS.help.organizer.interview.top
  }, [userType])

  const handleRequestReloadOnUnstableConnectionModal = useCallback(() => {
    unstableConnectionModal.close()
    reloadModal.open()
  }, [reloadModal, unstableConnectionModal])

  const handleAdditionalMenuIconClick = useCallback(
    () => setShowDropdown((prev) => !prev),
    []
  )

  const handleOutsideClick = useCallback(() => {
    setShowDropdown(false)
  }, [])

  const items = useMemo<Item[]>(() => {
    const value: Item[] = [
      {
        text: '接続が不安定なときはこちら',
        onClick: unstableConnectionModal.open,
        action: 'open_unstable_connection_modal',
      },
      {
        text: 'ヘルプ',
        href: helpHref,
        action: 'open_organizer_interview_help_page',
      },
    ]

    if (enableCameraDeviceChange) {
      value.push({
        text: 'デバイス選択',
        onClick: changeDeviceModal.open,
        action: 'open_change_device_modal',
      })
    }

    // サポートしていない環境でもChromeへの誘導のためにPCの場合はモーダルへの導線を残す
    if (pc && !isUnavailableVideoInput) {
      value.push({
        text: '背景設定',
        action: 'open_background_setting_modal',
        onClick: backgroundSettingModal.open,
      })
    }
    if (userType === 'organizer') {
      value.push({
        text: '軽量モード設定',
        secondaryText:
          qualityMode === QualityModeLow
            ? '軽量モード利用中'
            : '通常モード利用中',
        onClick: qualityModeModal.open,
        action: 'open_change_quality_mode_modal',
      })
    }

    return value
  }, [
    unstableConnectionModal.open,
    helpHref,
    enableCameraDeviceChange,
    pc,
    isUnavailableVideoInput,
    userType,
    changeDeviceModal.open,
    backgroundSettingModal.open,
    qualityMode,
    qualityModeModal.open,
  ])

  return {
    items,
    showDropdown,
    handleAdditionalMenuIconClick,
    unstableConnectionModal,
    handleRequestReloadOnUnstableConnectionModal,
    reloadModal,
    changeDeviceModal,
    qualityModeModal,
    dropdownRef,
    handleOutsideClick,
    backgroundSettingModal,
  }
}

export const DropdownContainer = createContainer(useDropdown)
