import { useCallback } from 'react'
import { MetadataMap, ensureMetadata } from '@/lib/react-interview-sdk/types'
import {
  SignalingNotifyConnectionCreated,
  SignalingNotifyConnectionDestroyed,
} from '@blue-agency/interview-sdk-js'
import { assertIsDefined } from '@/lib/react-interview-sdk/utils/assertions'
import { useSetRecoilState } from 'recoil'
import { metadataMapState } from '@/lib/react-interview-sdk/states'

export const useMetadataMapSoraHandlers = () => {
  const setMetadataMap = useSetRecoilState(metadataMapState)

  const connectionCreated = useCallback(
    (data: SignalingNotifyConnectionCreated) => {
      assertIsDefined(data.client_id)
      if (!ensureMetadata(data.metadata)) {
        throw new Error('Bad connection created notification payload')
      }

      const newMetadataMap: MetadataMap = {}
      newMetadataMap[data.client_id] = data.metadata

      // NOTE: metadata_listからdataへ移行される。サーバー側移行で動作するよう両方参照しておく。
      // 移行が終わったらmetadata_listへの参照を削除する。
      // https://sora-doc.shiguredo.jp/DEPRECATED#8a323e
      data.metadata_list?.forEach((item) => {
        assertIsDefined(item.client_id)
        if (!ensureMetadata(item.metadata)) {
          return
        }
        newMetadataMap[item.client_id] = item.metadata
      })
      data.data?.forEach((item) => {
        assertIsDefined(item.client_id)
        if (!ensureMetadata(item.metadata)) {
          return
        }
        newMetadataMap[item.client_id] = item.metadata
      })

      setMetadataMap((prev) => ({ ...prev, ...newMetadataMap }))
    },
    [setMetadataMap]
  )

  const connectionDestroyed = useCallback(
    (data: SignalingNotifyConnectionDestroyed) => {
      if (!ensureMetadata(data.metadata)) {
        throw new Error('Bad connection destroyed notification payload')
      }
      // NOTE: skywalker-frontではmetadataMap はチャットの role 表示に使うので、画面共有のものだけ削除している
      // skywalker-frontは別に参加者リストを持っているので問題ないが、yashiori-frontやsnoke-frontでは持っていないのでmetadataを削除する
      // 必要が出てきたらmetadataのactiveステータスを持たせたりを検討する

      setMetadataMap((metadataMap) => {
        const { [data.client_id!]: _, ...rest } = metadataMap
        return rest
      })
    },
    [setMetadataMap]
  )

  return { connectionCreated, connectionDestroyed }
}

export const useResetMetadataMap = () => {
  const setMetadataMap = useSetRecoilState(metadataMapState)
  return useCallback(() => {
    setMetadataMap({})
  }, [setMetadataMap])
}
