import React from 'react'
import {
  CheckBox,
  DangerButton,
  ErrorMessage as _ErrorMessage,
  Modal,
  TertiaryButton,
  theme,
  Txt as _Txt,
  RequireTxt as _RequireTxt,
  WeakColorSeparator,
} from '@blue-agency/rogue'
import styled from 'styled-components'
import { useBulkDeletePresentationsModal } from './hooks/useBulkDeletePresentationsModal'

const errorMessage = {
  default: '説明会動画の削除に失敗しました。もう一度お試しください。',
  isRecordingPresentationSelected: `撮影中の説明会動画が含まれるため、削除できません。
該当の説明会動画撮影を終了させてから、再度お試しください。 `,
  isRecordingPresentationDeleteRequested: `撮影中の説明会動画が含まれるため、削除できませんでした。
撮影を終了させてから、再度お試しください。`,
}

type Props = {
  active: boolean
  onClose: () => void
}

export const BulkDeletePresentationsModal: React.FCX<Props> = ({
  active,
  className,
  onClose,
}) => {
  const {
    selectedPresentations,
    totalCount,
    isRecordingPresentationSelected,
    isAgreed,
    error,
    isLoading,
    toggleAgreement,
    handleDeletePresentationsClick,
  } = useBulkDeletePresentationsModal(active, onClose)

  if (isRecordingPresentationSelected) {
    return (
      <Modal
        active={active}
        className={className}
        title="説明会動画を削除"
        onClose={onClose}
      >
        <Upper>
          <ErrorMessage text={errorMessage.isRecordingPresentationSelected} />
        </Upper>
        <WeakColorSeparator />
        <Bottom>
          <ButtonGroup>
            <CancelButton
              onClick={onClose}
              comlinkPushParams={{
                action: 'close_selected_only_running_presentations_modal',
              }}
            >
              閉じる
            </CancelButton>
          </ButtonGroup>
        </Bottom>
      </Modal>
    )
  } else {
    return (
      <Modal
        active={active}
        className={className}
        title="説明会動画を削除"
        onClose={onClose}
      >
        <Upper>
          {error === 'default' && <ErrorMessage text={errorMessage.default} />}
          {error === 'select_recording_presentation' && (
            <ErrorMessage
              text={errorMessage.isRecordingPresentationDeleteRequested}
            />
          )}
          <div>
            <Txt>
              <Count>{totalCount}</Count>件の説明会動画を削除します。
            </Txt>
            <Txt size="s" bold>
              削除したデータを復元することができません。
            </Txt>
            <Txt size="s" bold>
              この説明会動画に関わる情報はすべて削除されますのでご注意ください。
            </Txt>
          </div>
          <Separator />
          <Block>
            <BlockLabel>対象の説明会動画</BlockLabel>
            <RemovedPresentationList>
              {selectedPresentations.map((presentation) => (
                <Txt key={presentation.guid} size="s">
                  ・{presentation.presentationName}
                </Txt>
              ))}
            </RemovedPresentationList>
          </Block>
        </Upper>
        <WeakColorSeparator />
        <Bottom>
          <Block>
            <BlockLabel>削除確認</BlockLabel>
            <div style={{ display: 'flex' }}>
              <RequireTxt>必須</RequireTxt>
              <CheckBox
                checked={isAgreed}
                checkboxLabel="該当の説明会動画を完全に削除する"
                onChange={toggleAgreement}
              />
            </div>
          </Block>
          <ButtonGroup>
            <CancelButton
              comlinkPushParams={{
                action: 'close_bulk_delete_presentations_modal',
              }}
              onClick={onClose}
            >
              キャンセル
            </CancelButton>
            <DeleteButton
              disabled={!isAgreed}
              loading={isLoading}
              comlinkPushParams={{ action: 'delete_selected_presentations' }}
              onClick={handleDeletePresentationsClick}
            >
              削除する
            </DeleteButton>
          </ButtonGroup>
        </Bottom>
      </Modal>
    )
  }
}

const Upper = styled.div`
  padding: 20px;
`

const Block = styled.div`
  display: flex;
  margin: 20px 0 0;
`

const Txt = styled(_Txt)``
Txt.defaultProps = { size: 's' }

const BlockLabel = styled(_Txt)`
  width: 140px;
`
BlockLabel.defaultProps = { size: 's' }

const ErrorMessage = styled(_ErrorMessage)`
  white-space: pre-wrap;

  ${Upper} &:not(:last-child) {
    margin-bottom: 16px;
  }

  svg {
    width: 16px;
  }
`
ErrorMessage.defaultProps = { size: 's' }

const Count = styled.span`
  color: ${theme.color.green[4]};
  font-size: 24px;
`

const RequireTxt = styled(_RequireTxt)`
  line-height: 20px;
  margin-left: -28px;
  width: 28px;
`
RequireTxt.defaultProps = { size: 'xs' }

const Separator = styled(WeakColorSeparator)`
  margin: 20px 0;
`

const Bottom = styled.div`
  padding: 0 20px;
`

const RemovedPresentationList = styled.div`
  flex: 1;
  max-height: 80px;
  overflow-y: scroll;
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;

  > *:not(:last-child) {
    margin-right: 20px;
  }
`

const CancelButton = styled(TertiaryButton)`
  width: 136px;
`
CancelButton.defaultProps = { size: 'll' }

const DeleteButton = styled(DangerButton)`
  width: 136px;
`
DeleteButton.defaultProps = { size: 'll' }
