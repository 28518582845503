import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Layout } from '@/components/Layout'
import {
  PrimaryButton,
  theme,
  Txt,
  HelpLinkIcon as _HelpLinkIcon,
  NewTabLink,
} from '@blue-agency/rogue'
import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'
import { useCachedPromise } from '@blue-agency/front-state-management'
import {
  EXTERNAL_URLS,
  INTERNAL_PATHS,
  fillParams,
} from '@/services/urlService'
import { useTitle } from '@/hooks/useTitle'
import { cacheKey } from '@/services/bffService'
import { useHistory, useParams, Redirect } from 'react-router-dom'
import { PresentationContainer } from '../hooks/PresentationContainer'

export const BeforeRecordingPage: React.FCX = ({ className }) => {
  const history = useHistory()

  const { token, presentationGuid } = useParams<{
    token?: string
    presentationGuid?: string
  }>()
  if (!token) throw new Error('token not found')
  if (!presentationGuid) throw new Error('presentationGuid not found')

  const { getPresentationRes } = PresentationContainer.useContainer()

  useTitle('撮影前画面')

  const toRecordingPage = useCallback(() => {
    history.push(
      fillParams({
        path: INTERNAL_PATHS.organizer.my.presentations.presentation.recording,
        params: { token, presentationGuid },
      })
    )
  }, [history, token, presentationGuid])

  if (getPresentationRes.getIsRecorded())
    return (
      <Redirect
        to={fillParams({
          path: INTERNAL_PATHS.organizer.my.presentations.presentation.recorded,
          params: { token, presentationGuid },
        })}
      />
    )

  return (
    <Layout
      className={className}
      rightNode={<RightNode />}
      backgroundColor={theme.color.gray[4]}
    >
      <Padding>
        <Card>
          <Txt>準備ができたら撮影待機画面に進んでください</Txt>
          <ManualTxt>
            初めてご利用される方は、
            <NewTabLink
              href={EXTERNAL_URLS.presentationManual}
              action="open_presentation_manual_page"
            >
              操作マニュアル
            </NewTabLink>
            <br />
            をご確認ください
          </ManualTxt>
          <TermsTxt>
            <NewTabLink href={EXTERNAL_URLS.terms} action="open_terms_page">
              利用規約
            </NewTabLink>
            と
            <NewTabLink
              href={EXTERNAL_URLS.privacy}
              action="open_privacy_policy_page"
            >
              プライバシーポリシー
            </NewTabLink>
            に
          </TermsTxt>
          <StartButton
            size="ll"
            text="同意して待機画面に進む"
            onClick={toRecordingPage}
            comlinkPushParams={{ action: 'transition_to_recording_page' }}
          />
        </Card>
      </Padding>
    </Layout>
  )
}

const Padding = styled.div`
  padding: 40px;
`
const Card = styled.div`
  ${Padding} & {
    margin: 20px auto 0;
  }
  padding: 20px;
  width: 360px;
  background-color: ${theme.color.white[1]};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const StartButton = styled(PrimaryButton)`
  ${Card} & {
    margin-top: 20px;
  }
`
const ManualTxt = styled(Txt)`
  ${Card} & {
    margin-top: 20px;
  }
`
const TermsTxt = styled(Txt)`
  ${Card} & {
    margin-top: 20px;
    text-align: center;
  }
`

const RightNode = () => {
  const { getOrganizer } = OrganizerServiceContainer.useContainer()
  const organizer = useCachedPromise(cacheKey.getOrganizer(), getOrganizer)

  return (
    <RightNodeWrapper>
      <Txt size="s">{organizer.name}</Txt>
      <HelpLinkIcon
        href={EXTERNAL_URLS.help.organizer.presentation}
        action="open_organizer_presentation_help_page"
      />
    </RightNodeWrapper>
  )
}

const RightNodeWrapper = styled.div`
  display: flex;
  align-items: center;
`
const HelpLinkIcon = styled(_HelpLinkIcon)`
  ${RightNodeWrapper} & {
    margin-left: 20px;
  }
`
