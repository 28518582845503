import { useCallback } from 'react'
import { useRecoilValue } from 'recoil'
import { interviewWebSocketState } from '@/lib/react-interview-sdk/states'

export const useBroadcastFinish = (): (() => void) => {
  const ws = useRecoilValue(interviewWebSocketState)

  const finish = useCallback(() => {
    if (!ws) return
    ws.sendMessage(JSON.stringify({ status: 'finish' }))
  }, [ws])

  return finish
}
