import { atom } from 'recoil'
import { AttendeeMap, TileMap } from '@/lib/react-interview-sdk/types'
import { namespace } from './namespace'

type RemoteVideoTileState = {
  tiles: number[]
  tileIdToAttendeeId: TileMap
  attendeeIdToTileId: AttendeeMap
}
export const remoteVideoTileState = atom<RemoteVideoTileState>({
  key: `${namespace}/remoteVideoTileState`,
  default: {
    tiles: [],
    tileIdToAttendeeId: {},
    attendeeIdToTileId: {},
  },
})
