export const TOOLTIP_IDS = {
  interviewNameOnInterviewList: 'interviewNameOnInterviewList',
  interviewNameOnUpdateNameModal: 'interviewNameOnUpdateNameModal',
  countOnRegisterModal: 'countOnRegisterModal',
  titleOnRegisterModal: 'titleOnRegisterModal',
  interviewNameOnRegisterModal: 'interviewNameOnRegisterModal',
  interviewerUrlOnInterviewList: 'interviewerUrlOnInterviewList',
  intervieweeUrlOnInterviewList: 'intervieweeUrlOnInterviewList',
  interviewCountDuringContractPeriodOnInterviewList:
    'interviewCountDuringContractPeriodOnInterviewList',
  interviewCountOnReadyOnInterviewList: 'interviewCountOnReadyOnInterviewList',
  hallCountDuringContractPeriodOnHallList:
    'hallCountDuringContractPeriodOnHallList',
}
