import React from 'react'
import styled from 'styled-components'
import { ApplyPageContainer } from '../../ApplyPageContainer'
import { Txt } from '@blue-agency/rogue'
import { ConfirmStepItem } from '../../types'
import { employeesNumberOptions } from '../../options'

export const ContractSection: React.FCX = ({ className }) => {
  const { inputtedData } = ApplyPageContainer.useContainer()
  const employeesNumberLabel = employeesNumberOptions.find(
    (employeesNumber) =>
      employeesNumber.value === inputtedData.employeesNumberId
  )?.label
  return (
    <Wrapper className={className}>
      {contractItems.map((item, index) => (
        <React.Fragment key={index}>
          <Item>
            <Label>{index === 0 && '契約者情報'}</Label>
            <ItemLabel size="s" bold>
              {item.label}
            </ItemLabel>
            <Txt size="s">
              {item.name !== 'employeesNumberId'
                ? inputtedData[item.name]
                : employeesNumberLabel}
            </Txt>
          </Item>
        </React.Fragment>
      ))}
    </Wrapper>
  )
}

const contractItems: ConfirmStepItem[] = [
  {
    label: '会社名',
    name: 'name',
  },
  {
    label: '代表者名',
    name: 'representativeName',
  },
  {
    label: '電話番号',
    name: 'phoneNumber',
  },
  {
    label: '郵便番号',
    name: 'postCode',
  },
  {
    label: '住所',
    name: 'address',
  },
  {
    label: '従業員数',
    name: 'employeesNumberId',
  },
]

const Wrapper = styled.div`
  padding: 20px;
`
const Item = styled.div`
  &:nth-of-type(n + 2) {
    margin-top: 20px;
  }
  display: flex;
  align-items: center;
`
const Label = styled(Txt)`
  width: 160px;
`
const ItemLabel = styled(Txt)`
  width: 130px;
`
