import React from 'react'
import styled, { css } from 'styled-components'
import { theme, Button, Icon } from '@blue-agency/rogue'
import { useInterviewVideo } from './hooks/useInterviewVideo'
import { Wifi as _Wifi } from '@/components/Wifi'
import { buttonResetStyle } from '@/styles/buttonResetStyle'
import { InterviewEntryNumber as _InterviewEntryNumber } from '../InterviewEntryNumber'
import { CameraOffImage as _CameraOffImage } from '@/components/CameraOffImage'
import { UnavailableVideoInputImage as _UnavailableVideoInputImage } from '@/components/UnavailableVideoInputImage'
import { UnstableLevel } from '@/services/interviewService/types/UnstableLevel'
import { Participant } from '@/services/interviewService/types/Participant'
import { ParticipantType } from '@/services/interviewService/types/ParticipantType'
import { Switch, Case, Default } from 'react-if'

type CommonVideoProps = {
  width: number
  participantType: ParticipantType
  participant: Participant
  tileId: number | null
  pinTile: (tileId: number) => void
  showMute: boolean
  isMainVideo: boolean
  bindVideoElement: (tileId: number, el: HTMLVideoElement) => () => void
  setAudioOutputForVideoTile: (tileId: number) => Promise<void>
}

type RemoteVideoProps = CommonVideoProps

type LocalVideoProps = CommonVideoProps & {
  unstableLevel?: UnstableLevel
  isOwnCameraMuted: boolean
  isUnavailableVideoInput: boolean
}

type InterviewVideoProps = LocalVideoProps & {
  isOwn: boolean
}

export const RemoteVideo: React.FC<RemoteVideoProps> = ({ ...props }) => {
  return (
    <InterviewVideo
      {...props}
      isOwnCameraMuted={false}
      isUnavailableVideoInput={false}
      isOwn={false}
    />
  )
}

export const LocalVideo: React.FC<LocalVideoProps> = ({ ...props }) => {
  return <InterviewVideo {...props} isOwn />
}

export const InterviewVideo: React.FCX<InterviewVideoProps> = ({
  className,
  ...props
}) => {
  const { videoRef, handleClick } = useInterviewVideo(props)
  const height = (props.width * 9) / 16
  return (
    <Wrapper
      className={className}
      onClick={handleClick}
      width={props.width}
      height={height}
      comlinkPushParams={{ action: 'select_main_video_on_interview' }}
    >
      {props.participant.role === 'applicant_screen' ||
      props.participant.role === 'recruiter_screen' ? (
        <>
          <ScreenShareIconWrapper>
            <ScreenShareIcon name="screen-share" />
          </ScreenShareIconWrapper>
        </>
      ) : (
        <Switch>
          <Case condition={props.isOwn && props.isOwnCameraMuted}>
            <CameraOffImage />
          </Case>
          <Case condition={props.isOwn && props.isUnavailableVideoInput}>
            <UnavailableVideoInputImage />
          </Case>
          <Default>
            <Video
              autoPlay
              playsInline
              width={props.width}
              ref={videoRef}
              mirrorEffect={props.isOwn}
            />
          </Default>
        </Switch>
      )}
      {props.isMainVideo && (
        <Overlay>
          <PinIcon name="pin" />
        </Overlay>
      )}
      {props.isOwn && props.unstableLevel !== undefined && (
        <Wifi unstableLevel={props.unstableLevel} />
      )}
      {props.showMute && <MuteMikeIcon name="mute-mike" />}
      {props.participant.entryNumber && (
        <InterviewEntryNumber
          backgroundColor={
            props.participantType === 'interviewer'
              ? theme.color.green[4]
              : theme.color.red.iM
          }
          entryNumber={props.participant.entryNumber.toString()}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled(Button)<{ width: number; height: number }>`
  ${({ width, height }) => css`
    width: ${width}px;
    height: ${height}px;
  `}
  ${buttonResetStyle}
  position: relative;
`
const ScreenShareIconWrapper = styled.div`
  ${Wrapper} & {
    width: 100%;
    height: 100%;
  }
  background: ${theme.color.white[1]};
  border: 2px solid ${theme.color.white[1]};
  display: flex;
  align-items: center;
  justify-content: center;
`
const ScreenShareIcon = styled(Icon)`
  width: 40px;
  height: 33px;
`
type VideoProps = {
  width: number
  mirrorEffect?: boolean
}
const Video = styled.video<VideoProps>`
  ${Wrapper} & {
    width: 100%;
    height: 100%;
  }
  background: ${theme.color.black[1]};
  border: 2px solid ${theme.color.white[1]};
  ${({ mirrorEffect }) => mirrorEffect && 'transform: scaleX(-1);'}
`
const CameraOffImage = styled(_CameraOffImage)`
  ${Wrapper} & {
    width: 100%;
    height: 100%;
  }
  object-fit: cover;
`

const UnavailableVideoInputImage = styled(_UnavailableVideoInputImage)`
  ${Wrapper} & {
    width: 100%;
    height: 100%;
  }
  object-fit: cover;
`

const Wifi = styled(_Wifi)`
  width: 16px;
  height: 16px;
  ${Wrapper} & {
    position: absolute;
    top: 4px;
    right: 4px;
  }
`
const MuteMikeIcon = styled(Icon)`
  width: 12px;
  height: 12px;
  padding: 4px;
  border-radius: 50%;
  color: ${theme.color.white[1]};
  background-color: ${theme.color.red[2]};
  opacity: 0.9;

  ${Wrapper} & {
    position: absolute;
    bottom: 4px;
    right: 4px;
  }
`
const Overlay = styled.div`
  ${Wrapper} & {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
  background-color: ${theme.color.black[1]}80;
`
const InterviewEntryNumber = styled(_InterviewEntryNumber)`
  ${Wrapper} & {
    position: absolute;
    top: 2px;
    left: 2px;
  }
`
const PinIcon = styled(Icon)`
  ${Overlay} & {
    position: absolute;
    top: 22px;
    left: 1px;
  }
  width: 18px;
  height: 20px;
  color: ${theme.color.white[1]};
`
