import { useEffect } from 'react'
import {
  useUserPublishserStatus,
  useScreenSharingPublishserStatus,
  useScreenSharingSubscriberStatus,
} from '@/lib/react-interview-sdk/hooks/values'
import { comlinkPush } from '@/comlink'

const comlinkPushSignalingSuccess = (arg: {
  action: string
  interviewGuid: string
  channelId: string
  webrtcHost: string
  connectionId: string | null
}) => {
  comlinkPush({
    type: 'system_activity',
    action: arg.action,
    targetName: 'interviewGuid',
    targetIdStr: arg.interviewGuid,
    metadata: {
      channelId: arg.channelId,
      connectionId: arg.connectionId ?? '',
      soraHost: arg.webrtcHost,
    },
  })
}

const comlinkPushSignalingError = (arg: {
  action: string
  interviewGuid: string
  channelId: string
  webrtcHost: string
  reason: string
}) => {
  comlinkPush({
    type: 'system_activity',
    action: arg.action,
    targetName: 'interviewGuid',
    targetIdStr: arg.interviewGuid,
    metadata: {
      channelId: arg.channelId,
      soraHost: arg.webrtcHost,
      reason: arg.reason,
    },
  })
}

const comlinkPushSignalingDisconnected = (arg: {
  action: string
  interviewGuid: string
  channelId: string
  webrtcHost: string
  title: string
  code?: number
  reason?: string
}) => {
  comlinkPush({
    type: 'system_activity',
    action: arg.action,
    targetName: 'interviewGuid',
    targetIdStr: arg.interviewGuid,
    metadata: {
      channelId: arg.channelId,
      soraHost: arg.webrtcHost,
      title: arg.title,
      code: arg.code?.toString() ?? '',
      reason: arg.reason ?? '',
    },
  })
}

export const useComlinkPushSignalingStatus = (interviewGuid: string) => {
  const userPublisherStatus = useUserPublishserStatus()
  useEffect(() => {
    switch (userPublisherStatus.status) {
      case 'Completed':
        comlinkPushSignalingSuccess({
          interviewGuid,
          action: 'succeeded_to_connect_to_sora_in_interview',
          ...userPublisherStatus,
        })
        break
      case 'Error':
        comlinkPushSignalingError({
          interviewGuid,
          action: 'failed_to_connect_to_sora_in_interview',
          ...userPublisherStatus,
        })
        break
      case 'Disconnected':
        comlinkPushSignalingDisconnected({
          interviewGuid,
          action: 'disconnected_to_connect_to_sora_in_interview',
          ...userPublisherStatus,
        })
        break
    }
  }, [interviewGuid, userPublisherStatus])

  const screenSharingPublisherStatus = useScreenSharingPublishserStatus()
  useEffect(() => {
    switch (screenSharingPublisherStatus.status) {
      case 'Completed':
        comlinkPushSignalingSuccess({
          interviewGuid,
          action: 'succeeded_to_screen_sharing_connect_to_sora_in_interview',
          ...screenSharingPublisherStatus,
        })
        break
      case 'Error':
        comlinkPushSignalingError({
          interviewGuid,
          action: 'failed_to_connect_screen_sharing_to_sora_in_interview',
          ...screenSharingPublisherStatus,
        })
        break
      case 'Disconnected':
        // 現状、まだ画面共有の切断はハンドリングしていない
        comlinkPushSignalingDisconnected({
          interviewGuid,
          action: 'disconnected_to_connect_screen_sharing_to_sora_in_interview',
          ...screenSharingPublisherStatus,
        })
        break
    }
  }, [interviewGuid, screenSharingPublisherStatus])

  const screenSharingSubscriberStatus = useScreenSharingSubscriberStatus()
  useEffect(() => {
    switch (screenSharingSubscriberStatus.status) {
      case 'Completed':
        comlinkPushSignalingSuccess({
          interviewGuid,
          action:
            'succeeded_to_screen_sharing_recv_connect_to_sora_in_interview',
          ...screenSharingSubscriberStatus,
        })
        break
      case 'Error':
        comlinkPushSignalingError({
          interviewGuid,
          action: 'failed_to_connect_screen_recv_sharing_to_sora_in_interview',
          ...screenSharingSubscriberStatus,
        })
        break
      case 'Disconnected':
        comlinkPushSignalingDisconnected({
          interviewGuid,
          action:
            'disconnected_to_connect_screen_recv_sharing_to_sora_in_interview',
          ...screenSharingSubscriberStatus,
        })
        break
    }
  }, [interviewGuid, screenSharingSubscriberStatus])
}
