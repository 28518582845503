import { useRecoilValue } from 'recoil'
import {
  mainVideoState,
  pinnedTileState,
} from '@/lib/react-interview-sdk/states'

export const useMainVideo = () => {
  return useRecoilValue(mainVideoState)
}

export const usePinnedTileId = () => {
  return useRecoilValue(pinnedTileState)
}
