export const placeholder = {
  orgName: '例) 株式会社スタジアム',
  name: '例) 山田 太郎',
  nameKana: '例) やまだ たろう',
  tel: '例) 09001234567',
  department: '例) マーケティング部',
  postCode: '例) 1070052',
  address: '例) 東京都港区赤坂3-4-3 〇〇ビル6F',
  sessionName: '例) 新卒向け説明会',
  email: '例) yamada.taro@example.com',
  lastName: '例) 山田',
  firstName: '例) 太郎',
  select: '選択してください',
}
