import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { INTERNAL_PATHS } from '@/services/urlService'
import { InterviewRoutes } from './InterviewRoutes'
import { NotFound } from '@/components/NotFound'

export const InterviewsRoutes = () => {
  return (
    <Switch>
      <Route
        path={INTERNAL_PATHS.participant.interviews.interview.index}
        component={InterviewRoutes}
      />
      <Route path="*" component={NotFound} />
    </Switch>
  )
}
