import { useRef, useCallback, useState, useEffect } from 'react'
import soraSdk, { ConnectionOptions, ConnectionPublisher } from 'sora-js-sdk'
import { comlinkPush } from '@/comlink'

export const useSora = (presentationGuid: string) => {
  const sendonlyRef = useRef<ConnectionPublisher>()
  const [unstableLevel, setUnstableLevel] = useState<number>()

  const connect = useCallback(
    async ({
      hostname,
      channelId,
      screenShareStream,
    }: {
      hostname: string
      channelId: string
      screenShareStream: MediaStream
    }) => {
      const options: ConnectionOptions = {
        multistream: false,
        videoCodecType: 'H264',
        videoBitRate: 800,
        audio: false,
      }
      const metadata = {
        guid: presentationGuid,
      }
      const sora = soraSdk.connection(`wss://${hostname}/signaling`, false)
      const sendonly = sora.sendonly(channelId, metadata, options)
      sendonly.on('notify', (data) => {
        if (data.event_type === 'network.status') {
          setUnstableLevel(data.unstable_level)
        }
      })
      comlinkPush({
        type: 'system_activity',
        action: 'connect_screen_to_sora',
        targetName: 'channelId',
        targetIdStr: channelId,
      })
      await sendonly.connect(screenShareStream)
      sendonlyRef.current = sendonly
    },
    [presentationGuid]
  )

  useEffect(() => {
    if (unstableLevel === undefined) return
    comlinkPush({
      type: 'system_activity',
      action: 'change_unstable_level',
      targetName: 'presentationGuid',
      targetIdStr: presentationGuid,
      metadata: {
        videoType: 'screenShare',
        unstableLevel: unstableLevel + '',
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unstableLevel])

  const disconnect = useCallback(async () => {
    if (!sendonlyRef.current) return
    comlinkPush({
      type: 'system_activity',
      action: 'disconnect_screen_from_sora',
      targetName: 'channelId',
      targetIdStr: sendonlyRef.current.channelId,
    })
    await sendonlyRef.current.disconnect()
  }, [])

  return {
    connect,
    disconnect,
  }
}
