import { useCallback } from 'react'

type OnChange = (deviceId: string) => void
type ChangeCallback = (e: React.ChangeEvent<HTMLSelectElement>) => void

export function useHandleChangeDevice(onChange: OnChange): ChangeCallback {
  return useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      onChange(e.target.value)
    },
    [onChange]
  )
}
