export function injectAlertBox() {
  const organizerUrl = process.env.REACT_APP_ORGANIZER_ALERT_URI
  const participantsUrl = process.env.REACT_APP_PARTICIPANTS_ALERT_URI
  const alertBoxUrl = process.env.REACT_APP_ALERT_BOX_URI
  if (!organizerUrl || !participantsUrl || !alertBoxUrl)
    throw new Error('env not found')

  const isFormPage = window.location.pathname.match(/^\/organizer\/new/)
  if (isFormPage) return

  const jsonUrl = window.location.pathname.match(/^\/organizer/)
    ? organizerUrl
    : participantsUrl

  const script = document.createElement('script')
  script.src = alertBoxUrl

  document.body.appendChild(script)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ;(window as any).Alertbox = (window as any).Alertbox || []
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ;(window as any).Alertbox.push(jsonUrl)
}
