import { atom } from 'recoil'
import { namespace } from './namespace'

export const audioInputsState = atom<MediaDeviceInfo[]>({
  key: `${namespace}/audioInputsState`,
  default: [],
})

export const selectedAudioInputDeviceState = atom<string | null>({
  key: `${namespace}/selectedAudioInputDeviceState`,
  default: null,
})
