import React from 'react'
import styled from 'styled-components'
import { Txt, NewTabLink } from '@blue-agency/rogue'
import reactStringReplace from 'react-string-replace'
import { EXTERNAL_URLS } from '@/services/urlService'
import { FormStep } from '@/components/OrganizerForm/types'

const text = {
  first:
    'アップグレード後は、現在ご利用中のこちらのアカウントおよびデータを引き継いでご利用いただけます。',
  second: `スタンダードプラン、プレミアムプランをご希望の場合や、書面での見積書・申込書が必要な場合など
その他ご不明点がございましたらこちらまでお問い合わせください`,
}

type Props = {
  step: FormStep
}
export const Upper: React.FCX<Props> = ({ className, step }) => {
  if (step !== 'input') return null
  return (
    <Wrapper className={className}>
      <Txt>{text.first}</Txt>
      <Txt>
        {reactStringReplace(text.second, 'こちら', (match, index) => (
          <NewTabLink
            key={index}
            href={EXTERNAL_URLS.contact}
            action="open_contact_page"
          >
            {match}
          </NewTabLink>
        ))}
      </Txt>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 15px;
`
