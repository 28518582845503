import React from 'react'
import styled from 'styled-components'
import { PrimaryButton, TertiaryButton } from '@blue-agency/rogue'
import { FormStep } from '@/components/OrganizerForm/types'
import { UpgradePlanContainer } from './hooks/useUpgradePlan'

type Props = {
  step: FormStep
}

export const Bottom: React.FCX<Props> = ({ className, step }) => {
  const { handleBackToInputStep, handleBackToMyPage, isLoading } =
    UpgradePlanContainer.useContainer()
  switch (step) {
    case 'input':
      return (
        <Wrapper className={className}>
          <ButtonGroup>
            <BackButton
              size="l1"
              text="管理画面に戻る"
              onClick={handleBackToMyPage}
              comlinkPushParams={{ action: 'back_to_my_page' }}
            />
            <ConfirmButton
              size="l1"
              type="submit"
              text="申込内容を確認"
              comlinkPushParams={{ action: 'to_upgrade_plan_confirm_step' }}
            />
          </ButtonGroup>
        </Wrapper>
      )
    case 'confirm':
      return (
        <Wrapper className={className}>
          <ButtonGroup>
            <BackButton
              size="l1"
              text="入力画面に戻る"
              onClick={handleBackToInputStep}
              comlinkPushParams={{ action: 'back_to_upgrade_plan_input_step' }}
            />
            <ApplyButton
              size="l1"
              type="submit"
              text="この内容で申込む"
              loading={isLoading}
              comlinkPushParams={{ action: 'upgrade_plan' }}
            />
          </ButtonGroup>
        </Wrapper>
      )
    case 'completed':
      return null
  }
}

const Wrapper = styled.div``
const ButtonGroup = styled.div`
  height: 76px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const ConfirmButton = styled(PrimaryButton)`
  ${ButtonGroup} & {
    margin-left: 20px;
  }
`
const BackButton = styled(TertiaryButton)``
const ApplyButton = styled(PrimaryButton)`
  ${ButtonGroup} & {
    margin-left: 20px;
  }
`
