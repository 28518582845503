import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { initSentry } from '@/initSentry'
import { captureException } from '@sentry/react'
import { CaptureExceptionProvider } from '@/hooks/useCaptureException'

const isReactAppSentryEnabled = process.env.REACT_APP_SENTRY_ENABLED === 'true'

let elm: JSX.Element

if (isReactAppSentryEnabled) {
  initSentry()
  elm = (
    <CaptureExceptionProvider value={captureException}>
      <App />
    </CaptureExceptionProvider>
  )
} else {
  elm = <App />
}

ReactDOM.render(elm, document.getElementById('root'))
