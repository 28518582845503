import React from 'react'
import styled from 'styled-components'
import { Txt } from '@blue-agency/rogue'
import { ApplyPageContainer } from '../../ApplyPageContainer'

export const PlanSection: React.FCX = ({ className }) => {
  const { promotion, inputtedData } = ApplyPageContainer.useContainer()
  const preferredStartDate = promotion.preferredStartDates.find(
    (date) => date.id.toString() === inputtedData.preferredStartDateId
  )
  return (
    <Wrapper className={className}>
      <Item>
        <Label>申込内容</Label>
        <ItemLabel size="s" bold>
          申込プラン
        </ItemLabel>
        <Txt size="s">{promotion.planName}</Txt>
      </Item>
      <Item>
        <Label />
        <ItemLabel size="s" bold>
          利用開始希望日
        </ItemLabel>
        <Txt size="s">{preferredStartDate?.label}</Txt>
      </Item>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 20px;
`
const Item = styled.div`
  &:nth-of-type(n + 2) {
    margin-top: 20px;
  }
  display: flex;
  align-items: center;
`
const Label = styled(Txt)`
  width: 160px;
`
const ItemLabel = styled(Txt)`
  width: 130px;
`
