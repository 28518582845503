import React, { forwardRef, useMemo, useCallback } from 'react'
import styled from 'styled-components'
import { Icon, theme } from '@blue-agency/rogue'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { useUserAgent } from '@blue-agency/react-utils'
import { comlinkPush } from '@/comlink'

type ScreenShareRef = HTMLVideoElement
type Props = {
  controls?: boolean
}

export const ScreenShare = forwardRef<ScreenShareRef, Props>(
  ({ className, controls = false }, ref) => {
    const fullScreenHandle = useFullScreenHandle()

    const ua = useUserAgent()
    const enableFullscreen = useMemo(() => {
      return controls && ua.os.name !== 'iOS' && ua.browser.name !== 'Safari'
    }, [ua, controls])

    const enterFull = useCallback(() => {
      try {
        fullScreenHandle.enter()
      } catch (e) {
        comlinkPush({
          type: 'system_activity',
          action: 'failed_to_enter_fullscreen_on_screen_share',
          metadata: {
            errorMessage: e.message ?? '',
          },
        })
        alert('全画面表示に対応していません。')
      }
    }, [fullScreenHandle])

    const exitFull = useCallback(() => {
      try {
        fullScreenHandle.exit()
      } catch (e) {
        comlinkPush({
          type: 'system_activity',
          action: 'failed_to_exit_fullscreen_on_screen_share',
          metadata: {
            errorMessage: e.message ?? '',
          },
        })
        alert('全画面表示の解除に失敗しました。')
      }
    }, [fullScreenHandle])

    return (
      <Wrapper>
        <FullScreen handle={fullScreenHandle}>
          <FullScreenContent>
            <Video className={className} autoPlay muted playsInline ref={ref} />
            {fullScreenHandle.active && enableFullscreen && (
              <Controls>
                <IconWrapper onClick={exitFull}>
                  <Icon name="exit-fullscreen" size="l" />
                </IconWrapper>
              </Controls>
            )}
          </FullScreenContent>
        </FullScreen>
        {enableFullscreen && (
          <Controls>
            <IconWrapper onClick={enterFull}>
              <Icon name="fullscreen" size="l" />
            </IconWrapper>
          </Controls>
        )}
      </Wrapper>
    )
  }
)

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  .fullscreen {
    height: 100%;
    width: 100%;
  }
`
const Video = styled.video`
  ${Wrapper} & {
    height: 100%;
    width: 100%;
  }
`
const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px;
  height: 56px;
  ${Wrapper} & {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
  }
`
const FullScreenContent = styled.div`
  position: relative;
  ${Wrapper} & {
    width: 100%;
    height: 100%;
  }
`
const IconWrapper = styled.div`
  color: ${theme.color.white[1]};
  ${FullScreenContent} & {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
`
