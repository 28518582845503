import React from 'react'
import styled, { css } from 'styled-components'
import { Txt, NewTabLink } from '@blue-agency/rogue'
import { EXTERNAL_URLS } from '@/services/urlService'

export const Body: React.FCX = ({ className }) => {
  return (
    <Wrapper className={className}>
      <MainTxt>情報登録が完了しました</MainTxt>
      <TxtWrapper>
        <Txt>
          登録完了メールを送信しました。ご利用開始まで今しばらくお待ちください。
          <br />
          ご不明点がございましたら、
          <NewTabLink
            href={EXTERNAL_URLS.supportPolicy}
            action="open_support_policy_page"
          >
            こちら
          </NewTabLink>
          までお問い合わせください。
        </Txt>
      </TxtWrapper>
    </Wrapper>
  )
}

const flex = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const Wrapper = styled.div`
  ${flex}
`
const MainTxt = styled(Txt).attrs({ size: 'l' })`
  margin-top: 60px;
`
const TxtWrapper = styled.div`
  margin-top: 20px;
  ${flex}
`
