import React from 'react'
import styled from 'styled-components'
import {
  Modal as _Modal,
  TertiaryButton,
  PrimaryButton,
  WeakColorSeparator,
  Dropdown,
  Separator,
  Txt,
  Icon,
  theme,
} from '@blue-agency/rogue'
import { ChangeDeviceProps } from '..'
import { usePlayDemoSound } from './usePlayDemoSound'
import { useHandleChangeDevice } from './useHandleChangeDevice'
import { useDeviceOptions } from './useDeviceOptions'
import { useAudioInputVisualizer } from '@/lib/react-interview-sdk/hooks/utils'

type Props = {
  active: boolean
  changeDeviceProps: ChangeDeviceProps
  onClose: () => void
}

export const ChangeDeviceModal: React.FCX<Props> = ({
  className,
  active,
  onClose,
  changeDeviceProps,
}) => {
  const cameraOptions = useDeviceOptions(changeDeviceProps.cameraDevices)
  const micOptions = useDeviceOptions(changeDeviceProps.micDevices)
  const speakerOptions = useDeviceOptions(changeDeviceProps.speakerDevices)

  const { playSound, isPlaying } = usePlayDemoSound(
    changeDeviceProps.selectedSpeakerDeviceId
  )

  const audioWaveHeights = useAudioInputVisualizer({
    waveBarLength: 12,
    baseBarHeight: 10,
    maxBarHeight: 80,
    fftSize: 128,
  })

  const handleChangeCamera = useHandleChangeDevice(
    changeDeviceProps.onChangeCameraDevice
  )
  const handleChangeMic = useHandleChangeDevice(
    changeDeviceProps.onChangeMicDevice
  )
  const handleChangeSpeaker = useHandleChangeDevice(
    changeDeviceProps.onChangeSpeakerDevice
  )

  return (
    <Modal
      className={className}
      active={active}
      title="デバイス選択"
      onClose={onClose}
    >
      <>
        <Upper>
          <Row>
            <Label>カメラ</Label>
            <Dropdown
              size="m"
              value={changeDeviceProps.selectedCameraDeviceId}
              options={cameraOptions}
              onChange={handleChangeCamera}
              disabled={!changeDeviceProps.selectedCameraDeviceId}
            />
            <Demo>
              {changeDeviceProps.selectedCameraDeviceId && (
                <Video ref={changeDeviceProps.videoRef} />
              )}
            </Demo>
          </Row>
          <WeakColorSeparator />
          <Row>
            <Label>マイク</Label>
            <Dropdown
              size="m"
              value={changeDeviceProps.selectedMicDeviceId}
              options={micOptions}
              onChange={handleChangeMic}
            />
            <Demo>
              {changeDeviceProps.muted ? (
                <Txt size="s">ミュート中</Txt>
              ) : (
                <WaveBlock>
                  {audioWaveHeights.map((barHeight, index) => (
                    <WaveBar key={index} barHeight={barHeight} />
                  ))}
                  <WaveSeparator />
                </WaveBlock>
              )}
            </Demo>
          </Row>
          <WeakColorSeparator />
          {speakerOptions.length > 0 && (
            <>
              <Row>
                <Label>スピーカー</Label>
                <Dropdown
                  size="m"
                  value={changeDeviceProps.selectedSpeakerDeviceId}
                  options={speakerOptions}
                  onChange={handleChangeSpeaker}
                  disabled={isPlaying}
                />
                <Demo>
                  <DemoSoundPlayButton
                    icon={<Icon name="filled-play" size="s" />}
                    comlinkPushParams={{
                      action: 'start_playing_demo_sound_during_interview',
                    }}
                    onClick={playSound}
                    disabled={isPlaying}
                  />
                  {isPlaying && <PlayingTxt>再生中</PlayingTxt>}
                </Demo>
              </Row>
              <WeakColorSeparator />
            </>
          )}
        </Upper>
        <Bottom>
          <ButtonGroup>
            <CloseButton
              comlinkPushParams={{ action: 'close_ChangeDeviceModal' }}
              onClick={onClose}
            >
              閉じる
            </CloseButton>
          </ButtonGroup>
        </Bottom>
      </>
    </Modal>
  )
}

const Modal = styled(_Modal).attrs({ size: 'm' })`
  max-width: 90%;
`

const Upper = styled.div`
  padding: 0 16px;
`

const Row = styled.div`
  display: flex;
  margin-top: 20px;
  align-items: center;

  :not(:last-child) {
    margin-bottom: 20px;
  }

  :last-child {
    margin-bottom: 40px;
  }
`

const Demo = styled.div`
  padding-left: 20px;
  display: flex;
`

const Label = styled.div`
  margin-top: 8px;
  width: 136px;
`

const DemoSoundPlayButton = styled(PrimaryButton).attrs({ size: 'm1' })`
  width: 24px;
  border-radius: 50%;
`
const PlayingTxt = styled(Txt).attrs({ size: 's' })`
  margin-left: 11px;
`

const Bottom = styled.div`
  padding: 20px 16px;
`
const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const CloseButton = styled(TertiaryButton).attrs({ size: 'l1' })`
  width: 166px;
  height: 44px;
`
const WaveBlock = styled.div`
  width: 100px;
  height: 56px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`
const WaveSeparator = styled(Separator)`
  position: absolute;
`
const WaveBar = styled.div<{ barHeight: number }>`
  &:nth-of-type(n + 2) {
    margin-left: 4px;
  }
  width: 4px;
  height: ${({ barHeight }) => barHeight}px;
  background-color: ${theme.color.green[4]};
  border-radius: 4px;
`

const Video = styled.video`
  width: 100px;
  height: 56px;
  transform: scaleX(-1);
  background-color: #999;
`
