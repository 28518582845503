import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useState } from 'react'
import { toast } from '@blue-agency/rogue'
import { StatusCode } from 'grpc-web'
import { NotPermittedModalContainer } from '@/containers/NotPermittedModalContainer'
import { UpdateScheduleModal } from '../UpdateScheduleModal'
import { HallsContainer } from '@/pages/OrganizerRoutes/MyRoutes/HallsRoutes/HallsPage/hooks/HallsContainer'
import { RegisterForm, registerFormValidationSchema } from './useRegister'

type UpdateForm = RegisterForm
const updateFormValidationSchema = registerFormValidationSchema

export const useUpdateScheduleModal = ({
  hallGuid,
  onClose,
  onOpen,
  currentName,
  currentScheduledParticipantsNumber,
}: React.ComponentProps<typeof UpdateScheduleModal>) => {
  const { updateHall } = OrganizerServiceContainer.useContainer()
  const notPermittedModal = NotPermittedModalContainer.useContainer()
  const { reloadHalls } = HallsContainer.useContainer()

  const { register, handleSubmit, errors, formState, setValue } =
    useForm<UpdateForm>({
      resolver: yupResolver(updateFormValidationSchema),
      mode: 'onBlur',
      defaultValues: {
        sessionName: currentName,
        scheduledParticipantsNumber: currentScheduledParticipantsNumber,
      },
    })

  const [isSubmitError, setIsSubmitError] = useState(false)

  const onSubmit = useCallback<SubmitHandler<UpdateForm>>(
    async (data) => {
      try {
        const scheduledAt = data.scheduledAtDate
        scheduledAt.setHours(data.scheduledAtHour)
        scheduledAt.setMinutes(data.scheduledAtMinute)
        await updateHall({
          hallGuid: hallGuid,
          sessionName: data.sessionName,
          scheduledParticipantsNumber: data.scheduledParticipantsNumber,
          scheduledAt: scheduledAt,
        })
      } catch (e) {
        if (e.code === StatusCode.PERMISSION_DENIED) {
          onClose()
          notPermittedModal.open('説明会情報編集', { callback: onOpen })
          return
        }
        setIsSubmitError(true)
        throw e
      }
      onClose()
      reloadHalls()
      toast('説明会の情報を更新しました')
    },
    [onClose, reloadHalls, updateHall, hallGuid, notPermittedModal, onOpen]
  )

  return {
    register,
    handleSubmit,
    errors,
    isSubmitError,
    formState,
    onSubmit,
    setValue,
  }
}
