import { useState, useCallback, useRef } from 'react'
import { createContainer } from '@blue-agency/front-state-management'

type Option = {
  callback?: () => void
  seminar?: boolean
}

const useCommunicationErrorModal = () => {
  const [showCommunicationErrorModal, setShowCommunicationErrorModal] =
    useState(false)
  const [seminar, setSeminar] = useState(false)
  const callbackRef = useRef<(() => void) | null>(null)

  const handleCommunicationErrorModalOpen = useCallback((option?: Option) => {
    if (option) {
      option.seminar && setSeminar(true)
      if (option.callback) {
        callbackRef.current = option.callback
      }
    }
    setShowCommunicationErrorModal(true)
  }, [])

  const handleCommunicationErrorModalClose = useCallback(() => {
    setShowCommunicationErrorModal(false)
    setSeminar(false)
    if (callbackRef.current) {
      callbackRef.current()
      callbackRef.current = null
    }
  }, [])

  return {
    seminar,
    showCommunicationErrorModal,
    handleCommunicationErrorModalOpen,
    handleCommunicationErrorModalClose,
  }
}

export const CommunicationErrorModalContainer = createContainer(
  useCommunicationErrorModal
)
