import React, { useCallback, useState } from 'react'
import { Room } from './Room'
import { provide } from '@blue-agency/front-state-management'
import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'
import { InterviewContainer } from './hooks/useInterview'
import { Redirect } from 'react-router-dom'
import { INTERNAL_PATHS, fillParams } from '@/services/urlService'
import { GetPresenterInterviewResponse } from '@blue-agency/proton/web/v2/yashiori_bff/yashiori_bff_service_pb'
import { EnvironmentCheck } from './EnvironmentCheck'
import { WaitingRoom } from './WaitingRoom'
import { useTitle } from '@/hooks/useTitle'
import { storageKey } from '@/constants/storageKey'
import { BrowserContainer } from '@/containers/BrowserContainer'
import { UnsupportedBrowser } from './UnsupportedBrowser'
const { Status } = GetPresenterInterviewResponse

export const RoomPage = provide(
  InterviewContainer,
  OrganizerServiceContainer
)(() => {
  const { initialStatus, token, interviewGuid, showWaitingRoom, spotlight } =
    InterviewContainer.useContainer()
  const [isFinishedEnvCheck, setIsFinishedEnvCheck] = useState(() => {
    return (
      sessionStorage.getItem(
        storageKey.session.environmentCheck(interviewGuid)
      ) === 'done'
    )
  })

  // スポットライト機能はFirefox非対応
  const { isFirefox } = BrowserContainer.useContainer()
  const unspportedBrowser = spotlight.enabled && isFirefox

  useTitle('Web面接')

  const handleFinish = useCallback(() => {
    sessionStorage.setItem(
      storageKey.session.environmentCheck(interviewGuid),
      'done'
    )
    setIsFinishedEnvCheck(true)
  }, [interviewGuid])

  if (initialStatus === Status.EXPIRED) {
    return (
      <Redirect
        to={fillParams({
          path: INTERNAL_PATHS.organizer.my.interviews.interview.expired,
          params: { token, interviewGuid },
        })}
      />
    )
  }
  if (initialStatus === Status.FINISHED)
    return (
      <Redirect
        to={fillParams({
          path: INTERNAL_PATHS.organizer.my.interviews.interview.finish,
          params: { token, interviewGuid },
        })}
      />
    )
  if (unspportedBrowser) return <UnsupportedBrowser />
  if (!isFinishedEnvCheck) return <EnvironmentCheck onFinish={handleFinish} />
  if (showWaitingRoom) return <WaitingRoom />
  return <Room />
})
