import React from 'react'
import styled from 'styled-components'
import { Txt as _Txt } from '@blue-agency/rogue'

export const Upper: React.FCX = ({ className }) => {
  return (
    <Wrapper className={className}>
      <Txt>
        入力内容に不備がある場合、利用開始が遅れることがありますので、正しい情報のご入力をお願いします。
      </Txt>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 15px;
`
const Txt = styled(_Txt)`
  &:nth-of-type(n + 2) {
    margin-top: 20px;
  }
`
