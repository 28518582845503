import {
  createContainer,
  useCachedPromise,
} from '@blue-agency/front-state-management'
import { useState, useCallback, useEffect, useMemo } from 'react'
import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'
import { InterviewContainer } from '../../../hooks/useInterview'
import {
  GetInterviewQualityResponse,
  GetPresenterInterviewResponse,
  InterviewQualityMode,
} from '@blue-agency/proton/web/v2/yashiori_bff/yashiori_bff_service_pb'

import { useSessionToken } from './useSessionToken'
import { CommunicationErrorModalContainer } from '@/containers/CommunicationErrorModalContainer'
import {
  InterviewChatWebSocketParams,
  parseUserGuid,
} from '@/services/interviewChatService'
import { Message } from '@/components/Chat/Messages'
import { useModal } from '@/hooks/useModal'
import { cacheKey } from '@/services/bffService'
import { BeepContainer } from '@/containers/BeepContainer'
import { enteringUserBeepSrc } from './enteringUserBeepSrc'
import { startScreenSharingBeepSrc } from './startScreenSharingBeepSrc'

import { useStartInterviewBeep } from '@/hooks/useStartInterviewBeep'

import {
  Quality,
  QualityMode,
  QualityModeLow,
  QualityModeNormal,
} from '@/services/interviewService/types/Quality'
import { useComlinkPushSignalingStatus } from '@/services/interviewService/hooksForApp/useComlinkPushSignalingStatus'

import { toast } from '@blue-agency/rogue'
import {
  SoraConnectionConfig,
  Metadata,
  InterviewConfig,
} from '@/lib/react-interview-sdk'

import {
  useInterviewStatus,
  useUnstableLevel,
  useIsOtherScreenShared,
  useOwnParticipant,
  useUserPublishserStatus,
  useBgEffectCode,
} from '@/lib/react-interview-sdk/hooks/values'
import {
  useStartScreenSharing,
  useStopScreenSharing,
  useBroadcastStart,
  useBroadcastFinish,
} from '@/lib/react-interview-sdk/hooks/controls'
import { SignalingNotifyConnectionCreated } from '@blue-agency/interview-sdk-js'
import { comlinkPush } from '@/comlink'
// import { useSpecificMediaDevice } from '@blue-agency/react-media-devices'

const { Status } = GetPresenterInterviewResponse

const vaderWebHost = process.env.REACT_APP_VADER_WEB_HOST
if (!vaderWebHost) throw new Error('vaderWebHost not found')

const logRTCStatsEndpoint = process.env.REACT_APP_LOG_RTC_STATS_ENDPOINT
if (!logRTCStatsEndpoint) throw new Error('logRTCStatsEndpoint not found')

const SCREEN_SHARING_BITRATE = 256
const USE_DATACHANNEL_SIGNALING = true
const IGNORE_DISCONNECT_WEBSOCKET = true

const toQuality = (qualityRes: GetInterviewQualityResponse): Quality => {
  return {
    mode:
      qualityRes.getOldMode() === GetInterviewQualityResponse.Mode.LOW ||
      qualityRes.getMode() === InterviewQualityMode.LOW
        ? QualityModeLow
        : QualityModeNormal,
    videoBitRate: qualityRes.getVideoBitRate(),
    videoFrameRate: qualityRes.getVideoFrameRate(),
  }
}

const useRoom = () => {
  const { getPresenterInterviewRes, initialStatus, interviewGuid, spotlight } =
    InterviewContainer.useContainer()
  const {
    startInterview,
    finishInterview,
    listChatMessagesForInterview,
    getPresenterInterview,
    getSignalingPoints,
    getInterviewQuality,
    changeInterviewQuality: _changeInterviewQuality,
  } = OrganizerServiceContainer.useContainer()

  // 入室前にいるときにqualityが変更される可能性があるので再取得する
  const initialQuality = useCachedPromise(
    cacheKey.getInterviewQualityInRoom({ interviewGuid }),
    () => getInterviewQuality({ interviewGuid })
  )

  // qualityはwsで変更イベントが飛んでくると変わる
  const [quality, setQuality] = useState<Quality>(() =>
    toQuality(initialQuality)
  )
  const handleChangedQuality = useCallback(async () => {
    const res = await getInterviewQuality({ interviewGuid })
    const q = toQuality(res)
    setQuality(q)
    const modeTxt = q.mode === QualityModeLow ? '軽量モード' : '通常モード'
    toast(`${modeTxt}に変更されました。`)
  }, [getInterviewQuality, interviewGuid])

  const { beep } = BeepContainer.useContainer()

  const { sessionToken } = useSessionToken()

  const [status, setStatus] = useState(initialStatus)

  const { handleCommunicationErrorModalOpen } =
    CommunicationErrorModalContainer.useContainer()

  const signalingPoints = useCachedPromise(
    cacheKey.getSignalingPoints({ interviewGuid }),
    () => getSignalingPoints({ interviewGuid })
  )

  const mainSignalingPoint = signalingPoints.getMain()
  if (!mainSignalingPoint) {
    throw new Error('mainSignalingPoint not found')
  }
  const mainSoraConnectionConfig: SoraConnectionConfig = useMemo(
    () => ({
      webrtcHost: mainSignalingPoint.getWebrtcHost(),
      channelId: mainSignalingPoint.getChannelId(),
      spotlight: spotlight.enabled,
      spotlightNumber: spotlight.spotlightNumber,
      dataChannelSignaling: USE_DATACHANNEL_SIGNALING,
      ignoreDisconnectWebSocket: IGNORE_DISCONNECT_WEBSOCKET,
      metadata: {
        session_token: sessionToken.user,
        invitation_token: getPresenterInterviewRes.getInterviewerToken(),
      },
      videoBitRate: quality.videoBitRate,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [quality.videoBitRate]
  )

  // 画面共有送信用
  const screenSharingSignalingPoint = signalingPoints.getScreenSharing()
  if (!screenSharingSignalingPoint) {
    throw new Error('screenSharingSignalingPoint not found')
  }
  const screenSharingSoraConnectionConfig: SoraConnectionConfig = useMemo(
    () => ({
      webrtcHost: screenSharingSignalingPoint.getWebrtcHost(),
      channelId: screenSharingSignalingPoint.getChannelId(),
      metadata: {
        session_token: sessionToken.screenSharing,
        invitation_token: getPresenterInterviewRes.getInterviewerToken(),
      },
      videoBitRate: SCREEN_SHARING_BITRATE,
      dataChannelSignaling: USE_DATACHANNEL_SIGNALING,
      ignoreDisconnectWebSocket: IGNORE_DISCONNECT_WEBSOCKET,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  // 画面共有受信用
  const screenSharingRecvSoraConnectionConfig: SoraConnectionConfig = useMemo(
    () => ({
      channelId: screenSharingSignalingPoint.getChannelId(),
      webrtcHost: screenSharingSignalingPoint.getWebrtcHost(),
      metadata: {
        session_token: sessionToken.screenSharingRecv,
        invitation_token: getPresenterInterviewRes.getInterviewerToken(),
      },
      videoBitRate: SCREEN_SHARING_BITRATE,
      dataChannelSignaling: USE_DATACHANNEL_SIGNALING,
      ignoreDisconnectWebSocket: IGNORE_DISCONNECT_WEBSOCKET,
    }),
    // NOTE: https://github.com/blue-agency/yashiori-front/pull/1205#discussion_r454015996
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const interviewStatus = useInterviewStatus()

  const onConnectionCreated = useCallback(
    (data: SignalingNotifyConnectionCreated) => {
      const metadata = data.metadata as Metadata
      // 入室解除前は、面接官が入室した時のみ入室音を鳴らす
      if (interviewStatus !== 'started') {
        if (metadata.interview_role === 'recruiter') {
          beep(enteringUserBeepSrc)
        }
        return
      }
      if (
        metadata.interview_role === 'applicant' ||
        metadata.interview_role === 'recruiter'
      ) {
        beep(enteringUserBeepSrc)
      }
    },
    [beep, interviewStatus]
  )

  const interviewConfig = useMemo(
    (): InterviewConfig => ({
      webSocketUrl: `wss://${vaderWebHost}/channel_status/${getPresenterInterviewRes.getVideoInterviewGuid()}`,
      soraConnectionConfigs: {
        main: mainSoraConnectionConfig,
        screenSharing: screenSharingSoraConnectionConfig,
        screenSharingRecv: screenSharingRecvSoraConnectionConfig,
      },
      participantType: 'interviewer',
      videoFrameRate: quality.videoFrameRate,
      logRTCStatsEndpoint,
      onConnectionCreated,
      autoFocusedToMain: spotlight.enabled,
      onChangedQuality: handleChangedQuality,
    }),
    [
      getPresenterInterviewRes,
      handleChangedQuality,
      mainSoraConnectionConfig,
      onConnectionCreated,
      quality.videoFrameRate,
      screenSharingRecvSoraConnectionConfig,
      screenSharingSoraConnectionConfig,
      spotlight.enabled,
    ]
  )

  const userPublisherStatus = useUserPublishserStatus()

  useComlinkPushSignalingStatus(interviewGuid)

  const unstableLevel = useUnstableLevel()

  useStartInterviewBeep(interviewStatus)

  useEffect(() => {
    comlinkPush({
      type: 'system_activity',
      action: 'change_unstable_level',
      targetName: 'interviewGuid',
      targetIdStr: interviewGuid,
      metadata: {
        userType: 'interviewer',
        unstableLevel: unstableLevel + '',
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unstableLevel])

  const bgEffectCode = useBgEffectCode()
  useEffect(() => {
    comlinkPush({
      type: 'system_activity',
      action: 'selected_background_effect',
      metadata: {
        interviewGuid,
        backgroundEffect: bgEffectCode,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bgEffectCode])

  const startedAt = getPresenterInterviewRes.getStartedAt()
  const [startTime, setStartTime] = useState(startedAt && startedAt.toDate())

  const [showUsers, setShowUsers] = useState(true)
  const [showChat, setShowChat] = useState(false)

  const toggleShowUsers = useCallback(() => setShowUsers((prev) => !prev), [])
  const toggleShowChat = useCallback(() => setShowChat((prev) => !prev), [])

  const finishModal = useModal()
  const startScreenShareModal = useModal()
  const finishScreenShareModal = useModal()
  const screenSharingLimitModal = useModal()
  const isOtherScreenShared = useIsOtherScreenShared()

  useEffect(() => {
    if (isOtherScreenShared) {
      startScreenShareModal.close()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOtherScreenShared])

  const startScreenSharing = useStartScreenSharing()
  const stopScreenSharing = useStopScreenSharing()

  const handleScreenShareStart = useCallback(async () => {
    await startScreenSharing()
    // if (!started) return
    beep(startScreenSharingBeepSrc)
    startScreenShareModal.close()
  }, [beep, startScreenShareModal, startScreenSharing])

  const handleScreenShareFinish = useCallback(() => {
    stopScreenSharing()
    finishScreenShareModal.close()
  }, [stopScreenSharing, finishScreenShareModal])

  const broadcastStart = useBroadcastStart()

  const handleStart = useCallback(async () => {
    comlinkPush({
      type: 'manual_activity',
      action: 'start_interview',
      targetName: 'interviewGuid',
      targetIdStr: interviewGuid,
    })
    const res = await startInterview({
      interviewGuid,
    }).catch((err) => {
      handleCommunicationErrorModalOpen()
      throw err
    })
    const startedAt = res.getStartedAt()
    if (!startedAt) throw new Error('Not found startedAt')
    setStartTime(startedAt.toDate())
    broadcastStart()
    setStatus(Status.STARTED)
  }, [
    interviewGuid,
    startInterview,
    broadcastStart,
    handleCommunicationErrorModalOpen,
  ])

  const broadcastFinish = useBroadcastFinish()

  const handleFinish = useCallback(async () => {
    comlinkPush({
      type: 'manual_activity',
      action: 'finish_interview',
      targetName: 'interviewGuid',
      targetIdStr: interviewGuid,
    })
    await finishInterview({
      interviewGuid,
    }).catch((err) => {
      finishModal.close()
      const callback = () => finishModal.open()
      handleCommunicationErrorModalOpen({ callback })
      throw err
    })
    broadcastFinish()
    setStatus(Status.FINISHED)
  }, [
    interviewGuid,
    finishInterview,
    broadcastFinish,
    finishModal,
    handleCommunicationErrorModalOpen,
  ])

  useEffect(() => {
    if (interviewStatus === 'started') {
      setStatus(Status.STARTED)
      ;(async () => {
        const res = await getPresenterInterview({ interviewGuid })
        const startTime = res.getStartedAt()?.toDate()
        setStartTime(startTime)
      })()
    } else if (interviewStatus === 'finished') {
      setStatus(Status.FINISHED)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interviewStatus])

  const [chatParams, setChatParams] = useState<InterviewChatWebSocketParams>()
  const [chatMessages, setChatMessages] = useState<Message[]>([])
  const [unreadMessagesCnt, setUnreadMessagesCnt] = useState(0)

  const onAddMessage = useCallback(
    (message: Message) => {
      !showChat && setUnreadMessagesCnt((prev) => ++prev)
      setChatMessages((prev) => [...prev, message])
    },
    [showChat]
  )

  const ownParticipant = useOwnParticipant()

  useEffect(() => {
    ;(async () => {
      if (interviewStatus !== 'started' || !ownParticipant?.entryNumber) return
      const res = await listChatMessagesForInterview(interviewGuid)
      const chatMessages: Message[] = res.getMessagesList().map((message) => {
        const { participantType, entryNumber } = parseUserGuid(
          message.getUserGuid()
        )
        return {
          type: participantType,
          name: entryNumber,
          text: message.getText(),
        }
      })
      setChatMessages(chatMessages)
      setChatParams({
        chatRoomGuid: getPresenterInterviewRes.getChatRoomGuid(),
        participantType: 'interviewer',
        entryNumber: ownParticipant.entryNumber.toString(),
        onAddMessage,
      })
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interviewStatus, ownParticipant?.entryNumber])

  useEffect(() => {
    setChatParams((prev) => (prev ? { ...prev, onAddMessage } : prev))
    if (showChat) setUnreadMessagesCnt(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showChat])

  const changeInterviewQuality = useCallback(
    async (mode: QualityMode) => {
      await _changeInterviewQuality({ interviewGuid, mode })
    },
    [_changeInterviewQuality, interviewGuid]
  )
  const isConnected = userPublisherStatus.status === 'Completed'

  return {
    interviewConfig,
    status,
    isConnected,
    handleStart,
    handleFinish,
    showUsers,
    toggleShowUsers,
    showChat,
    toggleShowChat,
    finishModal,
    startScreenShareModal,
    finishScreenShareModal,
    screenSharingLimitModal,
    handleScreenShareStart,
    handleScreenShareFinish,
    startTime,
    chatParams,
    chatMessages,
    unreadMessagesCnt,
    quality,
    changeInterviewQuality,
  }
}

export const RoomContainer = createContainer(useRoom)
