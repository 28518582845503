import type { ContractPeriod } from '@/containers/OrganizerServiceContainer'

export class Quota {
  static infinity = 1_000_000_000
  static remainingCountBorder = 5

  private _quota: number
  private _doneCount: number

  constructor(contractPeriod: ContractPeriod) {
    this._quota = contractPeriod.quota
    this._doneCount = contractPeriod.doneCount
  }

  /**
   * 上限が無制限であるか否かを返す
   */
  get isUnlimited(): boolean {
    return this._quota >= Quota.infinity
  }

  /**
   * 残りの実施可能回数を返す
   */
  get remainingCount(): number {
    return this._quota - this._doneCount
  }

  /**
   * 利用上限回数を返す
   */
  get quota(): number {
    return this._quota
  }

  /**
   * 契約期間内の実施回数を返す
   */
  get doneCount(): number {
    return this._doneCount
  }

  /**
   * 利用上限に達したかどうかを返す
   */
  get isExhausted(): boolean {
    return this.remainingCount <= 0
  }

  /**
   * 利用上限を「超過している」場合に true を返す
   */
  get exceeded(): boolean {
    return this.remainingCount < 0
  }

  /**
   * このプランが面接/説明会を実施することのできるプランなのか否かを返す
   */
  get isAvailablePlan(): boolean {
    return this._quota > 0
  }

  /**
   * 残りの実施可能回数が残りわずかか否かを返す
   */
  get isRemainingCountFew(): boolean {
    return this.remainingCount <= Quota.remainingCountBorder
  }

  /**
   * `123 / 4567` のような文字列形式で `実施回数 / 上限回数` を返す
   * 上限が無制限の場合は実施回数のみを返し、区切り線は省略される
   */
  formatFraction(): string {
    if (this.isUnlimited) {
      return this._doneCount.toLocaleString()
    }
    return `${this._doneCount.toLocaleString()} / ${this._quota.toLocaleString()}`
  }

  /**
   * 残りの実施可能回数をフォーマット（カンマを3桁ごとに挿入）した文字列を返す
   * 負の場合（実施回数が実施可能回数を上回っている場合）は正に変換した値を返す
   */
  formatRemainingCount(): string {
    return Math.abs(this.remainingCount).toLocaleString()
  }
}
