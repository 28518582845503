import React from 'react'
import styled from 'styled-components'
import { useTitle } from '@/hooks/useTitle'
import { Layout } from '@/components/Layout'
import { H1 } from '@/components/Heading'
import { EXTERNAL_URLS } from '@/services/urlService'
import { theme, NewTabLink, Txt } from '@blue-agency/rogue'
import { cacheKey } from '@/services/bffService'
import { useCachedPromise } from '@blue-agency/front-state-management'
import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'
import { useParams } from 'react-router-dom'

export const AlreadyAppliedPage: React.FCX = ({ className }) => {
  const orgService = OrganizerServiceContainer.useContainer()
  const { promotionGuid } = useParams<{ promotionGuid?: string }>()
  if (!promotionGuid) throw new Error('promotionGuid not found')
  const promotion = useCachedPromise(
    cacheKey.getPromotionForRikunabiPackage({ promotionGuid }),
    () => orgService.getPromotionForRikunabiPackage({ promotionGuid })
  )
  useTitle(`「${promotion.planName}」新規申込`)
  return (
    <Layout
      className={className}
      notFixHeight
      backgroundColor={theme.color.white[1]}
    >
      <HeadingWrapper>
        <H1>「{promotion.planName}」新規申込</H1>
      </HeadingWrapper>
      <Container>
        <Subject>既に登録が完了しています。</Subject>
        <Txt>
          「申込完了メール」をご確認ください。
          <br />
          ご不明点がございましたら、
          <NewTabLink href={EXTERNAL_URLS.contact} action="open_contact_page">
            こちら
          </NewTabLink>
          までお問い合わせください。
        </Txt>
      </Container>
    </Layout>
  )
}

const HeadingWrapper = styled.div`
  height: 60px;
  padding: 0 100px;
  display: flex;
  align-items: center;
`

const Container = styled.div`
  max-width: 502px;
  margin: 44px auto 0;
`

const Subject = styled(Txt).attrs({ size: 'l' })`
  margin-bottom: 14px;
  text-align: center;
`
