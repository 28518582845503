import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'
import { BulkActionContainer } from '@/pages/OrganizerRoutes/MyRoutes/InterviewsRoutes/InterviewsPage/hooks/useBulkAction'
import { useState, useCallback, useEffect, useMemo } from 'react'
import { StatusCode } from 'grpc-web'
import { InterviewsContainer } from '@/pages/OrganizerRoutes/MyRoutes/InterviewsRoutes/InterviewsPage/hooks/useInterviews'
import { toast } from '@blue-agency/rogue'
import { NotPermittedModalContainer } from '@/containers/NotPermittedModalContainer'

type ErrorType = 'default' | 'select_running_interview'

export const useBulkDeleteInterviewsModal = (
  active: boolean,
  onComplete: () => void
) => {
  const { selectedInterviews, reloadInterviews } =
    InterviewsContainer.useContainer()
  const notPermittedModal = NotPermittedModalContainer.useContainer()
  const { deleteInterviews } = OrganizerServiceContainer.useContainer()
  const { count: totalCount } = BulkActionContainer.useContainer()

  const [isAgreed, setIsAgreed] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<ErrorType | undefined>(undefined)

  const isRunningInterviewSelected = useMemo(() => {
    return selectedInterviews.some(
      (interview) => interview.startedAt && interview.finishedAt === undefined
    )
  }, [selectedInterviews])

  const toggleAgreement = useCallback(() => {
    setIsAgreed((prev) => !prev)
  }, [])

  const handleDeleteInterviewsClick = useCallback(async () => {
    setIsLoading(true)

    try {
      await deleteInterviews(
        selectedInterviews.map((interview) => interview.guid)
      )
    } catch (e) {
      switch (e.code) {
        case StatusCode.PERMISSION_DENIED:
          notPermittedModal.open('面接を削除')
          setIsLoading(false)
          return
        case StatusCode.FAILED_PRECONDITION:
          setError('select_running_interview')
          setIsLoading(false)
          return
        default:
          setError('default')
          setIsLoading(false)
          throw e
      }
    }
    onComplete()
    setError(undefined)
    reloadInterviews()
    toast(`${totalCount}件の面接を削除しました`)
    setIsLoading(false)
  }, [
    deleteInterviews,
    onComplete,
    reloadInterviews,
    selectedInterviews,
    totalCount,
    notPermittedModal,
  ])

  useEffect(() => {
    setError(undefined)
    setIsAgreed(false)
  }, [active])

  return {
    selectedInterviews,
    totalCount,
    isRunningInterviewSelected,
    isAgreed,
    error,
    isLoading,
    toggleAgreement,
    handleDeleteInterviewsClick,
  }
}
