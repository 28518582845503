import React from 'react'
import styled from 'styled-components'
import { theme, Txt as _Txt } from '@blue-agency/rogue'

export const Message: React.FCX = ({ className }) => {
  return (
    <Wrapper className={className}>
      <Txt>
        開始待ちです
        <br />
        このままお待ちください
      </Txt>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 360px;
  padding: 20px;
  background: ${theme.color.white[1]}D9;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Txt = styled(_Txt)`
  text-align: center;
`
