import React from 'react'
import styled from 'styled-components'
import {
  Modal,
  Txt,
  TertiaryButton,
  SecondaryDangerButton,
  WeakColorSeparator,
} from '@blue-agency/rogue'
import { RecordingContainer } from './hooks/RecordingContainer'

export const FinishModal: React.FCX = ({ className }) => {
  const { finishModal, handleFinish } = RecordingContainer.useContainer()

  return (
    <Modal
      className={className}
      active={finishModal.active}
      title="撮影の終了"
      onClose={finishModal.close}
      size="s"
    >
      <Content>
        <Txt>
          撮影を終了します。
          <br />
          よろしいですか？
        </Txt>
      </Content>
      <WeakColorSeparator />
      <ButtonGroup>
        <CancelButton
          text="キャンセル"
          onClick={finishModal.close}
          comlinkPushParams={{ action: 'close_presentation_finish_modal' }}
        />
        <FinishButton
          text="終了"
          onClick={handleFinish}
          comlinkPushParams={{ action: 'finish_presentation' }}
        />
      </ButtonGroup>
    </Modal>
  )
}

const Content = styled.div`
  padding: 20px 16px;
`
const ButtonGroup = styled.div`
  padding: 20px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const CancelButton = styled(TertiaryButton)`
  ${ButtonGroup} & {
    width: 48%;
  }
`
const FinishButton = styled(SecondaryDangerButton)`
  ${ButtonGroup} & {
    width: 48%;
  }
`
