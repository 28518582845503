import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { theme, Icon } from '@blue-agency/rogue'
import { NavLink as _NavLink } from 'react-router-dom'
import { INTERNAL_PATHS, fillParams } from '@/services/urlService'
import { Logo as _Logo } from '@/components/Logo'

type Props = {
  rightNode?: ReactNode
  leftNode?: ReactNode
  tokenForNavs?: string
  showInterviewsNav?: boolean
  showHallsNav?: boolean
  showPresentationsNav?: boolean
}

type HeaderMenu = {
  path: string
  label: string
  icon: React.ReactNode
}

export const headerHeight = 60

export const Header: React.FCX<Props> = ({
  tokenForNavs,
  rightNode,
  leftNode,
  className,
  showInterviewsNav = false,
  showHallsNav = false,
  showPresentationsNav = false,
}) => {
  const headerMenu: HeaderMenu[] = []

  if (showInterviewsNav) {
    headerMenu.push({
      path: INTERNAL_PATHS.organizer.my.interviews.index,
      label: 'Web面接',
      icon: <Icon name="interview" size="l" />,
    })
  }
  if (showHallsNav) {
    headerMenu.push({
      path: INTERNAL_PATHS.organizer.my.halls.index,
      label: 'Web説明会',
      icon: <Icon name="seminar" size="l" />,
    })
  }
  if (showPresentationsNav) {
    headerMenu.push({
      path: INTERNAL_PATHS.organizer.my.presentations.index,
      label: '説明会撮影',
      icon: <Icon name="movie" size="l" />,
    })
  }

  return (
    <Wrapper className={className}>
      <Left>
        <Logo />
        {leftNode && <Title>{leftNode}</Title>}
        {tokenForNavs &&
          headerMenu.map((menu) => (
            <MenuItem key={menu.label}>
              <MenuItemLink
                to={fillParams({
                  path: menu.path,
                  params: { token: tokenForNavs },
                })}
                activeClassName="selected"
              >
                {menu.icon}
                <MenuItemLinkTxt>{menu.label}</MenuItemLinkTxt>
              </MenuItemLink>
            </MenuItem>
          ))}
      </Left>
      {rightNode && <Right>{rightNode}</Right>}
    </Wrapper>
  )
}

const flex = css`
  display: flex;
  justify-content: center;
  align-items: center;
`
const Wrapper = styled.header`
  ${flex};
  justify-content: space-between;
  color: ${theme.color.navy[1]};
  font-size: ${theme.fontSize.s};
  width: 100%;
  height: ${headerHeight}px;
  box-sizing: border-box;
  background: ${theme.color.white[1]};
  border-bottom: 1px solid ${theme.color.gray[3]};
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.15);
`
const Left = styled.div`
  ${flex};
  ${Wrapper} & {
    height: 100%;
  }
`
const Logo = styled(_Logo)`
  ${Wrapper} & {
    margin-left: 25px;
  }
  width: 96px;
`
const MenuItem = styled.div`
  position: relative;
  cursor: pointer;
  ${Left} & {
    height: 100%;
    &:first-of-type {
      margin-left: 28px;
    }
  }
`
const NavLink = styled(_NavLink)`
  ${MenuItem} {
    margin-left: 28px;
  }
`
const MenuItemLink = styled(NavLink)`
  ${flex};
  padding: 4px 14px 0;
  border-bottom: 4px solid transparent;
  box-sizing: border-box;
  text-decoration: none;
  transition: all 166ms ease-in;
  color: ${theme.color.navy[1]};
  :active,
  :visited {
    color: ${theme.color.navy[1]};
  }
  &.selected,
  :hover {
    border-bottom: 4px solid ${theme.color.red.iM};
    color: ${theme.color.red.iM};
  }
  ${MenuItem} & {
    height: 100%;
  }
`
const MenuItemLinkTxt = styled.span`
  ${MenuItemLink} & {
    margin-left: 16px;
  }
`
const Right = styled.div`
  ${Wrapper} & {
    margin-left: auto;
    margin-right: 20px;
  }
`

const Title = styled.div`
  margin-left: 25px;
`
