import { useCallback } from 'react'
import { useSetRecoilState } from 'recoil'
import { interviewStatusState } from '@/lib/react-interview-sdk/states'
import { MeetingManagerContainer } from '@/lib/react-interview-sdk/containers/MeetingManagerContainer'

export const useLeaveInterview = () => {
  const { meetingManager } = MeetingManagerContainer.useContainer()
  const setStatus = useSetRecoilState(interviewStatusState)

  return useCallback(async () => {
    if (!meetingManager) return
    await meetingManager.leave()
    setStatus('notStarted')
  }, [meetingManager, setStatus])
}
