import { Item, OrganizerForm } from './types'
import { placeholder } from '@/constants/placeholder'
import { Plan } from '@blue-agency/proton/web/v2/yashiori_bff/plan_data_pb'
import { SignupAsOrganizerRequest } from '@blue-agency/proton/web/v2/yashiori_bff/yashiori_bff_service_pb'
const { EmployeesNumber, AnnualNewHiresNumber } = SignupAsOrganizerRequest

const employeesNumberOptions = [
  {
    label: '100名以下',
    value: EmployeesNumber.EMPLOYEES_NUMBER_1_TO_100.toString(),
  },
  {
    label: '101~500名',
    value: EmployeesNumber.EMPLOYEES_NUMBER_101_TO_500.toString(),
  },
  {
    label: '501~1,000名',
    value: EmployeesNumber.EMPLOYEES_NUMBER_501_TO_1000.toString(),
  },
  {
    label: '1,001~3,000名',
    value: EmployeesNumber.EMPLOYEES_NUMBER_1001_TO_3000.toString(),
  },
  {
    label: '3,001名以上',
    value: EmployeesNumber.EMPLOYEES_NUMBER_3001_TO_INF.toString(),
  },
]

const annualNewHiresNumberOptions = [
  {
    label: '1~10名',
    value: AnnualNewHiresNumber.ANNUAL_NEW_HIRES_NUMBER_1_TO_10.toString(),
  },
  {
    label: '11~20名',
    value: AnnualNewHiresNumber.ANNUAL_NEW_HIRES_NUMBER_11_TO_20.toString(),
  },
  {
    label: '21~50名',
    value: AnnualNewHiresNumber.ANNUAL_NEW_HIRES_NUMBER_21_TO_50.toString(),
  },
  {
    label: '51~100名',
    value: AnnualNewHiresNumber.ANNUAL_NEW_HIRES_NUMBER_51_TO_100.toString(),
  },
  {
    label: '101名以上',
    value: AnnualNewHiresNumber.ANNUAL_NEW_HIRES_NUMBER_101_TO_INF.toString(),
  },
  {
    label: '予定なし',
    value: AnnualNewHiresNumber.ANNUAL_NEW_HIRES_NUMBER_NOT_PLANNED.toString(),
  },
]

export const basicItems: Item[] = [
  {
    label: '会社名',
    name: 'name',
    placeholder: placeholder.orgName,
  },
  {
    label: '代表者名',
    name: 'representativeName',
    placeholder: placeholder.name,
  },
  {
    label: '電話番号',
    name: 'phoneNumber',
    placeholder: placeholder.tel,
  },
  {
    label: '郵便番号',
    name: 'postCode',
    placeholder: placeholder.postCode,
  },
  {
    label: '住所',
    name: 'address',
    placeholder: placeholder.address,
  },
  {
    label: '従業員数',
    name: 'employeesNumber',
    placeholder: placeholder.select,
    options: employeesNumberOptions,
  },
]
export const adminItems: Item[] = [
  {
    label: '氏名',
    name: 'adminName',
    placeholder: placeholder.name,
  },
  {
    label: '氏名（かな）',
    name: 'adminNameKana',
    placeholder: placeholder.nameKana,
  },
  {
    label: '部署名',
    name: 'adminDepartment',
    placeholder: placeholder.department,
  },
  {
    label: '電話番号',
    name: 'adminPhoneNumber',
    placeholder: placeholder.tel,
  },
  {
    label: 'メールアドレス',
    name: 'adminEmail',
    placeholder: placeholder.email,
  },
]

export const billingItems: Item[] = [
  {
    label: '氏名',
    name: 'billingName',
    placeholder: placeholder.name,
  },
  {
    label: '氏名（かな）',
    name: 'billingNameKana',
    placeholder: placeholder.nameKana,
  },
  {
    label: '部署名',
    name: 'billingDepartment',
    placeholder: placeholder.department,
  },
  {
    label: '電話番号',
    name: 'billingPhoneNumber',
    placeholder: placeholder.tel,
  },
  {
    label: '郵便番号',
    name: 'billingPostCode',
    placeholder: placeholder.postCode,
  },
  {
    label: '住所',
    name: 'billingAddress',
    placeholder: placeholder.address,
  },
  {
    label: 'メールアドレス',
    name: 'billingEmail',
    placeholder: placeholder.email,
  },
]

export const newHiresNumberItems: Item[] = [
  {
    label: '新卒採用予定人数\n(年間)',
    name: 'newGraduate',
    placeholder: placeholder.select,
    options: annualNewHiresNumberOptions,
  },
  {
    label: '中途採用予定人数\n(年間)',
    name: 'midCareer',
    placeholder: placeholder.select,
    options: annualNewHiresNumberOptions,
  },
  {
    label: '派遣スタッフ\n採用予定人数(年間)',
    name: 'temporary',
    placeholder: placeholder.select,
    options: annualNewHiresNumberOptions,
  },
  {
    label: 'アルバイトスタッフ\n採用予定人数(年間)',
    name: 'parttime',
    placeholder: placeholder.select,
    options: annualNewHiresNumberOptions,
  },
]

export const planOptions = [
  { label: 'ベーシックプラン', value: Plan.Id.BASIC.toString() },
]

export const billToOptions = [
  { label: '契約者/管理者情報と同じ', value: 'same' },
  { label: '別に指定', value: 'other' },
]

export const getInputtedTxt = (form: OrganizerForm, item: Item) => {
  if (!item.options) return form[item.name]
  const option = item.options.find((option) => option.value === form[item.name])
  return option?.label || ''
}
