import React from 'react'
import styled from 'styled-components'
import { InputText as _InputText, RequireTxt, Txt } from '@blue-agency/rogue'
import { UseFormMethods } from 'react-hook-form'
import { SignupAsOrganizerForm } from '@/components/OrganizerForm/types'
import { SignupContainer } from '../../hooks/useSignup'
import { ThousandSeparatedNum } from '@/components/ThousandSeparatedNum'

type Props = {
  formContext: UseFormMethods<SignupAsOrganizerForm>
}

export const SpInputBodyPlan: React.FCX<Props> = ({ formContext }) => {
  const { promotion } = SignupContainer.useContainer()
  return (
    <Block>
      <ItemGroup>
        <Item>
          <ItemTitle>申込内容</ItemTitle>
          <label>
            <ItemLabelTxt>利用プラン</ItemLabelTxt>
            <Required size="xs" />
            <Txt size="s">{promotion.plan.name}</Txt>
          </label>
        </Item>
        <Item>
          <label>
            <ItemLabelTxt>ご契約期間</ItemLabelTxt>
            <Required size="xs" />
            <Txt size="s">{promotion.planTerm}</Txt>
          </label>
        </Item>
        <Item>
          <label>
            <ItemLabelTxt>合計金額</ItemLabelTxt>
            <Required size="xs" />
            <TotalSumTxt size="s">
              <ThousandSeparatedNum number={promotion.billingAmount} />円
            </TotalSumTxt>
          </label>
        </Item>
        <Item>
          <label>
            <ItemLabelTxt>ご紹介元</ItemLabelTxt>
            <Required size="xs" />
            <InputText
              name="referrer"
              size="max"
              placeholder="他の企業様からのご紹介の場合、入力してください"
              ref={formContext.register}
              onBlurError={formContext.errors.referrer?.message}
            />
          </label>
        </Item>
        <Item>
          <label>
            <ItemLabelTxt size="s">クーポンコード</ItemLabelTxt>
            <Required size="xs" />
            <InputText
              name="couponCode"
              size="max"
              placeholder="お持ちの場合は、入力してください"
              ref={formContext.register}
              onBlurError={formContext.errors.couponCode?.message}
            />
          </label>
        </Item>
      </ItemGroup>
    </Block>
  )
}

const Block = styled.div`
  &:nth-of-type(n + 2) {
    margin-top: 0;
  }
  padding: 20px 0;
`
const ItemGroup = styled.div``
const Item = styled.div<{ hide?: boolean }>`
  ${ItemGroup} &:nth-of-type(n + 2) {
    margin-top: 20px;
  }
  align-items: center;
`
const ItemTitle = styled(Txt).attrs({ size: 's', bold: true })`
  ${ItemGroup} & {
    margin-bottom: 8px;
  }
`
const ItemLabelTxt = styled(Txt).attrs({ size: 's', bold: true })`
  ${ItemGroup} & {
    margin-bottom: 8px;
  }
`
const Required = styled(RequireTxt)`
  width: 30px;
`
const InputText = styled(_InputText)``
const TotalSumTxt = styled(Txt)``
