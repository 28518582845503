import {
  createContainer,
  useCachedPromise,
} from '@blue-agency/front-state-management'
import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'
import { cacheKey } from '@/services/bffService'
import { useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { INTERNAL_PATHS, fillParams } from '@/services/urlService'

const useOrganizer = () => {
  const { getOrganizer } = OrganizerServiceContainer.useContainer()
  const organizer = useCachedPromise(cacheKey.getOrganizer(), getOrganizer)

  const history = useHistory()
  const { token } = useParams<{ token?: string }>()
  if (!token) throw new Error('token not found')

  const toUpgradePlanPage = useCallback(() => {
    history.push(
      fillParams({
        path: INTERNAL_PATHS.organizer.my.upgradePlan,
        params: {
          token,
        },
      })
    )
  }, [history, token])

  return {
    organizer,
    toUpgradePlanPage,
    token,
  }
}

export const OrganizerContainer = createContainer(useOrganizer)
