import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { INTERNAL_PATHS } from '@/services/urlService'
import { RegisterEmailPage } from './RegisterEmailPage'
import { ApplyPage } from './ApplyPage'
import { AlreadyAppliedPage } from './AlreadyAppliedPage'
import { ExpiredPage } from './ExpiredPage'
import { NotFound } from '@/components/NotFound'
import { provide } from '@blue-agency/front-state-management'
import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'

export const RikunabiRoutes = provide(OrganizerServiceContainer)(() => {
  return (
    <Switch>
      <Route
        exact
        path={INTERNAL_PATHS.organizer.rikunabi.registerEmail}
        component={RegisterEmailPage}
      />
      <Route
        exact
        path={INTERNAL_PATHS.organizer.rikunabi.expired}
        component={ExpiredPage}
      />
      <Route
        exact
        path={INTERNAL_PATHS.organizer.rikunabi.alreadyApplied}
        component={AlreadyAppliedPage}
      />
      <Route
        exact
        path={INTERNAL_PATHS.organizer.rikunabi.apply}
        component={ApplyPage}
      />
      <Route path="*" component={NotFound} />
    </Switch>
  )
})
