import { atom, selector } from 'recoil'
import { MutedStreamIdMap } from '@/lib/react-interview-sdk/types'
import { mainVideoState } from './main_video'
import { namespace } from './namespace'
import { remoteVideoTileState } from './remote_video_tile'

export const isOwnMicMutedState = atom({
  key: `${namespace}/isOwnMicMutedState`,
  default: false,
})

export const mutedStreamIdMapState = atom<MutedStreamIdMap>({
  key: `${namespace}/mutedStreamIdMapState`,
  default: {},
})

export const mutedMainVideoAudioState = selector({
  key: `${namespace}/mutedMainVideoAudioState`,
  get: ({ get }) => {
    const mainVideo = get(mainVideoState)
    if (!mainVideo.mainVideoTileId) return
    const { tileIdToAttendeeId } = get(remoteVideoTileState)
    const mutedStreamIdMap = get(mutedStreamIdMapState)

    const attendeeId = tileIdToAttendeeId[mainVideo.mainVideoTileId]
    return mutedStreamIdMap[attendeeId]
  },
})
