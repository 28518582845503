import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'
import { EnterContainer } from '@/pages/OrganizerRoutes/MyRoutes/HallsRoutes/EntranceRoutes/RoomPage/containers/EnterContainer'
import { useEffect, useState } from 'react'

export const useParticipantCount = () => {
  const [hallJoinedCount, setHallJoinedCount] = useState(0)

  const { getHallJoinedCount } = OrganizerServiceContainer.useContainer()
  const { enterHallRes } = EnterContainer.useContainer()

  const updateHallJoinedCount = async () => {
    const res = await getHallJoinedCount(enterHallRes.getHallGuid())
    setHallJoinedCount(res.getJoinedCount())
  }

  useEffect(() => {
    updateHallJoinedCount()

    setInterval(async () => {
      updateHallJoinedCount()
    }, 5000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    hallJoinedCount,
  }
}
