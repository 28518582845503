import { BackgroundEffect } from '@blue-agency/interview-sdk-js'
import { atom, selector } from 'recoil'
import { recoilPersist } from 'recoil-persist'
import { namespace } from './namespace'
import {
  BgEffectCode,
  BG_EFFECT_CODE_NO_EFFECT,
  BG_EFFECT_CODE_BLUR_WEAK,
  BG_EFFECT_CODE_BLUR_STRONG,
} from '@/lib/react-interview-sdk/types'

const { persistAtom } = recoilPersist({ key: 'bgEffectCode' })

const BACKGROUND_BLUR_SIZE_MAP: Readonly<
  Record<Exclude<BgEffectCode, 'no-effect'>, number>
> = {
  'blur-weak': 41,
  'blur-strong': 81,
}

export const convertBackgroundEffect = (
  code: BgEffectCode
): BackgroundEffect => {
  switch (code) {
    case BG_EFFECT_CODE_NO_EFFECT:
      return { type: 'no-effect' }
    case BG_EFFECT_CODE_BLUR_WEAK:
    case BG_EFFECT_CODE_BLUR_STRONG:
      return {
        type: 'blur',
        blurSize: BACKGROUND_BLUR_SIZE_MAP[code],
      }
  }
}

export const bgEffectCodeState = atom<BgEffectCode>({
  key: `${namespace}/bgEffectCodeState`,
  default: BG_EFFECT_CODE_NO_EFFECT,
  effects: [persistAtom],
})

export const bgEffectState = selector({
  key: `${namespace}/bgEffectState`,
  get: ({ get }) => {
    const code = get(bgEffectCodeState)
    return convertBackgroundEffect(code)
  },
})
