import React from 'react'
import styled from 'styled-components'
import { Background as _Background } from '@/components/Background'
import { RegisterFormContent } from './RegisterFormContent'
import { SendEmailContent } from './SendEmailContent'
import { provide } from '@blue-agency/front-state-management'
import { WindowSizeContainer } from '@/containers/WindowSizeContainer'
import { NewParticipantContainer } from './containers/NewParticipantContainer'
import { overflowYScrollStyle } from '@/styles/overflowYScrollStyle'
import { NotFound } from '@/components/NotFound'
import { useTitle } from '@/hooks/useTitle'
import { CheckEntranceResponse } from '@blue-agency/proton/web/v2/yashiori_bff/yashiori_bff_service_pb'
import { Redirect, useParams } from 'react-router-dom'
import { INTERNAL_PATHS, fillParams } from '@/services/urlService'

export const NewParticipantPage: React.FCX = provide(NewParticipantContainer)(
  ({ className }) => {
    const { status } = NewParticipantContainer.useContainer()
    const { entranceGuid } = useParams<{ entranceGuid?: string }>()
    if (!entranceGuid) throw new Error('entranceGuid not found')

    switch (status) {
      case CheckEntranceResponse.Status.FINISHED:
        return (
          <Redirect
            to={fillParams({
              path: INTERNAL_PATHS.participant.halls.entrance.alreadyFinished,
              params: { entranceGuid },
            })}
          />
        )
      case CheckEntranceResponse.Status.VALID:
        return <ValidComponent className={className} />
      default:
        return <NotFound />
    }
  }
)

const ValidComponent: React.FCX = ({ className }) => {
  const { isSendEmail, onSubmit, handleSubmit, organizerName, sessionName } =
    NewParticipantContainer.useContainer()

  const title = `${organizerName} ${sessionName} 参加受付`
  useTitle(title)

  const windowSize = WindowSizeContainer.useContainer()

  return (
    <Wrapper className={className} height={windowSize.height}>
      <Background showCopyRight />
      <ContentWrapper>
        {isSendEmail ? (
          <SendEmailContent />
        ) : (
          <RegisterFormContent onSubmit={handleSubmit(onSubmit)} />
        )}
      </ContentWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ height: number }>`
  height: ${({ height }) => height}px;
  ${overflowYScrollStyle};

  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
const Background = styled(_Background)`
  ${Wrapper} & {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
`
const ContentWrapper = styled.div`
  ${overflowYScrollStyle};

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`
