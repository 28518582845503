import React from 'react'
import { provide } from '@blue-agency/front-state-management'
import { Recording } from './Recording'
import { Redirect, useParams } from 'react-router-dom'
import { INTERNAL_PATHS, fillParams } from '@/services/urlService'
import { useTitle } from '@/hooks/useTitle'
import { RecordingContainer } from './hooks/RecordingContainer'
import { PresentationContainer } from '../hooks/PresentationContainer'

export const RecordingPage = provide(RecordingContainer)(() => {
  const { token, presentationGuid } = useParams<{
    token?: string
    presentationGuid?: string
  }>()
  if (!presentationGuid) throw new Error('presentationGuid not found')
  if (!token) throw new Error('token not found')

  const { getPresentationRes } = PresentationContainer.useContainer()

  useTitle('説明会撮影前ページ')

  if (getPresentationRes.getIsRecorded())
    return (
      <Redirect
        to={fillParams({
          path: INTERNAL_PATHS.organizer.my.presentations.presentation.recorded,
          params: { token, presentationGuid },
        })}
      />
    )
  return <Recording />
})
