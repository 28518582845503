import React from 'react'
import styled, { css } from 'styled-components'
import { WeakColorSeparator } from '@blue-agency/rogue'
import { PlanSection } from './PlanSection'
import { RikunabiSection } from './RikunabiSection'
import { ContractSection } from './ContractSection'
import { AdminSection } from './AdminSection'
import { AgreementSection } from './AgreementSection'
import { BillingSection } from './BillingSection'
import { NewHiresSection } from './NewHiresSection'
import { ApplyPageContainer } from '../../ApplyPageContainer'

export const Body: React.FCX = ({ className }) => {
  const { promotion } = ApplyPageContainer.useContainer()
  return (
    <Wrapper className={className}>
      <PlanSection />
      <WeakColorSeparator />
      <RikunabiSection />
      <WeakColorSeparator />
      <ContractSection />
      <WeakColorSeparator />
      <AdminSection />
      <WeakColorSeparator />
      <BillingSectionWrapper hide={!promotion.needsBilling}>
        <BillingSection />
        <WeakColorSeparator />
      </BillingSectionWrapper>
      <NewHiresSection />
      <WeakColorSeparator />
      <AgreementSection />
    </Wrapper>
  )
}

const Wrapper = styled.div``
const BillingSectionWrapper = styled.div<{ hide: boolean }>`
  ${({ hide }) =>
    hide &&
    css`
      display: none;
    `}
`
