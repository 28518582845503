import React from 'react'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import {
  theme,
  SecondaryButton,
  WeakColorSeparator,
  NewTabLink,
} from '@blue-agency/rogue'
import { RegisterContainer } from './hooks/useRegister'
export const Result: React.FCX = () => {
  const { interviews, handleDownloadInterviewsList } =
    RegisterContainer.useContainer()
  return (
    <Wrapper>
      <Separator />
      <DownloadButton
        text="CSVダウンロード"
        size="l2"
        onClick={handleDownloadInterviewsList}
        comlinkPushParams={{ action: 'download_interview_csv_for_kaburiku' }}
      />
      <Table hover>
        <TableHead>
          <TableRow>
            <Th property="id">ID</Th>
            <Th property="interviewerUrl">
              <span>interviewer</span>
            </Th>
            <Th property="intervieweeUrl">
              <span>interviewee</span>
            </Th>
          </TableRow>
        </TableHead>
        <TableBody>
          {interviews.map((interview) => (
            <TableRow key={interview.guid}>
              <Td property="id">{interview.guid}</Td>
              <Td property="interviewerUrl">
                <NewTabLink
                  href={interview.interviewerUrl}
                  action="open_interviewer_page_for_kaburiku"
                  hideIcon
                >
                  {interview.interviewerUrl}
                </NewTabLink>
              </Td>
              <Td property="intervieweeUrl">
                <NewTabLink
                  href={interview.intervieweeUrl}
                  action="open_interviewee_page"
                  hideIcon
                >
                  {interview.intervieweeUrl}
                </NewTabLink>
              </Td>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Wrapper>
  )
}

type Property = 'id' | 'interviewerUrl' | 'intervieweeUrl'

type TableCellProps = {
  property: Property
}

export type TableSize = 's' | 'm'

type Props = {
  size?: TableSize
  hover?: boolean
}

const StyledTableForKaburiku = styled.table<Props>(
  {
    background: theme.color.white[1],
    borderCollapse: 'collapse',
    tableLayout: 'fixed',
    'thead, tbody': {
      display: 'block',
    },
    th: {
      color: theme.color.black[1],
      background: theme.color.gray[4],
      fontSize: theme.fontSize.s,
      fontWeight: 'normal',
      textAlign: 'center',
      verticalAlign: 'middle',
      whiteSpace: 'nowrap',
      lineHeight: 1.5,
    },
    td: {
      fontSize: theme.fontSize.s,
      minHeight: 60,
      height: 60,
      padding: '0px 15px',
      borderBottom: `1px solid ${theme.color.gray[3]}`,
      verticalAlign: 'middle',
      lineHeight: 1.5,
      overflow: 'hidden',
    },
  },
  (props) => ({
    tr: {
      ':hover': {
        boxShadow: props.hover ? '0px 0px 8px rgba(0, 0, 0, 0.2)' : undefined,
      },
    },
    th: {
      height: props.size !== 's' ? 44 : 24,
    },
  })
)

export const TableBaseForKaburiku: React.FCX<Props> = ({
  children,
  hover,
  size = 'm',
  className,
}) => (
  <StyledTableForKaburiku hover={hover} size={size} className={className}>
    {children}
  </StyledTableForKaburiku>
)

const Wrapper = styled.div`
  text-align: center;
`

const Separator = styled(WeakColorSeparator)`
  margin: 20px 0;
`

const DownloadButton = styled(SecondaryButton)``

const Table = styled(TableBaseForKaburiku)`
  th:last-of-type {
    justify-content: flex-start;
  }
  margin-top: 20px;
  width: 100%;
`

const TableHead = styled.thead`
  position: sticky;
  top: 0px;
  z-index: 100;
`

const TableBody = styled.tbody``

const TableRow = styled.tr`
  display: flex;
  align-items: center;
  background: ${theme.color.white[1]};
`

const Th = styled.th<TableCellProps>`
  display: flex;
  align-items: center;
  padding: 0 16px;
  background: ${theme.color.gray[4]};
  ${({ property }) => CSS[property]}
`

const Td = styled.td<TableCellProps>`
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 70px;
  ${({ property }) => CSS[property]}
`

const CSS: Record<Property, FlattenSimpleInterpolation> = {
  id: css`
    width: 24%;
  `,
  interviewerUrl: css`
    width: 38%;
  `,
  intervieweeUrl: css`
    width: 38%;
  `,
}
