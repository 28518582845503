import React, { useCallback } from 'react'
import {
  Modal,
  TertiaryButton,
  Txt,
  WeakColorSeparator,
  Icon,
  theme,
} from '@blue-agency/rogue'
import styled from 'styled-components'
import { ReactComponent as BackgroundNoEffect } from './background-no-effect.svg'
import { ReactComponent as BackgroundBlurStrong } from './background-blur-strong.svg'
import { ReactComponent as BackgroundBlurWeak } from './background-blur-weak.svg'

import {
  BgEffectCode,
  BG_EFFECT_CODE_BLUR_STRONG,
  BG_EFFECT_CODE_BLUR_WEAK,
  BG_EFFECT_CODE_NO_EFFECT,
} from '@/services/interviewService/types/BgEffectCode'

export const BackgroundSettingModal: React.FC<{
  active: boolean
  videoRef:
    | ((el: HTMLVideoElement | null) => void)
    | React.RefObject<HTMLVideoElement>
  bgEffectCode: BgEffectCode
  onClose: () => void
  onChangeBgEffectCode: (bgEffectCode: BgEffectCode) => void
}> = ({ active, videoRef, bgEffectCode, onClose, onChangeBgEffectCode }) => {
  const handleClickNoEffect = useCallback(() => {
    onChangeBgEffectCode(BG_EFFECT_CODE_NO_EFFECT)
  }, [onChangeBgEffectCode])
  const handleClickBlurWeak = useCallback(() => {
    onChangeBgEffectCode(BG_EFFECT_CODE_BLUR_WEAK)
  }, [onChangeBgEffectCode])
  const handleClickBlurStrong = useCallback(() => {
    onChangeBgEffectCode(BG_EFFECT_CODE_BLUR_STRONG)
  }, [onChangeBgEffectCode])
  return (
    <Modal title="背景設定" active={active} size="l" onClose={onClose}>
      <CautionGroup>
        <Caution>
          <LightBulb />
          <div>
            <Text>
              [ WindowsまたはMacのGoogle Chrome 最新版] のみ利用可能です。
              <br />
              その他のブラウザ、バージョンでは利用できませんので、ご注意ください。
            </Text>
            <TextWithMargin>
              背景の設定は端末に負荷がかかります。正常に動作しない場合は「オフ」を選択して下さい。
            </TextWithMargin>
          </div>
        </Caution>
      </CautionGroup>
      <PreviewContainer>
        <Preview ref={videoRef} />
      </PreviewContainer>
      <ButtonGroup>
        {bgEffectCode === undefined || bgEffectCode === 'no-effect' ? (
          <SelectedButton
            icon={<BackgroundNoEffect width="16px" height="16px" />}
            text="オフ"
            comlinkPushParams={{}}
          />
        ) : (
          <UnselectedtedButton
            icon={<BackgroundNoEffect width="16px" height="16px" />}
            text="オフ"
            onClick={handleClickNoEffect}
            comlinkPushParams={{ action: 'set_no_effect_on_background' }}
          />
        )}
        {bgEffectCode === 'blur-weak' ? (
          <SelectedButton
            icon={<BackgroundBlurWeak width="16px" height="16px" />}
            text="ぼかし弱"
            comlinkPushParams={{}}
          />
        ) : (
          <UnselectedtedButton
            icon={<BackgroundBlurWeak width="16px" height="16px" />}
            text="ぼかし弱"
            onClick={handleClickBlurWeak}
            comlinkPushParams={{ action: 'set_blur_weak_on_background' }}
          />
        )}
        {bgEffectCode === 'blur-strong' ? (
          <SelectedButton
            icon={<BackgroundBlurStrong width="16px" height="16px" />}
            text="ぼかし強"
            comlinkPushParams={{}}
          />
        ) : (
          <UnselectedtedButton
            icon={<BackgroundBlurStrong width="16px" height="16px" />}
            text="ぼかし強"
            onClick={handleClickBlurStrong}
            comlinkPushParams={{ action: 'set_blur_strong_on_background' }}
          />
        )}
      </ButtonGroup>
      <WeakColorSeparator />
      <CloseArea>
        <CloneButton
          text="閉じる"
          onClick={onClose}
          comlinkPushParams={{ action: 'close_background_setting_modal' }}
        />
      </CloseArea>
    </Modal>
  )
}

const SelectedButton = styled(TertiaryButton).attrs({ direction: 'column' })`
  border: 2px solid ${theme.color.navy[1]};
  width: 60px;
  height: 44px;
  padding: 2px;
  font-size: 10px;
  background-color: ${theme.color.gray[5]};
`

const UnselectedtedButton = styled(TertiaryButton).attrs({
  direction: 'column',
})`
  border: 0px;
  width: 60px;
  height: 44px;
  padding: 2px;
  font-size: 10px;
  background-color: ${theme.color.gray[5]};
`

const CautionGroup = styled.div`
  margin-top: 20px;
`

const Caution = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 10px;
  padding-bottom: 20px;
`

const PreviewContainer = styled.div`
  margin: 20px auto;
`
const Preview = styled.video`
  transform: scaleX(-1);
  width: 400px;
  height: 225px;
  border: 1px solid ${theme.color.gray[3]};
  border-radius: 4px;
`

const CloneButton = styled(TertiaryButton)`
  width: 166px;
  height: 44px;
`

const Text = styled(Txt).attrs({ size: 's' })`
  width: 520px;
  line-height: 24px;
`

const TextWithMargin = styled(Text)`
  margin-top: 10px;
`

const CloseArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`

const LightBulb = styled(Icon).attrs({ size: 'l', name: 'light-bulb' })`
  margin-right: 10px;
`
