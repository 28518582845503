import React from 'react'
import styled from 'styled-components'
import { Layout } from '@/components/Layout'
import { format } from 'date-fns'
import ja from 'date-fns/locale/ja'
import {
  Txt as _Txt,
  SecondaryButton,
  HelpLinkIcon,
  NewTabLink,
} from '@blue-agency/rogue'
import { H1, H3 as _H3 } from '@/components/Heading'
import { provide } from '@blue-agency/front-state-management'
import { ManualModalContainer } from './containers/ManualModalContainer'
import { ManualModal } from './ManualModal'
import reactStringReplace from 'react-string-replace'
import { EXTERNAL_URLS } from '@/services/urlService'

const text = `開催時刻まで、こちらの画面で
しばらくお待ちください。
推奨された端末やブラウザ以外では
正常に動作しない可能性がございます。
事前に推奨環境をご確認ください。

開催までにセミナー中の画面操作方法を
ご確認ください。`

type Props = {
  sessionName: string
  organizerName: string
  scheduledAt?: Date
}

export const Pending: React.FCX<Props> = provide(ManualModalContainer)(
  ({ sessionName, organizerName, scheduledAt, className }) => {
    const { showManualModal, handleManualModalOpen } =
      ManualModalContainer.useContainer()
    return (
      <Layout
        className={className}
        showFooter
        notFixHeight
        rightNode={
          <HelpLinkIcon
            href={EXTERNAL_URLS.help.participants.seminar}
            action="open_participant_seminar_help_page"
          />
        }
      >
        <Wrapper>
          <H1>{organizerName}</H1>
          <H3>{sessionName}</H3>
          {scheduledAt && (
            <ScheduledAtTxt>
              {format(scheduledAt, 'yyyy年M月d日(E) HH:mm', { locale: ja })}
            </ScheduledAtTxt>
          )}
          <Txt>
            {reactStringReplace(text, '推奨環境', (match, index) => (
              <NewTabLink
                key={index}
                href={EXTERNAL_URLS.recommendedEnvironment.participants.seminar}
                action="open_participant_seminar_recommended_environment_page"
              >
                {match}
              </NewTabLink>
            ))}
          </Txt>
          <ConfirmButton
            text="操作方法を確認する"
            onClick={handleManualModalOpen}
            comlinkPushParams={{ action: 'open_interview_manual_modal' }}
          />
        </Wrapper>
        <ManualModal active={showManualModal} />
      </Layout>
    )
  }
)

const Wrapper = styled.div`
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const H3 = styled(_H3)`
  ${Wrapper} & {
    margin-top: 5px;
  }
`
const Txt = styled(_Txt)`
  ${Wrapper} & {
    margin-top: 20px;
    text-align: center;
  }
`
const ConfirmButton = styled(SecondaryButton)`
  ${Wrapper} & {
    margin-top: 37px;
    width: 100%;
  }

  max-width: 330px;
`

const ScheduledAtTxt = styled(_Txt)`
  margin-top: 5px;
  text-align: center;
`
