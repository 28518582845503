import {
  createContainer,
  useCachedPromise,
  CacheContainer,
} from '@blue-agency/front-state-management'
import {
  OrganizerServiceContainer,
  ListInterviewsInterview,
} from '@/containers/OrganizerServiceContainer'
import { cacheKey } from '@/services/bffService'
import { useParams } from 'react-router-dom'
import { useCallback, useState, useMemo, useEffect, useReducer } from 'react'
import { isBefore } from 'date-fns'

const ITEMS_PER_PAGE = 20

export type InterviewListItem = ListInterviewsInterview & {
  active: boolean
  isSelected: boolean
}

const useInterviews = () => {
  const { token } = useParams<{ token?: string }>()
  if (!token) throw new Error('token not found')
  const { searchInterviews } = OrganizerServiceContainer.useContainer()
  const { deleteCache } = CacheContainer.useContainer()
  const [, forceUpdate] = useReducer((x) => x + 1, 0)
  const [page, setPage] = useState(1)
  const allInterviews = useCachedPromise(
    cacheKey.searchInterviews({ page: page.toString() }),
    () => searchInterviews({ args: { page } })
  )
  const [interviews, setInterviews] = useState<InterviewListItem[]>([])
  const count = useMemo(() => {
    return Math.ceil(allInterviews.totalLength / ITEMS_PER_PAGE)
  }, [allInterviews])

  const selectedInterviews = useMemo(() => {
    return interviews.filter((interview) => interview.isSelected)
  }, [interviews])

  useEffect(() => {
    const paginated = allInterviews.interviews
    const interviews = paginated.map<InterviewListItem>((interview) =>
      mapInterviewListItem(interview)
    )
    setInterviews(interviews)
  }, [page, allInterviews])

  const reloadInterviews = useCallback(() => {
    for (var i = 1; i <= count; i++) {
      deleteCache(cacheKey.searchInterviews({ page: i.toString() }))
    }
    deleteCache(cacheKey.getInterviewsCount())
    forceUpdate()
  }, [deleteCache, count])

  const selectInterview = useCallback((targetInterview: InterviewListItem) => {
    setInterviews((interviews) =>
      interviews.map((interview) => {
        if (interview.guid === targetInterview.guid) {
          return { ...interview, isSelected: true }
        }
        return interview
      })
    )
  }, [])

  const selectAllInterviews = useCallback(() => {
    setInterviews((interviews) =>
      interviews.map((interview) => {
        return { ...interview, isSelected: true }
      })
    )
  }, [])

  const unselectInterview = useCallback(
    (targetInterview: InterviewListItem) => {
      setInterviews((interviews) =>
        interviews.map((interview) => {
          if (interview.guid === targetInterview.guid) {
            return { ...interview, isSelected: false }
          }
          return interview
        })
      )
    },
    []
  )

  const unselectAllInterviews = useCallback(() => {
    setInterviews((interviews) =>
      interviews.map((interview) => {
        return { ...interview, isSelected: false }
      })
    )
  }, [])

  return {
    page,
    setPage,
    count,
    interviews,
    selectedInterviews,
    selectInterview,
    selectAllInterviews,
    unselectInterview,
    unselectAllInterviews,
    reloadInterviews,
  }
}

export const InterviewsContainer = createContainer(useInterviews)

const mapInterviewListItem = (
  interview: ListInterviewsInterview
): InterviewListItem => ({
  ...interview,
  active: isActive(interview),
  isSelected: false,
})

// NOTE: 条件は Jira を参考にする
//   https://blue-agency.atlassian.net/browse/YAS-419
function isActive(interview: ListInterviewsInterview) {
  if (interview.startedAt && !interview.finishedAt) {
    return true
  }

  const beforeExpiresAt = isBefore(Date.now(), interview.expiresAt)

  if (!interview.startedAt && beforeExpiresAt) {
    return true
  }

  return false
}
