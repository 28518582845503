import { useRecoilValue } from 'recoil'
import {
  interviewersCountState,
  intervieweesCountState,
  participantsCountState,
} from '@/lib/react-interview-sdk/states'

export const useParticipantsCount = () => {
  return useRecoilValue(participantsCountState)
}

export const useInterviewersCount = () => {
  return useRecoilValue(interviewersCountState)
}

export const useIntervieweesCount = () => {
  return useRecoilValue(intervieweesCountState)
}
