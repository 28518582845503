import { useCallback } from 'react'
import { useRecoilValue } from 'recoil'
import { selectedAudioOutputDeviceState } from '@/lib/react-interview-sdk/states'
import { AudioVideoContainer } from '@/lib/react-interview-sdk/containers/AudioVideoContainer'
import { MeetingManagerContainer } from '@/lib/react-interview-sdk/containers/MeetingManagerContainer'

export const useSetAudioOutputForVideoTile = () => {
  const { meetingManager } = MeetingManagerContainer.useContainer()
  const { audioVideo } = AudioVideoContainer.useContainer()
  const selectedAudioOutputDevice = useRecoilValue(
    selectedAudioOutputDeviceState
  )

  return useCallback(
    async (tileId: number) => {
      if (!meetingManager) return
      if (!audioVideo) return
      if (!selectedAudioOutputDevice) return
      return meetingManager.setAudioOutputForVideoTile(
        selectedAudioOutputDevice,
        tileId
      )
    },
    [audioVideo, meetingManager, selectedAudioOutputDevice]
  )
}
