import { useState, useCallback, useMemo, useRef } from 'react'
import { MenuItem } from '@/components/Dropdown'

export const useMenu = (presentationGUID: string) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const handleMoreIconClick = useCallback(() => {
    // TODO: handleOutsideClickでDropdownを閉じた後再度表示してしまうのを直す(SHRQ272)
    setShowDropdown(true)
  }, [])

  const handleOutsideClick = useCallback(() => {
    setShowDropdown(false)
  }, [])

  const items = useMemo<MenuItem[]>(
    () => [
      {
        text: `動画ID: ${presentationGUID}`,
        onClick: () => {},
        disabled: false,
        action: '',
      },
    ],
    [presentationGUID]
  )

  return {
    items,
    showDropdown,
    handleMoreIconClick,
    dropdownRef,
    handleOutsideClick,
  }
}
