export function injectZendeskChat() {
  const isOrganizerPage = window.location.pathname.match(/^\/organizer/)
  if (!isOrganizerPage) return
  const isInterviewPage = window.location.pathname.match(
    /\/interviews\/\w+\/room$/
  )
  if (isInterviewPage) return

  const script = document.createElement('script')
  script.id = 'ze-snippet'
  script.setAttribute(
    'src',
    'https://static.zdassets.com/ekr/snippet.js?key=3a29191a-4335-4324-9a46-9353c4a85713'
  )

  document.body.insertBefore(script, document.body.childNodes[0])
}
