import { createContainer } from '@blue-agency/front-state-management'
import { useParams } from 'react-router-dom'
import { useEffect, useCallback, useState, useRef } from 'react'
import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'
import { CommunicationErrorModalContainer } from '@/containers/CommunicationErrorModalContainer'
import { useSora } from './useSora'
import { useModal } from '@/hooks/useModal'
import { RecordingContainer } from '../RecordingContainer'

const checkStartedPollingIntervalSecond = 0.3

const useScreenShareVideo = () => {
  const { presentationGuid, token } = useParams<{
    presentationGuid?: string
    token?: string
  }>()
  if (!presentationGuid) throw new Error('presentationGuid not found')
  if (!token) throw new Error('token not found')

  const {
    startScreenSharingForPresentation,
    finishScreenSharingForPresentation,
    checkStartedPollingForScreenSharing,
  } = OrganizerServiceContainer.useContainer()

  const { screenSora } = RecordingContainer.useContainer()
  const { handleCommunicationErrorModalOpen } =
    CommunicationErrorModalContainer.useContainer()
  const [screenShareStream, setScreenShareStream] = useState<MediaStream>()
  const [isRecordingScreenSharing, setIsRecordingScreenSharing] =
    useState(false)
  const checkStartedPollingIdRef = useRef<NodeJS.Timeout>()

  const screenShareVideoRef = useCallback(
    (element: HTMLVideoElement | null) => {
      if (!element) return
      element.srcObject = screenShareStream || null
    },
    [screenShareStream]
  )

  const startScreenShareModal = useModal()
  const finishScreenShareModal = useModal()

  const { connect, disconnect } = useSora(presentationGuid)

  const startScreenShare = useCallback(async () => {
    if (!screenSora) return
    if (
      navigator.mediaDevices === undefined ||
      navigator.mediaDevices.getDisplayMedia === undefined
    ) {
      alert(
        '利用できるカメラが見つかりません、本体設定や端末を変えて再度お試しください。'
      )
      return
    }
    const screenShareStream: MediaStream | null = await navigator.mediaDevices
      .getDisplayMedia({
        video: true,
        audio: false,
      })
      .catch(() => null)
    if (!screenShareStream) return

    await connect({
      hostname: screenSora.hostname,
      channelId: screenSora.channelId,
      screenShareStream,
    }).catch((err) => {
      handleCommunicationErrorModalOpen()
      throw err
    })

    await startScreenSharingForPresentation(presentationGuid).catch((err) => {
      screenShareStream.getTracks().forEach((track) => track.stop())
      handleCommunicationErrorModalOpen()
      throw err
    })
    setScreenShareStream(screenShareStream)
    startScreenShareModal.close()

    // NOTE: 画面共有を開始してから実際に再生ファイルが生成されるまで数十秒のラグがあるので、ポーリングで本来の再生開始タイミングを取得する
    const timer = setInterval(async () => {
      const res = await checkStartedPollingForScreenSharing(presentationGuid)
      setIsRecordingScreenSharing(res.getIsStarted())
    }, checkStartedPollingIntervalSecond * 1000)

    checkStartedPollingIdRef.current = timer
  }, [
    presentationGuid,
    screenSora,
    connect,
    startScreenSharingForPresentation,
    handleCommunicationErrorModalOpen,
    startScreenShareModal,
    checkStartedPollingForScreenSharing,
  ])

  useEffect(() => {
    if (isRecordingScreenSharing) {
      checkStartedPollingIdRef.current &&
        clearInterval(checkStartedPollingIdRef.current)
    }
  }, [isRecordingScreenSharing])

  const stopScreenShare = useCallback(async () => {
    await finishScreenSharingForPresentation(presentationGuid).catch((err) => {
      handleCommunicationErrorModalOpen()
      throw err
    })
    await disconnect()
    setScreenShareStream(undefined)
    setIsRecordingScreenSharing(false)
    checkStartedPollingIdRef.current &&
      clearInterval(checkStartedPollingIdRef.current)
    finishScreenShareModal.close()
  }, [
    presentationGuid,
    disconnect,
    finishScreenSharingForPresentation,
    handleCommunicationErrorModalOpen,
    finishScreenShareModal,
  ])

  useEffect(() => {
    if (!screenSora || !screenShareStream) return
    ;(async () => {
      await stopScreenShare()
      // TODO: alertでの表示をやめて、モーダルで表示する
      alert('画面共有の接続が切れました。もう一度やり直してください。')
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenSora])

  useEffect(() => {
    if (!screenShareStream) return
    const [videoTrack] = screenShareStream.getVideoTracks()
    // MEMO: ブラウザ固有の共有停止ボタンで画面共有が停止された時にもstopScreenShareが呼ばれるようにしている
    videoTrack.addEventListener('ended', stopScreenShare)
    return () => {
      videoTrack.removeEventListener('ended', stopScreenShare)
    }
  }, [screenShareStream, stopScreenShare])

  return {
    screenShareVideoRef,
    startScreenShare,
    stopScreenShare,
    startScreenShareModal,
    finishScreenShareModal,
    screenSharing: !!screenShareStream,
    isRecordingScreenSharing,
  }
}

export const ScreenShareVideoContainer = createContainer(useScreenShareVideo)
