import React, { useState, useCallback } from 'react'
import styled, { css } from 'styled-components'
import {
  PrimaryButton,
  Modal as _Modal,
  Txt,
  theme,
  Icon,
  NewTabLink,
} from '@blue-agency/rogue'
import { EXTERNAL_URLS } from '@/services/urlService'
import { WindowSizeContainer } from '@/containers/WindowSizeContainer'
import spImage from '@/assets/sp.png'

type Props = {
  onClick: () => void
}
export const StartModal: React.FCX<Props> = ({ onClick, className }) => {
  const { isPortrait, ...windowSize } = WindowSizeContainer.useContainer()
  const [showManual, setShowManual] = useState(false)
  const toggleShowManual = useCallback(() => {
    setShowManual((prev) => !prev)
  }, [])

  return (
    <Modal
      className={className}
      windowSize={windowSize}
      active
      size={isPortrait ? 's' : 'm'}
      titleSize="s"
      title="説明会に参加"
    >
      <Wrapper>
        <Txt size="s">
          <NewTabLink
            href={EXTERNAL_URLS.participantTerms}
            action="open_participant_terms_page"
          >
            利用規約及びプライバシーポリシー
          </NewTabLink>
          に同意して
        </Txt>
        <ButtonWrapper>
          <StartButton
            text="Web説明会に参加する"
            onClick={onClick}
            size="l1"
            comlinkPushParams={{ action: 'join_seminar' }}
          />
        </ButtonWrapper>
        <ConfirmTxtWrapper onClick={toggleShowManual}>
          <ConfirmTxt size="s" color={theme.color.blue[2]}>
            操作方法を確認する
            <ArrowIcon name="arrow" size="s" down={showManual} />
          </ConfirmTxt>
        </ConfirmTxtWrapper>
        {showManual && (
          <ManualWrapper>
            <StepWrapper>
              <Step>1</Step>
            </StepWrapper>
            <MainTxt>スマートフォンは「横向き」がおすすめ</MainTxt>
            <IconWrapper>
              <SmartphoneIcon name="smartphone" />
            </IconWrapper>
            <StepWrapper>
              <Step>2</Step>
            </StepWrapper>
            <MainTxt>
              「質問コーナー」で
              <br />
              質問を受け付けています
            </MainTxt>
            <SubTxt size="s" color={theme.color.gray[1]}>
              ※企業には質問者の名前が表示されます。
              <br />
              ※参加者同士は名前が表示されません
            </SubTxt>
            <Image src={spImage} />
            <StepWrapper>
              <Step>3</Step>
            </StepWrapper>
            <MainTxt>
              ・映像/画面が見えない
              <br />
              ・音が聞こえない
              <br />
              などが発生した場合は
              <br />
              ブラウザを再読込してください
            </MainTxt>
            <IconWrapper>
              <ReloadIcon name="reload" />
            </IconWrapper>
            <FinishTxt>以上で説明は終了です。</FinishTxt>
          </ManualWrapper>
        )}
      </Wrapper>
    </Modal>
  )
}

const Modal = styled(_Modal)<{ windowSize: { width: number; height: number } }>`
  ${({ windowSize }) => css`
    max-height: ${windowSize.height - 60}px;
  `}
`
const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const ButtonWrapper = styled.div`
  ${Wrapper} & {
    margin-top: 6px;
  }
`
const StartButton = styled(PrimaryButton)`
  height: 40px;
`
const ConfirmTxtWrapper = styled.div``
const ConfirmTxt = styled(Txt)`
  ${Wrapper} & {
    margin-top: 28px;
  }
  cursor: pointer;
  display: inline-flex;
  align-items: center;
`
const ArrowIcon = styled(Icon)<{ down: boolean }>`
  ${ConfirmTxt} & {
    margin-left: 3px;
  }
  stroke: ${theme.color.navy[1]};
  stroke-width: 1.5px;
  ${({ down }) =>
    css`
      transform: rotate(${down ? 270 : 90}deg);
    `}
`
const ManualWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const StepWrapper = styled.div`
  ${ManualWrapper} & {
    margin-top: 30px;
  }
`
const Step = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: ${theme.color.navy[1]};
  color: ${theme.color.white[1]};
`
const MainTxt = styled(Txt)`
  ${ManualWrapper} & {
    margin-top: 5px;
  }
`
const SubTxt = styled(Txt)`
  ${ManualWrapper} & {
    margin-top: 7px;
    align-self: flex-start;
  }
`
const IconWrapper = styled.div`
  ${ManualWrapper} & {
    margin-top: 35px;
  }
`
const SmartphoneIcon = styled(Icon)`
  width: 100px;
  height: 70px;
`
const Image = styled.img`
  ${ManualWrapper} & {
    margin-top: 20px;
  }
  width: 300px;
`
const ReloadIcon = styled(Icon)`
  height: 105px;
  width: 105px;
`
const FinishTxt = styled(Txt)`
  ${ManualWrapper} & {
    margin-top: 30px;
    padding-bottom: 20px;
  }
`
