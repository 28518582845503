import React, { useMemo } from 'react'
import styled from 'styled-components'
import { theme } from '@blue-agency/rogue'
import { LocalVideo, RemoteVideo } from './InterviewVideo'

import { DeviceType } from '@/services/interviewService/types/DeviceType'

import { UnstableLevel } from '@/services/interviewService/types/UnstableLevel'
import { Participant } from '@/services/interviewService/types/Participant'
import { ParticipantType } from '@/services/interviewService/types/ParticipantType'

export type ParticipantTile = Participant & {
  tileId: number | null
  muted: boolean
}

type InterviewVideosProps = {
  className?: string
  width: number
  participantType: ParticipantType
  deviceType: DeviceType
  ownParticipant: ParticipantTile | undefined
  participants: ParticipantTile[]
  pinTile: (tileId: number) => void
  pinnedTileId: number | null
  unstableLevel: UnstableLevel
  isOwnCameraMuted: boolean
  isUnavailableVideoInput: boolean
  bindVideoElement: (tileId: number, el: HTMLVideoElement) => () => void
  setAudioOutputForVideoTile: (tileId: number) => Promise<void>
}

export const InterviewVideos: React.FC<InterviewVideosProps> = ({
  className,
  width,
  participantType,
  deviceType,
  ownParticipant,
  participants,
  pinTile,
  pinnedTileId,
  unstableLevel,
  isOwnCameraMuted,
  isUnavailableVideoInput,
  bindVideoElement,
  setAudioOutputForVideoTile,
}) => {
  const needLocalVideo =
    ownParticipant &&
    ((ownParticipant.role === 'recruiter' &&
      participantType === 'interviewer') ||
      (ownParticipant.role === 'applicant' &&
        participantType === 'interviewee'))

  const remoteParticipants = useMemo(() => {
    if (!ownParticipant) return participants
    return participants.filter(
      (i) => i.soraClientId !== ownParticipant?.soraClientId
    )
  }, [ownParticipant, participants])

  return (
    <Wrapper className={className} width={width}>
      {0 < participants.length && (
        <TopBar
          isInterviewer={participantType === 'interviewer'}
          deviceType={deviceType}
        />
      )}
      <VideoGroup>
        {needLocalVideo && ownParticipant && (
          <LocalVideo
            width={width / 2}
            participantType={participantType}
            participant={ownParticipant}
            tileId={ownParticipant.tileId}
            showMute={ownParticipant.muted}
            pinTile={pinTile}
            isMainVideo={pinnedTileId === ownParticipant.tileId}
            unstableLevel={unstableLevel}
            isOwnCameraMuted={isOwnCameraMuted}
            isUnavailableVideoInput={isUnavailableVideoInput}
            bindVideoElement={bindVideoElement}
            setAudioOutputForVideoTile={setAudioOutputForVideoTile}
          />
        )}
        {remoteParticipants.map((participant) => {
          const isMainVideo = pinnedTileId === participant.tileId
          return (
            <RemoteVideo
              key={participant.soraClientId}
              width={width / 2}
              participantType={participantType}
              participant={participant}
              tileId={participant.tileId}
              pinTile={pinTile}
              showMute={participant.muted}
              isMainVideo={isMainVideo}
              bindVideoElement={bindVideoElement}
              setAudioOutputForVideoTile={setAudioOutputForVideoTile}
            />
          )
        })}
      </VideoGroup>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ width: number }>`
  position: relative;
  z-index: 0;
  width: ${({ width }) => width}px;
`
const TopBar = styled.div<{ isInterviewer?: boolean; deviceType: DeviceType }>`
  ${Wrapper} & {
    position: absolute;
    z-index: 1;
    top: 0;
    width: 100%;
  }
  height: ${({ deviceType }) => (deviceType === 'pc' ? 4 : 2)}px;
  background: ${({ isInterviewer }) =>
    isInterviewer ? theme.color.green[4] : theme.color.red.iM};
`
const VideoGroup = styled.div`
  ${Wrapper} & {
    width: 100%;
  }
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`
