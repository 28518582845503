import cameraOffImg from './camera_off.png'

interface CanvasElement extends HTMLCanvasElement {
  captureStream(frameRate: number): MediaStream
}

export const getCameraOffTrackForPresenter = () => {
  const c = document.createElement('canvas') as CanvasElement
  c.width = 2132
  c.height = 1200
  const ctx = c.getContext('2d')
  if (!ctx) throw new Error('ctx not found')
  const img = new Image()
  img.src = cameraOffImg
  img.onload = () => {
    ctx.drawImage(img, 0, 0)
  }

  const cs = c.captureStream(30)
  const videoTrack = cs.getTracks()[0]
  return videoTrack
}

export const getCameraOffTrackForParticipants = () => {
  const c = document.createElement('canvas') as CanvasElement
  c.width = 2132
  c.height = 1200
  const ctx = c.getContext('2d')
  if (!ctx) throw new Error('ctx not found')
  const img = new Image()
  img.src = cameraOffImg
  img.onload = () => {
    ctx.drawImage(img, 0, 0)
  }

  // NOTE: 完全な静止画だとvideo trackが流れないので1x1のダミーのデータを描画している
  let dummyPixelColorFlag = true
  setInterval(() => {
    ctx.fillStyle = dummyPixelColorFlag ? '#000' : '#001'
    ctx.fillRect(0, 0, 1, 1)
    dummyPixelColorFlag = !dummyPixelColorFlag
  }, 1000)

  const cs = c.captureStream(30)
  const videoTrack = cs.getTracks()[0]
  return videoTrack
}
