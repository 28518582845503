import React, { useMemo } from 'react'
import { HallLayout } from '@/components/HallLayout'
import { Presenter } from '@/components/Presenter'
import { ScreenShare } from '@/components/ScreenShare'
import { Messages } from './Messages'
import { useBeforeUnload } from '@blue-agency/react-utils'
import { provide } from '@blue-agency/front-state-management'
import { PresenterVideoContainer } from './containers/PresenterVideoContainer'
import { ScreenShareVideoContainer } from './containers/ScreenShareVideoContainer'
import { MessagesContainer } from './containers/MessagesContainer'
import { EnterContainer } from './containers/EnterContainer'
import { WindowSizeContainer } from '@/containers/WindowSizeContainer'
import { Layout } from '@/components/Layout'
import { headerHeight } from '@/components/Header'
import { Right } from './Right'
import { BriefingControls } from './BriefingControls'
import { LeftNode } from './LeftNode'
import { ErrorModal } from './ErrorModal'
import { BeforeStartModal } from './BeforeStartModal'
import { AlertboxHeightContainer } from '@/containers/AlertboxHeightContainer'
import { ConnectionFailedModal } from './ConnectionFailedModal'
import { ParticipantsCount } from '@/pages/OrganizerRoutes/MyRoutes/HallsRoutes/EntranceRoutes/RoomPage/ParticipantsCount'

export const Room: React.FCX = provide(
  MessagesContainer,
  PresenterVideoContainer,
  ScreenShareVideoContainer
)(({ className }) => {
  const {
    showErrorModal,
    showBeforeStartModal,
    handleFinishModalOpen,
    enterHallRes,
  } = EnterContainer.useContainer()
  const {
    presenterVideoRef,
    isCameraOff,
    isConnectionFailed: isPresenterConnectionFailed,
    muted,
    toggleMuted,
    toggleCameraOff,
  } = PresenterVideoContainer.useContainer()
  const {
    screenShareVideoRef,
    screenSharing,
    isConnectionFailed: isScreenShareConnectionFailed,
  } = ScreenShareVideoContainer.useContainer()
  const { height, width } = WindowSizeContainer.useContainer()
  const alertboxHeight = AlertboxHeightContainer.useContainer()
  const hallHeight = useMemo(
    () => height - headerHeight - alertboxHeight,
    [height, alertboxHeight]
  )
  useBeforeUnload('ページを離れます。よろしいですか？')

  return (
    <>
      <Layout
        className={className}
        rightNode={<Right />}
        leftNode={<LeftNode sessionName={enterHallRes.getSessionName()} />}
      >
        <HallLayout
          className={className}
          height={hallHeight}
          width={width}
          presenterVideo={
            <Presenter ref={presenterVideoRef} mirrorEffect={!isCameraOff} />
          }
          screenShareVideo={<ScreenShare ref={screenShareVideoRef} />}
          messages={<Messages />}
          participantsCount={<ParticipantsCount />}
          screenSharing={screenSharing}
          briefingControls={
            <BriefingControls
              muted={muted}
              isCameraOff={isCameraOff}
              toggleMuted={toggleMuted}
              toggleCameraOff={toggleCameraOff}
              handleFinishModalOpen={handleFinishModalOpen}
            />
          }
        />
      </Layout>
      <ErrorModal active={showErrorModal} />
      {(isScreenShareConnectionFailed || isPresenterConnectionFailed) && (
        <ConnectionFailedModal />
      )}
      <BeforeStartModal active={showBeforeStartModal} />
    </>
  )
})
