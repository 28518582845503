import React from 'react'
import styled from 'styled-components'
import { Txt } from '@blue-agency/rogue'

export const EconomyModeTxt: React.VFC<{
  className?: string
}> = ({ className }) => {
  return (
    <Wrapper className={className}>
      <Txt size="s">軽量モード利用中</Txt>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 2px 10px;
  background-color: rgba(255, 255, 255, 0.7);
  filter: blur();
`
