import React, { useState, useCallback, ComponentProps } from 'react'
import styled, { css } from 'styled-components'
import { EXTERNAL_URLS } from '@/services/urlService'
import { theme, Icon, Txt, Button, NewTabLink } from '@blue-agency/rogue'
import { DropdownMenu as _DropdownMenu } from './DropdownMenu'
import { buttonResetStyle } from '@/styles/buttonResetStyle'
import { Banner } from './Banner'

type Props = {
  orgName: string
  customerCode: string
  helpUrl: string
} & ComponentProps<typeof Banner>

export const OrganizerHeaderRight: React.FCX<Props> = ({
  className,
  orgName,
  customerCode,
  banner,
  helpUrl,
  expiresAt,
  expired,
}) => {
  const [showMenu, setShowMenu] = useState(false)
  const handleClick = useCallback(() => {
    setShowMenu((prev) => !prev)
  }, [])
  return (
    <Wrapper className={className}>
      <Banner banner={banner} expiresAt={expiresAt} expired={expired} />
      <Link
        href={EXTERNAL_URLS.supportPolicy}
        hideIcon
        action="open_support_policy_page"
      >
        <InformationIcon name="information" />
        <LinkTxt size="s">お問い合わせ</LinkTxt>
      </Link>
      <Link href={helpUrl} hideIcon action="open_organizer_help_page">
        <QuestionIcon name="question" />
        <LinkTxt size="s">ヘルプ</LinkTxt>
      </Link>
      <Separator />
      <TxtWrapper>
        <OrgNameTxt size="s">{orgName}</OrgNameTxt>
        <CustomerCodeTxt
          size="s"
          color={theme.color.gray[2]}
        >{`お客様番号: ${customerCode}`}</CustomerCodeTxt>
      </TxtWrapper>
      <DropdownButton
        onClick={handleClick}
        comlinkPushParams={{ action: 'show_dropdown_on_organizer_header' }}
      >
        <SettingsIcon name="settings" />
      </DropdownButton>
      <DropdownMenu orgName={orgName} show={showMenu} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`
const Link = styled(NewTabLink)`
  ${Wrapper} & {
    margin-left: 20px;
  }
`
const LinkTxt = styled(Txt)`
  ${Link} & {
    margin-left: 4px;
  }
`
const iconStyle = css`
  width: 22px;
  height: 22px;
  color: ${theme.color.navy[1]};
`
const QuestionIcon = styled(Icon)`
  ${iconStyle}
`
const InformationIcon = styled(Icon)`
  ${iconStyle}
`
const Separator = styled.div`
  ${Wrapper} & {
    margin-left: 27px;
  }
  width: 24px;
  height: 1px;
  background: ${theme.color.navy[2]};
  transform: rotate(90deg);
`
const DropdownButton = styled(Button)`
  ${Wrapper} & {
    margin-left: 13px;
  }
  ${buttonResetStyle};
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const TxtWrapper = styled.div`
  ${Wrapper} & {
    margin-left: 20px;
  }
  max-width: 130px;
  text-align: left;
`
const OrgNameTxt = styled(Txt)`
  overflow: hidden;
  text-overflow: ellipsis;
  /* MEMO: emotionにより自動付加されるclass名の長さ？によってTxtで設定したstyleに
  優先順位で勝ったり(ステージング)負けたり(本番)している疑惑があるので
  一時的にimportantで回避している */
  white-space: nowrap !important;
`
const CustomerCodeTxt = styled(Txt)``
const SettingsIcon = styled(Icon)`
  width: 20px;
  height: 20px;
`
const DropdownMenu = styled(_DropdownMenu)<{ show: boolean }>`
  ${Wrapper} & {
    position: absolute;
    top: 48px;
    right: -5px;
  }
  ${({ show }) =>
    !show &&
    css`
      display: none;
    `}
`
