import React from 'react'
import {
  Modal,
  WeakColorSeparator,
  Txt,
  TertiaryButton,
  PrimaryButton,
  Dropdown,
  ErrorMessage as _ErrorMessage,
  RequireTxt as _RequireTxt,
} from '@blue-agency/rogue'
import styled from 'styled-components'
import { useListDownloadModal } from './useListDownloadModal'

type Props = {
  active: boolean
  onClose: () => void
  onOpen: () => void
}

const labelWidth = 101
const requireTxtWidth = 28

export const ListDownloadModal: React.FCX<Props> = (props) => {
  const {
    options,
    showDownloadError,
    onSubmit,
    handleSubmit,
    register,
    formState,
    errors,
  } = useListDownloadModal(props)
  return (
    <Modal
      className={props.className}
      active={props.active}
      title="リストダウンロード"
      size="l"
      onClose={props.onClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Upper>
          {showDownloadError && (
            <ErrorMessage text="ダウンロードできませんでした。もう一度お試しください。" />
          )}
          <Row>
            <Label>
              <LabelTxt>面接登録月</LabelTxt>
              <RequireTxt>必須</RequireTxt>
              <Dropdown
                allowEmpty
                name="month"
                ref={register}
                options={options}
                onBlurError={errors.month?.message}
              />
            </Label>
          </Row>
          <Description>
            選択した「面接登録月」のデータを一括でダウンロードします。
          </Description>
        </Upper>
        <WeakColorSeparator />
        <ButtonGroup>
          <CancelButton
            comlinkPushParams={{
              action: 'close_interviews_csv_download_modal',
            }}
            onClick={props.onClose}
          >
            キャンセル
          </CancelButton>
          <ExecButton
            comlinkPushParams={{ action: 'download_interviews_csv' }}
            type="submit"
            loading={formState.isSubmitting}
          >
            実行
          </ExecButton>
        </ButtonGroup>
      </form>
    </Modal>
  )
}

const Upper = styled.div`
  padding: 44px 22px 16px;
`

const ErrorMessage = styled(_ErrorMessage)`
  ${Upper} & {
    margin-top: -44px;
    margin-bottom: 16px;
  }
`
ErrorMessage.defaultProps = { size: 's' }

const Row = styled.div`
  display: flex;
  align-items: center;
`
const Label = styled.label`
  display: flex;
  align-items: center;
`
const LabelTxt = styled(Txt).attrs({ size: 's' })`
  width: ${labelWidth}px;
`

const RequireTxt = styled(_RequireTxt)`
  width: ${requireTxtWidth}px;
`
RequireTxt.defaultProps = {
  size: 'xs',
}

const Description = styled(Txt).attrs({ size: 's' })`
  width: 350px;
  ${Upper} & {
    margin: 14px 0 20px ${labelWidth + requireTxtWidth}px;
  }
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;

  > *:not(:last-child) {
    margin-right: 20px;
  }
`

const CancelButton = styled(TertiaryButton)`
  width: 136px;
`
CancelButton.defaultProps = { size: 'll' }

const ExecButton = styled(PrimaryButton)`
  width: 136px;
`
ExecButton.defaultProps = { size: 'll' }
