import React from 'react'
import styled, { css } from 'styled-components'
import { ManualModalBody } from './ManualModalBody'
import { ManualModalContainer } from '../containers/ManualModalContainer'
import { SecondaryButton, TertiaryButton, Txt, Icon } from '@blue-agency/rogue'
import { TutorialStep } from '@/components/TutorialStep'
import { WindowSizeContainer } from '@/containers/WindowSizeContainer'

export const Third: React.FCX = ({ className }) => {
  return (
    <ManualModalBody
      className={className}
      content={<Content />}
      bottom={<Bottom />}
    />
  )
}

const Content = () => {
  const { isPortrait } = WindowSizeContainer.useContainer()

  return (
    <ContentWrapper>
      <StepWrapper isPortrait={isPortrait}>
        <TutorialStep current={3} max={3} />
        <MainTxt size="l" isPortrait={isPortrait}>
          {isPortrait
            ? '・映像/画面が見えない\n・音が聞こえない\nなどが発生した場合は\nブラウザを再読込してください'
            : '「映像/画面が見えない」「音が聞こえない」などが発生した場合はブラウザを再読込してください'}
        </MainTxt>
      </StepWrapper>
      <ReloadIcon name="reload" />
      <FinishTxt size="l">以上で説明は終了です。</FinishTxt>
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div`
  padding: 18px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const StepWrapper = styled.div<{ isPortrait: boolean }>`
  display: flex;
  ${({ isPortrait }) =>
    isPortrait
      ? css`
          flex-direction: column;
          align-items: center;
        `
      : css`
          flex-direction: row;
        `}
`
const MainTxt = styled(Txt)<{ isPortrait: boolean }>`
  ${ContentWrapper} & {
    ${({ isPortrait }) =>
      isPortrait
        ? css`
            margin-top: 15px;
            text-align: center;
          `
        : css`
            margin-left: 20px;
          `}
  }
`
const ReloadIcon = styled(Icon)`
  ${ContentWrapper} & {
    margin-top: 20px;
  }
  height: 105px;
  width: 105px;
`
const FinishTxt = styled(Txt)`
  ${ContentWrapper} & {
    margin-top: 30px;
  }
`

const Bottom = () => {
  const { handleManualModalClose, handleBack } =
    ManualModalContainer.useContainer()
  return (
    <BottomWrapper>
      <BackButton
        text="前に戻る"
        onClick={handleBack}
        comlinkPushParams={{ action: 'to_prev_manual_modal_step' }}
      />
      <CloseButton
        text="閉じる"
        onClick={handleManualModalClose}
        comlinkPushParams={{ action: 'to_next_manual_modal_step' }}
      />
    </BottomWrapper>
  )
}

const BottomWrapper = styled.div`
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const CloseButton = styled(SecondaryButton)`
  ${BottomWrapper} & {
    width: 48%;
  }
`
const BackButton = styled(TertiaryButton)`
  ${BottomWrapper} & {
    width: 48%;
  }
`
