import React from 'react'
import { TimerProps } from '@blue-agency/rogue'
import { Pc } from './Pc'
import { SpPortrait } from './SpPortrait'
import { SpLandscape } from './SpLandscape'
import { DropdownContainer } from './hooks/useDropdown'
import { WindowSize } from '@blue-agency/react-utils/dist/useWindowSize'
import { Quality, QualityMode } from '@/services/interviewService/types/Quality'
import { BgEffectCode } from '@/services/interviewService/types/BgEffectCode'
import { ParticipantType } from '@/services/interviewService/types/ParticipantType'
import { DeviceType } from '@/services/interviewService/types/DeviceType'

export type InterviewControlsProps = {
  participantType: ParticipantType
  deviceType: DeviceType
  status: InterviewStatus
  windowSize: WindowSize
  muteButtonProps: ButtonProps
  cameraOffButtonProps: ButtonProps
  chatButtonProps: ButtonProps & { unread?: boolean }
  userButtonProps: ButtonProps & { count?: number }
  backButtonProps?: ButtonProps
  finishButtonProps?: ButtonProps
  screenShareButtonProps: ButtonProps
  shownUnstableNavPopup: boolean
  changeDeviceProps?: ChangeDeviceProps
  backgroundSettingProps?: BackgroundSettingProps
  changeQualityModeProps?: ChangeQualityModeProps
  isKaburikuPlan: boolean
} & TimerProps
export type InterviewStatus = 'notStarted' | 'started'
type ButtonProps = {
  active?: boolean
  disabled?: boolean
  onClick?: () => void
}

export type ChangeDeviceProps = {
  videoRef: (el: HTMLVideoElement | null) => void
  cameraDevices: readonly MediaDeviceInfo[]
  // カメラ不許可の場合、以下が undefined になる
  selectedCameraDeviceId: string | undefined
  onChangeCameraDevice: (deviceId: string) => void
  micDevices: readonly MediaDeviceInfo[]
  selectedMicDeviceId: string
  onChangeMicDevice: (deviceId: string) => void
  muted: boolean
  // スピーカー選択に未対応のブラウザの場合は、以下2つが undefined になる
  speakerDevices: readonly MediaDeviceInfo[] | undefined
  selectedSpeakerDeviceId: string | undefined
  onChangeSpeakerDevice: (deviceId: string) => void
}

export type BackgroundSettingProps = {
  videoRef: (el: HTMLVideoElement | null) => void
  bgEffectCode: BgEffectCode
  changeBgEffectCode: (bgEffectCode: BgEffectCode) => void
}
export type ChangeQualityModeProps = {
  quality: Quality
  changeInterviewQuality: (mode: QualityMode) => Promise<void>
}

export const InterviewControls: React.FCX<InterviewControlsProps> = (props) => {
  return (
    <DropdownContainer.Provider
      initialState={{
        enableCameraDeviceChange: !!props.changeDeviceProps,
        qualityMode: props.changeQualityModeProps?.quality.mode,
      }}
    >
      {(() => {
        switch (props.deviceType) {
          case 'pc':
            return <Pc {...props} />
          case 'spPortrait':
            return <SpPortrait {...props} />
          case 'spLandscape':
            return <SpLandscape {...props} />
        }
      })()}
    </DropdownContainer.Provider>
  )
}
