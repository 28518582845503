import React from 'react'
import styled from 'styled-components'
import {
  Icon as _Icon,
  Txt,
  WeakColorSeparator,
  PrimaryButton,
  theme,
} from '@blue-agency/rogue'
import { StepContainer } from '../hooks/useStep'
import { overflowYScrollStyle } from '@/styles/overflowYScrollStyle'
import { WindowSizeContainer } from '@/containers/WindowSizeContainer'
import { isPc } from '@/utils'

export const First: React.FCX = ({ className }) => {
  const { handleNext } = StepContainer.useContainer()
  const { width } = WindowSizeContainer.useContainer()
  const isSp = !isPc(width)

  return (
    <Wrapper className={className}>
      <Upper isSpLayout={isSp}>
        <Row>
          <IconWrapper isSpLayout={isSp}>
            <Icon name="start-and-end" />
          </IconWrapper>
          <div style={{ flex: 1 }}>
            <Txt>面接の開始と終了</Txt>
            <Txt size="s">
              面接官が複数人の場合は、面接官の誰か一人が「面接を開始する」もしくは「終了」をクリックすると面接が開始および終了します。
            </Txt>
          </div>
        </Row>
        <Row>
          <IconWrapper isSpLayout={isSp}>
            <Icon name="cannot-restart" />
          </IconWrapper>
          <div style={{ flex: 1 }}>
            <Txt>終了後は再開できません</Txt>
            <Txt size="s">
              一度「終了」ボタンをクリックして終了した後は、再開することはできませんので、ご注意ください。
            </Txt>
          </div>
        </Row>
      </Upper>
      <WeakColorSeparator />
      <Bottom>
        <NextButton
          comlinkPushParams={{
            action: 'click_next_button_on_waiting_room_manual',
          }}
          text="次へ"
          onClick={handleNext}
        />
      </Bottom>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const Upper = styled.div<{ isSpLayout: boolean }>`
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;

  padding: ${({ isSpLayout }) => (isSpLayout ? '20px 16px' : '0 70px')};

  ${overflowYScrollStyle};
`

const Row = styled.div`
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-top: 37px;
  }
`

const IconWrapper = styled.div<{ isSpLayout: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  background-color: ${theme.color.navy[1]};
  border-radius: 50%;
  margin-right: ${({ isSpLayout }) => (isSpLayout ? '12px' : '40px')};
`

const Icon = styled(_Icon)`
  width: 78px;
  height: 78px;
`
const Bottom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
`

const NextButton = styled(PrimaryButton)`
  width: 166px;
  height: 44px;
`
