import React from 'react'
import styled from 'styled-components'
import { ApplyPageContainer } from '../../ApplyPageContainer'
import { Txt } from '@blue-agency/rogue'
import { ConfirmStepItem } from '../../types'

export const RikunabiSection: React.FCX = ({ className }) => {
  const { inputtedData } = ApplyPageContainer.useContainer()
  return (
    <Wrapper className={className}>
      {rikunabiItems.map((item, index) => (
        <React.Fragment key={index}>
          <Item>
            <Label>{index === 0 && 'リクナビ情報'}</Label>
            <ItemLabel size="s" bold>
              {item.label}
            </ItemLabel>
            <Txt size="s">{inputtedData[item.name]}</Txt>
          </Item>
        </React.Fragment>
      ))}
    </Wrapper>
  )
}

const rikunabiItems: ConfirmStepItem[] = [
  {
    label: 'リクナビ企業ID\n(r+9桁の数字)',
    name: 'rikunabiOrganizerId',
  },
  {
    label: 'リクナビ掲載企業名',
    name: 'rikunabiOrganizerName',
  },
]

const Wrapper = styled.div`
  padding: 20px;
`
const Item = styled.div`
  &:nth-of-type(n + 2) {
    margin-top: 20px;
  }
  display: flex;
  align-items: center;
`
const Label = styled(Txt)`
  width: 160px;
`
const ItemLabel = styled(Txt)`
  width: 130px;
`
