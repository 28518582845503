import React from 'react'
import styled from 'styled-components'
import { PrimaryButton } from '@blue-agency/rogue'

export const Bottom: React.FCX = ({ className }) => {
  return (
    <Wrapper className={className}>
      <ButtonGroup>
        <ConfirmButton
          size="l1"
          type="submit"
          text="申込内容を確認"
          comlinkPushParams={{ action: 'to_confirm_step' }}
        />
      </ButtonGroup>
    </Wrapper>
  )
}

const Wrapper = styled.div``
const ButtonGroup = styled.div`
  height: 76px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const ConfirmButton = styled(PrimaryButton)`
  margin-left: 20px;
`
