import React from 'react'
import styled, { css } from 'styled-components'
import { ManualModalBody } from './ManualModalBody'
import { ManualModalContainer } from '../containers/ManualModalContainer'
import { PrimaryButton, TertiaryButton, Txt, theme } from '@blue-agency/rogue'
import { TutorialStep } from '@/components/TutorialStep'
import { WindowSizeContainer } from '@/containers/WindowSizeContainer'
import spImage from '@/assets/sp.png'

export const Second: React.FCX = ({ className }) => {
  return (
    <ManualModalBody
      className={className}
      content={<Content />}
      bottom={<Bottom />}
    />
  )
}

const Content = () => {
  const { isPortrait } = WindowSizeContainer.useContainer()

  return (
    <ContentWrapper>
      <StepWrapper isPortrait={isPortrait}>
        <TutorialStep current={2} max={3} />
        <MainTxt size="l" isPortrait={isPortrait}>
          「質問コーナー」で{isPortrait && <br />}質問を受け付けています
        </MainTxt>
      </StepWrapper>
      <SubTxt size="s" color={theme.color.gray[1]}>
        ※企業担当者には誰が質問したかが表示されます。
        <br />
        ※参加者同士は名前が表示されません。
      </SubTxt>
      <Image src={spImage} />
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div`
  padding: 18px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const StepWrapper = styled.div<{ isPortrait: boolean }>`
  display: flex;
  ${({ isPortrait }) =>
    isPortrait
      ? css`
          flex-direction: column;
          align-items: center;
        `
      : css`
          flex-direction: row;
          align-items: center;
        `}
`
const MainTxt = styled(Txt)<{ isPortrait: boolean }>`
  ${ContentWrapper} & {
    ${({ isPortrait }) =>
      isPortrait
        ? css`
            margin-top: 15px;
          `
        : css`
            margin-left: 20px;
          `}
  }
`
const SubTxt = styled(Txt)`
  ${ContentWrapper} & {
    margin-top: 8px;
  }
`
const Image = styled.img`
  ${ContentWrapper} & {
    margin-top: 20px;
  }
  width: 300px;
`

const Bottom = () => {
  const { handleNext, handleBack } = ManualModalContainer.useContainer()
  return (
    <BottomWrapper>
      <BackButton
        text="前に戻る"
        onClick={handleBack}
        comlinkPushParams={{ action: 'to_prev_manual_modal_step' }}
      />
      <NextButton
        text="次に進む"
        onClick={handleNext}
        comlinkPushParams={{ action: 'to_next_manual_modal_step' }}
      />
    </BottomWrapper>
  )
}

const BottomWrapper = styled.div`
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const NextButton = styled(PrimaryButton)`
  ${BottomWrapper} & {
    width: 48%;
  }
`
const BackButton = styled(TertiaryButton)`
  ${BottomWrapper} & {
    width: 48%;
  }
`
