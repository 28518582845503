import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { INTERNAL_PATHS } from '@/services/urlService'
import { InterviewsPage } from './InterviewsPage'
import { InterviewRoutes } from './InterviewRoutes'
import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'
import { provide } from '@blue-agency/front-state-management'
import { NotFound } from '@/components/NotFound'

export const InterviewsRoutes = provide(OrganizerServiceContainer)(() => {
  return (
    <Switch>
      <Route
        exact
        path={INTERNAL_PATHS.organizer.my.interviews.index}
        component={InterviewsPage}
      />
      <Route
        path={INTERNAL_PATHS.organizer.my.interviews.interview.index}
        component={InterviewRoutes}
      />
      <Route path="*" component={NotFound} />
    </Switch>
  )
})
