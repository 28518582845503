import React, { useState } from 'react'
import {
  Dropdown as _Dropdown,
  ErrorMessage as _ErrorMessage,
  InputText,
  Modal,
  NewTabLink,
  PrimaryButton,
  RequireTxt as _RequireTxt,
  TertiaryButton,
  theme,
  Txt,
  WeakColorSeparator as _WeakColorSeparator,
} from '@blue-agency/rogue'

import styled from 'styled-components'
import { useUpdateScheduleModal } from './hooks/useUpdateScheduleModal'
import DatePicker, { registerLocale } from 'react-datepicker'
import { DatePickerInput } from '@/pages/OrganizerRoutes/MyRoutes/HallsRoutes/HallsPage/DatePickerInput'
import { format } from 'date-fns'
import ja from 'date-fns/locale/ja'
import { EXTERNAL_URLS } from '@/services/urlService'
import { convertNumberDropdownOption } from '@/services/dropdownOptionService'
import { getHourList, getMinuteList } from '@/services/dateService'

type Props = {
  hallGuid: string
  currentName: string
  currentScheduleAt: Date | undefined
  currentScheduledParticipantsNumber: number | undefined
  onClose: () => void
  onOpen: () => void
}

export const UpdateScheduleModal: React.FCX<Props> = (props) => {
  const {
    isSubmitError,
    onSubmit,
    register,
    errors,
    formState,
    handleSubmit,
    setValue,
  } = useUpdateScheduleModal(props)

  const currentScheduleAt = props.currentScheduleAt ?? new Date()
  // 秒は使用しないのでノイズにならないように0をset
  currentScheduleAt.setSeconds(0)

  const [startDate, setStartDate] = useState(currentScheduleAt)
  const currentHour = currentScheduleAt.getHours()
  // 分は5分刻みで表示させるため、5分単位で丸める
  const currentMinute = Math.round(currentScheduleAt.getMinutes() / 5) * 5

  const hourList = convertNumberDropdownOption(getHourList())
  const minuteList = convertNumberDropdownOption(getMinuteList(5))

  const onChangeDate = (date: Date) => {
    if (date) {
      setValue('scheduledAtDate', date)
      setStartDate(date)
    }
  }

  register('scheduledAtDate')
  setValue('scheduledAtDate', startDate)
  registerLocale('ja', ja)

  return (
    // MEMO: 親で UpdateNameModal の表示・非表示を管理するので、ここでは常に active={true} を渡す
    //  https://github.com/blue-agency/yashiori-front/pull/1727
    <Modal active onClose={props.onClose} title="Web説明会の編集" size="l">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Upper>
          {isSubmitError && (
            <ErrorMessage text="保存できませんでした。もう一度お試しください。" />
          )}
          <Item>
            <Label>
              <LabelTxt>Web説明会名</LabelTxt>
              <RequireTxt>必須</RequireTxt>
              <InputText
                name="sessionName"
                size="ll"
                ref={register}
                onBlurError={errors.sessionName?.message}
                placeholder="Web説明会名"
              />
            </Label>
          </Item>
          <WeakColorSeparator />
          <Item>
            <LabelTxt>実施予定日時</LabelTxt>
            <RequireTxt>必須</RequireTxt>
            <DatePicker
              locale="ja"
              dateFormat="yyyy/MM/dd"
              selected={startDate}
              onChange={onChangeDate}
              name="scheduledAtDatePicker"
              customInput={
                <DatePickerInput
                  name="scheduledAtDate"
                  onBlurError={errors.scheduledAtDate?.message}
                  value={format(startDate, 'yyyy/MM/dd', {
                    locale: ja,
                  })}
                />
              }
            />
            <Dropdown
              size="ss"
              name="scheduledAtHour"
              options={hourList}
              value={currentHour}
              placeholder="未選択"
              onBlurError={errors.scheduledAtHour?.message}
              ref={register}
            />
            <TimeTxt>時</TimeTxt>
            <Dropdown
              size="ss"
              name="scheduledAtMinute"
              options={minuteList}
              value={currentMinute}
              placeholder="未選択"
              onBlurError={errors.scheduledAtMinute?.message}
              ref={register}
            />
            <TimeTxt>分</TimeTxt>
          </Item>
          <Item>
            <Label>
              <LabelTxt>参加予定人数</LabelTxt>
              <RequireTxt>必須</RequireTxt>
              <InputText
                size="l"
                ref={register}
                name="scheduledParticipantsNumber"
                placeholder="参加予定の人数を入力してください"
                onBlurError={errors.scheduledParticipantsNumber?.message}
                type="number"
              />
            </Label>
          </Item>
          <Item>
            <SupplementList>
              <SupplementItem>
                「参加予定人数」は、参加者に表示されたり、制限がかかるものではありません。
              </SupplementItem>
              <SupplementItem>
                「参加予定人数」がご契約プランの上限を超える場合は
                <br />
                <NewTabLink
                  href={EXTERNAL_URLS.supportDesk.general}
                  action="open_support_desk_for_hall_update_modal"
                >
                  こちら
                </NewTabLink>
                までお問い合わせください。
              </SupplementItem>
            </SupplementList>
          </Item>
          <WeakColorSeparator />
          <ButtonGroup>
            <CancelButton
              size="l2"
              text="キャンセル"
              onClick={props.onClose}
              comlinkPushParams={{ action: 'close_update_seminar_modal' }}
            />
            <RegisterButton
              size="l2"
              text="保存"
              type="submit"
              loading={formState.isSubmitting}
              comlinkPushParams={{ action: 'update_seminar' }}
            />
          </ButtonGroup>
        </Upper>
      </Form>
    </Modal>
  )
}

const Form = styled.form`
  padding: 20px;
`
const Item = styled.div`
  ${Form} && {
    width: 100%;
  }

  ${Form} &&:nth-of-type(n + 2) {
    margin-top: 20px;
  }

  display: flex;
  align-items: center;
`

const Label = styled.label`
  display: flex;
  align-items: center;
`

const ButtonGroup = styled.div`
  　${Form} && {
    margin-top: 20px;
  }

  display: flex;
  align-items: center;
  justify-content: center;
`
const CancelButton = styled(TertiaryButton)``
const RegisterButton = styled(PrimaryButton)`
  ${ButtonGroup} && {
    margin-left: 20px;
  }
`

const RequireTxt = styled(_RequireTxt)`
  width: 30px;
`

RequireTxt.defaultProps = { size: 'xs' }

const LabelTxt = styled(Txt)`
  width: 140px;
`

const SupplementList = styled.ul`
  list-style-type: '※';
  margin-left: 170px;
  margin-bottom: 10px;
  font-size: ${theme.fontSize.s};
`

const SupplementItem = styled.li`
  padding-bottom: 4px;
  padding-top: 4px;
`

const TimeTxt = styled(Txt)``

TimeTxt.defaultProps = { size: 'ss' }

const Dropdown = styled(_Dropdown)`
  margin-left: 10px;
  margin-right: 10px;
`

const WeakColorSeparator = styled(_WeakColorSeparator)`
  margin-top: 20px;
`

const Upper = styled.div`
  padding-top: 20px;
`

const ErrorMessage = styled(_ErrorMessage)`
  ${Upper} & {
    margin-top: -20px;
    margin-bottom: 20px;
  }
`
ErrorMessage.defaultProps = { size: 's' }
