import { useRef, useCallback, useState, useEffect } from 'react'
import { Comlink } from '@blue-agency/comlink'
import soraSdk, { ConnectionOptions, ConnectionPublisher } from 'sora-js-sdk'
import { useInterval } from 'react-use'
import { comlinkPush } from '@/comlink'

const INTERVAL_MS = 10_000 // 10秒

export const useSora = (presentationGuid: string, logEndpoint: string) => {
  const sendonlyRef = useRef<ConnectionPublisher>()
  const [unstableLevel, setUnstableLevel] = useState<number>()

  const connect = useCallback(
    async ({
      hostname,
      channelId,
      userMediaStream,
    }: {
      hostname: string
      channelId: string
      userMediaStream: MediaStream
    }) => {
      const options: ConnectionOptions = {
        multistream: false,
        videoCodecType: 'H264',
        videoBitRate: 800,
      }
      const metadata = {
        guid: presentationGuid,
      }

      const sora = soraSdk.connection(`wss://${hostname}/signaling`, false)

      const sendonly = sora.sendonly(channelId, metadata, options)
      sendonly.on('notify', (data) => {
        if (data.event_type === 'network.status') {
          setUnstableLevel(data.unstable_level)
        }
      })
      sendonlyRef.current = sendonly

      comlinkPush({
        type: 'system_activity',
        action: 'connect_presenter_to_sora',
        targetName: 'channelId',
        targetIdStr: channelId,
      })
      return await sendonlyRef.current.connect(userMediaStream)
    },
    [presentationGuid]
  )

  let posting = false
  useInterval(
    async () => {
      if (posting) return
      posting = true

      const pc = sendonlyRef?.current?.pc
      if (!pc) return

      const stats = await pc.getStats()
      if (stats === undefined) return

      const uuid = await getBrowserClientUuid()

      const data = {
        uuid: uuid,
        data: {
          stats: Array.from(stats.values()),
          channelId: sendonlyRef?.current?.channelId,
          connectionId: sendonlyRef?.current?.connectionId,
          timestamp: new Date().getTime(),
        },
      }
      const dataJson = JSON.stringify(data)

      try {
        await fetch(logEndpoint, {
          method: 'POST',
          mode: 'cors',
          credentials: 'omit',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json',
          },
          body: dataJson,
        })
      } catch {
        // WebRTC Statsは失敗してもログが送信できないだけなので無視する
      }

      posting = false
    },
    sendonlyRef?.current?.pc ? INTERVAL_MS : null
  )

  useEffect(() => {
    if (unstableLevel === undefined) return
    comlinkPush({
      type: 'system_activity',
      action: 'change_unstable_level',
      targetName: 'presentationGuid',
      targetIdStr: presentationGuid,
      metadata: {
        videoType: 'presenter',
        unstableLevel: unstableLevel + '',
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unstableLevel])

  const disconnect = useCallback(async () => {
    if (!sendonlyRef.current) return
    comlinkPush({
      type: 'system_activity',
      action: 'disconnect_presenter_from_sora',
      targetName: 'channelId',
      targetIdStr: sendonlyRef.current?.channelId,
    })
    await sendonlyRef.current.disconnect()
  }, [])

  const replaceTrack = useCallback((videoTrack: MediaStreamTrack) => {
    const senders = sendonlyRef.current?.pc?.getSenders()
    if (!senders) {
      comlinkPush({
        type: 'system_activity',
        action: 'failed_to_replaceTrack_on_presentation',
        targetName: 'channelId',
        targetIdStr: sendonlyRef.current?.channelId,
        metadata: { reason: 'senders not found' },
      })
      throw new Error('senders not found')
    }

    const sender = senders.find((sender) => sender.track?.kind === 'video')
    if (!sender) {
      comlinkPush({
        type: 'system_activity',
        action: 'failed_to_replaceTrack_on_presentation',
        targetName: 'channelId',
        targetIdStr: sendonlyRef.current?.channelId,
        metadata: { reason: 'sender not found' },
      })
      throw new Error('sender not found')
    }

    sender.replaceTrack(videoTrack)
  }, [])

  return {
    connect,
    disconnect,
    replaceTrack,
  }
}

const getBrowserClientUuid = async () => {
  const i = await Comlink.getClientIdentity()
  return i.uuid
}
