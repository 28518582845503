import { useCallback, ComponentProps, useEffect, useRef } from 'react'
import { InterviewVideo } from '../InterviewVideo'
import { comlinkPush } from '@/comlink'

export const useInterviewVideo = ({
  pinTile,
  tileId,
  isOwn,
  isOwnCameraMuted,
  isUnavailableVideoInput,
  bindVideoElement,
  setAudioOutputForVideoTile,
}: ComponentProps<typeof InterviewVideo>) => {
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if (!videoRef.current) return
    if (!tileId) return
    if (isOwn && (isOwnCameraMuted || isUnavailableVideoInput)) return

    const cleanup = bindVideoElement(tileId, videoRef.current)
    videoRef.current.muted = false

    return () => {
      cleanup()
    }
  }, [
    bindVideoElement,
    tileId,
    isOwn,
    isOwnCameraMuted,
    isUnavailableVideoInput,
  ])

  useEffect(() => {
    if (!videoRef.current) return
    if (!tileId) return
    if (isOwn) return // 自身の音声を出力する必要はない
    setAudioOutputForVideoTile(tileId).catch((e) => {
      comlinkPush({
        action: 'failed_to_set_sink_for_other_participants_audio',
        metadata: {
          errorMessage: e.message ?? '',
        },
      })

      // DOMException が発生することがある（原因は不明）が、この例外が発生してもスピーカーの切り替えには成功している可能性が高い
      // そのため「失敗した可能性があります」という曖昧な表現にしている
      alert(
        '音声の出力デバイス設定に失敗した可能性があります。相手からの音声が聞こえない場合、ブラウザを再読込してください。'
      )
    })
  }, [isOwn, setAudioOutputForVideoTile, tileId])

  const handleClick = useCallback(() => {
    if (!tileId) return
    pinTile(tileId)
  }, [pinTile, tileId])

  return { videoRef, handleClick }
}
