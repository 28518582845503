import { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { SignupAsOrganizerParams } from '@/@types/app'
import {
  GetPermissionsRequest,
  CreateHallRequest,
  CreateDemoHallRequest,
  EnterHallRequest,
  EnterDemoHallRequest,
  StartSessionRequest,
  FinishSessionRequest,
  ListChatMessagesForPresenterRequest,
  GetParticipantNameRequest,
  ListHallsRequest,
  CreateInterviewRequest,
  UpdateInterviewNameRequest,
  SetupSessionRequest,
  GetSessionStatusRequest,
  ListInterviewsRequest,
  GetPresenterInterviewRequest,
  CreateInterviewSessionRequest,
  StartInterviewRequest,
  FinishInterviewRequest,
  StartScreenSharingRequest,
  FinishScreenSharingRequest,
  ExportInterviewsInMonthCSVRequest,
  ExportParticipantsCSVRequest,
  ExportChatMessagesForHallCSVRequest,
  CreateDemoInvitationRequest,
  DeleteDemoSessionTokenRequest,
  RestartSessionRequest,
  GetOrganizerRequest,
  UpdatePlanRequest,
  DeleteInterviewsRequest,
  GetInterviewsCountRequest,
  GetHallsCountRequest,
  ShortenUrlRequest,
  ListChatMessagesForInterviewRequest,
  SignupAsOrganizerRequest,
  GetPromotionRequest,
  GetInterviewRecordingRequest,
  CreatePresentationRequest,
  ListPresentationsRequest,
  GetDownloadUrlForPresentationRequest,
  GetPresentationRequest,
  SetupPresentationRequest,
  StartPresentationRequest,
  RestartPresentationRequest,
  GetPresentationStatusRequest,
  FinishPresentationRequest,
  StartScreenSharingForPresentationRequest,
  FinishScreenSharingForPresentationRequest,
  DeletePresentationsRequest,
  GetHallRecordingRequest,
  GetPromotionForRikunabiPackageRequest,
  RegisterEmailForRikunabiPackageRequest,
  VerifyTokenForRikunabiPackageRequest,
  ApplyForRikunabiPackageRequest,
  UpdatePresentationNameRequest,
  CheckStartedPollingRequest,
  CheckStartedPollingForScreenSharingRequest,
  DeleteHallsRequest,
  UpdateHallRequest,
  GetHallParticipantsCountRequest,
  GetHallJoinedCountRequest,
  ChangeInterviewQualityRequest,
  GetInterviewQualityRequest,
  GetSignalingPointsRequest,
  GetInterviewSpotlightRequest,
  InterviewQualityMode,
  SearchInterviewsRequest,
  CreateInterviewsForKaburikuRequest,
} from '@blue-agency/proton/web/v2/yashiori_bff/yashiori_bff_service_pb'
import { Date as GrpcDate } from '@blue-agency/proton/web/vendor/google/type/date_pb'
import { UpgradePlanForm } from '@/components/OrganizerForm/types'
import { Plan } from '@blue-agency/proton/web/v2/yashiori_bff/plan_data_pb'
import {
  ListHallsHall,
  Organizer,
  InterviewsCount,
  HallsCount,
  Permissions,
  ListPresentationsPresentation,
  ListInterviewsInterview,
  Promotion,
  PromotionForRikunabiPackage,
  ApplyForRikunabiPackageRequestParams,
  SearchInterviewsArgs,
  CreateInterviewsForKaburikuArgs,
  CreateInterviewsForKaburikuInterview,
} from './types'
import { PreferredStartDate } from '@blue-agency/proton/web/v2/yashiori_bff/preferred_start_date_pb'
import { callBff } from '@/services/bffService'
import * as google_protobuf_timestamp_pb from 'google-protobuf/google/protobuf/timestamp_pb'
import {
  QualityMode,
  QualityModeLow,
  QualityModeNormal,
} from '@/services/interviewService/types/Quality'

export const useOrganizerService = () => {
  const { token } = useParams<{ token?: string }>()
  const metadata = useMemo(() => ({ authorization: token || '' }), [token])

  const signupAsOrganizer = useCallback(
    async (params: SignupAsOrganizerParams) => {
      const req = new SignupAsOrganizerRequest()
      req.setPromotionGuid(params.promotionGuid)
      req.setName(params.name)
      req.setRepresentativeName(params.representativeName)
      req.setPhoneNumber(params.phoneNumber)
      req.setPostCode(params.postCode)
      req.setAddress(params.address)
      req.setEmployeesNumber(Number(params.employeesNumber))
      req.setAdminName(params.adminName)
      req.setAdminNameKana(params.adminNameKana)
      req.setAdminDepartment(params.adminDepartment)
      req.setAdminPhoneNumber(params.adminPhoneNumber)
      req.setAdminEmail(params.adminEmail)
      req.setBillingName(params.billingName || '')
      req.setBillingNameKana(params.billingNameKana || '')
      req.setBillingDepartment(params.billingDepartment || '')
      req.setBillingPostCode(params.billingPostCode || '')
      req.setBillingAddress(params.billingAddress || '')
      req.setBillingPhoneNumber(params.billingPhoneNumber || '')
      req.setBillingEmail(params.billingEmail || '')
      req.setReferrer(params.referrer || '')
      req.setCouponCode(params.couponCode || '')
      req.setNewGraduate(Number(params.newGraduate))
      req.setMidCareer(Number(params.midCareer))
      req.setTemporary(Number(params.temporary))
      req.setParttime(Number(params.parttime))
      const res = await callBff('signupAsOrganizer', [req])
      return { ...res.toObject() }
    },
    []
  )

  const getPromotion = useCallback(
    async ({ promotionGuid }: { promotionGuid: string }) => {
      const req = new GetPromotionRequest()
      req.setGuid(promotionGuid)
      const res = await callBff('getPromotion', [req])
      const promotion: Promotion = {
        ...res.toObject(),
        plan: res.getPlan()!.toObject(),
        planExpiresAt: res.getPlanExpiresAt()!.toDate(),
      }
      return promotion
    },
    []
  )

  const getPromotionForRikunabiPackage = useCallback(
    async ({ promotionGuid }: { promotionGuid: string }) => {
      const req = new GetPromotionForRikunabiPackageRequest()
      req.setGuid(promotionGuid)
      const res = await callBff('getPromotionForRikunabiPackage', [req])
      const promotion: PromotionForRikunabiPackage = {
        ...res.toObject(),
        preferredStartDates: res
          .getPreferredStartDatesList()
          .map((date) => date.toObject()),
      }
      return promotion
    },
    []
  )

  const registerEmailForRikunabiPackage = useCallback(
    async ({
      email,
      promotionGuid,
    }: {
      email: string
      promotionGuid: string
    }) => {
      const req = new RegisterEmailForRikunabiPackageRequest()
      req.setEmail(email)
      req.setPromotionGuid(promotionGuid)
      await callBff('registerEmailForRikunabiPackage', [req])
    },
    []
  )

  const verifyTokenForRikunabiPackage = useCallback(async (token: string) => {
    const req = new VerifyTokenForRikunabiPackageRequest()
    req.setToken(token)
    const res = await callBff('verifyTokenForRikunabiPackage', [req])
    return { ...res.toObject() }
  }, [])

  const applyForRikunabiPackage = useCallback(
    async (params: ApplyForRikunabiPackageRequestParams) => {
      const req = new ApplyForRikunabiPackageRequest()
      req.setToken(params.token)
      const preferredStartDate = new PreferredStartDate()
      preferredStartDate.setId(params.preferredStartDateId)
      req.setPreferredStartDate(preferredStartDate)
      req.setRikunabiOrganizerId(params.rikunabiOrganizerId)
      req.setRikunabiOrganizerName(params.rikunabiOrganizerName)
      req.setName(params.name)
      req.setRepresentativeName(params.representativeName)
      req.setPhoneNumber(params.phoneNumber)
      req.setPostCode(params.postCode)
      req.setAddress(params.address)
      req.setAdminName(params.adminName)
      req.setAdminNameKana(params.adminNameKana)
      req.setAdminDepartment(params.adminDepartment)
      req.setAdminPhoneNumber(params.adminPhoneNumber)
      req.setBillingOrganizerName(params.billingOrganizerName)
      req.setBillingName(params.billingName)
      req.setBillingNameKana(params.billingNameKana)
      req.setBillingDepartment(params.billingDepartment)
      req.setBillingPostCode(params.billingPostCode)
      req.setBillingAddress(params.billingAddress)
      req.setBillingPhoneNumber(params.billingPhoneNumber)
      req.setBillingEmail(params.billingEmail)
      req.setEmployeesNumber(params.employeesNumber)
      req.setNewHiresNumber(params.newHiresNumber)
      const res = await callBff('applyForRikunabiPackage', [req])
      return { ...res.toObject() }
    },
    []
  )

  const getOrganizer = useCallback(async () => {
    const req = new GetOrganizerRequest()
    const res = await callBff('getOrganizer', [req, metadata])
    const organizer: Organizer = {
      ...res.toObject(),
      interviewQuota: res.getInterviewQuota()!.toObject(),
      plan: res.getPlan()!.toObject(),
      planExpiresAt: res.getPlanExpiresAt()!.toDate(),
      registeredAt: res.getRegisteredAt()!.toDate(),
    }
    return organizer
  }, [metadata])

  const getPermissions = useCallback(async () => {
    const req = new GetPermissionsRequest()
    const res = await callBff('getPermissions', [req, metadata])
    const permissions: Permissions = res.toObject()
    return permissions
  }, [metadata])

  const updatePlan = useCallback(
    async (params: UpgradePlanForm) => {
      const req = new UpdatePlanRequest()
      const plan = new Plan()
      plan.setId(Number(params.plan))
      req.setPlan(plan)
      req.setName(params.name)
      req.setRepresentativeName(params.representativeName)
      req.setPhoneNumber(params.phoneNumber)
      req.setPostCode(params.postCode)
      req.setAddress(params.address)
      req.setAdminName(params.adminName)
      req.setAdminNameKana(params.adminNameKana)
      req.setAdminDepartment(params.adminDepartment)
      req.setAdminPhoneNumber(params.adminPhoneNumber)
      req.setAdminEmail(params.adminEmail)
      req.setBillingName(params.billingName || '')
      req.setBillingNameKana(params.billingNameKana || '')
      req.setBillingDepartment(params.billingDepartment || '')
      req.setBillingPostCode(params.billingPostCode || '')
      req.setBillingAddress(params.billingAddress || '')
      req.setBillingPhoneNumber(params.billingPhoneNumber || '')
      req.setBillingEmail(params.billingEmail || '')
      req.setCouponCode(params.couponCode || '')
      const res = await callBff('updatePlan', [req, metadata])
      return res
    },
    [metadata]
  )

  const createHall = useCallback(
    async ({
      sessionName,
      scheduledAt,
      scheduledParticipantsNumber,
    }: {
      sessionName: string
      scheduledAt: Date
      scheduledParticipantsNumber: number
    }) => {
      const req = new CreateHallRequest()
      req.setSessionName(sessionName)
      const timestamp = new google_protobuf_timestamp_pb.Timestamp()
      timestamp.fromDate(scheduledAt)
      req.setScheduledAt(timestamp)
      req.setScheduledParticipantsNumber(scheduledParticipantsNumber)
      await callBff('createHall', [req, metadata])
    },
    [metadata]
  )

  const createDemoHall = useCallback(async () => {
    const req = new CreateDemoHallRequest()
    const res = await callBff('createDemoHall', [req])
    return res
  }, [])

  const createInterview = useCallback(
    async ({ count, name }: { count: number; name: string }) => {
      const req = new CreateInterviewRequest()
      req.setCount(count)
      req.setName(name)
      await callBff('createInterview', [req, metadata])
    },
    [metadata]
  )

  const deleteInterviews = useCallback(
    async (interviewGuids: string[]) => {
      const req = new DeleteInterviewsRequest()
      req.setInterviewGuidsList(interviewGuids)
      await callBff('deleteInterviews', [req, metadata])
    },
    [metadata]
  )

  const updateInterviewName = useCallback(
    async ({
      interviewGuid,
      name,
    }: {
      interviewGuid: string
      name: string
    }) => {
      const req = new UpdateInterviewNameRequest()
      req.setInterviewGuid(interviewGuid)
      req.setName(name)
      await callBff('updateInterviewName', [req, metadata])
    },
    [metadata]
  )

  const updateHall = useCallback(
    async ({
      hallGuid,
      sessionName,
      scheduledAt,
      scheduledParticipantsNumber,
    }: {
      hallGuid: string
      sessionName: string
      scheduledAt: Date
      scheduledParticipantsNumber: number
    }) => {
      const req = new UpdateHallRequest()
      req.setGuid(hallGuid)
      req.setSessionName(sessionName)
      const timestamp = new google_protobuf_timestamp_pb.Timestamp()
      timestamp.fromDate(scheduledAt)
      req.setScheduledAt(timestamp)
      req.setScheduledParticipantsNumber(scheduledParticipantsNumber)
      const res = await callBff('updateHall', [req, metadata])

      return res
    },
    [metadata]
  )

  const enterHall = useCallback(
    async ({ entranceGuid }: { entranceGuid: string }) => {
      const req = new EnterHallRequest()
      req.setPresenterEntranceGuid(entranceGuid)
      const res = await callBff('enterHall', [req, metadata])
      return res
    },
    [metadata]
  )

  const enterDemoHall = useCallback(
    async ({ entranceGuid }: { entranceGuid: string }) => {
      const req = new EnterDemoHallRequest()
      req.setPresenterEntranceGuid(entranceGuid)
      const res = await callBff('enterDemoHall', [req])
      return res
    },
    []
  )

  const getSessionStatus = useCallback(
    async ({
      hallGuid,
      presenterToken,
    }: {
      hallGuid: string
      presenterToken: string
    }) => {
      const req = new GetSessionStatusRequest()
      req.setHallGuid(hallGuid)
      const res = await callBff('getSessionStatus', [
        req,
        { authorization: presenterToken },
      ])
      return res.toObject()
    },
    []
  )

  const setupSession = useCallback(
    async ({
      hallGuid,
      presenterToken,
    }: {
      hallGuid: string
      presenterToken: string
    }) => {
      const req = new SetupSessionRequest()
      req.setHallGuid(hallGuid)
      const res = await callBff('setupSession', [
        req,
        { authorization: presenterToken },
      ])
      return res
    },
    []
  )

  const startScreenSharing = useCallback(
    async ({
      hallGuid,
      presenterToken,
    }: {
      hallGuid: string
      presenterToken: string
    }) => {
      const req = new StartScreenSharingRequest()
      req.setHallGuid(hallGuid)
      const res = await callBff('startScreenSharing', [
        req,
        { authorization: presenterToken },
      ])
      return res
    },
    []
  )

  const finishScreenSharing = useCallback(
    async ({
      hallGuid,
      presenterToken,
    }: {
      hallGuid: string
      presenterToken: string
    }) => {
      const req = new FinishScreenSharingRequest()
      req.setHallGuid(hallGuid)
      const res = await callBff('finishScreenSharing', [
        req,
        { authorization: presenterToken },
      ])
      return res
    },
    []
  )

  const startSession = useCallback(
    async ({
      hallGuid,
      presenterToken,
    }: {
      hallGuid: string
      presenterToken: string
    }) => {
      const req = new StartSessionRequest()
      req.setHallGuid(hallGuid)
      const res = await callBff('startSession', [
        req,
        { authorization: presenterToken },
      ])
      return res
    },
    []
  )

  const finishSession = useCallback(
    async ({
      hallGuid,
      presenterToken,
    }: {
      hallGuid: string
      presenterToken: string
    }) => {
      const req = new FinishSessionRequest()
      req.setHallGuid(hallGuid)
      const res = await callBff('finishSession', [
        req,
        { authorization: presenterToken },
      ])
      return res
    },
    []
  )

  const listChatMessages = useCallback(
    async ({
      roomGuid,
      presenterToken,
    }: {
      roomGuid: string
      presenterToken: string
    }) => {
      const req = new ListChatMessagesForPresenterRequest()
      req.setRoomGuid(roomGuid)
      const res = await callBff('listChatMessagesForPresenter', [
        req,
        { authorization: presenterToken },
      ])
      return res
    },
    []
  )

  const getParticipantName = useCallback(
    async ({
      participantGuid,
      presenterToken,
    }: {
      participantGuid: string
      presenterToken: string
    }) => {
      const req = new GetParticipantNameRequest()
      req.setParticipantGuid(participantGuid)
      const res = await callBff('getParticipantName', [
        req,
        { authorization: presenterToken },
      ])
      return res
    },
    []
  )

  const listHalls = useCallback(async () => {
    const req = new ListHallsRequest()
    const res = await callBff('listHalls', [req, metadata])
    const halls: ListHallsHall[] = res.getHallsList().map((hall) => ({
      ...hall.toObject(),
      startTime: hall.getStartTime()?.toDate(),
      finishTime: hall.getFinishTime()?.toDate(),
      scheduledAt: hall.getScheduledAt()?.toDate(),
    }))
    return halls
  }, [metadata])

  const getInterviewsCount = useCallback(async () => {
    const req = new GetInterviewsCountRequest()
    const res = await callBff('getInterviewsCount', [req, metadata])
    const count: InterviewsCount = {
      ...res.toObject(),
      prevRecordsList: res.getPrevRecordsList().map((record) => ({
        targetMonth: record.getTargetMonth()!.toDate(),
        count: record.getCount()?.getValue(),
      })),
      contractPeriod: res.getContractPeriod()!.toObject(),
    }
    return count
  }, [metadata])

  const getHallsCount = useCallback(async () => {
    const req = new GetHallsCountRequest()
    const res = await callBff('getHallsCount', [req, metadata])
    const count: HallsCount = {
      ...res.toObject(),
      prevRecordsList: res.getPrevRecordsList().map((record) => ({
        targetMonth: record.getTargetMonth()!.toDate(),
        count: record.getCount()?.getValue(),
      })),
      contractPeriod: res.getContractPeriod()!.toObject(),
    }
    return count
  }, [metadata])

  const getHallParticipantsCount = useCallback(
    async ({ hallGuid }: { hallGuid: string }) => {
      const req = new GetHallParticipantsCountRequest()
      req.setHallGuid(hallGuid)
      const res = await callBff('getHallParticipantsCount', [req, metadata])

      return res.getParticipantsCount()
    },
    [metadata]
  )

  const listInterviews = useCallback(async () => {
    const req = new ListInterviewsRequest()
    const res = await callBff('listInterviews', [req, metadata])
    const interviews: ListInterviewsInterview[] = res
      .getInterviewsList()
      .map((interview) => ({
        ...interview.toObject(),
        createdAt: interview.getCreatedAt()!.toDate(),
        expiresAt: interview.getExpiresAt()!.toDate(),
        startedAt: interview.getStartedAt()?.toDate(),
        finishedAt: interview.getFinishedAt()?.toDate(),
        recordingUrl: interview.getRecordingUrl() || undefined,
      }))
    return interviews
  }, [metadata])

  const getPresenterInterview = useCallback(
    async ({ interviewGuid }: { interviewGuid: string }) => {
      const req = new GetPresenterInterviewRequest()
      req.setGuid(interviewGuid)
      const res = await callBff('getPresenterInterview', [req, metadata])
      return res
    },
    [metadata]
  )

  const createInterviewSession = useCallback(async () => {
    const req = new CreateInterviewSessionRequest()
    const res = await callBff('createInterviewSession', [req])
    return res
  }, [])

  const startInterview = useCallback(
    async ({ interviewGuid }: { interviewGuid: string }) => {
      const req = new StartInterviewRequest()
      req.setInterviewGuid(interviewGuid)
      const res = await callBff('startInterview', [req, metadata])
      return res
    },
    [metadata]
  )

  const finishInterview = useCallback(
    async ({ interviewGuid }: { interviewGuid: string }) => {
      const req = new FinishInterviewRequest()
      req.setInterviewGuid(interviewGuid)
      const res = await callBff('finishInterview', [req, metadata])
      return res
    },
    [metadata]
  )

  const exportInterviewsInMonthCSV = useCallback(
    async (month: Date) => {
      const req = new ExportInterviewsInMonthCSVRequest()
      const date = new GrpcDate()
      date.setYear(month.getFullYear())
      // MEMO: proto の month は 1 ~ 12, JavaScript の Date の month は 0 ~ 11 なので合わせる
      date.setMonth(month.getMonth() + 1)
      req.setMonth(date)
      const res = await callBff('exportInterviewsInMonthCSV', [req, metadata])
      return res.getCsv_asU8()
    },
    [metadata]
  )

  const exportParticipantsCSV = useCallback(
    async ({ hallGuid }: { hallGuid: string }) => {
      const req = new ExportParticipantsCSVRequest()
      req.setHallGuid(hallGuid)
      const res = await callBff('exportParticipantsCSV', [req, metadata])
      return res.getCsv_asU8()
    },
    [metadata]
  )

  const exportChatMessagesForHallCSV = useCallback(
    async ({ hallGuid }: { hallGuid: string }) => {
      const req = new ExportChatMessagesForHallCSVRequest()
      req.setHallGuid(hallGuid)
      const res = await callBff('exportChatMessagesForHallCSV', [req, metadata])
      return res.getCsv_asU8()
    },
    [metadata]
  )

  const createDemoInvitation = useCallback(async () => {
    const req = new CreateDemoInvitationRequest()
    const res = await callBff('createDemoInvitation', [req])
    return res
  }, [])

  const deleteDemoInvitation = useCallback(
    async ({ sessionToken }: { sessionToken: string }) => {
      const req = new DeleteDemoSessionTokenRequest()
      req.setSessionToken(sessionToken)
      const res = await callBff('deleteDemoSessionToken', [req])
      return res
    },
    []
  )

  const restartSession = useCallback(
    async ({
      hallGuid,
      presenterToken,
    }: {
      hallGuid: string
      presenterToken: string
    }) => {
      const req = new RestartSessionRequest()
      req.setHallGuid(hallGuid)
      const res = await callBff('restartSession', [
        req,
        { authorization: presenterToken },
      ])
      return res
    },
    []
  )

  const getHallJoinedCount = useCallback(
    async (hallGuid: string) => {
      const req = new GetHallJoinedCountRequest()
      req.setHallGuid(hallGuid)
      const res = await callBff('getHallJoinedCount', [req, metadata])

      return res
    },
    [metadata]
  )

  const shortenUrl = useCallback(
    async (url: string) => {
      const req = new ShortenUrlRequest()
      req.setUrl(url)
      const res = await callBff('shortenUrl', [req, metadata])
      return res
    },
    [metadata]
  )

  const listChatMessagesForInterview = useCallback(
    async (interviewGuid: string) => {
      const req = new ListChatMessagesForInterviewRequest()
      req.setInterviewGuid(interviewGuid)
      const res = await callBff('listChatMessagesForInterview', [req])
      return res
    },
    []
  )

  const getInterviewRecording = useCallback(async (token: string) => {
    const req = new GetInterviewRecordingRequest()
    req.setJwt(token)
    const res = await callBff('getInterviewRecording', [req])
    return res
  }, [])

  const getHallRecording = useCallback(async (token: string) => {
    const req = new GetHallRecordingRequest()
    req.setJwt(token)
    const res = await callBff('getHallRecording', [req])
    return res
  }, [])

  const createPresentation = useCallback(
    async (name: string) => {
      const req = new CreatePresentationRequest()
      req.setPresentationName(name)
      await callBff('createPresentation', [req, metadata])
    },
    [metadata]
  )

  const listPresentations = useCallback(async () => {
    const req = new ListPresentationsRequest()
    const res = await callBff('listPresentations', [req, metadata])
    const presentations: ListPresentationsPresentation[] = res
      .getPresentationsList()
      .map((presentation) => ({
        ...presentation.toObject(),
        startTime: presentation.getStartTime()?.toDate(),
      }))
    return presentations
  }, [metadata])

  const getDownloadUrlForPresentation = useCallback(
    async (guid: string) => {
      const req = new GetDownloadUrlForPresentationRequest()
      req.setPresentationGuid(guid)
      const res = await callBff('getDownloadUrlForPresentation', [
        req,
        metadata,
      ])
      return res.getUrl()
    },
    [metadata]
  )

  const getPresentation = useCallback(
    async (guid: string) => {
      const req = new GetPresentationRequest()
      req.setPresentationGuid(guid)
      const res = await callBff('getPresentation', [req, metadata])
      return res
    },
    [metadata]
  )

  const setupPresentation = useCallback(
    async (guid: string) => {
      const req = new SetupPresentationRequest()
      req.setPresentationGuid(guid)
      const res = await callBff('setupPresentation', [req, metadata])
      return res
    },
    [metadata]
  )

  const startPresentation = useCallback(
    async (guid: string) => {
      const req = new StartPresentationRequest()
      req.setPresentationGuid(guid)
      const res = await callBff('startPresentation', [req, metadata])
      return res
    },
    [metadata]
  )

  const restartPresentation = useCallback(
    async (guid: string) => {
      const req = new RestartPresentationRequest()
      req.setPresentationGuid(guid)
      const res = await callBff('restartPresentation', [req, metadata])
      return res
    },
    [metadata]
  )

  const getPresentationStatus = useCallback(
    async (guid: string) => {
      const req = new GetPresentationStatusRequest()
      req.setPresentationGuid(guid)
      const res = await callBff('getPresentationStatus', [req, metadata])
      return res
    },
    [metadata]
  )

  const finishPresentation = useCallback(
    async (guid: string) => {
      const req = new FinishPresentationRequest()
      req.setPresentationGuid(guid)
      const res = await callBff('finishPresentation', [req, metadata])
      return res
    },
    [metadata]
  )

  const startScreenSharingForPresentation = useCallback(
    async (guid: string) => {
      const req = new StartScreenSharingForPresentationRequest()
      req.setPresentationGuid(guid)
      const res = await callBff('startScreenSharingForPresentation', [
        req,
        metadata,
      ])
      return res
    },
    [metadata]
  )

  const finishScreenSharingForPresentation = useCallback(
    async (guid: string) => {
      const req = new FinishScreenSharingForPresentationRequest()
      req.setPresentationGuid(guid)
      const res = await callBff('finishScreenSharingForPresentation', [
        req,
        metadata,
      ])
      return res
    },
    [metadata]
  )

  const deletePresentations = useCallback(
    async (guids: string[]) => {
      const req = new DeletePresentationsRequest()
      req.setPresentationGuidsList(guids)
      const res = await callBff('deletePresentations', [req, metadata])
      return res
    },
    [metadata]
  )

  const updatePresentationName = useCallback(
    async ({
      presentationGuid,
      name,
    }: {
      presentationGuid: string
      name: string
    }) => {
      const req = new UpdatePresentationNameRequest()
      req.setPresentationGuid(presentationGuid)
      req.setName(name)
      await callBff('updatePresentationName', [req, metadata])
    },
    [metadata]
  )

  const checkStartedPolling = useCallback(
    async (guid: string) => {
      const req = new CheckStartedPollingRequest()
      req.setPresentationGuid(guid)
      const res = await callBff('checkStartedPolling', [req, metadata])
      return res
    },
    [metadata]
  )

  const checkStartedPollingForScreenSharing = useCallback(
    async (guid: string) => {
      const req = new CheckStartedPollingForScreenSharingRequest()
      req.setPresentationGuid(guid)
      const res = await callBff('checkStartedPollingForScreenSharing', [
        req,
        metadata,
      ])
      return res
    },
    [metadata]
  )

  const deleteHalls = useCallback(
    async (guids: string[]) => {
      const req = new DeleteHallsRequest()
      req.setHallGuidsList(guids)
      const res = await callBff('deleteHalls', [req, metadata])
      return res
    },
    [metadata]
  )

  const changeInterviewQuality = useCallback(
    async ({
      interviewGuid,
      mode,
    }: {
      interviewGuid: string
      mode: QualityMode
    }) => {
      const req = new ChangeInterviewQualityRequest()
      req.setInterviewGuid(interviewGuid)
      switch (mode) {
        case QualityModeNormal:
          req.setMode(InterviewQualityMode.NORMAL)
          break
        case QualityModeLow:
          req.setMode(InterviewQualityMode.LOW)
          break
        default:
          throw new Error(`Bad quality mode: ${mode}`)
      }
      const res = await callBff('changeInterviewQuality', [req])
      return res
    },
    []
  )

  const getInterviewQuality = useCallback(
    async ({ interviewGuid }: { interviewGuid: string }) => {
      const req = new GetInterviewQualityRequest()
      req.setInterviewGuid(interviewGuid)
      const res = await callBff('getInterviewQuality', [req])
      return res
    },
    []
  )

  const getSignalingPoints = useCallback(
    async ({ interviewGuid }: { interviewGuid: string }) => {
      const req = new GetSignalingPointsRequest()
      req.setInterviewGuid(interviewGuid)
      const res = await callBff('getSignalingPoints', [req])
      return res
    },
    []
  )
  const getInterviewSpotlight = useCallback(
    async ({ interviewGuid }: { interviewGuid: string }) => {
      const req = new GetInterviewSpotlightRequest()
      req.setInterviewGuid(interviewGuid)
      const res = await callBff('getInterviewSpotlight', [req])
      return res
    },
    []
  )
  const searchInterviews = useCallback(
    async ({ args }: { args: SearchInterviewsArgs }) => {
      const req = new SearchInterviewsRequest()
      req.setPage(args.page)
      const res = await callBff('searchInterviews', [req, metadata])
      const interviews: ListInterviewsInterview[] = res
        .getInterviewsList()
        .map((interview) => ({
          ...interview.toObject(),
          createdAt: interview.getCreatedAt()!.toDate(),
          expiresAt: interview.getExpiresAt()!.toDate(),
          startedAt: interview.getStartedAt()?.toDate(),
          finishedAt: interview.getFinishedAt()?.toDate(),
          recordingUrl: interview.getRecordingUrl() || undefined,
        }))
      const totalLength = res.getTotalLength()
      return {
        totalLength,
        interviews,
      }
    },
    [metadata]
  )

  const createInterviewsForKaburiku = useCallback(
    async ({ args }: { args: CreateInterviewsForKaburikuArgs }) => {
      const req = new CreateInterviewsForKaburikuRequest()
      req.setCount(args.count)
      const res = await callBff('createInterviewsForKaburiku', [req, metadata])
      const interviews: CreateInterviewsForKaburikuInterview[] = res
        .getInterviewsList()
        .map((interview) => ({
          ...interview.toObject(),
          guid: interview.getGuid(),
          interviewerUrl: interview.getInterviewerUrl(),
          intervieweeUrl: interview.getIntervieweeUrl(),
          expiresAt: interview.getExpiresAt()!.toDate(),
        }))
      const totalLength = res.getTotalLength()
      const csv = res.getInterviewsCsv_asU8()
      return {
        totalLength,
        interviews,
        csv,
      }
    },
    [metadata]
  )

  return {
    getOrganizer,
    signupAsOrganizer,
    getPromotion,
    getPromotionForRikunabiPackage,
    registerEmailForRikunabiPackage,
    verifyTokenForRikunabiPackage,
    applyForRikunabiPackage,
    getPermissions,
    updatePlan,
    createHall,
    createDemoHall,
    updateHall,
    createInterview,
    deleteInterviews,
    updateInterviewName,
    enterHall,
    enterDemoHall,
    startScreenSharing,
    finishScreenSharing,
    startSession,
    finishSession,
    listChatMessages,
    getParticipantName,
    listHalls,
    getInterviewsCount,
    getHallsCount,
    getHallParticipantsCount,
    setupSession,
    getSessionStatus,
    listInterviews,
    getPresenterInterview,
    createInterviewSession,
    startInterview,
    finishInterview,
    exportInterviewsInMonthCSV,
    exportParticipantsCSV,
    exportChatMessagesForHallCSV,
    createDemoInvitation,
    deleteDemoInvitation,
    restartSession,
    getHallJoinedCount,
    shortenUrl,
    listChatMessagesForInterview,
    getInterviewRecording,
    getHallRecording,
    createPresentation,
    listPresentations,
    getDownloadUrlForPresentation,
    getPresentation,
    setupPresentation,
    startPresentation,
    restartPresentation,
    getPresentationStatus,
    finishPresentation,
    startScreenSharingForPresentation,
    finishScreenSharingForPresentation,
    deletePresentations,
    updatePresentationName,
    checkStartedPolling,
    checkStartedPollingForScreenSharing,
    deleteHalls,
    changeInterviewQuality,
    getInterviewQuality,
    getSignalingPoints,
    getInterviewSpotlight,
    searchInterviews,
    createInterviewsForKaburiku,
  }
}
