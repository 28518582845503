import React from 'react'
import { Footer as _Footer } from '@/components/Footer'
import { Pagination } from '@blue-agency/rogue'
import styled from 'styled-components'
import { BulkAction } from './BulkAction'
import { InterviewsContainer } from '@/pages/OrganizerRoutes/MyRoutes/InterviewsRoutes/InterviewsPage/hooks/useInterviews'

export const Footer: React.FCX = ({ className }) => {
  const { count, setPage } = InterviewsContainer.useContainer()
  return (
    <Wrapper className={className}>
      <BulkAction />
      <Pagination count={count} onChange={setPage} />
    </Wrapper>
  )
}

const Wrapper = styled(_Footer)`
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  height: 60px;
`
