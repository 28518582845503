import React, { useMemo } from 'react'
import styled from 'styled-components'
import {
  Txt,
  PrimaryButton,
  theme,
  WeakColorSeparator,
  iconMap,
} from '@blue-agency/rogue'
import { InterviewContainer } from '../../hooks/useInterview'
import { WindowSizeContainer } from '@/containers/WindowSizeContainer'
import { isPc } from '@/utils'
import { ManualForSp as _ManualForSp } from './ManualForSp'
import { ManualForPc } from './ManualForPc'
import { StepContainer } from '../hooks/useStep'

export type ManualRowType = {
  iconName: keyof typeof iconMap
  title: string
  description: string
}

const manualRows: ManualRowType[] = [
  {
    iconName: 'chat',
    title: 'チャット機能',
    description: `参加者全員でチャット可能です。
音声/映像のトラブル時にもご利用いただけます。`,
  },
  {
    iconName: 'reload',
    title: '再読込 / リロード',
    description: `音声/映像が不安定な場合にお試しください。
接続状況が改善する場合があります。`,
  },
  {
    iconName: 'earphone',
    title: 'イヤホンの再接続',
    description: `「音声が聞こえない」ときは、イヤホンを再接続してみましょう。
接続状況が改善され、聞こえるようになる場合があります。`,
  },
]

export const Tutorial: React.FCX = ({ className }) => {
  const { organizerName } = InterviewContainer.useContainer()
  const { toWaitingRoom } = StepContainer.useContainer()
  const { width } = WindowSizeContainer.useContainer()

  const isSp = useMemo(() => {
    return !isPc(width)
  }, [width])

  return (
    <Wrapper className={className}>
      <TitleTxt size="l">{organizerName}</TitleTxt>
      <MainTxt>接続前に、使い方を確認しておきましょう。</MainTxt>
      {isSp && <Separator />}
      {isSp ? (
        <ManualForSp manualRows={manualRows} />
      ) : (
        <ManualForPc manualRows={manualRows} />
      )}
      <FixedFooter>
        <NextButton
          text="待機画面に進む"
          size="ll"
          onClick={toWaitingRoom}
          comlinkPushParams={{ action: 'to_waiting_room' }}
        />
      </FixedFooter>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
  width: 100%;
  max-width: 660px;
`
const TitleTxt = styled(Txt)`
  ${Wrapper} & {
    margin-top: 20px;
  }
`
const MainTxt = styled(Txt)`
  ${Wrapper} & {
    margin-top: 20px;
  }
`
const Separator = styled(WeakColorSeparator)`
  margin: 16px 0 25px;
`
const FixedFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: ${theme.color.white[1]};
  border-top: 1px solid ${theme.color.gray[4]};
  padding: 12px 20px;
`

const NextButton = styled(PrimaryButton)`
  height: 44px;
`

const ManualForSp = styled(_ManualForSp)`
  /* NOTE: 下部固定ボタンに隠れて「イヤホンの再接続」が見えなくなっていた */
  margin-bottom: 80px;
`
