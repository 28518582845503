import React, { useCallback, useRef } from 'react'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import {
  CheckBox,
  NewTabLink,
  TertiaryButton,
  theme,
  toast,
} from '@blue-agency/rogue'
import { TableBase } from '@/components/TableBase'
import { handleCopyUrlClick } from '@/utils'
import { formatDate } from '@/services/dateService'
import { useParams } from 'react-router-dom'
import { HallListItem, HallsContainer } from './hooks/HallsContainer'
import { Menu } from './Menu'
import { MenuPlace } from '@/components/Dropdown'
import { getParticipantsUrl } from './url'
import { getRelativePath } from '@/services/urlService'
import { useModal } from '@/hooks/useModal'
import { UpdateScheduleModal } from '@/pages/OrganizerRoutes/MyRoutes/HallsRoutes/HallsPage/UpdateScheduleModal'

type Property =
  | 'select'
  | 'name'
  | 'scheduledParticipantsNumber'
  | 'scheduledAt'
  | 'presenterUrl'
  | 'participantsUrl'
  | 'recording'
  | 'menu'
  | 'startedAt'
  | 'padding'
type TableCellProps = {
  property: Property
  hover?: boolean
}

const width = {
  select: 50,
  name: 270,
  scheduledParticipantsNumber: 100,
  scheduledAt: 110,
  presenterUrl: 110,
  participantsUrl: 110,
  recording: 110,
  startedAt: 110,
  menu: 50,
}

const hostname = process.env.REACT_APP_FRONT_HOST
if (!hostname) throw new Error('hostname not found')

export const Content: React.FC = () => {
  const { halls } = HallsContainer.useContainer()

  return (
    <Table hover>
      <TableHead>
        <TableRowWrapper>
          <Th property="select" />
          <Th property="name">Web説明会名</Th>
          <Th property="scheduledParticipantsNumber">参加予定人数</Th>
          <Th property="scheduledAt">実施予定日時</Th>
          <Th property="presenterUrl">発表者URL</Th>
          <Th property="participantsUrl">参加者URL</Th>
          <Th property="recording">録画データ</Th>
          <Th property="startedAt">開始日時</Th>
          <Th property="menu" />
          <Th property="padding" />
        </TableRowWrapper>
      </TableHead>
      <TableBody>
        {halls.map((hall, index) => {
          const menuPlace = getMenuPlace(index, halls.length)
          return <TableBodyRow key={index} hall={hall} menuPlace={menuPlace} />
        })}
      </TableBody>
    </Table>
  )
}

export const getMenuPlace = (index: number, hallsLength: number) => {
  // NOTE: 上4件はtopに表示するとテーブルヘッダーで見切れてしまうのでbottomに表示する
  if (index < 4) return 'bottom'
  // NOTE: 下4件はbottomに表示するとテーブルフッターで見切れてしまうのでtopに表示する
  return hallsLength - 4 <= index ? 'top' : 'bottom'
}

const TableBodyRow: React.FCX<{
  hall: HallListItem
  menuPlace: MenuPlace
}> = ({ hall, menuPlace }) => {
  const { selectHall, unselectHall } = HallsContainer.useContainer()
  const tableRowRef = useRef<HTMLTableRowElement>(null)

  const { token } = useParams<{ token?: string }>()
  if (!token) throw new Error('token not found')

  const isFinished = !!hall.finishTime
  const startTime = hall.startTime
  const isSelected = hall.isSelected

  const toggleCheck = useCallback(() => {
    if (isSelected) {
      unselectHall(hall)
    } else {
      selectHall(hall)
    }
  }, [isSelected, hall, unselectHall, selectHall])

  const handlePresenterUrlCopy = useCallback(() => {
    const url = window.location.origin + getRelativePath(hall.presenterUrl)
    handleCopyUrlClick(url)
    toast('発表者URLをコピーしました。')
  }, [hall])

  const handleParticipantsUrlCopy = useCallback(() => {
    const url = getParticipantsUrl(hall)
    handleCopyUrlClick(url)
    toast('参加者URLをコピーしました。')
  }, [hall])

  const updateScheduleModal = useModal()

  return (
    <>
      <TableRowWrapper ref={tableRowRef} isFinished={isFinished}>
        <Td property="select">
          <CheckBox
            checked={hall.isSelected}
            onChange={toggleCheck}
            size={16}
          />
        </Td>
        <Td property="name" onClick={updateScheduleModal.open}>
          {hall.sessionName}
        </Td>
        <Td property="scheduledParticipantsNumber">
          {hall.scheduledParticipantsNumber &&
          // 現状 scheduledParticipantsNumber=null(人数入力がなかった頃に作成されたweb説明会)は bffからはnumber=0で返却される
          // 値の性質上、0以下の値が入ることはないので、一旦>0で値の有無判定をしている
          hall.scheduledParticipantsNumber > 0
            ? hall.scheduledParticipantsNumber + '人'
            : ''}
        </Td>
        <Td property="scheduledAt">
          {hall.scheduledAt ? formatDate(hall.scheduledAt) : ''}
        </Td>
        <Td property="presenterUrl">
          <TertiaryButton
            text="URLコピー"
            size="m1"
            disabled={isFinished}
            onClick={handlePresenterUrlCopy}
            comlinkPushParams={{ action: 'copy_presenter_url' }}
          />
        </Td>
        <Td property="participantsUrl">
          <TertiaryButton
            text="URLコピー"
            size="m1"
            disabled={isFinished}
            onClick={handleParticipantsUrlCopy}
            comlinkPushParams={{ action: 'copy_participant_url' }}
          />
        </Td>
        <Td property="recording">
          {!!hall.recordingUrl && (
            <NewTabLink
              href={getRelativePath(hall.recordingUrl)}
              hideIcon
              action="open_hall_recording_page"
            >
              <TertiaryButton
                text="再生"
                size="m2"
                comlinkPushParams={{
                  action: 'click_open_hall_recording_button',
                }}
              />
            </NewTabLink>
          )}
        </Td>
        <Td property="startedAt">{startTime ? formatDate(startTime) : ''}</Td>
        <Td property="menu">
          <Menu
            tableRowRef={tableRowRef}
            hall={hall}
            token={token}
            place={menuPlace}
          />
        </Td>
        <Td property="padding" />
      </TableRowWrapper>
      {updateScheduleModal.active && (
        <UpdateScheduleModal
          hallGuid={hall.guid}
          currentName={hall.sessionName}
          currentScheduleAt={hall.scheduledAt}
          currentScheduledParticipantsNumber={
            hall.scheduledParticipantsNumber &&
            hall.scheduledParticipantsNumber > 0
              ? hall.scheduledParticipantsNumber
              : undefined
          }
          onClose={updateScheduleModal.close}
          onOpen={updateScheduleModal.open}
        />
      )}
    </>
  )
}

const Table = styled(TableBase)`
  th:last-of-type {
    justify-content: flex-start;
    padding-left: 30px;
  }
  width: 100%;
  min-width: ${Object.values(width).reduce((acc, val) => acc + val)}px;
`

const TableHead = styled.thead`
  position: sticky;
  top: 0px;
  z-index: 100;
`

const TableBody = styled.tbody``

const TableRowWrapper = styled.tr<{ isFinished?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;

  ${({ isFinished }) =>
    isFinished &&
    css`
      background: ${theme.color.gray[4]};

      & > * {
        background: ${theme.color.gray[4]};
      }
    `}
`

const CSS: Record<Property, FlattenSimpleInterpolation> = {
  select: css`
    display: flex;
    justify-content: center;
    flex: 0 0 ${width.select}px;
  `,
  name: css`
    flex: 0 0 ${width.name}px;

    ${TableBody} && {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  `,
  scheduledParticipantsNumber: css`
    justify-content: center;
    flex: 0 0 ${width.scheduledParticipantsNumber}px;
  `,
  scheduledAt: css`
    flex: 0 0 ${width.scheduledAt}px;
  `,
  presenterUrl: css`
    flex: 0 0 ${width.presenterUrl}px;
  `,
  participantsUrl: css`
    flex: 0 0 ${width.participantsUrl}px;
  `,
  recording: css`
    flex: 0 0 ${width.recording}px;
  `,
  menu: css`
    flex: 0 0 ${width.menu}px;
  `,
  startedAt: css`
    flex: 0 0 ${width.startedAt}px;
  `,
  padding: css`
    flex: 1 0 auto;
  `,
}

const Th = styled.th<TableCellProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ property }) => CSS[property]}
`
const Td = styled.td<TableCellProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${({ property }) => CSS[property]}
  ${({ hover }) =>
    hover &&
    css`
      &:hover {
        background: ${theme.color.gray[4]};
      }
    `}
`
