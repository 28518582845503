import { useEffect } from 'react'
import { useMountedState } from 'react-use'
import { DeviceChangeObserver } from 'amazon-chime-sdk-js'

import { useRecoilState } from 'recoil'
import {
  videoInputsState,
  selectedVideoInputDeviceState,
} from '@/lib/react-interview-sdk/states'
import { MeetingManagerContainer } from '@/lib/react-interview-sdk/containers/MeetingManagerContainer'
import { AudioVideoContainer } from '@/lib/react-interview-sdk/containers/AudioVideoContainer'

export const useVideoInput = () => {
  const { meetingManager } = MeetingManagerContainer.useContainer()
  const { audioVideo } = AudioVideoContainer.useContainer()

  const [videoInputs, setVideoInputs] = useRecoilState(videoInputsState)
  const [selectedVideoInputDevice, setSelectedVideoInputDevice] =
    useRecoilState(selectedVideoInputDeviceState)

  useEffect(() => {
    if (!meetingManager) return
    setSelectedVideoInputDevice(meetingManager.selectedVideoInputDevice)
  }, [meetingManager, setSelectedVideoInputDevice])

  useEffect(() => {
    if (!meetingManager) return
    const callback = (updatedVideoInputDevice: string | null): void => {
      setSelectedVideoInputDevice(updatedVideoInputDevice)
    }

    meetingManager.subscribeToSelectedVideoInputDevice(callback)

    return (): void => {
      meetingManager.unsubscribeFromSelectedVideoInputDevice(callback)
    }
  }, [
    meetingManager,
    videoInputs,
    selectedVideoInputDevice,
    setSelectedVideoInputDevice,
  ])

  const isMounted = useMountedState()
  useEffect(() => {
    const observer: DeviceChangeObserver = {
      videoInputsChanged: (newVideoInputs: MediaDeviceInfo[]) => {
        setVideoInputs(newVideoInputs)
      },
    }

    async function initVideoInput() {
      if (!audioVideo) {
        return
      }
      if (!isMounted()) {
        return
      }

      const devices = await audioVideo.listVideoInputDevices()

      setVideoInputs(devices)
      audioVideo.addDeviceChangeObserver(observer)
    }

    initVideoInput()

    return () => {
      audioVideo?.removeDeviceChangeObserver(observer)
    }
  }, [meetingManager, audioVideo, setVideoInputs, isMounted])
}
