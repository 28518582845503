import { createContainer } from '@blue-agency/front-state-management'
import { useParams } from 'react-router-dom'
import { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import {
  getCameraOffTrackForParticipants,
  getCameraOffTrackForPresenter,
} from './getCameraOffTrack'
import { useSora } from './useSora'
import { RecordingContainer } from '../RecordingContainer'
import { usePrevious } from '@/hooks/usePrevious'
import {
  buildErrorMessage,
  useSpecificMediaDevice,
} from '@blue-agency/react-media-devices'

const logRTCStatsEndpoint = process.env.REACT_APP_LOG_RTC_STATS_ENDPOINT
if (!logRTCStatsEndpoint) throw new Error('logRTCStatsEndpoint not found')

const usePresenterVideo = () => {
  const { setIsPresenterPublished, presenterSora } =
    RecordingContainer.useContainer()

  const prevPresenterSora = usePrevious(presenterSora)
  const updatedPresenterSora = useMemo(() => {
    return presenterSora !== prevPresenterSora
  }, [presenterSora, prevPresenterSora])

  const { selectedDeviceId: selectedCameraDeviceId, error: deviceError } =
    useSpecificMediaDevice('videoinput')
  useEffect(() => {
    if (deviceError) {
      alert(buildErrorMessage(deviceError))
    }
  }, [deviceError])

  const [muted, setMuted] = useState(false)
  const [isCameraOff, setIsCameraOff] = useState(false)

  const { presentationGuid } = useParams<{
    presentationGuid?: string
  }>()
  if (!presentationGuid) throw new Error('presentationGuid not found')

  const [userMediaStream, setUserMediaStream] = useState<MediaStream>()
  const [presenterVideoStream, setPresenterVideoStream] =
    useState<MediaStream>()

  const cameraOffTrackForPresenter = useMemo<MediaStreamTrack>(
    getCameraOffTrackForPresenter,
    []
  )
  const cameraOffTrackForParticipants = useMemo<MediaStreamTrack>(
    getCameraOffTrackForParticipants,
    []
  )
  const isConnectedRef = useRef(false)

  const presenterVideoRef = useCallback(
    (element: HTMLVideoElement | null) => {
      if (!element) return
      element.srcObject = presenterVideoStream || null
    },
    [presenterVideoStream]
  )

  const { connect, disconnect, replaceTrack } = useSora(
    presentationGuid,
    logRTCStatsEndpoint
  )

  useEffect(() => {
    if (!presenterSora) return
    if (selectedCameraDeviceId === undefined) return

    // MEMO: selectedCameraDeviceId が変更しただけの場合は、以降に connect 処理があるので
    //   ここでストップさせる
    if (isConnectedRef.current && !updatedPresenterSora) {
      return
    }

    ;(async () => {
      setIsPresenterPublished(false)
      await disconnect()
      isConnectedRef.current = false

      const constraints = {
        video: {
          facingMode: 'user',
          width: 1280,
          height: 720,
          deviceId: { exact: selectedCameraDeviceId },
        },
        audio: true,
      }
      if (
        navigator.mediaDevices === undefined ||
        navigator.mediaDevices.getUserMedia === undefined
      ) {
        alert(
          '利用できるカメラが見つかりません、本体設定や端末を変えて再度お試しください。'
        )
        return
      }
      const userMediaStream = await navigator.mediaDevices
        .getUserMedia(constraints)
        .catch(() => {
          alert(
            '利用できるカメラが見つかりません、本体設定や端末を変えて再度お試しください。'
          )
          return null
        })
      if (!userMediaStream) return

      if (presenterSora.channelId === '' || presenterSora.hostname === '') {
        return
      }
      await connect({
        hostname: presenterSora.hostname,
        channelId: presenterSora.channelId,
        userMediaStream,
      })

      const presenterVideoStream = new MediaStream()

      if (isCameraOff) {
        presenterVideoStream.addTrack(cameraOffTrackForPresenter)
        try {
          replaceTrack(cameraOffTrackForParticipants)
        } catch {
          alert('カメラオフに失敗しました')
        }
      } else {
        const userVideoTrack = userMediaStream.getVideoTracks()[0]
        if (!userVideoTrack) throw new Error('Not found videoTrack')
        presenterVideoStream.addTrack(userVideoTrack)
      }

      if (muted) {
        const audioTrack = userMediaStream.getAudioTracks()[0]
        if (!audioTrack) throw new Error('Not found audioTrack')
        audioTrack.enabled = false
      }

      isConnectedRef.current = true
      setUserMediaStream(userMediaStream)
      setPresenterVideoStream(presenterVideoStream)
      setIsPresenterPublished(true)
    })()
    /**
     * TODO
     * 本来はupdatedPresenterSoraをdepsに含めたいが、カメラオフが効かなくなる事があるので含めていない
     * updatedPresenterSoraをdepsに含めても問題ないようにする
     * ref: https://github.com/blue-agency/yashiori-front/pull/2002
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presenterSora, selectedCameraDeviceId])

  useEffect(() => {
    return () => {
      disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!userMediaStream) return
    const audioTrack = userMediaStream.getAudioTracks()[0]
    if (!audioTrack) return
    audioTrack.enabled = !muted
  }, [muted, userMediaStream])

  const toggleMuted = useCallback(() => {
    setMuted((prev) => !prev)
  }, [])

  const toggleCameraOff = useCallback(async () => {
    if (!userMediaStream) return

    const presenterVideoStream = new MediaStream()

    if (isCameraOff) {
      const userVideoTrack = userMediaStream.getVideoTracks()[0]
      try {
        presenterVideoStream.addTrack(userVideoTrack)
        replaceTrack(userVideoTrack)
      } catch {
        alert('カメラオフの解除に失敗しました')
        return
      }
    } else {
      presenterVideoStream.addTrack(cameraOffTrackForPresenter)
      try {
        replaceTrack(cameraOffTrackForParticipants)
      } catch {
        alert('カメラオフに失敗しました')
        return
      }
    }

    setPresenterVideoStream(presenterVideoStream)
    setIsCameraOff((prev) => !prev)
  }, [
    userMediaStream,
    isCameraOff,
    cameraOffTrackForParticipants,
    cameraOffTrackForPresenter,
    replaceTrack,
  ])

  return {
    presenterVideoRef,
    muted,
    toggleMuted,
    isCameraOff,
    toggleCameraOff,
  }
}

export const PresenterVideoContainer = createContainer(usePresenterVideo)
