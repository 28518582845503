import { useState } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { ImMeetingManager, LogLevel } from '@blue-agency/interview-sdk-js'

type Props = {
  logLevel?: LogLevel
}

const useMeetingManager = (initialState?: Props | undefined) => {
  const logLevel = initialState?.logLevel ?? LogLevel.DEBUG
  const [meetingManager] = useState(new ImMeetingManager({ logLevel }))

  return { meetingManager }
}

export const MeetingManagerContainer = createContainer(useMeetingManager)
