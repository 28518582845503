import React from 'react'
import styled from 'styled-components'
import { Icon, Txt } from '@blue-agency/rogue'
import { StepContainer } from '../containers/StepContainer'
import { TutorialStep as _TutorialStep } from '@/components/TutorialStep'
import { Dots as _Dots } from '@/components/Dots'
import firstImg from './first.png'
import { overflowYScrollStyle } from '@/styles/overflowYScrollStyle'

export const First: React.FCX = ({ className }) => {
  const { toNextStep } = StepContainer.useContainer()
  return (
    <Wrapper className={className}>
      <Pagination>
        <LeftIconWrapper>
          <LeftArrowIcon name="arrow" />
        </LeftIconWrapper>
        <TutorialStep current={1} max={2} />
        <StepTitleTxt size="l">配信中画面の説明</StepTitleTxt>
        <RightIconWrapper onClick={toNextStep}>
          <RightArrowIcon name="arrow" />
        </RightIconWrapper>
      </Pagination>
      <Image src={firstImg} />
      <Dots count={2} currentPage={1} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding-bottom: 40px;
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${overflowYScrollStyle};
`
const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 270px;
`
const TutorialStep = styled(_TutorialStep)`
  ${Pagination} & {
    margin-left: 28px;
  }
`
const StepTitleTxt = styled(Txt)`
  ${Pagination} & {
    margin-left: 6px;
  }
`
const LeftIconWrapper = styled.div``
const LeftArrowIcon = styled(Icon)``
const RightIconWrapper = styled.div`
  ${Pagination} & {
    margin-left: 28px;
  }
  cursor: pointer;
`
const RightArrowIcon = styled(Icon)`
  transform: rotate(180deg);
`
const Image = styled.img`
  ${Wrapper} & {
    margin-top: 20px;
  }
  width: 660px;
`
const Dots = styled(_Dots)`
  ${Wrapper} & {
    position: absolute;
    bottom: 10px;
  }
`
