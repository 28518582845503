import React from 'react'
import styled, { css } from 'styled-components'
import { theme, Txt, A } from '@blue-agency/rogue'
import reactStringReplace from 'react-string-replace'
import { SeminarMessageType } from '.'

type MessageType = {
  type: SeminarMessageType
  name: string
  text: string
}
type Props = {
  message: MessageType
  chatFontSize: keyof typeof theme.fontSize
  isPortrait: boolean
}

const regExp = /(https?:\/\/\S+)/g

export const SeminarMessage: React.FCX<Props> = ({
  className,
  message,
  chatFontSize,
  isPortrait,
}) => {
  return (
    <Message className={className} type={message.type} isPortrait={isPortrait}>
      <Txt size="s" color={theme.color.gray[3]}>
        {message.name}
      </Txt>
      <Txt size={chatFontSize}>
        <Span>
          {reactStringReplace(message.text, regExp, (match, i) => (
            <A
              key={i}
              href={match}
              target="_blank"
              rel="noopener noreferrer"
              comlinkPushParams={{ action: 'open_posted_url' }}
            >
              {match}
            </A>
          ))}
        </Span>
      </Txt>
    </Message>
  )
}

type MessageProps = {
  type: SeminarMessageType
  isPortrait: boolean
}
const Message = styled.div<MessageProps>`
  ${({ type }) => {
    switch (type) {
      case 'participant':
        return
      case 'presenter':
        return css`
          background: ${theme.color.yellow[3]};
        `
      case 'me':
        return css`
          background: ${theme.color.green[8]};
        `
      case 'notice':
        return css`
          background: ${theme.color.red[4]};
        `
    }
  }}
  padding: ${({ isPortrait }) => (isPortrait ? '8px 16px' : '8px')};
  border-bottom: 1px solid ${theme.color.gray[5]};
`

const Span = styled.span`
  word-break: break-all;
`
