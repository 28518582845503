import React from 'react'
import { PcFormLayout } from '@/components/FormLayout'
import { Layout } from '@/components/Layout'
import { ApplyPageContainer } from '../ApplyPageContainer'
import { Upper } from './Upper'
import { Body } from './Body'
import { Bottom } from './Bottom'

export const Input: React.FCX = ({ className }) => {
  const { handleSubmit, promotion } = ApplyPageContainer.useContainer()
  return (
    <Layout className={className} notFixHeight>
      <PcFormLayout
        className={className}
        title={`「${promotion.planName}」新規申込`}
        subTitle="基本情報"
        upper={<Upper />}
        body={<Body />}
        bottom={<Bottom />}
        onSubmit={handleSubmit}
      />
    </Layout>
  )
}
