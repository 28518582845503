import { YashioriBffServicePromiseClient } from '@blue-agency/proton/web/v2/yashiori_bff/yashiori_bff_service_grpc_web_pb'
import { logRpcErr } from './logRpcErr'
import { retry } from './retry'

const hostname = process.env.REACT_APP_API_HOST
if (!hostname) throw new Error('hostname not found')
const client = new YashioriBffServicePromiseClient(hostname)
if (process.env.REACT_APP_GRPC_WEB_DEVTOOLS_ENABLED === 'true') {
  // @ts-ignore
  const enableDevTools = window.__GRPCWEB_DEVTOOLS__ || (() => {})
  enableDevTools([client])
}

export function callBff<T extends keyof YashioriBffServicePromiseClient>(
  rpcName: T,
  req: Parameters<YashioriBffServicePromiseClient[T]>
): ReturnType<YashioriBffServicePromiseClient[T]> {
  // @ts-expect-error
  return retry(() =>
    // @ts-expect-error
    client[rpcName].apply(client, req).catch((e) => {
      logRpcErr(rpcName, e)
      throw e
    })
  )
}
