import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import {
  ErrorMessage,
  theme,
  Txt,
  NewTabLink,
  TertiaryButton,
} from '@blue-agency/rogue'
import { EXTERNAL_URLS } from '@/services/urlService'
import { BeepContainer, MyAudio } from '@/containers/BeepContainer'
import { beepSrc } from './beepSrc'

export const ConnectionFailedModal: React.FCX = ({ className }) => {
  const { beep } = BeepContainer.useContainer()
  const handleRef = useRef<number>()
  const [isBeepStopped, setIsBeepStopped] = useState(false)
  const beepAudio = useRef<MyAudio>()

  useEffect(() => {
    const handle = window.setInterval(() => {
      beepAudio.current = beep(beepSrc)
    }, 5000)
    handleRef.current = handle
    return () => clearInterval(handle)
  }, [beep])

  const stopBeep = useCallback(() => {
    beepAudio.current?.stop()
    handleRef.current && clearInterval(handleRef.current)
    setIsBeepStopped(true)
  }, [])

  return (
    <Wrapper className={className}>
      <ErrorMessage text="インターネットの接続が不安定なため、配信映像が正常に届いていない可能性があります" />
      <Txt size="s">
        <Bold>以下の手順に従って、復旧をお試しください</Bold>
        <br />
        ①インターネットに接続されているか確認
        <br />
        ②配信者側のブラウザを再読込
        <br />
        ③画面共有が必要な場合は、あらためて画面共有を開始
        <br />
        ④質問コーナーなどで参加者にもブラウザの再読込を依頼
        <br />
      </Txt>
      <Txt size="s">
        上記の手順で復旧しない場合は、別の端末や回線で配信いただくか
        <br />
        <NewTabLink
          action="open_support_desk_for_connection_failure_on_seminar"
          href={EXTERNAL_URLS.supportDesk.general}
        >
          サポートデスク
        </NewTabLink>
        までお問い合わせください。
      </Txt>
      <Button
        onClick={stopBeep}
        comlinkPushParams={{ action: 'stop_beep_on_ConnectionFailedModal' }}
        size="l2"
        disabled={isBeepStopped}
      >
        警告音を停止
      </Button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  width: 432px;
  height: 313px;
  margin: 0 auto;
  background-color: ${theme.color.white[1]};
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: ${theme.fontSize.m};
  /* 配信準備モーダルより前面に出している */
  z-index: 1;
`
const Bold = styled.span`
  font-weight: bold;
`
const Button = styled(TertiaryButton)`
  margin: 0 auto;
`
