import { useState, useRef, useCallback, useEffect } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import ResizeObserver from 'resize-observer-polyfill'

const alertboxClassName = 'alert-box-wrapper'

const useAlertboxHeight = () => {
  const [alertboxHeight, setAlertboxHeight] = useState<number>(0)
  const appearanceObserverRef = useRef<MutationObserver | undefined>(undefined)
  const resizeObserverRef = useRef<ResizeObserver | undefined>(undefined)

  const unregisterResizeObserver = useCallback(() => {
    resizeObserverRef.current?.disconnect()
  }, [])

  const registerResizeObserver = useCallback((alertboxEl: HTMLElement) => {
    const observer = new ResizeObserver((entries) => {
      const totalHeight = entries.reduce(
        (sum, entry) => entry.contentRect.height + sum,
        0
      )
      setAlertboxHeight(totalHeight)
    })
    observer.observe(alertboxEl)
    resizeObserverRef.current = observer
  }, [])

  const unregisterAppearanceObserver = useCallback(() => {
    appearanceObserverRef.current?.disconnect()
  }, [])

  const registerAppearanceObserver = useCallback(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        const alertboxEl = Array.from(mutation.addedNodes)
          .filter(
            (node): node is HTMLElement => node.nodeType === Node.ELEMENT_NODE
          )
          .find((el) => {
            return el.className === alertboxClassName
          })

        if (alertboxEl) {
          registerResizeObserver(alertboxEl)
          unregisterAppearanceObserver()
        }
      })
    })

    observer.observe(document.body, {
      childList: true,
    })

    appearanceObserverRef.current = observer
  }, [registerResizeObserver, unregisterAppearanceObserver])

  useEffect(() => {
    const alertboxEl = document.querySelector(`.${alertboxClassName}`)

    if (alertboxEl) {
      registerResizeObserver(alertboxEl as HTMLElement)
      setAlertboxHeight(alertboxEl.getBoundingClientRect().height)
    } else {
      registerAppearanceObserver()
    }

    return () => {
      unregisterAppearanceObserver()
      unregisterResizeObserver()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return alertboxHeight
}

export const AlertboxHeightContainer = createContainer(useAlertboxHeight)
