import { DropdownOption } from '@blue-agency/rogue'
import { useMemo } from 'react'

export function useDeviceOptions(
  devices: readonly MediaDeviceInfo[] | undefined
): DropdownOption[] {
  return useMemo<DropdownOption[]>(() => {
    const options = devices?.map<DropdownOption>((d) => ({
      label: d.label,
      value: d.deviceId,
    }))
    return options ?? []
  }, [devices])
}
