import React, { useMemo } from 'react'
import styled from 'styled-components'
import { ApplyPageContainer } from '../../ApplyPageContainer'
import { Txt, InputText } from '@blue-agency/rogue'
import { placeholder } from '@/constants/placeholder'
import { InputStepItem } from '../../types'
import { OrganizerFormItem } from '@/components/OrganizerFormItem'
import { Term } from '@blue-agency/proton/web/v2/yashiori_bff/plan_data_pb'

export const RikunabiSection: React.FCX = ({ className }) => {
  const { promotion, form } = ApplyPageContainer.useContainer()
  const term = useMemo(() => {
    switch (promotion.term) {
      case Term.RIKUNABI2022:
        return '2022'
      case Term.RIKUNABI2023:
        return '2023'
      case Term.RIKUNABI2023SPECIAL:
        return '2023'
      default:
        throw new Error('Invalid plan')
    }
  }, [promotion])
  const rikunabiItems: InputStepItem[] = [
    {
      label: 'リクナビ企業ID\n(r+9桁の数字)',
      name: 'rikunabiOrganizerId',
      placeholder: '例）r123456789',
      notes: '※貴社リクナビマネジメントシステムより、ご確認ください。',
    },
    {
      label: 'リクナビ掲載企業名',
      name: 'rikunabiOrganizerName',
      placeholder: placeholder.orgName,
      notes: `※リクナビ${term}に掲載予定の法人の正式社名を入力ください。\n※「法人格」（株式会社、合同会社など）もご入力をお願いします。㈱などの略称ではなく、正式名称でご入力ください。`,
    },
  ]
  return (
    <Wrapper className={className}>
      {rikunabiItems.map((item, index) => (
        <React.Fragment key={index}>
          <OrganizerFormItem
            title={index === 0 ? 'リクナビ情報' : undefined}
            label={item.label}
            required
            inputNode={
              <InputText
                name={item.name}
                size="l"
                placeholder={item.placeholder}
                ref={form.register}
                onBlurError={form.errors[item.name]?.message}
              />
            }
          />
          {item.notes && <Notes>{item.notes}</Notes>}
        </React.Fragment>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 20px;
`
const Notes = styled(Txt).attrs({ size: 's' })`
  margin-top: 8px;
  margin-left: 320px;
`
