import React from 'react'
import { Room } from './Room'
import { provide } from '@blue-agency/front-state-management'
import { InterviewContainer } from './hooks/useInterview'
import { ParticipantsServiceContainer } from '@/containers/ParticipantsServiceContainer'
import { Redirect } from 'react-router-dom'
import { INTERNAL_PATHS, fillParams } from '@/services/urlService'
import { GetParticipantsInterviewResponse } from '@blue-agency/proton/web/v2/yashiori_bff/yashiori_bff_service_pb'
import { Preparing } from './Preparing'
import { useTitle } from '@/hooks/useTitle'
import { BrowserContainer } from '@/containers/BrowserContainer'
import { UnsupportedBrowser } from './UnsupportedBrowser'
const { Status } = GetParticipantsInterviewResponse

export const RoomPage = provide(
  InterviewContainer,
  ParticipantsServiceContainer
)(() => {
  const {
    initialStatus,
    interviewGuid,
    isPreparing,
    organizerName,
    spotlight,
  } = InterviewContainer.useContainer()

  useTitle(organizerName)

  // スポットライト機能はFirefox非対応
  const { isFirefox } = BrowserContainer.useContainer()
  const unspportedBrowser = spotlight.enabled && isFirefox

  if (initialStatus === Status.EXPIRED) {
    return (
      <Redirect
        to={fillParams({
          path: INTERNAL_PATHS.participant.interviews.interview.expired,
          params: { interviewGuid },
        })}
      />
    )
  }
  if (initialStatus === Status.FINISHED)
    return (
      <Redirect
        to={fillParams({
          path: INTERNAL_PATHS.participant.interviews.interview.finish,
          params: { interviewGuid },
        })}
      />
    )
  if (unspportedBrowser) return <UnsupportedBrowser />
  return isPreparing ? <Preparing /> : <Room />
})
