import React from 'react'
import { PaidPlan } from './PaidPlan'
import { RikunabiPaidPlan } from './RikunabiPaidPlan'
import { RikunabiAdditionalService } from './RikunabiAdditionalService'
import { GetOrganizerResponse } from '@blue-agency/proton/web/v2/yashiori_bff/yashiori_bff_service_pb'
import { Rikunabi2023AdditionalService } from './Rikunabi2023AdditionalService'

const BannerEnum = GetOrganizerResponse.Banner

type Props = {
  banner: GetOrganizerResponse.Banner
  expiresAt: Date
  expired: boolean
}

export const Banner: React.FCX<Props> = (props) => {
  switch (props.banner) {
    case BannerEnum.PAID_PLAN:
      return <PaidPlan expired={props.expired} expiresAt={props.expiresAt} />
    case BannerEnum.RIKUNABI_PAID_PLAN:
      return <RikunabiPaidPlan />
    case BannerEnum.RIKUNABI_ADDITIONAL_SERVICE:
      return <RikunabiAdditionalService />
    case BannerEnum.RIKUNABI_2023_ADDITIONAL_SERVICE:
      return <Rikunabi2023AdditionalService />
    default:
      return null
  }
}
