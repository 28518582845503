import React from 'react'
import styled from 'styled-components'
import { theme, WeakColorSeparator } from '@blue-agency/rogue'
import { H1, H2 as _H2 } from '@/components/Heading'

type Props = {
  title: string
  subTitle?: string
  upper?: React.ReactNode
  body: React.ReactNode
  bottom?: React.ReactNode
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void
}

export const PcFormLayout: React.FCX<Props> = ({
  className,
  title,
  subTitle,
  upper,
  bottom,
  body,
  onSubmit,
}) => {
  return (
    <Wrapper className={className}>
      <Header>
        <H1>{title}</H1>
      </Header>
      <FormWrapper>
        {upper}
        <Form onSubmit={onSubmit}>
          {subTitle && (
            <SubHeader>
              <H2>{subTitle}</H2>
            </SubHeader>
          )}
          <Body>{body}</Body>
          <WeakColorSeparator />
          <Bottom>{bottom}</Bottom>
        </Form>
      </FormWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div``
const Header = styled.header`
  height: 60px;
  padding: 0 100px;
  background: ${theme.color.white[1]};
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const FormWrapper = styled.div`
  background: ${theme.color.white[1]};
  width: 960px;
  margin: 0 auto;
`
const Form = styled.form`
  min-height: calc(100vh - 142px);
  display: flex;
  flex-direction: column;
`
const SubHeader = styled.header`
  ${Form} & {
    flex: 0 0 auto;
  }
  height: 44px;
  background: ${theme.color.navy[1]};
  display: flex;
  align-items: center;
`
const H2 = styled(_H2)`
  ${SubHeader} & {
    margin-left: 20px;
  }
  color: ${theme.color.white[1]};
`
const Body = styled.div`
  background: ${theme.color.white[1]};
  ${Form} & {
    flex: 1 1 auto;
  }
`

const Bottom = styled.div`
  background: ${theme.color.white[1]};
  ${Form} & {
    flex: 0 0 auto;
  }
`
