import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { theme } from '@blue-agency/rogue'
import { DropdownItem } from './DropdownItem'

export type MenuItem = {
  text: string
  onClick: () => void
  disabled?: boolean
  action: string
}

type Props = {
  items: MenuItem[]
  className?: string
}

export const Dropdown = forwardRef<HTMLDivElement, Props>(
  ({ className, items }, ref) => {
    return (
      <Wrapper className={className} ref={ref}>
        {items.map((item) => (
          <DropdownItem key={item.action} {...item} />
        ))}
      </Wrapper>
    )
  }
)

const Wrapper = styled.div`
  width: 330px;
  background: ${theme.color.white[1]};
  border: 1px solid ${theme.color.gray[3]};
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
`
