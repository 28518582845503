import { useRecoilValue } from 'recoil'
import {
  ERR_INITIALIZE_MEDIA_DEVICES,
  ERR_MEETING_JOIN_FAILED,
  ERR_SCREEN_SHARING_BLOCKED_BY_OS,
  ERR_SCREEN_SHARING_PERMISSION_DENIED,
  interviewErrorState,
} from '@/lib/react-interview-sdk/states'

export const useInterviewErrors = () => {
  const initializeMediaDevicesError = useRecoilValue(
    interviewErrorState(ERR_INITIALIZE_MEDIA_DEVICES)
  )
  const meetingJoinFailedError = useRecoilValue(
    interviewErrorState(ERR_MEETING_JOIN_FAILED)
  )
  const screenSharingBlockedByOSError = useRecoilValue(
    interviewErrorState(ERR_SCREEN_SHARING_BLOCKED_BY_OS)
  )
  const screenSharingPermissionDenied = useRecoilValue(
    interviewErrorState(ERR_SCREEN_SHARING_PERMISSION_DENIED)
  )

  return {
    initializeMediaDevicesError,
    meetingJoinFailedError,
    screenSharingBlockedByOSError,
    screenSharingPermissionDenied,
  }
}
