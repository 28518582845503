import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { Message as _Message } from './Message'
import { theme, Txt } from '@blue-agency/rogue'
import { GetParticipantsInterviewResponse } from '@blue-agency/proton/web/v2/yashiori_bff/yashiori_bff_service_pb'
import { RoomContainer } from '../hooks/useRoom'
import { InterviewMainAreaLayout } from '@/components/InterviewMainAreaLayout'
import { DeviceType } from '@/services/interviewService/types/DeviceType'
import { VideoArea } from './VideoArea'
import { UsersArea } from './UsersArea'
import { ChatArea } from './ChatArea'
import { FixedControls } from '@/components/InterviewControls/FixedControls'
import { usePrevious } from '@/hooks/usePrevious'
import { toast } from '@blue-agency/rogue'
import { UnstableNavPopupContainer } from '../../hooks/UnstableNavPopupContainer'
import { StartScreenShareModal } from '@/components/StartScreenShareModal'
import { FinishScreenShareModal } from '@/components/FinishScreenShareModal'
import { ScreenSharingLimitModal } from '@/components/ScreenSharingLimitModal'
import { useInterviewLayout } from '../hooks/useInterviewLayout'
import { EconomyModeTxt } from '@/components/EconomyModeTxt'
import { QualityModeLow } from '@/services/interviewService/types/Quality'
import {
  useInterviewErrors,
  useScreenSharingSubscriberStatus,
  useUserPublishserStatus,
} from '@/lib/react-interview-sdk/hooks/values'

const { Status } = GetParticipantsInterviewResponse

type Props = {
  height: number
  width: number
  deviceType: DeviceType
}

export const MainArea: React.FCX<Props> = ({
  className,
  width,
  height,
  deviceType,
}) => {
  const {
    status,
    showUsers,
    showChat,
    chatParams,
    startScreenShareModal,
    finishScreenShareModal,
    screenSharingLimitModal,
    handleScreenShareStart,
    handleScreenShareFinish,
    quality,
  } = RoomContainer.useContainer()
  const unstableNavPopup = UnstableNavPopupContainer.useContainer()
  const { interviewControlsProps } = useInterviewLayout()

  const prevStatus = usePrevious(status)

  const userPublisherStatus = useUserPublishserStatus()
  const screenSharingSubscriberStatus = useScreenSharingSubscriberStatus()

  const errorMessage = useMemo(() => {
    if (userPublisherStatus.status === 'Error') {
      switch (userPublisherStatus.reason) {
        case 'AlreadyInvitedAsAnotherRole':
          return 'すでに面接官として入室済です。\n面接官用のURLから入り直してください。'
        case 'NumberOfParticipantsLimit':
          return '参加人数が上限を超えているため、参加することができませんでした。\n人事担当者様にお問い合わせください。'
        default:
          return '認証エラーが発生しました。\n画面を再読込（リロード）してください。'
      }
    }
    if (screenSharingSubscriberStatus.status === 'Error') {
      return '認証エラーが発生しました。\n画面を再読込（リロード）してください。'
    }
    if (userPublisherStatus.status === 'Disconnected') {
      return '通信エラーが発生しました。\nブラウザを再読込してください。'
    }
    if (screenSharingSubscriberStatus.status === 'Disconnected') {
      return '通信エラーが発生しました。\nブラウザを再読込してください。'
    }
    return undefined
  }, [screenSharingSubscriberStatus, userPublisherStatus])

  useEffect(() => {
    if (status === Status.STARTED) {
      const isInitialCall = prevStatus === undefined

      if (prevStatus === Status.NOT_STARTED) {
        toast('開始されました', {
          onClose: unstableNavPopup.show,
        })
      } else if (isInitialCall) {
        unstableNavPopup.show()
      }
    }
    // NOTE: unstableNavPopup ごとではなく、.show だけ入れるために disable にした
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevStatus, status, toast, unstableNavPopup.show])

  const fixedControlsProps = {
    participantType: interviewControlsProps.participantType,
    status: interviewControlsProps.status,
    muteButtonProps: interviewControlsProps.muteButtonProps,
    cameraOffButtonProps: interviewControlsProps.cameraOffButtonProps,
    backButtonProps: interviewControlsProps.backButtonProps,
    finishButtonProps: interviewControlsProps.finishButtonProps,
  }

  const {
    initializeMediaDevicesError,
    meetingJoinFailedError,
    screenSharingBlockedByOSError,
    screenSharingPermissionDenied,
  } = useInterviewErrors()
  useEffect(() => {
    if (initializeMediaDevicesError) {
      alert(
        '利用できるカメラまたはマイクが見つかりません。リロードをお試しください。'
      )
    }
  }, [initializeMediaDevicesError])
  useEffect(() => {
    if (meetingJoinFailedError) {
      alert('接続に失敗しました。リロードをお試しください。')
    }
  }, [meetingJoinFailedError])
  useEffect(() => {
    if (screenSharingBlockedByOSError) {
      alert(
        '画面を共有できません。\nPCの設定画面からブラウザに画面共有権限を付与してください。'
      )
    }
  }, [screenSharingBlockedByOSError])
  useEffect(() => {
    if (screenSharingPermissionDenied) {
      alert('ご利用のパソコンで画面共有を行う権限がない可能性があります。')
    }
  }, [screenSharingPermissionDenied])

  return (
    <Wrapper className={className}>
      <InterviewMainAreaLayout
        showChatArea={showChat}
        showUsersArea={showUsers}
        deviceType={deviceType}
        width={width}
        height={height}
        videoArea={<VideoArea />}
        usersArea={<UsersArea deviceType={deviceType} />}
        chatArea={<ChatArea chatParams={chatParams} />}
        controlsArea={<FixedControls {...fixedControlsProps} />}
      />
      {status === Status.NOT_STARTED && !errorMessage && <Message />}
      <StartScreenShareModal
        active={startScreenShareModal.active}
        onClose={startScreenShareModal.close}
        onStart={handleScreenShareStart}
      />
      <FinishScreenShareModal
        active={finishScreenShareModal.active}
        onClose={finishScreenShareModal.close}
        onFinish={handleScreenShareFinish}
      />
      <ScreenSharingLimitModal
        active={screenSharingLimitModal.active}
        onClose={screenSharingLimitModal.close}
      />
      {errorMessage && (
        <ErrorMessageWrapper maxWidth={width - 10}>
          <Txt color={theme.color.red[2]}>{errorMessage}</Txt>
        </ErrorMessageWrapper>
      )}
      {quality.mode === QualityModeLow && <EconomyModeTxtWrapper />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
`

const Message = styled(_Message)`
  ${Wrapper} & {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    top: 20px;
  }
`
const ErrorMessageWrapper = styled.div<{ maxWidth: number }>`
  ${Wrapper} & {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    top: 20px;
  }
  max-width: ${({ maxWidth }) => maxWidth}px;
  width: 360px;
  padding: 10px;
  background: ${theme.color.white[1]};
  text-align: center;
`

const EconomyModeTxtWrapper = styled(EconomyModeTxt)`
  position: absolute;
  bottom: 0;
`
