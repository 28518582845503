import { PushType } from '@blue-agency/proton/web/v2/vader/push_type_pb'
import { SignalingPushMessage } from 'sora-js-sdk/dist/types'

export type SoraPushType = keyof typeof PushType

export type SignalingPushMessageData = SignalingPushMessage['data']
interface SoraPushDataBase extends SignalingPushMessageData {
  type: SoraPushType
}

export interface SoraPushDataChangedQuality extends SoraPushDataBase {
  type: 'CHANGED_QUALITY'
}

export type SoraPushData = SoraPushDataChangedQuality

export const isSoraPushData = (
  d: SignalingPushMessageData
): d is SoraPushData => {
  if (!d.type) return false
  // 値として正しいtypeが導出できないので妥協
  return typeof d.type === 'string'
}
