import React, { useState } from 'react'
import styled from 'styled-components'
import {
  Dropdown as _Dropdown,
  InputText,
  Modal,
  NewTabLink,
  PrimaryButton,
  RequireTxt as _RequireTxt,
  TertiaryButton,
  theme,
  Txt,
  WeakColorSeparator as _WeakColorSeparator,
} from '@blue-agency/rogue'
import { RegisterContainer } from './hooks/useRegister'
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker, { registerLocale } from 'react-datepicker'
import ja from 'date-fns/locale/ja'
import { DatePickerInput } from '@/pages/OrganizerRoutes/MyRoutes/HallsRoutes/HallsPage/DatePickerInput'
import { format } from 'date-fns'
import { EXTERNAL_URLS } from '@/services/urlService'
import { convertNumberDropdownOption } from '@/services/dropdownOptionService'
import { getHourList, getMinuteList } from '@/services/dateService'

export const RegisterModal: React.FCX = ({ className }) => {
  const {
    showRegisterModal,
    handleRegisterModalClose,
    register,
    errors,
    handleSubmit,
    onSubmit,
    formState,
    setValue,
  } = RegisterContainer.useContainer()

  const nowDate = new Date()
  // 秒は使用しないのでノイズにならないように0をset
  nowDate.setSeconds(0)

  const [startDate, setStartDate] = useState(nowDate)
  const nowHour = nowDate.getHours()
  // 分は5分刻みで表示させるため、5分単位で丸める
  const nowMinute = Math.round(nowDate.getMinutes() / 5) * 5

  const hourList = convertNumberDropdownOption(getHourList())
  const minuteList = convertNumberDropdownOption(getMinuteList(5))

  const onChangeDate = (date: Date) => {
    if (date) {
      setValue('scheduledAtDate', date)
      setStartDate(date)
    }
  }

  register('scheduledAtDate')
  setValue('scheduledAtDate', startDate)
  registerLocale('ja', ja)

  return (
    <Modal
      size="l"
      className={className}
      active={showRegisterModal}
      title="Web説明会新規登録"
      onClose={handleRegisterModalClose}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Upper>
          <Item>
            <Label>
              <LabelTxt>Web説明会名</LabelTxt>
              <RequireTxt>必須</RequireTxt>
              <InputText
                name="sessionName"
                size="ll"
                ref={register}
                onBlurError={errors.sessionName?.message}
                placeholder="Web説明会名"
              />
            </Label>
          </Item>
          <WeakColorSeparator />
          <Item>
            <LabelTxt>実施予定日時</LabelTxt>
            <RequireTxt>必須</RequireTxt>
            <DatePicker
              locale="ja"
              dateFormat="yyyy/MM/dd"
              selected={startDate}
              onChange={onChangeDate}
              name="scheduledAtDatePicker"
              customInput={
                <DatePickerInput
                  name="scheduledAtDate"
                  onBlurError={errors.scheduledAtDate?.message}
                  value={format(startDate, 'yyyy/MM/dd', { locale: ja })}
                />
              }
            />
            <Dropdown
              size="ss"
              name="scheduledAtHour"
              options={hourList}
              value={nowHour}
              placeholder="未選択"
              onBlurError={errors.scheduledAtHour?.message}
              ref={register}
            />
            <TimeTxt>時</TimeTxt>
            <Dropdown
              size="ss"
              name="scheduledAtMinute"
              options={minuteList}
              value={nowMinute}
              placeholder="未選択"
              onBlurError={errors.scheduledAtMinute?.message}
              ref={register}
            />
            <TimeTxt>分</TimeTxt>
          </Item>
          <Item>
            <Label>
              <LabelTxt>参加予定人数</LabelTxt>
              <RequireTxt>必須</RequireTxt>
              <InputText
                size="l"
                ref={register}
                name="scheduledParticipantsNumber"
                placeholder="参加予定の人数を入力してください"
                onBlurError={errors.scheduledParticipantsNumber?.message}
                type="number"
              />
            </Label>
          </Item>
          <Item>
            <SupplementList>
              <SupplementItem>
                「参加予定人数」は、参加者に表示されたり、制限がかかるものではありません。
              </SupplementItem>
              <SupplementItem>
                「参加予定人数」がご契約プランの上限を超える場合は
                <br />
                <NewTabLink
                  href={EXTERNAL_URLS.supportDesk.general}
                  action="open_support_desk_for_hall_create_modal"
                >
                  こちら
                </NewTabLink>
                までお問い合わせください。
              </SupplementItem>
            </SupplementList>
          </Item>
          <WeakColorSeparator />
          <ButtonGroup>
            <CancelButton
              size="l2"
              text="キャンセル"
              onClick={handleRegisterModalClose}
              comlinkPushParams={{ action: 'close_register_seminar_modal' }}
            />
            <RegisterButton
              size="l2"
              text="登録"
              type="submit"
              loading={formState.isSubmitting}
              comlinkPushParams={{ action: 'register_seminar' }}
            />
          </ButtonGroup>
        </Upper>
      </Form>
    </Modal>
  )
}

const Form = styled.form`
  padding: 20px;
`
const Item = styled.div`
  ${Form} && {
    width: 100%;
  }

  ${Form} &&:nth-of-type(n + 2) {
    margin-top: 20px;
  }

  display: flex;
  align-items: center;
`

const Label = styled.label`
  display: flex;
  align-items: center;
`

const ButtonGroup = styled.div`
  　${Form} && {
    margin-top: 20px;
  }

  display: flex;
  align-items: center;
  justify-content: center;
`
const CancelButton = styled(TertiaryButton)``
const RegisterButton = styled(PrimaryButton)`
  ${ButtonGroup} && {
    margin-left: 20px;
  }
`

const RequireTxt = styled(_RequireTxt)`
  width: 30px;
`

RequireTxt.defaultProps = { size: 'xs' }

const SupplementList = styled.ul`
  list-style-type: '※';
  margin-left: 170px;
  margin-bottom: 10px;
  font-size: ${theme.fontSize.s};
`

const SupplementItem = styled.li`
  padding-bottom: 4px;
  padding-top: 4px;
`

const LabelTxt = styled(Txt)`
  width: 140px;
`

const TimeTxt = styled(Txt)``

TimeTxt.defaultProps = { size: 'ss' }

const Dropdown = styled(_Dropdown)`
  margin-left: 10px;
  margin-right: 10px;
`

const WeakColorSeparator = styled(_WeakColorSeparator)`
  margin-top: 20px;
`
const Upper = styled.div`
  padding-top: 20px;
`
