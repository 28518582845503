import { useCallback, useMemo } from 'react'
import {
  ListMessagesResponse,
  CreateMessageRequest,
} from '@blue-agency/proton/web/c3po/chat_service_pb'
import { Message } from '@/components/Chat/Messages'
import { ParticipantType } from '@/lib/react-interview-sdk'
import { parseUserGuid } from './parseUserGuid'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { buildWebSocketUrl } from './buildWebSocketUrl'
import { beepSrc } from './beepSrc'
import { BeepContainer } from '@/containers/BeepContainer'
import { throttle } from 'throttle-debounce'

const maxRetryCount = 5

export type InterviewChatWebSocketParams = {
  chatRoomGuid: string
  participantType: ParticipantType
  entryNumber: string
  onAddMessage: (message: Message) => void
}

export const useInterviewChatWebSocket = (
  params: InterviewChatWebSocketParams
) => {
  const userGuid = useMemo(
    () => makeUserGuid(params.participantType, params.entryNumber),
    [params]
  )
  const { beep } = BeepContainer.useContainer()

  // NOTE: 通知音を鳴らしてから30秒は次の通知音を鳴らさない
  const throttledBeep = useMemo(
    () =>
      throttle(30000, true, () => {
        beep(beepSrc, 0.8)
      }),
    [beep]
  )

  const handleMessage = useCallback(
    (event: MessageEvent) => {
      const reader = new FileReader()
      reader.readAsArrayBuffer(event.data)
      reader.onload = async () => {
        const bytes = new Uint8Array(reader.result as ArrayBuffer)
        const msg = ListMessagesResponse.ChatMessage.deserializeBinary(bytes)
        const { participantType, entryNumber } = parseUserGuid(
          msg.getUserGuid()
        )
        const message: Message = {
          type: participantType,
          name: entryNumber,
          text: msg.getText(),
        }
        params.onAddMessage(message)
        if (
          // 自分以外の投稿の場合に音を出す
          !(
            participantType === params.participantType &&
            entryNumber === params.entryNumber
          )
        ) {
          throttledBeep()
        }
      }
    },
    [params, throttledBeep]
  )

  const { sendMessage, readyState } = useWebSocket(
    buildWebSocketUrl(params.chatRoomGuid),
    {
      reconnectAttempts: maxRetryCount,
      onMessage: handleMessage,
      shouldReconnect,
      protocols: [userGuid],
    }
  )

  const connected = useMemo(() => readyState === ReadyState.OPEN, [readyState])

  const handleSend = useCallback(
    (text: string) => {
      if (!connected) return
      const message = new CreateMessageRequest()
      message.setRoomGuid(params.chatRoomGuid)
      message.setText(text)
      message.setUserGuid(userGuid)
      message.setUserType(ListMessagesResponse.UserType.UNKNOWN)
      sendMessage(message.serializeBinary())
    },
    [connected, sendMessage, params, userGuid]
  )

  return { connected, handleSend }
}

const makeUserGuid = (participantType: ParticipantType, entryNumber: string) =>
  `${participantType}-${entryNumber}`

export const shouldReconnect = (e: WebSocketEventMap['close']) => {
  // MEMO: 正常終了の時はretryしない
  if (e.code === 1005 && e.reason === '') return false
  return true
}
