import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { INTERNAL_PATHS } from '@/services/urlService'
import { BeforeRecordingPage } from './BeforeRecordingPage'
import { RecordingPage } from './RecordingPage'
import { RecordedPage } from './RecordedPage'
import { OvertimePage } from './OvertimePage'
import { NotFound } from '@/components/NotFound'
import { provide } from '@blue-agency/front-state-management'
import { PresentationContainer } from './hooks/PresentationContainer'

export const PresentationRoutes = provide(PresentationContainer)(() => {
  return (
    <Switch>
      <Route
        exact
        path={INTERNAL_PATHS.organizer.my.presentations.presentation.index}
        component={BeforeRecordingPage}
      />
      <Route
        exact
        path={INTERNAL_PATHS.organizer.my.presentations.presentation.recording}
        component={RecordingPage}
      />
      <Route
        exact
        path={INTERNAL_PATHS.organizer.my.presentations.presentation.recorded}
        component={RecordedPage}
      />
      <Route
        exact
        path={INTERNAL_PATHS.organizer.my.presentations.presentation.overtime}
        component={OvertimePage}
      />
      <Route path="*" component={NotFound} />
    </Switch>
  )
})
