import React from 'react'
import styled from 'styled-components'
import {
  Modal,
  Txt,
  WeakColorSeparator,
  TertiaryButton,
} from '@blue-agency/rogue'

const text = `画面共有を発信できるのは一人のみとなります。
画面共有をする場合は、他のユーザーが発信している画面共有を終了してから再度、試してください。`

type Props = {
  active: boolean
  onClose: () => void
}

export const ScreenSharingLimitModal: React.FCX<Props> = ({
  className,
  active,
  onClose,
}) => {
  return (
    <Modal
      className={className}
      active={active}
      size="s"
      title="画面共有の確認"
      onClose={onClose}
    >
      <Wrapper>
        <Upper>
          <Txt size="s">{text}</Txt>
        </Upper>
        <Separator />
        <Bottom>
          <CancelButton
            size="l2"
            text="キャンセル"
            onClick={onClose}
            comlinkPushParams={{ action: 'close_screen_sharing_limit_modal' }}
          />
        </Bottom>
      </Wrapper>
    </Modal>
  )
}

const Wrapper = styled.div``
const Upper = styled.div`
  padding: 16px 20px;
`
const Separator = styled(WeakColorSeparator)`
  ${Wrapper} & {
    margin-top: 35px;
  }
`
const Bottom = styled.div`
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const CancelButton = styled(TertiaryButton)``
