import { useRef, useEffect } from 'react'
import { usePrevious } from 'react-use'

/**
 * 初回呼び出しは false を返す
 * shallow 比較
 */
export function useChanged<T>(value: T): boolean {
  const atFirstRef = useRef(true)
  const prev = usePrevious(value)

  useEffect(() => {
    atFirstRef.current = false
  }, [])

  if (atFirstRef.current) return false

  return value !== prev
}
