import React from 'react'
import styled from 'styled-components'
import {
  Modal,
  Txt,
  RequireTxt as _RequireTxt,
  TertiaryButton,
  PrimaryButton,
  InputText as _InputText,
  Icon,
  WeakColorSeparator,
  ErrorMessage as _ErrorMessage,
  NewTabLink,
  theme,
} from '@blue-agency/rogue'
import { RegisterContainer } from './hooks/useRegister'
import { OrganizerContainer } from '../../hooks/useOrganizer'
import reactStringReplace from 'react-string-replace'
import { EXTERNAL_URLS } from '@/services/urlService'
import { Tooltip } from '@/components/Tooltip'
import { TOOLTIP_IDS } from '@/constants/tooltip'

const labelAreaWidth = 160
const rightAreaMaxWidth = 460

const exhaustedErrorText = `有効期限内の面接が上限に達しています。
登録した面接が終了するまでは、新しい面接が追加できません。
上限の緩和を希望する場合は、サポートデスクまでお問い合わせください。
`

const replacedExhaustedText = reactStringReplace(
  exhaustedErrorText,
  /(サポートデスク)/,
  (match, index) => (
    <NewTabLink
      key={index}
      action="open_support_desk_from_interviews_exhausted_error"
      href={EXTERNAL_URLS.supportDesk.registerInterview}
    >
      {match}
    </NewTabLink>
  )
)

export const RegisterModal: React.FCX = ({ className }) => {
  const {
    showRegisterModal,
    handleRegisterModalClose,
    register,
    handleSubmit,
    onSubmit,
    showExhaustedError,
    formState,
    errors,
  } = RegisterContainer.useContainer()
  const { organizer } = OrganizerContainer.useContainer()
  const { interviewQuota } = organizer

  const text = `※面接URLは「面接の終了」もしくは、「発行後${interviewQuota.validityPeriodDays}日間での有効期限切れ」で使用できなくなります。
  `

  const replacedText = reactStringReplace(text, 'フォーム', (match, index) => (
    <NewTabLink
      key={index}
      href={EXTERNAL_URLS.supportDesk.registerInterview}
      action="open_support_desk_page"
    >
      {match}
    </NewTabLink>
  ))

  return (
    <Modal
      className={className}
      size="l"
      active={showRegisterModal}
      title={
        <>
          <ModalTitle>
            Web面接新規登録
            <span data-tip data-for={TOOLTIP_IDS.titleOnRegisterModal}>
              <ModalTitleQuestionIcon />
            </span>
          </ModalTitle>
          <Tooltip
            id={TOOLTIP_IDS.titleOnRegisterModal}
            arrowPosition="topRight"
          >
            <Txt size="s">
              応募者と面接するための「面接ページ」を発行し
              <br />
              ます。
              <FAQLinkWrapper>
                <NewTabLink
                  href={
                    EXTERNAL_URLS.help.organizer.interview.registerInterview
                  }
                  action="click_detail_link_in_register_modal_title_tooltip"
                >
                  詳細はこちら
                </NewTabLink>
              </FAQLinkWrapper>
            </Txt>
          </Tooltip>
        </>
      }
      onClose={handleRegisterModalClose}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Upper>
          {showExhaustedError && <ErrorMessage text={replacedExhaustedText} />}
          <Item>
            <Label>
              <LabelTxt>
                <span>追加数</span>
                <span
                  data-tip
                  data-for={TOOLTIP_IDS.countOnRegisterModal}
                  style={{ cursor: 'pointer' }}
                >
                  <QuestionIcon />
                </span>
              </LabelTxt>
              <Tooltip
                id={TOOLTIP_IDS.countOnRegisterModal}
                arrowPosition="topRight"
              >
                <Txt size="s">
                  「面接ページ」の追加数を選択します。
                  <br />
                  「有効期間内の面接」は
                  {interviewQuota.maximumReadyInterviews}件まで保有できます。
                  <br />
                  それ以上の利用をご検討のお客様は、こちらの
                  <br />
                  <NewTabLink
                    href={EXTERNAL_URLS.supportDesk.registerInterview}
                    action="click_support_desk_link_in_register_modal_tooltip"
                  >
                    フォーム
                  </NewTabLink>
                  からご問い合わせください。
                  <FAQLinkWrapper>
                    <NewTabLink
                      href={EXTERNAL_URLS.help.organizer.interview.faq}
                      action="click_detail_link_in_register_modal_count_tooltip"
                    >
                      詳細はこちら
                    </NewTabLink>
                  </FAQLinkWrapper>
                </Txt>
              </Tooltip>
              <RequireTxt>必須</RequireTxt>
              <CountInputTxt
                size="l"
                name="count"
                ref={register}
                onBlurError={errors.count?.message}
              />
            </Label>
          </Item>
          <Item>
            <Label>
              <LabelTxt>
                管理用面接名
                <span
                  data-tip
                  data-for={TOOLTIP_IDS.interviewNameOnRegisterModal}
                  style={{ cursor: 'pointer' }}
                >
                  <QuestionIcon />
                </span>
              </LabelTxt>
              <Tooltip
                id={TOOLTIP_IDS.interviewNameOnRegisterModal}
                arrowPosition="topRight"
              >
                <Txt size="s">
                  社内管理用の面接名です。応募者には表示されません。
                  <br />
                  複数追加される場合、面接名の末尾に自動で連番
                  <br />
                  が付与されます。 例）　新卒総合職　→　新卒総合職-0001
                  <FAQLinkWrapper>
                    <NewTabLink
                      href={EXTERNAL_URLS.help.organizer.interview.faq}
                      action="click_detail_link_in_register_modal_name_tooltip"
                    >
                      詳細はこちら
                    </NewTabLink>
                  </FAQLinkWrapper>
                </Txt>
              </Tooltip>
              <NameInputText
                name="name"
                placeholder="Web面接"
                ref={register}
                size="max"
                onBlurError={errors.name?.message}
              />
            </Label>
          </Item>
          <DescriptionWrapper>
            <Description size="s">{replacedText}</Description>
          </DescriptionWrapper>
        </Upper>
        <WeakColorSeparator />
        <Bottom>
          <CancelButton
            size="l2"
            text="キャンセル"
            onClick={handleRegisterModalClose}
            comlinkPushParams={{ action: 'close_register_interview_modal' }}
          />
          <RegisterButton
            size="l2"
            text="登録"
            type="submit"
            loading={formState.isSubmitting}
            comlinkPushParams={{ action: 'register_interview' }}
          />
        </Bottom>
      </Form>
    </Modal>
  )
}

const Form = styled.form``
const Upper = styled.div`
  padding: 20px;
`
const ErrorMessage = styled(_ErrorMessage)`
  svg {
    width: 16px;
  }

  & > *:nth-child(2) {
    display: block;
    white-space: pre-wrap;
  }
`
ErrorMessage.defaultProps = { size: 's' }

const Item = styled.div`
  ${Form} & {
    width: 100%;
  }
  ${Form} &:nth-of-type(n + 2) {
    margin-top: 20px;
  }
  display: flex;
  align-items: center;
  margin-top: 20px;
`
const Label = styled.label`
  display: flex;
  align-items: center;
`
const LabelTxt = styled(Txt)`
  display: flex;

  ${Item} & {
    width: ${labelAreaWidth}px;
  }
`

const QuestionIcon = styled(Icon).attrs({ name: 'question', size: 'l' })`
  ${Label} & {
    margin-left: 4px;
  }
`

const CountInputTxt = styled(_InputText)``

const RequireTxt = styled(_RequireTxt)`
  ${Item} & {
    margin-left: -28px;
    width: 28px;
    padding-right: 4px;
  }
`
RequireTxt.defaultProps = { size: 'xs' }

const NameInputText = styled(_InputText)`
  ${Item} & {
    width: ${rightAreaMaxWidth}px;
  }
`
const Bottom = styled.div`
  ${Form} & {
    padding: 20px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
`
const CancelButton = styled(TertiaryButton)``
const RegisterButton = styled(PrimaryButton)`
  ${Bottom} & {
    margin-left: 20px;
  }
`

const DescriptionWrapper = styled.div`
  ${Form} & {
    margin-left: ${labelAreaWidth}px;
    width: ${rightAreaMaxWidth}px;
  }
`

const Description = styled(Txt)`
  ${Form} & {
    margin-top: 20px;
  }
`
const FAQLinkWrapper = styled.span`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`
const ModalTitle = styled.p`
  display: flex;
  align-items: center;
`

const ModalTitleQuestionIcon = styled(Icon).attrs({
  name: 'question-bg-white',
  size: 'l',
})`
  color: ${theme.color.navy[1]};
  cursor: pointer;
  margin-left: 8px;
`
