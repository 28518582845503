import { theme, NewTabLink } from '@blue-agency/rogue'
import { price } from '@/constants'
import { BasePriceTable, PriceTableInfo } from '.'
import reactStringReplace from 'react-string-replace'
import { EXTERNAL_URLS } from '@/services/urlService'
import React from 'react'
import styled from 'styled-components'

const RedSpan = styled.span`
  color: ${theme.color.red[2]};
`
const GraySpan = styled.span`
  color: ${theme.color.gray[1]};
`

const notice = `
※1　「面接実施待ち」のWeb面接は、100件以上登録することはできません。
　　 上記制限を超える利用をご希望の企業様は、こちらまでご相談ください
`

let replacedNoticeText
replacedNoticeText = reactStringReplace(notice, 'こちら', (match, index) => (
  <NewTabLink
    key={`こちら-${index}`}
    href={EXTERNAL_URLS.contact}
    action="open_contact_page"
  >
    {match}
  </NewTabLink>
))

const info: PriceTableInfo = {
  table: {
    backgroundColors: [theme.color.white[1], '#FCFAF2', theme.color.gray[4]],
    header: {
      cells: [
        {
          title: '合計金額(税抜)',
          price: '',
          width: 160,
          color: theme.color.black[1],
          bold: true,
          left: true,
        },
        {
          title: 'ベーシックプラン',
          price: `￥${price.basic.toLocaleString()}`,
          width: 174,
          color: theme.color.black[1],
        },
        {
          title: '無料プラン',
          price: `￥${price.free.toLocaleString()}`,
          width: 174,
          color: theme.color.gray[1],
        },
      ],
    },
    body: {
      rows: [
        {
          cells: [
            '月額利用料金(税抜)',
            '￥29,800',
            <GraySpan key={0}>￥0</GraySpan>,
          ],
        },
        {
          cells: [
            '初期導入費用(税抜)',
            '￥39,800',
            <GraySpan key={0}>￥0</GraySpan>,
          ],
        },
        {
          cells: [
            '利用期間',
            '12カ月',
            <GraySpan key={0}>お申込みから30日間</GraySpan>,
          ],
        },
        {
          cells: ['Web説明会', '◯', <GraySpan key={0}>◯</GraySpan>],
        },
        {
          cells: [
            '実施数 / 月',
            '4回',
            <GraySpan key={0}>無料期間内で4回</GraySpan>,
          ],
          sub: true,
        },
        {
          cells: [
            '最大参加者数 / 回',
            '200人',
            <GraySpan key={0}>200人</GraySpan>,
          ],
          sub: true,
        },
        {
          cells: ['接続時間 / 回', '90分', <GraySpan key={0}>90分</GraySpan>],
          sub: true,
        },
        {
          cells: [
            replaceFootnote('Web面接 ※1'),
            '◯',
            <GraySpan key={0}>◯</GraySpan>,
          ],
        },
        {
          cells: ['実施数 / 月', '30回', <GraySpan key={0}>無制限</GraySpan>],
          sub: true,
        },
        {
          cells: ['接続時間 / 回', '180分', <GraySpan key={0}>180分</GraySpan>],
          sub: true,
        },
      ],
    },
  },
  notice: replacedNoticeText,
}

export const UpgradePlanPriceTable: React.FCX = ({ className }) => (
  <BasePriceTable info={info} className={className} />
)

function replaceFootnote(text: string): React.ReactNodeArray {
  return reactStringReplace(text, /(※\d+)/, (match, index) => {
    return (
      <RedSpan key={index} color="red">
        {match}
      </RedSpan>
    )
  })
}
