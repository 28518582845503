import React from 'react'
import styled from 'styled-components'
import { Icon, Txt, theme } from '@blue-agency/rogue'

export const EconomyModeBox: React.FCX = ({ className }) => {
  return (
    <Wrapper className={className}>
      <Title>
        <Icon name="settings" />
        <TitleTxt>軽量モード利用中</TitleTxt>
      </Title>
      <BodyTxt>
        管理者により、通信を安定させるために画質が通常よりも低く設定されています。
        <br />
        この設定は全ての参加者に適用されます。
      </BodyTxt>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  border: 1px solid ${theme.color.navy[3]};
  box-sizing: border-box;
  text-align: left;
  padding: 10px 20px;
`

const TitleTxt = styled(Txt).attrs({ size: 's', bold: true })`
  margin-left: 10px;
`

const Title = styled.div`
  font-weight: bold;
  display: flex;
  align-items: center;
`

const BodyTxt = styled(Txt).attrs({ size: 's' })`
  margin-left: 28px;
`
