import { useCallback, useMemo, useRef } from 'react'
import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'
import {
  CameraTestOnConnectCallback,
  CameraTestOnFailureCallback,
  CameraTestConnectionConfigProps,
  CameraTestConnectionProps,
} from '@blue-agency/react-environment-check'
import { useCachedPromise } from '@blue-agency/front-state-management'
import { cacheKey } from '@/services/bffService'
import { comlinkPush } from '@/comlink'

export const useEnvironmentCheck = () => {
  const { createDemoInvitation, deleteDemoInvitation } =
    OrganizerServiceContainer.useContainer()

  const createDemoInvitationRes = useCachedPromise(
    cacheKey.createDemoInvitation(),
    () => {
      return createDemoInvitation()
    }
  )

  const soraProps = useMemo<CameraTestConnectionConfigProps>(
    () => ({
      sessionToken: createDemoInvitationRes.getSessionToken(),
      channelId: createDemoInvitationRes.getSoraChannelId(),
      webrtcHost: createDemoInvitationRes.getWebrtcHost(),
    }),
    [createDemoInvitationRes]
  )

  const connectedRef = useRef(false)

  const onConnect = useCallback<CameraTestOnConnectCallback>(
    (result) => {
      comlinkPush({
        type: 'system_activity',
        action: 'succeeded_to_connect_to_sora_in_camera_test',
        metadata: {
          channelId: soraProps.channelId,
          sessionToken: soraProps.sessionToken,
          protocol: result.protocol ?? '',
          sendonlyAttempt: result.sendonlyAttempt?.toString() ?? '',
          recvonlyAttempt: result.recvonlyAttempt?.toString() ?? '',
        },
      })
      connectedRef.current = true
    },
    [soraProps.channelId, soraProps.sessionToken]
  )

  const onFailure = useCallback<CameraTestOnFailureCallback>(
    (e) => {
      comlinkPush({
        type: 'system_activity',
        action: 'failed_to_connect_to_sora_in_camera_test',
        metadata: {
          role: e.role ?? '',
          errorMessage: e.message ?? '',
          reason: e.reason ?? '',
          sessionToken: soraProps.sessionToken,
        },
      })
    },
    [soraProps.sessionToken]
  )

  const onLeave = useCallback(async () => {
    if (!connectedRef.current) return

    await deleteDemoInvitation({ sessionToken: soraProps.sessionToken })
    comlinkPush({
      type: 'system_activity',
      action: 'delete_demo_invitation',
      metadata: {
        sessionToken: soraProps.sessionToken,
      },
    })
  }, [deleteDemoInvitation, soraProps.sessionToken])

  const cameraTestConnectionProps = useMemo<CameraTestConnectionProps>(() => {
    return { sora: soraProps, onConnect, onFailure, onLeave }
  }, [onConnect, onFailure, onLeave, soraProps])

  return { cameraTestConnectionProps }
}
