import {
  createContainer,
  CacheContainer,
} from '@blue-agency/front-state-management'
import { useState, useCallback, useMemo, useEffect } from 'react'
import { FormStep, UpgradePlanForm } from '@/components/OrganizerForm/types'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { validationSchema } from '@/components/OrganizerForm/validationSchema'
import { Plan } from '@blue-agency/proton/web/v2/yashiori_bff/plan_data_pb'
import {
  OrganizerServiceContainer,
  Organizer,
} from '@/containers/OrganizerServiceContainer'
import { OrganizerContainer } from '../../hooks/useOrganizer'
import { cacheKey } from '@/services/bffService'
import { useHistory } from 'react-router-dom'
import { INTERNAL_PATHS, fillParams } from '@/services/urlService'

const useUpgradePlan = () => {
  const orgService = OrganizerServiceContainer.useContainer()
  const { organizer, token } = OrganizerContainer.useContainer()
  const { deleteCache } = CacheContainer.useContainer()
  const history = useHistory()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultValues = useMemo(() => makeDefaultValues(organizer), [])

  const [step, setStep] = useState<FormStep>('input')
  const [checked, setChecked] = useState(false)
  const [form, setForm] = useState<UpgradePlanForm>(defaultValues)
  const [isLoading, setIsLoading] = useState(false)

  const formContext = useForm<UpgradePlanForm>({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
    defaultValues,
  })

  useEffect(() => {
    // NOTE: UpgradePageでは従業員数と採用状況の項目が存在しないので、
    // 手動でregisterしてあげないとrequiredのバリデーションに引っかかってしまう
    formContext.register({ name: 'employeesNumber' })
    formContext.register({ name: 'newGraduate' })
    formContext.register({ name: 'midCareer' })
    formContext.register({ name: 'temporary' })
    formContext.register({ name: 'parttime' })
    return () => {
      formContext.unregister('employeesNumber')
      formContext.unregister('newGraduate')
      formContext.unregister('midCareer')
      formContext.unregister('temporary')
      formContext.unregister('parttime')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formContext.register, formContext.unregister])

  const onSubmit: {
    [key in FormStep]?:
      | ((event: React.FormEvent<HTMLFormElement>) => void)
      | undefined
  } = {
    input: formContext.handleSubmit(
      useCallback<SubmitHandler<UpgradePlanForm>>(
        (data) => {
          if (!checked) {
            alert('利用規約とプライバシーポリシーに同意してください')
            return
          }
          if (data.billTo === 'same') {
            data.billingName = data.adminName
            data.billingNameKana = data.adminNameKana
            data.billingDepartment = data.adminDepartment
            data.billingPostCode = data.postCode
            data.billingAddress = data.address
            data.billingPhoneNumber = data.adminPhoneNumber
            data.billingEmail = data.adminEmail
          }
          setForm(data)
          setStep('confirm')
          window.scrollTo(0, 0)
        },
        [checked]
      )
    ),
    confirm: useCallback(
      async (e: React.FormEvent) => {
        setIsLoading(true)
        e.preventDefault()
        try {
          await orgService.updatePlan(form)
        } catch (err) {
          setIsLoading(false)
          alert('申込みに失敗しました。もう一度お試しください。')
          throw err
        }
        deleteCache(cacheKey.getOrganizer())
        setStep('completed')
      },
      [orgService, form, deleteCache]
    ),
  }

  const handleCheck = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked)
  }, [])

  const handleBackToMyPage = useCallback(() => {
    history.push(
      fillParams({
        path: INTERNAL_PATHS.organizer.my.index,
        params: { token },
      })
    )
  }, [history, token])

  const handleBackToInputStep = useCallback(() => {
    formContext.reset(form)
    setStep('input')
    window.scrollTo(0, 0)
  }, [formContext, form])

  const subTitle = useMemo(() => {
    switch (step) {
      case 'input':
        return '基本情報'
      case 'confirm':
        return '申込内容の確認'
      case 'completed':
        return '登録完了'
    }
  }, [step])

  return {
    step,
    onSubmit,
    formContext,
    form,
    checked,
    handleCheck,
    handleBackToMyPage,
    handleBackToInputStep,
    isLoading,
    subTitle,
  }
}

const makeDefaultValues = (organizer: Organizer): UpgradePlanForm => {
  // NOTE: プランのアップグレードの時にemployeesNumberやnewGraduate等の項目はフォーム上に存在せず、サーバーにも送信されないが、
  // 新規登録時のフォームと共通のコンポーネントを使っているので、validationを通過するために何かしらの値を入れないといけない
  const dummyValue = 'dummy'
  return {
    plan: Plan.Id.BASIC.toString(),
    name: organizer.name,
    representativeName: organizer.representativeName,
    phoneNumber: organizer.phoneNumber,
    postCode: organizer.postCode,
    address: organizer.address,
    employeesNumber: dummyValue,
    adminName: organizer.adminName,
    adminNameKana: organizer.adminNameKana,
    adminDepartment: organizer.adminDepartment,
    adminPhoneNumber: organizer.adminPhoneNumber,
    adminEmail: organizer.adminEmail,
    billTo: 'same',
    billingName: '',
    billingNameKana: '',
    billingDepartment: '',
    billingPostCode: '',
    billingAddress: '',
    billingPhoneNumber: '',
    billingEmail: '',
    referrer: '',
    couponCode: '',
    newGraduate: dummyValue,
    midCareer: dummyValue,
    temporary: dummyValue,
    parttime: dummyValue,
  }
}

export const UpgradePlanContainer = createContainer(useUpgradePlan)
