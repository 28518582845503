import React from 'react'
import { useBeforeUnload } from '@blue-agency/react-utils'
import { useCachedPromise } from '@blue-agency/front-state-management'
import { useTitle } from '@/hooks/useTitle'
import { ApplyPageContainer } from './ApplyPageContainer'
import { Input } from './Input'
import { Confirm } from './Confirm'
import { Completed } from './Completed'
import { Redirect, useParams } from 'react-router-dom'
import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'
import { cacheKey } from '@/services/bffService'
import { VerifyTokenForRikunabiPackageResponse } from '@blue-agency/proton/web/v2/yashiori_bff/yashiori_bff_service_pb'
import { INTERNAL_PATHS, fillParams } from '@/services/urlService'
import { NotFound } from '@/components/NotFound'
const { Status } = VerifyTokenForRikunabiPackageResponse

export const ApplyPage = () => {
  const { token } = useParams<{ token?: string }>()
  if (!token) throw new Error('token not found')

  const orgService = OrganizerServiceContainer.useContainer()
  const verifyTokenRes = useCachedPromise(
    cacheKey.verifyTokenForRikunabiPackage(),
    () => orgService.verifyTokenForRikunabiPackage(token)
  )

  switch (verifyTokenRes.status) {
    case Status.ALREADY_REGISTERED:
      return (
        <Redirect
          to={fillParams({
            path: INTERNAL_PATHS.organizer.rikunabi.alreadyApplied,
            params: { promotionGuid: verifyTokenRes.promotionGuid },
          })}
        />
      )
    case Status.EXPIRED:
      return (
        <Redirect
          to={fillParams({
            path: INTERNAL_PATHS.organizer.rikunabi.expired,
            params: { promotionGuid: verifyTokenRes.promotionGuid },
          })}
        />
      )
    case Status.VALID:
      return (
        <ApplyPageContainer.Provider initialState={verifyTokenRes}>
          <Verified />
        </ApplyPageContainer.Provider>
      )
    default:
      return <NotFound />
  }
}

const Verified = () => {
  const { step, promotion } = ApplyPageContainer.useContainer()
  useTitle(`「${promotion.planName}」新規申込`)
  useBeforeUnload('ページを離れます。よろしいですか？', step !== 'completed')

  switch (step) {
    case 'input':
      return <Input />
    case 'confirm':
      return <Confirm />
    case 'completed':
      return <Completed />
  }
}
