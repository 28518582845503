import React from 'react'
import styled, { css } from 'styled-components'
import { theme, Txt } from '@blue-agency/rogue'

type Content = string | React.ReactNode | React.ReactNodeArray

export type PriceTableInfo = {
  table: {
    backgroundColors: string[]
    header: {
      cells: Array<{
        title: Content
        price: Content
        width: number
        color: string
        bold?: boolean
        left?: boolean
      }>
    }
    body: {
      rows: Array<{
        cells: Array<Content>
        sub?: boolean
      }>
    }
  }
  notice: Content
}

export const BasePriceTable: React.FCX<{ info: PriceTableInfo }> = ({
  info,
  className,
}) => {
  return (
    <Wrapper className={className}>
      <Table>
        <Thead>
          <Tr>
            {info.table.header.cells.map((cell, index) => {
              return (
                <Th
                  key={index}
                  color={info.table.backgroundColors[index]}
                  width={cell.width}
                >
                  <Center>
                    <Title
                      size="s"
                      color={cell.color}
                      bold={cell.bold}
                      left={cell.left}
                    >
                      {cell.title}
                    </Title>
                    <Price bold size="xxl" color={cell.color}>
                      {cell.price}
                    </Price>
                  </Center>
                </Th>
              )
            })}
          </Tr>
        </Thead>
        <Tbody>
          {info.table.body.rows.map(({ cells, sub }, rowIndex) => (
            <Tr key={rowIndex}>
              {cells.map((text, cellIndex) => {
                return (
                  <Td
                    key={cellIndex}
                    color={info.table.backgroundColors[cellIndex]}
                    alignLeft={cellIndex === 0}
                    subBorder={cellIndex === 0 && sub}
                  >
                    <TdTxt
                      size="s"
                      color={theme.color.black[1]}
                      marginLeft={cellIndex === 0 && sub}
                    >
                      {text}
                    </TdTxt>
                  </Td>
                )
              })}
            </Tr>
          ))}
        </Tbody>
      </Table>
      <NoticeTxt size="s">{info.notice}</NoticeTxt>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Table = styled.table`
  ${Wrapper} & {
    margin-top: 20px;
  }
`
const Thead = styled.thead``
const Th = styled.th<{ color: string; width: number }>`
  width: 170px;
  height: 80px;
  padding: 12px;
  ${({ color, width }) => css`
    background: ${color};
    width: ${width}px;
  `}
`
const Center = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Title = styled(Txt)<{ left?: boolean }>`
  ${({ left }) =>
    left &&
    css`
      position: absolute;
      top: 20px;
      left: -7px;
    `}
`
const Price = styled(Txt)``
const Tbody = styled.tbody``
const Tr = styled.tr<{ height?: number }>`
  ${Tbody} & {
    ${({ height }) =>
      css`
        height: ${height || 27}px;
      `}
  }
`
const Td = styled.td<{
  color: string
  alignLeft?: boolean
  subBorder?: boolean
}>`
  position: relative;
  padding: 0 5px;
  vertical-align: middle;
  ${({ color }) => css`
    background: ${color};
  `}
  ${({ alignLeft }) => css`
    text-align: ${alignLeft ? 'left' : 'center'};
  `}
  ${({ subBorder }) => css`
    ${subBorder
      ? css`
          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 20px;
            height: 0.5px;
            width: 140px;
            background-color: #c1c1c1;
          }
        `
      : css`
          border-top: 0.5px solid #c1c1c1;
        `}
  `}
`
const TdTxt = styled(Txt)<{ marginLeft?: boolean }>`
  ${({ marginLeft }) =>
    marginLeft &&
    css`
      margin-left: 20px;
    `}
`
const NoticeTxt = styled(Txt)`
  ${Wrapper} & {
    margin-top: 10px;
    max-width: 630px;
  }
`
