import React from 'react'
import styled from 'styled-components'
import { ApplyPageContainer } from '../../ApplyPageContainer'
import { Txt } from '@blue-agency/rogue'
import { ConfirmStepItem } from '../../types'

export const AdminSection: React.FCX = ({ className }) => {
  const { inputtedData } = ApplyPageContainer.useContainer()
  return (
    <Wrapper className={className}>
      {adminItems.map((item, index) => (
        <Item key={index}>
          <Label>{index === 0 && '管理者情報'}</Label>
          <ItemLabel size="s" bold>
            {item.label}
          </ItemLabel>
          <Txt size="s">{inputtedData[item.name]}</Txt>
        </Item>
      ))}
      <Item>
        <Label />
        <ItemLabel size="s" bold>
          メールアドレス
        </ItemLabel>
        <Txt size="s">登録されたメールアドレス</Txt>
      </Item>
    </Wrapper>
  )
}

const adminItems: ConfirmStepItem[] = [
  {
    label: '氏名',
    name: 'adminName',
  },
  {
    label: '氏名（かな）',
    name: 'adminNameKana',
  },
  {
    label: '部署名',
    name: 'adminDepartment',
  },
  {
    label: '電話番号',
    name: 'adminPhoneNumber',
  },
]

const Wrapper = styled.div`
  padding: 20px;
`
const Item = styled.div`
  &:nth-of-type(n + 2) {
    margin-top: 20px;
  }
  display: flex;
  align-items: center;
`
const Label = styled(Txt)`
  width: 160px;
`
const ItemLabel = styled(Txt)`
  width: 130px;
`
