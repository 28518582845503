import React from 'react'

import { EXTERNAL_URLS } from '@/services/urlService'
import { WindowSizeContainer } from '@/containers/WindowSizeContainer'
import { UnsupportedBrowser as UnsupportedBrowserBase } from '@/pages/UnsupportedBrowserRoutes/UnsupportedBrowser'

export const UnsupportedBrowser = () => {
  const windowSize = WindowSizeContainer.useContainer()

  return (
    <UnsupportedBrowserBase
      windowSize={windowSize}
      href={EXTERNAL_URLS.recommendedEnvironment.organizer.interview}
      action="open_organizer_recommended_environment_page"
    />
  )
}
