import React from 'react'
import { NewTabLink, SecondaryButton, theme, Txt } from '@blue-agency/rogue'
import styled from 'styled-components'
import { EXTERNAL_URLS } from '@/services/urlService'

export const Rikunabi2023AdditionalService: React.FCX = (props) => {
  return (
    <Wrapper className={props.className}>
      <Left>
        <Txt color={theme.color.green[4]} size="s">
          もっと使いたい場合は
        </Txt>
      </Left>
      <Right>
        <NewTabLink
          href={EXTERNAL_URLS.rikunabi2023AdditionalService}
          action="open_rikunabi_2023_additional_service_page"
          hideIcon
        >
          <UpgradeButton
            text="プラン切替/回数追加"
            comlinkPushParams={{ action: 'click_upgrade_button' }}
          />
        </NewTabLink>
      </Right>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  padding: 0px 10px;
  border-style: solid;
  border-width: 1px;
  background-color: ${theme.color.green[8]};
  border-color: ${theme.color.green[7]};
`

const Left = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const Right = styled.div`
  height: 100%;
  margin-left: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const UpgradeButton = styled(SecondaryButton).attrs({ size: 'm1' })``
