import React from 'react'
import styled from 'styled-components'
import { ApplyPageContainer } from '../../ApplyPageContainer'
import { Txt, NewTabLink, CheckBox } from '@blue-agency/rogue'
import { EXTERNAL_URLS } from '@/services/urlService'

export const AgreementSection: React.FCX = ({ className }) => {
  const { isAgreementChecked, handleCheck } = ApplyPageContainer.useContainer()
  return (
    <Wrapper className={className}>
      <Item>
        <SectionTxt>同意事項</SectionTxt>
        <CheckBoxLabel>
          <CheckBox checked={isAgreementChecked} onChange={handleCheck} />
          <CheckBoxTxt size="s">
            <NewTabLink href={EXTERNAL_URLS.terms} action="open_terms_page">
              インタビューメーカー利用規約
            </NewTabLink>
            、株式会社スタジアムの
            <NewTabLink
              href={EXTERNAL_URLS.privacy}
              action="open_privacy_policy_page"
            >
              プライバシーポリシー
            </NewTabLink>
            に同意する
          </CheckBoxTxt>
        </CheckBoxLabel>
      </Item>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 20px;
`
const Item = styled.div`
  &:nth-of-type(n + 2) {
    margin-top: 20px;
  }
  display: flex;
  align-items: center;
`
const SectionTxt = styled(Txt)`
  width: 160px;
`
const CheckBoxLabel = styled.label`
  display: flex;
  align-items: center;
  margin-left: 150px;
`
const CheckBoxTxt = styled(Txt)`
  margin-left: 10px;
`
