import {
  createContainer,
  useCachedPromise,
  CacheContainer,
} from '@blue-agency/front-state-management'
import { useParams } from 'react-router-dom'
import { ParticipantsServiceContainer } from '@/containers/ParticipantsServiceContainer'
import { useEffect, useState, useMemo, useCallback } from 'react'
import { Status as ProtoStatus } from '@blue-agency/proton/web/v2/yashiori_bff/yashiori_bff_service_pb'
import { storageKey } from '@/constants/storageKey'
import { cacheKey } from '@/services/bffService'
import { comlinkPush } from '@/comlink'

const POLLING_INTERVAL_SECOND = 2
const FINISH_INTERVAL_SECOND = 30
const SCREEN_SHARING_INTERVAL_SECOND = 12

type Status = 'valid' | 'invalidToken' | 'finished' | 'pending'

const useCheck = () => {
  const token = sessionStorage.getItem(storageKey.session.emailToken)
  if (!token) throw new Error('token not found')
  const { entranceGuid } = useParams<{ entranceGuid?: string }>()
  if (!entranceGuid) throw new Error('entranceGuid not found')
  const { verifyEmail, checkEntrance, pollingSessionStatus } =
    ParticipantsServiceContainer.useContainer()
  const { deleteCache } = CacheContainer.useContainer()

  const checkEntranceRes = useCachedPromise(cacheKey.checkEntrance(), () =>
    checkEntrance({ entranceGuid })
  )
  const verifyEmailRes = useCachedPromise(cacheKey.verifyEmail(), () =>
    verifyEmail({ token })
  )
  const initialPollingSessionStatusRes = useCachedPromise(
    cacheKey.pollingSessionStatus(),
    () => pollingSessionStatus({ hallGuid: verifyEmailRes.getHallGuid() })
  )

  useEffect(() => {
    return () => {
      const keys = [
        cacheKey.checkEntrance(),
        cacheKey.verifyEmail(),
        cacheKey.pollingSessionStatus(),
      ]
      keys.forEach((key) => deleteCache(key))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const initialStatus: Status = useMemo(() => {
    if (!checkEntranceRes.validity) return 'finished'
    if (!verifyEmailRes.getValidity()) return 'invalidToken'
    switch (initialPollingSessionStatusRes.getStatus()) {
      case ProtoStatus.PENDING:
        return 'pending'
      case ProtoStatus.RUNNING:
        return 'valid'
      case ProtoStatus.FINISHED:
        return 'finished'
      default:
        throw new Error('Invalid status')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [status, setStatus] = useState<Status>(initialStatus)
  const [presenterDistributionUrl, setPresenterDistributionUrl] = useState(
    initialPollingSessionStatusRes.getPresenterDistributionUrl()
  )
  const [screenDistributionUrl, setScreenDistributionUrl] = useState(
    initialPollingSessionStatusRes.getScreenDistributionUrl()
  )
  const [isScreenShared, setIsScreenShared] = useState(false)
  const [isSessionFinished, setIsSessionFinished] = useState(false)

  const onPresenterEnded = useCallback(() => {
    setStatus('finished')
  }, [])

  useEffect(() => {
    if (!checkEntranceRes.validity) {
      setStatus('finished')
    } else if (!verifyEmailRes.getValidity()) {
      setStatus('invalidToken')
    }
    comlinkPush({
      type: 'system_activity',
      action: 'start_polling_session_status',
      targetName: 'yashiori_hall.guid',
      targetIdStr: verifyEmailRes.getHallGuid(),
    })
    const timer = setInterval(async () => {
      const res = await pollingSessionStatus({
        hallGuid: verifyEmailRes.getHallGuid(),
      })
      setPresenterDistributionUrl(res.getPresenterDistributionUrl())
      setScreenDistributionUrl(res.getScreenDistributionUrl())
      // MEMO: 画面共有の開始のステータスになっていても実際に画面共有の映像が配信されるまでタイムラグがあるのでその調整をしている
      setTimeout(() => {
        setIsScreenShared(res.getIsScreenShared())
      }, SCREEN_SHARING_INTERVAL_SECOND * 1000)
      const status = res.getStatus()
      if (status === ProtoStatus.FINISHED) {
        comlinkPush({
          type: 'system_activity',
          action: 'finish_polling_session_status',
          targetName: 'yashiori_hall.guid',
          targetIdStr: verifyEmailRes.getHallGuid(),
        })
        setIsSessionFinished(true)
        // NOTE: onPresenterEndedが呼ばれることで終了するはずだが、最悪でも時限で終了するように残す
        setTimeout(() => setStatus('finished'), FINISH_INTERVAL_SECOND * 1000)
        clearInterval(timer)
      } else if (status === ProtoStatus.PENDING) {
        setStatus('pending')
      } else if (status === ProtoStatus.RUNNING) {
        setStatus('valid')
      }
    }, POLLING_INTERVAL_SECOND * 1000)
    return () => {
      clearInterval(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    status,
    entranceGuid,
    verifyEmailRes,
    presenterDistributionUrl,
    screenDistributionUrl,
    isScreenShared,
    isSessionFinished,
    onPresenterEnded,
    checkEntranceRes,
  }
}

export const CheckContainer = createContainer(useCheck)
