import React from 'react'
import styled from 'styled-components'
import {
  Modal,
  WeakColorSeparator,
  TertiaryButton,
  ErrorMessage,
} from '@blue-agency/rogue'

export type ErrorMessageType = {
  title: string
  description: string
}

type Props = {
  active: boolean
  errorMessage: ErrorMessageType
  onClose: () => void
}

export const ErrorModal: React.FCX<Props> = ({
  active,
  errorMessage,
  onClose,
  className,
}) => {
  return (
    <Modal
      className={className}
      active={active}
      title={errorMessage.title}
      onClose={onClose}
    >
      <Wrapper>
        <Description text={errorMessage.description} size="s" />
        <Separator />
        <ReloadButton
          text="閉じる"
          onClick={onClose}
          size="l2"
          comlinkPushParams={{ action: 'close_short_url_error_modal' }}
        />
      </Wrapper>
    </Modal>
  )
}

const Wrapper = styled.div`
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Description = styled(ErrorMessage)`
  white-space: pre-wrap;
`
const Separator = styled(WeakColorSeparator)`
  ${Wrapper} & {
    margin-top: 20px;
  }
`
const ReloadButton = styled(TertiaryButton)`
  ${Wrapper} & {
    margin-top: 20px;
  }
`
