import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { INTERNAL_PATHS } from '@/services/urlService'
import { RoomPage } from './RoomPage'
import { FinishPage } from './FinishPage'
import { ExpiredPage } from './ExpiredPage'
import { NotFound } from '@/components/NotFound'
import { useTitle } from '@/hooks/useTitle'

export const InterviewRoutes = () => {
  useTitle('Web面接')

  return (
    <Switch>
      <Route
        exact
        path={INTERNAL_PATHS.organizer.my.interviews.interview.room}
        component={RoomPage}
      />
      <Route
        exact
        path={INTERNAL_PATHS.organizer.my.interviews.interview.finish}
        component={FinishPage}
      />
      <Route
        exact
        path={INTERNAL_PATHS.organizer.my.interviews.interview.expired}
        component={ExpiredPage}
      />
      <Route path="*" component={NotFound} />
    </Switch>
  )
}
