import uriTemplates from 'uri-templates'

export const fillParams = ({
  path,
  params,
}: {
  path: string
  params: { [key: string]: string }
}) => {
  const template = uriTemplates(
    path.replace(
      /:(.*?)\??\/|:(.*?)\??$/g,
      (_, n1, n2) => `{${n1 || n2}}${n1 ? '/' : ''}`
    )
  )
  return template.fillFromObject(params)
}
