import React, { ChangeEvent, useCallback } from 'react'
import {
  CheckBox,
  Dropdown,
  TertiaryButton,
  Txt as _Txt,
  theme,
} from '@blue-agency/rogue'
import styled from 'styled-components'
import { BulkDeletePresentationsModal } from './BulkDeletePresentationsModal'
import { BulkActionContainer } from './hooks/BulkActionContainer'

export const BulkAction: React.FCX = ({ className }) => {
  const {
    count,
    hasSelectedPresentations,
    canExecuteAction,
    options,
    execute,
    toggleAll,
    deletePresentationsModal,
    setSelectedActionKey,
  } = BulkActionContainer.useContainer()

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      setSelectedActionKey(event.target.value)
    },
    [setSelectedActionKey]
  )

  return (
    <Wrapper className={className}>
      <div style={{ display: 'flex' }}>
        <CheckBox onChange={toggleAll} checked={hasSelectedPresentations} />
        <TxtWrapper>
          <Count>{count}</Count>
          <Txt>件選択中</Txt>
        </TxtWrapper>
      </div>
      <Dropdown
        options={options}
        placeholder="一括処理を選択する"
        allowEmpty
        onChange={handleChange}
      />
      <ExecButton
        onClick={execute}
        disabled={!canExecuteAction}
        comlinkPushParams={{ action: 'open_bulk_action_modal' }}
      >
        実行
      </ExecButton>
      <BulkDeletePresentationsModal
        active={deletePresentationsModal.active}
        onClose={deletePresentationsModal.close}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin-right: 20px;
  }
`

const TxtWrapper = styled.div`
  display: flex;
  align-items: baseline;

  ${Wrapper} & {
    margin-left: 8px;
  }
`

const Count = styled(_Txt)`
  text-align: right;
  width: 28px;
  margin-right: 4px;
`
Count.defaultProps = { color: theme.color.green[4], size: 'xxl' }

const Txt = styled(_Txt)``
Txt.defaultProps = { color: theme.color.white[1] }

const ExecButton = styled(TertiaryButton)`
  &:disabled {
    color: ${theme.color.gray[2]};
  }
`
ExecButton.defaultProps = { size: 's' }
