import React, { useCallback } from 'react'
import styled from 'styled-components'
import {
  Modal,
  Txt,
  TertiaryButton,
  SecondaryDangerButton,
  WeakColorSeparator,
} from '@blue-agency/rogue'
import { WindowSizeContainer } from '@/containers/WindowSizeContainer'
import { InterviewContainer } from '../../hooks/useInterview'

type Props = {
  active: boolean
  leaveRoom: () => void
}

export const ExitModal: React.FCX<Props> = ({
  className,
  active,
  leaveRoom,
}) => {
  const { handleExitModalClose, handleExit } = InterviewContainer.useContainer()
  const { isPortrait } = WindowSizeContainer.useContainer()

  const handleLeaveRoom = useCallback(async () => {
    await leaveRoom()
    handleExit()
  }, [leaveRoom, handleExit])

  return (
    <Modal
      className={className}
      size={isPortrait ? 's' : 'm'}
      active={active}
      title="面接前室から退室"
      onClose={handleExitModalClose}
    >
      <Wrapper>
        <Upper>
          <Txt>退室してもよろしいでしょうか？</Txt>
        </Upper>
        <Separator />
        <Bottom>
          <ButtonGroup>
            <CancelButton
              text="キャンセル"
              onClick={handleExitModalClose}
              comlinkPushParams={{ action: 'close_exit_interview_modal' }}
            />
            <FinishButton
              text="退室する"
              onClick={handleLeaveRoom}
              comlinkPushParams={{ action: 'exit_interview_preparation_room' }}
            />
          </ButtonGroup>
        </Bottom>
      </Wrapper>
    </Modal>
  )
}

const Wrapper = styled.div``
const Upper = styled.div`
  padding: 20px 16px;
`
const Separator = styled(WeakColorSeparator)`
  ${Wrapper} & {
    margin-top: 40px;
  }
`
const Bottom = styled.div`
  padding: 20px 16px;
`
const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const CancelButton = styled(TertiaryButton)`
  ${ButtonGroup} & {
    width: 48%;
  }
`
const FinishButton = styled(SecondaryDangerButton)`
  ${ButtonGroup} & {
    width: 48%;
  }
`
