import React from 'react'
import styled from 'styled-components'
import { Txt as _Txt, NewTabLink } from '@blue-agency/rogue'
import reactStringReplace from 'react-string-replace'
import { EXTERNAL_URLS } from '@/services/urlService'

const text = `
録画データを作成しています。
作成完了まで、しばらくお待ち下さい。
ヘルプページはこちら
`

const replacedText = reactStringReplace(text, /(こちら)/, (match, i) => (
  <NewTabLink
    href={EXTERNAL_URLS.supportPolicy}
    key={i}
    action="open_support_policy_link_on_generating_interview_recording_page"
  >
    {match}
  </NewTabLink>
))

export const EncodingContent: React.FCX = ({ className }) => (
  <div className={className}>
    <Txt>{replacedText}</Txt>
  </div>
)

const Txt = styled(_Txt)`
  text-align: center;
`
