import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { Layout } from '@/components/Layout'
import {
  InterviewControlsProps,
  InterviewControls,
} from '@/components/InterviewControls'

type Props = {
  mainArea: ReactNode
  interviewControlsProps: InterviewControlsProps
  showInterviewsNav?: boolean
  showHallsNav?: boolean
}

export const InterviewLayout: React.FCX<Props> = ({
  className,
  mainArea,
  interviewControlsProps,
  showInterviewsNav = false,
  showHallsNav = false,
}) => {
  switch (interviewControlsProps.deviceType) {
    case 'pc':
      return (
        <Layout
          className={className}
          rightNode={<InterviewControls {...interviewControlsProps} />}
          showInterviewsNav={showInterviewsNav}
          showHallsNav={showHallsNav}
        >
          {mainArea}
        </Layout>
      )
    case 'spPortrait':
    case 'spLandscape':
      return (
        <SpWrapper
          className={className}
          isPortrait={interviewControlsProps.deviceType === 'spPortrait'}
        >
          {mainArea}
          <InterviewControls {...interviewControlsProps} />
        </SpWrapper>
      )
  }
}

const portraitStyle = css`
  display: flex;
  flex-direction: column;
`
const landscapeStyle = css`
  display: flex;
`
const SpWrapper = styled.div<{ isPortrait: boolean }>`
  ${({ isPortrait }) => (isPortrait ? portraitStyle : landscapeStyle)}
`
