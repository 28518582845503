import React from 'react'
import styled from 'styled-components'
import { Modal as _Modal, WeakColorSeparator } from '@blue-agency/rogue'
import { GetPresentationStatusResponse } from '@blue-agency/proton/web/v2/yashiori_bff/yashiori_bff_service_pb'
import { Upper } from './Upper'
import { Bottom } from './Bottom'
import { WindowSizeContainer } from '@/containers/WindowSizeContainer'
import { Body as _Body } from './Body'
import { overflowYScrollStyle } from '@/styles/overflowYScrollStyle'
import { RecordingContainer } from '../hooks/RecordingContainer'
const { Status } = GetPresentationStatusResponse

type Props = {
  active: boolean
}

export const BeforeStartModal: React.FCX<Props> = ({ className, active }) => {
  const { status } = RecordingContainer.useContainer()
  const windowSize = WindowSizeContainer.useContainer()
  const wrapperHeight = Math.min(windowSize.height - 80, 710)
  const bodyHeight = wrapperHeight - 186

  return (
    <Modal
      className={className}
      active={active}
      title={status === Status.NOT_READY ? '撮影準備中' : '撮影準備完了'}
      stopScroll
    >
      <Wrapper height={wrapperHeight}>
        <Upper />
        <WeakColorSeparator />
        <Body height={bodyHeight} />
        <WeakColorSeparator />
        <Bottom />
      </Wrapper>
    </Modal>
  )
}

const Modal = styled(_Modal)`
  width: 700px;
`
const Wrapper = styled.div<{ height: number }>`
  display: flex;
  flex-direction: column;
  height: ${({ height }) => height}px;
`
const Body = styled(_Body)<{ height: number }>`
  height: ${({ height }) => height}px;
  ${overflowYScrollStyle};
`
