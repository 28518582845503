import React from 'react'
import { theme } from '@blue-agency/rogue'
import { Txt } from '@blue-agency/rogue'
import { TimerProps } from '.'

export const NotStarted: React.FC<TimerProps> = ({ fontSize = 's' }) => {
  return (
    <Txt size={fontSize} color={theme.color.navy[2]}>
      00:00
    </Txt>
  )
}
