import React, { ReactNode, useMemo } from 'react'
import styled from 'styled-components'
import { theme } from '@blue-agency/rogue'

type Props = {
  height: number
  width: number
  presenterVideo: ReactNode
  screenShareVideo?: ReactNode
  screenSharing: boolean
}

export const PresentationLayout: React.FCX<Props> = ({
  height,
  width,
  presenterVideo,
  screenShareVideo,
  screenSharing,
  className,
}) => {
  const mainVideoHeight = useMemo(
    () =>
      Math.min(
        height,
        screenSharing ? width * 0.68 * (9 / 16) : width * 1.0 * (9 / 16)
      ),
    [width, height, screenSharing]
  )
  const subVideoHeight = useMemo(() => width * 0.28 * (9 / 16), [width])

  return (
    <Wrapper height={height} className={className}>
      <Left screenSharing={screenSharing}>
        <MainVideoArea height={mainVideoHeight}>
          {screenSharing ? screenShareVideo : presenterVideo}
        </MainVideoArea>
      </Left>
      {screenSharing && (
        <Right>
          <SubVideoArea height={subVideoHeight}>{presenterVideo}</SubVideoArea>
        </Right>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ height: number }>`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  height: ${({ height }) => height}px;
  background: ${theme.color.gray[3]};
`
const Left = styled.div<{ screenSharing: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 ${({ screenSharing }) => (screenSharing ? '68%' : '100%')};
`
const Right = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 28%;
`
const MainVideoArea = styled.div<{ height: number }>`
  background: ${theme.color.black[1]};
  ${Left} && {
    width: 100%;
    height: ${({ height }) => height}px;
  }
`
const SubVideoArea = styled.div<{ height: number }>`
  background: ${theme.color.black[1]};
  ${Right} && {
    width: 100%;
    height: ${({ height }) => height}px;
  }
`
