import React from 'react'
import { PcFormLayout } from '@/components/FormLayout'
import { useBeforeUnload } from '@blue-agency/react-utils'
import { provide } from '@blue-agency/front-state-management'
import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'
import { Layout } from '@/components/Layout'
import { useTitle } from '@/hooks/useTitle'
import { SignupContainer } from './hooks/useSignup'
import { Upper } from './Upper'
import { Body } from './Body'
import { Bottom } from './Bottom'
import { SpFormLayout } from '@/components/FormLayout'

export const SignupPage: React.FCX = provide(
  SignupContainer,
  OrganizerServiceContainer
)(({ className }) => {
  const { subTitle, step, onSubmit, promotion, isPc } =
    SignupContainer.useContainer()

  useTitle(`${promotion.plan.name}お申込みフォーム`)
  useBeforeUnload('ページを離れます。よろしいですか？', step !== 'completed')

  if (isPc) {
    return (
      <Layout className={className} notFixHeight>
        <PcFormLayout
          className={className}
          title={`インタビューメーカー「${promotion.plan.name}」申込フォーム`}
          subTitle={subTitle}
          upper={<Upper step={step} />}
          body={<Body step={step} />}
          bottom={<Bottom step={step} />}
          onSubmit={onSubmit[step]}
        />
      </Layout>
    )
  } else {
    return (
      <Layout className={className} notFixHeight>
        <SpFormLayout
          className={className}
          title={`インタビューメーカー\n「${promotion.plan.name}」\n申込フォーム`}
          step={step}
          subTitle={subTitle}
          upper={<Upper step={step} />}
          body={<Body step={step} />}
          bottom={<Bottom step={step} />}
          onSubmit={onSubmit[step]}
        />
      </Layout>
    )
  }
})
