import React from 'react'
import styled from 'styled-components'
import {
  DangerButton,
  Icon as _Icon,
  Modal,
  TertiaryButton,
  theme,
  WeakColorSeparator,
} from '@blue-agency/rogue'
import { RoomContainer } from '../hooks/useRoom'
import { WindowSizeContainer } from '@/containers/WindowSizeContainer'

type Props = {
  active: boolean
}

export const FinishModal: React.FCX<Props> = ({ className, active }) => {
  const { finishModal, handleFinish } = RoomContainer.useContainer()
  const { isPortrait } = WindowSizeContainer.useContainer()

  return (
    <Modal
      className={className}
      size={isPortrait ? 's' : 'm'}
      active={active}
      title="面接の終了"
      onClose={finishModal.close}
    >
      <Wrapper>
        <Upper>
          <Icon name="exclamation" />
          <CautionTxt>
            面接を終了しますか？終了後の再開はできません。
          </CautionTxt>
        </Upper>
        <Middle>
          <SupplementList>
            <SupplementItem>
              面接官が複数の場合は、一人が終了させた時点で面接が終了となります。
            </SupplementItem>
            <SupplementItem>
              終了前に退室される場合は、ブラウザのタブを閉じてください。
            </SupplementItem>
          </SupplementList>
        </Middle>
        <Separator />
        <Bottom>
          <ButtonGroup>
            <CancelButton
              text="キャンセル"
              onClick={finishModal.close}
              comlinkPushParams={{ action: 'close_finish_interview_modal' }}
            />
            <FinishButton
              text="面接を終了"
              onClick={handleFinish}
              comlinkPushParams={{ action: 'finish_interview' }}
            />
          </ButtonGroup>
        </Bottom>
      </Wrapper>
    </Modal>
  )
}

const Wrapper = styled.div``
const Upper = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 16px;
`

const CautionTxt = styled.span`
  color: ${theme.color.red[2]};
  font-size: ${theme.fontSize.l};
`

const Middle = styled.div`
  padding-left: 16px;
  padding-right: 16px;
`

const Icon = styled(_Icon)`
  color: ${theme.color.red[2]};
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
  width: 22px;
  height: 22px;
`

const SupplementList = styled.ul`
  margin-left: 20px;
  font-size: ${theme.fontSize.s};
`

const SupplementItem = styled.li`
  padding-bottom: 4px;
  padding-top: 4px;
`

const Separator = styled(WeakColorSeparator)`
  ${Wrapper} & {
    margin-top: 40px;
  }
`
const Bottom = styled.div`
  padding: 20px 16px;
`
const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const CancelButton = styled(TertiaryButton)`
  ${ButtonGroup} & {
    width: 48%;
  }
`
const FinishButton = styled(DangerButton)`
  ${ButtonGroup} & {
    width: 48%;
  }
`
