import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { theme, Txt, A } from '@blue-agency/rogue'
import { DropdownContainer } from './hooks/useDropdown'

export const Dropdown = forwardRef<HTMLDivElement>(({ className }, ref) => {
  const { items } = DropdownContainer.useContainer()
  return (
    <Wrapper className={className} ref={ref}>
      {items.map((item, index) => (
        <Link
          key={index}
          href={item.href}
          target="_blank"
          rel="noopener noreferrer"
          onClick={item.onClick}
          comlinkPushParams={{
            action: item.action,
          }}
        >
          <Txt>{item.text}</Txt>
          {item.secondaryText && (
            <SecondaryTxt>{item.secondaryText}</SecondaryTxt>
          )}
        </Link>
      ))}
    </Wrapper>
  )
})

const Wrapper = styled.div`
  width: 250px;
  background: ${theme.color.white[1]};
  border: 1px solid ${theme.color.gray[3]};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
`
const Link = styled(A)`
  height: 50px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    background: ${theme.color.gray[6]};
  }
  ${Wrapper} &:nth-of-type(n + 2) {
    border-top: 1px solid ${theme.color.gray[4]};
  }
`

const SecondaryTxt = styled(Txt)`
  margin-left: 12px;
  font-size: 12px;
  line-height: 18px;
  color: ${theme.color.gray[1]};
`
