import { useCallback } from 'react'

import { AudioVideoContainer } from '@/lib/react-interview-sdk/containers/AudioVideoContainer'

export const useStopScreenSharing = () => {
  const { audioVideo } = AudioVideoContainer.useContainer()

  const stopScreenSharing = useCallback<() => void>(() => {
    if (!audioVideo) {
      return
    }

    audioVideo.stopContentShare()
  }, [audioVideo])

  return stopScreenSharing
}
