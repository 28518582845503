import React from 'react'
import styled from 'styled-components'
import { Txt, Dropdown } from '@blue-agency/rogue'
import { ApplyPageContainer } from '../../ApplyPageContainer'
import { OrganizerFormItem } from '@/components/OrganizerFormItem'

export const PlanSection: React.FCX = ({ className }) => {
  const { promotion, form } = ApplyPageContainer.useContainer()
  const options = promotion.preferredStartDates.map((date) => ({
    value: date.id.toString(),
    label: date.label,
  }))
  return (
    <Wrapper className={className}>
      <OrganizerFormItem
        title="申込内容"
        label="申込プラン"
        inputNode={<Txt size="s">{promotion.planName}</Txt>}
      />
      <OrganizerFormItem
        label="利用開始希望日"
        required
        inputNode={
          <Dropdown
            ref={form.register}
            name="preferredStartDateId"
            size="l"
            options={options}
            placeholder="選択してください"
            allowEmpty
            onBlurError={form.errors.preferredStartDateId?.message}
          />
        }
      />
      <SubTxt>※利用開始希望日の10時頃よりご利用開始となります。</SubTxt>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 20px;
`
const SubTxt = styled(Txt).attrs({ size: 's' })`
  margin-top: 8px;
  margin-left: 320px;
`
