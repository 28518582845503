import React from 'react'
import styled from 'styled-components'
import { Layout } from '@/components/Layout'
import { Txt, theme, HelpLinkIcon } from '@blue-agency/rogue'
import { EXTERNAL_URLS } from '@/services/urlService'
import { useTitle } from '@/hooks/useTitle'
import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'
import { useCachedPromise } from '@blue-agency/front-state-management'
import { cacheKey } from '@/services/bffService'

export const ExpiredPage: React.FCX = ({ className }) => {
  const { getOrganizer } = OrganizerServiceContainer.useContainer()
  const organizer = useCachedPromise(cacheKey.getOrganizer(), getOrganizer)

  useTitle('有効期限切れ')

  return (
    <Layout
      className={className}
      rightNode={
        <HelpLinkIcon
          href={EXTERNAL_URLS.help.organizer.interview.top}
          action="open_organizer_interview_help_page"
        />
      }
      backgroundColor={theme.color.gray[4]}
    >
      <Wrapper>
        <Title size="l">
          {organizer.name}
          <br />
          Web面接
        </Title>
        <Description>
          <DescriptionTxt color={theme.color.red[2]}>
            Web面接の有効期限が切れています。
            <br />
            社内の人事担当者さまにお問い合わせください。
          </DescriptionTxt>
        </Description>
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`
const Title = styled(Txt)`
  text-align: center;
  ${Wrapper} & {
    margin-top: 20px;
  }
`
const Description = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 360px;
  height: 79px;
  background: ${theme.color.white[1]};
  ${Wrapper} & {
    margin-top: 20px;
  }
`
const DescriptionTxt = styled(Txt)`
  text-align: center;
`
