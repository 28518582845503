import React from 'react'
import styled from 'styled-components'
import { Txt, PrimaryButton } from '@blue-agency/rogue'
import { InterviewContainer } from '../hooks/useInterview'
import { EconomyModeBox } from '@/components/EconomyModeBox'

export const WaitingRoom: React.FCX = ({ className }) => {
  const { handlePreparingFinish, organizerName, isInitialEconomyMode } =
    InterviewContainer.useContainer()

  return (
    <Box>
      <OrgName>{organizerName}</OrgName>
      <MainTxt>参加準備ができたら入室してください</MainTxt>
      <StartButton
        size="ll"
        text="入室する"
        onClick={handlePreparingFinish}
        comlinkPushParams={{ action: 'enter_interview_room' }}
      />
      {isInitialEconomyMode && <EconomyModeBoxWrapper />}
    </Box>
  )
}

const Box = styled.div`
  position: absolute;
  top: 5%;
  right: 0;
  left: 0;
  margin: 0 auto;
  max-width: 400px;
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const OrgName = styled(Txt).attrs({ bold: true })`
  text-align: center;
`
const MainTxt = styled(Txt).attrs({ size: 'xl' })`
  text-align: center;
  margin-top: 20px;
`
const StartButton = styled(PrimaryButton)`
  margin-top: 30px;
`

const EconomyModeBoxWrapper = styled(EconomyModeBox)`
  margin-top: 20px;
`
