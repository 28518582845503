import { useCallback, useEffect } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
  isOwnMicMutedState,
  ownSoraClientIdState,
  interviewWebSocketState,
  mutedStreamIdMapState,
  participantsState,
} from '@/lib/react-interview-sdk/states'
import { AudioVideoContainer } from '@/lib/react-interview-sdk/containers/AudioVideoContainer'

type MuteMessage = {
  status: 'mute'
  stream_id: string
  muted: boolean
}

const useInterviewWebSocketBroadcastMuted = () => {
  const ws = useRecoilValue(interviewWebSocketState)
  const setMutedStreamIdMap = useSetRecoilState(mutedStreamIdMapState)

  const broadcastMuted = useCallback(
    (streamId: string, muted: boolean) => {
      if (!ws) return

      const message: MuteMessage = {
        status: 'mute',
        stream_id: streamId,
        muted: muted,
      }
      ws.sendMessage(JSON.stringify(message))
      setMutedStreamIdMap((prev) => ({
        ...prev,
        [streamId]: true,
      }))
    },
    [setMutedStreamIdMap, ws]
  )

  return broadcastMuted
}

export const useMicMute = () => {
  const { audioVideo } = AudioVideoContainer.useContainer()
  const ownSoraClientId = useRecoilValue(ownSoraClientIdState)
  const participants = useRecoilValue(participantsState)

  const broadcastMuted = useInterviewWebSocketBroadcastMuted()

  const setIsOwnMicMuted = useSetRecoilState(isOwnMicMutedState)

  useEffect(() => {
    if (!audioVideo) {
      return
    }
    if (!ownSoraClientId) {
      return
    }

    const observer = (muted: boolean) => {
      broadcastMuted(ownSoraClientId, muted)
      setIsOwnMicMuted(muted)
    }

    audioVideo.realtimeSubscribeToMuteAndUnmuteLocalAudio(observer)

    return () => {
      audioVideo.realtimeUnsubscribeToMuteAndUnmuteLocalAudio(observer)
    }
  }, [audioVideo, broadcastMuted, ownSoraClientId, setIsOwnMicMuted])

  const isOwnMicMuted = useRecoilValue(isOwnMicMutedState)

  // mute状態の復元
  useEffect(() => {
    if (!ownSoraClientId) return

    if (isOwnMicMuted) {
      broadcastMuted(ownSoraClientId, isOwnMicMuted)
    }
    // NOTE: 再接続時の復元および新規入室者への通知のみ行うため、isOwnMicMutedの変更は無視
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownSoraClientId, participants, broadcastMuted])
}
