import { atomFamily } from 'recoil'
import { namespace } from './namespace'

export const ERR_INITIALIZE_MEDIA_DEVICES = 'InitializeMediaDevices'
export const ERR_SCREEN_SHARING_BLOCKED_BY_OS = 'ScreenSharingBlockedByOS'
export const ERR_SCREEN_SHARING_PERMISSION_DENIED =
  'ScreenSharingPermissionDenied'
export const ERR_MEETING_JOIN_FAILED = 'MeetingJoinFailed'

export const interviewErrorState = atomFamily<
  { reason?: string } | null,
  string
>({
  key: `${namespace}/interviewErrorState`,
  default: null,
})
