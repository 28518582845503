import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { INTERNAL_PATHS, EXTERNAL_URLS } from '@/services/urlService'
import { UnsupportedBrowser } from './UnsupportedBrowser'
import { WindowSizeContainer } from '@/containers/WindowSizeContainer'

export const UnsupportedBrowserRoutes = () => {
  const windowSize = WindowSizeContainer.useContainer()
  return (
    <Switch>
      <Route
        path={INTERNAL_PATHS.organizer.my.interviews.interview.index}
        render={() => (
          <UnsupportedBrowser
            windowSize={windowSize}
            href={EXTERNAL_URLS.recommendedEnvironment.organizer.interview}
            action="open_organizer_recommended_environment_page"
          />
        )}
      />
      <Route
        path={INTERNAL_PATHS.organizer.my.halls.entrance.index}
        render={() => (
          <UnsupportedBrowser
            windowSize={windowSize}
            href={EXTERNAL_URLS.recommendedEnvironment.organizer.seminar}
            action="open_organizer_recommended_environment_page"
          />
        )}
      />
      <Route
        path={INTERNAL_PATHS.participant.index}
        render={() => (
          <UnsupportedBrowser
            windowSize={windowSize}
            href={EXTERNAL_URLS.recommendedEnvironment.participants.base}
            action="open_participant_recommended_environment_page"
          />
        )}
      />
      <Route
        path="*"
        render={() => (
          <UnsupportedBrowser
            windowSize={windowSize}
            href={EXTERNAL_URLS.recommendedEnvironment.organizer.hr}
            action="open_organizer_recommended_environment_page"
          />
        )}
      />
    </Switch>
  )
}
