import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { SpStartModal as _SpStartModal } from './SpStartModal'
import {
  theme,
  Txt,
  Modal,
  PrimaryButton,
  WeakColorSeparator,
} from '@blue-agency/rogue'
import { InterviewContainer } from '../../hooks/useInterview'
import { GetParticipantsInterviewResponse } from '@blue-agency/proton/web/v2/yashiori_bff/yashiori_bff_service_pb'
import { FinishModal } from './FinishModal'
import { ExitModal } from './ExitModal'
import { StartScreenShareModal } from '@/components/StartScreenShareModal'
import { FinishScreenShareModal } from '@/components/FinishScreenShareModal'
import { RoomContainer } from '../hooks/useRoom'
import { InterviewMainAreaLayout } from '@/components/InterviewMainAreaLayout'
import { DeviceType } from '@/services/interviewService/types/DeviceType'
import { VideoArea } from './VideoArea'
import { UsersArea } from './UsersArea'
import { ScreenSharingLimitModal } from '@/components/ScreenSharingLimitModal'
import { ChatArea } from './ChatArea'
import { FixedControls } from '@/components/InterviewControls/FixedControls'
import { EconomyModeTxt } from '@/components/EconomyModeTxt'
import { usePrevious } from '@/hooks/usePrevious'
import { useInterviewLayout } from '../hooks/useInterviewLayout'
import { toast } from '@blue-agency/rogue'
import { UnstableNavPopupContainer } from '../../hooks/UnstableNavPopupNavContainer'
import { QualityModeLow } from '@/services/interviewService/types/Quality'
import {
  useScreenSharingSubscriberStatus,
  useUserPublishserStatus,
  useInterviewErrors,
} from '@/lib/react-interview-sdk/hooks/values'
import { useLeaveInterview } from '@/lib/react-interview-sdk/hooks/controls'

const { Status } = GetParticipantsInterviewResponse

type Props = {
  height: number
  width: number
  deviceType: DeviceType
}

export const MainArea: React.FCX<Props> = ({
  className,
  width,
  height,
  deviceType,
}) => {
  const { showExitModal } = InterviewContainer.useContainer()
  const {
    status,
    handleStart,
    showChat,
    showUsers,
    finishModal,
    startScreenShareModal,
    finishScreenShareModal,
    screenSharingLimitModal,
    chatParams,
    handleScreenShareStart,
    handleScreenShareFinish,
    quality,
  } = RoomContainer.useContainer()
  const unstableNavPopup = UnstableNavPopupContainer.useContainer()
  const { interviewControlsProps } = useInterviewLayout()
  const leaveInterview = useLeaveInterview()

  const userPublisherStatus = useUserPublishserStatus()
  const screenSharingSubscriberStatus = useScreenSharingSubscriberStatus()

  const errorMessage = useMemo(() => {
    if (userPublisherStatus.status === 'Error') {
      switch (userPublisherStatus.reason) {
        case 'AlreadyInvitedAsAnotherRole':
          return 'すでに応募者として入室済です。\n応募者用のURLから入り直してください。'
        case 'NumberOfParticipantsLimit':
          return '参加人数の上限を超えているため、\n面接に参加できません。\n社内の人事担当者様にお問い合わせください。'
        default:
          return '認証エラーが発生しました。\n画面を再読込（リロード）してください。'
      }
    }
    if (screenSharingSubscriberStatus.status === 'Error') {
      return '認証エラーが発生しました。\n画面を再読込（リロード）してください。'
    }
    if (userPublisherStatus.status === 'Disconnected') {
      return '通信エラーが発生しました。\nブラウザを再読込してください。'
    }
    if (screenSharingSubscriberStatus.status === 'Disconnected') {
      return '通信エラーが発生しました。\nブラウザを再読込してください。'
    }
    return undefined
  }, [screenSharingSubscriberStatus, userPublisherStatus])

  const prevStatus = usePrevious(status)

  useEffect(() => {
    if (status === Status.STARTED) {
      const isInitialCall = prevStatus === undefined

      if (prevStatus === Status.NOT_STARTED) {
        toast('面接が開始されました', {
          onClose: unstableNavPopup.show,
        })
      } else if (isInitialCall) {
        unstableNavPopup.show()
      }
    }
    // NOTE: unstableNavPopup ごとではなく、.show だけ入れるために disable にした
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevStatus, status, toast, unstableNavPopup.show])

  const fixedControlsProps = {
    participantType: interviewControlsProps.participantType,
    status: interviewControlsProps.status,
    muteButtonProps: interviewControlsProps.muteButtonProps,
    cameraOffButtonProps: interviewControlsProps.cameraOffButtonProps,
    backButtonProps: interviewControlsProps.backButtonProps,
    finishButtonProps: interviewControlsProps.finishButtonProps,
  }

  const showStartModal = status === Status.NOT_STARTED && !errorMessage
  const isSp = interviewControlsProps.deviceType !== 'pc'

  const {
    initializeMediaDevicesError,
    meetingJoinFailedError,
    screenSharingBlockedByOSError,
    screenSharingPermissionDenied,
  } = useInterviewErrors()
  useEffect(() => {
    if (initializeMediaDevicesError) {
      alert(
        '利用できるカメラまたはマイクが見つかりません。リロードをお試しください。'
      )
    }
  }, [initializeMediaDevicesError])
  useEffect(() => {
    if (meetingJoinFailedError) {
      alert('接続に失敗しました。リロードをお試しください。')
    }
  }, [meetingJoinFailedError])
  useEffect(() => {
    if (screenSharingBlockedByOSError) {
      alert(
        '画面を共有できません。\nPCの設定画面からブラウザに画面共有権限を付与してください。'
      )
    }
  }, [screenSharingBlockedByOSError])
  useEffect(() => {
    if (screenSharingPermissionDenied) {
      alert('ご利用のパソコンで画面共有を行う権限がない可能性があります。')
    }
  }, [screenSharingPermissionDenied])

  return (
    <Wrapper className={className}>
      <InterviewMainAreaLayout
        showUsersArea={showUsers}
        showChatArea={showChat}
        deviceType={deviceType}
        width={width}
        height={height}
        videoArea={
          showStartModal ? (
            // NOTE: 入室解除モーダル。SPの場合はメインエリアに表示する必要がある。
            isSp && <SpStartModal onClick={handleStart} />
          ) : (
            <VideoArea />
          )
        }
        usersArea={<UsersArea deviceType={deviceType} />}
        chatArea={<ChatArea chatParams={chatParams} />}
        controlsArea={<FixedControls {...fixedControlsProps} />}
      />
      {showStartModal && !isSp && (
        <>
          <PcStartMessage>
            <Txt size="l">現在は面接官のみ入室できます</Txt>
            <Txt>
              準備ができたら候補者の入室制限を解除して
              <br />
              スタートしてください（候補者と繋がります）
            </Txt>
          </PcStartMessage>
          <PcStartModal>
            <ModalWrapper>
              <SubTxts>
                <SubTxt>
                  面接官全員の準備ができたことを確認し
                  <br />
                  入室制限を解除してください。
                  <br />
                  （候補者と繋がります）
                </SubTxt>
                <SubTxt>制限解除の取り消しはできません。</SubTxt>
              </SubTxts>
              <Separator />
              <StartButton
                size="l1"
                text="入室制限を解除"
                onClick={handleStart}
                comlinkPushParams={{ action: 'start_interview' }}
              />
            </ModalWrapper>
          </PcStartModal>
        </>
      )}
      <ExitModal active={showExitModal} leaveRoom={leaveInterview} />
      <FinishModal active={finishModal.active} />
      <StartScreenShareModal
        active={startScreenShareModal.active}
        onClose={startScreenShareModal.close}
        onStart={handleScreenShareStart}
      />
      <FinishScreenShareModal
        active={finishScreenShareModal.active}
        onClose={finishScreenShareModal.close}
        onFinish={handleScreenShareFinish}
      />
      <ScreenSharingLimitModal
        active={screenSharingLimitModal.active}
        onClose={screenSharingLimitModal.close}
      />
      {errorMessage && (
        <ErrorMessageWrapper maxWidth={width - 10}>
          <Txt color={theme.color.red[2]}>{errorMessage}</Txt>
        </ErrorMessageWrapper>
      )}
      {quality.mode === QualityModeLow && <EconomyModeTxtWrapper />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
`
const PcStartModal = styled(Modal).attrs({
  active: true,
  noOverlay: true,
  size: 's',
  title: '候補者の入室制限を解除',
})`
  position: absolute;
  top: 20%;
  left: 5%;
`
const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
`
const SubTxts = styled.ul`
  font-size: ${theme.fontSize.m};
  padding: 0 20px;
  margin-left: 20px;
`
const SubTxt = styled.li`
  line-height: 1.5;
`
const Separator = styled(WeakColorSeparator)`
  margin-top: 20px;
`
const StartButton = styled(PrimaryButton)`
  margin-top: 20px;
`
const PcStartMessage = styled.div`
  position: absolute;
  top: 5%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 433px;
  height: 90px;
  background-color: ${theme.color.white[1]};
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`
const SpStartModal = styled(_SpStartModal)`
  position: absolute;
  left: 0;
  right: 0;
  top: 20px;
  margin: 0 auto;
`
const ErrorMessageWrapper = styled.div<{ maxWidth: number }>`
  ${Wrapper} & {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    top: 20px;
  }
  max-width: ${({ maxWidth }) => maxWidth}px;
  width: 360px;
  padding: 10px;
  background: ${theme.color.white[1]};
  text-align: center;
`

const EconomyModeTxtWrapper = styled(EconomyModeTxt)`
  position: absolute;
  bottom: 0;
`
