import React, { useCallback } from 'react'
import styled, { css } from 'styled-components'
import {
  theme,
  Icon as _Icon,
  TertiaryButton,
  SecondaryDangerButton,
  ActiveTertiaryButton,
  Txt,
  Button,
  ClickOutsideListener,
} from '@blue-agency/rogue'
import { YashioriTimer } from '../Timer'
import { Dropdown as _Dropdown } from '../Dropdown'
import { InterviewControlsProps } from '..'
import { DropdownContainer } from '../hooks/useDropdown'
import { ReloadModal } from '@/components/ReloadModal'
import { buttonResetStyle } from '@/styles/buttonResetStyle'
import { RedCircle as _RedCircle } from '../RedCircle'
import { UnstableConnectionModal } from '../UnstableConnectionModal'
import { UnstableNavPopup } from '../UnstableNavPopup'
import { QualityModeModal } from '../QualityModeModal'
import { QualityMode } from '@/services/interviewService/types/Quality'
import { captureException } from '@sentry/react'

type Props = InterviewControlsProps & {
  controlsHeight: number
}

export const Interviewer: React.FCX<Props> = ({
  className,
  windowSize,
  startTime,
  currentTime,
  maxSeconds,
  status,
  muteButtonProps,
  cameraOffButtonProps,
  userButtonProps,
  chatButtonProps,
  backButtonProps,
  finishButtonProps,
  controlsHeight,
  shownUnstableNavPopup,
  changeQualityModeProps,
  isKaburikuPlan,
}) => {
  const {
    showDropdown,
    handleAdditionalMenuIconClick,
    unstableConnectionModal,
    reloadModal,
    dropdownRef,
    handleOutsideClick,
    qualityModeModal,
  } = DropdownContainer.useContainer()

  const handleQualityModeOk = useCallback(
    async (val: QualityMode) => {
      if (!changeQualityModeProps) return
      try {
        await changeQualityModeProps.changeInterviewQuality(val)
        qualityModeModal.close()
      } catch (e) {
        alert('モードの設定に失敗しました。もう1度お試しください。')
        captureException(e)
      }
    },
    [changeQualityModeProps, qualityModeModal]
  )

  return (
    <Wrapper
      className={className}
      width={windowSize.width}
      height={controlsHeight}
    >
      <TimerWrapper>
        <YashioriTimer
          fontSize="xxs"
          startTime={startTime}
          currentTime={currentTime}
          maxSeconds={maxSeconds}
          isKaburikuPlan={isKaburikuPlan}
        />
      </TimerWrapper>
      <IconWrapper>
        <ReloadButtonWrapper>
          <ReloadButton
            text="再読込"
            onClick={reloadModal.open}
            icon={<Icon name="reload" />}
            direction="column"
            comlinkPushParams={{ action: 'click_reload_button_at_interview' }}
          />
        </ReloadButtonWrapper>
        <UserButtonWrapper>
          {userButtonProps.active ? (
            <ActiveUserButton
              text="参加者"
              onClick={userButtonProps.onClick}
              icon={<Icon name="presenter" />}
              direction="column"
              disabled={userButtonProps.disabled}
              comlinkPushParams={{ action: 'hide_interview_users_area' }}
            />
          ) : (
            <UserButton
              text="参加者"
              onClick={userButtonProps.onClick}
              icon={<Icon name="presenter" />}
              direction="column"
              disabled={userButtonProps.disabled}
              comlinkPushParams={{ action: 'show_interview_users_area' }}
            />
          )}
          {!!userButtonProps.count && (
            <UserButtonCount>
              <Txt size="xs" bold color={theme.color.white[1]}>
                {userButtonProps.count}
              </Txt>
            </UserButtonCount>
          )}
        </UserButtonWrapper>
        <ChatButtonWrapper>
          {chatButtonProps.active ? (
            <ActiveChatButton
              text="チャット"
              onClick={chatButtonProps.onClick}
              icon={<Icon name="chat" />}
              direction="column"
              disabled={chatButtonProps.disabled}
              comlinkPushParams={{ action: 'hide_interview_chat_area' }}
            />
          ) : (
            <ChatButton
              text="チャット"
              onClick={chatButtonProps.onClick}
              icon={<Icon name="chat" />}
              direction="column"
              disabled={chatButtonProps.disabled}
              comlinkPushParams={{ action: 'show_interview_chat_area' }}
            />
          )}
          {chatButtonProps.unread && <RedCircle />}
        </ChatButtonWrapper>
        {muteButtonProps.active ? (
          <ActiveMuteButton
            text="解除"
            onClick={muteButtonProps.onClick}
            icon={<Icon name="mute-mike" />}
            direction="column"
            disabled={muteButtonProps.disabled}
            comlinkPushParams={{ action: 'unmute_at_interview' }}
          />
        ) : (
          <MuteButton
            text="ミュート"
            onClick={muteButtonProps.onClick}
            icon={<Icon name="mike" />}
            direction="column"
            disabled={muteButtonProps.disabled}
            comlinkPushParams={{ action: 'mute_at_interview' }}
          />
        )}
        {cameraOffButtonProps.active ? (
          <ActiveMuteButton
            text="解除"
            onClick={cameraOffButtonProps.onClick}
            icon={<Icon name="movie-off" />}
            direction="column"
            disabled={cameraOffButtonProps.disabled}
            comlinkPushParams={{ action: 'camera_on_at_interview' }}
          />
        ) : (
          <MuteButton
            text="オフ"
            onClick={cameraOffButtonProps.onClick}
            icon={<Icon name="movie" />}
            direction="column"
            disabled={cameraOffButtonProps.disabled}
            comlinkPushParams={{ action: 'camera_off_at_interview' }}
          />
        )}
        {status === 'started' ? (
          <FinishButton
            text="面接終了"
            onClick={finishButtonProps?.onClick}
            icon={<Icon name="close" />}
            direction="column"
            disabled={finishButtonProps?.disabled}
            comlinkPushParams={{ action: 'open_finish_interview_modal' }}
          />
        ) : (
          <BackButton
            text="退室"
            onClick={backButtonProps?.onClick}
            icon={<Icon name="exit" />}
            direction="column"
            disabled={backButtonProps?.disabled}
            comlinkPushParams={{
              action: 'back_to_interview_preparation_room',
            }}
          />
        )}
        <AdditionalMenuIconWrapper>
          {shownUnstableNavPopup && (
            <UnstableNavPopupWrapper>
              <UnstableNavPopup arrowDirection="bottom" />
            </UnstableNavPopupWrapper>
          )}
          <AdditionalMenuIconButton
            onClick={handleAdditionalMenuIconClick}
            comlinkPushParams={{
              action: 'show_dropdown_on_interview_controls',
            }}
          >
            <Icon name="settings" />
          </AdditionalMenuIconButton>
          {showDropdown && (
            <ClickOutsideListener
              targetRef={dropdownRef}
              onClick={handleOutsideClick}
            >
              <Dropdown ref={dropdownRef} />
            </ClickOutsideListener>
          )}
          <UnstableConnectionModal
            active={unstableConnectionModal.active}
            onClose={unstableConnectionModal.close}
          />
          <ReloadModal
            active={reloadModal.active}
            onCancel={reloadModal.close}
          />
          {changeQualityModeProps && (
            <QualityModeModal
              active={qualityModeModal.active}
              onClose={qualityModeModal.close}
              qualityMode={changeQualityModeProps.quality.mode}
              onOk={handleQualityModeOk}
            />
          )}
        </AdditionalMenuIconWrapper>
      </IconWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ width: number; height: number }>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  background: ${theme.color.gray[5]};
  border-top: 1px solid ${theme.color.gray[3]};
  position: relative;
  display: flex;
  flex-flow: column;
`
const IconWrapper = styled.div`
  background: ${theme.color.gray[5]};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`

const TimerWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
`

const iconSize = css`
  height: 20px;
  width: 70px;
`
const Icon = styled(_Icon)`
  ${iconSize}
`

const buttonBase = css`
  padding: 4.5px 0 0 !important;
  height: 40px;
  width: 44px;
  font-size: 8px !important;
`
const UserButtonWrapper = styled.div`
  position: relative;
`
const UserButtonCount = styled.div`
  ${UserButtonWrapper} & {
    position: absolute;
    top: -3px;
    right: -8px;
  }
  width: 16px;
  height: 16px;
  background: ${theme.color.navy[1]};
  border: 1px solid ${theme.color.white[1]};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`
const ActiveUserButton = styled(ActiveTertiaryButton)`
  ${buttonBase}
`
const UserButton = styled(TertiaryButton)`
  ${buttonBase}
`
const MuteButton = styled(TertiaryButton)`
  ${buttonBase}
`
const ActiveMuteButton = styled(ActiveTertiaryButton)`
  ${buttonBase}
`

const ChatButtonWrapper = styled.div`
  position: relative;
`
const ChatButton = styled(TertiaryButton)`
  ${buttonBase}
`
const ActiveChatButton = styled(ActiveTertiaryButton)`
  ${buttonBase}
`
const RedCircle = styled(_RedCircle)`
  ${ChatButtonWrapper} & {
    position: absolute;
    top: -3px;
    right: -4px;
  }
`
const BackButton = styled(TertiaryButton)`
  ${buttonBase}
`
const FinishButton = styled(SecondaryDangerButton)`
  ${buttonBase}
`
const AdditionalMenuIconWrapper = styled.div``
const AdditionalMenuIconButton = styled(Button)`
  ${buttonResetStyle};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 21px;
`
const UnstableNavPopupWrapper = styled.div`
  position: absolute;
  bottom: 48px;
  right: 12px;
`
const Dropdown = styled(_Dropdown)`
  ${AdditionalMenuIconWrapper} & {
    position: absolute;
    bottom: 60px;
    right: 0;
  }
`
const ReloadButtonWrapper = styled.div`
  position: relative;
`
const ReloadButton = styled(TertiaryButton)`
  ${buttonBase}
`
