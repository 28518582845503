import React from 'react'
import styled from 'styled-components'
import { Txt as _Txt, NewTabLink } from '@blue-agency/rogue'
import { FormStep } from '@/components/OrganizerForm/types'
import { SignupContainer } from './hooks/useSignup'
import ReactMarkdown from 'react-markdown'

type Props = {
  step: FormStep
}
export const Upper: React.FCX<Props> = ({ className, step }) => {
  if (step !== 'input') return null
  return <NonInputUpper className={className} />
}

const NonInputUpper: React.FCX = ({ className }) => {
  const { promotion, isPc } = SignupContainer.useContainer()

  return (
    <Wrapper className={className} isPc={isPc}>
      <ReactMarkdown
        source={promotion.notes}
        renderers={{
          paragraph: (props) => <Txt isPc={isPc}>{props.children}</Txt>,
          link: (props) => (
            <NewTabLink href={props.href} action="open_signup_related_page">
              {props.children}
            </NewTabLink>
          ),
        }}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div<{ isPc: boolean }>`
  padding: ${({ isPc }) => (isPc ? '15px' : 0)};
`
const Txt = styled(_Txt)<{ isPc: boolean }>`
  font-size: ${({ isPc }) => (isPc ? 'auto' : '12px')};
  ${Wrapper} &:nth-of-type(n + 2) {
    margin-top: 20px;
  }
`
