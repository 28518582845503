import { useEffect, useState } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { AudioVideoFacade } from '@blue-agency/interview-sdk-js'
import { MeetingManagerContainer } from './MeetingManagerContainer'

type AudioVideoValue = AudioVideoFacade | null

const useAudioVideo = () => {
  const { meetingManager } = MeetingManagerContainer.useContainer()
  const [audioVideo, setAudioVideo] = useState<AudioVideoValue>(null)

  useEffect(() => {
    const audioVideoUpdateCb = (av: AudioVideoValue) => {
      setAudioVideo(av)
    }

    meetingManager.subscribeToAudioVideo(audioVideoUpdateCb)

    return () => meetingManager.unsubscribeFromAudioVideo(audioVideoUpdateCb)
  }, [meetingManager])

  useEffect(() => {
    if (!audioVideo) {
      return
    }

    // realtime~のAPIでエラーは発生しないように作られている
    // ref: https://aws.github.io/amazon-chime-sdk-js/modules/apioverview.html#6-mute-and-unmute-microphone-audio-with-the-real-time-api
    // sdk利用者のプログラムのバグでエラーが発生する場合はすべてfatalなエラーになる
    // e.g. 音声のミュート関連でエラーがでる場合、ユーザーから見てミュートになっていても実際にはミュートになっていないとか致命的な問題になる
    // ↑の理由でそのまま例外を投げる
    const observer = (e: Error) => {
      throw e
    }

    audioVideo.realtimeSubscribeToFatalError(observer)

    return () => {
      audioVideo.realtimeUnsubscribeToFatalError(observer)
    }
  }, [audioVideo])

  return { audioVideo }
}

export const AudioVideoContainer = createContainer(useAudioVideo)
