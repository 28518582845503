export const EXTERNAL_URLS = {
  terms: 'https://interview-maker.jp/terms/terms-of-service.html',
  externalTransmission:
    'https://interview-maker.jp/terms/external_transmission/',
  privacy: 'https://interview-maker.jp/terms/privacy-policy.html',
  participantTerms: 'https://interview-maker.jp/terms/participant-terms',
  contact: 'https://form.run/@im-contact',
  recommendedEnvironment: {
    organizer: {
      hr: 'https://stadium.kibe.la/shared/entries/9b0ea716-6027-4481-b5b4-0221e4b47861',
      interview: 'https://im-lite-cp.zendesk.com/hc/ja/articles/900005674526',
      seminar: 'https://im-lite-cp.zendesk.com/hc/ja/articles/900005753526',
    },
    participants: {
      base: 'https://im-lite-ac.zendesk.com/hc/ja/articles/900004746883',
      interview:
        'https://im-lite-ac.zendesk.com/hc/ja/articles/900004746883#h_01G6HZ0A2HVMF2SB9TJGFV7X8Z',
      seminar:
        'https://im-lite-ac.zendesk.com/hc/ja/articles/900004746883#h_01G6HYZSZ757M7Y24M3WE75KHG',
    },
  },
  help: {
    organizer: {
      top: 'https://im-lite-cp.zendesk.com/hc/ja',
      seminar:
        'https://im-lite-cp.zendesk.com/hc/ja/sections/900001663863-Web%E8%AA%AC%E6%98%8E%E4%BC%9A',
      interview: {
        top: 'https://im-lite-cp.zendesk.com/hc/ja/sections/900001663843-Web%E9%9D%A2%E6%8E%A5',
        faq: 'https://im-lite-cp.zendesk.com/hc/ja/articles/900005193283-%E3%82%88%E3%81%8F%E3%81%82%E3%82%8B%E8%B3%AA%E5%95%8F',
        screenSharing:
          'https://im-lite-cp.zendesk.com/hc/ja/articles/900005303723-%E7%94%BB%E9%9D%A2%E5%85%B1%E6%9C%89%E3%81%A7PowerPoint%E3%81%AE%E3%82%B9%E3%83%A9%E3%82%A4%E3%83%89%E3%82%B7%E3%83%A7%E3%83%BC%E3%82%92%E8%A1%A8%E7%A4%BA%E3%81%99%E3%82%8B%E6%96%B9%E6%B3%95',
        registerInterview:
          'https://im-lite-cp.zendesk.com/hc/ja/articles/900005303583-Web%E9%9D%A2%E6%8E%A5%E6%93%8D%E4%BD%9C%E3%83%9E%E3%83%8B%E3%83%A5%E3%82%A2%E3%83%AB',
      },
      presentation:
        'https://im-lite-cp.zendesk.com/hc/ja/sections/900001663883-%E8%AA%AC%E6%98%8E%E4%BC%9A%E6%92%AE%E5%BD%B1',

      environment: {
        os: 'https://im-lite-cp.zendesk.com/hc/ja/articles/900004248146-%E6%8E%A8%E5%A5%A8%E7%92%B0%E5%A2%83-%E5%AF%BE%E5%BF%9C%E3%83%96%E3%83%A9%E3%82%A6%E3%82%B6',
        mike: 'https://im-lite-cp.zendesk.com/hc/ja/articles/900005194783-%E3%83%9E%E3%82%A4%E3%82%AF%E3%81%8C%E5%8F%8D%E5%BF%9C%E3%81%97%E3%81%AA%E3%81%84-%E9%9F%B3%E5%A3%B0%E3%81%8C%E7%9B%B8%E6%89%8B%E3%81%AB%E5%B1%8A%E3%81%8B%E3%81%AA%E3%81%84',
        speaker:
          'https://im-lite-cp.zendesk.com/hc/ja/articles/900005193203-%E9%9F%B3%E5%A3%B0%E3%81%8C%E8%81%9E%E3%81%93%E3%81%88%E3%81%AA%E3%81%84',
        camera:
          'https://im-lite-cp.zendesk.com/hc/ja/articles/900004237266-%E3%82%AB%E3%83%A1%E3%83%A9%E3%81%8C%E5%8F%8D%E5%BF%9C%E3%81%97%E3%81%AA%E3%81%84-%E6%98%A0%E5%83%8F%E3%81%8C%E7%9B%B8%E6%89%8B%E3%81%AB%E5%B1%8A%E3%81%8B%E3%81%AA%E3%81%84',
        speed:
          'https://im-lite-cp.zendesk.com/hc/ja/articles/900004248146-%E6%8E%A8%E5%A5%A8%E7%92%B0%E5%A2%83-%E5%AF%BE%E5%BF%9C%E3%83%96%E3%83%A9%E3%82%A6%E3%82%B6',
      },
    },
    participants: {
      seminar:
        'https://im-lite-ac.zendesk.com/hc/ja/sections/900001568123-Web%E8%AA%AC%E6%98%8E%E4%BC%9A',
      interview:
        'https://im-lite-ac.zendesk.com/hc/ja/sections/900001568103-Web%E9%9D%A2%E6%8E%A5',
      environment: {
        os: 'https://im-lite-ac.zendesk.com/hc/ja/articles/900004746883-%E6%8E%A8%E5%A5%A8%E7%92%B0%E5%A2%83-%E5%AF%BE%E5%BF%9C%E3%83%96%E3%83%A9%E3%82%A6%E3%82%B6',
        mike: 'https://im-lite-ac.zendesk.com/hc/ja/articles/900003771926-%E3%83%9E%E3%82%A4%E3%82%AF%E3%81%8C%E5%8F%8D%E5%BF%9C%E3%81%97%E3%81%AA%E3%81%84-%E9%9F%B3%E5%A3%B0%E3%81%8C%E7%9B%B8%E6%89%8B%E3%81%AB%E5%B1%8A%E3%81%8B%E3%81%AA%E3%81%84',
        speaker:
          'https://im-lite-ac.zendesk.com/hc/ja/articles/900004680903-%E9%9F%B3%E5%A3%B0%E3%81%8C%E8%81%9E%E3%81%93%E3%81%88%E3%81%AA%E3%81%84',
        camera:
          'https://im-lite-ac.zendesk.com/hc/ja/articles/900004680883-%E3%82%AB%E3%83%A1%E3%83%A9%E3%81%8C%E5%8F%8D%E5%BF%9C%E3%81%97%E3%81%AA%E3%81%84-%E6%98%A0%E5%83%8F%E3%81%8C%E7%9B%B8%E6%89%8B%E3%81%AB%E5%B1%8A%E3%81%8B%E3%81%AA%E3%81%84',
        speed:
          'https://im-lite-ac.zendesk.com/hc/ja/articles/900003830826-%E3%82%A4%E3%83%B3%E3%82%BF%E3%83%BC%E3%83%8D%E3%83%83%E3%83%88%E3%81%AE%E6%8E%A5%E7%B6%9A%E7%8A%B6%E6%B3%81%E3%81%8C%E6%82%AA%E3%81%84',
        manned_support:
          'https://im-lite-ac.zendesk.com/hc/ja/articles/900006417463',
      },
    },
  },
  survey: {
    interviewer:
      'https://docs.google.com/forms/d/e/1FAIpQLScLUzV_5Wq6_Y7_0JoZiXARKuWBeNyJyw3OMto-NjVLC0dQGQ/viewform?usp=pp_url&entry.848323147=',
    interviewee:
      'https://docs.google.com/forms/d/e/1FAIpQLSeM8nVpHPu_DctG1XrWABmdYMEchTsAKsPWCrpVRwZ5bXqMWA/viewform?usp=pp_url&entry.848323147=',
    seminarOrganizer:
      'https://docs.google.com/forms/d/e/1FAIpQLSdq8kFDey6pd5acEO7ZAwSoZtHeMGNbAu7A6a6JgwBLyg36aw/viewform?usp=pp_url&entry.848323147=',
    seminarParticipant:
      'https://docs.google.com/forms/d/e/1FAIpQLScYSnF63r_BaqZTN9yYszXlca7gVFfIz2_E1gOYOyIEi7iN3g/viewform?usp=pp_url&entry.848323147=',
  },
  supportDesk: {
    registerInterview: 'https://form.run/@stadium-3652',
    general: 'https://form.run/@stadium-9960',
  },
  lp: {
    basic: 'https://interview-maker.jp/basic.html',
  },
  releaseInfo:
    'https://im-lite-cp.zendesk.com/hc/ja/sections/900000712146-%E3%81%8A%E7%9F%A5%E3%82%89%E3%81%9B-%E3%83%AA%E3%83%AA%E3%83%BC%E3%82%B9%E6%83%85%E5%A0%B1',
  supportPolicy:
    'https://im-lite-cp.zendesk.com/hc/ja/articles/900004248226-%E3%82%B5%E3%83%9D%E3%83%BC%E3%83%88%E3%83%87%E3%82%B9%E3%82%AF',
  rikunabi22: 'https://interview-maker.jp/rikunabi22-form.html',
  rikunabi23pre: 'https://interview-maker.jp/rikunabi23-pre',
  rikunabi23Special: 'https://interview-maker.jp/rikunabi23',
  rikunabiPaidPlan:
    'https://stadium.kibe.la/shared/entries/3bcefb02-4c4c-455e-9f3b-362631c3dcd1',
  rikunabiAdditionalService:
    'https://stadium.kibe.la/shared/entries/6417e7a7-bc28-483c-9454-902414f4aa41',
  rikunabi2023AdditionalService: 'https://form.run/@stadium-rca-change23',
  rikunabi22SupportDesk: 'https://form.run/@stadium-1594717533',
  rikunabi23SupportDesk: 'https://form.run/@stadium-rikunabi23',
  rikunabi23SpecialSupportDesk: 'https://form.run/@stadium-rikunabi23',
  presentationManual:
    'https://im-lite-cp.zendesk.com/hc/ja/articles/900005304083-%E8%AA%AC%E6%98%8E%E4%BC%9A%E6%92%AE%E5%BD%B1%E6%93%8D%E4%BD%9C-%E9%81%8B%E7%94%A8%E3%83%9E%E3%83%8B%E3%83%A5%E3%82%A2%E3%83%AB',
}
