import { useCallback } from 'react'

import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
  isOwnCameraMutedState,
  selectedVideoInputDeviceState,
  videoInputsState,
} from '@/lib/react-interview-sdk/states'
import { MeetingManagerContainer } from '@/lib/react-interview-sdk/containers/MeetingManagerContainer'
import { storeDeviceIdInLS } from '@/lib/react-interview-sdk/utils/storeDeviceIdInLS'

export const useMuteCamera = () => {
  const { meetingManager } = MeetingManagerContainer.useContainer()
  const setIsOwnCameraMuted = useSetRecoilState(isOwnCameraMutedState)

  return useCallback(() => {
    if (!meetingManager) return
    meetingManager.muteCamera().then(() => {
      setIsOwnCameraMuted(true)
    })
  }, [meetingManager, setIsOwnCameraMuted])
}

export const useUnmuteCamera = () => {
  const { meetingManager } = MeetingManagerContainer.useContainer()
  const setIsOwnCameraMuted = useSetRecoilState(isOwnCameraMutedState)
  const selectedVideoInputDevice = useRecoilValue(selectedVideoInputDeviceState)
  const videoInputs = useRecoilValue(videoInputsState)

  return useCallback(async () => {
    if (!meetingManager) return
    // カメラミュート中にデバイスを変更した場合に必要
    if (selectedVideoInputDevice) {
      await meetingManager.selectVideoInputDevice(selectedVideoInputDevice)
      storeDeviceIdInLS('videoinput', videoInputs, selectedVideoInputDevice)
    }
    await meetingManager.unmuteCamera()
    setIsOwnCameraMuted(false)
  }, [
    meetingManager,
    selectedVideoInputDevice,
    setIsOwnCameraMuted,
    videoInputs,
  ])
}
