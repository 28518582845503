import { useEffect } from 'react'
import { DeviceChangeObserver } from 'amazon-chime-sdk-js'
import { useLatest, useMountedState } from 'react-use'

import { useRecoilState } from 'recoil'
import {
  audioInputsState,
  selectedAudioInputDeviceState,
} from '@/lib/react-interview-sdk/states'
import { MeetingManagerContainer } from '@/lib/react-interview-sdk/containers/MeetingManagerContainer'
import { AudioVideoContainer } from '@/lib/react-interview-sdk/containers/AudioVideoContainer'

export const useAudioInput = () => {
  const { meetingManager } = MeetingManagerContainer.useContainer()
  const { audioVideo } = AudioVideoContainer.useContainer()

  const [audioInputs, setAudioInputs] = useRecoilState(audioInputsState)
  const [selectedAudioInputDevice, setSelectedAudioInputDevice] =
    useRecoilState(selectedAudioInputDeviceState)
  const selectedInputRef = useLatest(selectedAudioInputDevice)

  useEffect(() => {
    if (!meetingManager) return
    setSelectedAudioInputDevice(meetingManager.selectedAudioInputDevice)
  }, [meetingManager, setSelectedAudioInputDevice])

  useEffect(() => {
    if (!meetingManager) return
    const callback = (updatedAudioInputDevice: string | null): void => {
      setSelectedAudioInputDevice(updatedAudioInputDevice)
    }
    meetingManager.subscribeToSelectedAudioInputDevice(callback)

    return (): void => {
      meetingManager.unsubscribeFromSelectedAudioInputDevice(callback)
    }
  }, [
    meetingManager,
    audioInputs,
    selectedAudioInputDevice,
    setSelectedAudioInputDevice,
  ])

  const isMounted = useMountedState()

  useEffect(() => {
    const observer: DeviceChangeObserver = {
      audioInputsChanged: async (newAudioInputs: MediaDeviceInfo[]) => {
        const hasSelectedDevice = newAudioInputs.some(
          (device) => device.deviceId === selectedInputRef.current
        )

        if (
          meetingManager &&
          selectedInputRef.current &&
          !hasSelectedDevice &&
          newAudioInputs[0]
        ) {
          await meetingManager.selectAudioInputDevice(
            newAudioInputs[0].deviceId
          )
        } else if (selectedInputRef.current === 'default') {
          await audioVideo?.chooseAudioInputDevice(selectedInputRef.current)
        }

        setAudioInputs(newAudioInputs)
      },
    }

    async function initAudioInput() {
      if (!audioVideo) {
        return
      }
      if (!isMounted()) {
        return
      }

      const devices = await audioVideo.listAudioInputDevices()

      setAudioInputs(devices)
      audioVideo.addDeviceChangeObserver(observer)
    }

    initAudioInput()

    return () => {
      audioVideo?.removeDeviceChangeObserver(observer)
    }
  }, [meetingManager, audioVideo, selectedInputRef, setAudioInputs, isMounted])
}
