import React from 'react'
import styled from 'styled-components'
import { EnvironmentCheck as EnvironmentCheckComponent } from '@blue-agency/react-environment-check'
import { provide } from '@blue-agency/front-state-management'
import { headerHeight } from '@/components/Header'
import { StepContainer } from './hooks/useStep'
import { EXTERNAL_URLS } from '@/services/urlService'
import { useEnvironmentCheck } from '@/services/environmentCheckService'
import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'
import { checkInterviewBrowser } from '@/services/browserCheckService'

export const EnvironmentCheck: React.FCX = provide(OrganizerServiceContainer)(
  ({ className }) => {
    const { handleEnvironmentCheckFinish } = StepContainer.useContainer()
    const { cameraTestConnectionProps } = useEnvironmentCheck()
    return (
      <Wrapper className={className}>
        <EnvironmentCheckComponent
          userType="interviewee"
          steps={[
            { name: 'recommendForParticipant' },
            { name: 'recommendForAll' },
            {
              name: 'browser',
              helpUrl: EXTERNAL_URLS.help.participants.environment.os,
              showRecommendPc: true,
              checkBrowserFunction: checkInterviewBrowser,
            },
            {
              name: 'camera',
              helpUrl: EXTERNAL_URLS.help.participants.environment.camera,
              ...cameraTestConnectionProps,
            },
            {
              name: 'mic',
              helpUrl: EXTERNAL_URLS.help.participants.environment.mike,
            },
            {
              name: 'speaker',
              helpUrl: EXTERNAL_URLS.help.participants.environment.speaker,
            },
            {
              name: 'speed',
              helpUrl: EXTERNAL_URLS.help.participants.environment.speed,
            },
          ]}
          onFinish={handleEnvironmentCheckFinish}
        />
      </Wrapper>
    )
  }
)
const Wrapper = styled.div`
  min-height: calc(100vh - ${headerHeight}px);
`
