import React from 'react'
import styled from 'styled-components'
import { Txt as _Txt } from '@blue-agency/rogue'
import { HallRecordingPageContainer } from './hooks/useHallRecordingPage'
import { formatDate } from '@/services/dateService'

export const HallRecordingMeta: React.FCX = ({ className }) => {
  const { startedAt, sessionName, hallGuid } =
    HallRecordingPageContainer.useContainer()

  return (
    <Wrapper className={className}>
      <div>
        <Head size="xs" bold>
          ID
        </Head>
        <Txt size="s">{hallGuid}</Txt>
      </div>
      <div>
        <Head size="xs" bold>
          Web説明会名
        </Head>
        <Txt size="s">{sessionName}</Txt>
      </div>
      <div>
        <Head size="xs" bold>
          開始日時
        </Head>
        <Txt size="s">{formatDate(startedAt)}</Txt>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;

  & > * {
    margin-right: 20px;
  }
`

const Head = styled(_Txt)``

const Txt = styled(_Txt)`
  ${Wrapper} & {
    margin-top: 4px;
  }
`
