import { useMemo } from 'react'
import { useUserAgent } from '@blue-agency/react-utils'
import { createContainer } from '@blue-agency/front-state-management'

const useBrowser = () => {
  const ua = useUserAgent()

  const isInternetExplorer = useMemo(() => {
    const userAgent = window.navigator.userAgent.toLowerCase()
    return (
      userAgent.indexOf('msie') !== -1 || userAgent.indexOf('trident') !== -1
    )
  }, [])

  const isSafari = useMemo(
    () => ua.browser.name === 'Safari' || ua.browser.name === 'Mobile Safari',
    [ua]
  )
  const isFirefox = useMemo(() => ua.browser.name === 'Firefox', [ua])

  const isIOS = useMemo(() => ua.os.name === 'iOS', [ua])

  return { isInternetExplorer, isSafari, isIOS, isFirefox }
}

export const BrowserContainer = createContainer(useBrowser)
