import React from 'react'
import { Icon } from '@blue-agency/rogue'
import { Chat } from '@/components/Chat'
import { WindowSizeContainer } from '@/containers/WindowSizeContainer'
import { isPc } from '@/utils'

export const NotReady: React.FCX = ({ className }) => {
  const { isPortrait, width } = WindowSizeContainer.useContainer()
  const isSp = !isPc(width)
  return (
    <Chat
      className={className}
      messages={[]}
      title="チャット"
      titleIcon={<Icon name="chat" />}
      connected={false}
      placeholder="準備中です、しばらくお待ちください"
      onSend={() => {}}
      isPortrait={isPortrait}
      isSp={isSp}
    />
  )
}
