import { useCallback, useEffect } from 'react'
import { AudioVideoObserver } from '@blue-agency/interview-sdk-js'

import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
  localVideoTileIdState,
  pinnedTileState,
} from '@/lib/react-interview-sdk/states'
import { AudioVideoContainer } from '@/lib/react-interview-sdk/containers/AudioVideoContainer'

export const useLocalVideo = () => {
  const { audioVideo } = AudioVideoContainer.useContainer()
  const setLocalVideoTileId = useSetRecoilState(localVideoTileIdState)

  useEffect(() => {
    if (!audioVideo) {
      return
    }

    const observer: AudioVideoObserver = {
      videoTileDidUpdate: (tileState) => {
        if (!tileState.localTile || !tileState.tileId) {
          return
        }

        setLocalVideoTileId(tileState.tileId)
      },
    }
    audioVideo.addObserver(observer)

    return () => {
      audioVideo.removeObserver(observer)
    }
  }, [audioVideo, setLocalVideoTileId])
}

export const usePinLocalVideo = () => {
  const localVideoTileId = useRecoilValue(localVideoTileIdState)
  const setPinnedTile = useSetRecoilState(pinnedTileState)

  return useCallback(() => {
    if (!localVideoTileId) {
      return
    }
    setPinnedTile(localVideoTileId)
  }, [setPinnedTile, localVideoTileId])
}
