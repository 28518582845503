import { useState, useCallback } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { useModal } from '@/hooks/useModal'

type Option = {
  callback?: () => void
}

type State = {
  title: string
  callback: () => void
}

const noop = () => {}
const defaultState = { title: '', callback: noop }

const useNotPermittedModal = () => {
  const modal = useModal()
  const [state, setState] = useState<State>(defaultState)

  const open = useCallback(
    (title: string, option?: Option) => {
      setState({ title, callback: option?.callback || noop })
      modal.open()
    },
    [modal]
  )

  const close = useCallback(() => {
    state.callback()
    setState(defaultState)
    modal.close()
  }, [modal, state])

  return {
    title: state.title,
    active: modal.active,
    open,
    close,
  }
}

export const NotPermittedModalContainer = createContainer(useNotPermittedModal)
