import { HallRecordingTokenDTO } from '../types'
import { useMemo } from 'react'
import jwtDecode from 'jwt-decode'

export function useTokenExpires(token: string) {
  const expiresAt = useMemo(() => {
    let decoded: HallRecordingTokenDTO

    try {
      decoded = jwtDecode<HallRecordingTokenDTO>(token)
    } catch {
      throw new Error('failed to decode hallRecordingToken')
    }

    if (!decoded.exp) {
      throw new Error('hallRecordingToken is invalid')
    }

    return new Date(decoded.exp * 1000)
  }, [token])

  const isExpired = useMemo(() => {
    const now = new Date()

    return now > expiresAt
  }, [expiresAt])

  return {
    isExpired,
    expiresAt,
  }
}
