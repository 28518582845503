import { useEffect } from 'react'
import { initTracking as initRogueTracking } from '@blue-agency/rogue'
import { finder } from '@medv/finder'
import { History } from 'history'
import { comlinkPush } from '@/comlink'

export const useTracking = (history: History<unknown>) => {
  useEffect(() => {
    initRogueTracking({
      click: (event, comlinkPushParams) => {
        const element = event.currentTarget
        const metadataBase = {
          tagName: element.tagName,
          label: element.innerText,
          className: element.className,
          selector: finder(element),
        }
        const metadata =
          element instanceof HTMLAnchorElement
            ? { ...metadataBase, href: element.href }
            : { ...metadataBase }

        comlinkPush({
          ...comlinkPushParams,
          type: 'manual_activity',
          action: comlinkPushParams.action || `click_${element.innerText}`,
          metadata: comlinkPushParams.metadata
            ? { ...metadata, ...comlinkPushParams.metadata }
            : metadata,
        })
      },
    })

    comlinkPush({
      type: 'system_activity',
      action: 'open_page',
    })
    comlinkPush({
      type: 'system_activity',
      action: 'screen_size',
      metadata: {
        width: window.screen.width.toString(),
        height: window.screen.height.toString(),
      },
    })
  }, [])

  useEffect(() => {
    const unlisten = history.listen(() => {
      comlinkPush({
        type: 'system_activity',
        action: 'change_page',
      })
    })

    return () => {
      unlisten()
    }
  }, [history])
}
