import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'
import { cacheKey } from '@/services/bffService'
import {
  useCachedPromise,
  createContainer,
  CacheContainer,
} from '@blue-agency/front-state-management'
import { useMemo, useEffect } from 'react'
import { useTokenExpires } from './useTokenExpires'
import { useToken } from './useToken'

const useHallRecordingPage = () => {
  const token = useToken()

  const { getHallRecording } = OrganizerServiceContainer.useContainer()
  const { deleteCache } = CacheContainer.useContainer()

  const getHallRecordingRes = useCachedPromise(
    cacheKey.getHallRecording({ token }),
    async () => getHallRecording(token)
  )

  const startedAt = useMemo<Date>(() => {
    const startedAt = getHallRecordingRes.getStartedAt()

    if (!startedAt) throw new Error('startedAt not found')

    return startedAt.toDate()
  }, [getHallRecordingRes])

  const { expiresAt } = useTokenExpires(token)

  useEffect(() => {
    return () => {
      deleteCache(cacheKey.getHallRecording({ token }))
    }
  }, [deleteCache, token])

  return {
    hallGuid: getHallRecordingRes.getHallGuid(),
    sessionName: getHallRecordingRes.getSessionName(),
    startedAt,
    expiresAt,
    organizerName: getHallRecordingRes.getOrganizerName(),
    videoPlayerUrl: getHallRecordingRes.getVideoPlayerUrl(),
  }
}

export const HallRecordingPageContainer = createContainer(useHallRecordingPage)
