import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { ApplyFormParams } from '../types'

const basicStringSchema = yup
  .string()
  .required('入力してください')
  .max(255, '255文字以内で入力してください')

const rikunabiOrganizerIdSchema = yup
  .string()
  .required('入力してください')
  .matches(/^[0-9a-zA-Z]{10}$/, '半角英数字10文字で入力してください')

const emailSchema = yup
  .string()
  .required('入力してください')
  .email('メールアドレスを入力してください')
  .max(255, '255文字以内で入力してください')
const selectSchema = yup.string().required('選択してください')

const validationSchema: yup.SchemaOf<ApplyFormParams> = yup
  .object()
  .shape({
    preferredStartDateId: selectSchema,
    rikunabiOrganizerId: rikunabiOrganizerIdSchema,
    rikunabiOrganizerName: basicStringSchema,
    name: basicStringSchema,
    representativeName: basicStringSchema,
    phoneNumber: basicStringSchema,
    postCode: basicStringSchema,
    address: basicStringSchema,
    employeesNumberId: selectSchema,
    adminName: basicStringSchema,
    adminNameKana: basicStringSchema,
    adminDepartment: basicStringSchema,
    adminPhoneNumber: basicStringSchema,
    billTo: basicStringSchema,
    billingOrganizerName: yup.string().when('billTo', {
      is: 'other',
      then: basicStringSchema,
    }),
    billingName: yup.string().when('billTo', {
      is: 'other',
      then: basicStringSchema,
    }),
    billingNameKana: yup.string().when('billTo', {
      is: 'other',
      then: basicStringSchema,
    }),
    billingDepartment: yup.string().when('billTo', {
      is: 'other',
      then: basicStringSchema,
    }),
    billingPostCode: yup.string().when('billTo', {
      is: 'other',
      then: basicStringSchema,
    }),
    billingAddress: yup.string().when('billTo', {
      is: 'other',
      then: basicStringSchema,
    }),
    billingPhoneNumber: yup.string().when('billTo', {
      is: 'other',
      then: basicStringSchema,
    }),
    billingEmail: yup.string().when('billTo', {
      is: 'other',
      then: emailSchema,
    }),
    newHiresNumberId: selectSchema,
  })
  .defined()

export const useApplyForm = () => {
  const form = useForm<ApplyFormParams>({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
    defaultValues: {
      billTo: 'same',
    },
  })
  return form
}
