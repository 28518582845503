import React, {
  useState,
  useCallback,
  useMemo,
  ReactNode,
  useEffect,
} from 'react'
import styled, { css } from 'styled-components'
import { theme } from '@blue-agency/rogue'
import { ReloadModal } from '@/components/ReloadModal'
import { PortraitControls } from './PortraitControls'
import { LandscapeControls } from './LandscapeControls'
import { WindowSizeContainer } from '@/containers/WindowSizeContainer'
import { AlertboxHeightContainer } from '@/containers/AlertboxHeightContainer'

export type Props = {
  presenterVideo: ReactNode
  screenShareVideo?: ReactNode
  messages: ReactNode
  onQuestionIconClick: () => void
  screenSharing: boolean
}

const topTabHeight = 48
const controlsWidth = 60

export const SpHallLayout: React.FCX<Props> = ({
  presenterVideo,
  screenShareVideo,
  messages,
  onQuestionIconClick,
  screenSharing,
  className,
}) => {
  const [isPresenter, setIsPresenter] = useState(true)
  const [showMessages, setShowMessages] = useState(true)
  const [showReloadModal, setShowReloadModal] = useState(false)

  const handlePresenterClick = useCallback(() => setIsPresenter(true), [])
  const handleScreenShareClick = useCallback(() => setIsPresenter(false), [])
  const toggleShowMessage = useCallback(
    () => setShowMessages((prev) => !prev),
    []
  )
  const handleReloadModalOpen = useCallback(() => setShowReloadModal(true), [])
  const handleReloadModalClose = useCallback(
    () => setShowReloadModal(false),
    []
  )

  useEffect(() => {
    if (screenSharing) {
      handleScreenShareClick()
    } else {
      handlePresenterClick()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenSharing])

  const { isPortrait, ...windowSize } = WindowSizeContainer.useContainer()
  const alertboxHeight = AlertboxHeightContainer.useContainer()
  const wrapperHeight = windowSize.height - alertboxHeight
  const contentHeight = useMemo(
    () => (wrapperHeight - topTabHeight) / 2,
    [wrapperHeight]
  )

  return (
    <Wrapper
      isPortrait={isPortrait}
      height={wrapperHeight}
      width={windowSize.width}
      className={className}
    >
      {isPortrait && (
        <PortraitControls
          isPresenter={isPresenter}
          onPresenterClick={handlePresenterClick}
          onScreenShareClick={handleScreenShareClick}
          onQuestionClick={onQuestionIconClick}
          onReloadClick={handleReloadModalOpen}
        />
      )}
      <VideoArea
        isPortrait={isPortrait}
        show={isPresenter}
        wide={!showMessages}
        height={contentHeight}
      >
        {presenterVideo}
      </VideoArea>
      <VideoArea
        isPortrait={isPortrait}
        show={!isPresenter && screenSharing}
        wide={!showMessages}
        height={contentHeight}
      >
        {screenShareVideo}
      </VideoArea>
      <VideoArea
        isPortrait={isPortrait}
        show={!isPresenter && !screenSharing}
        wide={!showMessages}
        height={contentHeight}
      >
        <NotScreenSharing>映像が配信されていません</NotScreenSharing>
      </VideoArea>
      {/* TODO: Messageを隠しているときに最新のメッセージだけ表示する */}
      <MessageArea
        isPortrait={isPortrait}
        show={showMessages}
        height={contentHeight}
      >
        {messages}
      </MessageArea>
      {!isPortrait && (
        <LandscapeControls
          isPresenter={isPresenter}
          showMessages={showMessages}
          onQAClick={toggleShowMessage}
          onPresenterClick={handlePresenterClick}
          onScreenShareClick={handleScreenShareClick}
          onReloadClick={handleReloadModalOpen}
          onQuestionClick={onQuestionIconClick}
        />
      )}
      <ReloadModal active={showReloadModal} onCancel={handleReloadModalClose} />
    </Wrapper>
  )
}

type WrapperProps = { isPortrait: boolean; height: number; width: number }
const Wrapper = styled.div<WrapperProps>`
  ${({ isPortrait, height, width }) =>
    !isPortrait
      ? css`
          display: flex;
          height: ${height}px;
          width: ${width}px;
        `
      : css`
          display: flex;
          flex-direction: column;
          width: 100%;
        `}
`
type VideoAreaProps = {
  isPortrait: boolean
  show: boolean
  wide: boolean
  height: number
}
const VideoArea = styled.div<VideoAreaProps>`
  background: ${theme.color.black[1]};
  ${({ show }) =>
    !show &&
    css`
      display: none;
    `}
  ${Wrapper} && {
    ${({ isPortrait, wide, height }) =>
      !isPortrait
        ? wide
          ? css`
              width: calc(100% - ${controlsWidth}px);
            `
          : css`
              // MessageArea の 28%
              width: calc(100% - 28% - ${controlsWidth}px);
            `
        : css`
            height: ${height}px;
          `}
  }
`
type MessageAreaProps = {
  isPortrait: boolean
  show: boolean
  height: number
}
const MessageArea = styled.div<MessageAreaProps>`
  ${({ isPortrait, show, height }) =>
    !isPortrait
      ? css`
          display: ${show ? 'block' : 'none'};
          ${Wrapper} && {
            width: 28%;
          }
        `
      : css`
          ${Wrapper} && {
            height: ${height}px;
          }
        `}
`
const NotScreenSharing = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.color.white[1]};
  ${VideoArea} && {
    width: 100%;
    height: 100%;
  }
`
