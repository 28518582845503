import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  ReactNode,
} from 'react'
import styled, { css } from 'styled-components'
import { theme, Txt } from '@blue-agency/rogue'
import { SubmitHandler, useForm } from 'react-hook-form'
import { overflowYScrollStyle } from '@/styles/overflowYScrollStyle'
import { SendButton, chatSendButtonWidth } from '@/components/Chat/SendButton'
import { Messages, Message } from './Messages'

type Props = {
  messages: Message[]
  onSend: (text: string) => void
  title: string
  titleIcon: ReactNode
  placeholder: string
  hidden?: boolean
  connected?: boolean
  height?: number
  chatFontSize?: keyof typeof theme.fontSize
  isPortrait: boolean
  isSp: boolean
}
type FormParams = {
  text: string
}

export const Chat: React.FCX<Props> = ({
  className,
  messages,
  onSend,
  title,
  titleIcon,
  placeholder,
  hidden = false,
  connected = false,
  height,
  chatFontSize = 's',
  isPortrait,
  isSp,
}) => {
  const { register, handleSubmit, setValue } = useForm<FormParams>()
  const chatBottomRef = useRef<HTMLDivElement>(null)
  const [rows, setRows] = useState(1)

  const onSubmit = useCallback<SubmitHandler<FormParams>>(
    (data) => {
      if (data.text === '') return
      setRows(1)
      setValue('text', '')
      onSend(data.text)
    },
    [setValue, onSend]
  )

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const lines = (event.target.value + '\n')?.match(/\n/g)?.length || 1
      setRows(Math.min(5, lines))
    },
    []
  )

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if ((e.ctrlKey || e.metaKey) && e.keyCode === 13) {
        handleSubmit(onSubmit)()
      }
    },
    [handleSubmit, onSubmit]
  )

  useEffect(() => {
    if (hidden) return
    chatBottomRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' })
  }, [messages, hidden])

  return (
    <Wrapper className={className} height={height}>
      <Title isPortrait={isPortrait}>
        {titleIcon}
        <TitleTxt bold>{title}</TitleTxt>
      </Title>
      <Scroll>
        <Messages
          messages={messages}
          chatFontSize={chatFontSize}
          isPortrait={isPortrait}
        />
        <div ref={chatBottomRef} />
      </Scroll>
      <ChatForm onSubmit={handleSubmit(onSubmit)} isPortrait={isPortrait}>
        <Textarea
          name="text"
          disabled={!connected}
          isSp={isSp}
          isPortrait={isPortrait}
          placeholder={placeholder}
          rows={rows}
          ref={register}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
        <SendButton disabled={!connected} />
      </ChatForm>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ height?: number }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  ${({ height }) =>
    height &&
    css`
      height: ${height}px;
    `}
`
const Title = styled.div<{ isPortrait: boolean }>`
  display: flex;
  align-items: center;
  height: 36px;
  padding: ${({ isPortrait }) => (isPortrait ? '8px 16px' : '8px')};
`
const TitleTxt = styled(Txt)`
  ${Title} & {
    margin-left: 10px;
  }
`
const Scroll = styled.div`
  ${overflowYScrollStyle};

  ${Wrapper} & {
    height: 100%;
  }
`
const ChatForm = styled.form<{ isPortrait: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${Wrapper} && {
    padding: ${({ isPortrait }) => (isPortrait ? '8px 16px' : '5px 8px')};
  }
`
const Textarea = styled.textarea<{ isSp: boolean; isPortrait: boolean }>`
  min-height: 43px;
  line-height: 1.4;
  color: ${theme.color.navy[1]};
  ::placeholder {
    color: ${theme.color.gray[2]};
  }
  font-size: ${theme.fontSize.s};
  padding: 4px 8px;
  background: ${theme.color.white[1]};
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid ${theme.color.gray[2]};
  resize: none;

  ${ChatForm} && {
    width: calc(100% - ${chatSendButtonWidth}px);
    margin-right: ${({ isPortrait }) => (isPortrait ? '12px' : '5px')};
  }
`
