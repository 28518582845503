import { useCallback, useState } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { INTERNAL_PATHS, fillParams } from '@/services/urlService'
import { useHistory, useParams } from 'react-router-dom'
import { EnterHallContainer } from '../../containers/EnterHallContainer'

const useEnter = () => {
  const history = useHistory()
  const { entranceGuid, token } = useParams<{
    entranceGuid?: string
    token?: string
  }>()
  if (!entranceGuid) {
    throw new Error('entranceGuid not found')
  }
  if (!token) {
    throw new Error('token not found')
  }

  const { enterHallRes, isDemo, participantsCount } =
    EnterHallContainer.useContainer()

  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const handleConfirmModalOpen = useCallback(() => {
    setShowConfirmModal(true)
  }, [])
  const handleConfirmModalClose = useCallback(() => {
    setShowConfirmModal(false)
  }, [])

  const toRoomPage = useCallback(async () => {
    history.push(
      fillParams({
        path: INTERNAL_PATHS.organizer.my.halls.entrance.room,
        params: {
          entranceGuid,
          token,
        },
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    toRoomPage,
    enterHallRes,
    showConfirmModal,
    handleConfirmModalClose,
    handleConfirmModalOpen,
    isDemo,
    participantsCount,
  }
}

export const EnterContainer = createContainer(useEnter)
