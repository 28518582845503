import { useEffect, useRef, useCallback } from 'react'
import { DefaultVideoTile, VideoTileState } from 'amazon-chime-sdk-js'

import { MeetingManagerContainer } from '@/lib/react-interview-sdk/containers/MeetingManagerContainer'
import { AudioVideoContainer } from '@/lib/react-interview-sdk/containers/AudioVideoContainer'

export const useVideoPreview = () => {
  const { meetingManager } = MeetingManagerContainer.useContainer()
  const { audioVideo } = AudioVideoContainer.useContainer()

  const elRef = useRef<HTMLVideoElement>()
  const videoRef = useCallback(
    (el: HTMLVideoElement | null) => {
      if (!el) {
        if (elRef.current) {
          DefaultVideoTile.disconnectVideoStreamFromVideoElement(
            elRef.current,
            false
          )
          elRef.current = undefined
        }
        return
      }
      meetingManager.bindClonedLocalStreamToVideoElement(el)
      elRef.current = el
    },
    [meetingManager]
  )

  useEffect(() => {
    const videoTileDidUpdate = (tileState: VideoTileState) => {
      if (
        !tileState.localTile ||
        !tileState.boundVideoStream ||
        !elRef.current
      ) {
        return
      }

      meetingManager.bindClonedLocalStreamToVideoElement(elRef.current)
    }

    audioVideo?.addObserver({ videoTileDidUpdate })

    return () => {
      audioVideo?.removeObserver({ videoTileDidUpdate })
    }
  }, [meetingManager, audioVideo])

  return { videoRef }
}
