import React, { useMemo } from 'react'
import { InputBody as PcInputBody } from '@/components/OrganizerForm/Pc/InputBody'
import { InputBody as SpInputBody } from '@/components/OrganizerForm/Sp/InputBody'
import { SignupContainer } from '../../hooks/useSignup'
import { PcInputBodyPlan } from './PcInputBodyPlan'
import { SpInputBodyPlan } from './SpInputBodyPlan'

export const Input: React.FCX = ({ className }) => {
  const { formContext, isAgreementChecked, handleCheck, promotion, isPc } =
    SignupContainer.useContainer()

  const InputBody = useMemo(() => {
    return isPc ? PcInputBody : SpInputBody
  }, [isPc])

  const InputBodyPlan = useMemo(() => {
    return isPc ? PcInputBodyPlan : SpInputBodyPlan
  }, [isPc])

  return (
    <InputBody
      className={className}
      formContext={formContext}
      checked={isAgreementChecked}
      onCheck={handleCheck}
      plan={<InputBodyPlan formContext={formContext} />}
      hideBilling={promotion.billingAmount === 0}
    />
  )
}
