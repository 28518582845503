import React from 'react'
import styled from 'styled-components'
import { Txt } from '@blue-agency/rogue'
import { ApplyPageContainer } from '../../ApplyPageContainer'
import { ConfirmStepItem } from '../../types'

export const BillingSection: React.FCX = ({ className }) => {
  const { inputtedData } = ApplyPageContainer.useContainer()
  return (
    <Wrapper className={className}>
      {billingItems.map((item, index) => (
        <Item key={index}>
          <Label>{index === 0 && '請求先情報'}</Label>
          <ItemLabel>{item.label}</ItemLabel>
          <Txt size="s">{inputtedData[item.name]}</Txt>
        </Item>
      ))}
      <Item>
        <Label />
        <ItemLabel>メールアドレス</ItemLabel>
        <Txt size="s">
          {inputtedData.billTo === 'same'
            ? '登録されたメールアドレス'
            : inputtedData.billingEmail}
        </Txt>
      </Item>
    </Wrapper>
  )
}

const billingItems: ConfirmStepItem[] = [
  {
    label: '会社名',
    name: 'billingOrganizerName',
  },
  {
    label: '氏名',
    name: 'billingName',
  },
  {
    label: '氏名（かな）',
    name: 'billingNameKana',
  },
  {
    label: '部署名',
    name: 'billingDepartment',
  },
  {
    label: '電話番号',
    name: 'billingPhoneNumber',
  },
  {
    label: '郵便番号',
    name: 'billingPostCode',
  },
  {
    label: '住所',
    name: 'billingAddress',
  },
]

const Wrapper = styled.div`
  padding: 20px;
`
const Item = styled.div`
  &:nth-of-type(n + 2) {
    margin-top: 20px;
  }
  display: flex;
  align-items: center;
`
const Label = styled(Txt)`
  width: 160px;
`
const ItemLabel = styled(Txt).attrs({ size: 's', bold: true })`
  width: 130px;
`
