import { DropdownOption } from '@blue-agency/rogue'
import { useMemo, useCallback, useState, ComponentProps } from 'react'
import { format as formatFn, eachMonthOfInterval } from 'date-fns'
import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'
import { downloadCSV } from '@/utils'
import { convertToJstDate } from '@/services/dateService'
import { toast } from '@blue-agency/rogue'
import { useCachedPromise } from '@blue-agency/front-state-management'
import { cacheKey } from '@/services/bffService'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { StatusCode } from 'grpc-web'
import { NotPermittedModalContainer } from '@/containers/NotPermittedModalContainer'
import { ListDownloadModal } from './ListDownloadModal'

type Form = {
  month: string
}

const validationSchema: yup.SchemaOf<Form> = yup
  .object()
  .shape({
    month: yup.string().required('選択してください'),
  })
  .defined()

export function useListDownloadModal(
  props: ComponentProps<typeof ListDownloadModal>
) {
  const { getOrganizer, exportInterviewsInMonthCSV } =
    OrganizerServiceContainer.useContainer()
  const notPermittedModal = NotPermittedModalContainer.useContainer()
  const { register, handleSubmit, errors, formState } = useForm<Form>({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
  })
  const [showDownloadError, setShowDownloadError] = useState(false)

  const organizer = useCachedPromise(cacheKey.getOrganizer(), getOrganizer)

  const options: DropdownOption[] = useMemo(() => {
    const now = new Date()

    const months = eachMonthOfInterval({
      start: convertToJstDate(organizer.registeredAt),
      end: convertToJstDate(now),
    }).reverse()

    return months.map((month) => ({
      label: formatFn(month, 'yyyy年M月'),
      value: month.toISOString(),
    }))
  }, [organizer.registeredAt])

  const onSubmit = useCallback<SubmitHandler<Form>>(
    async (data) => {
      const d = new Date(data.month)
      let csv: Uint8Array
      try {
        csv = await exportInterviewsInMonthCSV(d)
      } catch (e) {
        if (e.code === StatusCode.PERMISSION_DENIED) {
          props.onClose()
          notPermittedModal.open('リストダウンロード', {
            callback: props.onOpen,
          })
          return
        }
        setShowDownloadError(true)
        throw e
      }
      const fileName = `Web面接一覧${formatFn(d, 'yyyyMM')}.csv`
      downloadCSV({
        data: csv,
        fileName,
      })
      toast('リストをダウンロードしました')
      setShowDownloadError(false)
      props.onClose()
    },
    [exportInterviewsInMonthCSV, notPermittedModal, props]
  )

  return {
    options,
    showDownloadError,
    register,
    handleSubmit,
    formState,
    errors,
    onSubmit,
  }
}
