import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import {
  audioInputsState,
  selectedAudioInputDeviceState,
} from '@/lib/react-interview-sdk/states'
import { AudioVideoContainer } from '@/lib/react-interview-sdk/containers/AudioVideoContainer'

type Params = {
  waveBarLength: number
  baseBarHeight: number
  maxBarHeight: number
  fftSize?: number
}

export const useAudioInputVisualizer = (params: Params) => {
  const { audioVideo } = AudioVideoContainer.useContainer()
  const audioInputs = useRecoilValue(audioInputsState)
  const selectedAudioInputDevice = useRecoilValue(selectedAudioInputDeviceState)

  const [waveBarHeights, setWaveBarHeights] = useState<number[]>(
    Array(params.waveBarLength).fill(params.baseBarHeight)
  )

  useEffect(() => {
    if (!audioVideo || !selectedAudioInputDevice) {
      return
    }
    const analyzer = audioVideo.createAnalyserNodeForAudioInput()
    if (!analyzer) {
      return
    }
    if (params.fftSize) {
      analyzer.fftSize = params.fftSize
    }

    // getByteFrequencyDataによって返されるデータに含まれる最大周波数の概数
    // https://developer.mozilla.org/en-US/docs/Web/API/AnalyserNode/getByteFrequencyData
    const approximateMaxFrequency = analyzer.context.sampleRate / 2
    // 2000Hzくらいの周波数に対応するindex. 2000Hz: 人間にとって聞き取りやすい周波数
    const from = Math.ceil((2000 * analyzer.fftSize) / approximateMaxFrequency)

    const draw = () => {
      const freqData = new Uint8Array(analyzer.frequencyBinCount)
      analyzer.getByteFrequencyData(freqData)
      const barHeights = [...Array(params.waveBarLength).keys()].map(
        (i) =>
          params.baseBarHeight +
          (freqData[i + from] / 255) *
            (params.maxBarHeight - params.baseBarHeight)
      )
      setWaveBarHeights(barHeights)
    }

    const id = setInterval(draw)

    return () => {
      clearInterval(id)
      analyzer.disconnect()
      analyzer.removeOriginalInputs()
    }
  }, [
    audioInputs,
    audioVideo,
    params.baseBarHeight,
    params.fftSize,
    params.maxBarHeight,
    params.waveBarLength,
    selectedAudioInputDevice,
  ])

  return waveBarHeights
}
