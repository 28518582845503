import React from 'react'
import styled, { css } from 'styled-components'
import { ManualModalBody } from './ManualModalBody'
import { PrimaryButton, Txt as _Txt, Icon } from '@blue-agency/rogue'
import { TutorialStep } from '@/components/TutorialStep'
import { ManualModalContainer } from '../containers/ManualModalContainer'
import { WindowSizeContainer } from '@/containers/WindowSizeContainer'

export const First: React.FCX = ({ className }) => {
  return (
    <ManualModalBody
      className={className}
      content={<Content />}
      bottom={<Bottom />}
    />
  )
}

const Content = () => {
  const { isPortrait } = WindowSizeContainer.useContainer()

  return (
    <ContentWrapper>
      <StepWrapper isPortrait={isPortrait}>
        <TutorialStep current={1} max={3} />
        <Txt isPortrait={isPortrait} size="l">
          スマートフォンは「横向き」がおすすめ
        </Txt>
      </StepWrapper>
      <SmartphoneIcon name="smartphone" size="l" />
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div`
  padding: 18px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const StepWrapper = styled.div<{ isPortrait: boolean }>`
  display: flex;
  ${({ isPortrait }) =>
    isPortrait
      ? css`
          flex-direction: column;
          align-items: center;
        `
      : css`
          flex-direction: row;
          align-items: center;
        `}
`
const Txt = styled(_Txt)<{ isPortrait: boolean }>`
  ${ContentWrapper} && {
    ${({ isPortrait }) =>
      isPortrait
        ? css`
            margin-top: 15px;
          `
        : css`
            margin-left: 20px;
          `}
  }
`
const SmartphoneIcon = styled(Icon)`
  ${ContentWrapper} & {
    margin-top: 35px;
  }
  width: 100px;
  height: 70px;
`

const Bottom = () => {
  const { handleNext } = ManualModalContainer.useContainer()
  return (
    <BottomWrapper>
      <NextButton
        text="次に進む"
        onClick={handleNext}
        comlinkPushParams={{ action: 'to_next_manual_modal_step' }}
      />
    </BottomWrapper>
  )
}

const BottomWrapper = styled.div`
  padding: 12px 16px;
`
const NextButton = styled(PrimaryButton)`
  ${BottomWrapper} & {
    width: 100%;
  }
`
