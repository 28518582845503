import React from 'react'
import { theme, Txt, PrimaryButton, NewTabLink } from '@blue-agency/rogue'
import styled, { css } from 'styled-components'
import { format } from 'date-fns'
import { ja } from 'date-fns/locale'
import { fillParams, INTERNAL_PATHS } from '@/services/urlService'
import { useParams } from 'react-router-dom'

type Props = {
  expiresAt: Date
  expired: boolean
}

export const PaidPlan: React.FCX<Props> = ({
  className,
  expiresAt,
  expired,
}) => {
  const { token } = useParams<{ token?: string }>()
  if (!token) throw new Error('token not found')
  return (
    <Wrapper expired={expired} className={className}>
      <Left>
        {expired ? (
          <>
            <Txt color={color.expired.textPrimary} size="s">
              無料期間は終了しました
            </Txt>
            <Txt color={color.expired.textSub} size="xs">
              ※アカウントは一定期間経過後に停止されます
            </Txt>
          </>
        ) : (
          <Txt color={color.notExpired.text} size="s">
            無料期間：{stringify(expiresAt)}24時まで
          </Txt>
        )}
      </Left>
      <Right>
        <NewTabLink
          href={fillParams({
            path: INTERNAL_PATHS.organizer.my.upgradePlan,
            params: { token },
          })}
          action="open_upgrade_plan_page"
          hideIcon
        >
          <UpgradeButton
            text="有料プラン申込"
            comlinkPushParams={{ action: 'click_upgrade_button' }}
          />
        </NewTabLink>
      </Right>
    </Wrapper>
  )
}

function stringify(date: Date): string {
  return format(date, 'yyyy年M月d日', { locale: ja })
}

const color = {
  notExpired: {
    background: theme.color.green[8],
    border: theme.color.green[7],
    text: theme.color.green[4],
  },
  expired: {
    background: theme.color.red[4],
    border: theme.color.red[3],
    textPrimary: theme.color.red[2],
    textSub: theme.color.gray[2],
  },
}

const Wrapper = styled.div<{ expired: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  padding: 0px 10px;
  border-style: solid;
  border-width: 1px;
  ${({ expired }) =>
    expired
      ? css`
          background-color: ${color.expired.background};
          border-color: ${color.expired.border};
        `
      : css`
          background-color: ${color.notExpired.background};
          border-color: ${color.notExpired.border};
        `}
`

const Left = styled.div`
  ${Wrapper} & {
    height: 100%;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const Right = styled.div`
  ${Wrapper} & {
    height: 100%;
    margin-left: 16px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
`
const UpgradeButton = styled(PrimaryButton).attrs({ size: 'm1' })``
