import { useCallback } from 'react'
import { useRecoilValue } from 'recoil'
import { MeetingManagerContainer } from '@/lib/react-interview-sdk/containers/MeetingManagerContainer'
import { audioOutputsState } from '@/lib/react-interview-sdk/states'

import { storeDeviceIdInLS } from '@/lib/react-interview-sdk/utils/storeDeviceIdInLS'

export const useSelectAudioOutput = () => {
  const { meetingManager } = MeetingManagerContainer.useContainer()
  const audioOutputs = useRecoilValue(audioOutputsState)

  const selectAudioOutput = useCallback(
    async (deviceId: string) => {
      if (!meetingManager) return
      await meetingManager.selectAudioOutputDevice(deviceId)
      storeDeviceIdInLS('audiooutput', audioOutputs, deviceId)
    },
    [meetingManager, audioOutputs]
  )

  return selectAudioOutput
}
