import React from 'react'
import styled from 'styled-components'
import { H2 as _H2 } from '@/components/Heading'
import { provide } from '@blue-agency/front-state-management'
import { StepContainer } from './containers/StepContainer'

export const Body: React.FCX = provide(StepContainer)(({ className }) => {
  const { content } = StepContainer.useContainer()
  return (
    <Wrapper className={className}>
      <H2>説明会について</H2>
      <Content>{content}</Content>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const H2 = styled(_H2)`
  margin-top: 20px;
`
const Content = styled.div`
  ${Wrapper} & {
    margin-top: 20px;
    flex: 1 0 auto;
  }
`
