import UAParser from 'ua-parser-js'

export function isWindows(ua: UAParser.IResult): boolean {
  return ua.os.name === 'Windows'
}

export function isMac(ua: UAParser.IResult): boolean {
  // iPadOS の場合 ua.os.name === 'Mac OS' なので、iPadOS ではないことも確認する
  return ua.os.name === 'Mac OS' && !isIPadOS(ua)
}

export function isIOS(ua: UAParser.IResult): boolean {
  // 一応、iPadOS とは分けておく
  return ua.os.name === 'iOS' && !isIPadOS(ua)
}

function hasTouchableScreen() {
  return 'ontouchend' in document
}

// rogue 内のモジュールとやっていることは同じ
// https://github.com/blue-agency/rogue/blob/master/src/responsive/useIsIPad.ts
// 変更がある場合、どちらも変更する
export function isIPadOS(ua: UAParser.IResult): boolean {
  if (
    // NOTE: iPadはSafariでの場合UAからはmacOSとの区別がつかないので、タッチスクリーンかどうかで判別している
    (ua.os.name === 'Mac OS' && hasTouchableScreen()) ||
    ua.device.model === 'iPad'
  ) {
    return true
  }
  return false
}

// UAがmacOSになっているiPadはiPadOSのバージョンを直接UAから取れないので、Safariのバージョンを見る必要がある
export function shouldValidateIPadOSBySafariSemver(
  iPadUA: UAParser.IResult
): boolean {
  return iPadUA.os.name === 'Mac OS'
}

export function isAndroid(ua: UAParser.IResult): boolean {
  return ua.os.name === 'Android'
}
