import { ApplyForRikunabiPackageRequest } from '@blue-agency/proton/web/v2/yashiori_bff/yashiori_bff_service_pb'
const { NewHiresNumber, EmployeesNumber } = ApplyForRikunabiPackageRequest

export const employeesNumberOptions = [
  {
    label: '100名以下',
    value: EmployeesNumber.EMPLOYEES_NUMBER_1_TO_100.toString(),
  },
  {
    label: '101~500名',
    value: EmployeesNumber.EMPLOYEES_NUMBER_101_TO_500.toString(),
  },
  {
    label: '501~1,000名',
    value: EmployeesNumber.EMPLOYEES_NUMBER_501_TO_1000.toString(),
  },
  {
    label: '1,001~3,000名',
    value: EmployeesNumber.EMPLOYEES_NUMBER_1001_TO_3000.toString(),
  },
  {
    label: '3,001名以上',
    value: EmployeesNumber.EMPLOYEES_NUMBER_3001_TO_INF.toString(),
  },
]

export const newHiresNumberOptions = [
  {
    label: '1~10名',
    value: NewHiresNumber.NEW_HIRES_NUMBER_1_TO_10.toString(),
  },
  {
    label: '11~20名',
    value: NewHiresNumber.NEW_HIRES_NUMBER_11_TO_20.toString(),
  },
  {
    label: '21~50名',
    value: NewHiresNumber.NEW_HIRES_NUMBER_21_TO_50.toString(),
  },
  {
    label: '51~100名',
    value: NewHiresNumber.NEW_HIRES_NUMBER_51_TO_100.toString(),
  },
  {
    label: '101名以上',
    value: NewHiresNumber.NEW_HIRES_NUMBER_101_TO_INF.toString(),
  },
  {
    label: '未定',
    value: NewHiresNumber.NEW_HIRES_NUMBER_NOT_PLANNED.toString(),
  },
]
