import React, { useMemo, useState, useCallback } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { storageKey } from '@/constants/storageKey'
import { InterviewContainer } from '../../hooks/useInterview'
import { EnvironmentCheck } from '../EnvironmentCheck'
import { Entrance } from '../Entrance'
import { Tutorial } from '../Tutorial'
import { WaitingRoom } from '../WaitingRoom'

type Step = 'entrance' | 'environmentCheck' | 'tutorial' | 'waiting'

const useStep = () => {
  const { interviewGuid } = InterviewContainer.useContainer()
  const initialStep = useMemo(
    () => {
      const isEnvironmentCheckFinished =
        sessionStorage.getItem(
          storageKey.session.environmentCheck(interviewGuid)
        ) === 'done'
      return isEnvironmentCheckFinished ? 'waiting' : 'entrance'
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const [step, setStep] = useState<Step>(initialStep)

  const content = useMemo(() => {
    switch (step) {
      case 'entrance':
        return <Entrance />
      case 'environmentCheck':
        return <EnvironmentCheck />
      case 'tutorial':
        return <Tutorial />
      case 'waiting':
        return <WaitingRoom />
      default:
        throw new Error('Invalid step')
    }
  }, [step])

  const toEnvironmentCheck = useCallback(() => {
    setStep('environmentCheck')
  }, [])
  const handleEnvironmentCheckFinish = useCallback(() => {
    setStep('tutorial')
  }, [])
  const toWaitingRoom = useCallback(() => {
    sessionStorage.setItem(
      storageKey.session.environmentCheck(interviewGuid),
      'done'
    )
    setStep('waiting')
  }, [interviewGuid])

  return {
    content,
    handleEnvironmentCheckFinish,
    toEnvironmentCheck,
    toWaitingRoom,
    step,
  }
}

export const StepContainer = createContainer(useStep)
