import { useLocation } from 'react-router-dom'
import { useMemo } from 'react'
import { createContainer } from '@blue-agency/front-state-management'

type UserType = 'organizer' | 'participant' | 'undefined'

const organizerPattern = /^\/organizer\/?/
const participantPattern = /^\/participant\/?/

function useUserType() {
  const location = useLocation()

  const userType = useMemo<UserType>(() => {
    const { pathname } = location

    if (pathname.match(organizerPattern)) {
      return 'organizer'
    }
    if (pathname.match(participantPattern)) {
      return 'participant'
    }
    return 'undefined'
  }, [location])

  return userType
}

export const UserTypeContainer = createContainer(useUserType)
