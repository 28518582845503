import { useCallback } from 'react'
import { useRecoilValue } from 'recoil'
import { interviewWebSocketState } from '@/lib/react-interview-sdk/states'

export const useBroadcastStart = (): (() => void) => {
  const ws = useRecoilValue(interviewWebSocketState)
  const start = useCallback(() => {
    if (!ws) return
    ws.sendMessage(JSON.stringify({ status: 'start' }))
  }, [ws])

  return start
}
