import React from 'react'
import styled from 'styled-components'
import { Icon, Button, ClickOutsideListener } from '@blue-agency/rogue'
import { useMenu } from './hooks/useMenu'
import { buttonResetStyle } from '@/styles/buttonResetStyle'
import { DropdownPosition, Dropdown, MenuPlace } from '@/components/Dropdown'
import { ErrorModal } from './ErrorModal'
import { ListHallsHall } from '@/containers/OrganizerServiceContainer'

type Props = {
  tableRowRef: React.RefObject<HTMLElement>
  place: MenuPlace
  hall: ListHallsHall
  token: string
}

export const Menu: React.FCX<Props> = ({
  className,
  tableRowRef,
  place,
  hall,
  token,
}) => {
  const {
    showDropdown,
    handleMoreIconClick,
    dropdownRef,
    handleOutsideClick,
    items,
    errorMessage,

    handleErrorModalClose,
  } = useMenu(hall, token)

  return (
    <Wrapper className={className}>
      <MoreIconButton
        onClick={handleMoreIconClick}
        comlinkPushParams={{ action: 'show_dropdown_on_halls_table' }}
      >
        <Icon name="more" />
      </MoreIconButton>
      {showDropdown && (
        <ClickOutsideListener
          targetRef={dropdownRef}
          onClick={handleOutsideClick}
        >
          <DropdownPosition parentRef={tableRowRef} place={place}>
            <Dropdown items={items} ref={dropdownRef} />
          </DropdownPosition>
        </ClickOutsideListener>
      )}
      {!!errorMessage && (
        <ErrorModal
          active
          errorMessage={errorMessage}
          onClose={handleErrorModalClose}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
`
const MoreIconButton = styled(Button)`
  ${buttonResetStyle};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
`
