import { INTERNAL_PATHS, fillParams } from '@/services/urlService'
import { ListHallsHall } from '@/containers/OrganizerServiceContainer'

export function getParticipantsUrl(hall: ListHallsHall) {
  const p = fillParams({
    path: INTERNAL_PATHS.participant.halls.entrance.newParticipant,
    params: { entranceGuid: hall.participantsEntranceGuid },
  })

  return window.location.origin + p + '?openExternalBrowser=1'
}
