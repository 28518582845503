import React from 'react'
import styled, { css } from 'styled-components'
import { theme, Icon } from '@blue-agency/rogue'

type Props = {
  isPresenter: boolean
  onPresenterClick: () => void
  onScreenShareClick: () => void
  onQuestionClick: () => void
  onReloadClick: () => void
}

export const PortraitControls: React.FCX<Props> = ({
  className,
  isPresenter,
  onPresenterClick,
  onScreenShareClick,
  onQuestionClick,
  onReloadClick,
}) => {
  return (
    <Top className={className}>
      <TopTabs>
        <TopTab active={isPresenter} onClick={onPresenterClick}>
          <Icon name="presenter" size="l" />
        </TopTab>
        <TopTab active={!isPresenter} onClick={onScreenShareClick}>
          <Icon name="screen-share" size="l" />
        </TopTab>
      </TopTabs>
      <RightControls>
        <Reload active={false} onClick={onReloadClick}>
          <Icon name="reload" size="m" />
          <ReloadTxt>再読込</ReloadTxt>
        </Reload>
        <IconWrapper onClick={onQuestionClick}>
          <QuestionIcon name="question" />
        </IconWrapper>
      </RightControls>
    </Top>
  )
}

const ToggleBase = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 45px;
  border-radius: 4px;
  border: 1px solid ${theme.color.navy[1]};
  cursor: pointer;
  color: ${theme.color.navy[1]};
  background: ${theme.color.white[1]};
  ${({ active }) =>
    active &&
    css`
      color: ${theme.color.white[1]};
      background: ${theme.color.navy[1]};
    `}
`
const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  background: ${theme.color.white[1]};
  padding: 0 16px;
`
const TopTabs = styled.div`
  display: flex;
  height: 40px;
  width: 128px;
`
const Reload = styled(ToggleBase)`
  ${Top} & {
    margin-left: 8px;
  }
  padding: 3px 0;
  height: 40px;
  width: 64px;
`
const ReloadTxt = styled.span`
  font-size: ${theme.fontSize.xxs};
  ${Reload} & {
    margin-top: 3px;
  }
`
const IconWrapper = styled.div`
  ${Top} & {
    margin-left: 16px;
  }
  cursor: pointer;
`
const QuestionIcon = styled(Icon)`
  width: 22px;
  height: 22px;
`
const TopTab = styled.div<{ active: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  ${TopTabs} & {
    flex: 1 0 auto;
    height: 100%;
    &:first-of-type {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:last-of-type {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
  border: 1px solid ${theme.color.navy[1]};
  color: ${theme.color.navy[1]};
  ${({ active }) =>
    active &&
    css`
      color: ${theme.color.white[1]};
      background: ${theme.color.navy[1]};
    `}
`
const RightControls = styled.div`
  display: flex;
  align-items: center;
`
