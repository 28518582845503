import { useCallback } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { MeetingManagerContainer } from '@/lib/react-interview-sdk/containers/MeetingManagerContainer'
import {
  isOwnCameraMutedState,
  selectedVideoInputDeviceState,
  videoInputsState,
} from '@/lib/react-interview-sdk/states'
import { storeDeviceIdInLS } from '@/lib/react-interview-sdk/utils/storeDeviceIdInLS'

export const useSelectVideoInput = () => {
  const { meetingManager } = MeetingManagerContainer.useContainer()
  const isOwnCameraMuted = useRecoilValue(isOwnCameraMutedState)
  const setSelectedVideoInputDevice = useSetRecoilState(
    selectedVideoInputDeviceState
  )
  const videoInputs = useRecoilValue(videoInputsState)

  const selectVideoInput = useCallback(
    async (deviceId: string) => {
      if (isOwnCameraMuted) {
        setSelectedVideoInputDevice(deviceId)
        return
      }
      await meetingManager.selectVideoInputDevice(deviceId)
      storeDeviceIdInLS('videoinput', videoInputs, deviceId)
    },
    [isOwnCameraMuted, meetingManager, setSelectedVideoInputDevice, videoInputs]
  )

  return selectVideoInput
}
