import React, { ReactNode, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { DeviceType } from '@/services/interviewService/types/DeviceType'
import { theme } from '@blue-agency/rogue'
import { BrowserContainer } from '@/containers/BrowserContainer'
import { useInterviewMainAreaLayout } from './useInterviewMainAreaLayout'
import { overflowYScrollStyle } from '@/styles/overflowYScrollStyle'

type Props = {
  width: number
  height: number
  deviceType: DeviceType
  videoArea: ReactNode
  usersArea: ReactNode
  chatArea: ReactNode
  controlsArea: ReactNode
  showUsersArea: boolean
  showChatArea: boolean
}

export const InterviewMainAreaLayout: React.FCX<Props> = (props) => {
  const {
    className,
    width,
    height,
    deviceType,
    videoArea,
    usersArea,
    chatArea,
    controlsArea,
    showUsersArea,
    showChatArea,
  } = props
  // MEMO: Safariのバグの回避策としてUsersAreaを一瞬消した後に、再表示している
  // https://github.com/blue-agency/yashiori-front/pull/951
  const { isSafari } = BrowserContainer.useContainer()
  const [hideUsersArea, setHideUsersArea] = useState(false)
  useEffect(() => {
    if (showUsersArea && isSafari) {
      setHideUsersArea(true)
      requestAnimationFrame(() => setHideUsersArea(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showChatArea])

  const { usersAreaWidth, handleMouseDown } = useInterviewMainAreaLayout(props)

  return (
    <Wrapper
      className={className}
      width={width}
      height={height}
      deviceType={deviceType}
    >
      <VideoArea
        deviceType={deviceType}
        showUsersArea={showUsersArea}
        showChatArea={showChatArea}
        usersAreaWidth={usersAreaWidth}
      >
        {videoArea}
        {deviceType === 'pc' && (
          <Hoverable onMouseDown={handleMouseDown}>
            <Separator />
          </Hoverable>
        )}
      </VideoArea>
      <UsersArea
        deviceType={deviceType}
        hide={!showUsersArea || hideUsersArea}
        width={usersAreaWidth}
        showChatArea={showChatArea}
      >
        {usersArea}
      </UsersArea>
      <ChatArea
        deviceType={deviceType}
        hide={!showChatArea}
        showUsersArea={showUsersArea}
      >
        {chatArea}
      </ChatArea>
      {deviceType === 'pc' && controlsArea}
    </Wrapper>
  )
}

export const pcUsersAreaWidth = 422
export const pcChatAreaWidth = 250
export const spPortraitVideoAreaHeightPercentage = 59
export const spPortraitVideoAreaHeightWithChatAreaPercentage = 28.6
export const spPortraitChatAreaHeight = 254
export const spLandscapeVideoAreaWidthPercentage = 66
export const spLandscapeVideoAreaWidthWithChatPercentage = 31
export const spLandscapeChatAreaWidth = 250

const Wrapper = styled.div<{
  width: number
  height: number
  deviceType: DeviceType
}>`
  ${({ width, height }) => css`
    width: ${width}px;
    height: ${height}px;
  `}
  display: flex;
  ${({ deviceType }) =>
    deviceType === 'spPortrait' &&
    css`
      flex-direction: column;
    `}
`
const VideoArea = styled.div<{
  deviceType: DeviceType
  showUsersArea: boolean
  showChatArea: boolean
  usersAreaWidth: number
}>`
  position: relative;
  background: ${theme.color.black[1]};
  ${({ deviceType, showUsersArea, showChatArea, usersAreaWidth }) => {
    switch (deviceType) {
      case 'pc':
        const pcWidth = (() => {
          if (showUsersArea && showChatArea)
            return `calc(100% - ${usersAreaWidth + pcChatAreaWidth}px)`
          if (showUsersArea && !showChatArea)
            return `calc(100% - ${usersAreaWidth}px)`
          if (!showUsersArea && showChatArea)
            return `calc(100% - ${pcChatAreaWidth}px)`
          return '100%'
        })()
        return css`
          ${Wrapper} && {
            height: 100%;
            width: ${pcWidth};
          }
        `
      case 'spPortrait':
        const spPortraitHeight = (() => {
          if (showUsersArea && showChatArea)
            return `${spPortraitVideoAreaHeightWithChatAreaPercentage}%`
          if (!showUsersArea && !showChatArea) return '100%'
          return `${spPortraitVideoAreaHeightPercentage}%`
        })()
        return css`
          ${Wrapper} && {
            width: 100%;
            height: ${spPortraitHeight};
          }
        `
      case 'spLandscape':
        const spLandscapeWidth = (() => {
          if (showUsersArea && showChatArea)
            return `${spLandscapeVideoAreaWidthWithChatPercentage}%`
          if (showUsersArea && !showChatArea)
            return `${spLandscapeVideoAreaWidthPercentage}%`
          if (!showUsersArea && showChatArea)
            return `calc(100% - ${spLandscapeChatAreaWidth}px)`
          return '100%'
        })()
        return css`
          ${Wrapper} && {
            height: 100%;
            width: ${spLandscapeWidth};
          }
        `
    }
  }}
`
const UsersArea = styled.div<{
  deviceType: DeviceType
  hide: boolean
  width: number
  showChatArea: boolean
}>`
  ${({ deviceType, width, showChatArea }) => {
    switch (deviceType) {
      case 'pc':
        return css`
          ${Wrapper} && {
            height: 100%;
            width: ${width}px;
          }
          ${overflowYScrollStyle}
        `
      case 'spPortrait':
        return css`
          ${Wrapper} && {
            overflow-y: scroll;
            height: calc(
              100% - ${spPortraitVideoAreaHeightWithChatAreaPercentage}%
                ${showChatArea && ` - ${spPortraitChatAreaHeight}px`}
            );
            width: 100%;
          }
        `
      case 'spLandscape':
        return css`
          ${Wrapper} && {
            overflow-y: scroll;
            height: 100%;
            width: calc(
              100% -
                ${showChatArea
                  ? spLandscapeVideoAreaWidthWithChatPercentage
                  : spLandscapeVideoAreaWidthPercentage}%
                ${showChatArea && `- ${spLandscapeChatAreaWidth}px`}
            );
          }
        `
    }
  }}
  ${({ hide }) =>
    hide &&
    css`
      display: none;
    `}
  background: ${theme.color.gray[4]};
`

const ChatArea = styled.div<{
  deviceType: DeviceType
  hide: boolean
  showUsersArea: boolean
}>`
  ${({ deviceType, showUsersArea }) => {
    switch (deviceType) {
      case 'pc':
        return css`
          ${Wrapper} && {
            height: 100%;
          }
          width: ${pcChatAreaWidth}px;
        `
      case 'spPortrait':
        return css`
          ${Wrapper} && {
            overflow-y: scroll;
            height: ${showUsersArea
              ? `${spPortraitChatAreaHeight}px`
              : `calc(100% - ${spPortraitVideoAreaHeightPercentage}%)`};
            width: 100%;
          }
        `
      case 'spLandscape':
        return css`
          ${Wrapper} && {
            overflow-y: scroll;
            height: 100%;
          }
          width: ${spLandscapeChatAreaWidth}px;
        `
    }
  }}
  ${({ hide }) =>
    hide &&
    css`
      display: none;
    `}
  background: ${theme.color.white[1]};
`

const Hoverable = styled.div`
  ${VideoArea} & {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: calc(100% - 12px);
    z-index: 2;
  }
  width: 24px;
  display: flex;
  justify-content: center;
  :hover {
    cursor: ew-resize;
  }
`
const Separator = styled.div`
  ${Hoverable} & {
    height: 100%;
  }
  width: 0px;
  transition: 200ms 0s ease;
  ${Hoverable}:hover && {
    width: 4px;
    background-color: ${theme.color.green[4]};
  }
`
