import { PrimaryButton, Icon } from '@blue-agency/rogue'
import React from 'react'
import { css } from 'styled-components'

export const chatSendButtonWidth = 55

const buttonStyle = css`
  padding: 0 0 0 4px;
  width: ${chatSendButtonWidth}px;
  height: 24px;
`

type Props = {
  disabled: boolean
}

export const SendButton: React.FC<Props> = ({ disabled }) => {
  return (
    <PrimaryButton
      icon={<Icon name="send" size="m" />}
      type="submit"
      css={buttonStyle}
      disabled={disabled}
      comlinkPushParams={{
        action: 'send_chat_message',
      }}
    />
  )
}
