import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'
import { useCallback, useState, useEffect } from 'react'
import { handleCopyUrlClick } from '@/utils'
import { INTERNAL_PATHS, fillParams } from '@/services/urlService'
import { toast } from '@blue-agency/rogue'

export const useDemoHall = () => {
  const { createDemoHall } = OrganizerServiceContainer.useContainer()
  const [presenterUrl, setPresenterUrl] = useState('')
  const [participantsUrl, setParticipantsUrl] = useState('')

  useEffect(() => {
    ;(async () => {
      const createDemoHallRes = await createDemoHall()
      setPresenterUrl(
        window.location.origin +
          fillParams({
            path: INTERNAL_PATHS.organizer.my.halls.entrance.index,
            params: {
              token: 'demo',
              entranceGuid: createDemoHallRes.getPresenterEntranceGuid(),
            },
          })
      )
      setParticipantsUrl(
        window.location.origin +
          fillParams({
            path: INTERNAL_PATHS.participant.halls.entrance.newParticipant,
            params: {
              token: 'demo',
              entranceGuid: createDemoHallRes.getParticipantsEntranceGuid(),
            },
          })
      )
    })()
  }, [createDemoHall])

  const handleParticipantsUrlCopy = useCallback(() => {
    handleCopyUrlClick(participantsUrl)
    toast('参加者URLをコピーしました')
  }, [participantsUrl])

  return {
    presenterUrl,
    handleParticipantsUrlCopy,
  }
}
