import { createContainer } from '@blue-agency/front-state-management'
import { useState, useCallback, useEffect } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { toast } from '@blue-agency/rogue'
import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'
import { StatusCode } from 'grpc-web'
import { OrganizerContainer } from '../../../hooks/useOrganizer'
import { Plan } from '@blue-agency/proton/web/v2/yashiori_bff/plan_data_pb'
import { InterviewsContainer } from '@/pages/OrganizerRoutes/MyRoutes/InterviewsRoutes/InterviewsPage/hooks/useInterviews'
import { PermissionsContainer } from '@/containers/PermissionsContainer'
import { NotPermittedModalContainer } from '@/containers/NotPermittedModalContainer'
import { useCachedPromise } from '@blue-agency/front-state-management'
import { cacheKey } from '@/services/bffService'
import { Quota } from '@/services/quotaService'

const countValidationErrorMessage = '1〜1000までの半角数字で入力してください'

type Form = {
  count: number
  name?: string
}

const validationSchema: yup.SchemaOf<Form> = yup
  .object()
  .shape({
    count: yup
      .number()
      .required('入力してください')
      .typeError(countValidationErrorMessage)
      .min(1, countValidationErrorMessage)
      .max(1000, countValidationErrorMessage)
      .integer(countValidationErrorMessage),
    name: yup.string().max(25, '25文字以内で入力してください'),
  })
  .defined()

const useRegister = () => {
  const { createInterview, getInterviewsCount } =
    OrganizerServiceContainer.useContainer()
  const { organizer } = OrganizerContainer.useContainer()
  const { hasPermission } = PermissionsContainer.useContainer()
  const { reloadInterviews } = InterviewsContainer.useContainer()
  const notPermittedModal = NotPermittedModalContainer.useContainer()

  const { contractPeriod } = useCachedPromise(
    cacheKey.getInterviewsCount(),
    () => {
      return getInterviewsCount()
    }
  )
  const quota = new Quota(contractPeriod)

  const { register, handleSubmit, errors, formState } = useForm<Form>({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
    defaultValues: { count: 1 },
  })
  const [showRegisterModal, setShowRegisterModal] = useState(false)
  const [showUpgradePlanModal, setShowUpgradePlanModal] = useState(false)
  const [showExhaustedError, setShowExhaustedError] = useState(false)

  const handleRegisterModalClose = useCallback(() => {
    setShowRegisterModal(false)
  }, [])
  const handleUpgradePlanModalClose = useCallback(() => {
    setShowUpgradePlanModal(false)
  }, [])

  const handleRegisterButtonClick = useCallback(() => {
    if (!hasPermission('creatableInterview')) {
      notPermittedModal.open('Web面接登録')
      return
    }
    const { plan } = organizer
    if (
      (plan.id === Plan.Id.TRIAL_30DAYS || plan.id === Plan.Id.FREE) &&
      organizer.isPlanExpired
    ) {
      setShowUpgradePlanModal(true)
      return
    }
    setShowRegisterModal(true)
  }, [organizer, hasPermission, notPermittedModal])

  const onSubmit = useCallback<SubmitHandler<Form>>(
    async (data) => {
      try {
        await createInterview({
          count: data.count,
          name: data.name || '',
        })
      } catch (e) {
        switch (e.code) {
          case StatusCode.PERMISSION_DENIED:
            setShowRegisterModal(false)
            notPermittedModal.open('Web面接登録', {
              callback: () => setShowRegisterModal(true),
            })
            return
          case StatusCode.RESOURCE_EXHAUSTED:
            setShowExhaustedError(true)
            return
          default:
            throw e
        }
      }
      setShowRegisterModal(false)
      reloadInterviews()
      toast('面接を登録しました')
    },
    [createInterview, reloadInterviews, notPermittedModal]
  )

  useEffect(() => {
    if (!showRegisterModal) {
      setShowExhaustedError(false)
    }
  }, [showRegisterModal])

  return {
    quota,
    handleRegisterModalClose,
    showRegisterModal,
    handleUpgradePlanModalClose,
    showUpgradePlanModal,
    handleRegisterButtonClick,
    showExhaustedError,
    register,
    handleSubmit,
    errors,
    formState,
    onSubmit,
  }
}

export const RegisterContainer = createContainer(useRegister)
