import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'
import { cacheKey } from '@/services/bffService'
import {
  useCachedPromise,
  createContainer,
  CacheContainer,
} from '@blue-agency/front-state-management'
import { useMemo, useEffect } from 'react'
import { GetInterviewRecordingResponse } from '@blue-agency/proton/web/v2/yashiori_bff/yashiori_bff_service_pb'
import { useTokenExpires } from './useTokenExpires'
import { useToken } from './useToken'

export type InterviewRecordingVideoStatus = 'encoding' | 'encoded' | 'failed'

const useInterviewRecordingPage = () => {
  const token = useToken()

  const { getInterviewRecording } = OrganizerServiceContainer.useContainer()
  const { deleteCache } = CacheContainer.useContainer()

  const getInterviewRecordingRes = useCachedPromise(
    cacheKey.getInterviewRecording({ token }),
    async () => getInterviewRecording(token)
  )

  const startedAt = useMemo<Date>(() => {
    const startedAt = getInterviewRecordingRes.getStartedAt()

    if (!startedAt) throw new Error('startedAt not found')

    return startedAt.toDate()
  }, [getInterviewRecordingRes])

  const { expiresAt } = useTokenExpires(token)

  useEffect(() => {
    return () => {
      deleteCache(cacheKey.getInterviewRecording({ token }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const status = useMemo(
    () => mapStatus(getInterviewRecordingRes.getStatus()),
    [getInterviewRecordingRes]
  )

  return {
    interviewGuid: getInterviewRecordingRes.getInterviewGuid(),
    interviewName: getInterviewRecordingRes.getInterviewName(),
    status,
    startedAt,
    expiresAt,
    organizerName: getInterviewRecordingRes.getOrganizerName(),
    videoPlayerUrl: getInterviewRecordingRes.getVideoPlayerUrl(),
  }
}

export const InterviewRecordingPageContainer = createContainer(
  useInterviewRecordingPage
)

function mapStatus(
  resStatus: GetInterviewRecordingResponse.Status
): InterviewRecordingVideoStatus {
  switch (resStatus) {
    case GetInterviewRecordingResponse.Status.FAILED:
      return 'failed'
    case GetInterviewRecordingResponse.Status.ENCODING:
      return 'encoding'
    case GetInterviewRecordingResponse.Status.ENCODED:
      return 'encoded'
    default:
      throw new Error('unexpected status')
  }
}
