import React from 'react'
import { provide } from '@blue-agency/front-state-management'
import { RoomContainer } from './hooks/useRoom'
import { MainArea } from './MainArea'
import { InterviewLayout } from '@/components/InterviewLayout'
import { useInterviewLayout } from './hooks/useInterviewLayout'
import { GetPresenterInterviewResponse } from '@blue-agency/proton/web/v2/yashiori_bff/yashiori_bff_service_pb'
import { Redirect } from 'react-router-dom'
import { INTERNAL_PATHS, fillParams } from '@/services/urlService'
import { InterviewContainer } from '../hooks/useInterview'
import { UnstableNavPopupContainer } from '../hooks/UnstableNavPopupNavContainer'
import { usePreventPinchZoom } from '@/hooks/usePreventPinchZoom'
import { ReactInterviewSdkRoot, InterviewRoot } from '@/lib/react-interview-sdk'
import { captureException } from '@sentry/react'
import type { ComlinkPush as SdkComlinkPush } from '@/lib/react-interview-sdk/comlink'
import { comlinkPush as comlinkPushBase } from '@/comlink'

const { Status } = GetPresenterInterviewResponse

const RoomSubstance: React.FCX = provide(
  UnstableNavPopupContainer,
  RoomContainer
)(({ className }) => {
  const { mainAreaSize, interviewControlsProps } = useInterviewLayout()
  const { token, interviewGuid } = InterviewContainer.useContainer()
  const { status, interviewConfig } = RoomContainer.useContainer()

  usePreventPinchZoom()

  const comlinkPush: SdkComlinkPush = ({ action, metadata }) => {
    return comlinkPushBase({
      targetName: 'interviewGuid',
      targetIdStr: interviewGuid,
      action,
      metadata,
    })
  }

  if (status === Status.FINISHED) {
    return (
      <Redirect
        to={fillParams({
          path: INTERNAL_PATHS.organizer.my.interviews.interview.finish,
          params: { token, interviewGuid },
        })}
      />
    )
  }

  return (
    <InterviewRoot
      config={interviewConfig}
      captureException={captureException}
      comlinkPush={comlinkPush}
    >
      <InterviewLayout
        className={className}
        interviewControlsProps={interviewControlsProps}
        mainArea={
          <MainArea
            {...mainAreaSize}
            deviceType={interviewControlsProps.deviceType}
          />
        }
      />
    </InterviewRoot>
  )
})

export const Room = () => {
  return (
    <ReactInterviewSdkRoot>
      <RoomSubstance />
    </ReactInterviewSdkRoot>
  )
}
