import React from 'react'
import styled, { css } from 'styled-components'
import {
  SecondaryDangerButton,
  TertiaryButton,
  Icon,
  ActiveTertiaryButton,
  HelpLinkIcon,
  Timer,
} from '@blue-agency/rogue'
import { FinishModal } from '../FinishModal'
import { StartScreenShareModal } from '@/components/StartScreenShareModal'
import { FinishScreenShareModal } from '@/components/FinishScreenShareModal'
import { RecordingContainer } from '../hooks/RecordingContainer'
import { PresenterVideoContainer } from '../hooks/PresenterVideoContainer'
import { ScreenShareVideoContainer } from '../hooks/ScreenShareVideoContainer'
import { EXTERNAL_URLS } from '@/services/urlService'

export const Right: React.FCX = ({ className }) => {
  const { startTime, currentTime, maxSeconds, finishModal, handleOvertime } =
    RecordingContainer.useContainer()
  const {
    startScreenShare,
    stopScreenShare,
    screenSharing,
    startScreenShareModal,
    finishScreenShareModal,
  } = ScreenShareVideoContainer.useContainer()
  const { muted, toggleMuted, isCameraOff, toggleCameraOff } =
    PresenterVideoContainer.useContainer()

  const showScreenShareButton =
    typeof navigator.mediaDevices.getDisplayMedia !== 'undefined'

  return (
    <Wrapper className={className}>
      {startTime && currentTime ? (
        <Timer
          startTime={startTime}
          currentTime={currentTime}
          maxSeconds={maxSeconds}
          onFinish={handleOvertime}
        />
      ) : (
        <Timer
          startTime={undefined}
          currentTime={new Date()}
          maxSeconds={maxSeconds}
          onFinish={undefined}
        />
      )}
      <IconWrapper>
        <HelpLinkIcon
          href={EXTERNAL_URLS.help.organizer.presentation}
          action="open_organizer_presentation_help_page"
        />
      </IconWrapper>
      <ButtonWrapper>
        {muted ? (
          <DisableMuteButton
            text="解除"
            icon={<Icon name="mute-mike" />}
            direction="column"
            onClick={toggleMuted}
            comlinkPushParams={{ action: 'unmute_presentation_mike' }}
          />
        ) : (
          <MuteButton
            text="マイクミュート"
            icon={<Icon name="mike" />}
            direction="column"
            onClick={toggleMuted}
            comlinkPushParams={{ action: 'mute_presentation_mike' }}
          />
        )}
        {isCameraOff ? (
          <DisableCameraOffButton
            text="解除"
            icon={<Icon name="movie-off" />}
            direction="column"
            onClick={toggleCameraOff}
            comlinkPushParams={{ action: 'unmute_presentation_camera' }}
          />
        ) : (
          <CameraOffButton
            text="カメラオフ"
            icon={<Icon name="movie" />}
            direction="column"
            onClick={toggleCameraOff}
            comlinkPushParams={{ action: 'mute_presentation_camera' }}
          />
        )}
        {showScreenShareButton &&
          (screenSharing ? (
            <FinishScreenShareButton
              text="画面共有を終了"
              icon={<Icon name="screen-share" />}
              direction="column"
              onClick={finishScreenShareModal.open}
              comlinkPushParams={{ action: 'open_finish_screen_share_modal' }}
            />
          ) : (
            <StartScreenShareButton
              text="画面共有"
              icon={<Icon name="screen-share" />}
              direction="column"
              onClick={startScreenShareModal.open}
              comlinkPushParams={{ action: 'open_start_screen_share_modal' }}
            />
          ))}
        <StartScreenShareModal
          active={startScreenShareModal.active}
          onClose={startScreenShareModal.close}
          onStart={startScreenShare}
        />
        <FinishScreenShareModal
          active={finishScreenShareModal.active}
          onClose={finishScreenShareModal.close}
          onFinish={stopScreenShare}
        />
        <FinishButton
          text="撮影を終了"
          icon={<Icon name="stop-rec" />}
          direction="column"
          onClick={finishModal.open}
          comlinkPushParams={{ action: 'open_presentation_finish_modal' }}
        />
        <FinishModal />
      </ButtonWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  ${Wrapper} & {
    margin-left: 30px;
  }
`
const IconWrapper = styled.div`
  ${Wrapper} & {
    margin-left: 30px;
  }
  cursor: pointer;
`
const buttonSize = css`
  padding: 0;
  height: 50px;
  width: 90px;
`
const MuteButton = styled(TertiaryButton)`
  ${buttonSize}
`
const DisableMuteButton = styled(ActiveTertiaryButton)`
  ${buttonSize}
`
const CameraOffButton = styled(TertiaryButton)`
  ${buttonSize}
  ${ButtonWrapper} & {
    margin-left: 20px;
  }
`
const DisableCameraOffButton = styled(ActiveTertiaryButton)`
  ${buttonSize}
  ${ButtonWrapper} & {
    margin-left: 20px;
  }
`
const StartScreenShareButton = styled(TertiaryButton)`
  ${buttonSize}
  ${ButtonWrapper} & {
    margin-left: 20px;
  }
`
const FinishScreenShareButton = styled(ActiveTertiaryButton)`
  ${buttonSize}
  ${ButtonWrapper} & {
    margin-left: 20px;
  }
`
const FinishButton = styled(SecondaryDangerButton)`
  ${buttonSize}
  ${ButtonWrapper} & {
    margin-left: 20px;
  }
`
