import React from 'react'
import { theme } from '@blue-agency/rogue'
import { InterviewMessage } from './InterviewMessage'
import { SeminarMessage } from './SeminarMessage'

export type Message = {
  type: MessageType
  name: string
  text: string
}
type Props = {
  messages: Message[]
  chatFontSize: keyof typeof theme.fontSize
  isPortrait: boolean
}

export type SeminarMessageType = 'presenter' | 'participant' | 'me' | 'notice'
const interviewMessageTypes = ['interviewer', 'interviewee'] as const
export type InterviewMessageType = typeof interviewMessageTypes[number]
type MessageType = SeminarMessageType | InterviewMessageType

export const Messages: React.FC<Props> = ({
  messages,
  chatFontSize,
  isPortrait,
}) => {
  return (
    <>
      {messages.map((message, i) => {
        const isInterviewMessage = interviewMessageTypes.some(
          (type) => type === message.type
        )
        return isInterviewMessage ? (
          <InterviewMessage
            key={i}
            message={{ ...message, type: message.type as InterviewMessageType }}
            isPortrait={isPortrait}
            chatFontSize={chatFontSize}
          />
        ) : (
          <SeminarMessage
            key={i}
            message={{ ...message, type: message.type as SeminarMessageType }}
            isPortrait={isPortrait}
            chatFontSize={chatFontSize}
          />
        )
      })}
    </>
  )
}
