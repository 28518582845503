import React from 'react'
import styled from 'styled-components'
import {
  Modal,
  Txt,
  WeakColorSeparator,
  PrimaryButton,
} from '@blue-agency/rogue'
type Props = {
  active: boolean
  onClose: () => void
  onClick: () => void
}

export const UpgradePlanModal: React.FCX<Props> = ({
  className,
  active,
  onClick,
  onClose,
}) => {
  return (
    <Modal
      className={className}
      active={active}
      title="有料プラン申込"
      onClose={onClose}
    >
      <Wrapper>
        <Upper>
          <Txt size="s">
            無料期間は終了しました。
            <br />
            ご利用には有料プランへの申込みが必要です。
          </Txt>
        </Upper>
        <Separator />
        <Bottom>
          <ApplyButton
            size="l2"
            text="有料プラン申込"
            onClick={onClick}
            comlinkPushParams={{ action: 'to_upgrade_plan_page' }}
          />
        </Bottom>
      </Wrapper>
    </Modal>
  )
}

const Wrapper = styled.div``
const Upper = styled.div`
  padding: 20px 16px;
`
const Separator = styled(WeakColorSeparator)`
  ${Wrapper} & {
    margin-top: 40px;
  }
`
const Bottom = styled.div`
  height: 76px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const ApplyButton = styled(PrimaryButton)``
