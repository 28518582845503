import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { INTERNAL_PATHS } from '@/services/urlService'
import { MyPage } from './MyPage'
import { HallsRoutes } from './HallsRoutes'
import { InterviewsRoutes } from './InterviewsRoutes'
import { PresenstationsRoutes } from './PresentationsRoutes'
import { NotFound } from '@/components/NotFound'
import { UpgradePlanPage } from './UpgradePlanPage'
import { KaburikuPage } from './KaburikuPage'

export const MyRoutes = () => {
  return (
    <Switch>
      <Route
        exact
        path={INTERNAL_PATHS.organizer.my.index}
        component={MyPage}
      />
      <Route
        exact
        path={INTERNAL_PATHS.organizer.my.upgradePlan}
        component={UpgradePlanPage}
      />
      <Route
        path={INTERNAL_PATHS.organizer.my.halls.index}
        component={HallsRoutes}
      />
      <Route
        path={INTERNAL_PATHS.organizer.my.interviews.index}
        component={InterviewsRoutes}
      />
      <Route
        path={INTERNAL_PATHS.organizer.my.presentations.index}
        component={PresenstationsRoutes}
      />
      <Route
        exact
        path={INTERNAL_PATHS.organizer.my.interviews.interviewsCreate}
        component={KaburikuPage}
      />
      <Route path="*" component={NotFound} />
    </Switch>
  )
}
