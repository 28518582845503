import React from 'react'
import styled from 'styled-components'
import { Txt, Tooltip as _Tooltip } from '@blue-agency/rogue'
import { truncateString } from '@/utils'

type Props = {
  sessionName: string
}

export const LeftNode: React.FCX<Props> = ({ sessionName, className }) => {
  return (
    <Wrapper className={className}>
      {sessionName.length > 50 ? (
        <>
          <p
            data-tip
            data-for="title-tip-arrow-topLeft"
            style={{ cursor: 'text' }}
          >
            <Txt>{truncateString(sessionName, 50)}</Txt>
          </p>
          <Tooltip id="title-tip-arrow-topLeft" arrowPosition="topLeft">
            {sessionName}
          </Tooltip>
        </>
      ) : (
        <Txt>{sessionName}</Txt>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const Tooltip = styled(_Tooltip)`
  width: 1000px;
  word-break: break-all;
`
