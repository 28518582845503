import React from 'react'
import styled from 'styled-components'
import { PrimaryButton } from '@blue-agency/rogue'
import { EnterContainer } from '../containers/EnterContainer'
import { GetSessionStatusResponse } from '@blue-agency/proton/web/v2/yashiori_bff/yashiori_bff_service_pb'
const { Status } = GetSessionStatusResponse

export const Bottom: React.FCX = ({ className }) => {
  const { status, handleStart } = EnterContainer.useContainer()
  return (
    <Wrapper className={className}>
      <StartButton
        size="ll"
        text="配信開始"
        onClick={handleStart}
        disabled={status === Status.NOT_READY}
        comlinkPushParams={{ action: 'start_seminar' }}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const StartButton = styled(PrimaryButton)``
