import { SignalingNotifyConnectionCreated } from '@blue-agency/interview-sdk-js'
import { InterviewRole } from './InterviewRole'

export type MetadataMap = { [soraClientId: string]: Metadata }

export type Metadata = {
  interview_role: InterviewRole
  entry_number?: number
}

export function ensureMetadata(
  metadata: SignalingNotifyConnectionCreated['metadata']
): metadata is Metadata {
  if (
    !metadata ||
    typeof metadata === 'boolean' ||
    typeof metadata === 'number' ||
    typeof metadata === 'string' ||
    Array.isArray(metadata)
  ) {
    return false
  }
  return metadata['interview_role'] !== undefined
}

export type MutedStreamIdMap = {
  [streamId: string]: boolean
}
