import React from 'react'
import styled from 'styled-components'
import {
  Modal,
  Txt,
  WeakColorSeparator,
  NewTabLink,
  TertiaryButton,
} from '@blue-agency/rogue'
import { EXTERNAL_URLS } from '@/services/urlService'

type Props = {
  title: string
  active: boolean
  onClose: () => void
}

export const NotPermittedModal: React.FCX<Props> = ({
  className,
  title,
  active,
  onClose,
}) => {
  return (
    <Modal
      className={className}
      active={active}
      title={title}
      onClose={onClose}
    >
      <Wrapper>
        <Upper>
          <Txt size="s">
            ご契約のプランでは、こちらの機能はご利用いただけません。
            <br />
            ご利用希望の場合は
            <NewTabLink href={EXTERNAL_URLS.contact} action="open_contact_page">
              こちら
            </NewTabLink>
            からお問い合わせください。
          </Txt>
        </Upper>
        <Separator />
        <Bottom>
          <CloseButton
            size="l2"
            text="閉じる"
            onClick={onClose}
            comlinkPushParams={{
              action: 'click_close_not_permitted_modal_button',
            }}
          />
        </Bottom>
      </Wrapper>
    </Modal>
  )
}

const Wrapper = styled.div``
const Upper = styled.div`
  margin-top: 20px;
  padding: 0 20px;
`
const Separator = styled(WeakColorSeparator)`
  margin-top: 60px;
`
const Bottom = styled.div`
  height: 76px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const CloseButton = styled(TertiaryButton)``
