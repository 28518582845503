import React from 'react'
import styled from 'styled-components'
import { ApplyPageContainer } from '../../ApplyPageContainer'
import { Txt } from '@blue-agency/rogue'
import { newHiresNumberOptions } from '../../options'

export const NewHiresSection: React.FCX = ({ className }) => {
  const { inputtedData } = ApplyPageContainer.useContainer()
  const newHiresNumberLabel = newHiresNumberOptions.find(
    (newHiresNumber) => newHiresNumber.value === inputtedData.newHiresNumberId
  )?.label
  return (
    <Wrapper className={className}>
      <Item>
        <Label>採用情報</Label>
        <ItemLabel size="s" bold>
          採用予定数
        </ItemLabel>
        <Txt size="s">{newHiresNumberLabel}</Txt>
      </Item>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 20px;
`
const Item = styled.div`
  &:nth-of-type(n + 2) {
    margin-top: 20px;
  }
  display: flex;
  align-items: center;
`
const Label = styled(Txt)`
  width: 160px;
`
const ItemLabel = styled(Txt)`
  width: 130px;
`
