import React from 'react'
import styled from 'styled-components'
import {
  theme,
  PrimaryButton,
  InputText as _InputText,
  NewTabLink,
  ErrorBox as _ErrorBox,
} from '@blue-agency/rogue'
import reactStringReplace from 'react-string-replace'
import { provide } from '@blue-agency/front-state-management'
import { overflowYScrollStyle } from '@/styles/overflowYScrollStyle'
import { WindowSizeContainer } from '@/containers/WindowSizeContainer'
import { Layout } from '@/components/Layout'
import { Result } from './Result'
import { RegisterContainer } from './hooks/useRegister'
import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'
import { EXTERNAL_URLS } from '@/services/urlService'

const exhaustedErrorText = `有効期限内の面接が上限に達しています。
登録した面接が終了するまでは、新しい面接が追加できません。
上限の緩和を希望する場合は、サポートデスクまでお問い合わせください。
`

const replacedExhaustedText = reactStringReplace(
  exhaustedErrorText,
  /(サポートデスク)/,
  (match, index) => (
    <NewTabLink
      key={index}
      action="open_support_desk_from_interviews_exhausted_error"
      href={EXTERNAL_URLS.supportDesk.registerInterview}
    >
      {match}
    </NewTabLink>
  )
)

export const KaburikuPage: React.FCX = provide(
  RegisterContainer,
  OrganizerServiceContainer
)(({ className }) => {
  const windowSize = WindowSizeContainer.useContainer()
  const {
    onSubmit,
    register,
    handleSubmit,
    formState,
    showResult,
    errors,
    showExhaustedError,
  } = RegisterContainer.useContainer()
  return (
    <Layout className={className}>
      <Wrapper height={windowSize.height}>
        <Content height={windowSize.height}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            {showExhaustedError && <ErrorBox>{replacedExhaustedText}</ErrorBox>}
            <InputWrapper>
              <span>発行数</span>
              <InputText
                size="s"
                name="count"
                ref={register}
                onBlurError={errors.count?.message}
              />
              <RegisterButton
                text="ルーム発行"
                size="l2"
                type="submit"
                loading={formState.isSubmitting}
                comlinkPushParams={{
                  action: 'register_interviews_for_kaburiku',
                }}
              />
            </InputWrapper>
          </Form>
          {showResult && <Result />}
        </Content>
      </Wrapper>
    </Layout>
  )
})

const Form = styled.form``

const Wrapper = styled.div<{ height: number }>`
  height: ${({ height }) => height - 60}px;
  ${overflowYScrollStyle};
`

const InputText = styled(_InputText)`
  padding-left: 10px;
`

const InputWrapper = styled.div`
  padding-top: 50px;
  align-items: center;
  text-align: center;
  justify-content: center;
  display: flex;
`

const Content = styled.div<{ height: number }>`
  height: 100%;
  width: 80%;
  margin: auto;
  background: ${theme.color.white[1]};
`

const RegisterButton = styled(PrimaryButton)`
  margin-left: 10px;
`
const ErrorBox = styled(_ErrorBox)`
  svg {
    width: 16px;
  }

  & > *:nth-child(2) {
    display: block;
    white-space: pre-wrap;
  }
`
ErrorBox.defaultProps = { size: 's' }
