import React from 'react'
import styled from 'styled-components'
import { Layout } from '@/components/Layout'
import { H2 as _H2 } from '@/components/Heading'
import {
  HelpLinkIcon as _HelpLinkIcon,
  NewTabLink,
  PrimaryButton,
  Separator as _Separator,
  theme,
  Txt,
} from '@blue-agency/rogue'
import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'
import { provide, useCachedPromise } from '@blue-agency/front-state-management'
import { EnterContainer } from './containers/EnterContainer'
import { EXTERNAL_URLS } from '@/services/urlService'
import { ConfirmModal } from './ConfirmModal'
import reactStringReplace from 'react-string-replace'
import { useTitle } from '@/hooks/useTitle'
import { cacheKey } from '@/services/bffService'
import { format } from 'date-fns'
import ja from 'date-fns/locale/ja'

const text = `説明会の配信はこの画面から開始します。
配信開始までに、必ず推奨環境のご確認をお願いいたします。

配信時間の上限は90分間です。
また、配信準備を開始した後は、中断できませんのでご注意ください。`

export const EntrancePage: React.FCX = provide(
  EnterContainer,
  OrganizerServiceContainer
)(({ className }) => {
  const {
    enterHallRes,
    handleConfirmModalOpen,
    showConfirmModal,
    isDemo,
    participantsCount,
  } = EnterContainer.useContainer()

  const title = enterHallRes.getSessionName()
  useTitle(title)
  const scheduledAt = enterHallRes.getScheduledAt()

  return (
    <Layout
      className={className}
      rightNode={isDemo ? <DemoRightNode /> : <RightNode />}
      backgroundColor={theme.color.gray[4]}
    >
      <Padding>
        <SessionNameTxt size="l">
          {enterHallRes.getSessionName()}
        </SessionNameTxt>
        {scheduledAt && (
          <ScheduledAtTxt>
            {format(scheduledAt.toDate(), 'yyyy年M月d日(E) HH:mm', {
              locale: ja,
            })}
          </ScheduledAtTxt>
        )}
        <ParticipantsCountTxt>
          参加登録者数：{participantsCount}人
        </ParticipantsCountTxt>
        <Separator />
        <H2>発表者画面</H2>
        <Card>
          {enterHallRes.getFinishTime() ? (
            <Txt>説明会は既に終了しています</Txt>
          ) : (
            <>
              <Txt>
                {reactStringReplace(text, '推奨環境', (match, index) => (
                  <NewTabLink
                    key={index}
                    hideIcon
                    href={
                      EXTERNAL_URLS.recommendedEnvironment.organizer.seminar
                    }
                    action="open_organizer_recommended_environment_page"
                  >
                    {match}
                  </NewTabLink>
                ))}
              </Txt>
              <TermsTxt>
                <NewTabLink href={EXTERNAL_URLS.terms} action="open_terms_page">
                  利用規約
                </NewTabLink>
                と
                <NewTabLink
                  href={EXTERNAL_URLS.privacy}
                  action="open_privacy_policy_page"
                >
                  プライバシーポリシー
                </NewTabLink>
                に
              </TermsTxt>
              <StartButton
                size="ll"
                text="同意して配信準備を開始する"
                onClick={handleConfirmModalOpen}
                comlinkPushParams={{ action: 'open_seminar_confirm_modal' }}
              />
              <ConfirmModal active={showConfirmModal} />
            </>
          )}
        </Card>
      </Padding>
    </Layout>
  )
})

const Padding = styled.div`
  padding: 20px;
`
const SessionNameTxt = styled(Txt)`
  ${Padding} & {
    margin-top: 30px;
  }

  text-align: center;
`
const H2 = styled(_H2)`
  ${Padding} & {
    margin-top: 20px;
  }

  font-size: ${theme.fontSize.m};
  color: ${theme.color.green[4]};
  text-align: center;
`
const Card = styled.div`
  ${Padding} & {
    margin: 20px auto 0;
  }

  padding: 20px;
  width: 360px;
  background-color: ${theme.color.white[1]};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const StartButton = styled(PrimaryButton)`
  ${Card} & {
    margin-top: 20px;
  }
`
const TermsTxt = styled(Txt)`
  ${Card} & {
    margin-top: 20px;
    text-align: center;
  }
`

const RightNode = () => {
  const { getOrganizer } = OrganizerServiceContainer.useContainer()
  const organizer = useCachedPromise(cacheKey.getOrganizer(), getOrganizer)

  return (
    <RightNodeWrapper>
      <Txt size="s">{organizer.name}</Txt>
      <HelpLinkIcon
        href={EXTERNAL_URLS.help.organizer.seminar}
        action="open_organizer_seminar_help_page"
      />
    </RightNodeWrapper>
  )
}

const DemoRightNode = () => {
  return (
    <RightNodeWrapper>
      <Txt size="s">Web説明会テスト配信アカウント</Txt>
      <HelpLinkIcon
        href={EXTERNAL_URLS.help.organizer.seminar}
        action="open_organizer_seminar_help_page"
      />
    </RightNodeWrapper>
  )
}

const RightNodeWrapper = styled.div`
  display: flex;
  align-items: center;
`
const HelpLinkIcon = styled(_HelpLinkIcon)`
  ${RightNodeWrapper} & {
    margin-left: 20px;
  }
`

const ScheduledAtTxt = styled(Txt)`
  margin-top: 10px;
  text-align: center;
`

const ParticipantsCountTxt = styled(Txt)`
  margin-top: 5px;
  text-align: center;
`

const Separator = styled(_Separator)`
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 360px;
`
