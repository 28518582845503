import { useState, useCallback, useEffect, useRef, useReducer } from 'react'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { SendMessage } from 'react-use-websocket'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
  interviewWebSocketState,
  mutedStreamIdMapState,
  WebSocketMessageMap,
} from '@/lib/react-interview-sdk/states'

export type WsStatus = 'unknown' | 'started' | 'finished'
const MAX_HEARTBEATS_COUNT = 3
const RECONNECT_COUNT = 5
const RECONNECT_INTERVAL_MS = 3000
const HEARTBEATS_INTERVAL_MS = 5000

export const WebSocketReadyState = ReadyState

type NewType = WebSocketMessageMap

export type InterviewWebSocketMessageListenerFn<
  K extends keyof WebSocketMessageMap
> = (message: NewType[K]) => void

type InterviewWebSocketMessageListener<K extends keyof WebSocketMessageMap> = {
  status: K
  fn: InterviewWebSocketMessageListenerFn<K>
}

export type InterviewWebSocket = {
  readyState: ReadyState
  sendMessage: SendMessage
  addMessageListener: <K extends keyof WebSocketMessageMap>(
    status: K,
    listener: InterviewWebSocketMessageListenerFn<K>
  ) => void
  removeMessageListener: <K extends keyof WebSocketMessageMap>(
    status: K,
    listener: InterviewWebSocketMessageListenerFn<K>
  ) => void
}

export const useInterviewWebSocket = (socketUrl: string) => {
  const setInterviewWebSocket = useSetRecoilState(interviewWebSocketState)

  // NOTE: any を使っている理由
  // https://github.com/blue-agency/skywalker-front/pull/40#discussion_r529146791
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const messageListenersRef = useRef<InterviewWebSocketMessageListener<any>[]>(
    []
  )
  const heartbeatsCntRef = useRef(0)
  const [forcedReconnectCnt, forceReconnect] = useReducer((prev) => ++prev, 0)

  const getSocketUrl = useCallback(
    () => socketUrl,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [socketUrl, forcedReconnectCnt]
  )

  const onMessage = useCallback((event: MessageEvent) => {
    const message = (() => {
      try {
        return JSON.parse(event.data)
      } catch {
        throw new Error(`Failed to JSON.parse: ${event.data}`)
      }
    })()

    if (message.status === 'pong') {
      heartbeatsCntRef.current = 0
      return
    }

    messageListenersRef.current.forEach((listener) => {
      if (message.status === listener.status) {
        listener.fn(message)
      }
    })
  }, [])

  const { readyState, sendMessage } = useWebSocket(getSocketUrl, {
    onMessage,
    // MEMO: 正常に終了した時はcodeが1005でreasonが空文字なので、それ以外の時は必ず再接続するようにしている
    shouldReconnect: (event) => !(event.code === 1005 && event.reason === ''),
    reconnectAttempts: RECONNECT_COUNT,
    reconnectInterval: RECONNECT_INTERVAL_MS,
    // NOTE: pongなどws受信で再レンダリングが発生するのを抑止 https://github.com/robtaussig/react-use-websocket/issues/93
    filter: () => false,
  })

  useEffect(() => {
    if (readyState !== ReadyState.OPEN) return
    const intervalId = setInterval(() => {
      if (MAX_HEARTBEATS_COUNT <= heartbeatsCntRef.current) {
        forceReconnect()
        heartbeatsCntRef.current = 0
        return
      }
      heartbeatsCntRef.current++
      sendMessage(JSON.stringify({ status: 'ping' }))
    }, HEARTBEATS_INTERVAL_MS)
    return () => clearInterval(intervalId)
  }, [readyState, sendMessage])

  const addMessageListener = useCallback(
    <K extends keyof WebSocketMessageMap>(
      status: K,
      listener: InterviewWebSocketMessageListenerFn<K>
    ) => {
      const newListeners = [
        ...messageListenersRef.current,
        {
          status,
          fn: listener,
        },
      ]
      messageListenersRef.current = newListeners
    },
    []
  )

  const removeMessageListener = useCallback(
    <K extends keyof WebSocketMessageMap>(
      status: K,
      targetListener: InterviewWebSocketMessageListenerFn<K>
    ) => {
      const newListeners = messageListenersRef.current.filter((listener) => {
        return listener.status !== status && listener.fn !== targetListener
      })
      messageListenersRef.current = newListeners
    },
    []
  )

  useEffect(() => {
    setInterviewWebSocket({
      readyState,
      sendMessage,
      addMessageListener,
      removeMessageListener,
    })
  }, [
    addMessageListener,
    readyState,
    removeMessageListener,
    sendMessage,
    setInterviewWebSocket,
  ])
}

export const useInterviewWebSocketStatus = (): WsStatus => {
  const ws = useRecoilValue(interviewWebSocketState)

  const [status, setStatus] = useState<WsStatus>('unknown')

  useEffect(() => {
    if (!ws) return
    const onStart = (_msg: WebSocketMessageMap['start']) => {
      setStatus('started')
    }
    const onFinish = (_msg: WebSocketMessageMap['finish']) => {
      setStatus('finished')
    }
    ws.addMessageListener('start', onStart)
    ws.addMessageListener('finish', onFinish)

    return () => {
      ws.removeMessageListener('start', onStart)
      ws.removeMessageListener('finish', onFinish)
    }
  }, [ws])

  return status
}

export function useInterviewWebSocketWebrtcHost(
  channelId: string,
  initialWebrtcHost: string
): string
export function useInterviewWebSocketWebrtcHost(
  channelId: string | undefined,
  initialWebrtcHost: string | undefined
): string | undefined
export function useInterviewWebSocketWebrtcHost(
  channelId: string | undefined,
  initialWebrtcHost: string | undefined
): string | undefined {
  const ws = useRecoilValue(interviewWebSocketState)

  const [webrtcHost, setWebrtcHost] = useState(initialWebrtcHost)

  useEffect(() => {
    if (!ws) return
    if (!channelId || !webrtcHost) {
      return
    }
    const handle = (msg: WebSocketMessageMap['in trouble']) => {
      if (msg.action === 'change webrtc host') {
        if (channelId === msg.channel_id) {
          setWebrtcHost(msg.webrtc_host)
        }
      }
    }
    ws.addMessageListener('in trouble', handle)

    return () => {
      ws.removeMessageListener('in trouble', handle)
    }
  }, [channelId, webrtcHost, ws])

  return webrtcHost
}

export const useInterviewWebSocketMute = () => {
  const ws = useRecoilValue(interviewWebSocketState)
  const setMutedStreamIdMap = useSetRecoilState(mutedStreamIdMapState)

  useEffect(() => {
    if (!ws) return

    const onMute = (msg: WebSocketMessageMap['mute']) => {
      setMutedStreamIdMap((prev) => ({
        ...prev,
        [msg.stream_id]: msg.muted,
      }))
    }
    ws.addMessageListener('mute', onMute)

    return () => {
      ws.removeMessageListener('mute', onMute)
    }
  }, [ws, setMutedStreamIdMap])
}
