import React from 'react'
import styled from 'styled-components'
import { Layout } from '@/components/Layout'
import { Txt, theme, HelpLinkIcon } from '@blue-agency/rogue'
import { EXTERNAL_URLS } from '@/services/urlService'
import { useTitle } from '@/hooks/useTitle'

export const ExpiredPage: React.FCX = ({ className }) => {
  useTitle('有効期限切れ')

  return (
    <Layout
      className={className}
      rightNode={
        <HelpLinkIcon
          href={EXTERNAL_URLS.help.participants.interview}
          action="open_participant_interview_help_page"
        />
      }
      backgroundColor={theme.color.white[1]}
    >
      <Wrapper>
        <DescriptionTxt color={theme.color.red[2]}>
          このページにはアクセスできません。
          <br />
          応募先企業の担当者さまにお問い合わせください。
        </DescriptionTxt>
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`
const DescriptionTxt = styled(Txt)`
  text-align: center;
  ${Wrapper} & {
    margin-top: 80px;
  }
`
