import { WindowSizeContainer } from '@/containers/WindowSizeContainer'
import { GetParticipantsInterviewResponse } from '@blue-agency/proton/web/v2/yashiori_bff/yashiori_bff_service_pb'
import { MAX_INTERVIEW_SECONDS } from '@/constants'
import { getInterviewMainAreaSize, getDeviceType } from '@/utils'
import {
  ChangeDeviceProps,
  InterviewControlsProps,
} from '@/components/InterviewControls'
import { RoomContainer } from './useRoom'
import { AlertboxHeightContainer } from '@/containers/AlertboxHeightContainer'
import { UnstableNavPopupContainer } from '../../hooks/UnstableNavPopupContainer'
import { useMemo } from 'react'
import {
  useVideoInputs,
  useAudioInputs,
  useAudioOutputs,
  useSelectedVideoInputDevice,
  useSelectedAudioInputDevice,
  useSelectedAudioOutputDevice,
  useIsOwnCameraMuted,
  useParticipantsCount,
  useIsOtherScreenShared,
  useIsRunningOwnScreenShare,
  useUserPublishserStatus,
  useScreenSharingSubscriberStatus,
  useIsOwnMicMuted,
  useBgEffectCode,
  useIsUnavailableVideoInput,
} from '@/lib/react-interview-sdk/hooks/values'
import {
  useSelectAudioInput,
  useSelectVideoInput,
  useSelectAudioOutput,
  useMuteMic,
  useUnmuteMic,
  useMuteCamera,
  useUnmuteCamera,
  useSetBgEffectCode,
  useVideoPreview,
} from '@/lib/react-interview-sdk/hooks/controls'
import { InterviewContainer } from '../../hooks/useInterview'

const { Status } = GetParticipantsInterviewResponse

export const useInterviewLayout = () => {
  const { isPortrait, ...windowSize } = WindowSizeContainer.useContainer()
  const {
    status,
    isConnected,
    toggleShowUsers,
    showUsers,
    toggleShowChat,
    showChat,
    startScreenShareModal,
    finishScreenShareModal,
    unreadMessagesCnt,
  } = RoomContainer.useContainer()
  const { isKaburikuPlan } = InterviewContainer.useContainer()
  const unstableNavPopup = UnstableNavPopupContainer.useContainer()
  const deviceType = getDeviceType({
    windowWidth: windowSize.width,
    isPortrait,
  })
  const alertboxHeight = AlertboxHeightContainer.useContainer()
  const mainAreaSize = getInterviewMainAreaSize({
    deviceType,
    ...windowSize,
    alertboxHeight,
  })

  const videoInputs = useVideoInputs()
  const audioInputs = useAudioInputs()
  const audioOutputs = useAudioOutputs()
  const selectVideoInput = useSelectVideoInput()
  const selectAudioInput = useSelectAudioInput()
  const selectAudioOutput = useSelectAudioOutput()
  const selectedVideoInputDevice = useSelectedVideoInputDevice()
  const selectedAudioInputDevice = useSelectedAudioInputDevice()
  const selectedAudioOutputDevice = useSelectedAudioOutputDevice()
  const isOwnMicMuted = useIsOwnMicMuted()
  const muteMic = useMuteMic()
  const unmuteMic = useUnmuteMic()
  const isOwnCameraMuted = useIsOwnCameraMuted()
  const isUnavailableVideoInput = useIsUnavailableVideoInput()
  const muteCamera = useMuteCamera()
  const unmuteCamera = useUnmuteCamera()
  const participantsCount = useParticipantsCount()
  const isOtherScreenShared = useIsOtherScreenShared()
  const isRunningOwnScreenShare = useIsRunningOwnScreenShare()
  const bgEffectCode = useBgEffectCode()
  const setBgEffectCode = useSetBgEffectCode()
  const { videoRef: bgPreviewVideoRef } = useVideoPreview()
  const { videoRef: changeDevicePreviewVideoRef } = useVideoPreview()

  const userPublisherStatus = useUserPublishserStatus()
  const screenSharingSubscriberStatus = useScreenSharingSubscriberStatus()
  const isSignalingError =
    userPublisherStatus.status === 'Error' ||
    screenSharingSubscriberStatus.status === 'Error'

  const changeDeviceProps = useMemo<ChangeDeviceProps | undefined>(() => {
    if (deviceType === 'pc' && selectedAudioInputDevice) {
      return {
        videoRef: changeDevicePreviewVideoRef,
        cameraDevices: videoInputs ?? [],
        selectedCameraDeviceId: selectedVideoInputDevice || undefined,
        onChangeCameraDevice: selectVideoInput,
        micDevices: audioInputs ?? [],
        selectedMicDeviceId: selectedAudioInputDevice,
        onChangeMicDevice: selectAudioInput,
        muted: isOwnMicMuted,
        speakerDevices: audioOutputs,
        selectedSpeakerDeviceId: selectedAudioOutputDevice || undefined,
        onChangeSpeakerDevice: selectAudioOutput,
      }
    }
    return undefined
  }, [
    deviceType,
    selectedVideoInputDevice,
    selectedAudioInputDevice,
    changeDevicePreviewVideoRef,
    videoInputs,
    selectVideoInput,
    audioInputs,
    selectAudioInput,
    isOwnMicMuted,
    audioOutputs,
    selectedAudioOutputDevice,
    selectAudioOutput,
  ])

  const isControlDisabled = !isConnected
  const interviewControlsProps: InterviewControlsProps = {
    windowSize,
    participantType: 'interviewee',
    deviceType,
    status: status === Status.STARTED ? 'started' : 'notStarted',
    maxSeconds: MAX_INTERVIEW_SECONDS,
    muteButtonProps: {
      disabled: isControlDisabled,
      active: isOwnMicMuted,
      onClick: isOwnMicMuted ? unmuteMic : muteMic,
    },
    cameraOffButtonProps: {
      disabled: isControlDisabled || isUnavailableVideoInput,
      active: isOwnCameraMuted,
      onClick: isOwnCameraMuted ? unmuteCamera : muteCamera,
    },
    chatButtonProps: {
      disabled: isSignalingError || status !== Status.STARTED,
      active: showChat,
      onClick: toggleShowChat,
      unread: 0 < unreadMessagesCnt,
    },
    userButtonProps: {
      disabled: isSignalingError,
      active: showUsers,
      onClick: toggleShowUsers,
      count: status !== Status.STARTED ? 1 : participantsCount,
    },
    screenShareButtonProps: {
      disabled:
        isSignalingError || status !== Status.STARTED || isOtherScreenShared,
      active: isRunningOwnScreenShare,
      onClick: isRunningOwnScreenShare
        ? finishScreenShareModal.open
        : startScreenShareModal.open,
    },
    currentTime: new Date(),
    shownUnstableNavPopup: isKaburikuPlan ? false : unstableNavPopup.active,
    changeDeviceProps,
    backgroundSettingProps: isUnavailableVideoInput
      ? undefined
      : {
          videoRef: bgPreviewVideoRef,
          bgEffectCode,
          changeBgEffectCode: setBgEffectCode,
        },
    isKaburikuPlan: isKaburikuPlan,
  }

  return {
    interviewControlsProps,
    mainAreaSize,
  }
}
