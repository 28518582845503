import React from 'react'
import styled from 'styled-components'
import { Layout as _Layout } from '@/components/Layout'
import { Txt, theme } from '@blue-agency/rogue'
import { useTitle } from '@/hooks/useTitle'

export const AlreadyFinishedPage: React.FCX = ({ className }) => {
  useTitle('終了ページ')

  return (
    <Layout className={className}>
      <Wrapper>
        <TxtWrapper>
          <Txt>説明会は既に終了しています。</Txt>
        </TxtWrapper>
      </Wrapper>
    </Layout>
  )
}

const Layout = styled(_Layout)``
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  background: ${theme.color.gray[4]};
  ${Layout} & {
    height: 100%;
  }
`
const TxtWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 360px;
  height: 61px;
  background: ${theme.color.white[1]};
  ${Wrapper} & {
    margin: 41px auto 0;
  }
`
