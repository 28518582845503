import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Modal, Txt, PrimaryButton } from '@blue-agency/rogue'

type Props = {
  active: boolean
}

export const ErrorModal: React.FCX<Props> = ({ active, className }) => {
  const handleReloadClick = useCallback(() => {
    window.location.reload()
  }, [])

  return (
    <Modal
      className={className}
      active={active}
      title="撮影準備エラー"
      size="s"
    >
      <Wrapper>
        <Txt>
          撮影準備中にエラーが発生しました。
          <br />
          ページを再読み込みしてください。
        </Txt>
        <ReloadButton
          text="再読み込み"
          onClick={handleReloadClick}
          comlinkPushParams={{ action: 'reload_presentation_preparation' }}
        />
      </Wrapper>
    </Modal>
  )
}

const Wrapper = styled.div`
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const ReloadButton = styled(PrimaryButton)`
  ${Wrapper} & {
    margin-top: 20px;
  }
`
