import React from 'react'
import styled from 'styled-components'
import { Icon } from '@blue-agency/rogue'
import { OrganizerName } from './OrganizerName'

type Props = {
  organizerName: string
  handleHelpModalOpen: () => void
}
export const RightNode: React.FCX<Props> = ({
  organizerName,
  handleHelpModalOpen,
  className,
}) => (
  <Wrapper className={className}>
    <OrganizerName organizerName={organizerName} />
    <IconWrapper onClick={handleHelpModalOpen}>
      <QuestionIcon name="question" />
    </IconWrapper>
  </Wrapper>
)

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const IconWrapper = styled.div`
  ${Wrapper} & {
    margin-left: 20px;
  }
  cursor: pointer;
`
const QuestionIcon = styled(Icon)`
  width: 22px;
  height: 22px;
`
