import React from 'react'
import styled from 'styled-components'
import {
  Modal,
  Txt,
  TertiaryButton,
  DangerButton,
  Icon,
  WeakColorSeparator,
  theme,
} from '@blue-agency/rogue'
import { EnterContainer } from './containers/EnterContainer'

export const FinishModal: React.FCX = ({ className }) => {
  const { showFinishModal, handleFinishModalClose, handleFinish } =
    EnterContainer.useContainer()
  return (
    <Modal
      className={className}
      active={showFinishModal}
      title="説明会の終了"
      onClose={handleFinishModalClose}
      size="s"
    >
      <>
        <Messages>
          <Caution>
            <Icon name="exclamation" />
            <CautionTxt>説明会の配信を終了します</CautionTxt>
          </Caution>
          <Txts>
            <Txt bold>一度終了すると再開できません。</Txt>
            <Txt>本当に終了してもよろしいですか？</Txt>
          </Txts>
        </Messages>
        <Separator />
        <ButtonGroup>
          <CancelButton
            text="キャンセル"
            onClick={handleFinishModalClose}
            comlinkPushParams={{ action: 'close_seminar_finish_modal' }}
          />
          <FinishButton
            text="終了する"
            onClick={handleFinish}
            comlinkPushParams={{ action: 'finish_seminar' }}
          />
        </ButtonGroup>
      </>
    </Modal>
  )
}

const Messages = styled.div`
  margin-top: 20px;
  margin-left: 16px;
`
const Caution = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.color.red[2]};
`
const CautionTxt = styled(Txt).attrs({ size: 'l', color: theme.color.red[2] })`
  margin-left: 8px;
`
const Txts = styled.div`
  margin-top: 20px;
`
const Separator = styled(WeakColorSeparator)`
  margin-top: 20px;
`
const ButtonGroup = styled.div`
  margin: 20px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const CancelButton = styled(TertiaryButton).attrs({ size: 'l1' })`
  ${ButtonGroup} && {
    width: 48%;
  }
`
const FinishButton = styled(DangerButton).attrs({ size: 'l1' })`
  ${ButtonGroup} && {
    width: 48%;
  }
`
