import React from 'react'
import styled from 'styled-components'
import { theme, PrimaryButton } from '@blue-agency/rogue'
import { H1 } from '@/components/Heading'
import { RegisterContainer } from './hooks/useRegister'

export const PageHeader: React.FCX = ({ className }) => {
  const { handleRegisterButtonClick } = RegisterContainer.useContainer()

  return (
    <Header className={className}>
      <H1>Web説明会一覧</H1>
      <HeaderRight>
        <PrimaryButton
          text="Web説明会登録"
          size="l2"
          onClick={handleRegisterButtonClick}
          comlinkPushParams={{ action: 'open_register_seminar_modal' }}
        />
      </HeaderRight>
    </Header>
  )
}

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
`

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  background-color: ${theme.color.white[1]};
`
