import {
  createContainer,
  useCachedPromise,
} from '@blue-agency/front-state-management'
import { useParams } from 'react-router-dom'
import { cacheKey } from '@/services/bffService'
import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'
import { useState, useCallback, useEffect, useMemo } from 'react'
import { AudioContextContainer } from '@/containers/AudioContextContainer'
import { BrowserContainer } from '@/containers/BrowserContainer'
import {
  GetInterviewQualityResponse,
  InterviewQualityMode,
} from '@blue-agency/proton/web/v2/yashiori_bff/yashiori_bff_service_pb'
import { comlinkPush } from '@/comlink'
import { Plan } from '@blue-agency/proton/web/v2/yashiori_bff/plan_data_pb'

const useInterview = () => {
  const { interviewGuid, token } = useParams<{
    interviewGuid?: string
    token?: string
  }>()
  if (!interviewGuid) throw new Error('interviewGuid not found')
  if (!token) throw new Error('token not found')

  const { getAudioContext } = AudioContextContainer.useContainer()
  const { isSafari } = BrowserContainer.useContainer()
  useEffect(() => {
    // MEMO: audioContextインスタンス作成のタイミングはgetUserMediaを呼ぶ前が望ましい
    // という情報をみた気がするので、Roomに入ったタイミングでインスタンスを作成している

    // TODO: getUserMediaを呼んだ後でインスタンスを作成しても問題ないかを確認して、
    // 問題なければここでgetAudioContextを呼ぶのをやめる
    if (isSafari) getAudioContext()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { getPresenterInterview, getInterviewQuality, getInterviewSpotlight } =
    OrganizerServiceContainer.useContainer()
  const getPresenterInterviewRes = useCachedPromise(
    cacheKey.getPresenterInterview({ interviewGuid }),
    () => getPresenterInterview({ interviewGuid })
  )
  const initialStatus = useMemo(() => {
    return getPresenterInterviewRes.getStatus()
  }, [getPresenterInterviewRes])

  const [showWaitingRoom, setShowWaitingRoom] = useState(true)
  const [showExitModal, setShowExitModal] = useState(false)

  const isStarted = useMemo(
    () => getPresenterInterviewRes.getStartedAt() !== undefined,
    [getPresenterInterviewRes]
  )

  const handleExitModalOpen = useCallback(() => {
    setShowExitModal(true)
  }, [])
  const handleExitModalClose = useCallback(() => {
    setShowExitModal(false)
  }, [])

  const handleEnter = useCallback(() => {
    comlinkPush({
      type: 'manual_activity',
      action: 'enter_interview_room',
      targetName: 'yashiori_interview.guid',
      targetIdStr: interviewGuid,
    })
    setShowWaitingRoom(false)
  }, [interviewGuid])

  const handleExit = useCallback(() => {
    comlinkPush({
      type: 'manual_activity',
      action: 'exit_interview_room',
      targetName: 'yashiori_interview.guid',
      targetIdStr: interviewGuid,
    })
    setShowWaitingRoom(true)
    setShowExitModal(false)
  }, [interviewGuid])

  const quality = useCachedPromise(
    cacheKey.getInterviewQuality({ interviewGuid }),
    () => getInterviewQuality({ interviewGuid })
  )

  const isInitialEconomyMode =
    quality.getOldMode() === GetInterviewQualityResponse.Mode.LOW ||
    quality.getMode() === InterviewQualityMode.LOW

  const sl = useCachedPromise(
    cacheKey.getInterviewSpotlight({ interviewGuid }),
    () => getInterviewSpotlight({ interviewGuid })
  )
  const spotlight = {
    enabled: sl.getEnabled(),
    spotlightNumber: sl.getSpotlightNumber(),
  }

  const isKaburikuPlan = useMemo(() => {
    return getPresenterInterviewRes.getPlan()?.getId() === Plan.Id.KABURIKU
  }, [getPresenterInterviewRes])

  return {
    getPresenterInterviewRes,
    initialStatus,
    interviewGuid,
    token,
    showWaitingRoom,
    handleEnter,
    handleExit,
    showExitModal,
    handleExitModalClose,
    handleExitModalOpen,
    initialQuality: quality,
    isInitialEconomyMode,
    isStarted,
    spotlight,
    isKaburikuPlan,
  }
}

export const InterviewContainer = createContainer(useInterview)
