import {
  createContainer,
  useCachedPromise,
  CacheContainer,
} from '@blue-agency/front-state-management'
import { useState, useCallback, useEffect } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ParticipantsServiceContainer } from '@/containers/ParticipantsServiceContainer'
import * as yup from 'yup'
import { useParams } from 'react-router-dom'
import { cacheKey } from '@/services/bffService'
import { CommunicationErrorModalContainer } from '@/containers/CommunicationErrorModalContainer'
import { comlinkPush } from '@/comlink'

type FormParams = {
  lastName: string
  firstName: string
  email: string
}

const validationSchema: yup.SchemaOf<FormParams> = yup
  .object()
  .shape({
    lastName: yup.string().required('入力してください'),
    firstName: yup.string().required('入力してください'),
    email: yup
      .string()
      .required('入力してください')
      .email('メールアドレスを入力してください'),
  })
  .defined()

const useNewParticipant = () => {
  const { entranceGuid } = useParams<{ entranceGuid?: string }>()
  if (!entranceGuid) throw new Error('entranceGuid not found')
  const { deleteCache } = CacheContainer.useContainer()
  const { registerParticipant, checkEntrance } =
    ParticipantsServiceContainer.useContainer()
  const { handleCommunicationErrorModalOpen } =
    CommunicationErrorModalContainer.useContainer()

  const checkEntranceRes = useCachedPromise(cacheKey.checkEntrance(), () =>
    checkEntrance({ entranceGuid })
  )
  useEffect(() => {
    return () => {
      deleteCache(cacheKey.checkEntrance())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [isSendEmail, setIsSendEmail] = useState(false)
  const [email, setEmail] = useState('')

  const { handleSubmit, register, errors, formState } = useForm<FormParams>({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
  })

  const onSubmit = useCallback<SubmitHandler<FormParams>>(async (data) => {
    comlinkPush({
      type: 'manual_activity',
      action: 'register_participant_in_hall',
      targetName: 'yashiori_hall.participants_entrance_guid',
      targetIdStr: entranceGuid,
    })

    await registerParticipant({ entranceGuid, ...data }).catch((err) => {
      handleCommunicationErrorModalOpen()
      throw err
    })

    setEmail(data.email)
    setIsSendEmail(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleBack = useCallback(() => {
    setIsSendEmail(false)
  }, [])

  return {
    isSendEmail,
    onSubmit,
    handleSubmit,
    register,
    errors,
    formState,
    handleBack,
    email,
    status: checkEntranceRes.status,
    organizerName: checkEntranceRes.organizerName,
    sessionName: checkEntranceRes.sessionName,
    scheduledAt: checkEntranceRes.scheduledAt,
  }
}

export const NewParticipantContainer = createContainer(useNewParticipant)
