import {
  createContainer,
  useCachedPromise,
  CacheContainer,
} from '@blue-agency/front-state-management'
import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'
import { useHistory, useParams } from 'react-router-dom'
import { useCallback, useEffect, useState, useMemo, useRef } from 'react'
import { INTERNAL_PATHS, fillParams } from '@/services/urlService'
import { cacheKey, CustomGrpcError } from '@/services/bffService'
import { GetPresentationStatusResponse } from '@blue-agency/proton/web/v2/yashiori_bff/yashiori_bff_service_pb'
import { CommunicationErrorModalContainer } from '@/containers/CommunicationErrorModalContainer'
import { PresentationContainer } from '../../hooks/PresentationContainer'
import { useModal } from '@/hooks/useModal'
import grpcWeb from 'grpc-web'
import { storageKey } from '@/constants/storageKey'
import { comlinkPush } from '@/comlink'

const { Status } = GetPresentationStatusResponse
sessionStorage.setItem(storageKey.isPresentationStarted, 'false')
const getPresentationStatusPollingIntervalSecond = 2
const checkStartedPollingIntervalSecond = 0.3

const useRecording = () => {
  const { presentationGuid, token } = useParams<{
    presentationGuid?: string
    token?: string
  }>()
  if (!presentationGuid) throw new Error('presentationGuid not found')
  if (!token) throw new Error('token not found')

  const {
    setupPresentation,
    startPresentation,
    finishPresentation,
    getPresentationStatus,
    restartPresentation,
    checkStartedPolling,
  } = OrganizerServiceContainer.useContainer()
  const { getPresentationRes } = PresentationContainer.useContainer()
  const { handleCommunicationErrorModalOpen } =
    CommunicationErrorModalContainer.useContainer()
  const { deleteCache } = CacheContainer.useContainer()

  const history = useHistory()

  const getPresentationStatusRes = useCachedPromise(
    cacheKey.getPresentationStatus(),
    () => getPresentationStatus(presentationGuid)
  )

  const [status, setStatus] = useState(getPresentationStatusRes.getStatus())
  const finishModal = useModal()
  const errorModal = useModal()
  const beforeStartModal = useModal(
    status === Status.NOT_READY || status === Status.READY
  )
  const [startTime, setStartTime] = useState(() =>
    getPresentationRes.getStartTime()?.toDate()
  )
  const [currentTime, setCurrentTime] = useState(() =>
    getPresentationRes.getCurrentTime()?.toDate()
  )
  const [isPresenterPublished, setIsPresenterPublished] = useState(false)
  const [presenterSora, setPresenterSora] = useState(() =>
    getPresentationStatusRes.getPresenterSora()?.toObject()
  )
  const [screenSora, setScreenSora] = useState(() =>
    getPresentationStatusRes.getScreenSora()?.toObject()
  )

  const maxSeconds = useMemo(() => {
    const maximumDuration = getPresentationRes.getMaximumDuration()
    if (!maximumDuration) throw new Error('maximumDuration not found')
    return maximumDuration.getSeconds()
  }, [getPresentationRes])

  const checkStartedPollingIdRef = useRef<NodeJS.Timeout>()
  const isShownOverlay = useMemo(
    () => !(beforeStartModal.active || status === Status.RECORDING),
    [beforeStartModal.active, status]
  )

  useEffect(() => {
    if (
      status !== Status.RECORDING ||
      !isPresenterPublished ||
      sessionStorage.getItem(storageKey.isPresentationStarted) === 'true'
    )
      return
    comlinkPush({
      type: 'system_activity',
      action: 'restart_presentation',
      targetName: 'presentationGuid',
      targetIdStr: presentationGuid,
    })
    restartPresentation(presentationGuid)
  }, [status, presentationGuid, restartPresentation, isPresenterPublished])

  useEffect(() => {
    if (status === Status.NOT_READY) {
      ;(async () => {
        comlinkPush({
          type: 'system_activity',
          action: 'setup_presentation',
          targetName: 'presentationGuid',
          targetIdStr: presentationGuid,
        })
        try {
          await setupPresentation(presentationGuid)
        } catch (e) {
          errorModal.open()
          return
        }
      })()
    }

    const timer = setInterval(async () => {
      let res: GetPresentationStatusResponse
      try {
        res = await getPresentationStatus(presentationGuid)
      } catch (err) {
        // getPresentationStatusのレスポンスがnot foundになるのは、正常動線ではstart後に別タブ等で当該の説明会撮影が削除された場合
        // そのためその旨をユーザーに表示 & これ以降のタイマー処理を停止して、エラーは返さない
        if (
          err &&
          err instanceof CustomGrpcError &&
          err.code === grpcWeb.StatusCode.NOT_FOUND
        ) {
          clearInterval(timer)
          alert('この説明会動画は削除されたため、撮影を開始できません。')
          return
        }
        throw err
      }

      const { presenterSora, screenSora, status } = res.toObject()

      /**
       * NOTE:
       * 撮影開始のタイミングはcheckStartedPollingで取得するため、ここではStatus.RECORDINGをセットしない
       * checkStartedPollingより先にここでStatus.RECORDINGをセットしてしまうとcheckStartedPollingのポーリングが解除される
       * その結果、checkStartedPollingで取得しているstartTimeを取得できなくなり、タイマーが表示されない可能性がある
       */
      if (status !== Status.RECORDING) {
        setStatus(status)
      }

      if (presenterSora) {
        const { hostname, channelId } = presenterSora
        setPresenterSora((prev) => {
          if (
            !prev ||
            hostname !== prev.hostname ||
            channelId !== prev.channelId
          ) {
            return presenterSora
          }
          return prev
        })
      }
      if (screenSora) {
        const { hostname, channelId } = screenSora
        setScreenSora((prev) => {
          if (
            !prev ||
            hostname !== prev.hostname ||
            channelId !== prev.channelId
          ) {
            return screenSora
          }
          return prev
        })
      }
    }, getPresentationStatusPollingIntervalSecond * 1000)
    return () => {
      clearInterval(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    return () => {
      deleteCache(cacheKey.enterHall())
      deleteCache(cacheKey.getPresentationStatus())
    }
  }, [deleteCache])

  const handleStart = useCallback(async () => {
    comlinkPush({
      type: 'manual_activity',
      action: 'start_presentation',
      targetName: 'presentationGuid',
      targetIdStr: presentationGuid,
    })

    if (!isPresenterPublished) {
      alert('カメラを許可し、映像が確認できてから開始してください')
      return
    }

    try {
      await startPresentation(presentationGuid)
      sessionStorage.setItem(storageKey.isPresentationStarted, 'true')
    } catch (e) {
      if (
        e &&
        e instanceof CustomGrpcError &&
        e.code === grpcWeb.StatusCode.NOT_FOUND
      ) {
        // この段階でstartPresentationでNotFoundが帰ってくる ≒ 別のタブやユーザーによって当該presentationが削除されている(正常動線でそのケース以外消せることはないはず)
        // そのためその旨をユーザーに表示してエラーは返さない
        alert('この説明会動画は削除されたため、撮影を開始できません。')
        return
      }

      beforeStartModal.close()
      const callback = () => beforeStartModal.open()
      handleCommunicationErrorModalOpen({ callback })

      throw e
    }

    beforeStartModal.close()

    const timer = setInterval(async () => {
      // NOTE: 撮影を開始してから実際に再生ファイルが生成されるまで数十秒のラグがあるので、ポーリングで本来の再生開始タイミングを取得する
      const res = await checkStartedPolling(presentationGuid)
      if (!res.getIsStarted()) return

      setStartTime(res.getStartTime()?.toDate())
      setCurrentTime(res.getCurrentTime()?.toDate())
      setStatus(Status.RECORDING)
    }, checkStartedPollingIntervalSecond * 1000)

    checkStartedPollingIdRef.current = timer
  }, [
    isPresenterPublished,
    beforeStartModal,
    handleCommunicationErrorModalOpen,
    startPresentation,
    presentationGuid,
    checkStartedPolling,
  ])

  useEffect(() => {
    if (status === Status.RECORDING) {
      checkStartedPollingIdRef.current &&
        clearInterval(checkStartedPollingIdRef.current)
    }
  }, [status])

  const handleFinish = useCallback(async () => {
    comlinkPush({
      type: 'manual_activity',
      action: 'finish_presentation',
      targetName: 'presentationGuid',
      targetIdStr: presentationGuid,
    })

    await finishPresentation(presentationGuid).catch((err) => {
      finishModal.close()
      const callback = () => finishModal.open()
      handleCommunicationErrorModalOpen({ callback })
      throw err
    })
    checkStartedPollingIdRef.current &&
      clearInterval(checkStartedPollingIdRef.current)

    history.push(
      fillParams({
        path: INTERNAL_PATHS.organizer.my.presentations.presentation.recorded,
        params: {
          token,
          presentationGuid,
        },
      })
    )
  }, [
    finishModal,
    finishPresentation,
    handleCommunicationErrorModalOpen,
    history,
    presentationGuid,
    token,
  ])

  const handleOvertime = useCallback(async () => {
    comlinkPush({
      type: 'system_activity',
      action: 'over_presentation_time_limit',
      targetName: 'presentationGuid',
      targetIdStr: presentationGuid,
    })

    await finishPresentation(presentationGuid)

    history.push(
      fillParams({
        path: INTERNAL_PATHS.organizer.my.presentations.presentation.overtime,
        params: {
          token,
          presentationGuid,
        },
      })
    )
  }, [presentationGuid, token, history, finishPresentation])

  return {
    handleFinish,
    handleStart,
    status,
    presenterSora,
    screenSora,
    startTime,
    finishModal,
    errorModal,
    beforeStartModal,
    setIsPresenterPublished,
    maxSeconds,
    currentTime,
    handleOvertime,
    isShownOverlay,
  }
}

export const RecordingContainer = createContainer(useRecording)
