import { useMemo } from 'react'
import { SoraConnectionConfig } from '@/lib/react-interview-sdk/types'
import { useInterviewWebSocketWebrtcHost } from './useInterviewWebSocket'

export const useSoraConnectionConfig = (config: SoraConnectionConfig) => {
  const webrtcHost = useInterviewWebSocketWebrtcHost(
    config.channelId,
    config.webrtcHost
  )
  return useMemo(
    () => ({
      ...config,
      webrtcHost: webrtcHost,
    }),
    [config, webrtcHost]
  )
}
