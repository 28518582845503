import { ParticipantType } from '@/lib/react-interview-sdk'

export const parseUserGuid = (
  userGuid: string
): { participantType: ParticipantType; entryNumber: string } => {
  const [participantType, entryNumber] = userGuid.split('-')
  if (!participantType) throw new Error('Not found participantType')
  if (!entryNumber) throw new Error('Not found entryNumber')
  return { participantType: participantType as ParticipantType, entryNumber }
}
