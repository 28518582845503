import React, { ReactNode, useMemo } from 'react'
import styled from 'styled-components'
import { theme } from '@blue-agency/rogue'

type Props = {
  height: number
  width: number
  presenterVideo: ReactNode
  screenShareVideo?: ReactNode
  briefingControls?: ReactNode
  messages: ReactNode
  participantsCount?: ReactNode
  screenSharing: boolean
}

const participantsCountHeight = 32

export const HallLayout: React.FCX<Props> = ({
  height,
  width,
  presenterVideo,
  screenShareVideo,
  messages,
  screenSharing,
  participantsCount,
  briefingControls,
  className,
}) => {
  const subVideoHeight = useMemo(() => width * 0.3 * (9 / 16), [width])
  const messageHeight = useMemo(() => {
    let mheight = height
    if (screenSharing) {
      mheight -= subVideoHeight
    }
    if (participantsCount) {
      mheight -= participantsCountHeight
    }
    return mheight
  }, [height, screenSharing, participantsCount, subVideoHeight])

  return (
    <Wrapper height={height} className={className}>
      <Left>
        <MainVideoArea>
          {screenSharing ? screenShareVideo : presenterVideo}
        </MainVideoArea>
      </Left>
      <Right>
        {screenSharing && (
          <SubVideoArea height={subVideoHeight}>{presenterVideo}</SubVideoArea>
        )}
        {participantsCount && (
          <ParticipantsCountArea>{participantsCount}</ParticipantsCountArea>
        )}
        <MessageArea height={messageHeight}>{messages}</MessageArea>
      </Right>
      {briefingControls}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ height?: number }>`
  display: flex;
  width: 100%;
  height: ${({ height }) => height}px;
`
const Left = styled.div`
  ${Wrapper} & {
    width: 70%;
    height: 100%;
  }
`
const Right = styled.div`
  display: flex;
  flex-direction: column;
  ${Wrapper} & {
    width: 30%;
    height: 100%;
  }
`
const MainVideoArea = styled.div`
  position: relative;
  background: ${theme.color.black[1]};
  ${Left} & {
    width: 100%;
    height: 100%;
  }
`
const SubVideoArea = styled.div<{ height: number }>`
  position: relative;
  background: ${theme.color.black[1]};
  ${Right} && {
    width: 100%;
    height: ${({ height }) => height}px;
  }
`
const MessageArea = styled.div<{ height: number }>`
  ${Right} && {
    width: 100%;
    height: ${({ height }) => height}px;
  }
`

const ParticipantsCountArea = styled.div`
  ${Right} && {
    width: 100%;
    height: ${participantsCountHeight}px;
  }
`
