import { useMemo, useCallback, useState } from 'react'
import { DropdownOption } from '@blue-agency/rogue'
import { createContainer } from '@blue-agency/front-state-management'
import { PresentationsContainer } from './PresentationsContainer'
import { useModal } from '@/hooks/useModal'

const useBulkAction = () => {
  const {
    selectedPresentations,
    selectAllPresentations,
    unselectAllPresentations,
  } = PresentationsContainer.useContainer()

  const deletePresentationsModal = useModal()

  const actions = useMemo(
    () => ({
      deletePresentations: {
        key: 'deletePresentations',
        func: () => deletePresentationsModal.open(),
      },
    }),
    [deletePresentationsModal]
  )

  const options = useMemo<DropdownOption[]>(() => {
    const options: DropdownOption[] = []

    options.push({
      label: '説明会の削除',
      value: actions.deletePresentations.key,
    })

    return options
  }, [actions])

  const [selectedActionKey, setSelectedActionKey] = useState<string>('')

  const count = useMemo(
    () => selectedPresentations.length,
    [selectedPresentations]
  )

  const canExecuteAction = useMemo(() => {
    return selectedActionKey !== '' && count > 0
  }, [count, selectedActionKey])

  const hasSelectedPresentations = useMemo(() => count > 0, [count])

  const execute = useCallback(() => {
    const targetAction = Object.values(actions).find(
      (action) => action.key === selectedActionKey
    )

    if (targetAction) {
      targetAction.func()
    }
  }, [actions, selectedActionKey])

  const toggleAll = useCallback(() => {
    if (hasSelectedPresentations) {
      unselectAllPresentations()
    } else {
      selectAllPresentations()
    }
  }, [
    hasSelectedPresentations,
    selectAllPresentations,
    unselectAllPresentations,
  ])

  return {
    count,
    hasSelectedPresentations,
    canExecuteAction,
    options,
    execute,
    toggleAll,
    setSelectedActionKey,
    deletePresentationsModal,
  }
}

export const BulkActionContainer = createContainer(useBulkAction)
