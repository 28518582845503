import {
  createContainer,
  useCachedPromise,
} from '@blue-agency/front-state-management'
import { WindowSizeContainer } from '@/containers/WindowSizeContainer'
import { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import {
  FormStep,
  SignupAsOrganizerForm,
} from '@/components/OrganizerForm/types'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { validationSchema } from '@/components/OrganizerForm/validationSchema'
import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'
import { useHistory, useParams } from 'react-router-dom'
import { cacheKey } from '@/services/bffService'
import { format } from 'date-fns'
import { Plan } from '@blue-agency/proton/web/v2/yashiori_bff/plan_data_pb'
import { isPc as isPcFn } from '@/utils'
import { fillParams, INTERNAL_PATHS } from '@/services/urlService'

const useSignup = () => {
  const orgService = OrganizerServiceContainer.useContainer()
  const { promotionGuid } = useParams<{ promotionGuid?: string }>()
  if (!promotionGuid) throw new Error('Not found promotionGuid')
  const history = useHistory()

  const promotionRes = useCachedPromise(
    cacheKey.getPromotion({ promotionGuid }),
    () => orgService.getPromotion({ promotionGuid })
  )
  const promotion = useMemo(
    () => ({
      plan: promotionRes.plan,
      planTerm: `お申込日 ～ ${format(
        promotionRes.planExpiresAt,
        'yyyy年M月d日'
      )}`,
      billingAmount: promotionRes.billingAmount,
      notes: promotionRes.notes,
    }),
    [promotionRes]
  )

  const defaultValues = useMemo(
    () => makeDefaultValues(promotion.plan),
    [promotion.plan]
  )

  const [step, setStep] = useState<FormStep>('input')
  const [isAgreementChecked, setIsAgreementChecked] = useState(false)
  const [form, setForm] = useState<SignupAsOrganizerForm>(defaultValues)
  const [isLoading, setIsLoading] = useState(false)

  const formContext = useForm<SignupAsOrganizerForm>({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
    defaultValues,
  })
  const { width } = WindowSizeContainer.useContainer()

  const isPc = isPcFn(width)

  // NOTE: PC / SP 用フォームコンポーネントが切り替わったときにフォーム内容を保持し続けるために
  //   毎レンダリング フォームの値を保存し、切り替えタイミングで保存した値をセットする
  const formValuesRef = useRef<SignupAsOrganizerForm>(defaultValues)
  formValuesRef.current = formContext.getValues()
  useEffect(() => {
    return () => {
      formContext.reset(formValuesRef.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPc])

  const needsBilling = promotion.billingAmount > 0
  useEffect(() => {
    // MEMO: SignupPageではプランの入力項目が存在しないのと、
    // billingAmountが0の時は請求先情報が存在しないので、
    // 手動でregisterしてあげないとrequiredのバリデーションに引っかかってしまう
    formContext.register({ name: 'plan' })
    if (!needsBilling) formContext.register({ name: 'billTo' })
    return () => {
      formContext.unregister('plan')
      if (!needsBilling) formContext.unregister('billTo')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needsBilling, formContext.register, formContext.unregister])

  const onSubmit: {
    [key in FormStep]?:
      | ((event: React.FormEvent<HTMLFormElement>) => void)
      | undefined
  } = {
    input: formContext.handleSubmit(
      useCallback<SubmitHandler<SignupAsOrganizerForm>>(
        (data) => {
          if (!isAgreementChecked) {
            alert('利用規約とプライバシーポリシーに同意してください')
            return
          }
          if (data.billTo === 'same') {
            data.billingName = data.adminName
            data.billingNameKana = data.adminNameKana
            data.billingDepartment = data.adminDepartment
            data.billingPostCode = data.postCode
            data.billingAddress = data.address
            data.billingPhoneNumber = data.adminPhoneNumber
            data.billingEmail = data.adminEmail
          }
          setForm(data)
          setStep('confirm')
          window.scrollTo(0, 0)
        },
        [isAgreementChecked]
      )
    ),
    confirm: useCallback(
      async (e: React.FormEvent) => {
        if (!promotionGuid) throw new Error('Not found promotionGuid')
        setIsLoading(true)
        e.preventDefault()
        try {
          await orgService.signupAsOrganizer({ ...form, promotionGuid })
        } catch (err) {
          setIsLoading(false)
          alert('申込みに失敗しました。もう一度お試しください。')
          throw err
        }
        setStep('completed')
        history.push(
          fillParams({
            path: INTERNAL_PATHS.organizer.signup.finish,
            params: {
              promotionGuid,
            },
          })
        )
      },
      [form, orgService, promotionGuid, history]
    ),
  }

  const handleCheck = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setIsAgreementChecked(e.target.checked)
  }, [])

  const handleBackToInputStep = useCallback(() => {
    formContext.reset(form)
    setStep('input')
    setIsAgreementChecked(false)
    window.scrollTo(0, 0)
  }, [formContext, form])

  const subTitle = useMemo(() => {
    switch (step) {
      case 'input':
        return '基本情報'
      case 'confirm':
        return '申込内容の確認'
      case 'completed':
        return '登録完了'
    }
  }, [step])

  return {
    step,
    onSubmit,
    formContext,
    form,
    isAgreementChecked,
    handleCheck,
    handleBackToInputStep,
    isLoading,
    subTitle,
    promotion,
    isPc,
  }
}

const makeDefaultValues = (plan: Plan.AsObject): SignupAsOrganizerForm => {
  return {
    plan: plan.id.toString(),
    name: '',
    representativeName: '',
    phoneNumber: '',
    postCode: '',
    address: '',
    employeesNumber: '',
    adminName: '',
    adminNameKana: '',
    adminDepartment: '',
    adminPhoneNumber: '',
    adminEmail: '',
    billTo: 'same',
    billingName: '',
    billingNameKana: '',
    billingDepartment: '',
    billingPostCode: '',
    billingAddress: '',
    billingPhoneNumber: '',
    billingEmail: '',
    referrer: '',
    couponCode: '',
    newGraduate: '',
    midCareer: '',
    temporary: '',
    parttime: '',
  }
}

export const SignupContainer = createContainer(useSignup)
