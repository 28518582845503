import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import ReactDOM from 'react-dom'

export type MenuPlace = 'top' | 'bottom'

type ContentPosition = {
  top?: number
  left?: number
  bottom?: number
  right?: number
}
type Props = {
  parentRef: React.RefObject<HTMLElement>
  place: MenuPlace
}

export const DropdownPosition: React.FCX<Props> = ({
  className,
  children,
  parentRef,
  place,
}) => {
  const [position, setPosition] = useState<ContentPosition | undefined>(
    undefined
  )

  const wrapperElRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const parentEl = parentRef.current
    const wrapperEl = wrapperElRef.current

    if (!parentEl || !wrapperEl) return

    const parentElRect = parentEl.getBoundingClientRect()
    const wrapperElRect = wrapperEl.getBoundingClientRect()

    if (place === 'bottom') {
      setPosition({
        top: wrapperElRect.bottom - parentElRect.top,
        left: wrapperElRect.left / 2 + 150,
      })
    } else if (place === 'top') {
      setPosition({
        bottom: parentElRect.height - 18 + 4, // NOTE: 18 (menu の svg の height), 4 (余白)
        left: wrapperElRect.left / 2 + 150,
      })
    }
  }, [parentRef, place])

  if (!parentRef.current) return null

  return (
    <Wrapper ref={wrapperElRef} className={className}>
      {position &&
        ReactDOM.createPortal(
          <Content position={position}>{children}</Content>,
          parentRef.current
        )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
`

const Content = styled.div<{ position: ContentPosition }>`
  position: absolute;
  top: ${({ position }) => getPositionString(position.top)};
  left: ${({ position }) => getPositionString(position.left)};
  bottom: ${({ position }) => getPositionString(position.bottom)};
  right: ${({ position }) => getPositionString(position.right)};
  z-index: 1;
`

function getPositionString(position?: number) {
  return position !== undefined ? `${position}px` : 'auto'
}
