import React from 'react'
import { UpgradePlanContainer } from '../hooks/useUpgradePlan'
import { UpgradePlanPriceTable } from '@/components/PriceTable/UpgradePlanPriceTable'
import { InputBody } from '@/components/OrganizerForm/Pc/InputBody'
import { planOptions } from '@/components/OrganizerForm/formItems'
import { InputBodyPlan } from '@/components/OrganizerForm/Pc/InputBody/InputBodyPlan'
import { EXTERNAL_URLS } from '@/services/urlService'

export const Input: React.FCX = ({ className }) => {
  const { formContext, checked, handleCheck } =
    UpgradePlanContainer.useContainer()
  return (
    <InputBody
      className={className}
      formContext={formContext}
      checked={checked}
      onCheck={handleCheck}
      PriceTable={UpgradePlanPriceTable}
      // ベーシックプランのみへのアップセル画面のため、ベーシックプランへのLPを配置している
      recommendedEnvUrl={EXTERNAL_URLS.lp.basic}
      hideEmployeesNumber
      hideNewHiresNumber
      plan={
        <InputBodyPlan
          formContext={formContext}
          planOptions={planOptions}
          hideReferrer
        />
      }
    />
  )
}
