import React from 'react'
import { theme } from '@blue-agency/rogue'
import styled from 'styled-components'

export type TableSize = 's' | 'm'

type Props = {
  size?: TableSize
  hover?: boolean
}

const StyledTable = styled.table<Props>(
  {
    background: theme.color.white[1],
    borderCollapse: 'collapse',
    tableLayout: 'fixed',
    'thead, tbody': {
      display: 'block',
    },
    th: {
      color: theme.color.white[1],
      background: theme.color.navy[1],
      fontSize: theme.fontSize.s,
      fontWeight: 'normal',
      textAlign: 'center',
      verticalAlign: 'middle',
      whiteSpace: 'nowrap',
      lineHeight: 1.5,
    },
    td: {
      color: theme.color.navy[1],
      fontSize: theme.fontSize.s,
      minHeight: 60,
      height: 60,
      padding: '0px 15px',
      // TODO: trに付け替える
      borderBottom: `1px solid ${theme.color.gray[3]}`,
      verticalAlign: 'middle',
      lineHeight: 1.5,
      overflow: 'hidden',
    },
  },
  (props) => ({
    tr: {
      ':hover': {
        boxShadow: props.hover ? '0px 0px 8px rgba(0, 0, 0, 0.2)' : undefined,
      },
    },
    th: {
      height: props.size !== 's' ? 44 : 24,
    },
  })
)

export const TableBase: React.FCX<Props> = ({
  children,
  hover,
  size = 'm',
  className,
}) => (
  <StyledTable hover={hover} size={size} className={className}>
    {children}
  </StyledTable>
)
