import React from 'react'
import styled from 'styled-components'
import { ApplyPageContainer } from '../../ApplyPageContainer'
import { Txt, InputText } from '@blue-agency/rogue'
import { placeholder } from '@/constants/placeholder'
import { InputStepItem } from '../../types'
import { OrganizerFormItem } from '@/components/OrganizerFormItem'

export const AdminSection: React.FCX = ({ className }) => {
  const { form } = ApplyPageContainer.useContainer()
  return (
    <Wrapper className={className}>
      {adminItems.map((item, index) => (
        <React.Fragment key={index}>
          <OrganizerFormItem
            title={index === 0 ? '管理者情報' : undefined}
            label={item.label}
            required
            inputNode={
              <InputText
                name={item.name}
                size={item.label === '住所' ? 'll' : 'l'}
                placeholder={item.placeholder}
                ref={form.register}
                onBlurError={form.errors[item.name]?.message}
              />
            }
          />
          {item.notes && <Notes>{item.notes}</Notes>}
        </React.Fragment>
      ))}
      <OrganizerFormItem
        label="メールアドレス"
        inputNode={<Txt size="s">登録されたメールアドレス</Txt>}
      />
    </Wrapper>
  )
}

const adminItems: InputStepItem[] = [
  {
    label: '氏名',
    name: 'adminName',
    placeholder: placeholder.name,
  },
  {
    label: '氏名（かな）',
    name: 'adminNameKana',
    placeholder: placeholder.nameKana,
  },
  {
    label: '部署名',
    name: 'adminDepartment',
    placeholder: placeholder.department,
  },
  {
    label: '電話番号',
    name: 'adminPhoneNumber',
    placeholder: placeholder.tel,
    notes:
      '※ハイフン(-)なしで入力してください。\n※連絡がとれる窓口番号を入力してください。',
  },
]

const Wrapper = styled.div`
  padding: 20px;
`
const Notes = styled(Txt).attrs({ size: 's' })`
  margin-top: 8px;
  margin-left: 320px;
`
