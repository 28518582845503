import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  ComponentProps,
  useMemo,
} from 'react'
import {
  InterviewMainAreaLayout,
  pcUsersAreaWidth as defaultPcUsersAreaWidth,
} from '.'
import { comlinkPush } from '@/comlink'

let countable = true
export const useInterviewMainAreaLayout = ({
  deviceType,
  width,
}: ComponentProps<typeof InterviewMainAreaLayout>) => {
  const defaultUsersAreaWidth = useMemo(() => {
    switch (deviceType) {
      case 'pc':
        return defaultPcUsersAreaWidth
      case 'spLandscape':
        // NOTE: spLandscapeの時はuseInterviewMainAreaLayoutから返るusersAreaWidthを利用していない、ダミーで0を入れておく
        return 0
      case 'spPortrait':
        return width
    }
  }, [deviceType, width])

  const [usersAreaWidth, setUsersAreaWidth] = useState(defaultUsersAreaWidth)
  const mouseDownedUsersAreaWidthRef = useRef(usersAreaWidth)

  useEffect(() => {
    setUsersAreaWidth(defaultUsersAreaWidth)
  }, [defaultUsersAreaWidth])

  const handleMouseDown = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (deviceType !== 'pc') return
      const defaultClientX = event.clientX
      mouseDownedUsersAreaWidthRef.current = usersAreaWidth

      const handleMouseMove = (event: MouseEvent) => {
        const offsetX = event.clientX - defaultClientX
        const newWidth = mouseDownedUsersAreaWidthRef.current - offsetX
        const minWidth = 120
        const maxWidth = width - 250
        setUsersAreaWidth(Math.max(minWidth, Math.min(newWidth, maxWidth)))
        setTimeout(function () {
          countable = true
        }, 60000)
        if (countable) {
          comlinkPush({
            type: 'manual_activity',
            action: 'resize_users_area',
          })
          countable = false
        }
      }

      const handleMouseUpOrLeave = () => {
        document.removeEventListener('mousemove', handleMouseMove)
        document.removeEventListener('mouseup', handleMouseUpOrLeave)
        document.removeEventListener('mouseleave', handleMouseUpOrLeave)
      }

      document.addEventListener('mousemove', handleMouseMove)
      document.addEventListener('mouseup', handleMouseUpOrLeave)
      document.addEventListener('mouseleave', handleMouseUpOrLeave)
    },
    [deviceType, usersAreaWidth, width]
  )

  return {
    usersAreaWidth,
    handleMouseDown,
  }
}
