import { atom, DefaultValue, selector } from 'recoil'
import { namespace } from './namespace'

type MainVideoState = {
  mainVideoTileId: number | null
  pinnedTileId: number | null
}

export const mainVideoState = atom<MainVideoState>({
  key: `${namespace}/mainVideoState`,
  default: {
    mainVideoTileId: null,
    pinnedTileId: null,
  },
})

export const pinnedTileState = selector<number | null>({
  key: `${namespace}/pinnedTileState`,
  get: ({ get }) => {
    const mainVideo = get(mainVideoState)
    return mainVideo.pinnedTileId
  },
  set: ({ set }, newValue) => {
    set(mainVideoState, (prev) => {
      if (newValue instanceof DefaultValue) return prev

      return { ...prev, pinnedTileId: newValue }
    })
  },
})
