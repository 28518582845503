import { useCallback, useRef } from 'react'
import Hls, { ErrorData } from 'hls.js'

export const useHlsErrorHandler = (hls: Hls) => {
  const prevRecoveredDateRef = useRef(0)
  const recoverRetryCountRef = useRef(0)

  /**
   * ref: https://github.com/video-dev/hls.js/blob/c19056ba0c3e521edfd909a27406b7276c8cbfbf/docs/API.md#hlsswapaudiocodec
   * 最初にMediaErrorが起きたときはrecoverMediaError()を呼び、MediaErrorが連続して起きたときはswapAudioCodec()を呼ぶ
   * MediaErrorが短時間で連続して起きた場合は、先にswapAudioCodec()を呼んでからrecoverMediaError()を呼ぶ
   */
  const handleMediaError = useCallback(() => {
    const now = Date.now()

    if (recoverRetryCountRef.current > 3) {
      alert('映像の読み込みに失敗しました、リロードしてください。')
      return
    }

    // 1回目のMediaErrorが起きたとき
    if (recoverRetryCountRef.current === 0) {
      recoverRetryCountRef.current++
      prevRecoveredDateRef.current = Date.now()
      hls.recoverMediaError()
      return
    }

    // 2回目以降のMediaErrorが起きたとき
    recoverRetryCountRef.current++
    prevRecoveredDateRef.current = Date.now()
    hls.swapAudioCodec()

    // 前回のMediaError発生から短時間で再度MediaErrorが起きたとき
    if (
      prevRecoveredDateRef.current &&
      now - prevRecoveredDateRef.current < 3000
    ) {
      hls.recoverMediaError()
    }
  }, [hls])

  const handleHlsError = useCallback(
    (_e: 'hlsError', data: ErrorData) => {
      if (data.fatal) {
        switch (data.type) {
          case Hls.ErrorTypes.NETWORK_ERROR:
            hls.startLoad()
            break
          case Hls.ErrorTypes.MEDIA_ERROR:
            handleMediaError()
            break
          default:
            hls.destroy()
            alert('映像の読み込みに失敗しました、リロードしてください。')
            break
        }
      }
    },
    [hls, handleMediaError]
  )

  return handleHlsError
}
