import { useCallback, useRef, useEffect, useState } from 'react'
import { createContainer } from '@blue-agency/front-state-management'

function useUnstableNavPopup() {
  const [active, setActive] = useState(false)
  const unmountedRef = useRef(false)

  const show = useCallback(() => {
    setTimeout(() => {
      if (!unmountedRef.current) {
        setActive(true)

        setTimeout(() => {
          if (!unmountedRef.current) {
            setActive(false)
          }
        }, 4000)
      }
    }, 800)
  }, [])

  useEffect(() => {
    return () => {
      unmountedRef.current = true
    }
  }, [])

  return {
    active,
    show,
  }
}

export const UnstableNavPopupContainer = createContainer(useUnstableNavPopup)
