import React from 'react'
import styled from 'styled-components'
import { Background as _Background } from '@/components/Background'
import { WindowSizeContainer } from '@/containers/WindowSizeContainer'
import { overflowYScrollStyle } from '@/styles/overflowYScrollStyle'
import { useRegisterEmailPage } from './useRegisterEmailPage'
import { RegisterEmailForm } from './RegisterEmailForm'
import { Submitted } from './Submitted'
import { useTitle } from '@/hooks/useTitle'

export const RegisterEmailPage: React.FCX = ({ className }) => {
  const windowSize = WindowSizeContainer.useContainer()
  const {
    isSubmitted,
    handleBack,
    handleSubmit,
    form,
    registeredEmail,
    promotion,
  } = useRegisterEmailPage()

  useTitle('メールアドレス登録ページ')
  return (
    <Wrapper className={className} height={windowSize.height}>
      <Background showCopyRight />
      <ContentWrapper>
        {isSubmitted ? (
          <Submitted
            onBack={handleBack}
            email={registeredEmail}
            term={promotion.term}
          />
        ) : (
          <RegisterEmailForm
            onSubmit={handleSubmit}
            form={form}
            promotion={promotion}
          />
        )}
      </ContentWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ height: number }>`
  height: ${({ height }) => height}px;
  ${overflowYScrollStyle};

  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
const Background = styled(_Background)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
`
const ContentWrapper = styled.div`
  ${overflowYScrollStyle};

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`
