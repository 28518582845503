import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'
import { useState, useCallback, useEffect, useMemo } from 'react'
import { CustomGrpcError } from '@/services/bffService'
import * as grpcWeb from 'grpc-web'
import { toast } from '@blue-agency/rogue'
import { BulkActionContainer } from './BulkActionContainer'
import { PresentationsContainer } from './PresentationsContainer'

type ErrorType = 'default' | 'select_recording_presentation'

export const useBulkDeletePresentationsModal = (
  active: boolean,
  onComplete: () => void
) => {
  const { selectedPresentations, reloadPresentations } =
    PresentationsContainer.useContainer()
  const { deletePresentations } = OrganizerServiceContainer.useContainer()
  const { count: totalCount } = BulkActionContainer.useContainer()

  const [isAgreed, setIsAgreed] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<ErrorType | undefined>(undefined)

  const isRecordingPresentationSelected = useMemo(
    () =>
      selectedPresentations.some(
        (presentation) => presentation.startTime && !presentation.isRecorded
      ),
    [selectedPresentations]
  )

  const toggleAgreement = useCallback(() => {
    setIsAgreed((prev) => !prev)
  }, [])

  const handleDeletePresentationsClick = useCallback(async () => {
    setIsLoading(true)

    try {
      await deletePresentations(
        selectedPresentations.map((interview) => interview.guid)
      )
      onComplete()
      setError(undefined)
      reloadPresentations()
      toast(`${totalCount}件の説明会動画を削除しました`)
    } catch (e) {
      if (
        e instanceof CustomGrpcError &&
        e.code === grpcWeb.StatusCode.FAILED_PRECONDITION
      ) {
        setError('select_recording_presentation')
      } else {
        setError('default')
      }
    }

    setIsLoading(false)
  }, [
    deletePresentations,
    onComplete,
    reloadPresentations,
    selectedPresentations,
    totalCount,
  ])

  useEffect(() => {
    setError(undefined)
    setIsAgreed(false)
  }, [active])

  return {
    selectedPresentations,
    totalCount,
    isRecordingPresentationSelected,
    isAgreed,
    error,
    isLoading,
    toggleAgreement,
    handleDeletePresentationsClick,
  }
}
