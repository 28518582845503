import React from 'react'
import styled, { css } from 'styled-components'
import {
  CheckBox,
  InputText as _InputText,
  RadioGroup,
  RequireTxt,
  theme,
  Txt,
  WeakColorSeparator,
  Dropdown,
  NewTabLink,
} from '@blue-agency/rogue'
import { EXTERNAL_URLS } from '@/services/urlService'
import { RecommendedEnvTable as _RecommendedEnvTable } from '@/components/RecommendEnvTable'
import {
  basicItems,
  adminItems,
  billingItems,
  billToOptions,
  newHiresNumberItems,
} from '@/components/OrganizerForm/formItems'
import { OrganizerForm } from '../../types'
import { UseFormMethods } from 'react-hook-form'

const text = {
  admin: `このメールアドレスに「ご利用アカウント情報」が送信されます。
入力内容にお間違いがないか再度確認お願いします。
また、以下のメールアドレスを受信できるように設定してください。
noreply@interview-maker.com`,
}

type Props = {
  PriceTable?: React.FCX
  plan: React.ReactNode
  formContext: UseFormMethods<OrganizerForm>
  checked: boolean
  onCheck: (e: React.ChangeEvent<HTMLInputElement>) => void
  hideBilling?: boolean
  recommendedEnvUrl?: string
}

export const InputBody: React.FCX<Props> = ({
  className,
  PriceTable,
  formContext,
  checked,
  onCheck,
  plan,
  hideBilling = false,
  recommendedEnvUrl = undefined,
}) => {
  return (
    <NewOrganizerBodyWrapper className={className}>
      {PriceTable && (
        <>
          <PriceTableWrapper>
            <PriceTable />
          </PriceTableWrapper>
          <WeakColorSeparator />
        </>
      )}
      {recommendedEnvUrl !== undefined && (
        <>
          <RecommendedEnvTable recommendedEnvUrl={recommendedEnvUrl} />
          <WeakColorSeparator />
        </>
      )}
      {plan}
      <WeakColorSeparator />
      <Block>
        <ItemGroup>
          {basicItems.map((item, index) => (
            <Item key={index}>
              <ItemTitle>{index === 0 && '契約者情報'}</ItemTitle>
              <label>
                <ItemLabelWrapper>
                  <ItemLabelTxt size="s">{item.label}</ItemLabelTxt>
                  <Required size="xs">必須</Required>
                </ItemLabelWrapper>
                {item.options ? (
                  <Dropdown
                    size="max"
                    name={item.name}
                    ref={formContext.register}
                    options={item.options}
                    placeholder={item.placeholder}
                    onBlurError={formContext.errors[item.name]?.message}
                  />
                ) : (
                  <InputText
                    name={item.name}
                    size="max"
                    placeholder={item.placeholder}
                    ref={formContext.register}
                    onBlurError={formContext.errors[item.name]?.message}
                  />
                )}
              </label>
            </Item>
          ))}
        </ItemGroup>
      </Block>
      <WeakColorSeparator />
      <Block>
        <ItemGroup>
          {adminItems.map((item, index) => (
            <Item key={index}>
              <ItemTitle>{index === 0 && '管理者情報'}</ItemTitle>
              <label>
                <ItemLabelWrapper>
                  <ItemLabelTxt size="s">{item.label}</ItemLabelTxt>
                  <Required size="xs">必須</Required>
                </ItemLabelWrapper>
                <InputText
                  name={item.name}
                  size="max"
                  placeholder={item.placeholder}
                  ref={formContext.register}
                  onBlurError={formContext.errors[item.name]?.message}
                />
              </label>
            </Item>
          ))}
          <AdminTxt size="s" color={theme.color.red[2]}>
            {text.admin}
          </AdminTxt>
        </ItemGroup>
      </Block>
      <WeakColorSeparator />
      {!hideBilling && <Billing formContext={formContext} />}
      <WeakColorSeparator />
      <Block>
        <ItemGroup>
          {newHiresNumberItems.map((item, index) => (
            <Item key={index}>
              <ItemTitle>{index === 0 && '採用状況'}</ItemTitle>
              <label>
                <ItemLabelWrapper>
                  <ItemLabelTxt size="s">{item.label}</ItemLabelTxt>
                  <Required size="xs">必須</Required>
                </ItemLabelWrapper>
                <Dropdown
                  size="max"
                  name={item.name}
                  ref={formContext.register}
                  options={item.options || []}
                  placeholder={item.placeholder}
                  onBlurError={formContext.errors[item.name]?.message}
                />
              </label>
            </Item>
          ))}
        </ItemGroup>
      </Block>
      <WeakColorSeparator />
      <Block>
        <ItemGroup>
          <Item>
            <label>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Txt size="s" bold css={{ marginRight: '4px' }}>
                  同意事項
                </Txt>
                <Txt size="s" color={theme.color.red[2]} bold>
                  必須
                </Txt>
              </div>
              <TxtGroup>
                <CheckBoxWrapper>
                  <CheckBox checked={checked} onChange={onCheck} />
                  <CheckBoxTxt size="s">
                    <NewTabLink
                      href={EXTERNAL_URLS.terms}
                      action="open_terms_page"
                    >
                      インタビューメーカー利用規約
                    </NewTabLink>
                    、株式会社スタジアムの
                    <NewTabLink
                      href={EXTERNAL_URLS.privacy}
                      action="open_privacy_policy_page"
                    >
                      プライバシーポリシー
                    </NewTabLink>
                    に同意する
                  </CheckBoxTxt>
                </CheckBoxWrapper>
              </TxtGroup>
            </label>
          </Item>
        </ItemGroup>
      </Block>
    </NewOrganizerBodyWrapper>
  )
}

type BillingProps = {
  formContext: UseFormMethods<OrganizerForm>
}
const Billing: React.FCX<BillingProps> = ({ formContext }) => {
  const watchedBillTo = formContext.watch('billTo')
  return (
    <Block>
      <ItemGroup>
        <Item>
          <ItemTitle>請求先情報</ItemTitle>
          <ItemLabelTxt size="s">請求先</ItemLabelTxt>
          <Required size="xs" />
          <RadioGroup
            ref={formContext.register}
            name="billTo"
            direction="row"
            options={billToOptions}
          />
        </Item>
        {billingItems.map((item, index) => (
          <Item key={index} hide={watchedBillTo === 'same'}>
            <ItemLabelWrapper>
              <ItemLabelTxt size="s">{item.label}</ItemLabelTxt>
              <Required size="xs">必須</Required>
            </ItemLabelWrapper>
            <InputText
              name={item.name}
              size="max"
              placeholder={item.placeholder}
              ref={formContext.register}
              onBlurError={formContext.errors[item.name]?.message}
            />
          </Item>
        ))}
      </ItemGroup>
    </Block>
  )
}

const NewOrganizerBodyWrapper = styled.div``
const PriceTableWrapper = styled.div`
  padding: 20px;
  ${NewOrganizerBodyWrapper} & {
    margin: 0 auto;
  }
`
const RecommendedEnvTable = styled(_RecommendedEnvTable)`
  padding: 20px;
  ${NewOrganizerBodyWrapper} & {
    margin: 0 auto;
  }
`
const Block = styled.div`
  ${NewOrganizerBodyWrapper} &:nth-of-type(n + 2) {
    margin-top: 0;
  }
  padding: 20px 0;
`
const ItemGroup = styled.div``
const Item = styled.div<{ hide?: boolean }>`
  ${ItemGroup} &:nth-of-type(n + 2) {
    margin-top: 20px;
  }
  ${({ hide }) =>
    hide &&
    css`
      display: none;
    `}
`
const ItemTitle = styled(Txt).attrs({ size: 's', bold: true })`
  ${ItemGroup} & {
    margin-bottom: 8px;
  }
`
const ItemLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  ${ItemGroup} & {
    margin-bottom: 8px;
  }
`
const ItemLabelTxt = styled(Txt)`
  ${ItemGroup} & {
    margin-right: 4px;
  }
  white-space: nowrap;
`
const Required = styled(RequireTxt)``
const InputText = styled(_InputText)``
const TxtGroup = styled.div``
const AdminTxt = styled(Txt)`
  ${ItemGroup} & {
    margin-top: 16px;
  }
`
const CheckBoxWrapper = styled.label`
  display: flex;
  align-items: center;
  ${Item} & {
    margin-top: 10px;
  }
`
const CheckBoxTxt = styled(Txt)`
  ${ItemGroup} & {
    margin-left: 10px;
  }
`
