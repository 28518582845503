import React from 'react'
import styled, { css } from 'styled-components'
import { Txt, theme, TertiaryButton, NewTabLink } from '@blue-agency/rogue'
import { UpgradePlanContainer } from '../hooks/useUpgradePlan'
import { EXTERNAL_URLS } from '@/services/urlService'

export const Completed: React.FCX = ({ className }) => {
  const { form, handleBackToMyPage } = UpgradePlanContainer.useContainer()
  return (
    <Wrapper className={className}>
      <MainTxt size="l">有料プラン切り替えのお申込みが完了しました</MainTxt>
      <TxtGroup>
        <Txt>
          ご登録頂いたメールアドレス宛にメールが送信されますので
          <br />
          ご確認のほど、よろしくお願いします。
        </Txt>
      </TxtGroup>
      <Box>
        <BoxTxt size="s">登録メールアドレス</BoxTxt>
        <Email bold>{form.adminEmail}</Email>
      </Box>
      <SupportTxt>
        ご不明点がございましたら、
        <NewTabLink
          href={EXTERNAL_URLS.supportPolicy}
          action="open_support_policy_page"
        >
          こちら
        </NewTabLink>
        までお問い合わせください。
      </SupportTxt>
      <BackButton
        size="l1"
        text="TOPに戻る"
        onClick={handleBackToMyPage}
        comlinkPushParams={{ action: 'back_to_my_page' }}
      />
    </Wrapper>
  )
}

const flex = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const Wrapper = styled.div`
  ${flex}
`
const MainTxt = styled(Txt)`
  ${Wrapper} & {
    margin-top: 60px;
  }
`
const TxtGroup = styled.div`
  ${Wrapper} & {
    margin-top: 20px;
  }
  ${flex}
`
const Box = styled.div`
  ${Wrapper} & {
    margin-top: 20px;
  }
  min-width: 200px;
  padding: 10px;
  background: ${theme.color.green[8]};
  ${flex}
`
const BoxTxt = styled(Txt)``
const Email = styled(Txt)``
const SupportTxt = styled(Txt)`
  ${Wrapper} & {
    margin-top: 24px;
  }
`
const BackButton = styled(TertiaryButton)`
  ${Wrapper} & {
    margin-top: 24px;
  }
`
