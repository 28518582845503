import React, { useMemo, ComponentProps } from 'react'
import styled, { css } from 'styled-components'
import { Header as _Header, headerHeight } from '@/components/Header'
import { Footer, footerHeight } from '@/components/Footer'
import { WindowSizeContainer } from '@/containers/WindowSizeContainer'
import { theme } from '@blue-agency/rogue'
import { AlertboxHeightContainer } from '@/containers/AlertboxHeightContainer'

type Props = ComponentProps<typeof _Header> & {
  showFooter?: boolean
  notFixHeight?: boolean
  backgroundColor?: string
}

export const Layout: React.FCX<Props> = ({
  className,
  children,
  rightNode,
  leftNode,
  tokenForNavs,
  showFooter = false,
  notFixHeight = false,
  backgroundColor,
  showInterviewsNav = false,
  showHallsNav = false,
  showPresentationsNav = false,
}) => {
  const windowSize = WindowSizeContainer.useContainer()
  const alertboxHeight = AlertboxHeightContainer.useContainer()

  const height = useMemo(() => {
    if (showFooter) {
      return windowSize.height - headerHeight - footerHeight - alertboxHeight
    }
    return windowSize.height - headerHeight - alertboxHeight
  }, [showFooter, windowSize.height, alertboxHeight])

  return (
    <Wrapper className={className}>
      <Header
        rightNode={rightNode}
        leftNode={leftNode}
        tokenForNavs={tokenForNavs}
        showInterviewsNav={showInterviewsNav}
        showHallsNav={showHallsNav}
        showPresentationsNav={showPresentationsNav}
      />
      {notFixHeight ? (
        <Content minHeight={height} background={backgroundColor}>
          {children}
        </Content>
      ) : (
        <Content height={height} background={backgroundColor}>
          {children}
        </Content>
      )}
      {showFooter && <Footer showCopyright />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  z-index: 0;
`
const Header = styled(_Header)`
  /* MEMO: HeaderがContentよりも上に来るようにスタックコンテキストを形成している */
  position: relative;
  z-index: 1;
`
const Content = styled.div<{
  height?: number
  minHeight?: number
  background?: string
}>`
  position: relative;
  z-index: 0;
  ${({ height }) =>
    height &&
    css`
      height: ${height}px;
    `}
  ${({ minHeight }) =>
    minHeight &&
    css`
      min-height: ${minHeight}px;
    `}
  background: ${({ background }) => background || theme.color.gray[5]};
`
