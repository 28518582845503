import React from 'react'
import styled from 'styled-components'
import { Txt } from '@blue-agency/rogue'
import { ThousandSeparatedNum } from '@/components/ThousandSeparatedNum'
import {
  basicItems,
  adminItems,
  billingItems,
  newHiresNumberItems,
  getInputtedTxt,
} from '@/components/OrganizerForm/formItems'
import { OrganizerForm, PlanInfo } from '../types'

type Props = {
  planInfo: PlanInfo
  form: OrganizerForm
  hideBillingBlock?: boolean
  hideReferrer?: boolean
  hideEmpoyeesNumber?: boolean
  hideNewHiresNumber?: boolean
}

export const ConfirmBody: React.FCX<Props> = ({
  className,
  form,
  planInfo,
  hideBillingBlock = false,
  hideReferrer = false,
  hideEmpoyeesNumber = false,
  hideNewHiresNumber = false,
}) => {
  return (
    <Wrapper className={className}>
      <Block>
        <ItemGroup>
          <Item>
            <Label>申込内容</Label>
            <ItemLabel size="s" bold>
              利用プラン
            </ItemLabel>
            <InputtedTxt>{planInfo.label}</InputtedTxt>
          </Item>
          <Item>
            <Label />
            <ItemLabel size="s" bold>
              ご契約期間
            </ItemLabel>
            <InputtedTxt>{planInfo.term}</InputtedTxt>
          </Item>
          <Item>
            <Label />
            <ItemLabel size="s" bold>
              合計金額
            </ItemLabel>
            <InputtedTxt>
              <>
                <ThousandSeparatedNum number={planInfo.price} />
                {planInfo.price === 0 ? '円' : '円（税抜）'}
              </>
            </InputtedTxt>
          </Item>
          {!hideReferrer && (
            <Item>
              <Label />
              <ItemLabel size="s" bold>
                ご紹介元
              </ItemLabel>
              <InputtedTxt>{form.referrer}</InputtedTxt>
            </Item>
          )}
          {planInfo.couponCode && (
            <Item>
              <Label />
              <ItemLabel size="s" bold>
                クーポンコード
              </ItemLabel>
              <InputtedTxt>{planInfo.couponCode}</InputtedTxt>
            </Item>
          )}
        </ItemGroup>
      </Block>
      <Block>
        <ItemGroup>
          {basicItems.map((item, index) => {
            if (item.name === 'employeesNumber' && hideEmpoyeesNumber)
              return null
            return (
              <Item key={index}>
                <Label>{index === 0 && '契約者情報'}</Label>
                <ItemLabel size="s" bold>
                  {item.label}
                </ItemLabel>
                <InputtedTxt>{getInputtedTxt(form, item)}</InputtedTxt>
              </Item>
            )
          })}
        </ItemGroup>
      </Block>
      <Block>
        <ItemGroup>
          {adminItems.map((item, index) => (
            <Item key={index}>
              <Label>{index === 0 && '管理者情報'}</Label>
              <ItemLabel size="s" bold>
                {item.label}
              </ItemLabel>
              <InputtedTxt>{getInputtedTxt(form, item)}</InputtedTxt>
            </Item>
          ))}
        </ItemGroup>
      </Block>
      {!hideBillingBlock && (
        <Block>
          <ItemGroup>
            {billingItems.map((item, index) => (
              <Item key={index}>
                <Label>{index === 0 && '請求先情報'}</Label>
                <ItemLabel size="s" bold>
                  {item.label}
                </ItemLabel>
                <InputtedTxt>{getInputtedTxt(form, item)}</InputtedTxt>
              </Item>
            ))}
          </ItemGroup>
        </Block>
      )}
      {!hideNewHiresNumber && (
        <Block>
          <ItemGroup>
            {newHiresNumberItems.map((item, index) => (
              <Item key={index}>
                <Label>{index === 0 && '採用状況'}</Label>
                <ItemLabel size="s" bold>
                  {item.label}
                </ItemLabel>
                <InputtedTxt>{getInputtedTxt(form, item)}</InputtedTxt>
              </Item>
            ))}
          </ItemGroup>
        </Block>
      )}
    </Wrapper>
  )
}
const Wrapper = styled.div``
const Block = styled.div`
  ${Wrapper} &:nth-of-type(n + 2) {
    margin-top: 0;
  }
  padding: 20px 0;
`
const ItemGroup = styled.div``
const Item = styled.div`
  ${ItemGroup} &:nth-of-type(n + 2) {
    margin-top: 20px;
  }
  padding: 0 20px;
  display: flex;
  align-items: center;
`
const Label = styled(Txt)`
  width: 160px;
`
const ItemLabel = styled(Txt)`
  width: 130px;
`
const InputtedTxt = styled(Txt)``
