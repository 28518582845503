import { useEffect } from 'react'
import { useMountedState } from 'react-use'
import { DeviceChangeObserver } from 'amazon-chime-sdk-js'

import { useRecoilState } from 'recoil'
import {
  audioOutputsState,
  selectedAudioOutputDeviceState,
} from '@/lib/react-interview-sdk/states'
import { MeetingManagerContainer } from '@/lib/react-interview-sdk/containers/MeetingManagerContainer'
import { AudioVideoContainer } from '@/lib/react-interview-sdk/containers/AudioVideoContainer'

export const useAudioOutput = () => {
  const { meetingManager } = MeetingManagerContainer.useContainer()
  const { audioVideo } = AudioVideoContainer.useContainer()
  const [audioOutputs, setAudioOutputs] = useRecoilState(audioOutputsState)
  const [selectedAudioOutputDevice, setSelectedAudioOutputDevice] =
    useRecoilState(selectedAudioOutputDeviceState)

  useEffect(() => {
    if (!meetingManager) return
    setSelectedAudioOutputDevice(meetingManager.selectedAudioOutputDevice)
  }, [meetingManager, setSelectedAudioOutputDevice])

  useEffect(() => {
    if (!meetingManager) return
    const callback = (updatedAudioOutputDevice: string | null): void => {
      setSelectedAudioOutputDevice(updatedAudioOutputDevice)
    }

    meetingManager.subscribeToSelectedAudioOutputDevice(callback)

    return (): void => {
      meetingManager.unsubscribeFromSelectedAudioOutputDevice(callback)
    }
  }, [
    meetingManager,
    audioOutputs,
    selectedAudioOutputDevice,
    setSelectedAudioOutputDevice,
  ])

  const isMounted = useMountedState()

  useEffect(() => {
    const observer: DeviceChangeObserver = {
      audioOutputsChanged: (newAudioOutputs: MediaDeviceInfo[]) => {
        setAudioOutputs(newAudioOutputs)
      },
    }

    async function initAudioOutput() {
      if (!audioVideo) {
        return
      }

      if (!isMounted()) {
        return
      }

      const devices = await audioVideo.listAudioOutputDevices()

      setAudioOutputs(devices)
      audioVideo.addDeviceChangeObserver(observer)
    }

    initAudioOutput()

    return () => {
      audioVideo?.removeDeviceChangeObserver(observer)
    }
  }, [audioVideo, isMounted, setAudioOutputs])
}
