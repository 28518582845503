import React from 'react'
import styled, { css } from 'styled-components'
import {
  Modal,
  Txt,
  TertiaryButton,
  theme,
  NewTabLink,
} from '@blue-agency/rogue'
import { EXTERNAL_URLS } from '@/services/urlService'
import { HelpModalContainer } from './containers/HelpModalContainer'
import { WindowSizeContainer } from '@/containers/WindowSizeContainer'
import { isPc } from '@/utils'

export const HelpModal: React.FCX = ({ className }) => {
  const { width } = WindowSizeContainer.useContainer()
  const { showHelpModal, handleHelpModalClose, handleReload } =
    HelpModalContainer.useContainer()
  return (
    <Modal
      className={className}
      active={showHelpModal}
      onClose={handleHelpModalClose}
      size={isPc(width) ? 'm' : 's'}
      titleSize="s"
      title="ヘルプ"
    >
      <Wrapper>
        <Txt>どのような状態でお困りですか？</Txt>
        <Table>
          <Thead>
            <Tr>
              <Th width={160}>
                <ThTxt bold size="s">
                  状態
                </ThTxt>
              </Th>
              <Th width={150}>
                <ThTxt bold size="s">
                  対応
                </ThTxt>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>
                <Txt size="s">
                  {'・画面が止まっている。表示されない\n・画面表示が崩れている'}
                </Txt>
              </Td>
              <Td>
                <ReloadButton
                  size="l2"
                  text="リロードして再接続"
                  onClick={handleReload}
                  comlinkPushParams={{ action: 'reload_interview_room' }}
                />
              </Td>
            </Tr>
            <Tr>
              <Td>
                <Txt size="s">
                  {'・その他の症状\n・再読み込みで解決しない場合'}
                </Txt>
              </Td>
              <Td>
                <HelpTxt size="s">
                  <NewTabLink
                    href={EXTERNAL_URLS.help.participants.seminar}
                    action="open_participant_seminar_help_page"
                  >
                    ヘルプ
                  </NewTabLink>
                  をご確認ください
                </HelpTxt>
              </Td>
            </Tr>
          </Tbody>
        </Table>
        <CancelButton
          text="キャンセル"
          size="l2"
          onClick={handleHelpModalClose}
          comlinkPushParams={{ action: 'close_interview_help_modal' }}
        />
      </Wrapper>
    </Modal>
  )
}

const Wrapper = styled.div`
  padding: 13px;
  display: flex;
  flex-direction: column;
`
const Table = styled.table`
  margin-top: 20px;
`
const Thead = styled.thead``
const Tbody = styled.tbody``
const Tr = styled.tr`
  border-bottom: 1px solid ${theme.color.gray[4]};
`
const Th = styled.th<{ width: number }>`
  ${({ width }) =>
    css`
      width: ${width}px;
    `}
`
const ThTxt = styled(Txt)`
  height: 20px;
  ${Th} & {
    margin-left: 10px;
    text-align: left;
  }
`
const Td = styled.td`
  position: relative;
  padding: 10px;
`
const HelpTxt = styled(Txt)`
  width: 130px;
  ${Td} & {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`
const ReloadButton = styled(TertiaryButton)`
  ${Td} & {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  height: 44px;
`
const CancelButton = styled(TertiaryButton)`
  ${Wrapper} & {
    align-self: center;
    margin-top: 13px;
  }
  height: 44px;
`
