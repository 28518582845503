import { useCallback } from 'react'

import { useRecoilValue } from 'recoil'
import { isRunningOwnScreenShareState } from '@/lib/react-interview-sdk/states'
import { AudioVideoContainer } from '@/lib/react-interview-sdk/containers/AudioVideoContainer'

export const useStartScreenSharing = () => {
  const { audioVideo } = AudioVideoContainer.useContainer()

  const isRunningOwnScreenShare = useRecoilValue(isRunningOwnScreenShareState)

  const startScreenSharing = useCallback<() => Promise<void>>(async () => {
    if (!audioVideo || isRunningOwnScreenShare) {
      return
    }

    await audioVideo.startContentShareFromScreenCapture()
  }, [audioVideo, isRunningOwnScreenShare])

  return startScreenSharing
}
