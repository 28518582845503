import React from 'react'
import styled from 'styled-components'
import { HallRecordingPageContainer } from './hooks/useHallRecordingPage'
import { NewTabLink, Icon, theme } from '@blue-agency/rogue'
import { EXTERNAL_URLS } from '@/services/urlService'

export const HeaderRightNode: React.FCX = ({ className }) => {
  const { organizerName } = HallRecordingPageContainer.useContainer()

  return (
    <Wrapper className={className}>
      <p>{organizerName}</p>
      <NewTabLink
        href={EXTERNAL_URLS.help.organizer.top}
        hideIcon
        action="open_organizer_help_page"
      >
        <QuestionIcon />
      </NewTabLink>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const QuestionIcon = styled(Icon).attrs({
  name: 'question',
})`
  color: ${theme.color.navy[1]};
  margin-left: 20px;
`
