import { useState, useEffect, useCallback } from 'react'
import { comlinkPush, ComlinkPushParams } from '@/comlink'
import { useOnlineStatus } from './useOnlineStatus'

export const useOfflineComlinkQueue = () => {
  const { isOffline } = useOnlineStatus()
  const [comlinkParams, setComlinkParams] = useState<ComlinkPushParams[]>([])

  const enqueue = useCallback((param: ComlinkPushParams) => {
    // NOTE: オフラインになった瞬間にenqueueされた場合
    // useOfflineComlinkQueue側がまだオンライン判定になっている可能性がある
    // そのため、少し時間をおいてenqueueする
    setTimeout(() => {
      setComlinkParams((prev) => [...prev, param])
    }, 500)
  }, [])

  useEffect(() => {
    if (!isOffline && comlinkParams.length > 0) {
      comlinkParams.forEach((param) => {
        comlinkPush(param)
      })
      setComlinkParams([])
    }
  }, [comlinkParams, isOffline])

  return { enqueue }
}
