import { createContainer } from '@blue-agency/front-state-management'
import { useState, useCallback } from 'react'

const useHelpModal = () => {
  const [showHelpModal, setShowHelpModal] = useState(false)

  const handleHelpModalOpen = useCallback(() => {
    setShowHelpModal(true)
  }, [])
  const handleHelpModalClose = useCallback(() => {
    setShowHelpModal(false)
  }, [])

  const handleReload = useCallback(() => {
    window.location.reload()
  }, [])

  return {
    showHelpModal,
    handleHelpModalOpen,
    handleHelpModalClose,
    handleReload,
  }
}

export const HelpModalContainer = createContainer(useHelpModal)
