import React from 'react'
import { WindowSizeContainer } from '@/containers/WindowSizeContainer'
import { EXTERNAL_URLS } from '@/services/urlService'
import { UnsupportedBrowser as UnsupportedBrowserBase } from '@/pages/UnsupportedBrowserRoutes/UnsupportedBrowser'

export const UnsupportedBrowser = () => {
  const windowSize = WindowSizeContainer.useContainer()

  return (
    <UnsupportedBrowserBase
      windowSize={windowSize}
      href={EXTERNAL_URLS.recommendedEnvironment.participants.base}
      action="open_participant_recommended_environment_page"
    />
  )
}
