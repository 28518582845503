import { atom } from 'recoil'
import { namespace } from './namespace'

export const videoInputsState = atom<MediaDeviceInfo[]>({
  key: `${namespace}/videoInputsState`,
  default: [],
})

export const selectedVideoInputDeviceState = atom<string | null>({
  key: `${namespace}/selectedVideoInputDeviceState`,
  default: null,
})
