import { useRef, useState, useEffect } from 'react'

export const useOnlineStatus = () => {
  const [isOffline, setIsOffline] = useState(false)
  const failedToFetchCountRef = useRef(0)
  const succeededFetchCountRef = useRef(0)

  useEffect(() => {
    const checkOnlineStatus = async () => {
      try {
        const online = await fetch('/1pixel.png', { cache: 'no-cache' })
        return online.status >= 200 && online.status < 300
      } catch (err) {
        return false
      }
    }

    const timer = setInterval(async () => {
      const isOnline = await checkOnlineStatus()
      if (!isOnline) {
        failedToFetchCountRef.current++
        succeededFetchCountRef.current = 0
      } else {
        succeededFetchCountRef.current++
        failedToFetchCountRef.current = 0
      }
      if (failedToFetchCountRef.current > 3) {
        setIsOffline(true)
      }
      if (succeededFetchCountRef.current > 3) {
        setIsOffline(false)
      }
    }, 3000)

    return () => {
      clearInterval(timer)
    }
  }, [])

  return { isOffline }
}
