import React from 'react'
import styled from 'styled-components'
import { theme } from '@blue-agency/rogue'
import { H1 as _H1 } from '@/components/Heading'
import { FormStep } from '../OrganizerForm/types'

type Props = {
  title: string
  subTitle?: string
  upper?: React.ReactNode
  body: React.ReactNode
  step: FormStep
  bottom: React.ReactNode
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void
}

export const SpFormLayout: React.FCX<Props> = ({
  className,
  title,
  step,
  upper,
  bottom,
  body,
  onSubmit,
}) => {
  return (
    <Wrapper className={className}>
      {step === 'input' && (
        <Header>
          <H1>{title}</H1>
        </Header>
      )}
      <FormWrapper>
        {upper}
        <Form onSubmit={onSubmit}>
          <Body>{body}</Body>
          <Bottom>{bottom}</Bottom>
        </Form>
      </FormWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: calc(100% - 16px * 2);
  margin: 0 auto;
  padding-top: 16px;
`
const Header = styled.header`
  ${Wrapper} & {
    margin-bottom: 12px;
  }
`
const H1 = styled(_H1)`
  line-height: 1.6;
  white-space: pre-wrap;
`
const FormWrapper = styled.div``
const Form = styled.form`
  min-height: calc(100vh - 142px);
  display: flex;
  flex-direction: column;
`
const Body = styled.div`
  ${Form} & {
    flex: 1 1 auto;
  }
`

const Bottom = styled.div`
  background: ${theme.color.white[1]};
  ${Form} & {
    margin: 0 -16px;
  }
`
