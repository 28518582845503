import React from 'react'
import styled from 'styled-components'
import backgroundImg from '@/assets/background.png'
import { theme, Txt } from '@blue-agency/rogue'

type Props = {
  showCopyRight?: boolean
}
export const Background: React.FCX<Props> = ({
  showCopyRight = false,
  children,
  className,
}) => {
  return (
    <Wrapper className={className}>
      <Overlay>{children}</Overlay>
      {showCopyRight && (
        <CopyRight size="xxs">© 2020 STADIUM All Rights Reserved.</CopyRight>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-image: url(${backgroundImg});
  background-size: cover;
  background-position: top;
  width: 100%;
  height: 100%;
`

const Overlay = styled.div`
  background: ${theme.color.white[1]}CC;
  ${Wrapper} & {
    height: 100%;
    width: 100%;
  }
`

const CopyRight = styled(Txt)`
  text-align: center;
  ${Wrapper} & {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 10px;
  }
`
