import { comlinkPush } from '@/comlink'
import { CustomGrpcError } from './CustomGrpcError'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const logRpcErr = (rpcName: string, err: any) => {
  const customErr = new CustomGrpcError(err)
  comlinkPush({
    type: 'system_activity',
    action: 'rpc_error',
    metadata: {
      name: rpcName,
      message: customErr.message ?? '',
      metadata: JSON.stringify(customErr.metadata) ?? '',
      code: customErr.code.toString() ?? '',
    },
  })
}
