import React from 'react'
import styled, { css } from 'styled-components'
import {
  theme,
  SecondaryDangerButton,
  TertiaryButton,
  Icon,
  ActiveTertiaryButton,
} from '@blue-agency/rogue'
import { FinishModal } from '../FinishModal'

type BriefingControlsProps = {
  muted: boolean
  isCameraOff: boolean
  toggleMuted: () => void
  toggleCameraOff: () => Promise<void>
  handleFinishModalOpen: () => void
}

export const BriefingControls: React.VFC<BriefingControlsProps> = ({
  muted,
  isCameraOff,
  toggleMuted,
  toggleCameraOff,
  handleFinishModalOpen,
}) => {
  return (
    <ControlWrapper>
      <Controls>
        {muted ? (
          <DisableMuteButton
            text="解除"
            icon={<Icon name="mute-mike" />}
            direction="column"
            onClick={toggleMuted}
            comlinkPushParams={{ action: 'unmute_seminar_mike' }}
          />
        ) : (
          <MuteButton
            text="ミュート"
            icon={<Icon name="mike" />}
            direction="column"
            onClick={toggleMuted}
            comlinkPushParams={{ action: 'mute_seminar_mike' }}
          />
        )}
        {isCameraOff ? (
          <DisableCameraOffButton
            text="解除"
            icon={<Icon name="movie-off" />}
            direction="column"
            onClick={toggleCameraOff}
            comlinkPushParams={{ action: 'unmute_seminar_camera' }}
          />
        ) : (
          <CameraOffButton
            text="オフ"
            icon={<Icon name="movie" />}
            direction="column"
            onClick={toggleCameraOff}
            comlinkPushParams={{ action: 'mute_seminar_camera' }}
          />
        )}
        <VerticalLine />
        <FinishButton
          text="説明会を終了"
          icon={<Icon name="close" />}
          direction="column"
          onClick={handleFinishModalOpen}
          comlinkPushParams={{ action: 'open_seminar_finish_modal' }}
        />
        <FinishModal />
      </Controls>
    </ControlWrapper>
  )
}

const ControlWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
`

const Controls = styled.div`
  display: flex;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 4px 4px 0 0;
  padding: 10px;

  > *:not(:first-child) {
    margin-left: 20px;
  }
`

const buttonSize = css`
  font-size: ${theme.fontSize.xs};
  padding: 0;
  width: 52px;
  height: 44px;
`

const MuteButton = styled(TertiaryButton)`
  ${buttonSize}
`
const DisableMuteButton = styled(ActiveTertiaryButton)`
  ${buttonSize}
`
const CameraOffButton = styled(TertiaryButton)`
  ${buttonSize}
`
const DisableCameraOffButton = styled(ActiveTertiaryButton)`
  ${buttonSize}
`

const VerticalLine = styled.div`
  width: 1px;
  height: 100%;
  background-color: ${theme.color.gray[1]};
  opacity: 0.5;
`

const FinishButton = styled(SecondaryDangerButton)`
  ${buttonSize}
  width: 84px;
`
