import {
  createContainer,
  useCachedPromise,
  CacheContainer,
} from '@blue-agency/front-state-management'
import { useParams } from 'react-router-dom'
import { useCallback, useState, useMemo, useEffect, useReducer } from 'react'
import { cacheKey } from '@/services/bffService'
import {
  OrganizerServiceContainer,
  ListPresentationsPresentation,
} from '@/containers/OrganizerServiceContainer'

const ITEMS_PER_PAGE = 20

export type PresentationListItem = ListPresentationsPresentation & {
  isSelected: boolean
}

const usePresentations = () => {
  const { token } = useParams<{ token?: string }>()
  if (!token) throw new Error('token not found')
  const { listPresentations } = OrganizerServiceContainer.useContainer()
  const { deleteCache } = CacheContainer.useContainer()
  const [, forceUpdate] = useReducer((x) => x + 1, 0)

  const allPresentations = useCachedPromise(
    cacheKey.listPresentations(),
    listPresentations
  )

  const [page, setPage] = useState(1)
  const [presentations, setPresentations] = useState<PresentationListItem[]>([])
  const count = useMemo(() => {
    const totalLength = allPresentations.length
    return Math.ceil(totalLength / ITEMS_PER_PAGE)
  }, [allPresentations])

  const selectedPresentations = useMemo(() => {
    return presentations.filter((presentation) => presentation.isSelected)
  }, [presentations])

  useEffect(() => {
    const begin = (page - 1) * ITEMS_PER_PAGE
    const end = begin + ITEMS_PER_PAGE
    const paginated = allPresentations.slice(begin, end)

    const presentations = paginated.map<PresentationListItem>((presentation) =>
      mapPresentationListItem(presentation)
    )
    setPresentations(presentations)
  }, [page, allPresentations])

  const reloadPresentations = useCallback(() => {
    deleteCache(cacheKey.listPresentations())
    forceUpdate()
  }, [deleteCache])

  const selectPresentation = useCallback(
    (targetPresentation: PresentationListItem) => {
      setPresentations((presentations) =>
        presentations.map((presentation) => {
          if (presentation.guid === targetPresentation.guid) {
            return { ...presentation, isSelected: true }
          }
          return presentation
        })
      )
    },
    []
  )

  const selectAllPresentations = useCallback(() => {
    setPresentations((presentations) =>
      presentations.map((presentation) => {
        return { ...presentation, isSelected: true }
      })
    )
  }, [])

  const unselectPresentation = useCallback(
    (targetPresentation: PresentationListItem) => {
      setPresentations((presentations) =>
        presentations.map((presentation) => {
          if (presentation.guid === targetPresentation.guid) {
            return { ...presentation, isSelected: false }
          }
          return presentation
        })
      )
    },
    []
  )

  const unselectAllPresentations = useCallback(() => {
    setPresentations((presentations) =>
      presentations.map((presentation) => {
        return { ...presentation, isSelected: false }
      })
    )
  }, [])

  return {
    page,
    setPage,
    count,
    presentations,
    selectedPresentations,
    selectPresentation,
    selectAllPresentations,
    unselectPresentation,
    unselectAllPresentations,
    reloadPresentations,
  }
}

export const PresentationsContainer = createContainer(usePresentations)

const mapPresentationListItem = (
  presentation: ListPresentationsPresentation
): PresentationListItem => ({
  ...presentation,
  isSelected: false,
})
