import { createContainer } from '@blue-agency/front-state-management'
import { useCallback, useState } from 'react'

const useAudioContext = () => {
  const [audioContext, setAudioContext] = useState<AudioContext | null>(null)

  const getAudioContext = useCallback(() => {
    if (audioContext) return audioContext
    const newAudioContext = new (window.AudioContext ||
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ((window as any).webkitAudioContext as AudioContext))()
    setAudioContext(newAudioContext)
    return newAudioContext
  }, [audioContext])

  return { getAudioContext }
}

export const AudioContextContainer = createContainer(useAudioContext)
