import { CheckBrowserFunction } from '@blue-agency/react-environment-check'
import { UAParser } from 'ua-parser-js'

type ErrorMessage = string

const defaultUnsupportedMessage = 'お使いのOS、ブラウザはご利用いただけません。'
const defaultNotRecommendedStronglyMessage =
  'この端末は、推奨端末ではありません。\nPCでお試しください。'

export const checkSeminarBrowser: CheckBrowserFunction = (ua) => {
  // iOS15.7からUAが変更されており、UAParserを利用したチェックだと推奨環境からはじかれるので一時的に通るようにする
  // 参考 https://github.com/faisalman/ua-parser-js/pull/595/files
  if (ua.match(/version\/15\.6,[\w]+ .*mobile\/\w+ (safari)/i)) {
    return { type: 'valid', message: '' }
  }
  if (ua.match(/version\/15\.6,[\w]+ .*(mobile ?safari|safari)/i)) {
    return { type: 'valid', message: '' }
  }

  const uaParser = new UAParser(ua)
  const uaResult = uaParser.getResult()

  if (ua === '') {
    return {
      type: 'notRecommendedStrongly',
      message: defaultNotRecommendedStronglyMessage,
    }
  }

  const unsupportedMessage = getUnsupportedBrowserMessage(uaResult)

  if (unsupportedMessage) {
    return {
      type: 'unsupported',
      message: unsupportedMessage,
    }
  }

  const notRecommendedStronglyMessage =
    getNotRecommendedStronglyMessage(uaResult)

  if (notRecommendedStronglyMessage) {
    return {
      type: 'notRecommendedStrongly',
      message: notRecommendedStronglyMessage,
    }
  }

  return { type: 'valid', message: '' }
}

const getUnsupportedBrowserMessage = (
  ua: UAParser.IResult
): ErrorMessage | undefined => {
  if (ua.device.type === 'mobile') {
    return '本端末・機器は利用できません。推奨環境内のカメラつきのPCをご利用ください。'
  }

  if (ua.browser.name === 'IE') {
    return defaultUnsupportedMessage
  }
  if (
    ua.browser.name === 'Edge' &&
    ua.browser.version &&
    parseInt(ua.browser.version, 10) < 79
  ) {
    return '最新のMicrosoft Edgeにアップデートしてください。'
  }
  if (
    ua.browser.name === 'Safari' &&
    ua.browser.version &&
    parseInt(ua.browser.version, 10) < 12.1
  ) {
    return '最新のSafariにアップデートしてください。'
  }
  if (
    ua.browser.name === 'Chrome' &&
    ua.browser.version &&
    parseInt(ua.browser.version, 10) < 71
  ) {
    return '最新のGoogle Chromeにアップデートしてください。'
  }

  return undefined
}

const getNotRecommendedStronglyMessage = (
  ua: UAParser.IResult
): ErrorMessage | undefined => {
  if (ua.device.type === 'tablet') {
    return defaultNotRecommendedStronglyMessage
  }

  return undefined
}
