import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { INTERNAL_PATHS } from '@/services/urlService'
import { VerifyEmailPage } from './VerifyEmailPage'
import { HallsRoutes } from './HallsRoutes'
import { InterviewsRoutes } from './InterviewsRoutes'
import { EnvironmentCheckPage } from './EnvironmentCheckPage'
import { provide } from '@blue-agency/front-state-management'
import { ParticipantsServiceContainer } from '@/containers/ParticipantsServiceContainer'
import { NotFound } from '@/components/NotFound'
import { UserTypeContainer } from '@/containers/UserTypeContainer'

export const ParticipantRoutes = provide(
  ParticipantsServiceContainer,
  UserTypeContainer
)(() => {
  return (
    <Switch>
      <Route
        exact
        path={INTERNAL_PATHS.participant.verifyEmail}
        component={VerifyEmailPage}
      />
      <Route
        path={INTERNAL_PATHS.participant.halls.index}
        component={HallsRoutes}
      />
      <Route
        path={INTERNAL_PATHS.participant.interviews.index}
        component={InterviewsRoutes}
      />
      <Route
        exact
        path={INTERNAL_PATHS.participant.environment}
        component={EnvironmentCheckPage}
      />
      <Route path="*" component={NotFound} />
    </Switch>
  )
})
