import { useEffect } from 'react'

export const usePreventPinchZoom = () => {
  const preventDefault = (e: TouchEvent) => {
    if (e.scale !== undefined && e.scale !== 1.0) {
      e.preventDefault()
    }
  }
  useEffect(() => {
    document.addEventListener('touchmove', preventDefault, { passive: false })
    return () => {
      document.removeEventListener('touchmove', preventDefault)
    }
  }, [])
}
