import ja from 'date-fns/locale/ja'
import { format } from 'date-fns'
import { convertToTimeZone } from 'date-fns-timezone'

export function formatDate(date: Date): string {
  return format(date, 'yyyy/MM/dd(E) HH:mm', { locale: ja })
}

export function convertToJstDate(date: Date): Date {
  return convertToTimeZone(date, { timeZone: 'Asia/Tokyo' })
}

export const getHourList = (): number[] => {
  return createNumberList(24)
}

export const getMinuteList = (interval: number): number[] => {
  let now = 0
  let minuteList: Array<number> = []
  while (now < 60) {
    minuteList.push(now)
    now += interval
  }
  return minuteList
}

const createNumberList = (num: number): number[] => {
  return Array(num)
    .fill(0)
    .map((_, index) => {
      return index
    })
}
