import {
  createContainer,
  useCachedPromise,
} from '@blue-agency/front-state-management'
import { useParams } from 'react-router-dom'
import { cacheKey } from '@/services/bffService'
import { ParticipantsServiceContainer } from '@/containers/ParticipantsServiceContainer'
import { useState, useCallback, useEffect, useMemo } from 'react'
import { AudioContextContainer } from '@/containers/AudioContextContainer'
import { BrowserContainer } from '@/containers/BrowserContainer'
import {
  GetInterviewQualityResponse,
  InterviewQualityMode,
} from '@blue-agency/proton/web/v2/yashiori_bff/yashiori_bff_service_pb'
import { Plan } from '@blue-agency/proton/web/v2/yashiori_bff/plan_data_pb'

const useInterview = () => {
  const { interviewGuid } = useParams<{ interviewGuid?: string }>()
  if (!interviewGuid) throw new Error('interviewGuid not found')

  const { getAudioContext } = AudioContextContainer.useContainer()
  const { isSafari } = BrowserContainer.useContainer()

  useEffect(() => {
    // MEMO: audioContextインスタンス作成のタイミングはgetUserMediaを呼ぶ前が望ましい
    // という情報をみた気がするので、Roomに入ったタイミングでインスタンスを作成している

    // TODO: getUserMediaを呼んだ後でインスタンスを作成しても問題ないかを確認して、
    // 問題なければここでgetAudioContextを呼ぶのをやめる
    if (isSafari) getAudioContext()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    getParticipantsInterview,
    getInterviewQuality,
    getInterviewSpotlight,
  } = ParticipantsServiceContainer.useContainer()
  const getParticipantsInterviewRes = useCachedPromise(
    cacheKey.getParticipantsInterview({ interviewGuid }),
    () => getParticipantsInterview({ interviewGuid })
  )

  const initialStatus = useMemo(() => {
    return getParticipantsInterviewRes.getStatus()
  }, [getParticipantsInterviewRes])

  const [isPreparing, setIsPreparing] = useState(true)

  const handlePreparingFinish = useCallback(() => {
    setIsPreparing(false)
  }, [])

  const quality = useCachedPromise(
    cacheKey.getInterviewQuality({ interviewGuid }),
    () => getInterviewQuality({ interviewGuid })
  )
  const sl = useCachedPromise(
    cacheKey.getInterviewSpotlight({ interviewGuid }),
    () => getInterviewSpotlight({ interviewGuid })
  )
  const spotlight = {
    enabled: sl.getEnabled(),
    spotlightNumber: sl.getSpotlightNumber(),
  }

  const isInitialEconomyMode =
    quality.getOldMode() === GetInterviewQualityResponse.Mode.LOW ||
    quality.getMode() === InterviewQualityMode.LOW

  const isKaburikuPlan = useMemo(() => {
    return getParticipantsInterviewRes.getPlan()?.getId() === Plan.Id.KABURIKU
  }, [getParticipantsInterviewRes])

  return {
    getParticipantsInterviewRes,
    organizerName: getParticipantsInterviewRes.getOrganizerName(),
    initialStatus,
    interviewGuid,
    isPreparing,
    handlePreparingFinish,
    initialQuality: quality,
    isInitialEconomyMode,
    spotlight,
    isKaburikuPlan,
  }
}

export const InterviewContainer = createContainer(useInterview)
