import React from 'react'
import styled from 'styled-components'
import { theme, WeakColorSeparator, Txt, A, Icon } from '@blue-agency/rogue'
import { EXTERNAL_URLS, INTERNAL_PATHS } from '@/services/urlService'

type Props = {
  orgName: string
}

export const DropdownMenu: React.FCX<Props> = ({ className, orgName }) => {
  return (
    <Wrapper className={className}>
      <Top>
        <OrgNameTxt size="xs">{orgName}</OrgNameTxt>
      </Top>
      <WeakColorSeparator />
      <div>
        <Link
          href={EXTERNAL_URLS.releaseInfo}
          target="_blank"
          rel="noopener noreferrer"
          comlinkPushParams={{
            action: 'open_release_info',
          }}
        >
          <LinkItem>
            <LinkItemTxt size="s">お知らせ・リリース情報</LinkItemTxt>
          </LinkItem>
        </Link>
      </div>
      <WeakColorSeparator />
      <div>
        <Link
          href={INTERNAL_PATHS.organizer.environment}
          target="_blank"
          rel="noopener noreferrer"
          comlinkPushParams={{
            action: 'open_check_seminar_environment_page',
          }}
        >
          <LinkItem>
            <LinkItemTxt size="s">動作環境テスト(説明会)</LinkItemTxt>
            <SeminarIcon name="seminar" />
          </LinkItem>
        </Link>
      </div>
      <WeakColorSeparator />
      <div>
        <Link
          href={EXTERNAL_URLS.privacy}
          target="_blank"
          rel="noopener noreferrer"
          comlinkPushParams={{
            action: 'open_privacy_policy_page',
          }}
        >
          <LinkItem>
            <LinkItemTxt size="s">プライバシーポリシー</LinkItemTxt>
          </LinkItem>
        </Link>
        <WeakColorSeparator />
        <Link
          href={EXTERNAL_URLS.terms}
          target="_blank"
          rel="noopener noreferrer"
          comlinkPushParams={{
            action: 'open_terms_page',
          }}
        >
          <LinkItem>
            <LinkItemTxt size="s">利用規約</LinkItemTxt>
          </LinkItem>
        </Link>
        <Link
          href={EXTERNAL_URLS.externalTransmission}
          target="_blank"
          rel="noopener noreferrer"
          comlinkPushParams={{
            action: 'open_term_of_external_transmission_page',
          }}
        >
          <LinkItem>
            <LinkItemTxt size="s">情報の外部送信</LinkItemTxt>
          </LinkItem>
        </Link>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 200px;
  background: ${theme.color.white[1]};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
  border-radius: 0 0 4px 4px;
`
const Top = styled.div`
  cursor: default;
  padding: 13px 20px;
  text-align: left;
`
const OrgNameTxt = styled(Txt)`
  cursor: text;
`
const Link = styled(A)`
  text-decoration: none;
`
const LinkItem = styled.div`
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  &:hover {
    background: ${theme.color.gray[6]};
  }
`
const SeminarIcon = styled(Icon)`
  ${LinkItem} & {
    margin-right: 20px;
  }
  color: ${theme.color.gray[2]};
`
const LinkItemTxt = styled(Txt)`
  ${LinkItem} & {
    margin-left: 20px;
  }
`
