import React, { FC, useMemo } from 'react'

import { MeetingManagerContainer } from './containers/MeetingManagerContainer'
import { AudioVideoContainer } from './containers/AudioVideoContainer'
import { LogLevel } from '@blue-agency/interview-sdk-js'
import { RecoilRoot } from 'recoil'

export const ReactInterviewSdkRoot: FC = ({ children }) => {
  const meetingManagerInitialState = useMemo(
    () => ({
      logLevel: LogLevel.OFF,
    }),
    []
  )
  return (
    <RecoilRoot>
      <MeetingManagerContainer.Provider
        initialState={meetingManagerInitialState}
      >
        <AudioVideoContainer.Provider>{children}</AudioVideoContainer.Provider>
      </MeetingManagerContainer.Provider>
    </RecoilRoot>
  )
}
