import React from 'react'
import styled from 'styled-components'
import {
  theme,
  Txt,
  TertiaryButton,
  PrimaryButton,
  NewTabLink,
  DangerButton,
} from '@blue-agency/rogue'
import { InterviewContainer } from '../hooks/useInterview'
import { EXTERNAL_URLS } from '@/services/urlService'
import { ManualModal } from './ManualModal'
import { ManualModalContainer } from './hooks/useManualModal'
import { EconomyModeBox } from '@/components/EconomyModeBox'
import { provide } from '@blue-agency/front-state-management'
import { Layout } from '@/components/Layout'
import { WindowSizeContainer } from '@/containers/WindowSizeContainer'
import { getDeviceType } from '@/utils'

export const WaitingRoom: React.VFC = provide(ManualModalContainer)(() => {
  const { handleEnter, isInitialEconomyMode, isStarted, interviewGuid } =
    InterviewContainer.useContainer()
  const { showManualModal, handleManualModalOpen } =
    ManualModalContainer.useContainer()
  const { isPortrait, ...windowSize } = WindowSizeContainer.useContainer()
  const deviceType = getDeviceType({
    windowWidth: windowSize.width,
    isPortrait,
  })

  const content = (
    <Box>
      <MainTxt>
        {isStarted ? '面接が開始されています' : '面接官のみ入室可能な状態です'}
        <br />
        参加準備ができたら入室してください
      </MainTxt>
      <SubTxt>
        <NewTabLink href={EXTERNAL_URLS.terms} action="open_terms_page">
          利用規約
        </NewTabLink>
        及び
        <NewTabLink
          href={EXTERNAL_URLS.privacy}
          action="open_privacy_policy_page"
        >
          プライバシーポリシー
        </NewTabLink>
        に
      </SubTxt>
      <ButtonWrapper>
        {isStarted ? (
          <RedStartButton
            size="ll"
            text="同意して面接に参加"
            onClick={handleEnter}
            comlinkPushParams={{
              action: 'click_enter_started_interview_room_button',
              targetName: 'interviewGuid',
              targetIdStr: interviewGuid,
            }}
          />
        ) : (
          <StartButton
            size="ll"
            text="同意して入室"
            onClick={handleEnter}
            comlinkPushParams={{
              action: 'click_enter_interview_room_button',
              targetName: 'interviewGuid',
              targetIdStr: interviewGuid,
            }}
          />
        )}
      </ButtonWrapper>
      <ButtonWrapper>
        <ConfirmButton
          size="ll"
          text="操作方法の確認"
          onClick={handleManualModalOpen}
          comlinkPushParams={{ action: 'open_interview_manual_modal' }}
        />
        <ManualModal active={showManualModal} />
      </ButtonWrapper>
      {isInitialEconomyMode && <EconomyModeBoxWrapper />}
    </Box>
  )

  return deviceType === 'spLandscape' ? (
    content
  ) : (
    <Layout backgroundColor={theme.color.white[1]}>{content}</Layout>
  )
})

const Box = styled.div`
  position: absolute;
  top: 10%;
  right: 0;
  left: 0;
  margin: 0 auto;
  max-width: 400px;
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const MainTxt = styled(Txt).attrs({ size: 'xl' })`
  text-align: center;
`
const SubTxt = styled(Txt)`
  margin-top: 25px;
`
const ButtonWrapper = styled.div`
  margin-top: 10px;
`
const StartButton = styled(PrimaryButton)``
const RedStartButton = styled(DangerButton)``
const ConfirmButton = styled(TertiaryButton)``

const EconomyModeBoxWrapper = styled(EconomyModeBox)`
  margin-top: 20px;
`
