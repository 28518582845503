import styled, { css } from 'styled-components'
import { theme } from '@blue-agency/rogue'
import React, { forwardRef, RefObject, useCallback } from 'react'

type DatePickerInputRef = HTMLInputElement

type InputSize = theme.InputSize

type DatePickerInputProps = DatePickerInputBaseProps & {
  onSubmitError?: string
  onBlurError?: string
}

export const DatePickerInput = forwardRef<
  DatePickerInputRef,
  DatePickerInputProps
>(({ onSubmitError, onBlurError, className, ...inputProps }, ref) => {
  const inputTextBaseWithValidation = (
    <ValidationWrapper>
      {onBlurError && (
        <ErrorWrapper>
          <Error>{onBlurError}</Error>
        </ErrorWrapper>
      )}
      <DatePickerInputBase {...inputProps} error={!!onSubmitError} ref={ref} />
    </ValidationWrapper>
  )

  return (
    <Wrapper className={className}>
      {inputTextBaseWithValidation}
      {onSubmitError && <ErrorMessageSpan>{onSubmitError}</ErrorMessageSpan>}
    </Wrapper>
  )
})

type DatePickerInputBaseProps = {
  name?: string
  value?: string
  defaultValue?: string
  size?: InputSize
  height?: string
  error?: boolean
  placeholder?: string
  required?: boolean
  autoComplete?: string
  autoFocus?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const DatePickerInputBase = forwardRef<
  DatePickerInputRef,
  DatePickerInputBaseProps
>(({ size, error, onChange, ...props }, ref) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(e)
    },
    [onChange]
  )

  return (
    <Input
      inputSize={size}
      error={error}
      type="text"
      ref={ref as RefObject<HTMLInputElement>}
      height="32px"
      {...props}
      onChange={handleChange}
      size={20} // input originally has a size attribute.
    />
  )
})

const base = css`
  min-height: 32px;
  color: ${theme.color.navy[1]};

  ::placeholder {
    color: ${theme.color.gray[2]};
  }

  font-size: ${theme.fontSize.s};
  padding: 5px 13px;
  background: ${theme.color.white[1]};
  box-sizing: border-box;
  border-radius: 4px;
`

const errorStyle = css`
  background: ${theme.color.red[4]};
  border: 1px solid ${theme.color.red[2]};
`

type DatePickerInInputProps = {
  inputSize?: InputSize
  error?: boolean
  height: string
}

const Input = styled.input<DatePickerInInputProps>`
  ${base};
  border: 1px solid ${theme.color.navy[1]};
  height: ${({ height }) => height};

  width: ${({ inputSize }) =>
    inputSize ? inputSizeToWidth[inputSize] : inputSizeToWidth['m']};
  ${({ error }) => error && errorStyle};
`

const inputSizeToWidth: Record<InputSize, string> = {
  max: '100%',
  ll: '460px',
  l: '260px',
  m: '160px',
  s: '108px',
  ss: '76px',
}

const Error = styled.span`
  display: block;
  padding: 6px 8px;
  background: ${theme.color.red[2]};
  opacity: 0.9;
  color: ${theme.color.white[1]};
  font-size: ${theme.fontSize.s};
  white-space: pre-wrap;

  :before {
    content: '';
    position: absolute;
    top: 100%;
    left: 8px;
    border: 6px solid transparent;
    border-top: 6px solid ${theme.color.red[2]};
  }
`

const ValidationWrapper = styled.div`
  position: relative;
`

const ErrorWrapper = styled.div`
  ${ValidationWrapper} & {
    position: absolute;
    bottom: calc(100% + 6px);
    left: 8px;
  }
`

const Wrapper = styled.div``

const ErrorMessageSpan = styled.span`
  color: ${theme.color.red[2]};
  font-size: ${theme.fontSize.xs};
  margin-top: 4px;
  margin-left: 10px;
`
