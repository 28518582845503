import { useCallback } from 'react'
import {
  CheckEntranceRequest,
  CreateInterviewSessionRequest,
  RegisterRequest,
  VerifyEmailRequest,
  PollingSessionStatusRequest,
  ListChatMessagesForParticipantsRequest,
  JoinSessionRequest,
  GetParticipantsInterviewRequest,
  CreateDemoInvitationRequest,
  DeleteDemoSessionTokenRequest,
  ListChatMessagesForInterviewRequest,
  GetInterviewQualityRequest,
  GetSignalingPointsRequest,
  GetInterviewSpotlightRequest,
} from '@blue-agency/proton/web/v2/yashiori_bff/yashiori_bff_service_pb'
import { CheckEntranceRes } from './types'
import { callBff } from '@/services/bffService'

export const useParticipantsService = () => {
  const registerParticipant = useCallback(
    async ({
      entranceGuid,
      lastName,
      firstName,
      email,
    }: {
      entranceGuid: string
      lastName: string
      firstName: string
      email: string
    }): Promise<void> => {
      const req = new RegisterRequest()
      req.setParticipantsEntranceGuid(entranceGuid)
      req.setLastName(lastName)
      req.setFirstName(firstName)
      req.setEmail(email)
      await callBff('register', [req])
    },
    []
  )

  const checkEntrance = useCallback(
    async ({
      entranceGuid,
    }: {
      entranceGuid: string
    }): Promise<CheckEntranceRes> => {
      const req = new CheckEntranceRequest()
      req.setParticipantsEntranceGuid(entranceGuid)
      const res = await callBff('checkEntrance', [req])
      return {
        ...res.toObject(),
        scheduledAt: res.getScheduledAt()?.toDate(),
      }
    },
    []
  )

  const verifyEmail = useCallback(async ({ token }: { token: string }) => {
    const req = new VerifyEmailRequest()
    req.setJwt(token)
    const res = await callBff('verifyEmail', [req])
    return res
  }, [])

  const pollingSessionStatus = useCallback(
    async ({ hallGuid }: { hallGuid: string }) => {
      const req = new PollingSessionStatusRequest()
      req.setHallGuid(hallGuid)
      const res = await callBff('pollingSessionStatus', [req])
      return res
    },
    []
  )

  const listChatMessages = useCallback(
    async ({ roomGuid }: { roomGuid: string }) => {
      const req = new ListChatMessagesForParticipantsRequest()
      req.setRoomGuid(roomGuid)
      const res = await callBff('listChatMessagesForParticipants', [req])
      return res
    },
    []
  )

  const joinSession = useCallback(
    async ({
      hallGuid,
      participantGuid,
    }: {
      hallGuid: string
      participantGuid: string
    }) => {
      const req = new JoinSessionRequest()
      req.setHallGuid(hallGuid)
      req.setParticipantGuid(participantGuid)
      const res = await callBff('joinSession', [req])
      return res
    },
    []
  )

  const getParticipantsInterview = useCallback(
    async ({ interviewGuid }: { interviewGuid: string }) => {
      const req = new GetParticipantsInterviewRequest()
      req.setGuid(interviewGuid)
      const res = await callBff('getParticipantsInterview', [req])
      return res
    },
    []
  )

  const createInterviewSession = useCallback(async () => {
    const req = new CreateInterviewSessionRequest()
    const res = await callBff('createInterviewSession', [req])
    return res
  }, [])

  const createDemoInvitation = useCallback(async () => {
    const req = new CreateDemoInvitationRequest()
    const res = await callBff('createDemoInvitation', [req])
    return res
  }, [])

  const deleteDemoInvitation = useCallback(
    async ({ sessionToken }: { sessionToken: string }) => {
      const req = new DeleteDemoSessionTokenRequest()
      req.setSessionToken(sessionToken)
      const res = await callBff('deleteDemoSessionToken', [req])
      return res
    },
    []
  )

  const listChatMessagesForInterview = useCallback(
    async (interviewGuid: string) => {
      const req = new ListChatMessagesForInterviewRequest()
      req.setInterviewGuid(interviewGuid)
      const res = await callBff('listChatMessagesForInterview', [req])
      return res
    },
    []
  )

  const getInterviewQuality = useCallback(
    async ({ interviewGuid }: { interviewGuid: string }) => {
      const req = new GetInterviewQualityRequest()
      req.setInterviewGuid(interviewGuid)
      const res = await callBff('getInterviewQuality', [req])
      return res
    },
    []
  )

  const getSignalingPoints = useCallback(
    async ({ interviewGuid }: { interviewGuid: string }) => {
      const req = new GetSignalingPointsRequest()
      req.setInterviewGuid(interviewGuid)
      const res = await callBff('getSignalingPoints', [req])
      return res
    },
    []
  )
  const getInterviewSpotlight = useCallback(
    async ({ interviewGuid }: { interviewGuid: string }) => {
      const req = new GetInterviewSpotlightRequest()
      req.setInterviewGuid(interviewGuid)
      const res = await callBff('getInterviewSpotlight', [req])
      return res
    },
    []
  )

  return {
    checkEntrance,
    registerParticipant,
    verifyEmail,
    pollingSessionStatus,
    listChatMessages,
    joinSession,
    getParticipantsInterview,
    createInterviewSession,
    createDemoInvitation,
    deleteDemoInvitation,
    listChatMessagesForInterview,
    getInterviewQuality,
    getSignalingPoints,
    getInterviewSpotlight,
  }
}
