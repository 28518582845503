import React from 'react'
import { theme } from '@blue-agency/rogue'

type Size = 'xxl' | 'xl' | 'l' | 'm' | 's' | 'xs'
type Props = {
  number: number
  size?: Size
  color?: string
}
export const ThousandSeparatedNum: React.FCX<Props> = ({
  className,
  number,
  size = 'xxl',
  color = theme.color.green[4],
}) => (
  <span
    className={className}
    style={{ fontSize: theme.fontSize[size], color: color }}
  >
    {number.toLocaleString()}
  </span>
)
