import React from 'react'
import {
  ErrorMessage as _ErrorMessage,
  InputText as _InputText,
  Modal,
  PrimaryButton as _PrimaryButton,
  Icon,
  TertiaryButton as _TertiaryButton,
  Txt as _Txt,
  WeakColorSeparator,
  NewTabLink,
} from '@blue-agency/rogue'
import styled from 'styled-components'
import { Tooltip } from '@/components/Tooltip'
import { useUpdateNameModal } from './useUpdateNameModal'
import { TOOLTIP_IDS } from '@/constants/tooltip'
import { EXTERNAL_URLS } from '@/services/urlService'

type Props = {
  currentName: string
  interviewGuid: string
  onClose: () => void
  onOpen: () => void
}

export const UpdateNameModal: React.FCX<Props> = (props) => {
  const { isSubmitError, onSubmit, register, errors, formState, handleSubmit } =
    useUpdateNameModal(props)

  return (
    // MEMO: 親で UpdateNameModal の表示・非表示を管理するので、ここでは常に active={true} を渡す
    //  https://github.com/blue-agency/yashiori-front/pull/1727
    <Modal active onClose={props.onClose} title="管理用面接名の編集" size="l">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Upper>
          {isSubmitError && (
            <ErrorMessage text="保存できませんでした。もう一度お試しください。" />
          )}
          <Row>
            <Label>
              <Txt>管理用面接名</Txt>
              <div
                data-tip
                data-for={TOOLTIP_IDS.interviewNameOnUpdateNameModal}
                style={{ cursor: 'pointer' }}
              >
                <Icon name="question" size="l" />
              </div>
              <Tooltip
                id={TOOLTIP_IDS.interviewNameOnUpdateNameModal}
                arrowPosition="topRight"
              >
                <Txt>
                  社内管理用の面接名です。応募者には表示されません。
                  <FAQLinkWrapper>
                    <NewTabLink
                      href={EXTERNAL_URLS.help.organizer.interview.faq}
                      action="click_detail_link_in_update_name_modal_tooltip"
                    >
                      詳細はこちら
                    </NewTabLink>
                  </FAQLinkWrapper>
                </Txt>
              </Tooltip>
            </Label>
            <InputText
              name="name"
              ref={register}
              onBlurError={errors.name?.message}
            />
          </Row>
        </Upper>
        <WeakColorSeparator />
        <Bottom>
          <CancelButton
            onClick={props.onClose}
            comlinkPushParams={{ action: 'cancel_interview_update' }}
          >
            キャンセル
          </CancelButton>
          <SubmitButton
            type="submit"
            loading={formState.isSubmitting}
            disabled={!formState.isValid}
            comlinkPushParams={{ action: 'update_interview' }}
          >
            保存
          </SubmitButton>
        </Bottom>
      </Form>
    </Modal>
  )
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Upper = styled.div`
  ${Form} & {
    width: 660px;
  }
  padding: 40px 20px 50px;
`

const ErrorMessage = styled(_ErrorMessage)`
  ${Upper} & {
    margin-top: -20px;
    margin-bottom: 20px;
  }
`
ErrorMessage.defaultProps = { size: 's' }

const Row = styled.div`
  display: flex;
  align-items: center;
`

const Label = styled.div`
  display: flex;

  ${Row} & {
    width: 140px;
    margin-right: 24px;
  }
`

const Txt = styled(_Txt)`
  ${Label} & {
    margin-right: 4px;
  }
`
Txt.defaultProps = { size: 's' }

const InputText = styled(_InputText)`
  ${Upper} & {
    flex: 1;
  }
`
InputText.defaultProps = { size: 'max' }

const Bottom = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`

const CancelButton = styled(_TertiaryButton)`
  ${Bottom} & {
    margin-right: 20px;
  }
`
CancelButton.defaultProps = { size: 'l2' }

const SubmitButton = styled(_PrimaryButton)``
SubmitButton.defaultProps = { size: 'l2' }

const FAQLinkWrapper = styled.span`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`
