import {
  createContainer,
  CacheContainer,
} from '@blue-agency/front-state-management'
import { useCallback } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { toast } from '@blue-agency/rogue'
import { useModal } from '@/hooks/useModal'
import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'
import { PermissionsContainer } from '@/containers/PermissionsContainer'
import { NotPermittedModalContainer } from '@/containers/NotPermittedModalContainer'
import { StatusCode } from 'grpc-web'
import { cacheKey } from '@/services/bffService'
import { PresentationsContainer } from './PresentationsContainer'

type Form = {
  name: string
}

const validationSchema: yup.SchemaOf<Form> = yup
  .object()
  .shape({
    name: yup
      .string()
      .required('入力してください')
      .max(30, '30文字以内で入力してください'),
  })
  .defined()

const useRegister = () => {
  const { createPresentation } = OrganizerServiceContainer.useContainer()
  const { deleteCache } = CacheContainer.useContainer()

  const { hasPermission } = PermissionsContainer.useContainer()
  const notPermittedModal = NotPermittedModalContainer.useContainer()
  const { reloadPresentations } = PresentationsContainer.useContainer()
  const registerModal = useModal()
  const handleRegisterButtonClick = useCallback(() => {
    hasPermission('creatablePresentation')
      ? registerModal.open()
      : notPermittedModal.open('説明会動画の登録')
  }, [hasPermission, registerModal, notPermittedModal])

  const formContext = useForm<Form>({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
  })

  const onSubmit = useCallback<SubmitHandler<Form>>(
    async (data) => {
      try {
        await createPresentation(data.name)
      } catch (e) {
        if (e.code === StatusCode.PERMISSION_DENIED) {
          registerModal.close()
          notPermittedModal.open('説明会動画の登録', {
            callback: registerModal.open,
          })
          return
        }
        alert('説明会動画の登録に失敗しました、再度お試しください。')
        throw e
      }
      deleteCache(cacheKey.listPresentations())
      registerModal.close()
      reloadPresentations()
      toast('説明会動画を登録しました')
      formContext.reset()
    },
    [
      registerModal,
      createPresentation,
      notPermittedModal,
      deleteCache,
      formContext,
      reloadPresentations,
    ]
  )

  return {
    registerModal,
    notPermittedModal,
    onSubmit,
    formContext,
    handleRegisterButtonClick,
  }
}

export const RegisterContainer = createContainer(useRegister)
