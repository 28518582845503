import * as yup from 'yup'
import { OrganizerForm } from './types'

const basicStringSchema = yup
  .string()
  .required('入力してください')
  .max(255, '255文字以内で入力してください')

const notRequiredStringSchema = yup
  .string()
  .max(255, '255文字以内で入力してください')

const emailSchema = yup
  .string()
  .required('入力してください')
  .email('メールアドレスを入力してください')
  .max(255, '255文字以内で入力してください')

const selectSchema = yup.string().required('選択してください')

export const validationSchema: yup.SchemaOf<OrganizerForm> = yup
  .object()
  .shape({
    plan: yup.string().required(),
    name: basicStringSchema,
    representativeName: basicStringSchema,
    phoneNumber: basicStringSchema,
    postCode: basicStringSchema,
    address: basicStringSchema,
    employeesNumber: selectSchema,
    adminName: basicStringSchema,
    adminNameKana: basicStringSchema,
    adminDepartment: basicStringSchema,
    adminPhoneNumber: basicStringSchema,
    adminEmail: emailSchema,
    billTo: yup.string().required(),
    billingName: yup.string().when('billTo', {
      is: 'other',
      then: basicStringSchema,
    }),
    billingNameKana: yup.string().when('billTo', {
      is: 'other',
      then: basicStringSchema,
    }),
    billingDepartment: yup.string().when('billTo', {
      is: 'other',
      then: basicStringSchema,
    }),
    billingPostCode: yup.string().when('billTo', {
      is: 'other',
      then: basicStringSchema,
    }),
    billingAddress: yup.string().when('billTo', {
      is: 'other',
      then: basicStringSchema,
    }),
    billingPhoneNumber: yup.string().when('billTo', {
      is: 'other',
      then: basicStringSchema,
    }),
    billingEmail: yup.string().when('billTo', {
      is: 'other',
      then: emailSchema,
    }),
    couponCode: notRequiredStringSchema,
    referrer: notRequiredStringSchema,
    newGraduate: selectSchema,
    midCareer: selectSchema,
    temporary: selectSchema,
    parttime: selectSchema,
  })
  .defined()
