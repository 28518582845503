import React from 'react'
import styled from 'styled-components'
import { truncateString } from '@/utils'
import { Txt as _Txt, Tooltip as _Tooltip } from '@blue-agency/rogue'

type Props = {
  organizerName: string
}

export const OrganizerName: React.FCX<Props> = ({
  organizerName,
  className,
}) => {
  return (
    <Wrapper className={className}>
      {organizerName.length > 30 ? (
        <>
          <p
            data-tip
            data-for="orgName-tip-arrow-topRight"
            style={{ cursor: 'text' }}
          >
            <Txt>{truncateString(organizerName, 30)}</Txt>
          </p>
          <Tooltip id="orgName-tip-arrow-topRight" arrowPosition="topRight">
            {organizerName}
          </Tooltip>
        </>
      ) : (
        <Txt>{organizerName}</Txt>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`
const Txt = styled(_Txt)`
  text-align: right;
`

const Tooltip = styled(_Tooltip)`
  width: 1000px;
  word-break: break-all;
`
