import React, { useMemo, useEffect, useState, useRef } from 'react'
import { theme } from '@blue-agency/rogue'
import { Txt } from '@blue-agency/rogue'
import { TimerProps, timerFormat } from '.'

type Props = TimerProps & { startTime: Date }

// タイマーの表示を更新する間隔 (ms)
// タイマーは秒単位の表示なので、500ms間隔での更新は無駄ではあるものの、setInterval による実際の実行間隔は
// 他の処理の負荷に依存するため、短めの間隔に設定することで可能な限り時間通りにタイマーが更新されるようにする
const INTERVAL = 500

export const Started: React.FC<Props> = ({
  startTime,
  currentTime,
  maxSeconds,
  fontSize = 's',
  onFinish,
}) => {
  const isFinishedRef = useRef(false)

  const initialElapsedMilliseconds = useMemo(
    () => currentTime.getTime() - startTime.getTime(),
    [currentTime, startTime]
  )

  const maxMilliseconds = useMemo(() => maxSeconds * 1000, [maxSeconds])
  const [elapsedMilliseconds, setElapsedMilliseconds] = useState(
    initialElapsedMilliseconds
  )

  useEffect(() => {
    setElapsedMilliseconds(initialElapsedMilliseconds)
  }, [initialElapsedMilliseconds])

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date()
      setElapsedMilliseconds(now.getTime() - startTime.getTime())
    }, INTERVAL)
    return () => clearInterval(timer)
  }, [startTime])

  useEffect(() => {
    if (isFinishedRef.current || !onFinish) return
    if (elapsedMilliseconds > maxMilliseconds) {
      onFinish()
      isFinishedRef.current = true
    }
  }, [elapsedMilliseconds, maxMilliseconds, onFinish])

  return (
    <Txt size={fontSize} color={theme.color.navy[1]}>
      {timerFormat(elapsedMilliseconds)}
    </Txt>
  )
}
