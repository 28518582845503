import React from 'react'
import styled from 'styled-components'
import {
  PrimaryButton,
  theme,
  Txt,
  NewTabLink as _NewTabLink,
} from '@blue-agency/rogue'
import { EXTERNAL_URLS } from '@/services/urlService'

export const Finish: React.FCX = ({ className }) => {
  return (
    <Wrapper className={className}>
      <FinishTxt>全てのテストが完了しました。</FinishTxt>
      <ButtonWrapper>
        <NewTabLink
          href={EXTERNAL_URLS.help.participants.environment.manned_support}
          action="open_manned_support_page"
          hideIcon
        >
          <NextButton
            text="次に進む"
            comlinkPushParams={{ action: 'open_manned_support_page' }}
          />
        </NewTabLink>
      </ButtonWrapper>
    </Wrapper>
  )
}

const footerHeight = 81

const Wrapper = styled.div`
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`
const FinishTxt = styled(Txt)`
  text-align: center;
  ${Wrapper} & {
    margin-top: 4px;
  }
`

const NewTabLink = styled(_NewTabLink)`
  max-width: 328px;
  ${Wrapper} & {
    width: 100%;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 18px;
  border-top: 1px solid ${theme.color.gray[4]};
  background-color: ${theme.color.white[1]};
  height: ${footerHeight}px;
  ${Wrapper} & {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
  }
`
const NextButton = styled(PrimaryButton)`
  max-width: 328px;
  border: 1px solid ${theme.color.white[1]};
  ${Wrapper} & {
    width: 100%;
  }
`
