import React, { useCallback } from 'react'
import styled from 'styled-components'
import { A, Txt as _Txt, theme } from '@blue-agency/rogue'
import { MenuItem } from './Dropdown'

export const DropdownItem: React.FCX<MenuItem> = (props) => {
  const onClick = useCallback(() => {
    if (props.disabled) return

    props.onClick()
  }, [props])

  return (
    <Wrapper
      className={props.className}
      disabled={props.disabled}
      onClick={onClick}
      comlinkPushParams={{ action: props.action }}
    >
      <Txt disabled={props.disabled}>{props.text}</Txt>
    </Wrapper>
  )
}

const Wrapper = styled(A)<{ disabled?: boolean }>`
  height: 50px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    ${({ disabled = false }) =>
      !disabled && `background: ${theme.color.gray[6]};`}
  }
  &:nth-of-type(n + 2) {
    border-top: 1px solid ${theme.color.gray[4]};
  }
`

const Txt = styled(_Txt)<{ disabled?: boolean }>`
  ${({ disabled }) => disabled && 'opacity: 0.5;'}
`
