// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import { useEffect, useRef } from 'react'
import {
  ContentShareObserver,
  ImMeetingSessionStatusCode,
} from '@blue-agency/interview-sdk-js'

import { buildScreenSharingPublisher } from '@/lib/react-interview-sdk/utils/soraConnection'
import { SoraConnectionConfig } from '@/lib/react-interview-sdk/types/SoraConnectionConfig'
import { useChanged } from '@/lib/react-interview-sdk/utils/useChanged'
import { assertIsDefined } from '@/lib/react-interview-sdk/utils/assertions'

import { useRecoilState, useSetRecoilState } from 'recoil'
import {
  isRunningOwnScreenShareState,
  screenSharingPublisherStatusState,
  interviewErrorState,
  ERR_SCREEN_SHARING_BLOCKED_BY_OS,
  ERR_SCREEN_SHARING_PERMISSION_DENIED,
} from '@/lib/react-interview-sdk/states'

import { MeetingManagerContainer } from '@/lib/react-interview-sdk/containers/MeetingManagerContainer'
import { AudioVideoContainer } from '@/lib/react-interview-sdk/containers/AudioVideoContainer'
import { ConnectionPublisher } from '@blue-agency/interview-sdk-js'

export const useContentShare = (config: SoraConnectionConfig) => {
  const { meetingManager } = MeetingManagerContainer.useContainer()
  const { audioVideo } = AudioVideoContainer.useContainer()

  const [isRunningOwnScreenShare, setIsRunningOwnScreenShare] = useRecoilState(
    isRunningOwnScreenShareState
  )
  const setScreenSharingPublisherStatus = useSetRecoilState(
    screenSharingPublisherStatusState
  )
  const setScreenSharingBlockedByOS = useSetRecoilState(
    interviewErrorState(ERR_SCREEN_SHARING_BLOCKED_BY_OS)
  )
  const setScreenSharingPermissionDenied = useSetRecoilState(
    interviewErrorState(ERR_SCREEN_SHARING_PERMISSION_DENIED)
  )

  const isConfigChanged = useChanged(config)

  const screenSharingPublisherRef = useRef<ConnectionPublisher | null>(null)

  useEffect(() => {
    if (!meetingManager || !audioVideo) {
      return
    }

    if (isConfigChanged && isRunningOwnScreenShare) {
      audioVideo.stopContentShare()
      const publisher = buildScreenSharingPublisher(config)
      meetingManager.setContentSharePublisher(publisher)
      screenSharingPublisherRef.current = publisher
      // TODO: recoil stateに持つ
      alert('画面共有の接続が切れました。再度お試しください。')
    }
  }, [
    audioVideo,
    config,
    isConfigChanged,
    isRunningOwnScreenShare,
    meetingManager,
  ])

  useEffect(() => {
    if (!audioVideo) {
      return
    }

    const contentShareObserver: ContentShareObserver = {
      contentShareDidStart: () => {
        assertIsDefined(screenSharingPublisherRef.current)

        setIsRunningOwnScreenShare(true)

        setScreenSharingPublisherStatus({
          status: 'Completed',
          channelId: config.channelId,
          webrtcHost: config.webrtcHost,
          connectionId: screenSharingPublisherRef.current.connectionId,
        })
      },
      contentShareDidStop: (sessionStatus) => {
        if (!sessionStatus.isFailure()) {
          setIsRunningOwnScreenShare(false)
          return
        }

        switch (sessionStatus.statusCode()) {
          case ImMeetingSessionStatusCode.SoraParticipantsLimitExceeded:
            setScreenSharingPublisherStatus({
              status: 'Error',
              reason: 'NumberOfParticipantsLimit',
              channelId: config.channelId,
              webrtcHost: config.webrtcHost,
            })
            break
          default:
            setScreenSharingPublisherStatus({
              status: 'Error',
              reason: 'Unknown',
              channelId: config.channelId,
              webrtcHost: config.webrtcHost,
            })
            break
        }
      },
    }

    audioVideo.addContentShareObserver(contentShareObserver)

    return () => {
      audioVideo.removeContentShareObserver(contentShareObserver)
    }
  }, [
    audioVideo,
    config,
    setIsRunningOwnScreenShare,
    setScreenSharingPublisherStatus,
  ])

  useEffect(() => {
    if (!audioVideo) {
      return
    }

    const cb = (e: PromiseRejectionEvent) => {
      // Safari は、OS での画面共有設定はなく、ユーザーが都度 Safari 上でブロックするものなので今回は表示しない
      // ref: https://github.com/blue-agency/skywalker-front/pull/491
      const blockedByOS =
        // Chrome でのメッセージ
        e.reason.message === 'Permission denied by system' ||
        // Firefox でのメッセージ
        e.reason.name === 'NotFoundError'

      if (blockedByOS) {
        setScreenSharingBlockedByOS({
          reason:
            e.reason.message === 'Permission denied by system'
              ? e.reason.message
              : e.reason.name,
        })
      }

      // https://developer.mozilla.org/ja/docs/Web/API/MediaDevices/getUserMedia
      // ブラウザ上の、共有する画面 選択でキャンセルしたときにこのエラーが発生する
      if (e.reason.name === 'NotAllowedError') {
        return
      }
      // https://developer.mozilla.org/ja/docs/Web/API/MediaDevices/getUserMedia
      if (e.reason.name === 'NotReadableError') {
        setScreenSharingPermissionDenied({ reason: 'NotReadableError' })
        return null
      }
    }

    window.addEventListener('unhandledrejection', cb)
    return () => window.removeEventListener('unhandledrejection', cb)
  }, [
    audioVideo,
    isRunningOwnScreenShare,
    setScreenSharingBlockedByOS,
    setScreenSharingPermissionDenied,
  ])

  return { screenSharingPublisherRef }
}
