import React from 'react'
import styled from 'styled-components'
import {
  Modal as _Modal,
  ErrorMessage as _ErrorMessage,
  Txt,
  WeakColorSeparator,
  TertiaryButton,
} from '@blue-agency/rogue'
import { WindowSize } from '@blue-agency/react-utils/dist/useWindowSize'

const text = {
  error: `通信が不安定な可能性があります。
インターネットの通信環境を確認して再度お試しください。`,
  main: '改善されない場合は、ブラウザを再読込してください。',
  notice:
    '※説明会実施中に再読込した場合は、約1分後に参加者にも再読込を促してください。',
}

type Props = {
  active: boolean
  onClose: () => void
  seminar?: boolean
  windowSize: WindowSize
}

export const CommunicationErrorModal: React.FCX<Props> = ({
  className,
  active,
  onClose,
  seminar,
  windowSize,
}) => {
  return (
    <Modal
      className={className}
      active={active}
      onClose={onClose}
      title="通信が不安定な可能性"
      stopScroll
      maxWidth={windowSize.width - 30}
    >
      <Wrapper>
        <Upper>
          <ErrorMessage size="s" text={text.error} />
          <MainTxt size="s">{text.main}</MainTxt>
          {seminar && <Txt size="s">{text.notice}</Txt>}
        </Upper>
        <WeakColorSeparator />
        <Bottom>
          <ButtonWrapper>
            <CloseButton
              size="l2"
              text="閉じる"
              onClick={onClose}
              comlinkPushParams={{ action: 'close_communication_error_modal' }}
            />
          </ButtonWrapper>
        </Bottom>
      </Wrapper>
    </Modal>
  )
}

const Modal = styled(_Modal)<{ maxWidth: number }>`
  max-width: ${({ maxWidth }) => maxWidth}px;
`
const Wrapper = styled.div``
const Upper = styled.div`
  padding: 20px;
`
const ErrorMessage = styled(_ErrorMessage)`
  white-space: pre-wrap;
`
const MainTxt = styled(Txt)`
  ${Upper} & {
    margin-top: 20px;
  }
`
const Bottom = styled.div`
  padding: 20px;
`
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const CloseButton = styled(TertiaryButton)``
