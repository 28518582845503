import React from 'react'
import styled from 'styled-components'
import { Txt, theme, Icon } from '@blue-agency/rogue'
import { StepContainer } from './hooks/StepContainer'
import { TutorialStep as _TutorialStep } from '@/components/TutorialStep'
import reactStringReplace from 'react-string-replace'
import { Dots as _Dots } from '@/components/Dots'
import { overflowYScrollStyle } from '@/styles/overflowYScrollStyle'

const cautions = [
  '実施時間が90分を過ぎると強制終了となりますのでご注意ください。',
  '撮影開始前には、ローディング画面が表示されますが、\n通信状況によりローディング画面が消える前に、数秒間が録画されていることがございますので、気を抜かずにお待ちください。',
  '不要なアプリケーションはすべて閉じておくことを推奨します。',
]

export const First: React.FCX = ({ className }) => {
  const { toNextStep } = StepContainer.useContainer()

  return (
    <Wrapper className={className}>
      <Pagination>
        <LeftIconWrapper>
          <LeftArrowIcon name="arrow" />
        </LeftIconWrapper>
        <TutorialStep current={1} max={2} />
        <StepTitleTxt size="l">撮影中の注意点</StepTitleTxt>
        <RightIconWrapper onClick={toNextStep}>
          <RightArrowIcon name="arrow" />
        </RightIconWrapper>
      </Pagination>
      <CautionGroup>
        {cautions.map((text, index) => (
          <Caution key={index}>
            <IconWrapper>
              <CautionIcon name="caution" />
            </IconWrapper>
            <CautionTxt>
              {reactStringReplace(text, /(数秒間|90分)/, (match, index) => (
                <RedSpan key={index}>{match}</RedSpan>
              ))}
            </CautionTxt>
          </Caution>
        ))}
      </CautionGroup>
      <Dots count={2} currentPage={1} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding-bottom: 40px;
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${overflowYScrollStyle};
`
const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 270px;
`
const TutorialStep = styled(_TutorialStep)`
  ${Pagination} & {
    margin-left: 28px;
  }
`
const StepTitleTxt = styled(Txt)`
  ${Pagination} & {
    margin-left: 6px;
  }
`
const LeftIconWrapper = styled.div``
const LeftArrowIcon = styled(Icon)``
const RightIconWrapper = styled.div`
  ${Pagination} & {
    margin-left: 28px;
  }
  cursor: pointer;
`
const RightArrowIcon = styled(Icon)`
  transform: rotate(180deg);
`
const CautionGroup = styled.div`
  ${Wrapper} & {
    margin-top: 50px;
  }
  width: 550px;
`
const Caution = styled.div`
  ${CautionGroup} & {
    &:nth-of-type(n + 2) {
      margin-top: 30px;
    }
  }
  display: flex;
`
const IconWrapper = styled.div`
  ${Caution} & {
    margin-top: 2px;
  }
`
const CautionIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  color: ${theme.color.red[2]};
`
const CautionTxt = styled(Txt)`
  ${Caution} & {
    margin-left: 6px;
  }
`
const RedSpan = styled.span`
  color: ${theme.color.red[2]};
`
const Dots = styled(_Dots)`
  ${Wrapper} & {
    position: absolute;
    bottom: 10px;
  }
`
