import { GetPermissionsResponse } from '@blue-agency/proton/web/v2/yashiori_bff/yashiori_bff_service_pb'
import { OrganizerServiceContainer } from './OrganizerServiceContainer'
import {
  useCachedPromise,
  createContainer,
} from '@blue-agency/front-state-management'
import { cacheKey } from '@/services/bffService'
import { useCallback } from 'react'

type Permissions = GetPermissionsResponse.AsObject
type PermissionKey = keyof Permissions

function usePermissions() {
  const { getPermissions } = OrganizerServiceContainer.useContainer()

  const permissions = useCachedPromise<GetPermissionsResponse.AsObject>(
    cacheKey.getPermissions(),
    getPermissions
  )

  const hasPermission = useCallback(
    (key: PermissionKey) => {
      return permissions[key]
    },
    [permissions]
  )

  return {
    hasPermission,
  }
}

export const PermissionsContainer = createContainer(usePermissions)
