import { useCallback } from 'react'
import { AudioVideoContainer } from '@/lib/react-interview-sdk/containers/AudioVideoContainer'

export const useBindVideoElement = () => {
  const { audioVideo } = AudioVideoContainer.useContainer()

  return useCallback(
    (tileId: number, el: HTMLVideoElement) => {
      if (!audioVideo) return () => {}
      const av = audioVideo
      av.bindVideoElement(tileId, el)

      return () => {
        const tile = av.getVideoTile(tileId)
        if (tile) {
          av.unbindVideoElement(tileId)
        }
      }
    },
    [audioVideo]
  )
}
