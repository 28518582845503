import { useState, useCallback, useMemo, useRef } from 'react'
import {
  OrganizerServiceContainer,
  ListHallsHall,
} from '@/containers/OrganizerServiceContainer'
import { handleCopyUrlClick, downloadCSV } from '@/utils'
import { toast } from '@blue-agency/rogue'
import { ShortenUrlResponse } from '@blue-agency/proton/web/v2/yashiori_bff/yashiori_bff_service_pb'
import { getParticipantsUrl } from '../../url'
import { ErrorMessageType } from '../ErrorModal'
import { NotPermittedModalContainer } from '@/containers/NotPermittedModalContainer'
import { StatusCode } from 'grpc-web'
import { getRelativePath } from '@/services/urlService'
import { MenuItem } from '@/components/Dropdown'

export const useMenu = (hall: ListHallsHall, token: string) => {
  const { shortenUrl, exportChatMessagesForHallCSV, exportParticipantsCSV } =
    OrganizerServiceContainer.useContainer()
  const notPermittedModal = NotPermittedModalContainer.useContainer()

  const [showDropdown, setShowDropdown] = useState(false)
  const [errorMessage, setErrorMessage] = useState<ErrorMessageType>()
  const dropdownRef = useRef<HTMLDivElement>(null)

  const handleMoreIconClick = useCallback(() => {
    // TODO: handleOutsideClickでDropdownを閉じた後再度表示してしまうのを直す(SHRQ272)
    setShowDropdown(true)
  }, [])

  const handleOutsideClick = useCallback(() => {
    setShowDropdown(false)
  }, [])

  const handleErrorModalClose = useCallback(() => {
    setErrorMessage(undefined)
  }, [])

  const copyShortUrl = useCallback(
    async (url: string) => {
      let urlRes: ShortenUrlResponse
      try {
        urlRes = await shortenUrl(url)
      } catch (e) {
        setErrorMessage({
          title: '短縮URLのコピー',
          description: `短縮URLコピーに失敗しました。
再度試していただくか、通常URLをご利用ください。`,
        })
        throw e
      }
      handleCopyUrlClick(urlRes.getShortUrl())
    },
    [shortenUrl]
  )

  const handleShortPresenterUrlCopy = useCallback(async () => {
    const url = window.location.origin + getRelativePath(hall.presenterUrl)
    try {
      await copyShortUrl(url)
    } catch {
      return
    }
    toast('発表者短縮URLをコピーしました。')
  }, [hall, copyShortUrl])

  const handleShortParticipantsUrlCopy = useCallback(async () => {
    const url = getParticipantsUrl(hall)
    try {
      await copyShortUrl(url)
    } catch {
      return
    }
    toast('参加者短縮URLをコピーしました。')
  }, [hall, copyShortUrl])

  const downloadChatMessages = useCallback(async () => {
    const fileName = `${hall.sessionName}_質問履歴`
    let csv: Uint8Array
    try {
      csv = await exportChatMessagesForHallCSV({
        hallGuid: hall.guid,
      })
    } catch (e) {
      if (e.code === StatusCode.PERMISSION_DENIED) {
        notPermittedModal.open('質問履歴ダウンロード')
        return
      }
      setErrorMessage({
        title: '質問履歴ダウンロード',
        description: `質問履歴ダウンロードに失敗しました。
再度試してください。`,
      })
      // NOTE: この方法で Sentry に送るのが良いのか怪しいけど、暫定的に一番ラクな方法を取ってる
      throw e
    }
    downloadCSV({
      data: csv,
      fileName: `${fileName}.csv`,
    })
    toast('質問履歴をダウンロードしました')
  }, [exportChatMessagesForHallCSV, hall, notPermittedModal])

  const downloadParticipantsList = useCallback(async () => {
    const fileName = `${hall.sessionName}_参加者リスト`
    let csv: Uint8Array
    try {
      csv = await exportParticipantsCSV({ hallGuid: hall.guid })
    } catch (err) {
      if (err.code === StatusCode.PERMISSION_DENIED) {
        notPermittedModal.open('参加者リストダウンロード')
        return
      }
      throw err
    }
    downloadCSV({
      data: csv,
      fileName: `${fileName}.csv`,
    })
    toast('参加者リストをダウンロードしました')
  }, [hall, exportParticipantsCSV, notPermittedModal])

  const items = useMemo<MenuItem[]>(
    () => [
      {
        text: '発表者短縮URLコピー',
        onClick: handleShortPresenterUrlCopy,
        disabled: !!hall.finishTime,
        action: 'copy_short_presenter_url',
      },
      {
        text: '参加者短縮URLコピー',
        onClick: handleShortParticipantsUrlCopy,
        disabled: !!hall.finishTime,
        action: 'copy_short_participant_url',
      },
      {
        text: '質問履歴ダウンロード',
        onClick: downloadChatMessages,
        disabled: !hall.finishTime,
        action: 'download_chat_messages',
      },
      {
        text: '参加者リストダウンロード',
        onClick: downloadParticipantsList,
        disabled: false,
        action: 'download_participants_list',
      },
    ],
    [
      handleShortPresenterUrlCopy,
      handleShortParticipantsUrlCopy,
      downloadChatMessages,
      downloadParticipantsList,
      hall,
    ]
  )

  return {
    items,
    showDropdown,
    handleMoreIconClick,
    dropdownRef,
    handleOutsideClick,
    errorMessage,
    handleErrorModalClose,
  }
}
