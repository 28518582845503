import React from 'react'
import styled from 'styled-components'
import { Txt as _Txt } from '@blue-agency/rogue'

const text = `
この録画データはセキュリティ上の理由から新しいURLに変更されています。
Web面接一覧画面に戻って、もう一度お試しください。
`

export const ExpiredContent: React.FCX = ({ className }) => (
  <div className={className}>
    <Txt>{text}</Txt>
  </div>
)

const Txt = styled(_Txt)`
  text-align: center;
`
