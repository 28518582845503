import React from 'react'
import styled from 'styled-components'
import { H2 } from '@/components/Heading'
import { format } from 'date-fns'
import ja from 'date-fns/locale/ja'
import {
  InputText,
  theme,
  PrimaryButton,
  Txt,
  NewTabLink,
} from '@blue-agency/rogue'
import { NewParticipantContainer } from './containers/NewParticipantContainer'
import { placeholder } from '@/constants/placeholder'
import { EXTERNAL_URLS } from '@/services/urlService'
import { Logo as _Logo } from '@/components/Logo'

type Props = {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void
}
export const RegisterFormContent: React.FCX<Props> = ({
  onSubmit,
  className,
}) => {
  const {
    register,
    errors,
    formState,
    organizerName,
    sessionName,
    scheduledAt,
  } = NewParticipantContainer.useContainer()
  return (
    <Form onSubmit={onSubmit} className={className}>
      <Logo />
      <OrganizerNameTxt size="l">{organizerName}</OrganizerNameTxt>
      <SessionNameTxt size="m">{sessionName}</SessionNameTxt>
      <FormTitle>参加受付</FormTitle>
      {scheduledAt && (
        <ScheduleAtTxt>
          {format(scheduledAt, 'yyyy年M月d日(E) HH:mm', { locale: ja })}
        </ScheduleAtTxt>
      )}
      <FormItem>
        <InputText
          label="姓"
          name="lastName"
          placeholder={placeholder.lastName}
          size="l"
          ref={register}
          onBlurError={errors.lastName?.message}
        />
      </FormItem>
      <FormItem>
        <InputText
          label="名"
          name="firstName"
          placeholder={placeholder.firstName}
          size="l"
          ref={register}
          onBlurError={errors.firstName?.message}
        />
      </FormItem>
      <FormItem>
        <InputText
          label="メールアドレス"
          type="email"
          name="email"
          placeholder={placeholder.email}
          size="l"
          ref={register}
          onBlurError={errors.email?.message}
        />
      </FormItem>
      <Caution size="m" color={theme.color.red[2]}>
        [注意]
        iCloudメールは、誤判定により受信できないことがございますので、@icloud.com以外のメールアドレスでご登録下さい。
      </Caution>
      <Caution size="xs" color={theme.color.red[2]}>
        登録完了メールが送信されますので、
        <br />
        noreply@interview-maker.com
        からのメールを受信できるように設定してください。
      </Caution>
      <Note size="s">
        <NewTabLink
          href={EXTERNAL_URLS.participantTerms}
          action="open_participant_terms_page"
        >
          利用規約及びプライバシーポリシー
        </NewTabLink>
        に
      </Note>
      <RegisterButton
        text="同意して登録する"
        type="submit"
        loading={formState.isSubmitting}
        comlinkPushParams={{ action: 'register_seminar_participant' }}
      />
    </Form>
  )
}
const Form = styled.form`
  width: 324px;
  padding: 30px 20px 15px;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${theme.color.white[1]};
  border-radius: 4px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
`
const Logo = styled(_Logo)`
  width: 100px;
`
const OrganizerNameTxt = styled(Txt)`
  ${Form} & {
    margin-top: 15px;
  }
`
const SessionNameTxt = styled(Txt)``
const ScheduleAtTxt = styled(Txt)`
  margin-top: 3px;
`

const FormTitle = styled(H2)`
  ${Form} & {
    margin-top: 3px;
  }

  font-size: ${theme.fontSize.m};
`
const FormItem = styled.div`
  display: flex;
  align-items: center;

  ${Form} & {
    margin-top: 20px;
    width: 100%;
  }
`
const RegisterButton = styled(PrimaryButton)`
  ${Form} & {
    width: 100%;
    margin-top: 10px;
  }
`
const Caution = styled(Txt)`
  ${Form} & {
    margin-top: 10px;
  }
`
const Note = styled(Txt)`
  ${Form} & {
    margin-top: 20px;
    align-self: flex-start;
  }
`
