import { useRecoilValue } from 'recoil'
import {
  participantsState,
  interviewersState,
  intervieweesState,
} from '@/lib/react-interview-sdk/states'

export const useParticipants = () => {
  return useRecoilValue(participantsState)
}

export const useInterviewers = () => {
  return useRecoilValue(interviewersState)
}

export const useInterviewees = () => {
  return useRecoilValue(intervieweesState)
}
