import {
  createContainer,
  useCachedPromise,
} from '@blue-agency/front-state-management'
import { useParams } from 'react-router-dom'
import { useRef, useEffect, useCallback, useState } from 'react'
import { GetSessionStatusResponse } from '@blue-agency/proton/web/v2/yashiori_bff/yashiori_bff_service_pb'
import { EnterContainer } from '../EnterContainer'
import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'
import { CommunicationErrorModalContainer } from '@/containers/CommunicationErrorModalContainer'
import { useSora } from './useSora'
import { useModal } from '@/hooks/useModal'
import { cacheKey } from '@/services/bffService'
const { Status } = GetSessionStatusResponse

const useScreenShareVideo = () => {
  const { startScreenSharing, finishScreenSharing, getSessionStatus } =
    OrganizerServiceContainer.useContainer()
  const { enterHallRes, screenSora } = EnterContainer.useContainer()
  const { handleCommunicationErrorModalOpen } =
    CommunicationErrorModalContainer.useContainer()
  const [screenShareStream, setScreenShareStream] = useState<MediaStream>()

  const screenShareVideoRef = useCallback(
    (element: HTMLVideoElement | null) => {
      if (!element) return
      element.srcObject = screenShareStream || null
    },
    [screenShareStream]
  )

  const { entranceGuid } = useParams<{ entranceGuid?: string }>()
  if (!entranceGuid) throw new Error('entranceGuid not found')
  const entranceGuidRef = useRef(entranceGuid)
  const hallGuidRef = useRef(enterHallRes.getHallGuid())
  const getSessionStatusRes = useCachedPromise(
    cacheKey.getSessionStatus(),
    () =>
      getSessionStatus({
        hallGuid: hallGuidRef.current,
        presenterToken: enterHallRes.getPresenterToken(),
      })
  )

  const startScreenShareModal = useModal()
  const finishScreenShareModal = useModal()

  const { connect, disconnect, isConnectionFailed } = useSora(
    hallGuidRef.current
  )

  const startScreenShare = useCallback(async () => {
    if (!screenSora) return
    if (
      navigator.mediaDevices === undefined ||
      navigator.mediaDevices.getDisplayMedia === undefined
    ) {
      alert(
        '利用できるカメラが見つかりません、本体設定や端末を変えて再度お試しください。'
      )
      return
    }
    const screenShareStream: MediaStream | null = await navigator.mediaDevices
      .getDisplayMedia({
        video: true,
        audio: false,
      })
      .catch(() => null)
    if (!screenShareStream) return

    await connect({
      entranceGuid: entranceGuidRef.current,
      hostname: screenSora.hostname,
      channelId: screenSora.channelId,
      screenShareStream,
    }).catch((err) => {
      handleCommunicationErrorModalOpen({ seminar: true })
      throw err
    })

    await startScreenSharing({
      hallGuid: hallGuidRef.current,
      presenterToken: enterHallRes.getPresenterToken(),
    }).catch((err) => {
      screenShareStream.getTracks().forEach((track) => track.stop())
      handleCommunicationErrorModalOpen({ seminar: true })
      throw err
    })
    setScreenShareStream(screenShareStream)
    startScreenShareModal.close()
  }, [
    screenSora,
    connect,
    startScreenSharing,
    handleCommunicationErrorModalOpen,
    startScreenShareModal,
    enterHallRes,
  ])

  const stopScreenShare = useCallback(async () => {
    await finishScreenSharing({
      hallGuid: hallGuidRef.current,
      presenterToken: enterHallRes.getPresenterToken(),
    }).catch((err) => {
      handleCommunicationErrorModalOpen({ seminar: true })
      throw err
    })
    await disconnect()
    setScreenShareStream(undefined)
    finishScreenShareModal.close()
  }, [
    disconnect,
    finishScreenSharing,
    handleCommunicationErrorModalOpen,
    finishScreenShareModal,
    enterHallRes,
  ])

  // NOTE: 画面共有時にリロードした際に、リロード後に画面共有停止の手続きを行う
  useEffect(() => {
    if (!screenShareStream && getSessionStatusRes.status === Status.RUNNING) {
      stopScreenShare()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!screenSora || !screenShareStream) return
    ;(async () => {
      await stopScreenShare()
      // TODO: alertでの表示をやめて、モーダルで表示する
      alert('画面共有の接続が切れました。もう一度やり直してください。')
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenSora])

  useEffect(() => {
    if (!screenShareStream) return
    const [videoTrack] = screenShareStream.getVideoTracks()
    // MEMO: ブラウザ固有の共有停止ボタンで画面共有が停止された時にもstopScreenShareが呼ばれるようにしている
    videoTrack.addEventListener('ended', stopScreenShare)
    return () => {
      videoTrack.removeEventListener('ended', stopScreenShare)
    }
  }, [screenShareStream, stopScreenShare])

  return {
    screenShareVideoRef,
    startScreenShare,
    stopScreenShare,
    startScreenShareModal,
    finishScreenShareModal,
    screenSharing: !!screenShareStream,
    isConnectionFailed,
  }
}

export const ScreenShareVideoContainer = createContainer(useScreenShareVideo)
