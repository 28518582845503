import {
  createContainer,
  useCachedPromise,
} from '@blue-agency/front-state-management'
import { useParams } from 'react-router-dom'
import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'
import { useMemo } from 'react'
import { cacheKey } from '@/services/bffService'

const useEnterHall = () => {
  const { enterHall, enterDemoHall, getHallParticipantsCount } =
    OrganizerServiceContainer.useContainer()
  const { token, entranceGuid } = useParams<{
    token?: string
    entranceGuid?: string
  }>()
  if (!entranceGuid) throw new Error('entranceGuid not found')

  const isDemo = useMemo(() => token === 'demo', [token])

  const enterHallRes = useCachedPromise('enterhall', () => {
    if (isDemo) {
      return enterDemoHall({ entranceGuid })
    } else {
      return enterHall({ entranceGuid })
    }
  })

  const hallGuid = enterHallRes.getHallGuid()

  const participantsCount = useCachedPromise(
    cacheKey.getHallParticipantsCount({ hallGuid }),
    () => {
      return getHallParticipantsCount({ hallGuid })
    }
  )

  return { enterHallRes, isDemo, participantsCount }
}

export const EnterHallContainer = createContainer(useEnterHall)
