import React from 'react'
import styled from 'styled-components'
import { Txt as _Txt } from '@blue-agency/rogue'
import { InterviewRecordingPageContainer } from './hooks/useInterviewRecordingPage'
import { formatDate } from '@/services/dateService'

export const InterviewRecordingMeta: React.FCX = ({ className }) => {
  const { startedAt, interviewGuid, interviewName } =
    InterviewRecordingPageContainer.useContainer()

  return (
    <Wrapper className={className}>
      <div>
        <Head size="xs" bold>
          ID
        </Head>
        <Txt size="s">{interviewGuid}</Txt>
      </div>
      <div>
        <Head size="xs" bold>
          面接名
        </Head>
        <Txt size="s">{interviewName}</Txt>
      </div>
      <div>
        <Head size="xs" bold>
          開始日時
        </Head>
        <Txt size="s">{formatDate(startedAt)}</Txt>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;

  & > * {
    margin-right: 20px;
  }
`

const Head = styled(_Txt)``

const Txt = styled(_Txt)`
  ${Wrapper} & {
    margin-top: 4px;
  }
`
