import {
  DevicePermissionStatus,
  GetAudioInputMediaError,
  ImMeetingSessionStatusCode,
  MeetingStatus,
  MeetingStatusObserver,
} from '@blue-agency/interview-sdk-js'
import { MutableRefObject, useEffect, useMemo } from 'react'

import { SoraConnectionConfig } from '@/lib/react-interview-sdk/types/SoraConnectionConfig'
import { assertIsDefined } from '@/lib/react-interview-sdk/utils/assertions'
import { useEffectWaitCleanup } from '@/lib/react-interview-sdk/utils/useEffectWaitCleanup'
import {
  buildScreenSharingPublisher,
  buildScreenSharingSubscriber,
  buildUserPublisher,
  isUnseparatedChannel,
} from '@/lib/react-interview-sdk/utils/soraConnection'
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil'
import {
  bgEffectState,
  ERR_INITIALIZE_MEDIA_DEVICES,
  interviewErrorState,
  isAnyOneScreenSharedState,
  isOwnCameraMutedState,
  isOwnMicMutedState,
  localVideoTileIdState,
  ownSoraClientIdState,
  screenSharingSubscriberStatusState,
  unstableLevelState,
  userPublisherStatusState,
  isUnavailableVideoInputState,
  isUnavailableAudioInputState,
  ERR_MEETING_JOIN_FAILED,
} from '@/lib/react-interview-sdk/states'
import {
  useMetadataMapSoraHandlers,
  useResetMetadataMap,
} from './useMetadataMap'
import { MeetingManagerContainer } from '@/lib/react-interview-sdk/containers/MeetingManagerContainer'
import { logger } from '@/lib/react-interview-sdk/logger'
import {
  ConnectionPublisher,
  SignalingNotifyConnectionCreated,
  GetVideoInputMediaError,
} from '@blue-agency/interview-sdk-js'
import { usePinByAttendeeId } from './useMainVideo'
import { useRefUpdating } from '@/lib/react-interview-sdk/utils/useRefUpdating'
import { alertToast } from '@blue-agency/rogue'
import type { ComlinkPush } from '@/lib/react-interview-sdk/comlink'

import { isSoraPushData } from '@/lib/react-interview-sdk/types/PushEvent'

const RTC_STATS_LOGGING_INTERVAL = 10_000

type Params = {
  userPublisherConfig: SoraConnectionConfig
  screenSharingSubscriberConfig: SoraConnectionConfig
  screenSharingPublisherConfig: SoraConnectionConfig
  videoFrameRate: number | undefined
  screenSharingPublisherRef: MutableRefObject<ConnectionPublisher | null>
  onConnectionCreated?: (data: SignalingNotifyConnectionCreated) => void
  onChangedQuality: () => void
  autoFocusedToMain?: boolean
  logRTCStatsEndpoint: string
  captureException: (e: Error) => void
  comlinkPush: ComlinkPush
}

export const useStartMeeting = ({
  userPublisherConfig,
  screenSharingSubscriberConfig,
  screenSharingPublisherConfig,
  screenSharingPublisherRef,
  videoFrameRate,
  autoFocusedToMain,
  onConnectionCreated,
  onChangedQuality,
  logRTCStatsEndpoint,
  captureException,
  comlinkPush,
}: Params) => {
  const { meetingManager } = MeetingManagerContainer.useContainer()

  const [ownSoraClientId, setOwnSoraClientId] =
    useRecoilState(ownSoraClientIdState)
  const setUserPublisherStatus = useSetRecoilState(userPublisherStatusState)
  const setLocalTileId = useSetRecoilState(localVideoTileIdState)
  const { connectionCreated, connectionDestroyed } =
    useMetadataMapSoraHandlers()
  const setUnstableLevel = useSetRecoilState(unstableLevelState)

  const setScreenSharingRecvSignalingStatus = useSetRecoilState(
    screenSharingSubscriberStatusState
  )
  const setInitializeMediaDevicesError = useSetRecoilState(
    interviewErrorState(ERR_INITIALIZE_MEDIA_DEVICES)
  )
  const setMeetingJoinFailedError = useSetRecoilState(
    interviewErrorState(ERR_MEETING_JOIN_FAILED)
  )

  const resetMetadataMap = useResetMetadataMap()
  const isAnyoneScreenShared = useRecoilValue(isAnyOneScreenSharedState)

  const onConnectionCreatedRef = useRefUpdating(onConnectionCreated)

  const isOwnCameraMuted = useRecoilValue(isOwnCameraMutedState)
  const [isUnavailableVideoInput, setIsUnavailableVideoInput] = useRecoilState(
    isUnavailableVideoInputState
  )
  const [isUnavailableAudioInput, setIsUnavailableAudioInput] = useRecoilState(
    isUnavailableAudioInputState
  )
  const isOwnMicMuted = useRecoilValue(isOwnMicMutedState)
  const bgEffect = useRecoilValue(bgEffectState)

  const userPublisher = useMemo(
    () => buildUserPublisher(userPublisherConfig),
    [userPublisherConfig]
  )

  const pinBySoraClientId = usePinByAttendeeId()

  // Setup user publisher
  useEffect(() => {
    userPublisher.on('notify', (data) => {
      // 参考： https://sora-doc.shiguredo.jp/signaling_notify#json
      switch (data.event_type) {
        case 'connection.created':
          connectionCreated(data)
          onConnectionCreatedRef.current && onConnectionCreatedRef.current(data)
          return
        case 'connection.destroyed':
          connectionDestroyed(data)
          return
        case 'network.status':
          setUnstableLevel(data.unstable_level)
          return
        case 'spotlight.focused':
          logger.log('spotlight.focused', data.client_id)
          if (
            autoFocusedToMain &&
            data.client_id &&
            userPublisher.clientId !== data.client_id && // 自分ならmainにしない
            !isAnyoneScreenShared // 画面共有中は切り替えない
          ) {
            pinBySoraClientId(data.client_id)
          }
      }
    })
  }, [
    userPublisher,
    autoFocusedToMain,
    connectionCreated,
    onConnectionCreatedRef,
    connectionDestroyed,
    isAnyoneScreenShared,
    pinBySoraClientId,
    setUnstableLevel,
  ])

  useEffect(() => {
    userPublisher.on('disconnect', (ev) => {
      if (ev.type === 'abend') {
        setUserPublisherStatus({
          status: 'Disconnected',
          channelId: userPublisher.channelId,
          webrtcHost: userPublisher.connectedSignalingUrl,
          title: ev.title,
          code: ev.code,
          reason: ev.reason,
        })
      }
    })
  }, [userPublisher, setUserPublisherStatus])

  useEffect(() => {
    userPublisher.on('push', (ev) => {
      const data = ev.data
      if (!isSoraPushData(data)) return

      if (data.type === 'CHANGED_QUALITY') {
        onChangedQuality()
      }
    })
  }, [userPublisher, onChangedQuality])

  const needScreenSharingSubscriber = isUnseparatedChannel(
    userPublisherConfig,
    screenSharingSubscriberConfig
  )

  const screenSharingSubscriber = useMemo(() => {
    const screenSharingSubscriber = needScreenSharingSubscriber
      ? null
      : buildScreenSharingSubscriber(screenSharingSubscriberConfig)
    if (screenSharingSubscriber) {
      screenSharingSubscriber.on('notify', (data) => {
        switch (data.event_type) {
          case 'connection.created':
            connectionCreated(data)
            return
          case 'connection.destroyed':
            connectionDestroyed(data)
            return
        }
      })
      screenSharingSubscriber.on('disconnect', (ev) => {
        if (ev.type === 'abend') {
          setScreenSharingRecvSignalingStatus({
            status: 'Disconnected',
            channelId: screenSharingSubscriber.channelId,
            webrtcHost: screenSharingSubscriber.connectedSignalingUrl,
            title: ev.title,
            code: ev.code,
            reason: ev.reason,
          })
        }
      })
    }
    return screenSharingSubscriber
  }, [
    connectionCreated,
    connectionDestroyed,
    needScreenSharingSubscriber,
    screenSharingSubscriberConfig,
    setScreenSharingRecvSignalingStatus,
  ])

  const screenSharingPublisher = useMemo(() => {
    const screenSharingPublisher = buildScreenSharingPublisher(
      screenSharingPublisherConfig
    )
    screenSharingPublisherRef.current = screenSharingPublisher
    return screenSharingPublisher
  }, [screenSharingPublisherConfig, screenSharingPublisherRef])

  useEffect(() => {
    const cb: MeetingStatusObserver = async (status, sessionStatus) => {
      switch (status) {
        case MeetingStatus.Succeeded:
          assertIsDefined(userPublisher)

          const userPublisherSoraClientId = userPublisher.clientId
          assertIsDefined(userPublisherSoraClientId)

          setUserPublisherStatus({
            status: 'Completed',
            channelId: userPublisherConfig.channelId,
            webrtcHost: userPublisherConfig.webrtcHost,
            connectionId: userPublisher.connectionId,
          })
          setOwnSoraClientId(userPublisherSoraClientId)

          if (!screenSharingSubscriber) {
            // 資料共有チャンネル分離前
            return
          }

          setScreenSharingRecvSignalingStatus({
            status: 'Completed',
            channelId: screenSharingSubscriberConfig.channelId,
            webrtcHost: screenSharingSubscriberConfig.webrtcHost,
            connectionId: screenSharingSubscriber.connectionId,
          })
          break
        case MeetingStatus.Failed:
          const code = sessionStatus.statusCode()
          switch (code) {
            case ImMeetingSessionStatusCode.NoActiveVideoInput:
              // 音声のみで参加
              setIsUnavailableVideoInput(true)
              return
            case ImMeetingSessionStatusCode.NoActiveAudioInput:
              setInitializeMediaDevicesError({
                reason: sessionStatus.toString(),
              })
              break
            case ImMeetingSessionStatusCode.SoraPublisherConnectionFailed:
              setUserPublisherStatus({
                status: 'Error',
                reason: 'Unknown',
                channelId: userPublisherConfig.channelId,
                webrtcHost: userPublisherConfig.webrtcHost,
              })
              break
            case ImMeetingSessionStatusCode.SoraAlreadyInvitedAsAnotherRole:
              setUserPublisherStatus({
                status: 'Error',
                reason: 'AlreadyInvitedAsAnotherRole',
                channelId: userPublisherConfig.channelId,
                webrtcHost: userPublisherConfig.webrtcHost,
              })
              break
            case ImMeetingSessionStatusCode.SoraParticipantsLimitExceeded:
              setUserPublisherStatus({
                status: 'Error',
                reason: 'NumberOfParticipantsLimit',
                channelId: userPublisherConfig.channelId,
                webrtcHost: userPublisherConfig.webrtcHost,
              })
              break
            case ImMeetingSessionStatusCode.SoraSubscriberConnectionFailed:
              setScreenSharingRecvSignalingStatus({
                status: 'Error',
                reason: 'Unknown',
                channelId: screenSharingSubscriberConfig.channelId,
                webrtcHost: screenSharingSubscriberConfig.webrtcHost,
              })
              break
          }
          break
      }

      if (
        sessionStatus.statusCode() ===
        ImMeetingSessionStatusCode.PreparedRestart
      ) {
        logger.log('PreparedRestart')
        assertIsDefined(meetingManager.audioVideo)
        resetMetadataMap()
        meetingManager.setUserSendrecv(userPublisher)
        meetingManager.chooseVideoInputQuality({ frameRate: videoFrameRate })
        await meetingManager.restart()
        meetingManager.startRTCStatsLogging({
          intervalMs: RTC_STATS_LOGGING_INTERVAL,
          logEndpoint: logRTCStatsEndpoint,
        })
      }
    }

    meetingManager.subscribeToMeetingStatus(cb)

    return () => {
      meetingManager.unsubscribeFromMeetingStatus(cb)
    }
  }, [
    meetingManager,
    userPublisher,
    userPublisherConfig.channelId,
    userPublisherConfig.webrtcHost,
    screenSharingSubscriber,
    screenSharingSubscriberConfig.channelId,
    screenSharingSubscriberConfig.webrtcHost,
    setInitializeMediaDevicesError,
    setOwnSoraClientId,
    setScreenSharingRecvSignalingStatus,
    setUserPublisherStatus,
    resetMetadataMap,
    logRTCStatsEndpoint,
    videoFrameRate,
    setIsUnavailableVideoInput,
  ])

  useEffectWaitCleanup(() => {
    if (!meetingManager) {
      return
    }

    logger.log('meetingManager.joinStart')
    meetingManager
      .joinAndStart(
        userPublisher,
        screenSharingPublisher,
        screenSharingSubscriber,
        isOwnCameraMuted,
        isOwnMicMuted,
        isUnavailableVideoInput,
        isUnavailableAudioInput,
        {
          frameRate: videoFrameRate,
        },
        bgEffect,
        false
      )
      .then(() => {
        const localTileId = meetingManager.audioVideo?.getLocalVideoTile()?.id()
        assertIsDefined(localTileId)
        setLocalTileId(localTileId)

        meetingManager.startRTCStatsLogging({
          intervalMs: RTC_STATS_LOGGING_INTERVAL,
          logEndpoint: logRTCStatsEndpoint,
        })

        const status = irregularJoinStatus(
          isUnavailableVideoInput,
          isUnavailableAudioInput,
          meetingManager.devicePermissionStatus
        )
        const alertMsg = irregularJoinAlertMsg(status)
        alertMsg && alertToast(alertMsg)
        if (status) {
          comlinkPush({
            action: 'join_in_irregular_situation',
            metadata: { status },
          })
        }
      })
      .catch((e) => {
        if (e instanceof GetVideoInputMediaError) {
          setIsUnavailableVideoInput(true)
          return
        }
        if (e instanceof GetAudioInputMediaError) {
          setIsUnavailableAudioInput(true)
          return
        }

        if (e instanceof Error) {
          setMeetingJoinFailedError({ reason: e.name })
        }
        captureException(e)
      })

    return async () => {
      logger.log('disconnect')

      await meetingManager.leave().catch((e) => {
        return meetingManager.leave().catch((e) => {
          captureException(e)
        })
      })
    }
    // 再接続を実行しないためにisOwnCameraMuted, isOwnMicMuted, bgEffect, userPublisher, videoFrameRateをdepsから外す
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    captureException,
    logRTCStatsEndpoint,
    meetingManager,
    screenSharingPublisher,
    screenSharingSubscriber,
    setLocalTileId,
    isUnavailableVideoInput, // 切り替わりで再接続
    isUnavailableAudioInput, // 切り替わりで再接続
  ])

  // reconnect
  useEffect(() => {
    if (!meetingManager.audioVideo) return
    // 接続済であることを保証する
    if (!ownSoraClientId) return
    logger.log('reconnect')

    meetingManager.stopRTCStatsLogging()
    meetingManager.stopBeforeRestart()

    // NOTE: meetingManager.audioVideoやownSoraClientIdは接続後に更新され、そこで再接続は実施したくないのでdepsから外す
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPublisher])
}

type IrregularJoinStatus = 'NoCamera' | 'NoMic' | 'Neither'

const irregularJoinStatus = (
  isUnavailableVideoInput: boolean,
  isUnavailableAudioInput: boolean,
  permissionStatus: DevicePermissionStatus
): IrregularJoinStatus | undefined => {
  switch (permissionStatus) {
    // すでにデバイスのラベルが取得されてるときはinterview-sdk-js内部で更新が走らずUNSETのまま
    case DevicePermissionStatus.UNSET:
    case DevicePermissionStatus.GRANTED:
      if (isUnavailableVideoInput && isUnavailableAudioInput) {
        return 'Neither'
      }
      // windowsで他アプリでカメラを使用中の場合
      if (isUnavailableVideoInput) {
        return 'NoCamera'
      }
      if (isUnavailableAudioInput) {
        return 'NoMic'
      }
      return undefined
    case DevicePermissionStatus.DENIED:
      if (isUnavailableVideoInput) {
        return 'Neither'
      } else {
        // 別で接続エラーが発生する
        return undefined
      }
    // audio不許可
    case DevicePermissionStatus.GRANTED_VIDEO_ONLY: {
      // windowsで他アプリでカメラを使用中の場合に許可かつunavailable
      if (isUnavailableVideoInput) {
        return 'Neither'
      } else {
        return 'NoMic'
      }
    }
    // video不許可
    case DevicePermissionStatus.GRANTED_AUDIO_ONLY: {
      if (isUnavailableVideoInput) {
        return 'NoCamera'
      } else {
        // 別で接続エラーが発生する
        return undefined
      }
    }
  }
}

const irregularJoinAlertMsg = (status: IrregularJoinStatus | undefined) => {
  switch (status) {
    case 'NoCamera':
      return '利用できるカメラが見つかりません。\n音声のみで接続されます。'
    case 'NoMic':
      return '利用できるマイクが見つかりません。\n映像のみで接続されます。'
    case 'Neither':
      return '利用できるカメラとマイクが見つかりません。\n映像・音声なしで接続されます。'
    default:
      return undefined
  }
}
