import { useCallback } from 'react'
import { AudioVideoContainer } from '@/lib/react-interview-sdk/containers/AudioVideoContainer'

export const useMuteMic = () => {
  const { audioVideo } = AudioVideoContainer.useContainer()
  return useCallback(() => {
    if (!audioVideo) {
      return
    }

    audioVideo.realtimeMuteLocalAudio()
  }, [audioVideo])
}

export const useUnmuteMic = () => {
  const { audioVideo } = AudioVideoContainer.useContainer()

  return useCallback(() => {
    if (!audioVideo) {
      return
    }

    audioVideo.realtimeUnmuteLocalAudio()
  }, [audioVideo])
}
