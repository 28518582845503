import { DropdownOption } from '@blue-agency/rogue'

export const convertNumberDropdownOption = (
  numList: number[]
): DropdownOption[] => {
  return numList.map((num) => {
    return {
      label: num.toString(),
      value: num,
    }
  })
}
