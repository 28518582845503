import { atom, selector } from 'recoil'
import { namespace } from './namespace'
import { participantsState } from './participants'

export const isRunningOwnScreenShareState = atom({
  key: `${namespace}/isRunningOwnScreenShareState`,
  default: false,
})

export const isAnyOneScreenSharedState = selector({
  key: `${namespace}/isAnyOneScreenSharedState`,
  get: ({ get }) => {
    const participants = get(participantsState)
    const targetRoles = ['recruiter_screen', 'applicant_screen']
    return participants.some((p) => targetRoles.includes(p.role))
  },
})

export const isOtherScreenSharedState = selector({
  key: `${namespace}/isOtherScreenSharedState`,
  get: ({ get }) => {
    const isRunningOwnScreenShare = get(isRunningOwnScreenShareState)
    const isAnyoneScreenShared = get(isAnyOneScreenSharedState)
    return !isRunningOwnScreenShare && isAnyoneScreenShared
  },
})
