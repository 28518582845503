import React from 'react'
import styled from 'styled-components'
import { PrimaryButton } from '@blue-agency/rogue'
import { GetPresentationStatusResponse } from '@blue-agency/proton/web/v2/yashiori_bff/yashiori_bff_service_pb'
import { RecordingContainer } from '../hooks/RecordingContainer'
const { Status } = GetPresentationStatusResponse

export const Bottom: React.FCX = ({ className }) => {
  const { status, handleStart } = RecordingContainer.useContainer()

  return (
    <Wrapper className={className}>
      <StartButton
        size="ll"
        text="撮影開始"
        onClick={handleStart}
        disabled={status === Status.NOT_READY}
        comlinkPushParams={{ action: 'start_presentation' }}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const StartButton = styled(PrimaryButton)``
