import React from 'react'
import styled from 'styled-components'
import { Layout } from '@/components/Layout'
import { theme } from '@blue-agency/rogue'
import { useParams } from 'react-router-dom'
import { provide } from '@blue-agency/front-state-management'
import { RegisterContainer } from './hooks/useRegister'
import { RegisterModal } from './RegisterModal'
import { Content as _Content } from './Content'
import { PageHeader as _PageHeader } from './Header'
import { SideNav } from '@/components/SideNav'
import { InterviewsContainer } from './hooks/useInterviews'
import { OrganizerContainer } from '../../hooks/useOrganizer'
import { overflowYScrollStyle } from '@/styles/overflowYScrollStyle'
import { OrganizerHeaderRight } from '@/components/OrganizerHeaderRight'
import { useTitle } from '@/hooks/useTitle'
import { Footer } from '@/pages/OrganizerRoutes/MyRoutes/InterviewsRoutes/InterviewsPage/Footer'
import { BulkActionContainer } from '@/pages/OrganizerRoutes/MyRoutes/InterviewsRoutes/InterviewsPage/hooks/useBulkAction'
import { UsedInterviewOrHallCount } from '@/components/UsedInterviewOrHallCount'
import { UpgradePlanModal } from '@/components/UpgradePlanModal'
import { PermissionsContainer } from '@/containers/PermissionsContainer'
import { NotPermittedModalContainer } from '@/containers/NotPermittedModalContainer'
import { NotPermittedModal } from '@/components/NotPermittedModal'
import { EXTERNAL_URLS } from '@/services/urlService'

export const InterviewsPage: React.FCX = provide(
  BulkActionContainer,
  RegisterContainer,
  OrganizerContainer,
  InterviewsContainer,
  NotPermittedModalContainer,
  PermissionsContainer
)(({ className }) => {
  const { hasPermission } = PermissionsContainer.useContainer()

  if (!hasPermission('listableInterviews')) {
    throw new Error('This operation is not permitted: listableInterviews')
  }

  const notPermittedModal = NotPermittedModalContainer.useContainer()
  const { token } = useParams<{ token?: string }>()
  const { showUpgradePlanModal, handleUpgradePlanModalClose } =
    RegisterContainer.useContainer()
  const { organizer, toUpgradePlanPage } = OrganizerContainer.useContainer()

  useTitle('Web面接一覧')

  return (
    <Layout
      className={className}
      tokenForNavs={token}
      showInterviewsNav={hasPermission('listableInterviews')}
      showHallsNav={hasPermission('listableSeminars')}
      showPresentationsNav={hasPermission('listablePresentations')}
      rightNode={
        <OrganizerHeaderRight
          orgName={organizer.name}
          customerCode={organizer.customerCode}
          banner={organizer.banner}
          helpUrl={EXTERNAL_URLS.help.organizer.interview.top}
          expiresAt={organizer.planExpiresAt}
          expired={organizer.isPlanExpired}
        />
      }
    >
      <Page>
        <PageHeader />
        <PageSidebar>
          <SideNav>
            <UsedInterviewOrHallCount type="interview" />
          </SideNav>
        </PageSidebar>
        <PageContent>
          <Content />
        </PageContent>
        <Footer />
      </Page>
      <RegisterModal />
      <UpgradePlanModal
        active={showUpgradePlanModal}
        onClick={toUpgradePlanPage}
        onClose={handleUpgradePlanModalClose}
      />
      <NotPermittedModal
        title={notPermittedModal.title}
        active={notPermittedModal.active}
        onClose={notPermittedModal.close}
      />
    </Layout>
  )
})

const Content = styled(_Content)`
  width: 100%;
  height: 100%;
`

const Page = styled.div`
  display: grid;
  grid-template:
    'header  header ' 60px
    'sidebar content' 1fr
    'sidebar footer ' auto
    / auto 1fr;

  width: 100%;
  height: 100%;
`
const PageHeader = styled(_PageHeader)`
  ${Page} & {
    grid-area: header;
  }
`
const PageSidebar = styled.aside`
  ${Page} & {
    grid-area: sidebar;
    overflow-y: hidden;
  }
`
const PageContent = styled.main`
  ${Page} & {
    grid-area: content;
    ${overflowYScrollStyle};
  }
  background: ${theme.color.white[1]};
`
