import React from 'react'
import { INTERNAL_PATHS, fillParams } from '@/services/urlService'
import { Redirect, useParams } from 'react-router-dom'
import { provide } from '@blue-agency/front-state-management'
import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'

export const MyPage = provide(OrganizerServiceContainer)(() => {
  const { token } = useParams<{ token?: string }>()
  if (!token) throw new Error('token not found')
  if (process.env.REACT_APP_MAINTENANCE === 'true') {
    return <Redirect to={INTERNAL_PATHS.maintenance} />
  }
  return (
    <Redirect
      to={fillParams({
        path: INTERNAL_PATHS.organizer.my.interviews.index,
        params: { token },
      })}
    />
  )
})
