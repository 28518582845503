import React from 'react'
import styled from 'styled-components'
import { Txt, theme } from '@blue-agency/rogue'

type Props = {
  showCopyright?: boolean
}
export const Footer: React.FCX<Props> = ({
  showCopyright = false,
  children,
  className,
}) => {
  return (
    <Wrapper className={className}>
      {showCopyright && (
        <Copyright>
          <Txt size="s" color={theme.color.white[1]}>
            © 2020 STADIUM
          </Txt>
        </Copyright>
      )}
      {children}
    </Wrapper>
  )
}

export const footerHeight = 40
const Wrapper = styled.footer`
  position: relative;
  display: flex;
  align-items: center;
  background: ${theme.color.navy[1]};
  height: ${footerHeight}px;
`
const Copyright = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${Wrapper} & {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: 0 auto;
  }
`
