import React from 'react'
import styled, { css } from 'styled-components'
import { theme, Icon } from '@blue-agency/rogue'

type Props = {
  isPresenter: boolean
  showMessages: boolean
  onQAClick: () => void
  onPresenterClick: () => void
  onScreenShareClick: () => void
  onQuestionClick: () => void
  onReloadClick: () => void
}

export const LandscapeControls: React.FCX<Props> = ({
  className,
  isPresenter,
  showMessages,
  onQAClick,
  onPresenterClick,
  onScreenShareClick,
  onReloadClick,
  onQuestionClick,
}) => {
  return (
    <Controls className={className}>
      <div>
        <ToggleMessage active={showMessages} onClick={onQAClick}>
          <Icon name="qa" size="l" />
        </ToggleMessage>
        <Tabs>
          <Tab selected={isPresenter} onClick={onPresenterClick}>
            <Icon name="presenter" size="l" />
          </Tab>
          <Tab selected={!isPresenter} onClick={onScreenShareClick}>
            <Icon name="screen-share" size="l" />
          </Tab>
        </Tabs>
      </div>
      <BottomControls>
        <Reload active={false} onClick={onReloadClick}>
          <Icon name="reload" size="m" />
          <ReloadTxt>再読込</ReloadTxt>
        </Reload>
        <IconWrapper onClick={onQuestionClick}>
          <QuestionIcon name="question" />
        </IconWrapper>
      </BottomControls>
    </Controls>
  )
}

const Controls = styled.div`
  padding: 10px 0;
  position: relative;
  width: 60px;
  background: ${theme.color.gray[5]};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`
const ToggleBase = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 36px;
  min-height: 36px;
  width: 45px;
  border-radius: 4px;
  border: 1px solid ${theme.color.navy[1]};
  cursor: pointer;
  color: ${theme.color.navy[1]};
  background: ${theme.color.white[1]};
  ${({ active }) =>
    active &&
    css`
      color: ${theme.color.white[1]};
      background: ${theme.color.navy[1]};
    `}
`
const ToggleMessage = styled(ToggleBase)``
const Reload = styled(ToggleBase)`
  padding: 3px 0;
  ${Controls} & {
    margin-top: 14px;
  }
`
const ReloadTxt = styled.span`
  font-size: ${theme.fontSize.xxs};
  ${Reload} & {
    margin-top: 2px;
  }
`
const Tabs = styled.div`
  width: 45px;
  height: 90px;
  display: flex;
  flex-direction: column;
  ${Controls} & {
    margin-top: 14px;
  }
`
const Tab = styled.div<{ selected: boolean }>`
  cursor: pointer;
  height: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border: 1px solid ${theme.color.navy[1]};
  background: ${({ selected }) =>
    selected ? theme.color.navy[1] : theme.color.white[1]};
  color: ${({ selected }) =>
    selected ? theme.color.white[1] : theme.color.navy[1]};
  border-radius: 4px 4px 0 0;
  &:last-of-type {
    border-radius: 0 0 4px 4px;
  }
`
const IconWrapper = styled.div`
  ${Controls} & {
    margin-top: auto;
  }
  cursor: pointer;
`
const QuestionIcon = styled(Icon)`
  width: 22px;
  height: 22px;
`
const BottomControls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > *:not(:first-child) {
    margin-top: 14px;
  }
`
