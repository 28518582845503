import React, { useCallback } from 'react'
import styled, { css } from 'styled-components'
import { InputText, RadioGroup, Txt } from '@blue-agency/rogue'
import { billToOptions } from '@/components/OrganizerForm/formItems'
import { ApplyPageContainer } from '../../ApplyPageContainer'
import { placeholder } from '@/constants/placeholder'
import { InputStepItem, billingParams } from '../../types'
import { OrganizerFormItem } from '@/components/OrganizerFormItem'

export const BillingSection: React.FCX = ({ className }) => {
  const { form } = ApplyPageContainer.useContainer()
  const watchedBillTo = form.watch('billTo')
  const handleChange = useCallback<
    (e: React.ChangeEvent<HTMLInputElement>) => void
  >(
    (e) => {
      if (e.target.value === 'other') {
        billingParams.forEach((param) => form.setValue(param, undefined))
      }
    },
    [form]
  )
  return (
    <Wrapper className={className}>
      <OrganizerFormItem
        title="請求先情報"
        label="請求先"
        inputNode={
          <RadioGroup
            ref={form.register}
            onChange={handleChange}
            name="billTo"
            direction="row"
            options={billToOptions}
          />
        }
      />
      {billingItems.map((item, index) => (
        <ItemWrapper key={index} hide={watchedBillTo === 'same'}>
          <OrganizerFormItem
            label={item.label}
            required
            inputNode={
              <InputText
                name={item.name}
                size={(() => {
                  if (item.label === '住所') return 'll'
                  if (item.label === '郵便番号') return 's'
                  return 'l'
                })()}
                placeholder={item.placeholder}
                ref={form.register}
                onBlurError={form.errors[item.name]?.message}
              />
            }
          />
          {item.notes && <Notes>{item.notes}</Notes>}
        </ItemWrapper>
      ))}
    </Wrapper>
  )
}

const billingItems: InputStepItem[] = [
  {
    label: '会社名',
    name: 'billingOrganizerName',
    placeholder: placeholder.orgName,
    notes:
      '※「法人格」（株式会社、合同会社など）もご入力をお願いします。㈱などの略称ではなく、正式名称でご入力ください。',
  },
  {
    label: '氏名',
    name: 'billingName',
    placeholder: placeholder.name,
  },
  {
    label: '氏名（かな）',
    name: 'billingNameKana',
    placeholder: placeholder.nameKana,
  },
  {
    label: '部署名',
    name: 'billingDepartment',
    placeholder: placeholder.department,
  },
  {
    label: '電話番号',
    name: 'billingPhoneNumber',
    placeholder: placeholder.tel,
    notes:
      '※ハイフン(-)なしで入力してください。\n※連絡がとれる窓口番号を入力してください。',
  },
  {
    label: '郵便番号',
    name: 'billingPostCode',
    placeholder: placeholder.postCode,
    notes: '※ハイフン(-)なしで入力してください。',
  },
  {
    label: '住所',
    name: 'billingAddress',
    placeholder: placeholder.address,
  },

  {
    label: 'メールアドレス',
    name: 'billingEmail',
    placeholder: placeholder.email,
  },
]

const Wrapper = styled.div`
  padding: 20px;
`
const ItemWrapper = styled.div<{ hide?: boolean }>`
  &:nth-of-type(n + 2) {
    margin-top: 20px;
  }
  ${({ hide }) =>
    hide &&
    css`
      display: none;
    `}
`
const Notes = styled(Txt).attrs({ size: 's' })`
  margin-top: 8px;
  margin-left: 320px;
`
