import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'
import { useState, useCallback, useEffect, useMemo } from 'react'
import { CustomGrpcError } from '@/services/bffService'
import * as grpcWeb from 'grpc-web'
import { toast } from '@blue-agency/rogue'
import { BulkActionContainer } from './BulkActionContainer'
import { HallsContainer } from './HallsContainer'

type ErrorType = 'default' | 'select_not_finished_hall'

export const useBulkDeleteHallsModal = (
  active: boolean,
  onComplete: () => void
) => {
  const { selectedHalls, reloadHalls } = HallsContainer.useContainer()
  const { deleteHalls } = OrganizerServiceContainer.useContainer()
  const { count: totalCount } = BulkActionContainer.useContainer()

  const [isAgreed, setIsAgreed] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<ErrorType | undefined>(undefined)

  const isNotFinishedHallSelected = useMemo(
    () => selectedHalls.some((hall) => hall.startTime && !hall.finishTime),
    [selectedHalls]
  )

  const toggleAgreement = useCallback(() => {
    setIsAgreed((prev) => !prev)
  }, [])

  const handleDeleteHallsClick = useCallback(async () => {
    setIsLoading(true)

    try {
      await deleteHalls(selectedHalls.map((hall) => hall.guid))
      onComplete()
      setError(undefined)
      reloadHalls()
      toast(`${totalCount}件の説明会を削除しました`)
    } catch (e) {
      if (
        e instanceof CustomGrpcError &&
        e.code === grpcWeb.StatusCode.FAILED_PRECONDITION
      ) {
        setError('select_not_finished_hall')
      } else {
        setError('default')
      }
    }

    setIsLoading(false)
  }, [deleteHalls, onComplete, reloadHalls, selectedHalls, totalCount])

  useEffect(() => {
    setError(undefined)
    setIsAgreed(false)
  }, [active])

  return {
    selectedHalls,
    totalCount,
    isNotFinishedHallSelected,
    isAgreed,
    error,
    isLoading,
    toggleAgreement,
    handleDeleteHallsClick,
  }
}
