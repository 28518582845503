const makeCacheKey =
  <T extends { [key: string]: string } | void>(key: string) =>
  (params: T) => {
    return (
      key +
      '-' +
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Object.values((params as any) || {})
        .sort()
        .join('-')
    )
  }
export const cacheKey = {
  getOrganizer: makeCacheKey('getOrganizer'),
  getPermissions: makeCacheKey('getPermissions'),
  listHalls: makeCacheKey('listHalls'),
  listInterviews: makeCacheKey('listInterviews'),
  enterHall: makeCacheKey('enterHall'),
  getSessionStatus: makeCacheKey('getSessionStatus'),
  createDemoInvitation: makeCacheKey('createDemoInvitation'),
  getPresenterInterview: makeCacheKey<{ interviewGuid: string }>(
    'getPresenterInterview'
  ),
  getParticipantName:
    makeCacheKey<{ participantGuid: string }>('getParticipantName'),
  getParticipantsInterview: makeCacheKey<{ interviewGuid: string }>(
    'getParticipantsInterview'
  ),
  getInterviewsCount: makeCacheKey('getInterviewsCount'),
  getHallsCount: makeCacheKey('getHallsCount'),
  checkEntrance: makeCacheKey('checkEntrance'),
  verifyEmail: makeCacheKey('verifyEmail'),
  pollingSessionStatus: makeCacheKey('pollingSessionStatus'),
  interviewSessionToken: makeCacheKey('interviewSessionToken'),
  getPromotion: makeCacheKey<{ promotionGuid: string }>('getPromotion'),
  getPromotionForRikunabiPackage: makeCacheKey<{ promotionGuid: string }>(
    'getPromotionForRikunabiPackage'
  ),
  verifyTokenForRikunabiPackage: makeCacheKey('verifyTokenForRikunabiPackage'),
  getInterviewRecording: makeCacheKey<{ token: string }>(
    'getInterviewRecording'
  ),
  getHallRecording: makeCacheKey<{ token: string }>('getHallRecording'),
  listPresentations: makeCacheKey('listPresentations'),
  getPresentationStatus: makeCacheKey('getPresentationStatus'),
  getPresentation: makeCacheKey('getPresentation'),
  getHallParticipantsCount: makeCacheKey<{ hallGuid: string }>(
    'getHallParticipantsCount'
  ),
  getInterviewQuality: makeCacheKey<{ interviewGuid: string }>(
    'getInterviewQuality'
  ),
  getInterviewQualityInRoom: makeCacheKey<{ interviewGuid: string }>(
    'getInterviewQualityInRoom'
  ),
  getSignalingPoints:
    makeCacheKey<{ interviewGuid: string }>('getSignalingPoints'),
  getInterviewSpotlight: makeCacheKey<{ interviewGuid: string }>(
    'getInterviewSpotlight'
  ),
  searchInterviews: makeCacheKey<{ page: string }>('searchInterviews'),
}
