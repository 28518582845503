import React, { useMemo } from 'react'
import styled from 'styled-components'
import {
  InputText,
  theme,
  PrimaryButton,
  Txt,
  NewTabLink,
} from '@blue-agency/rogue'
import { placeholder } from '@/constants/placeholder'
import { Logo as _Logo } from '@/components/Logo'
import { UseFormMethods } from 'react-hook-form'
import { RegisterEmailFormParams } from './useRegisterEmailPage'
import { EXTERNAL_URLS } from '@/services/urlService'
import { PromotionForRikunabiPackage } from '@/containers/OrganizerServiceContainer'
import { Term } from '@blue-agency/proton/web/v2/yashiori_bff/plan_data_pb'

type Props = {
  promotion: PromotionForRikunabiPackage
  form: UseFormMethods<RegisterEmailFormParams>
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void
}

export const RegisterEmailForm: React.FCX<Props> = (props) => {
  const linkProps = useMemo(() => {
    switch (props.promotion.term) {
      case Term.RIKUNABI2022:
        return {
          href: EXTERNAL_URLS.rikunabi22,
          action: 'open_rikunabi_22_page',
        }
      case Term.RIKUNABI2023:
        return {
          href: EXTERNAL_URLS.rikunabi23pre,
          action: 'open_rikunabi_23pre_page',
        }
      case Term.RIKUNABI2023SPECIAL:
        return {
          href: EXTERNAL_URLS.rikunabi23Special,
          action: 'open_rikunabi_23_special_page',
        }
      default:
        throw new Error('Invalid plan')
    }
  }, [props.promotion])
  return (
    <Form onSubmit={props.onSubmit} className={props.className}>
      <Logo />
      <PlanNameTxt>
        「{props.promotion.planName}」
        <br />
        申込フォーム
      </PlanNameTxt>
      <TxtWrapper>
        <Txt size="s">
          本フォームは、インタビューメーカーliteを新規でご契約いただく企業様向けの申込フォームです。
        </Txt>
        <Txt size="s">
          詳細な申込条件や注意事項は
          <NewTabLink {...linkProps}>こちら</NewTabLink>
          をご確認ください。
        </Txt>
      </TxtWrapper>
      <FormItem>
        <InputEmail
          label="メールアドレス"
          name="email"
          size="max"
          placeholder={placeholder.email}
          ref={props.form.register}
          onBlurError={props.form.errors.email?.message}
        />
      </FormItem>
      <Caution>
        インタビューメーカーを管理する方のメールアドレスを登録してください。
        <br />
        登録後、すぐに認証メールが送信されますので送信されたメールに記載のURLから、必要情報を入力して申込を完了させてください。
      </Caution>
      <Note>
        <NewTabLink href={EXTERNAL_URLS.terms} action="open_terms_page">
          インタビューメーカーの利用規約
        </NewTabLink>
        および
        <br />
        株式会社スタジアムの
        <NewTabLink
          href={EXTERNAL_URLS.privacy}
          action="open_privacy_policy_page"
        >
          プライバシーポリシー
        </NewTabLink>
        に
      </Note>
      <RegisterButton
        text="同意して登録する"
        type="submit"
        loading={props.form.formState.isSubmitting}
        comlinkPushParams={{ action: 'register_email_for_rikunabi_package' }}
      />
    </Form>
  )
}

const Form = styled.form`
  width: 326px;
  @media (min-width: 1024px) {
    width: 436px;
  }
  padding: 30px 20px 15px;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${theme.color.white[1]};
  border-radius: 4px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
`
const Logo = styled(_Logo)`
  width: 100px;
`
const PlanNameTxt = styled(Txt).attrs({ size: 'l' })`
  margin-top: 15px;
  text-align: center;
`
const TxtWrapper = styled.div`
  margin-top: 15px;
  & > :not(:first-of-type) {
    margin-top: 6px;
  }
`
const FormItem = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  width: 100%;
`
const InputEmail = styled(InputText)`
  width: 100%;
`
const RegisterButton = styled(PrimaryButton)`
  width: 100%;
  margin-top: 10px;
`
const Caution = styled(Txt).attrs({ size: 'xs', color: theme.color.red[2] })`
  margin-top: 10px;
`
const Note = styled(Txt).attrs({ size: 's' })`
  margin-top: 20px;
  align-self: flex-start;
`
