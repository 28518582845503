import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { Presenter } from '@/components/Presenter'
import { Txt, theme } from '@blue-agency/rogue'

type PresenterRef = HTMLVideoElement
type Props = {
  presenterProps: React.ComponentProps<typeof Presenter>
  showOverlay: boolean
}

export const PresenterWithOverlay = forwardRef<PresenterRef, Props>(
  ({ showOverlay, presenterProps }, ref) => {
    return (
      <Wrapper>
        <Presenter ref={ref} {...presenterProps} />
        {showOverlay && <Overlay />}
      </Wrapper>
    )
  }
)

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`

const Overlay = () => (
  <OverlayWrapper>
    <LoadingSvg />
    <LargeTxt>
      まもなく撮影が開始されます。
      <br />
      少々お待ちください。
    </LargeTxt>
    <SmallTxt>
      ※通信状況によりこの画面が消える前の数秒が
      <br />
      録画されていることがございますので、気を抜かずにお待ちください
    </SmallTxt>
  </OverlayWrapper>
)

const OverlayWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: ${theme.color.black[1]};
  opacity: 0.7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const LargeTxt = styled(Txt).attrs({ color: theme.color.white[1], size: 'xl' })`
  margin-top: 20px;
  text-align: center;
`
const SmallTxt = styled(Txt).attrs({ color: theme.color.white[1], size: 'l' })`
  margin-top: 20px;
  text-align: center;
`

const LoadingSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="221px"
    height="221px"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <circle
      cx="50"
      cy="50"
      fill="none"
      stroke="#ffffff"
      strokeWidth="2"
      r="23"
      strokeDasharray="108.38494654884786 38.12831551628262"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1.333333333333333s"
        values="0 50 50;360 50 50"
        keyTimes="0;1"
      ></animateTransform>
    </circle>
  </svg>
)
