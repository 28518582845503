import React from 'react'
import styled, { css } from 'styled-components'
import {
  theme,
  SecondaryDangerButton,
  TertiaryButton,
  Icon,
  ActiveTertiaryButton,
} from '@blue-agency/rogue'
import { InterviewControlsProps } from '@/components/InterviewControls'

type FixedControlsProps = Pick<
  InterviewControlsProps,
  | 'participantType'
  | 'status'
  | 'muteButtonProps'
  | 'cameraOffButtonProps'
  | 'backButtonProps'
  | 'finishButtonProps'
>

export const FixedControls: React.VFC<FixedControlsProps> = ({
  participantType,
  status,
  muteButtonProps,
  cameraOffButtonProps,
  backButtonProps,
  finishButtonProps,
}) => {
  return (
    <ControlWrapper>
      <Controls>
        {muteButtonProps.active ? (
          <ActiveMuteButton
            text="解除"
            onClick={muteButtonProps.onClick}
            icon={<Icon name="mute-mike" />}
            direction="column"
            disabled={muteButtonProps.disabled}
            comlinkPushParams={{ action: 'unmute_at_interview' }}
          />
        ) : (
          <MuteButton
            text="ミュート"
            onClick={muteButtonProps.onClick}
            icon={<Icon name="mike" />}
            direction="column"
            disabled={muteButtonProps.disabled}
            comlinkPushParams={{ action: 'mute_at_interview' }}
          />
        )}
        {cameraOffButtonProps.active ? (
          <ActiveCameraOffButton
            text="解除"
            onClick={cameraOffButtonProps.onClick}
            icon={<Icon name="movie-off" />}
            direction="column"
            disabled={cameraOffButtonProps.disabled}
            comlinkPushParams={{ action: 'camera_on_at_interview' }}
          />
        ) : (
          <CameraOffButton
            text="オフ"
            onClick={cameraOffButtonProps.onClick}
            icon={<Icon name="movie" />}
            direction="column"
            disabled={cameraOffButtonProps.disabled}
            comlinkPushParams={{ action: 'camera_off_at_interview' }}
          />
        )}
        {participantType === 'interviewer' && (
          <>
            <VerticalLine />
            {status === 'started' ? (
              <FinishButton
                text="面接終了"
                onClick={finishButtonProps?.onClick}
                icon={<Icon name="close" />}
                direction="column"
                disabled={finishButtonProps?.disabled}
                comlinkPushParams={{ action: 'open_finish_interview_modal' }}
              />
            ) : (
              <BackButton
                text="退室"
                onClick={backButtonProps?.onClick}
                icon={<Icon name="exit" />}
                direction="column"
                disabled={backButtonProps?.disabled}
                comlinkPushParams={{
                  action: 'back_to_interview_preparation_room',
                }}
              />
            )}
          </>
        )}
      </Controls>
    </ControlWrapper>
  )
}

const ControlWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
`

const Controls = styled.div`
  display: flex;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 4px 4px 0 0;
  padding: 10px;

  > *:not(:first-child) {
    margin-left: 20px;
  }
`

const buttonSize = css`
  font-size: ${theme.fontSize.xs};
  padding: 0;
  width: 52px;
  height: 44px;
`

const MuteButton = styled(TertiaryButton)`
  ${buttonSize}
  width: 52px;
`
const ActiveMuteButton = styled(ActiveTertiaryButton)`
  ${buttonSize}
  width: 52px;
`
const CameraOffButton = styled(TertiaryButton)`
  ${buttonSize}
  width: 52px;
`
const ActiveCameraOffButton = styled(ActiveTertiaryButton)`
  ${buttonSize}
  width: 52px;
`

const VerticalLine = styled.div`
  width: 1px;
  height: 100%;
  background-color: ${theme.color.gray[1]};
  opacity: 0.5;
`

const BackButton = styled(TertiaryButton)`
  ${buttonSize}
  width: 84px;
`
const FinishButton = styled(SecondaryDangerButton)`
  ${buttonSize}
  width: 84px;
`
