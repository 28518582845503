import { useRef, useCallback, useEffect, useState } from 'react'
import { Comlink } from '@blue-agency/comlink'
import soraSdk, { ConnectionOptions, ConnectionPublisher } from 'sora-js-sdk'
import { useOfflineComlinkQueue } from '@/hooks/useOfflineComlinkQueue'
import { useInterval } from 'react-use'

const INTERVAL_MS = 10_000 // 10秒

export const useSora = (hallGuid: string, logEndpoint: string) => {
  const sendonlyRef = useRef<ConnectionPublisher>()
  const [unstableLevel, setUnstableLevel] = useState<number>()
  const [isConnectionFailed, setIsConnectionFailed] = useState(false)
  const { enqueue: comlinkPush } = useOfflineComlinkQueue()

  const onIceConnectionStateChangeHandler = useCallback(() => {
    const pc = sendonlyRef?.current?.pc
    if (!pc) return
    pc.oniceconnectionstatechange = () => {
      if (
        pc.iceConnectionState === 'failed' ||
        pc.iceConnectionState === 'disconnected' ||
        pc.iceConnectionState === 'closed'
      ) {
        setIsConnectionFailed(true)
        comlinkPush({
          type: 'system_activity',
          action: 'disconnected_from_webrtc_connection_on_presenter',
          targetName: 'hallGuid',
          targetIdStr: hallGuid,
          metadata: {
            iceConnectionState: pc.iceConnectionState,
          },
        })
      }
    }
  }, [hallGuid, comlinkPush])

  const connectToSora = useCallback(
    async ({
      entranceGuid,
      hostname,
      channelId,
      userMediaStream,
    }: {
      entranceGuid: string
      hostname: string
      channelId: string
      userMediaStream: MediaStream
    }) => {
      const options: ConnectionOptions = {
        multistream: false,
        videoCodecType: 'H264',
        videoBitRate: 800,
      }
      const metadata = {
        guid: entranceGuid,
      }

      const sora = soraSdk.connection(`wss://${hostname}/signaling`, false)

      const sendonly = sora.sendonly(channelId, metadata, options)
      sendonly.on('notify', (data) => {
        if (data.event_type === 'network.status') {
          setUnstableLevel(data.unstable_level)
        }
      })
      sendonlyRef.current = sendonly

      comlinkPush({
        type: 'system_activity',
        action: 'connect_presenter_to_sora',
        targetName: 'channelId',
        targetIdStr: channelId,
      })
      return await sendonlyRef.current
        .connect(userMediaStream)
        .then(onIceConnectionStateChangeHandler)
    },
    [comlinkPush, onIceConnectionStateChangeHandler]
  )

  let posting = false
  useInterval(
    async () => {
      if (posting) return
      posting = true

      const pc = sendonlyRef?.current?.pc
      if (!pc) return

      const stats = await pc.getStats()
      if (stats === undefined) return

      const uuid = await getBrowserClientUuid()

      const data = {
        uuid: uuid,
        data: {
          stats: Array.from(stats.values()),
          channelId: sendonlyRef?.current?.channelId,
          connectionId: sendonlyRef?.current?.connectionId,
          timestamp: new Date().getTime(),
        },
      }
      const dataJson = JSON.stringify(data)

      try {
        await fetch(logEndpoint, {
          method: 'POST',
          mode: 'cors',
          credentials: 'omit',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json',
          },
          body: dataJson,
        })
      } catch {
        // WebRTC Statsは失敗してもログが送信できないだけなので無視する
      }

      posting = false
    },
    sendonlyRef?.current?.pc ? INTERVAL_MS : null
  )

  useEffect(() => {
    if (unstableLevel === undefined) return
    comlinkPush({
      type: 'system_activity',
      action: 'change_unstable_level',
      targetName: 'hallGuid',
      targetIdStr: hallGuid,
      metadata: {
        videoType: 'presenter',
        unstableLevel: unstableLevel + '',
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unstableLevel])

  const disconnectFromSora = useCallback(async () => {
    if (!sendonlyRef.current) return
    comlinkPush({
      type: 'system_activity',
      action: 'disconnect_presenter_from_sora',
      targetName: 'channelId',
      targetIdStr: sendonlyRef.current?.channelId,
    })
    await sendonlyRef.current.disconnect()
  }, [comlinkPush])

  const replaceTrack = useCallback(
    (videoTrack: MediaStreamTrack) => {
      const senders = sendonlyRef.current?.pc?.getSenders()
      if (!senders) {
        comlinkPush({
          type: 'system_activity',
          action: 'failed_to_replaceTrack_on_seminar',
          targetName: 'channelId',
          targetIdStr: sendonlyRef.current?.channelId,
          metadata: { reason: 'senders not found' },
        })
        throw new Error('senders not found')
      }

      const sender = senders.find((sender) => sender.track?.kind === 'video')
      if (!sender) {
        comlinkPush({
          type: 'system_activity',
          action: 'failed_to_replaceTrack_on_seminar',
          targetName: 'channelId',
          targetIdStr: sendonlyRef.current?.channelId,
          metadata: { reason: 'sender not found' },
        })
        throw new Error('sender not found')
      }

      sender.replaceTrack(videoTrack)
    },
    [comlinkPush]
  )

  return {
    connectToSora,
    disconnectFromSora,
    replaceTrack,
    isConnectionFailed,
  }
}

const getBrowserClientUuid = async () => {
  const i = await Comlink.getClientIdentity()
  return i.uuid
}
