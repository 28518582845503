import { SoraConnectionConfig } from '@/lib/react-interview-sdk/types/SoraConnectionConfig'
import {
  Sora,
  ConnectionPublisher,
  ConnectionSubscriber,
  ConnectionOptions,
} from '@blue-agency/interview-sdk-js'

const buildCommon = (
  webrtcHost: string,
  params: {
    videoBitRate?: number
    spotlight?: boolean
    spotlightNumber?: number
  }
) => {
  const connection = Sora.connection(`wss://${webrtcHost}/signaling`, false)
  const options: ConnectionOptions = {
    multistream: true,
    simulcast: params.spotlight, // スポットライトのときは必ずサイマルキャストが必要。個別にサイマルキャストを使うことも今はない。
    spotlight: params.spotlight,
    spotlightNumber: params.spotlightNumber,
    videoCodecType: 'VP8',
    videoBitRate: params.videoBitRate,
  }
  return { connection, options }
}

export const buildSendrecv = ({
  webrtcHost,
  channelId,
  metadata,
  videoBitRate,
  spotlight,
  spotlightNumber,
}: SoraConnectionConfig): ConnectionPublisher => {
  const { connection, options } = buildCommon(webrtcHost, {
    videoBitRate,
    spotlight,
    spotlightNumber,
  })
  return connection.sendrecv(channelId, metadata, options)
}

export const buildSendonly = ({
  webrtcHost,
  channelId,
  metadata,
  videoBitRate,
  spotlight,
  spotlightNumber,
}: SoraConnectionConfig): ConnectionPublisher => {
  const { connection, options } = buildCommon(webrtcHost, {
    videoBitRate,
    spotlight,
    spotlightNumber,
  })
  return connection.sendonly(channelId, metadata, options)
}

export const buildRecvonly = ({
  webrtcHost,
  channelId,
  metadata,
  videoBitRate,
  spotlight,
  spotlightNumber,
}: SoraConnectionConfig): ConnectionSubscriber => {
  const { connection, options } = buildCommon(webrtcHost, {
    videoBitRate,
    spotlight,
    spotlightNumber,
  })
  return connection.recvonly(channelId, metadata, options)
}

export const buildUserPublisher = (params: SoraConnectionConfig) => {
  return buildSendrecv({
    ...params,
    metadata: {
      ...params.metadata,
      is_screen_shared: false,
      is_screen_sharing_recv: false,
    },
  })
}

export const buildScreenSharingPublisher = (params: SoraConnectionConfig) => {
  return buildSendonly({
    ...params,
    metadata: {
      ...params.metadata,
      is_screen_shared: true,
      is_screen_sharing_recv: false,
    },
  })
}

export const buildScreenSharingSubscriber = (params: SoraConnectionConfig) => {
  return buildRecvonly({
    ...params,
    metadata: {
      ...params.metadata,
      is_screen_shared: false,
      is_screen_sharing_recv: true,
    },
  })
}

export const isUnseparatedChannel = (
  mainPublisherParams: SoraConnectionConfig,
  screenSharingSubscriberParams: SoraConnectionConfig
) => {
  // チャンネル分離以前のデータで同じチャンネルIDの場合、受信する必要がない
  return (
    mainPublisherParams.channelId === screenSharingSubscriberParams.channelId &&
    mainPublisherParams.webrtcHost === screenSharingSubscriberParams.webrtcHost
  )
}
