import {
  CacheContainer,
  createContainer,
  useCachedPromise,
} from '@blue-agency/front-state-management'
import { useCallback, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { toast } from '@blue-agency/rogue'
import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'
import { cacheKey } from '@/services/bffService'
import { OrganizerContainer } from '../../../hooks/useOrganizer'
import { Plan } from '@blue-agency/proton/web/v2/yashiori_bff/plan_data_pb'
import { PermissionsContainer } from '@/containers/PermissionsContainer'
import { NotPermittedModalContainer } from '@/containers/NotPermittedModalContainer'
import { StatusCode } from 'grpc-web'
import { Quota } from '@/services/quotaService'
import { HallsContainer } from './HallsContainer'
import { comlinkPush } from '@/comlink'

export type RegisterForm = {
  sessionName: string
  scheduledParticipantsNumber: number
  scheduledAtDate: Date
  scheduledAtHour: number
  scheduledAtMinute: number
}

export const registerFormValidationSchema: yup.SchemaOf<RegisterForm> = yup
  .object()
  .shape({
    sessionName: yup.string().required('入力してください'),
    scheduledParticipantsNumber: yup
      .number()
      .max(100000000, '入力可能な値の範囲を超えています') // 巨大な値を入れられた際にエラーを表示(特に決められていないので、一旦1億と仮で設定)
      // このカラムが空のとき、""をnumberにキャストしようとしてTypeErrorになる そのエラーを抑制&実質的に未入力時のエラーなので「入力してください」と表示させる
      .typeError('入力してください')
      .required('入力してください')
      .positive('正の値を入力してください')
      .integer('整数を入力してください'),
    scheduledAtDate: yup
      .date()
      .required('入力してください')
      .typeError('正しい日付を入力してください'),
    scheduledAtHour: yup.number().required('入力してください').min(0).max(23),
    scheduledAtMinute: yup.number().required('入力してください').min(0).max(59),
  })
  .defined()

const useRegister = () => {
  const { deleteCache } = CacheContainer.useContainer()
  const { createHall, getHallsCount } = OrganizerServiceContainer.useContainer()
  const { organizer } = OrganizerContainer.useContainer()
  const { hasPermission } = PermissionsContainer.useContainer()
  const { reloadHalls } = HallsContainer.useContainer()
  const notPermittedModal = NotPermittedModalContainer.useContainer()

  const { contractPeriod } = useCachedPromise(cacheKey.getHallsCount(), () => {
    return getHallsCount()
  })
  const quota = new Quota(contractPeriod)

  const { register, handleSubmit, errors, formState, reset, setValue } =
    useForm<RegisterForm>({
      resolver: yupResolver(registerFormValidationSchema),
      mode: 'onBlur',
    })
  const [showRegisterModal, setShowRegisterModal] = useState(false)
  const [showUpgradePlanModal, setShowUpgradePlanModal] = useState(false)

  const handleRegisterModalClose = useCallback(() => {
    setShowRegisterModal(false)
  }, [])
  const handleUpgradePlanModalClose = useCallback(() => {
    setShowUpgradePlanModal(false)
  }, [])

  const handleRegisterButtonClick = useCallback(() => {
    if (!hasPermission('creatableSeminar')) {
      notPermittedModal.open('Web説明会登録')
      return
    }
    const { plan } = organizer
    if (
      (plan.id === Plan.Id.TRIAL_30DAYS || plan.id === Plan.Id.FREE) &&
      organizer.isPlanExpired
    ) {
      setShowUpgradePlanModal(true)
      return
    }
    setShowRegisterModal(true)
  }, [organizer, hasPermission, notPermittedModal])

  const onSubmit = useCallback<SubmitHandler<RegisterForm>>(
    async (data) => {
      comlinkPush({
        type: 'manual_activity',
        action: 'create_hall',
        targetName: 'yashiori_hall.session_name',
        targetIdStr: data.sessionName,
      })

      const scheduledAt = data.scheduledAtDate
      scheduledAt.setHours(data.scheduledAtHour)
      scheduledAt.setMinutes(data.scheduledAtMinute)
      try {
        await createHall({
          sessionName: data.sessionName,
          scheduledAt: scheduledAt,
          scheduledParticipantsNumber: data.scheduledParticipantsNumber,
        })
      } catch (err) {
        if (err.code === StatusCode.PERMISSION_DENIED) {
          setShowRegisterModal(false)
          notPermittedModal.open('Web説明会登録', {
            callback: () => setShowRegisterModal(true),
          })
          return
        }
        throw err
      }
      deleteCache(cacheKey.listHalls())
      deleteCache(cacheKey.getHallsCount())
      setShowRegisterModal(false)
      reloadHalls()
      toast('Web説明会を登録しました')
      reset()
    },
    [createHall, deleteCache, notPermittedModal, reset, reloadHalls]
  )

  return {
    quota,
    handleRegisterModalClose,
    handleRegisterButtonClick,
    showRegisterModal,
    showUpgradePlanModal,
    handleUpgradePlanModalClose,
    register,
    handleSubmit,
    errors,
    formState,
    onSubmit,
    setValue,
  }
}

export const RegisterContainer = createContainer(useRegister)
