import React from 'react'
import { PcFormLayout } from '@/components/FormLayout'
import { Layout } from '@/components/Layout'
import { Body } from './Body'
import { ApplyPageContainer } from '../ApplyPageContainer'

export const Completed: React.FCX = ({ className }) => {
  const { promotion } = ApplyPageContainer.useContainer()
  return (
    <Layout className={className} notFixHeight>
      <PcFormLayout
        className={className}
        title={`「${promotion.planName}」新規申込`}
        subTitle="登録完了"
        body={<Body />}
      />
    </Layout>
  )
}
