import * as Sentry from '@sentry/react'
import { CustomGrpcError } from '@/services/bffService'
import { UAParser } from 'ua-parser-js'
import { checkInterviewBrowser } from './services/browserCheckService'

// NOTE: RPCから返ってくるエラーで、Sentryに通知したくないものはここに追記していく
const ignoreGrpcErrorMessages = [
  'Waiting for a available date', // NOTE: 開始待ちエラーの時は無視する
  'contract has expired', // NOTE: 契約期限切れエラーの時は無視する
]

// NOTE: アプリケーション内でthrowしているエラーで、Sentryに通知したくないものはここに追記していく
const ignoreAppErrorMessages = [
  'failed to decode interviewRecordingToken',
  'token not found',
]

export const initSentry = () => {
  Sentry.init({
    environment: process.env.REACT_APP_SENTRY_ENV,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    beforeSend(
      event: Sentry.Event,
      hint?: Sentry.EventHint
    ): PromiseLike<Sentry.Event | null> | Sentry.Event | null {
      // NOTE: 緊急対応の必要ないエラーをSentryに飛ばしたくないので、サポート外のUAの場合は無視する
      // TODO: SeminarではなくInterview, intervieweeではなくinterviewerにしていることに意図はない、明確にSentryに送るかどうかの条件が決まったら修正
      const parser = new UAParser(navigator.userAgent)
      const res = checkInterviewBrowser(parser.getUA(), 'interviewer')
      if (res.message === 'unsupported') return null

      const originalException = hint?.originalException

      // TODO: タイムアウト等sentryに通知不要なエラーは無視する
      if (originalException instanceof CustomGrpcError) {
        if (
          ignoreGrpcErrorMessages.includes(
            originalException.metadata['grpc-message']
          )
        ) {
          return null
        }

        if (!event.tags) {
          event.tags = {}
        }

        event.tags.grpc_status_code = originalException.code.toString()
        if (!event.extra) {
          event.extra = {}
        }
        event.extra.CustomGrpcError = originalException
      }

      if (originalException instanceof Error) {
        if (ignoreAppErrorMessages.includes(originalException.message)) {
          return null
        }
      }

      return event
    },
  })
}
