export type ApplyFormParams = {
  preferredStartDateId: string
  rikunabiOrganizerId: string
  rikunabiOrganizerName: string
  name: string
  representativeName: string
  phoneNumber: string
  postCode: string
  address: string
  employeesNumberId: string
  adminName: string
  adminNameKana: string
  adminDepartment: string
  adminPhoneNumber: string
  billTo: string
  newHiresNumberId: string
} & BillingParams

export const billingParams = [
  'billingOrganizerName',
  'billingName',
  'billingNameKana',
  'billingDepartment',
  'billingPostCode',
  'billingAddress',
  'billingPhoneNumber',
  'billingEmail',
] as const
export type BillingParams = Partial<
  Record<typeof billingParams[number], string>
>

export type InputStepItem = {
  label: string
  name: keyof ApplyFormParams
  placeholder: string
  options?: { value: string; label: string }[]
  notes?: string
}

export type ConfirmStepItem = {
  label: string
  name: keyof ApplyFormParams
}
