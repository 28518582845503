import React from 'react'
import styled from 'styled-components'
import {
  Modal,
  Txt,
  RequireTxt as _RequireTxt,
  TertiaryButton,
  PrimaryButton,
  InputText,
} from '@blue-agency/rogue'
import { RegisterContainer } from './hooks/RegisterContainer'

export const RegisterModal: React.FCX = ({ className }) => {
  const { registerModal, onSubmit, formContext } =
    RegisterContainer.useContainer()

  return (
    <Modal
      className={className}
      size="l"
      active={registerModal.active}
      title="説明会動画登録"
      onClose={registerModal.close}
    >
      <Form onSubmit={formContext.handleSubmit(onSubmit)}>
        <Upper>
          <Item>
            <Label>
              <LabelTxt>動画名</LabelTxt>
              <RequireTxt>必須</RequireTxt>
              <InputText
                name="name"
                size="ll"
                placeholder="動画名"
                ref={formContext.register}
                onBlurError={formContext.errors.name?.message}
              />
            </Label>
          </Item>
        </Upper>
        <Bottom>
          <CancelButton
            size="l2"
            text="キャンセル"
            onClick={registerModal.close}
            comlinkPushParams={{ action: 'close_register_presentation_modal' }}
          />
          <RegisterButton
            size="l2"
            text="登録"
            type="submit"
            loading={formContext.formState.isSubmitting}
            comlinkPushParams={{ action: 'register_presentation' }}
          />
        </Bottom>
      </Form>
    </Modal>
  )
}

const Form = styled.form``
const Upper = styled.div`
  padding: 20px;
`

const Item = styled.div`
  ${Form} & {
    width: 100%;
  }
  ${Form} &:nth-of-type(n + 2) {
    margin-top: 20px;
  }
  display: flex;
  align-items: center;
  margin-top: 20px;
`
const Label = styled.label`
  display: flex;
  align-items: center;
`
const LabelTxt = styled(Txt)`
  display: flex;
  width: 100px;
`

const RequireTxt = styled(_RequireTxt)`
  margin-right: 20px;
  width: 30px;
`
RequireTxt.defaultProps = { size: 'xs' }

const Bottom = styled.div`
  ${Form} & {
    padding: 20px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
`
const CancelButton = styled(TertiaryButton)``
const RegisterButton = styled(PrimaryButton)`
  ${Bottom} & {
    margin-left: 20px;
  }
`
