import React from 'react'
import { PcFormLayout } from '@/components/FormLayout'
import { useBeforeUnload } from '@blue-agency/react-utils'
import { provide } from '@blue-agency/front-state-management'
import { OrganizerServiceContainer } from '@/containers/OrganizerServiceContainer'
import { Layout } from '@/components/Layout'
import { Redirect } from 'react-router-dom'
import { OrganizerContainer } from '../hooks/useOrganizer'
import { useTitle } from '@/hooks/useTitle'
import { UpgradePlanContainer } from './hooks/useUpgradePlan'
import { Upper } from './Upper'
import { Bottom } from './Bottom'
import { Body } from './Body'
import { Plan } from '@blue-agency/proton/web/v2/yashiori_bff/plan_data_pb'
import { INTERNAL_PATHS, fillParams } from '@/services/urlService'

export const UpgradePlanPage: React.FCX = provide(
  UpgradePlanContainer,
  OrganizerContainer,
  OrganizerServiceContainer
)(({ className }) => {
  const { organizer, token } = OrganizerContainer.useContainer()
  const { step, onSubmit, subTitle } = UpgradePlanContainer.useContainer()

  useTitle('有料プラン切替申込フォーム')
  useBeforeUnload('ページを離れます。よろしいですか？', step !== 'completed')

  const { plan } = organizer
  if (!(plan.id === Plan.Id.TRIAL_30DAYS || plan.id === Plan.Id.FREE)) {
    return (
      <Redirect
        to={fillParams({
          path: INTERNAL_PATHS.organizer.my.index,
          params: { token },
        })}
      />
    )
  }

  return (
    <Layout className={className} notFixHeight>
      <PcFormLayout
        className={className}
        title="有料プラン切替申込フォーム"
        subTitle={subTitle}
        upper={<Upper step={step} />}
        body={<Body step={step} />}
        bottom={<Bottom step={step} />}
        onSubmit={onSubmit[step]}
      />
    </Layout>
  )
})
