import { useState, useCallback } from 'react'
import { createContainer } from '@blue-agency/front-state-management'

const useManualModal = () => {
  const [showManualModal, setShowManualModal] = useState(false)

  const handleManualModalOpen = useCallback(() => {
    setShowManualModal(true)
  }, [])
  const handleManualModalClose = useCallback(() => {
    setShowManualModal(false)
  }, [])

  return {
    showManualModal,
    handleManualModalOpen,
    handleManualModalClose,
  }
}

export const ManualModalContainer = createContainer(useManualModal)
