import React from 'react'
import styled from 'styled-components'
import {
  InputText as _InputText,
  RadioGroup,
  RequireTxt,
  Txt,
  RadioOption,
} from '@blue-agency/rogue'
import { OrganizerForm } from '../../types'
import { UseFormMethods } from 'react-hook-form'
import { useInputBodyPlan } from '../../hooks/useInputBodyPlan'

type Props = {
  formContext: UseFormMethods<OrganizerForm>
  planOptions: RadioOption[]
  hideCouponCode?: boolean
  hideReferrer?: boolean
}
export const InputBodyPlan: React.FCX<Props> = ({
  formContext,
  planOptions,
  hideCouponCode = false,
  hideReferrer = false,
}) => {
  const { total, term } = useInputBodyPlan({
    formContext,
    planOptions,
  })
  return (
    <Block>
      <ItemGroup>
        <Item>
          <ItemTitle>申込内容</ItemTitle>
          <ItemLabel>
            <ItemLabelTxt>利用プラン</ItemLabelTxt>
            <Required size="xs" />
            <RadioGroup
              name="plan"
              direction="row"
              options={planOptions}
              ref={formContext.register}
            />
          </ItemLabel>
        </Item>
        <Item>
          <ItemTitle />
          <ItemLabel>
            <ItemLabelTxt>ご契約期間</ItemLabelTxt>
            <Required size="xs" />
            <Txt size="s">{term}</Txt>
          </ItemLabel>
        </Item>
        <Item>
          <ItemTitle />
          <ItemLabel>
            <ItemLabelTxt>合計金額</ItemLabelTxt>
            <Required size="xs" />
            <TotalSumTxt size="s">{total.sum}</TotalSumTxt>
          </ItemLabel>
        </Item>
        <TotalNoticeTxt size="s">{total.notice}</TotalNoticeTxt>
        {!hideReferrer && (
          <Item>
            <ItemTitle />
            <ItemLabel>
              <ItemLabelTxt>ご紹介元</ItemLabelTxt>
              <Required size="xs" />
              <InputText
                name="referrer"
                size="ll"
                placeholder="インタビューメーカーのご紹介を受けた企業名をご記入ください"
                ref={formContext.register}
                onBlurError={formContext.errors.referrer?.message}
              />
            </ItemLabel>
          </Item>
        )}
        {!hideCouponCode && (
          <Item>
            <ItemTitle />
            <ItemLabel>
              <ItemLabelTxt>クーポンコード</ItemLabelTxt>
              <Required size="xs" />
              <InputText
                name="couponCode"
                size="ll"
                placeholder="クーポンコードをお持ちでしたらご入力ください。"
                ref={formContext.register}
                onBlurError={formContext.errors.couponCode?.message}
              />
            </ItemLabel>
          </Item>
        )}
      </ItemGroup>
    </Block>
  )
}

const Block = styled.div`
  &:nth-of-type(n + 2) {
    margin-top: 0;
  }
  padding: 20px 0;
`
const ItemGroup = styled.div``
const Item = styled.div<{ hide?: boolean }>`
  ${ItemGroup} &:nth-of-type(n + 2) {
    margin-top: 20px;
  }
  padding: 0 20px;
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  align-items: center;
`
const ItemTitle = styled(Txt)`
  width: 160px;
`
const ItemLabel = styled.label`
  display: flex;
  align-items: center;
`
const ItemLabelTxt = styled(Txt).attrs({ size: 's', bold: true })`
  width: 120px;
`
const Required = styled(RequireTxt)`
  width: 30px;
`
const InputText = styled(_InputText)``
const TotalSumTxt = styled(Txt)``
const TotalNoticeTxt = styled(Txt)`
  ${ItemGroup} & {
    margin-left: 330px;
    margin-top: 6px;
  }
`
