// PlanによってTimerの表示を変える必要がありyashiori-frontに別にTimerを用意した
// https://blue-agency.atlassian.net/browse/IMLD-861

import React from 'react'
import { Started } from './Started'
import { NotStarted } from './NotStarted'
import { theme, Timer } from '@blue-agency/rogue'

export type Milliseconds = number
export type TimerProps = {
  startTime?: Date
  currentTime: Date
  maxSeconds: number
  fontSize?: keyof typeof theme.fontSize
  onFinish?: () => void
  className?: string
  isKaburikuPlan: boolean
}

export const YashioriTimer: React.FC<TimerProps> = (props) => {
  if (props.isKaburikuPlan) {
    return props.startTime ? (
      <Started {...props} startTime={props.startTime} />
    ) : (
      <NotStarted {...props} />
    )
  }
  return (
    <Timer
      startTime={props.startTime}
      currentTime={props.currentTime}
      maxSeconds={props.maxSeconds}
    />
  )
}

export const timerFormat = (ms: Milliseconds) => {
  const h = Math.floor(ms / (1000 * 60))
    .toString()
    .padStart(2, '0')

  const min = Math.floor((ms / 1000) % 60)
    .toString()
    .padStart(2, '0')

  return `${h}:${min}`
}
